const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const currencyFormatterWithoutCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function getMoneyValue(amount: number, decimal: boolean = true): string {
  if (amount === undefined || isNaN(amount) || typeof amount !== 'number') {
    return 'N/A';
  }
  return decimal ? currencyFormatter.format(amount) : currencyFormatterWithoutCents.format(amount);
}

export function hasNonZeroDecimals(amount: number): boolean {
  return !(amount % 1 === 0);
}

export function getPercentValue(amount: number): string {
  if (amount === undefined || isNaN(amount) || typeof amount !== 'number') {
    return 'N/A';
  }
  return hasNonZeroDecimals(amount) ? amount.toFixed(2) + '%' : amount.toFixed(0) + '%';
}

export function getMoneyValueHtml(amount: number, decimal: boolean = true): string {
  const money = getMoneyValue(amount, decimal);
  if (decimal) {
    const moneyArr = money.split('.');
    return moneyArr[0] + '.<span class="cents">' + moneyArr[1] + '</span>';
  }
  return money;
}
