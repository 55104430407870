/* eslint-disable max-classes-per-file */
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { root } from 'rxjs/util/root';
import { IAxiosRequestConfig } from 'scripts/api/api.interfaces';
import { getCache, getCacheKey } from 'scripts/api/cache';
import { LocaleKey } from '../util/constants/i18n.constants';

const NO_CACHE_KEY = 'nocache';

declare module 'angular' {
  namespace chieffancypants {
    // eslint-disable-next-line @typescript-eslint/interface-name-prefix
    export interface LoadingBarProvider {
      includeSpinner: boolean;
      parentSelector: string;
    }
  }
}

function getNoCacheValue(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(NO_CACHE_KEY) && urlParams.get(NO_CACHE_KEY) === 'true';
}

function removeNoCacheValue(): void {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(NO_CACHE_KEY)) {
    urlParams.delete(NO_CACHE_KEY);
    const queryString = urlParams.toString();
    // get path without hash ('#/path/more-path?query=param&another' becomes '/path/more-path')
    const path = window.location.hash ? window.location.hash.substr(1).split('?')[0] : window.location.pathname;
    const newUrl = queryString ? path + '?' + queryString : path;
    window.history.replaceState(null, '', newUrl);
  }
}
export function axiosRequestInterceptor(config: IAxiosRequestConfig): IAxiosRequestConfig {
  const { headers, method, cacheName, localizedCacheKey, url } = config;
  headers['Arcade-Locale'] = localStorage.getItem(LocaleKey);
  if (method === 'get' && cacheName) {
    const cache = getCache(cacheName);
    const cacheKey = getCacheKey(url, localizedCacheKey);
    const cachedResponse = cache && cache.get(cacheKey);
    if (cachedResponse) {
      config.adapter = () => Promise.resolve({ ...cachedResponse, config }) as AxiosPromise;
    }
  }
  return config;
}

export function axiosResponseInterceptor(response: AxiosResponse): AxiosResponse {
  const { config }: { config: IAxiosRequestConfig } = response;
  if (config.method === 'get' && response.status < 300 && config.cacheName) {
    const cache = getCache(config.cacheName);
    if (cache) {
      const cacheKey = getCacheKey(config.url, config.localizedCacheKey);
      cache.put(cacheKey, response);
    }
  }
  return response;
}

export class Request {
  constructor($httpProvider: ng.IHttpProvider) {
    'ngInject';
    $httpProvider.defaults.xsrfHeaderName = 'Arcade-XSRF-Token';
    $httpProvider.defaults.xsrfCookieName = 'Arcade-XSRF-Token';
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.Accept = 'application/json';
    $httpProvider.defaults.headers.Origin = location.origin;

    const noCache = getNoCacheValue();

    $httpProvider.interceptors.push(
      ($cookies: ng.cookies.ICookiesService): ng.IHttpInterceptor => {
        'ngInject';
        return {
          request(config: ng.IRequestConfig): ng.IRequestConfig {
            config.headers['Arcade-XSRF-Token'] = $cookies.get('Arcade-XSRF-Token');
            config.headers['Arcade-Locale'] = localStorage.getItem(LocaleKey);
            if (noCache) {
              config.headers['Cache-Control'] = 'no-cache';
            }
            return config;
          },
        };
      },
    );
  }
}

export class LoadingBar {
  constructor(cfpLoadingBarProvider: ng.chieffancypants.LoadingBarProvider) {
    'ngInject';
    cfpLoadingBarProvider.includeSpinner = false;
    cfpLoadingBarProvider.parentSelector = '#sticky-nav sticky-content';
  }
}

export class DisableHttpCache {
  constructor($http: ng.IHttpService) {
    'ngInject';
    $http.defaults.cache = false;
  }
}

export class RxPromise {
  constructor($q: ng.IQService) {
    'ngInject';
    root.Rx = root.Rx || {};
    root.Rx.config = root.Rx.config || {};
    root.Rx.config.Promise = $q;
  }
}

export function initializeApi(): void {
  const noCache = getNoCacheValue();

  axios.defaults.xsrfHeaderName = 'Arcade-XSRF-Token';
  axios.defaults.xsrfCookieName = 'Arcade-XSRF-Token';
  axios.defaults.withCredentials = true;
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
  axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

  if (noCache) {
    axios.defaults.headers['Cache-Control'] = 'no-cache';
    window.sessionStorage.clear();
  }

  axios.interceptors.request.use(axiosRequestInterceptor);
  axios.interceptors.response.use(axiosResponseInterceptor);

  removeNoCacheValue();
}
