import React, { FunctionComponent } from 'react';
import { ICoverageSection } from './id-cards.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';

interface ISelectUserRadio extends IIdCardTrackedInputProps {
  changeSelectedUser: () => void;
}

interface IIdCardTrackedInputProps {
  user: IProfileUser;
  coverage: ICoverageSection;
  selectedUserUpdate?: (selectedUser: IProfileUser['dependentSeqNum']) => void;
}

const SelectUserRadio: FunctionComponent<ISelectUserRadio> = props => {
  const { user, coverage, changeSelectedUser, ...rest } = props;

  return (
    <input
      id={coverage.coverageType + '-radio-' + user.dependentSeqNum}
      type="radio"
      name="member-id"
      aria-hidden="true"
      checked={user.dependentSeqNum === coverage.selectedDepSeqNum}
      onChange={changeSelectedUser}
      {...rest}
    />
  );
};

export const IdCardTrackedInput: FunctionComponent<IIdCardTrackedInputProps> = props => {
  const { user, coverage, selectedUserUpdate } = props;

  const TrackedInput = withClickTracking(SelectUserRadio, coverage.coverageType + '-option-' + user.dependentSeqNum);

  return (
    <TrackedInput
      user={user}
      coverage={coverage}
      changeSelectedUser={() => {
        selectedUserUpdate(user.dependentSeqNum);
      }}
    />
  );
};
