import React, { useEffect, createRef } from 'react';
import { handleOnClick } from 'scripts/hoc/with-click-tracking/with-click-tracking';

export interface IHtmlElementProps extends React.HTMLAttributes<HTMLDivElement> {
  html: string;
}

const onAnchorClick = (e: MouseEvent): boolean => {
  handleOnClick((e as unknown) as React.MouseEvent<HTMLAnchorElement>);
  return true;
};

const HtmlElement: React.FunctionComponent<IHtmlElementProps> = ({ html, ...rest }) => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const anchorElements = ref.current.querySelectorAll('a');
    if (!anchorElements) {
      return;
    }

    Array.from(ref.current.querySelectorAll('a')).forEach((anchorEl: HTMLAnchorElement) => {
      anchorEl.addEventListener('click', onAnchorClick);
      anchorEl.setAttribute('data-track-id', anchorEl.textContent);
    });

    return () => {
      Array.from(ref.current.querySelectorAll('a')).forEach((anchorEl: HTMLAnchorElement) => {
        anchorEl.removeEventListener('click', onAnchorClick);
      });
    };
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: html }} ref={ref} {...rest} />;
};

export default HtmlElement;
