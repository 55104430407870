import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { applyPolyfills } from '@rally/advantage-components/loader';
applyPolyfills();

// Polyfills for @rally/arcade-ui-web-components
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js';

import 'custom-event-polyfill';
