import paginationTemplate from 'views/ui/pagination.html';

export class PaginationController<T> implements ng.IController {
  // Bindings
  public allObjects: T[];
  public pageSize: number;
  public remoteCtrl: PaginationController<T>;

  public currentPage: number;
  public displayedObjects: T[];
  public numberOfPages: number;
  private pageQueryParam = 'page';

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
  ) {
    'ngInject';
    this.remoteCtrl = this;
    this.pageSize = this.pageSize || 10;
  }

  public $onChanges(): void {
    this.numberOfPages = Math.ceil(this.allObjects.length / this.pageSize);
    this.setCurrentPage();
    this.displayedObjects = this.getPage();
  }

  public getFirstPosition(): number {
    return this.allObjects.length > 0 ? (this.currentPage - 1) * this.pageSize + 1 : 0;
  }

  public getLastPosition(): number {
    return this.allObjects.length > 0 ? this.getFirstPosition() + this.displayedObjects.length - 1 : 0;
  }

  public goBack(): void {
    this.currentPage--;
    this.displayedObjects = this.getPage();
    this.$state.go(
      this.$state.current,
      { [this.pageQueryParam]: this.currentPage },
      { location: 'replace', notify: false },
    );
    this.$timeout(() => this.scrollToTop());
  }

  public goForward(): void {
    this.currentPage++;
    this.displayedObjects = this.getPage();
    this.$state.go(
      this.$state.current,
      { [this.pageQueryParam]: this.currentPage },
      { location: 'replace', notify: false },
    );
    this.$timeout(() => this.scrollToTop());
  }

  public showBack(): boolean {
    return this.currentPage > 1;
  }

  public showForward(): boolean {
    return this.currentPage * this.pageSize < this.allObjects.length;
  }

  private getPage(): T[] {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = this.currentPage * this.pageSize;
    return this.allObjects.slice(start, end);
  }

  private scrollToTop(complete: () => void = () => undefined): void {
    this.$window.pageYOffset > 0 ? jQuery('html, body').animate({ scrollTop: 0 }, complete) : complete();
  }

  private setCurrentPage(): void {
    if (this.$stateParams.page) {
      if (this.$stateParams.page <= this.numberOfPages) {
        this.currentPage = this.$stateParams.page;
      } else {
        this.currentPage = 1;
        this.$state.go(
          this.$state.current,
          { [this.pageQueryParam]: this.currentPage },
          { location: 'replace', notify: false },
        );
      }
    } else {
      this.currentPage = 1;
    }
  }
}

export class PaginationComponent implements ng.IComponentOptions {
  public bindings = {
    allObjects: '<',
    pageSize: '<?',
    remoteCtrl: '=',
  };
  public controller: any;
  public controllerAs = '$pagination';
  public templateUrl: string;
  public transclude = true;

  constructor() {
    this.controller = PaginationController;
    this.templateUrl = paginationTemplate;
  }
}
