import 'angular-translate';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import {
  benefitsCoverage,
  benefitsCoverageMedical,
  connectDoctor,
  connectEmergent,
  connectSpecialist,
  connectUrgent,
  connectVirtualVisits,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { CoverageStatus, CoverageType } from '../../../api/api.interfaces';
import { IBenefitAmountService } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { ILocale } from '../../../util/locale/locale.interfaces';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { CostCopy, CostId } from '../../modals/cost-info/cost-info.interfaces';
import { CostInfoService } from '../../modals/cost-info/cost-info.service';
import { ICost, ICostColumn, ICostsController } from './costs.interfaces';

export class CostsController implements ICostsController {
  public costs: ICostColumn[];
  public locale: ILocale;
  public request: Observable<any>;
  public benefitsCoverage: IResource;
  public benefitsCoverageMedical: IResource;
  public showCopayDisclaimer: boolean;
  public isEmpire: boolean;
  private localeSubscription: Subscription;
  constructor(
    private $sce: ng.ISCEService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private costInfoService: CostInfoService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COSTS);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.TIPS_TO_SAVE);

    this.benefitsCoverage = benefitsCoverage;
    this.benefitsCoverageMedical = benefitsCoverageMedical;

    this.costs = [
      {
        title: 'DOCTOR_VISITS',
        helpLinkId: 'doctor-visits-help',
        helpLinkText: 'DOCTOR_VISITS_HELP',
        helpLinkUrl: 'modal.compareDoctorCare',
        costItems: [
          {
            id: CostId.doctor,
            name: CostCopy.costDoctor.title,
            guidedSearch: {
              id: 'medical',
              text: CostCopy.costDoctor.link,
              href: this.resourceService.get(connectDoctor),
            },
          },
          {
            id: CostId.specialist,
            name: CostCopy.costSpecialist.title,
            guidedSearch: {
              id: 'specialist',
              text: CostCopy.costSpecialist.link,
              href: this.resourceService.get(connectSpecialist),
            },
          },
        ],
      },
      {
        title: 'IMMEDIATE_CARE',
        helpLinkId: 'immediate-care-help',
        helpLinkText: 'IMMEDIATE_CARE_HELP',
        helpLinkUrl: 'modal.compareImmediateCare',
        costItems: [
          {
            id: CostId.urgent,
            name: CostCopy.costUrgent.title,
            guidedSearch: {
              id: 'facility',
              text: CostCopy.costUrgent.link,
              href: this.resourceService.get(connectUrgent),
            },
          },
          {
            id: CostId.emergency,
            name: CostCopy.costEmergency.title,
            guidedSearch: {
              id: 'emergency',
              text: CostCopy.costEmergency.link,
              href: this.resourceService.get(connectEmergent),
            },
          },
        ],
      },
    ];

    let isTieredPlan;
    const costsRequest = userService
      .getHeartbeat()
      .let(profileService.toProfile())
      .do(profile => {
        this.isEmpire = profile.data.currentUser.membershipCategory === MembershipCategory.EMPIRE;
        if (this.isEmpire) {
          this.costs[1].costItems = [this.costs[1].costItems[0]]; // We can't use pop because we can't guarantee this will run only once.
          this.costs[1].helpLinkId = '';
          this.costs[1].helpLinkText = '';
          this.costs[1].helpLinkUrl = '';
        }
      })
      .flatMap(profile =>
        this.plansService.getBenefits(profile.data.currentUser.rallyId, profile.data.currentUser.dependentSeqNum),
      )
      .do(rsp => {
        for (const b of rsp.data.benefits) {
          if (b.coverageType === CoverageType.Medical) {
            isTieredPlan = b.planFeatures.isTieredPlan;
            break;
          }
        }
      })
      .flatMap(() => Observable.from(this.costs))
      .flatMap(costCol => costCol.costItems)
      .flatMap(
        cost =>
          costInfoService
            .getCostsForService(cost.id)
            .map(({ inNetwork, tier1 }) => {
              if (isTieredPlan && cost.id !== CostId.virtual && tier1 && tier1.length > 0) {
                cost.isTier1 = true;

                const tier1WithReferralRequired = this.getWithReferralCosts(cost, tier1);
                if (tier1WithReferralRequired.length > 0 && this.featureFlagService.isReferralRequiredOn()) {
                  cost.showWithReferralDesc = true;

                  return costInfoService.getNonZeroCostMap(tier1WithReferralRequired);
                }

                return costInfoService.getNonZeroCostMap(tier1);
              } else {
                cost.isTier1 = false;

                const inNetworkWithReferralRequired = this.getWithReferralCosts(cost, inNetwork);
                if (inNetworkWithReferralRequired.length > 0 && this.featureFlagService.isReferralRequiredOn()) {
                  cost.showWithReferralDesc = true;

                  return costInfoService.getNonZeroCostMap(inNetworkWithReferralRequired);
                }

                return costInfoService.getNonZeroCostMap(inNetwork);
              }
            })
            .catch(() => Observable.of({})),
        (cost, costMap) => ({ cost, costMap }),
      )
      .do(({ cost, costMap }) => {
        cost.costInfoCopy = this.costInfoService.chooseCostInfoCopy(costMap, false, cost.id === CostId.virtual);
        cost.amounts = costMap;
        if (!this.showCopayDisclaimer) {
          this.showCopayDisclaimer = this.costInfoService.requiresCopayDisclaimer(cost.costInfoCopy);
        }
      })
      .last();

    const isVirtualEligible$: Observable<boolean> = userService
      .getHeartbeat()
      .let(profileService.toProfile())
      .flatMap(profile => profile.data.currentUser.planCoverages)
      .first(({ coverageType, planFeatures, planPeriod }) => {
        return (
          coverageType === CoverageType.Medical && planFeatures.isDVCN && planPeriod.status === CoverageStatus.Active
        );
      })
      .map(() => true);

    const hasVirtualService$: Observable<boolean> = costInfoService
      .getCostsForService(CostId.virtual)
      .map(info => info.inNetwork.length > 0);

    const virtualRequest = isVirtualEligible$
      .flatMap(isVirtualEligible => Observable.if(() => isVirtualEligible, hasVirtualService$, Observable.of(false)))
      .do(() => {
        const virtualCostItem = {
          id: CostId.virtual,
          name: CostCopy.costVirtual.title,
          guidedSearch: {
            id: 'virtual',
            text: CostCopy.costVirtual.link,
            href: this.resourceService.get(connectVirtualVisits),
          },
        };
        // Add virtual visits to each column. Both columns have length 2 so set VV item at index 2.
        this.costs[0].costItems[2] = virtualCostItem;
        this.costs[1].costItems[2] = virtualCostItem;
        this.costs[0].helpLinkText = 'DOCTOR_VISITS_HELP_VV';
        this.costs[1].helpLinkText = this.isEmpire ? '' : 'IMMEDIATE_CARE_HELP_VV';
      })
      .catch(() => Observable.of(false));

    this.request = virtualRequest.flatMap(() => costsRequest);

    this.localeSubscription = localeService.localeChanged.subscribe(() => {
      this.costs.forEach(cost =>
        cost.costItems.forEach(({ guidedSearch }) => {
          guidedSearch.href =
            guidedSearch.id === 'medical' ? this.resourceService.get(connectDoctor) : guidedSearch.href;
          guidedSearch.href =
            guidedSearch.id === 'specialist' ? this.resourceService.get(connectSpecialist) : guidedSearch.href;
          guidedSearch.href =
            guidedSearch.id === 'facility' ? this.resourceService.get(connectUrgent) : guidedSearch.href;
          guidedSearch.href =
            guidedSearch.id === 'emergency' ? this.resourceService.get(connectEmergent) : guidedSearch.href;
          guidedSearch.href =
            guidedSearch.id === 'virtual' ? this.resourceService.get(connectVirtualVisits) : guidedSearch.href;
        }),
      );
    });
  }

  public $onInit(): void {
    this.request.subscribe(() => undefined, console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private getWithReferralCosts(cost: ICost, currentCosts: IBenefitAmountService[]): IBenefitAmountService[] {
    if (cost.id === CostId.specialist) {
      return currentCosts.filter(c => !!c.referralRequired);
    }

    return [];
  }
}
