import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DeductibleOOP, BenefitNetwork, DEDUCTIBLE } from 'scripts/api/plans/plans.interfaces';
import { Accumulator } from 'scripts/ui/accumulator/accumulator';
import { IBenefitsSpending } from 'scripts/ui/accumulator/accumulator.interfaces';
import { AccumulatorLabelModalLink } from 'scripts/ui/accumulator/accumulator-label-modal-link';
import { NoAccumulator } from 'scripts/ui/accumulator/no-accumulator';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

const BenefitTranslationMap = {
  [DeductibleOOP.DEDUCTIBLE]: 'DEDUCTIBLE',
  [DeductibleOOP.OOP]: 'OOP_MAXIMUM',
  No_inNetwork_DEDUCTIBLE: 'NO_ACCUM_DEDUCTIBLE',
  No_inNetwork_OOP: 'NO_ACCUM_OOP',
  No_outOfNetwork_DEDUCTIBLE: 'NO_ACCUM_DEDUCTIBLE',
  No_outOfNetwork_OOP: 'NO_ACCUM_OOP',
};

interface IAccumulatorsListItemProps {
  accumulatorData: IBenefitsSpending;
  benefit: DeductibleOOP;
  disableLabelModal?: boolean;
  network: BenefitNetwork;
}

export const AccumulatorsListItem: FunctionComponent<IAccumulatorsListItemProps> = props => {
  const { accumulatorData, benefit, disableLabelModal, network } = props;
  const { t } = useTranslation([Dictionary.COMMON, Dictionary.ACCUMULATORS]);
  if (accumulatorData) {
    const { amount, max, type } = accumulatorData;
    const benefitLabelDictionary = benefit === DEDUCTIBLE ? Dictionary.COMMON : Dictionary.ACCUMULATORS;
    const benefitLabel = t(`${benefitLabelDictionary}:${BenefitTranslationMap[benefit]}`);
    const testId = `${network}-${type}`;
    const accumulatorLabel = disableLabelModal ? (
      benefitLabel
    ) : (
      <AccumulatorLabelModalLink benefit={benefit} modalLabel={benefitLabel} network={network} type={type} />
    );
    return (
      <li className="accumulators-list-item" key={`${testId}-${amount}`} data-testid={testId}>
        <Accumulator amount={amount} benefitNetwork={network} label={accumulatorLabel} max={max} />
      </li>
    );
  } else {
    const NoAccumMessage = BenefitTranslationMap[`No_${network}_${benefit}`];
    return (
      <li
        className="accumulators-list-item"
        key={`No-Accum-${network}-${benefit}`}
        data-testid={`No-Accum-${network}-${benefit}`}
      >
        <NoAccumulator noAccumMessage={t(`${Dictionary.ACCUMULATORS}:${NoAccumMessage}`)} />
      </li>
    );
  }
};
