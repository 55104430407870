import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import {
  appealDentalClaimsOffline,
  appealMedialClaimsOffline,
  appealMedicalClaimsOnline,
  claimReleaseInfo,
  claimReleaseInfoEmpire,
  instamedAccounts,
  instamedHelp,
  instamedPayments,
} from 'scripts/util/resource/resource.constants';
import additionalLinksTemplate from 'views/claims-and-accounts/summary/additional-links.html';
import { CoverageType, ILink, LinkTarget, RelationshipType } from 'scripts/api/api.interfaces';
import { IProfileUser, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { hasPayNowSuppression } from 'scripts/util/targeting/targeting';
import { isEmpire } from 'scripts/util/user/user';
import { isExpatriate } from 'scripts/util/user/user';

export interface IAdditionalLinksSection {
  headline: string;
  body?: string;
  links: IAdditionalLink[];
  show?: boolean;
}

export interface IAdditionalLink extends ILink {
  tag?: CoverageType;
  show?: boolean;
  getLink: () => string;
  getShow?: (currentUser: IProfileUser, clientConfig: IClientConfig) => boolean;
}

export class AdditionalLinksController implements ng.IComponentController {
  public additionalLinkSections: IAdditionalLinksSection[];
  private clientConfigReq: Observable<IClientConfig>;
  private isEmpire: boolean;
  private localeSubscription: Subscription;
  private onlineMedialAppeals: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $state: angular.ui.IStateService,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    this.$translatePartialLoader.addPart(Dictionary.ADDITIONAL_LINKS);
    this.$translatePartialLoader.addPart(Dictionary.COMMON);
    this.clientConfigReq = userService
      .getHeartbeat()
      .flatMap(({ data }) => targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => {
        this.onlineMedialAppeals = clientConfig && clientConfig.suppressions.onlineMedicalAppeals;
      });

    this.additionalLinkSections = [
      {
        headline: 'CLAIM_LETTERS',
        body: 'CLAIM_LETTERS_BODY',
        links: [
          {
            text: 'VIEW_ALL_CLAIM_LETTERS',
            getShow: (currentUser: IProfileUser) =>
              currentUser.relationshipType === RelationshipType.Subscriber &&
              currentUser.membershipCategory !== MembershipCategory.OXFORD,
            getLink: () => this.$state.href('authenticated.claimsAndAccounts.claimLetters'),
          },
        ],
      },
      {
        headline: 'FORMS_HEADER',
        links: [
          {
            tag: CoverageType.Medical,
            text: 'FORMS_APPEAL_MEDICAL',
            getLink: () => {
              return this.resourceService.get(
                this.onlineMedialAppeals ? appealMedicalClaimsOnline : appealMedialClaimsOffline,
              );
            },
          },
          {
            tag: CoverageType.Dental,
            text: 'FORMS_APPEAL_DENTAL',
            getLink: () => this.resourceService.get(appealDentalClaimsOffline),
          },
          {
            text: 'FORMS_RELEASE_INFO',
            target: LinkTarget.Blank,
            getLink: () => this.resourceService.get(this.isEmpire ? claimReleaseInfoEmpire : claimReleaseInfo),
            getShow: (currentUser: IProfileUser) => !isExpatriate(currentUser),
          },
        ],
      },
      {
        headline: 'QUICK_LINKS_HEADER',
        links: [
          {
            text: 'QUICK_LINKS_PAYMENTS',
            target: LinkTarget.Blank,
            getLink: () => this.resourceService.get(instamedPayments),
            getShow: (currentUser: IProfileUser, clientConfig: IClientConfig) =>
              this.profileService.hasInstamed(currentUser) && !hasPayNowSuppression(clientConfig),
          },
          {
            text: 'QUICK_LINKS_ACCOUNTS',
            target: LinkTarget.Blank,
            getLink: () => this.resourceService.get(instamedAccounts),
            getShow: (currentUser: IProfileUser, clientConfig: IClientConfig) =>
              this.profileService.hasInstamed(currentUser) && !hasPayNowSuppression(clientConfig),
          },
          {
            text: 'QUICK_LINKS_PAYMENT_HELP',
            target: LinkTarget.Blank,
            getLink: () => this.resourceService.get(instamedHelp),
            getShow: (currentUser: IProfileUser, clientConfig: IClientConfig) =>
              this.profileService.hasInstamed(currentUser) && !hasPayNowSuppression(clientConfig),
          },
        ],
      },
    ];
  }

  public $onInit(): void {
    Observable.zip(
      this.userService
        .getHeartbeat()
        .let(this.profileService.toProfile())
        .map(rsp => rsp.data.currentUser),
      this.clientConfigReq,
    ).subscribe(([currentUser, clientConfig]) => {
      const info = ProfileService.getCoverageInfo(currentUser.planCoverages);
      this.isEmpire = isEmpire(currentUser);
      this.additionalLinkSections.forEach(section => {
        section.links.forEach(link => {
          if (link.tag) {
            link.show = info.coverageTypes[link.tag];
          }
          if (link.getShow) {
            link.show = link.getShow(currentUser, clientConfig);
          }
        });

        section.show = section.links.some(link => link.show);
      });
      this.setLinks();
    }, console.warn);

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.setLinks();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private setLinks(): void {
    this.additionalLinkSections.forEach(section => {
      section.links.forEach(link => {
        link.href = link.getLink();
      });
    });
  }
}

export class AdditionalLinksComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AdditionalLinksController;
    this.templateUrl = additionalLinksTemplate;
  }
}
