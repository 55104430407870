import { constructParams, parse } from 'scripts/util/uri/uri';
import React, { FunctionComponent, MouseEvent } from 'react';
import { Anchor } from '../anchor/anchor';
import { connect } from 'react-redux';
import { getInstamedUrl } from 'scripts/thunks/claims-service-thunks';
import { getResource } from 'scripts/util/resource/resource';
import { Idp } from 'scripts/api/user/user.interfaces';
import { instamedPayNow } from 'scripts/util/resource/resource.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectPopulation } from 'scripts/selectors/population-selectors';
import { selectSessionIdp } from 'scripts/selectors/user-service-selectors';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

const TrackedAnchor = withClickTracking(Anchor, 'pay-now');

interface IClaimPayNowAnchorLinkProps
  extends IClaimPayNowAnchorLinkStateToProps,
    IClaimPayNowAnchorLinkDispatchToProps {
  claimPayKey: string;
  payNowMsg: string;
}

interface IClaimPayNowAnchorLinkStateToProps {
  population: IPopulation;
  sessionIdp: Idp;
}

interface IClaimPayNowAnchorLinkDispatchToProps {
  getInstamedUrl: (claimPayKey: string, returnUrl: string) => Promise<string>;
}

export const RawClaimPayNowAnchorLink: FunctionComponent<IClaimPayNowAnchorLinkProps> = props => {
  const { claimPayKey, getInstamedUrl, payNowMsg, population, sessionIdp } = props;
  const { location } = useRouter();
  const isOfflineWeb = sessionIdp === Idp.OfflineWeb;

  const getReturnUrl = (useFilters = false): string => {
    const currentParams = new URLSearchParams(location.search);
    const params = {};
    currentParams.forEach((value, key) => (params[key] = value));
    const paramsWithoutFilters = {
      ...params,
      filters: undefined,
      useSessionFilters: useFilters ? 1 : undefined,
    };
    const filterlessCurrentUrl = `${location.pathname}${constructParams(paramsWithoutFilters)}`;
    const returnStateUrl = `/claims-and-accounts/claim-paid${constructParams({ goTo: filterlessCurrentUrl })}`;
    return parse(returnStateUrl).href;
  };

  const determineHref = (useFilters = false): string => {
    const returnUrl = getReturnUrl(useFilters);
    return `${getResource(instamedPayNow, population)}${claimPayKey}&RETURN_URL=${encodeURIComponent(returnUrl)}`;
  };

  const handlePayNowClick = async (event: MouseEvent): Promise<void> => {
    event.preventDefault();
    const currentParams = new URLSearchParams(location.search);
    const hasFilters = currentParams.has('filters');

    let href: string;
    if (isOfflineWeb) {
      href = await getInstamedUrl(claimPayKey, getReturnUrl(hasFilters));
    } else {
      href = determineHref(hasFilters);
    }

    if (hasFilters) {
      const filters = currentParams.get('filters');
      window.sessionStorage.setItem('arcade.claim.filters', filters);
    }

    if (href) {
      window.open(href);
    }
  };

  return (
    <TrackedAnchor
      className="secondary-btn claim-pay-now"
      data-testid="claim-pay-now-anchor-link"
      href={isOfflineWeb ? '#' : determineHref()}
      noRel={true}
      onClick={handlePayNowClick}
      target="_blank"
    >
      {payNowMsg}
    </TrackedAnchor>
  );
};

const mapStateToProps = (state: IReduxState): IClaimPayNowAnchorLinkStateToProps => {
  return {
    population: selectPopulation(state),
    sessionIdp: selectSessionIdp(state),
  };
};

export const ClaimPayNowAnchorLink = connect(
  mapStateToProps,
  { getInstamedUrl },
)(RawClaimPayNowAnchorLink);
