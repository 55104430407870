import angular from 'angular';
import modalTemplate from 'views/ui/modal.html';
import { ModalController } from './modal.controller';
import { IModalController } from './modal.interfaces';

export class ModalDirective implements ng.IDirective {
  public restrict = 'E';
  public transclude = {
    title: '?transcludeTitle',
    notifications: '?transcludeNotifications',
  };
  public scope = {};
  public controller = ModalController;
  public controllerAs = '$modalCtrl';
  public bindToController = {
    fullscreen: '<',
    confirm: '<',
    transcludeNotifications: '<',
    transcludeTitle: '<',
    modalTitle: '@',
    modalSubtitle: '@',
    modalSecondarySubtitle: '@',
    titleImgSrc: '@',
    bindCtrlTo: '=?',
    customCloseModal: '<?',
  };

  constructor(private $rootScope: ng.IRootScopeService, private $timeout: ng.ITimeoutService) {}
  public templateUrl = (element: ng.IAugmentedJQuery, attrs: ng.IAttributes): string => {
    if (attrs.templateUrl) {
      return attrs.templateUrl;
    } else {
      return modalTemplate;
    }
  };

  public link = (
    scope: ng.IScope,
    element: ng.IAugmentedJQuery,
    attrs: ng.IAttributes,
    $modalCtrl: IModalController,
  ): void => {
    const hideModalEvent = (e: JQueryEventObject): void => {
      const modalContainer = element.children()[0];
      const target = e.target;
      if (!$modalCtrl.fullscreen && target === modalContainer) {
        scope.$evalAsync(() => $modalCtrl.close());
        angular.element(document.body).off('mouseup', hideModalEvent);
      }
    };

    const keydownEvent = (e: JQueryEventObject): void => {
      switch (e.keyCode) {
        case 27: // esc key
          scope.$evalAsync(() => $modalCtrl.close());
          break;
        case 9: // tab key
          $modalCtrl.trapFocus(e);
          break;
      }
    };

    $modalCtrl.bindCtrlTo = $modalCtrl;

    angular.element(window as any).on('keydown', keydownEvent);
    angular.element(document.body).on('mouseup', hideModalEvent);

    scope.$on('$destroy', () => {
      $modalCtrl.destroy();
      angular.element(window as any).off('keydown', keydownEvent);
      angular.element(document.body).off('mouseup', hideModalEvent);
    });

    // Set the focus onto the first visible and/or tabbable element
    this.$timeout(() => {
      $modalCtrl.setFirstFocus();
    });
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($rootScope: ng.IRootScopeService, $timeout: ng.ITimeoutService) => {
      'ngInject';
      return new ModalDirective($rootScope, $timeout);
    };
    return directive;
  }
}
