import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IClaim, ClaimStatus } from 'scripts/api/claims/claims.interfaces';
import { getYouMayOweAmount, getYouPaidAmount } from 'scripts/util/claims/claims';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { ClaimBalance } from './claim-balance';

export interface IClaimBalancesHealthcareProps {
  claim: IClaim;
}

export const ClaimBalancesHealthcare: FunctionComponent<IClaimBalancesHealthcareProps> = props => {
  const { claim } = props;
  const {
    balance: { totalBilledAmount, healthPlanDiscount, healthPlanPays },
  } = claim;
  const { t } = useTranslation(Dictionary.COMMON);
  const youMayOweAmount = claim.claimManagementInfo.markPaid ? 0 : getYouMayOweAmount(claim);
  const youPaidAmount = getYouPaidAmount(claim);
  return (
    <div className="claim-balances" data-testid="claim-balances-healthcare">
      <ClaimBalance className="claim-amount-billed" label={t('AMOUNT_BILLED')} value={totalBilledAmount.value} />
      <ClaimBalance className="claim-plan-discount" label={t('PLAN_DISCOUNT')} value={healthPlanDiscount.value} />
      {(claim.claimStatus !== ClaimStatus.InProcess || healthPlanPays) && (
        <ClaimBalance
          className="claim-plan-pays"
          label={t('PLAN_PAID')}
          value={healthPlanPays ? healthPlanPays.value : 0}
        />
      )}
      <ClaimBalance className="claim-you-paid" label={t('YOU_PAID')} value={youPaidAmount} />
      <ClaimBalance className="claim-you-owe" label={t('YOU_MAY_OWE')} value={youMayOweAmount} />
    </div>
  );
};
