import classNames from 'classnames';
import React, { Component, forwardRef, ReactElement, Ref } from 'react';
import { Translation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Anchor } from '../anchor/anchor';
import { IDropdownOption } from './dropdown.interfaces';

interface IDropdownListItemProps {
  dataTrackId?: string;
  dictionary: string;
  nav?: boolean;
  onClick: () => void;
  option: IDropdownOption;
  selected: boolean;
}

interface IListItemProps extends IDropdownListItemProps {
  forwardedRef: Ref<HTMLLIElement | HTMLAnchorElement>;
}

class ListItem extends Component<IListItemProps> {
  public render(): ReactElement<IListItemProps> {
    const { dictionary, onClick, option, selected, forwardedRef, ...rest } = this.props;
    return (
      <li
        className={classNames('dropdown-option', option.className, { selected })}
        onClick={onClick}
        ref={forwardedRef as Ref<HTMLLIElement>}
        role="button"
        tabIndex={0}
        {...rest}
      >
        <Translation ns={dictionary}>{t => t(option.label)}</Translation>
      </li>
    );
  }
}

const ListItemWithTracking = withClickTracking(ListItem, 'option');

class AnchorItem extends Component<IListItemProps> {
  public render(): ReactElement<IListItemProps> {
    const { dictionary, onClick, option, selected, forwardedRef, ...rest } = this.props;
    return (
      <li className={classNames('dropdown-option', option.className, { selected })}>
        <Anchor
          onClick={onClick}
          href={option.value}
          ref={forwardedRef as Ref<HTMLAnchorElement>}
          target={option.target}
          {...rest}
        >
          <Translation ns={dictionary}>{t => t(option.label)}</Translation>
        </Anchor>
      </li>
    );
  }
}

const AnchorItemWithTracking = withClickTracking(AnchorItem, 'option');

export const DropdownListItem = forwardRef(
  (props: IDropdownListItemProps, ref: Ref<HTMLLIElement | HTMLAnchorElement>) => {
    const { nav, option, ...rest } = props;
    const ListItemElement = nav ? AnchorItemWithTracking : ListItemWithTracking;
    return <ListItemElement forwardedRef={ref} dataTrackId={`option-${option.label}`} option={option} {...rest} />;
  },
);
