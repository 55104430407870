import { TrackController } from './track.controller';
import { ITrackAttrs, ITrackController } from './track.interfaces';

export class TrackFeatureDirective implements ng.IDirective {
  public scope = false;
  public controller = TrackController;
  public controllerAs = '$track';
  public bindToController = {
    trackFeature: '<',
  };
  public static Factory(): ng.IDirectiveFactory {
    return () => new TrackFeatureDirective();
  }
}

export class TrackDirective implements ng.IDirective {
  public scope = false;
  public controller = TrackController;
  public controllerAs = '$track';
  public bindToController = {
    actionName: '@track',
    clickType: '@?trackClickType', // only specified in rare cases to override default logic in controller
    placement: '@?trackPlacement',
    campaign: '<?trackCampaign',
    isInView: '=?trackCampaignIsInView',
  };

  constructor(private $timeout: ng.ITimeoutService) {}

  public link = (
    scope: ng.IScope,
    element: ng.IAugmentedJQuery,
    attrs: ITrackAttrs,
    $track: ITrackController,
  ): void => {
    const isInView =
      (element as any).is(':visible') &&
      (!Object.prototype.hasOwnProperty.call(attrs, 'trackCampaignIsInView') ||
        scope.$eval(attrs.trackCampaignIsInView));
    if (isInView && Object.prototype.hasOwnProperty.call(attrs, 'trackCampaign')) {
      $track.queueEvent(element, true);
    }

    if (!isInView) {
      const isInViewWatcher = scope.$watch('$track.isInView', inView => {
        if (inView) {
          this.$timeout(() => {
            if ((element as any).is(':visible')) {
              $track.queueEvent(element, true);
              isInViewWatcher();
            }
          });
        }
      });
    }

    element.on('click', () => {
      const elTag = element[0].tagName.toLowerCase();
      if (elTag === 'a' && attrs.uiSref === undefined) {
        $track.sendAndFlushEvents(element);
      } else {
        $track.queueEvent(element);
      }
    });
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($timeout: ng.ITimeoutService) => {
      'ngInject';
      return new TrackDirective($timeout);
    };
    return directive;
  }
}
