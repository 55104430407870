import { Observable } from 'rxjs/Observable';
import { IClaim, IGetMatchingClaimParams, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import { ClaimsService } from 'scripts/api/claims/claims.service';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService, LedgerService } from 'scripts/api/ledger/ledger.service';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { ArcadeWebTheme, IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { instamedPayNow } from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { parse } from 'scripts/util/uri/uri';
import claimPayNowTemplate from 'views/ui/claim-pay-now.html';

export class ClaimPayNowController implements ng.IComponentController {
  // Bindings
  public claim: IClaim | IHealthcareClaimDetails;
  public hasHSA: boolean;
  private accountsReq: Observable<ILedgerAccount[]>;
  private clientConfigReq: Observable<IClientConfig>;
  private currentUserReq: Observable<IProfileUser>;
  private hasInstamed: boolean;
  private payNowSuppression: boolean;
  private rallypayRolloutEnabledSuppression: boolean;

  constructor(
    public $location: ng.ILocationService,
    private $state: ng.ui.IStateService,
    private $stateParams: IGetMatchingClaimParams,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.accountsReq = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(currentUser => this.ledgerService.getAccounts(currentUser.rallyId))
      .map(({ data }) => data.filter(account => account.isActive))
      .do(accounts => (this.hasHSA = accounts.some(account => LedgerService.isHsaAccount(account))));

    this.clientConfigReq = this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => {
        if (clientConfig && clientConfig.suppressions) {
          const { payNowSuppression, rallypayRolloutEnabled } = clientConfig.suppressions;
          this.payNowSuppression = payNowSuppression;
          this.rallypayRolloutEnabledSuppression = rallypayRolloutEnabled;
        }
      });

    this.currentUserReq = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => (this.hasInstamed = this.profileService.hasInstamed(currentUser)));
  }

  public $onInit(): void {
    this.accountsReq.subscribe(() => undefined, console.warn);
    this.clientConfigReq.subscribe(() => undefined, console.warn);
    this.currentUserReq.subscribe(() => undefined, console.warn);
  }

  public showPayNow(claim: IClaim | IHealthcareClaimDetails): boolean {
    return ClaimsService.showPayNow(claim, this.$stateParams.dependentSeqNbr) && !this.suppressPayNow();
  }

  public showPayNowModal(): boolean {
    const isShowModalFeatureFlagOn = this.featureFlagService.isPayNowModalOn();
    const isTargetingRolloutFeatureFlagOn = this.featureFlagService.isPayNowModalTargetingRolloutOn();
    const showModalBasedOnTargeting = isTargetingRolloutFeatureFlagOn && this.rallypayRolloutEnabledSuppression;

    return isShowModalFeatureFlagOn && this.hasHSA && (!isTargetingRolloutFeatureFlagOn || showModalBasedOnTargeting);
  }

  public showPayNowInstamed(): boolean {
    return this.Environment.CONFIG.ARCADE_WEB_THEME !== ArcadeWebTheme.Advantage;
  }

  public goPayNow(claim: IClaim | IHealthcareClaimDetails): void {
    const url = this.resourceService.get(instamedPayNow);

    // Remove filters and store them in session storage because of the Instamed requirements that the RETURN_URL cannot be "too" long
    let params;
    if (this.$stateParams.filters) {
      params = { filters: undefined, useSessionFilters: true };
      this.$window.sessionStorage.setItem('arcade.claim.filters', this.$stateParams.filters);
    }
    const filterlessCurrentUrl = this.$state.href(this.$state.current.name, params);

    const returnStateUrl = this.$state.href('authenticated.claimsAndAccounts.claimPaid', {
      goTo: filterlessCurrentUrl,
    });

    const returnUrl = parse(returnStateUrl).href;
    this.$window.open(`${url}${claim.claimPayKey}&RETURN_URL=${encodeURIComponent(returnUrl)}`);
  }

  private suppressPayNow(): boolean {
    return this.payNowSuppression || !this.hasInstamed;
  }
}

export class ClaimPayNowComponent implements ng.IComponentOptions {
  public controller = ClaimPayNowController;
  public templateUrl = claimPayNowTemplate;
  public bindings = {
    claim: '<',
  };
}
