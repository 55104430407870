import React, { FunctionComponent, useEffect, HTMLAttributes } from 'react';
import { Feature } from 'scripts/ui/feature/feature';
import { trackFeatures } from 'scripts/util/constants/track-features';
import {
  IProfileUser,
  IReferralsPerMember,
  IPrimaryCarePerMember,
  IPrimaryCareFpc,
  FpcPcpType,
  PhoneType,
  IPrimaryCareUserInfo,
  PcpType,
  PcpMemberState,
  IPrimaryCareFpcPhysician,
  IPrimaryCarePhysician,
} from 'scripts/api/profile/profile.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { connect } from 'react-redux';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import {
  selectedUser,
  selectReferralsError,
  selectReferralsLoading,
  selectReferralsData,
  currentUser,
  selectPrimaryCareLoading,
  selectPrimaryCareError,
  selectPrimaryCareData,
  selectFpcPrimaryCareData,
  selectFpcPrimaryCareLoading,
  selectFpcPrimaryCareError,
} from 'scripts/selectors/profile-service-selectors';
import { getFpcPrimaryCare, getPrimaryCare, getReferrals } from 'scripts/thunks/profile-service-thunks';
import { isTermedForCoverageType, getFullName } from 'scripts/util/profile/profile';
import { CoverageType, CoverageStatus } from 'scripts/api/api.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { PcpStripHeader, IPcpStripHeaderProps } from './pcp-strip-header';
import { PcpStripFindProvidersTile } from './pcp-strip-find-providers-tile';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { PcpStripPrimaryCareProviderTile } from './pcp-strip-primary-care-provider-tile';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { isOxford, isCS, isDsnp } from 'scripts/util/user/user';

interface IPcpStripProps extends IPcpStripStateToProps, IPcpStripDispatchToProps {}

interface IPcpStripStateToProps {
  currentUser: IProfileUser;
  selectedUser: IProfileUser;
  fpcPrimaryCareError: boolean;
  fpcPrimaryCareLoading: boolean;
  fpcPrimaryCare: IPrimaryCareFpc;
  primaryCareError: boolean;
  primaryCareLoading: boolean;
  primaryCarePerMember: IPrimaryCarePerMember;
  referralsError: boolean;
  referralsLoading: boolean;
  referralsPerMember: IReferralsPerMember;
  population: IPopulation;
}

interface IPcpStripDispatchToProps {
  getFpcPrimaryCare: () => void;
  getPrimaryCare: () => void;
  getReferrals: () => void;
}

export interface ISelectedUserPrimaryCareInfo {
  activeDocId?: string;
  activeDocName?: string;
  hasFuturePcp?: boolean;
  phoneNumber?: string;
  lockedIn?: boolean;
  memberState?: PcpMemberState;
}

export enum PcpTileStatusText {
  Assign = 'ASSIGN',
  AssignmentInProgress = 'ASSIGNMENT_IN_PROGRESS',
  Change = 'CHANGE',
  ChangeInProgress = 'CHANGE_IN_PROGRESS',
}

const getFpcPrimaryCareInfo = (fpcPrimaryCareInfo: IPrimaryCareFpc): ISelectedUserPrimaryCareInfo => {
  if (fpcPrimaryCareInfo) {
    const isActivePcpWithMedicalFpcPcpType = (pcp: IPrimaryCareFpcPhysician): boolean =>
      pcp.status === CoverageStatus.Active && pcp.pcpType === FpcPcpType.Medical;
    const activePcp = fpcPrimaryCareInfo.doctorDetails.find(isActivePcpWithMedicalFpcPcpType);
    const futurePcp = fpcPrimaryCareInfo.doctorDetails.find(pcp => pcp.status === CoverageStatus.Future);
    const hasFuturePcp = futurePcp !== undefined;
    const lockedIn = fpcPrimaryCareInfo.lockedIn;

    if (activePcp) {
      const activeDocId = activePcp.pcpId.split(' ').join('%7C'); // encode any spaces
      const firstPhoneNumber = activePcp.phones.filter(phone => phone.phoneType === PhoneType.phone)[0];
      return {
        activeDocId,
        activeDocName: getFullName(activePcp),
        hasFuturePcp,
        lockedIn,
        phoneNumber: firstPhoneNumber && firstPhoneNumber.number,
      };
    } else if (futurePcp) {
      return {
        hasFuturePcp,
        lockedIn,
      };
    }
  }

  return {};
};

const getPrimaryCareInfo = (
  selectedUsersPcpInfo: IPrimaryCareUserInfo,
  isCS: boolean,
  isOxford: boolean,
): ISelectedUserPrimaryCareInfo => {
  const lockedIn = selectedUsersPcpInfo && selectedUsersPcpInfo.lockedIn;
  const memberState = selectedUsersPcpInfo && selectedUsersPcpInfo.memberState;
  if (selectedUsersPcpInfo && selectedUsersPcpInfo.primaryCarePhysicians) {
    const isActivePcpWithValidPcpType = (pcp: IPrimaryCarePhysician): boolean =>
      pcp.status === CoverageStatus.Active &&
      (FeatureFlagService.isPcpGroupOrFacilityNameOn()
        ? [PcpType.Physician, PcpType.Facility, PcpType.Group].indexOf(pcp.pcpType) > -1
        : pcp.pcpType === PcpType.Physician);
    const activePcp = selectedUsersPcpInfo.primaryCarePhysicians.find(isActivePcpWithValidPcpType);
    const futurePcp = selectedUsersPcpInfo.primaryCarePhysicians.find(pcp => pcp.status === CoverageStatus.Future);
    const hasFuturePcp = futurePcp !== undefined;

    if (activePcp) {
      const activeDocId =
        isCS || isOxford
          ? activePcp.providerId.toString()
          : `${activePcp.providerId}%7C${activePcp.addressSequenceNumber}`;
      return {
        activeDocId,
        activeDocName: getFullName(activePcp),
        hasFuturePcp,
        lockedIn,
        memberState,
      };
    } else if (futurePcp) {
      return {
        hasFuturePcp,
        lockedIn,
      };
    }
  }

  return {
    lockedIn,
    memberState,
  };
};

const getPcpStatusText = (selectedUserPrimaryCareInfo: ISelectedUserPrimaryCareInfo): PcpTileStatusText => {
  if (selectedUserPrimaryCareInfo.activeDocName && selectedUserPrimaryCareInfo.hasFuturePcp) {
    return PcpTileStatusText.ChangeInProgress;
  } else if (selectedUserPrimaryCareInfo.activeDocName) {
    return selectedUserPrimaryCareInfo.memberState &&
      selectedUserPrimaryCareInfo.memberState === PcpMemberState.PcpChangeInProgress
      ? PcpTileStatusText.ChangeInProgress
      : PcpTileStatusText.Change;
  } else if (selectedUserPrimaryCareInfo.hasFuturePcp) {
    return PcpTileStatusText.AssignmentInProgress;
  } else {
    return selectedUserPrimaryCareInfo.memberState &&
      selectedUserPrimaryCareInfo.memberState === PcpMemberState.PcpChangeInProgress
      ? PcpTileStatusText.AssignmentInProgress
      : PcpTileStatusText.Assign;
  }
};

const Div: FunctionComponent<HTMLAttributes<HTMLDivElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

export const RawPcpStrip: FunctionComponent<IPcpStripProps> = props => {
  const {
    currentUser,
    selectedUser,
    fpcPrimaryCare,
    fpcPrimaryCareError,
    fpcPrimaryCareLoading,
    primaryCarePerMember,
    primaryCareError,
    primaryCareLoading,
    referralsPerMember,
    referralsError,
    population,
  } = props;

  const isTermedMedical = isTermedForCoverageType(CoverageType.Medical, selectedUser.planCoverages);
  const isPcpEligibleAkaGated = isTermedMedical ? true : selectedUser.memberFeatures.pcpEligible;

  // TODO: to be used in PCD tile
  // const isPcdEligible = selectedUser.memberFeatures.pcdEligible;
  // const dentalCoverage = getCoverage(CoverageType.Dental, selectedUser.planCoverages);

  const savedProviders = [];

  const checkForFpcPrimaryCareResponseAndMakeGetCall = (): void => {
    if (!fpcPrimaryCare) {
      props.getFpcPrimaryCare();
    }
  };
  const checkForPrimaryCareResponseAndMakeGetCall = (): void => {
    if (!primaryCarePerMember) {
      props.getPrimaryCare();
    }
  };

  const setSelectedUsersPrimaryCareInfo = (): ISelectedUserPrimaryCareInfo => {
    if (isPcpEligibleAkaGated && FeatureFlagService.isFpcPcpForGatedOn() && fpcPrimaryCare && primaryCarePerMember) {
      const optumPrimaryCareInfo = getPrimaryCareInfo(
        primaryCarePerMember[selectedUser.dependentSeqNum],
        isCS(selectedUser),
        isOxford(selectedUser),
      );
      const fpcPrimaryCareInfo = getFpcPrimaryCareInfo(fpcPrimaryCare);
      return {
        ...fpcPrimaryCareInfo,
        lockedIn: fpcPrimaryCareInfo.lockedIn || optumPrimaryCareInfo.lockedIn,
        memberState: optumPrimaryCareInfo.memberState,
      };
    } else if (isPcpEligibleAkaGated && primaryCarePerMember) {
      return getPrimaryCareInfo(
        primaryCarePerMember[selectedUser.dependentSeqNum],
        isCS(selectedUser),
        isOxford(selectedUser),
      );
    } else if (fpcPrimaryCare) {
      return getFpcPrimaryCareInfo(fpcPrimaryCare);
    }
  };

  const error =
    isPcpEligibleAkaGated && FeatureFlagService.isFpcPcpForGatedOn()
      ? primaryCareError && fpcPrimaryCareError
      : isPcpEligibleAkaGated
      ? primaryCareError
      : fpcPrimaryCareError;

  const loading =
    isPcpEligibleAkaGated && FeatureFlagService.isFpcPcpForGatedOn()
      ? primaryCareLoading && fpcPrimaryCareLoading
      : isPcpEligibleAkaGated
      ? primaryCareLoading
      : fpcPrimaryCareLoading;

  const getPrimaryCareData = (): void => {
    if (isPcpEligibleAkaGated && FeatureFlagService.isFpcPcpForGatedOn()) {
      checkForFpcPrimaryCareResponseAndMakeGetCall();
      checkForPrimaryCareResponseAndMakeGetCall();
    } else if (isPcpEligibleAkaGated) {
      checkForPrimaryCareResponseAndMakeGetCall();
    } else {
      checkForFpcPrimaryCareResponseAndMakeGetCall();
    }
  };

  const loadDependencies = (): void => {
    getPrimaryCareData();

    if (!referralsPerMember || !referralsError) {
      props.getReferrals();
    }
  };
  useEffect(loadDependencies, []);

  const selectedUserPrimaryCareInfo = setSelectedUsersPrimaryCareInfo();
  const primaryCareStatusText = selectedUserPrimaryCareInfo && getPcpStatusText(selectedUserPrimaryCareInfo);
  const isTermedMedicalWithExistingSavedPcp =
    isTermedMedical &&
    !(
      primaryCareStatusText === PcpTileStatusText.Change || primaryCareStatusText === PcpTileStatusText.ChangeInProgress
    );
  const showPrimaryCareProviderTile =
    selectedUserPrimaryCareInfo && (!isDsnp(selectedUser) || !isTermedMedicalWithExistingSavedPcp);
  const showFindProvidersTile = savedProviders.length === 0 && !isTermedMedical;

  const pcpStripHeaderProps: IPcpStripHeaderProps = {
    currentUser,
    isTermedMedical,
    population,
    referralsError,
    referralsPerMember,
    savedProviders,
    selectedUser,
  };

  // TODO: update waitFor error/loading with pcd tile, trackData upon render for pcd tile
  return (
    <Feature featureId={trackFeatures.pcpAndSaved}>
      <div className="dashboard-pcp container">
        <PcpStripHeader {...pcpStripHeaderProps} />
        <DivWithWaitFor error={error} loading={loading} onRetry={getPrimaryCareData} className="provider-row">
          {showPrimaryCareProviderTile && (
            <PcpStripPrimaryCareProviderTile
              isCS={isCS(selectedUser)}
              isDsnp={isDsnp(selectedUser)}
              isOxford={isOxford(selectedUser)}
              isTermedMedical={isTermedMedical}
              population={population}
              primaryCareInfo={selectedUserPrimaryCareInfo}
              statusText={primaryCareStatusText}
            />
          )}
          {showFindProvidersTile && <PcpStripFindProvidersTile population={population} />}
        </DivWithWaitFor>
      </div>
    </Feature>
  );
};

const mapStateToProps = (state: IReduxState): IPcpStripStateToProps => ({
  currentUser: currentUser.selectProfile(state),
  selectedUser: selectedUser.selectProfile(state),
  fpcPrimaryCareError: selectFpcPrimaryCareError(state),
  fpcPrimaryCareLoading: selectFpcPrimaryCareLoading(state),
  fpcPrimaryCare: selectFpcPrimaryCareData(state),
  primaryCareError: selectPrimaryCareError(state),
  primaryCareLoading: selectPrimaryCareLoading(state),
  primaryCarePerMember: selectPrimaryCareData(state),
  referralsError: selectReferralsError(state),
  referralsLoading: selectReferralsLoading(state),
  referralsPerMember: selectReferralsData(state),
  population: state.population,
});

export const PcpStrip = withProvider(
  connect(
    mapStateToProps,
    { getFpcPrimaryCare, getPrimaryCare, getReferrals },
  )(RawPcpStrip),
);
