import angular from 'angular';

export class StickyDirective implements ng.IDirective {
  public restrict = 'E';
  public transclude = true;
  public template = '<sticky-content ng-transclude></sticky-content>';
  private sortedStickies = [];

  constructor(private $timeout: ng.ITimeoutService, private $window: ng.IWindowService) {}
  public link = ($scope: ng.IScope, element: JQuery): void => {
    const sticky = element.children()[0];
    const $sticky = angular.element(sticky);
    const $window = this.$window;
    let yPos = sticky.offsetTop;

    this.insertIntoSortedStickies(sticky);
    this.assignYPositions();

    const addOrRemoveStickyFixed = (): void => {
      yPos = sticky.offsetTop !== 0 ? sticky.offsetTop : yPos;
      const extraYOffset = $sticky.data('yPos') || 0;
      if (yPos < $window.pageYOffset + extraYOffset && sticky.offsetTop >= element[0].getBoundingClientRect().top) {
        $sticky.addClass('sticky-fixed');
      } else {
        $sticky.removeClass('sticky-fixed');
      }
    };

    const onScroll = (): void => {
      addOrRemoveStickyFixed();
    };

    // Listen for window resize to address bug where spacing is off if user loads page
    // with responsive screen width and then expands window
    let resizeTimer;
    const onResize = (): void => {
      this.$timeout.cancel(resizeTimer);
      resizeTimer = this.$timeout(() => {
        element.css('height', sticky.clientHeight + 'px');
        addOrRemoveStickyFixed();
        this.assignYPositions();
      }, 100);
    };

    angular
      .element($window)
      .off('scroll', onScroll)
      .on('scroll', onScroll)
      .off('resize', onResize)
      .on('resize', onResize);

    element.on('$destroy', () => {
      this.removeFromSortedStickies(sticky);
    });
  };
  private assignYPositions = (): void => {
    let heightSum = 50; // start at 50 to account for main navigation from arcade-ui-chrome
    this.sortedStickies.forEach(s => {
      const $sticky = angular.element(s);
      $sticky.css('top', heightSum + 'px').data('yPos', heightSum);
      heightSum += s.clientHeight;
    });
  };
  private insertIntoSortedStickies = (sticky: HTMLElement): void => {
    if (this.sortedStickies.length > 0) {
      for (let i = 0; i < this.sortedStickies.length; i++) {
        // find the existing sticky before which this sticky should be placed, based on DOM order
        if (sticky.compareDocumentPosition(this.sortedStickies[i]) === Node.DOCUMENT_POSITION_FOLLOWING) {
          this.sortedStickies.splice(i, 0, sticky);
          return;
        }
      }
    }
    this.sortedStickies.push(sticky);
  };
  private removeFromSortedStickies = (sticky: HTMLElement): void => {
    this.sortedStickies.splice(this.sortedStickies.indexOf(sticky), 1);
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($timeout: ng.ITimeoutService, $window: ng.IWindowService) => {
      'ngInject';
      return new StickyDirective($timeout, $window);
    };
    return directive;
  }
}
