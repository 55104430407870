import allClaimsFaqTemplate from 'views/modals/all-claims-faq.html';
import { IClientConfig, IFaq } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { isExpatriate } from 'scripts/util/user/user';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { expatSubmitClaims, internationalClaims, expatGlobalGateway } from 'scripts/util/resource/resource.constants';

interface IQuestion {
  question: string;
  answer: string;
  config?: IQuestionConfig;
}

interface IQuestionConfig {
  link?: string;
}

export class AllClaimsFaqController implements ng.IComponentController {
  public customizedFaq: IFaq[];
  public standardFaq: IQuestion[];
  public useCustomizedFaq = false;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.FAQ);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS_FAQ);
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .do(({ currentUser }) => this.setStandardFaq(currentUser))
      .flatMap(({ rallyId }) => this.targetingService.getClientConfig(rallyId))
      .filter(
        clientConfig =>
          !!(clientConfig && clientConfig.contentOverrides && clientConfig.contentOverrides.customAllClaimsFAQ),
      )
      .subscribe(clientConfig => {
        this.customizedFaq = clientConfig.contentOverrides.customAllClaimsFAQ;
        this.setCustomizedFaq(clientConfig);
      });
  }

  private setCustomizedFaq(clientConfig: IClientConfig): void {
    this.useCustomizedFaq =
      clientConfig.contentOverrides.customAllClaimsFAQ !== undefined &&
      clientConfig.contentOverrides.customAllClaimsFAQ.length > 0;
  }

  private setStandardFaq(currentUser: IProfileUser): void {
    if (isExpatriate(currentUser)) {
      this.standardFaq = [
        {
          question: 'Q_HOW_TO_SUBMIT_CLAIM',
          answer: 'A_HOW_TO_SUBMIT_CLAIM',
          config: {
            link: this.resourceService.get(expatSubmitClaims),
          },
        },
        {
          question: 'Q_PROCESS_CLAIM_TIME',
          answer: 'A_PROCESS_CLAIM_TIME_EXPATRIATE',
        },
        {
          question: 'Q_MAKE_ONLINE_PAYMENT',
          answer: 'A_MAKE_ONLINE_PAYMENT_EXPATRIATE',
        },
        {
          question: 'Q_FIND_CLAIMS',
          answer: 'A_FIND_CLAIMS_EXPATRIATE',
        },
        {
          question: 'Q_CLAIM_STATUS_IN_PROCESS',
          answer: 'A_CLAIM_STATUS_IN_PROCESS',
        },
        {
          question: 'Q_CLAIM_STATUS_PROCESSED',
          answer: 'A_CLAIM_STATUS_PROCESSED',
        },
        {
          question: 'Q_CLAIM_BEEN_PAID',
          answer: 'A_CLAIM_BEEN_PAID',
          config: {
            link: this.resourceService.get(internationalClaims),
          },
        },
        {
          question: 'Q_DIRECT_BILLING',
          answer: 'A_DIRECT_BILLING',
          config: {
            link: this.resourceService.get(expatGlobalGateway),
          },
        },
      ];
    } else {
      this.standardFaq = [
        {
          question: 'Q_PROCESS_CLAIM_TIME',
          answer: 'A_PROCESS_CLAIM_TIME',
        },
        {
          question: 'Q_MAKE_ONLINE_PAYMENT',
          answer: 'A_MAKE_ONLINE_PAYMENT',
        },
        {
          question: 'Q_FIND_CLAIMS',
          answer: 'A_FIND_CLAIMS',
        },
        {
          question: 'Q_CLAIM_STATUS_IN_PROCESS',
          answer: 'A_CLAIM_STATUS_IN_PROCESS',
        },
        {
          question: 'Q_CLAIM_STATUS_PROCESSED',
          answer: 'A_CLAIM_STATUS_PROCESSED',
        },
        {
          question: 'Q_REDUCE_PAPERWORK',
          answer: 'A_REDUCE_PAPERWORK',
        },
      ];
    }
  }
}

export class AllClaimsFaqComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsFaqController;
    this.templateUrl = allClaimsFaqTemplate;
  }
}
