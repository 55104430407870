import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { getCoverage } from 'scripts/util/profile/profile';
import { CoverageType, CoverageStatus } from 'scripts/api/api.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { IPlanCoverage } from 'scripts/api/profile/profile.interfaces';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IInactiveCoverageProps extends IInactiveCoverageStateToProps {
  className?: string;
  displayType?: boolean;
  onlyShowFuture?: boolean;
}

interface IInactiveCoverageStateToProps {
  medicalCoverage: IPlanCoverage;
}

export const RawInactiveCoverage: FunctionComponent<IInactiveCoverageProps> = props => {
  const { className, displayType, medicalCoverage, onlyShowFuture } = props;
  const planPeriod = medicalCoverage && medicalCoverage.planPeriod;
  const show = onlyShowFuture
    ? planPeriod.status === CoverageStatus.Future
    : planPeriod.status !== CoverageStatus.Active;
  const type = medicalCoverage.coverageType;
  const isFuture = planPeriod.status === CoverageStatus.Future;
  const isTermed = planPeriod.status === CoverageStatus.Termed;
  const { t } = useTranslation(Dictionary.COMMON);

  if (!show) {
    return null;
  }

  return (
    <div className={classNames('inactive-coverage-status', className)}>
      {isFuture && (
        <span data-testid="coverage-starts">
          {t(displayType ? 'TYPE_COVERAGE_STARTS' : 'COVERAGE_STARTS', { type })}{' '}
          {planPeriod.startDate.format('MM/DD/YYYY')}
        </span>
      )}
      {isTermed && (
        <span data-testid="coverage-ended">
          {t(displayType ? 'TYPE_COVERAGE_ENDED' : 'COVERAGE_ENDED', { type })}{' '}
          {planPeriod.startDate.format('MM/DD/YYYY')}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IInactiveCoverageStateToProps => {
  return {
    medicalCoverage: getCoverage(CoverageType.Medical, currentUser.selectProfile(state).planCoverages),
  };
};

export const InactiveCoverage = connect(mapStateToProps)(RawInactiveCoverage);
