import React, { FunctionComponent, useState } from 'react';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { Feature } from 'scripts/ui/feature/feature';
import { IdCardCoverageTypeHeader } from './id-card-coverage-type-header';
import { IdCardCoverageDetailsHeader } from './id-card-coverage-details-header';
import classNames from 'classnames';
import {
  isActiveOrFutureIndividualCardMultipleUsers,
  getUsersWithCoverage,
  hasTermedCoverage,
} from 'scripts/util/plans/plans';
import { IdCardContainer } from './id-card-container';
import { IdCardCoverageTable } from './id-card-coverage-table';
import { CoverageType } from 'scripts/api/api.interfaces';
import { IIdCard } from 'scripts/api/plans/plans.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IdCardGatedCoverage } from './id-card-gated-coverage';
import { IdCardCarousel } from './id-card-carousel';

interface IIdCardTypicalCoverageProps {
  currentProfile: IProfileUser;
  population?: IPopulation;
  coverage: ICoverageSection;
  users: IProfileUser[];
  isCS: boolean;
  otherCoverageTypesForCs?: CoverageType[];
  idCards: IIdCard[][];
  idCardsError: boolean;
  idCardsLoading: boolean;
  csMedicalSupportPhoneNum?: string;
  isGatedPlan: boolean;
  activePcpPerMember?: { [depSeqNum: string]: string };
}

export const IdCardTypicalCoverage: FunctionComponent<IIdCardTypicalCoverageProps> = props => {
  const {
    currentProfile,
    coverage,
    users,
    population,
    isCS,
    otherCoverageTypesForCs,
    idCards,
    csMedicalSupportPhoneNum,
    idCardsError,
    idCardsLoading,
    isGatedPlan,
    activePcpPerMember,
  } = props;
  const [isIdCardFlipped, setIsIdCardFlipped] = useState(props.coverage.flipped);
  const flipCardUpdate = (): void => {
    setIsIdCardFlipped(!isIdCardFlipped);
  };
  const [currentUserDepSeqNum, setSelectedUser] = useState(props.currentProfile.dependentSeqNum);
  const selectedUserUpdate = (selectedUserDepSeqNum: IProfileUser['dependentSeqNum']): void => {
    if (selectedUserDepSeqNum !== currentUserDepSeqNum) {
      coverage.selectedDepSeqNum = selectedUserDepSeqNum;
      setSelectedUser(selectedUserDepSeqNum);
    }
  };
  const { t } = useTranslation(Dictionary.ID_CARDS);

  return (
    <Feature featureId={coverage.coverageType}>
      <header>
        <IdCardCoverageTypeHeader
          coverage={coverage}
          isCS={isCS}
          otherCoverageTypesForCs={otherCoverageTypesForCs}
          users={users}
        />
        <IdCardCoverageDetailsHeader coverage={coverage} currentUser={currentProfile} />
      </header>
      {coverage.coverageType === CoverageType.Medical && isCS && (
        <div className="recently-selected">{t('RECENTLY_SELECTED_PCP')}</div>
      )}
      <div
        className={classNames('clearfix coverage-content', {
          'gated-content': isActiveOrFutureIndividualCardMultipleUsers(coverage, users),
        })}
      >
        {isActiveOrFutureIndividualCardMultipleUsers(coverage, users) ? (
          <>
            <div className="hide-mobile">
              <IdCardGatedCoverage
                coverage={coverage}
                users={users}
                isGatedPlan={isGatedPlan}
                activePcpPerMember={activePcpPerMember}
                selectedUserUpdate={selectedUserUpdate}
              />
              <div className="col-1-3 col-last">
                <IdCardContainer
                  idCards={idCards}
                  currentUser={currentProfile}
                  coverage={coverage}
                  users={users}
                  isCS={isCS}
                  csMedicalSupportPhoneNum={csMedicalSupportPhoneNum}
                  population={population}
                  idCardsError={idCardsError}
                  idCardsLoading={idCardsLoading}
                  flipCard={flipCardUpdate}
                  flipped={isIdCardFlipped}
                />
              </div>
            </div>
            <div className="hide-desktop">
              {/* TODO: add <carousel> and <slide> in a separate ticket */}
              {getUsersWithCoverage(coverage, users).map(user => (
                <div key={'slide-' + user.dependentSeqNum + coverage.coverageType}>
                  {' '}
                  {/* TODO change to <slide>*/}
                  <IdCardCarousel coverage={coverage} user={user} activePcpPerMember={activePcpPerMember} />
                  {hasTermedCoverage(coverage, user) ? (
                    <div className="termed-no-id">{t(`${Dictionary.ID_CARDS}:TERMED_ID_NOT_AVAILABLE`)}</div>
                  ) : (
                    <>
                      <div className="id-placeholder" />
                      <IdCardContainer
                        idCards={idCards}
                        currentUser={currentProfile}
                        coverage={coverage}
                        users={users}
                        isCS={isCS}
                        csMedicalSupportPhoneNum={csMedicalSupportPhoneNum}
                        population={population}
                        idCardsError={idCardsError}
                        idCardsLoading={idCardsLoading}
                        flipCard={flipCardUpdate}
                        flipped={isIdCardFlipped}
                      />
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="col-1-3">
              <IdCardContainer
                idCards={idCards}
                currentUser={currentProfile}
                coverage={coverage}
                users={users}
                isCS={isCS}
                csMedicalSupportPhoneNum={csMedicalSupportPhoneNum}
                population={population}
                idCardsError={idCardsError}
                idCardsLoading={idCardsLoading}
                flipCard={flipCardUpdate}
                flipped={isIdCardFlipped}
              />
            </div>
            <IdCardCoverageTable coverage={coverage} users={users} />
          </>
        )}
      </div>
    </Feature>
  );
};
