import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IQuickLinksCategory } from './quick-links.controller';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import {
  allClaims,
  benefitsCoverage,
  connect,
  prescriptions,
  viewDocs,
} from 'scripts/util/resource/resource.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IProducts } from 'scripts/api/profile/profile.interfaces';

const getOriginalCsQuickLinks = (population: IPopulation): IQuickLinksCategory[] => {
  return [
    {
      id: 'FIND_PROVIDER',
      dictionary: Dictionary.COMMON,
      text: 'FIND_PROVIDER',
      href: { pathname: '/internal-redirect', search: `?deepLink=${getResource(connect, population)}` },
      icon: 'icon-find-doctor',
      show: true,
    },
    {
      id: 'BENEFITS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_YOUR_BENEFITS',
      href: getResource(benefitsCoverage, population),
      icon: 'icon-benefits',
      show: true,
    },
    {
      id: 'PRESCRIPTIONS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'MANAGE_PRESCRIPTIONS',
      href: getResource(prescriptions, population),
      icon: 'icon-pharmacy',
    },
    {
      id: 'CLAIMS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_YOUR_CLAIMS_AND_VISITS',
      href: getResource(allClaims, population),
      icon: 'icon-claims',
      show: true,
    },
    {
      id: 'VIEW_DOCS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_DOCUMENTS',
      href: getResource(viewDocs, population),
      target: LinkTarget.Blank,
      icon: 'icon-plan-documents',
      show: true,
    },
  ];
};

export const getCsQuickLinks = (population: IPopulation, products: IProducts): IQuickLinksCategory[] => {
  const originalCsQuickLinks = getOriginalCsQuickLinks(population);

  if (products && products.rx) {
    originalCsQuickLinks[2].show = true;
  }

  return originalCsQuickLinks.filter(quickLink => quickLink.show);
};
