import { BehaviorSubject, Observable, Subject } from 'rxjs/Rx';
import { CoverageType, IDateFilter } from '../../api/api.interfaces';
import { IMiscellaneousInfo, IProfileUser } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { IEnvironmentConstants } from '../constants/environment.interfaces';
import { getLocale } from '../locale/locale';
import { LocaleLanguage } from '../locale/locale.interfaces';
import { ILocaleService } from '../locale/locale.service';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    OgnGenesys: IOgnGenesis;
  }
}

export interface IOgnGenesisApp {
  configure: (config: IGenesysConfig) => Promise<void>;
  initialize: () => {};
  update: (pageName?: string, lang?: LocaleLanguage, buttonClass?: string) => void;
}

export interface IOgnGenesis {
  app: IOgnGenesisApp;
  chatStatus: () => { isOpen: boolean };
}

/* Some fields to be filled by the PSA response, specifically
 * ENT_ContactSourceSystem
 * ENT_Client
 * ENT_Consortium
 * ENT_Product
 * ENT_DedicatedCustSvc
 * ENT_OffshoreRestrictFlag
 *
 * Other fields left commented out as documentation
 * */
export interface IGenesysUserData {
  BRAND_Company: string;
  CHAT_PortalPage: string;
  CHAT_SurveyUrl: string;
  ENT_Client?: string;
  ENT_Consortium?: string;
  // ENT_ContactConstituentID: string;
  // ENT_ContactConstituentSourceID: string;
  ENT_ContactConstituentType: string;
  ENT_ContactDOB: string;
  // ENT_ContactEmployeeID: string;
  ENT_ContactFirstNm: string;
  ENT_ContactGroupID: string;
  ENT_ContactLastNm: string;
  ENT_ContactPhoneNbr?: string;
  ENT_ContactSourceSystem?: string;
  ENT_DedicatedCustSvc?: string;
  // ENT_FromAddress: string;
  ENT_Function: string;
  // ENT_ObligorID: string;
  ENT_OffshoreRestrictFlag?: string;
  ENT_PlanVariationCD: string;
  ENT_Product?: string;
  ENT_Segment: string;
  // ENT_SharedArrangement: string;
  ENT_SourceMemberID: string;
  // ENT_SubFunction: string;
  ENT_Unit: string;
}

export interface IGenesysConfig {
  portalName: string;
  pageName: string;
  lang: LocaleLanguage;
  buttonClass: string;
  isAuthorized: boolean;
  userData: IGenesysUserData;
  cacheId: string;
  environment?: string;
  chatStatusMonitor?: any;
  analytics?: any;
  launchSurvey: boolean;
  openInNewWindow: boolean;
}

// in theory, we should be able to set some of these to other values (e.g. 'dev', 'test') but 'stage' is
// the only one that works right now. If support isn't added for the others, we can get rid of this.
const configEnvToGenesysEnv = {
  Bluesteel: 'stage',
  Development: 'stage',
  Integration: 'stage',
  Loadtest: 'stage',
  Local: 'stage',
};

export interface IGenesysService {
  chatButtonClassName: string;
  isChatAvailable(): Subject<boolean>;
  updatePageAndButtonClassNames(): void;
}

export class GenesysService implements IGenesysService {
  public chatButtonClassName: string;
  private userData: IGenesysUserData;
  private pageName: string;
  private isChatAvailableSubject: Subject<boolean> = new Subject();

  constructor(
    private $filter: IDateFilter,
    private $interval: ng.IIntervalService,
    private $state: ng.ui.IStateService,
    private Environment: IEnvironmentConstants,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = Environment.CONFIG.ARCADE_WEB_GENESYS_URL;
    document.body.appendChild(script);

    const hiddenButtonClass = 'genesys-chat-temp';
    const hiddenButton = document.createElement('Button');
    hiddenButton.classList.add(hiddenButtonClass);
    hiddenButton.style.display = 'none';
    document.body.appendChild(hiddenButton);

    this.pageName = $state.current.data.name;

    this.localeService.localeChanged.subscribe(locale => {
      this.updateLanguage(locale.language);
    });

    const clickToChat$ = this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.profileService.getProducts(data.rallyId))
      .map(({ data }) => data.products.clickToChat);

    const currentUser$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(({ data }) => data.currentUser);

    Observable.zip(currentUser$, clickToChat$).subscribe(([currentUser, clickToChat]) => {
      this.setUserData(currentUser, clickToChat && clickToChat.miscellaneousInfo);

      const interval = this.$interval(() => {
        if (window.OgnGenesys) {
          this.$interval.cancel(interval);

          const config: IGenesysConfig = {
            portalName: 'myuhc',
            pageName: this.pageName,
            lang: getLocale().language,
            buttonClass: hiddenButtonClass,
            isAuthorized: true,
            userData: this.userData,
            cacheId: currentUser.rallyId,
            launchSurvey: true,
            openInNewWindow: true,
          };

          if (configEnvToGenesysEnv[Environment.CONFIG.ARCADE_WEB_ENVIRONMENT_NAME]) {
            config.environment = configEnvToGenesysEnv[Environment.CONFIG.ARCADE_WEB_ENVIRONMENT_NAME];
          }

          const configObservable = Observable.from(window.OgnGenesys.app.configure(config));
          configObservable.subscribe(
            () => {
              const isOpen = window.OgnGenesys.chatStatus && window.OgnGenesys.chatStatus().isOpen;
              if (isOpen) {
                window.OgnGenesys.app.initialize();
              }
              this.isChatAvailableSubject.next(!!isOpen);
            },
            () => {
              this.isChatAvailableSubject.next(
                !!(window.OgnGenesys.chatStatus && window.OgnGenesys.chatStatus().isOpen),
              );
              console.warn('Genesys config failed');
            },
          );
        }
      }, 100);
    });
  }

  public isChatAvailable(): Subject<boolean> {
    // if the chatStatus is already available, return a subject with the status as its initial value
    if (window.OgnGenesys && window.OgnGenesys.chatStatus) {
      return new BehaviorSubject(window.OgnGenesys.chatStatus().isOpen);
    }
    return this.isChatAvailableSubject;
  }

  public updatePageAndButtonClassNames(): void {
    this.chatButtonClassName = this.getChatButtonClassName();
    this.pageName = this.$state.current.data.name;

    // interval in order to give the element with the new button class a chance to render before calling update
    // (Genesys must be able to find an element with the specified button class, otherwise it will throw an error)
    const interval = this.$interval(() => {
      if (window.OgnGenesys && document.getElementsByClassName(this.chatButtonClassName).length > 0) {
        this.$interval.cancel(interval);
        window.OgnGenesys.app.update(this.pageName, undefined, this.chatButtonClassName);
      }
    }, 100);
  }

  private getChatButtonClassName(): string {
    return `genesys-chat-${this.$state.current.data.name.toLowerCase()}`;
  }

  private updateLanguage(lang: LocaleLanguage): void {
    if (window.OgnGenesys) {
      window.OgnGenesys.app.update(undefined, lang);
    }
  }

  private setUserData(currentUser: IProfileUser, maybePsaInfo?: IMiscellaneousInfo): void {
    const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages);
    let psaValues = {};

    if (maybePsaInfo) {
      psaValues = {
        ENT_ContactSourceSystem: maybePsaInfo.sourceSystem,
        ENT_Client: maybePsaInfo.client,
        ENT_Consortium: maybePsaInfo.consortium,
        ENT_Product: maybePsaInfo.uniqueProduct,
        ENT_DedicatedCustSvc: maybePsaInfo.dedicatedQueue ? maybePsaInfo.dedicatedQueue : 'false',
        ENT_OffshoreRestrictFlag: maybePsaInfo.offshoreRestricted ? maybePsaInfo.offshoreRestricted : 'false',
      };
    } else {
      psaValues = {
        ENT_DedicatedCustSvc: 'false',
        ENT_OffshoreRestrictFlag: 'false',
      };
    }

    const member = 'Member';

    const nonPsaValues = {
      BRAND_Company: 'UHC',
      CHAT_PortalPage: this.pageName,
      CHAT_SurveyUrl: 'https://uhg.az1.qualtrics.com/jfe/form/SV_2l6N6PCRkSANKzb',
      ENT_ContactConstituentType: member,
      ENT_ContactDOB: this.$filter('amDateFormat')(currentUser.userInfo.dob, 'YYYY-MM-DD'),
      ENT_ContactFirstNm: currentUser.userInfo.firstName,
      ENT_ContactLastNm: currentUser.userInfo.lastName,
      ENT_ContactGroupID: currentUser.primaryPolicyNumber,
      ENT_Function: member,
      ENT_PlanVariationCD: currentUser.pvrc ? currentUser.pvrc.planVariationCode : '',
      ENT_Segment: 'EI',
      ENT_SourceMemberID: medicalCoverage && medicalCoverage.memberId.id,
      ENT_Unit: 'UHC',
    };

    this.userData = { ...psaValues, ...nonPsaValues };
  }
}
