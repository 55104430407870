import { CoverageType, CoverageTypeCode, ICurrencyAmount, IResponse } from '../api.interfaces';

export enum BenefitPaymentType {
  FamilyCopayMax = 'FAMILY_COPAY_MAX',
  FamilyDeductible = 'FAMILY_DEDUCTIBLE',
  FamilyOop = 'FAMILY_OOP',
  FamilyOopMax2 = 'FAMILY_OOP_MAX_2',
  IndividualAnnualMax = 'INDIVIDUAL_ANNUAL_MAX',
  IndividualCopayMax = 'INDIVIDUAL_COPAY_MAX',
  IndividualDeductible = 'INDIVIDUAL_DEDUCTIBLE',
  IndividualLifetimeMax = 'INDIVIDUAL_LIFETIME_MAX',
  IndividualOop = 'INDIVIDUAL_OOP',
  IndividualOopMax2 = 'INDIVIDUAL_OOP_MAX_2',
  PlanLifetimeMax = 'PLAN_LIFETIME_MAX',
  PreDeductibleAllowanceCombined = 'PREDEDUCTIBLE_ALLOWANCE_COMBINED',
  PreDeductibleAllowanceEmergencyServices = 'PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES',
  PreDeductibleAllowanceRoutineServices = 'PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES',
}

export enum DeductibleOOP {
  DEDUCTIBLE = 'DEDUCTIBLE',
  OOP = 'OOP',
}

export enum BenefitServiceType {
  CoinsurancePercent = 'COINSURANCE_PERCENT',
  CopayAmount = 'COPAY_AMOUNT',
  DeductibleAmount = 'DEDUCTIBLE_AMOUNT',
}

export enum BenefitNetwork {
  InNetwork = 'inNetwork',
  OutOfNetwork = 'outOfNetwork',
  Tier1 = 'tier1',
  Tier2 = 'tier2',
  Type1 = 'type1',
  Type2 = 'type2',
}

export const DEDUCTIBLE = 'DEDUCTIBLE';
export const OOP = 'OOP';
export const ANNUAL_MAX_BENEFIT = 'ANNUAL_MAX_BENEFIT';
export const LIFETIME_MAX_BENEFIT = 'LIFETIME_MAX_BENEFIT';
export const INDIVIDUAL = 'INDIVIDUAL';
export const FAMILY = 'FAMILY';
export const OOP_MAX_2 = 'OOP_MAX_2';
export const COPAY_MAX = 'COPAY_MAX';
export const BENEFIT_TYPE = {
  INDIVIDUAL_DEDUCTIBLE: {
    owner: INDIVIDUAL,
    benefit: DEDUCTIBLE,
  },
  FAMILY_DEDUCTIBLE: {
    owner: FAMILY,
    benefit: DEDUCTIBLE,
  },
  INDIVIDUAL_OOP: {
    owner: INDIVIDUAL,
    benefit: OOP,
  },
  FAMILY_OOP: {
    owner: FAMILY,
    benefit: OOP,
  },
  INDIVIDUAL_ANNUAL_MAX: {
    owner: INDIVIDUAL,
    benefit: ANNUAL_MAX_BENEFIT,
  },
  INDIVIDUAL_LIFETIME_MAX: {
    owner: INDIVIDUAL,
    benefit: LIFETIME_MAX_BENEFIT,
  },
  INDIVIDUAL_OOP_MAX_2: {
    owner: INDIVIDUAL,
    benefit: OOP_MAX_2,
  },
  FAMILY_OOP_MAX_2: {
    owner: FAMILY,
    benefit: OOP_MAX_2,
  },
  INDIVIDUAL_COPAY_MAX: {
    owner: INDIVIDUAL,
    benefit: COPAY_MAX,
  },
  FAMILY_COPAY_MAX: {
    owner: FAMILY,
    benefit: COPAY_MAX,
  },
};

export enum BenefitTypeCopyMap {
  ANNUAL_MAX_BENEFIT = 'ANNUAL_MAX_DENTAL',
  COPAY_MAX = 'COPAY_MAXIMUM',
  DEDUCTIBLE = 'DEDUCTIBLE',
  LIFETIME_MAX_BENEFIT = 'LIFETIME_MAX_ORTHO',
  OOP = 'OOP_MAXIMUM',
  OOP_MAX_2 = 'OOP_MAXIMUM_2',
  PLAN_LIFETIME_MAX = 'PLAN_LIFETIME_MAX',
  PREDEDUCTIBLE_ALLOWANCE_COMBINED = 'PREDEDUCTIBLE_ALLOWANCE_COMBINED',
  PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES = 'PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES',
  PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES = 'PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES',
}

export enum Tier1Feature {
  Hospitals = 'TIER1_HOSPITALS',
  PCP = 'TIER1_OOP_PCP',
  Specialist = 'TIER1_OOP_SPECIALIST',
}

export interface IBenefitsSpendingList {
  inNetwork: IBenefitAmount[];
  outOfNetwork?: IBenefitAmount[];
  tier1?: IBenefitAmount[];
  predeductibleMax?: IBenefitAmount;
  lifetimeMax?: IBenefitAmount;
}

export interface IBenefitAccumulators {
  coverageType: CoverageType;
  accumulators: IBenefitsSpendingList;
  predeductibleAccumulators?: IBenefitAmount[];
  lifetimeMaxAccumulator?: IBenefitAmount;
  prorateReasonCode?: ProrateReasonCode;
}

export interface IBenefitAmount {
  type: BenefitPaymentType;
  amount: ICurrencyAmount;
}

export interface IBenefitAmountService {
  type: BenefitServiceType;
  amount: string;
  referralRequired?: boolean;
}

export interface IBenefitServiceCode {
  type: string;
  code: string;
  friendlyCode: string;
}

export interface IBenefitService {
  serviceCode: IBenefitServiceCode;
  inNetwork: IBenefitAmountService[];
  outOfNetwork: IBenefitAmountService[];
  tier1: IBenefitAmountService[];
  tier2: IBenefitAmountService[];
  type1: IBenefitAmountService[];
  type2: IBenefitAmountService[];
}

export interface IPlanFeatures {
  isTieredPlan: boolean;
  isCspGspPlan: boolean;
  knownFeatures: Tier1Feature[];
}

export interface IBenefit {
  coverageType: CoverageType;
  coverageTypeCode: CoverageTypeCode;
  shortPlanName: string;
  planName: string;
  maxes: IBenefitsSpendingList;
  services: IBenefitService[];
  planFeatures: IPlanFeatures;
  hasAncillaryBenefits: boolean; // deprecated
  showRxCarveoutClaims: boolean;
  ancillaryBenefits: AncillaryBenefit[];
  hideUHPD: boolean;
  drugBenefits?: IDrugBenefits;
}

export interface IPlanAccumulators {
  planKey?: string;
  policyNumber?: string;
  benefits: IBenefitAccumulators[];
}

export interface IPlanBenefits {
  planKey?: string;
  groupName?: string;
  groupNumber?: string;
  benefits: IBenefit[];
}

export interface IPlanBenefitsResponse extends IResponse<IPlanBenefits> {}

export interface IPlanAccumulatorsResponse extends IResponse<IPlanAccumulators> {}

export interface IPlanFamilyBenefitsResponse extends IResponse<IPlanFamilyBenefits> {}

export interface IPlanFamilyBenefits {
  [depSeqNum: string]: IFamilyBenefits;
}

export interface IFamilyBenefits {
  benefits: IBenefit[];
}

export interface IPlanFamilyAccumulatorsResponse extends IResponse<IPlanFamilyAccumulators> {}

export interface IPlanFamilyAccumulators {
  [depSeqNum: string]: IFamilyAccumulators;
}

export interface IFamilyAccumulators {
  benefits: IBenefitAccumulators[];
}

export interface IIdCard {
  cardType: string;
  front: IIdCardSide;
  back: IIdCardSide;
  generatedImage: boolean;
  coverageTypeCode?: CoverageTypeCode;
}

export interface IIdCardSide {
  mimeType: string;
  length: number;
  stream: string;
}

export interface IIdCardsResponse extends IResponse<IIdCard[]> {}

export interface IUsefulAccumulator {
  max: ICurrencyAmount;
  amount: ICurrencyAmount;
  network: BenefitNetwork;
  type: BenefitPaymentType;
  customLabel?: string;
}

export interface IUsefulAccumulators {
  INDIVIDUAL_DEDUCTIBLE?: IUsefulAccumulator;
  FAMILY_DEDUCTIBLE?: IUsefulAccumulator;
  INDIVIDUAL_OOP?: IUsefulAccumulator;
  FAMILY_OOP?: IUsefulAccumulator;
  INDIVIDUAL_ANNUAL_MAX?: IUsefulAccumulator;
  INDIVIDUAL_LIFETIME_MAX?: IUsefulAccumulator;
  PREDEDUCTIBLE_ALLOWANCE_COMBINED?: IUsefulAccumulator;
  PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES?: IUsefulAccumulator;
  PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES?: IUsefulAccumulator;
}

export interface IUsefulBenefits {
  coverageType: CoverageType;
  isFamilyPlan: boolean;
  isTieredPlan: boolean;
  isCspGspPlan: boolean;
  inNetwork?: IUsefulAccumulators;
  outOfNetwork?: IUsefulAccumulators;
  tier1?: IUsefulAccumulators;
}

export interface IUsefulBenefitsWithAccumulators {
  MEDICAL?: IUsefulBenefits;
  DENTAL?: IUsefulBenefits;
}

export interface IBenefitExplanation {
  label: string;
  isCustomLabel: boolean;
  isCustomDef: boolean;
  definition: string;
}

export interface IBenefitLinkReplacement {
  [label: string]: IBenefitLinkReplacementLabel;
}

export interface IBenefitLinkReplacementLabel {
  label: string;
  customLabel?: string;
}

export interface IPlanVariationParams {
  pvrc?: string;
  clientId?: string;
}

export interface ICoverageParams {
  coverageTypeCode: CoverageTypeCode;
  fundingArrangementType: string;
  programType: string;
  marketType: string;
}

export interface IMRBenefitParams extends IPlanVariationParams, ICoverageParams {
  d?: string;
  showFamily?: boolean;
}

export interface IIdCardMRBenefitParams extends ICoverageParams {
  getImage: boolean;
}

export interface IDrugBenefits {
  TIER_ONE?: IDrugBenefit;
  TIER_TWO?: IDrugBenefit;
  TIER_THREE?: IDrugBenefit;
  TIER_FOUR?: IDrugBenefit;
  TIER_FIVE?: IDrugBenefit;
  GENERIC_DRUGS?: IDrugBenefit;
  OTHER_DRUGS?: IDrugBenefit;
}

export interface IDrugBenefit {
  drugCoverageState: DrugCoverageState;
  drugTypeInformations: IDrugInformation[];
}

export interface IDrugInformation {
  drugCoverageType: DrugCoverageType;
  drugCopay?: string;
  drugCoinsurance?: string;
}

export enum DrugTierType {
  GenericDrugs = 'GENERIC_DRUGS',
  OtherDrugs = 'OTHER_DRUGS',
  Tier1 = 'TIER_ONE',
  Tier2 = 'TIER_TWO',
  Tier3 = 'TIER_THREE',
  Tier4 = 'TIER_FOUR',
  Tier5 = 'TIER_FIVE',
}

export enum DrugCoverageState {
  Catastrophic = 'CATASTROPHIC',
  CoverageGap = 'COVERAGE_GAP',
  Initial = 'INITIAL',
}

export enum DrugCoverageType {
  Preferred = 'PREFERRED',
  PreferredMailOrder = 'PREFERRED_MAIL_ORDER',
  Standard = 'STANDARD',
  StandardMailOrder = 'STANDARD_MAIL_ORDER',
}

export enum ProrateReasonCode {
  BenefitChange = 'BENEFIT_CHANGE',
  MidYear = 'MID_YEAR',
  MidYearAndBenefitChange = 'MID_YEAR_AND_BENEFIT_CHANGE',
}

export enum AncillaryBenefit {
  Chiropractic = 'ANCILLARY_CHIROPRACTIC',
  Dental = 'ANCILLARY_DENTAL',
  Hearing = 'ANCILLARY_HEARING',
  Vision = 'ANCILLARY_VISION',
}
