import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { Anchor, IAnchorProps } from 'scripts/ui/anchor/anchor';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

export interface ILinkToOtherFormProps extends IAnchorProps {
  label: string;
}

export const LinkToOtherForm: FunctionComponent<ILinkToOtherFormProps> = ({ label, ...rest }) => {
  const AnchorWithClickTracking = withClickTracking(Anchor, label);
  const { t } = useTranslation(Dictionary.SUBMIT_CLAIM);

  return (
    <AnchorWithClickTracking
      className="possible-first-main-focus submit-claim-other-forms-link"
      target={LinkTarget.Blank}
      {...rest}
    >
      <span className="submit-claim-other-forms-link-text">{t(label)}</span>
    </AnchorWithClickTracking>
  );
};
