import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';

interface IActiveProviderReferralsHeaderProps {
  activeReferralsCount: number;
}

export const ActiveProviderReferralsHeader: FunctionComponent<IActiveProviderReferralsHeaderProps> = props => {
  const { activeReferralsCount } = props;
  const { t } = useTranslation(Dictionary.PCP);
  const translationKey = activeReferralsCount === 1 ? 'ACTIVE_REFERRAL' : 'ACTIVE_REFERRALS';

  return (
    <div data-testid="active-referrals-header">
      <h2>{t(translationKey, { number: activeReferralsCount })}</h2>
      <p className="information-not-available-text">{t('INFORMATION_NOT_AVAILABLE_OR_QUESTIONS')}</p>
    </div>
  );
};
