import pieChartTemplate from 'views/ui/pie-chart.html';
import { IPieChartData, IPieChartSegment, IPieChartSlice } from './pie-chart.interfaces';

export class PieChartController implements ng.IComponentController {
  public data: IPieChartData;
  public segments: IPieChartSegment[];

  public $onChanges(changesObj: { [property: string]: angular.IChangesObject<number> }): void {
    if (changesObj.data.currentValue) {
      this.segments = this.generatePathData(this.data.slices, this.data.spacing);
    }
  }
  // the following is courtesy of https://hackernoon.com/a-simple-pie-chart-in-svg-dbdd653b6936
  private generatePathData(slices: IPieChartSlice[], spacing: boolean): IPieChartSegment[] {
    const nonZeroSlices = slices.filter(({ percent }) => percent > 0);
    // sliceEndPadding expands out each slice
    // SVG is not precise enough to deal with an infinitely small parallel border without aliasing.
    // when spacing we want negative padding to show white lines
    const sliceEndPadding = nonZeroSlices.length > 1 ? (spacing ? -0.001 : 0.005) : 0;
    // for exploding the pie (pushing each piece out from the center when spacing = true)
    const offset = 0.025;
    let cumulativePercent = 0;

    return nonZeroSlices.map(({ percent, color }) => {
      // IE will not render a full circle so lower the percent to just below 100%
      percent = percent >= 1 ? 0.9999 : percent;

      // destructuring assignment sets the two variables at once
      const [startX, startY] = this.getCoordinatesForPercent(cumulativePercent - sliceEndPadding);

      // get the center of the arc position in radians so that we know which direction to push the slice
      const midAngleRads = Math.PI * 2 * ((cumulativePercent + (cumulativePercent + percent)) / 2);

      // each slice starts where the last slice ended, so keep a cumulative percent
      cumulativePercent += percent;

      const [endX, endY] = this.getCoordinatesForPercent(cumulativePercent + sliceEndPadding);

      const transformX = spacing ? Math.cos(midAngleRads) * offset : 0;
      const transformY = spacing ? Math.sin(midAngleRads) * offset : 0;

      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = percent > 0.5 ? 1 : 0;

      return {
        shape:
          `M ${startX + transformX} ${startY + transformY} ` + // Move
          `A 1 1 0 ${largeArcFlag} 1 ${endX + transformX} ${endY + transformY} ` + // Arc
          `L ${transformX} ${transformY}`, // Line
        color,
      };
    });
  }

  private getCoordinatesForPercent(percent: number): number[] {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }
}

export class PieChartComponent implements ng.IComponentOptions {
  public controller = PieChartController;
  public bindings = {
    data: '<',
  };
  public templateUrl = pieChartTemplate;
  public transclude = true;
}
