import { combineReducers } from 'redux';
import app from './app-reducer';
import claims from './claims-service-reducer';
import ledger from './ledger-service-reducer';
import locale from './locale-reducer';
import plans from './plans-service-reducer';
import population from './population-reducer';
import profile from './profile-service-reducer';
import targeting from './targeting-service-reducer';
import user from './user-service-reducer';

const reducers = {
  app,
  claims,
  ledger,
  locale,
  plans,
  population,
  profile,
  targeting,
  user,
};

export default combineReducers(reducers);
