import { IClaim, IClaimTotals } from 'scripts/api/claims/claims.interfaces';
import moment from 'moment';

export enum ClaimsServiceActionTypes {
  GET_ALL_HEALTHCARE_ERROR = 'GET_ALL_HEALTHCARE_ERROR',
  GET_ALL_HEALTHCARE_LOADING = 'GET_ALL_HEALTHCARE_LOADING',
  GET_ALL_HEALTHCARE_SUCCESS = 'GET_ALL_HEALTHCARE_SUCCESS',
  GET_SUMMARY_ERROR = 'GET_SUMMARY_ERROR',
  GET_SUMMARY_LOADING = 'GET_SUMMARY_LOADING',
  GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS',
  GET_TOTALS_ERROR = 'GET_TOTALS_ERROR',
  GET_TOTALS_LOADING = 'GET_TOTALS_LOADING',
  GET_TOTALS_SUCCESS = 'GET_TOTALS_SUCCESS',
  SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS',
}

export interface IGetAllHealthcareLoading {
  type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_LOADING;
}

export interface IGetAllHealthcareSuccess {
  type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_SUCCESS;
  payload: IClaim[];
}

export interface IGetAllHealthcareError {
  type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_ERROR;
}

export interface IGetSummaryLoading {
  type: ClaimsServiceActionTypes.GET_SUMMARY_LOADING;
}

export interface IGetSummarySuccess {
  type: ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS;
  payload: { summary: IClaim[]; arcadeDataUpdated?: moment.Moment };
}

export interface IGetSummaryError {
  type: ClaimsServiceActionTypes.GET_SUMMARY_ERROR;
}

export interface IGetTotalsLoading {
  type: ClaimsServiceActionTypes.GET_TOTALS_LOADING;
}

export interface IGetTotalsSuccess {
  type: ClaimsServiceActionTypes.GET_TOTALS_SUCCESS;
  payload: { totals: IClaimTotals[]; arcadeDataUpdated?: moment.Moment };
}

export interface IGetTotalsError {
  type: ClaimsServiceActionTypes.GET_TOTALS_ERROR;
}

export interface ISaveClaimSuccess {
  type: ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS;
  payload: string;
}

export function getAllHealthcareLoading(): IGetAllHealthcareLoading {
  return {
    type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_LOADING,
  };
}

export function getAllHealthcareSuccess(payload: IClaim[]): IGetAllHealthcareSuccess {
  return {
    type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_SUCCESS,
    payload,
  };
}

export function getAllHealthcareError(): IGetAllHealthcareError {
  return {
    type: ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_ERROR,
  };
}

export function getSummaryLoading(): IGetSummaryLoading {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_LOADING,
  };
}

export function getSummarySuccess(payload: {
  summary: IClaim[];
  arcadeDataUpdated?: moment.Moment;
}): IGetSummarySuccess {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS,
    payload,
  };
}

export function getSummaryError(): IGetSummaryError {
  return {
    type: ClaimsServiceActionTypes.GET_SUMMARY_ERROR,
  };
}

export function getTotalsLoading(): IGetTotalsLoading {
  return {
    type: ClaimsServiceActionTypes.GET_TOTALS_LOADING,
  };
}

export function getTotalsSuccess(payload: {
  totals: IClaimTotals[];
  arcadeDataUpdated?: moment.Moment;
}): IGetTotalsSuccess {
  return {
    type: ClaimsServiceActionTypes.GET_TOTALS_SUCCESS,
    payload,
  };
}

export function getTotalsError(): IGetTotalsError {
  return {
    type: ClaimsServiceActionTypes.GET_TOTALS_ERROR,
  };
}

export function saveClaimSuccess(claimKey: string): ISaveClaimSuccess {
  return {
    type: ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS,
    payload: claimKey,
  };
}

export type ClaimsServiceAction =
  | IGetAllHealthcareLoading
  | IGetAllHealthcareSuccess
  | IGetAllHealthcareError
  | IGetSummaryLoading
  | IGetSummarySuccess
  | IGetSummaryError
  | ISaveClaimSuccess
  | IGetTotalsLoading
  | IGetTotalsSuccess
  | IGetTotalsError;
