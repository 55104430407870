import { documentsOverview } from 'scripts/util/resource/resource.constants';
import { BENEFIT_TYPE, BenefitPaymentType, BenefitTypeCopyMap } from '../../../api/plans/plans.interfaces';
import mrMedicalAccountExplanationTemplate from 'views/modals/mr-medical-account-explanation.html';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IMRMedicalAccountParams extends ng.ui.IStateParamsService {
  paymentType: BenefitPaymentType;
}

export class MRMedicalAccountExplanationController implements ng.IComponentController {
  public paymentTypeLabel: string;
  private paymentType: BenefitPaymentType;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $stateParams: IMRMedicalAccountParams,
    private resourceService: IResourceService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
  }

  public $onInit(): void {
    this.paymentType = this.$stateParams.paymentType;
    this.paymentTypeLabel = this.getPaymentTypeLabel(this.paymentType);
  }

  public getTrackFeature(feature: string): string {
    return `${feature}-${this.paymentTypeLabel}`;
  }

  public getLink(): string | undefined {
    return this.paymentType === BenefitPaymentType.IndividualOop
      ? this.resourceService.get(documentsOverview)
      : undefined;
  }

  private getPaymentTypeLabel(paymentType: BenefitPaymentType): string {
    const benefitPaymentType = BENEFIT_TYPE[paymentType] ? BENEFIT_TYPE[paymentType].benefit : paymentType;
    return BenefitTypeCopyMap[benefitPaymentType] || '';
  }
}

export class MRMedicalAccountExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = MRMedicalAccountExplanationController;
    this.templateUrl = mrMedicalAccountExplanationTemplate;
  }
}
