import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ILedgerState } from 'scripts/reducers/ledger-service-reducer';

const selectLedger = (state: IReduxState): ILedgerState => state.ledger;

export const selectAccounts = createSelector(
  selectLedger,
  ledger => ledger.accounts,
);

export const selectAccountsData = createSelector(
  selectAccounts,
  accounts => accounts.data,
);

export const selectAccountsLoading = createSelector(
  selectAccounts,
  accounts => accounts.loading,
);

export const selectAccountsError = createSelector(
  selectAccounts,
  accounts => accounts.error,
);
