import {
  getAllHealthcareError,
  getAllHealthcareLoading,
  getAllHealthcareSuccess,
  getSummaryError,
  getSummaryLoading,
  getSummarySuccess,
  getTotalsError,
  getTotalsLoading,
  getTotalsSuccess,
  saveClaimSuccess,
} from 'scripts/actions/claims-service-actions';
import { IClaim, IHealthcareClaimDetails, ClaimType } from 'scripts/api/claims/claims.interfaces';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { ClaimsApi } from 'scripts/api/claims/claims-api';
import CONFIG from 'scripts/util/constants/config';
import { ProfileApi } from 'scripts/api/profile/profile-api';
import {
  selectedUser,
  selectProfileData,
  selectHealthcareCoveragesData,
} from 'scripts/selectors/profile-service-selectors';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import { getCoverage } from 'scripts/util/profile/profile';
import { CoverageType } from 'scripts/api/api.interfaces';
import { getBenefits } from './plans-service-thunks';
import moment from 'moment';
import { getHealthcareCoverages } from './profile-service-thunks';
import { selectBenefitsData } from 'scripts/selectors/plans-service-selectors';

export function getSummary(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getSummaryLoading());
      const profile = selectedUser.selectProfile(getState());
      const { data, arcadeDataUpdated } = await ClaimsApi.getSummary(profile);
      dispatch(getSummarySuccess({ summary: data, arcadeDataUpdated }));
    } catch (error) {
      dispatch(getSummaryError());
    }
  };
}

export function saveClaim(claim: IClaim | IHealthcareClaimDetails): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const profile = selectedUser.selectProfile(getState());
      await ClaimsApi.saveClaim(profile, claim);
      const { claimKey } = claim;
      dispatch(saveClaimSuccess(claimKey));
    } catch (error) {
      console.warn(error);
    }
  };
}

export function getInstamedUrl(claimPayKey: string, returnUrl: string): ArcadeThunkAction<Promise<string>> {
  return async (_, getState) => {
    try {
      const rallyId = selectRallyId(getState());
      const {
        data: { ssoPayload },
      } = await ProfileApi.getInstamedMobileToken(rallyId, claimPayKey, returnUrl);
      return `${CONFIG.ARCADE_WEB_RALLY_AUTH_URL}/sso/v1/vendor/DHPMOBILEINSTAMED?attriData=${encodeURIComponent(
        ssoPayload,
      )}`;
    } catch (error) {
      console.warn('Unable to get instamed URL', error);
      return null;
    }
  };
}

export function getTotals(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getTotalsLoading());
      const profile = selectedUser.selectProfile(getState());
      const medicalCoverage = getCoverage(CoverageType.Medical, profile.planCoverages);
      if (medicalCoverage) {
        const { arcadeDataUpdated, data } = await ClaimsApi.getTotals(profile, ClaimType.Medical);
        dispatch(getTotalsSuccess({ totals: data, arcadeDataUpdated }));
      } else {
        dispatch(getTotalsSuccess({ totals: [] }));
      }
    } catch (error) {
      dispatch(getTotalsError());
    }
  };
}

export function getAllHealthcare(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getAllHealthcareLoading());
      const period = {
        startDate: moment()
          .subtract(18, 'M')
          .startOf('date'),
        endDate: moment().startOf('date'),
      };
      const profile = selectProfileData(getState());
      let healthcareCoverages = selectHealthcareCoveragesData(getState());
      if (!healthcareCoverages) {
        let benefits = selectBenefitsData(getState());
        if (!benefits) {
          await dispatch(getBenefits());
          benefits = selectBenefitsData(getState());
        }
        const showCarveoutClaims =
          benefits && benefits.benefits ? benefits.benefits.some(b => b.showRxCarveoutClaims) : false;
        await dispatch(getHealthcareCoverages(showCarveoutClaims));
        healthcareCoverages = selectHealthcareCoveragesData(getState());
      }
      if (!healthcareCoverages) {
        throw new Error('healthcare coverages not found');
      }
      const {
        data: { claims },
      } = await ClaimsApi.getAllHealthcare(profile, healthcareCoverages, period);
      dispatch(getAllHealthcareSuccess(claims));
    } catch (error) {
      dispatch(getAllHealthcareError());
    }
  };
}
