import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import IconExternalLink from 'images/icons/icon-external-link.svg';

const ExternalLinks: FunctionComponent = () => {
  const { t } = useTranslation([Dictionary.COMMON]);
  return (
    <>
      <IconExternalLink className="icon-external-link" aria-hidden={true} />
      <span className="sr-only" data-testid="external-link-accessibility">
        {t('OPENS_IN_NEW_WINDOW')}
      </span>
    </>
  );
};

export default ExternalLinks;
