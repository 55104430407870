import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { getMoneyValue } from 'scripts/util/money/money';
import IconInfo from 'images/icons/icon-info.svg';
import { Link } from 'scripts/ui/link/link';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

export interface IClaimBalanceProps {
  className: string;
  label: string;
  term?: string;
  value: number;
}

const TrackedLink = withClickTracking(Link, 'amount-billed-info');

export const ClaimBalance: FunctionComponent<IClaimBalanceProps> = props => {
  const { className, label, term, value } = props;
  return (
    <div className={classNames('claim-balance', className)}>
      {term && (
        <TrackedLink className="claim-balance-name" to={`/modal/glossary/${term}/`} data-testid="claim-balance-link">
          <IconInfo className="icon-info" aria-hidden="true" />
          <span>{label}</span>
        </TrackedLink>
      )}
      {!term && <div className="claim-balance-name">{label}</div>}
      <div className="claim-balance-value">{getMoneyValue(value)}</div>
    </div>
  );
};
