import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation, Trans } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { getRxProviderMap, getRxProvider } from 'scripts/util/profile/profile';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { hasActiveOrFutureUser } from 'scripts/util/plans/plans';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IdCardIconCircleCheck, IdCardIconCircleMinus } from './id-card-status-icons';

interface IIdCardRxCarveOutCoverageHeaderProps {
  coverage: ICoverageSection;
  population?: IPopulation;
  users: IProfileUser[];
}

export const IdCardRxCarveOutCoverageHeader: FunctionComponent<IIdCardRxCarveOutCoverageHeaderProps> = props => {
  const { coverage, population, users } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);
  const providerMap = getRxProviderMap(population);
  const rxProvider = getRxProvider(coverage);

  return (
    <header>
      <h2 key={'header-' + coverage.planKey} data-testid="id-card-rx-carveout-coverage-header">
        <ul className="clearfix">
          <li>
            <div>{hasActiveOrFutureUser(coverage, users) ? <IdCardIconCircleCheck /> : <IdCardIconCircleMinus />}</div>
            <span>{t(`${Dictionary.COMMON}:${coverage.coverageType}`)}</span>
          </li>
        </ul>
      </h2>
      {providerMap && rxProvider && (
        <p className="coverage-details" data-testid="show-rx-benefits-covered-through">
          <Trans
            i18nKey={`${Dictionary.ID_CARDS}:RX_BENEFITS_COVERED_THROUGH_REACT`}
            values={{ provider: providerMap[rxProvider].displayName }}
            data-testid="trans-benefits"
          >
            <strong />
          </Trans>
        </p>
      )}
    </header>
  );
};
