import React, { FunctionComponent } from 'react';
import { ActiveProviderReferralRow } from './active-provider-referral-row';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReferralInfo } from 'scripts/api/profile/profile.interfaces';

interface IActiveProviderReferralsTableProps {
  referrals: IReferralInfo[];
  population: IPopulation;
}

export const ActiveProviderReferralsTable: FunctionComponent<IActiveProviderReferralsTableProps> = props => {
  const { referrals, population } = props;
  return (
    <table className="active-provider-referrals-table" data-testid="active-provider-referrals-table">
      <tbody>
        {referrals.map((referral, index) => (
          <ActiveProviderReferralRow key={index} referral={referral} population={population} />
        ))}
      </tbody>
    </table>
  );
};
