import { Observable } from 'rxjs/Observable';
import { activateUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { getCache, getCacheKey } from '../cache';
import { IActivateStepsResponseV7, IActivateStepsResponseSwitch } from './activate.interfaces';

export class ActivateService implements IActivateService {
  constructor(private baseApiService: IBaseApiService) {
    'ngInject';
    getCache(CacheName.Activate).removeAll();
  }

  public getStepsV7(rallyId: string): Observable<IActivateStepsResponseV7> {
    const url = activateUris.stepsV7(rallyId);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public postPlanV7(rallyId: string, planKey: string, planVersion: number): Observable<IActivateStepsResponseV7> {
    const url = activateUris.stepsPostPlanV7(rallyId, planKey, planVersion);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.post(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public postStepsSwitch(
    rallyId: string,
    planKey: string,
    planVersion: number,
  ): Observable<IActivateStepsResponseSwitch> {
    const url = activateUris.stepsPostSwitch(rallyId, planKey, planVersion);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.post(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getStepsSwitch(rallyId: string): Observable<IActivateStepsResponseSwitch> {
    const url = activateUris.stepsSwitch(rallyId);
    const activateCache = getCache(CacheName.Activate);
    const cacheKey = getCacheKey(url);
    const cachedData = activateCache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => activateCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$);
  }
}

export interface IActivateService {
  getStepsV7(rallyId: string): Observable<IActivateStepsResponseV7>;
  postPlanV7(rallyId: string, planKey: string, planVersion: number): Observable<IActivateStepsResponseV7>;
  postStepsSwitch(rallyId: string, planKey: string, planVersion: number): Observable<IActivateStepsResponseSwitch>;
  getStepsSwitch(rallyId: string): Observable<IActivateStepsResponseSwitch>;
}
