/* eslint-disable max-len */
// IMPORTANT: polyfills must be the first thing imported
import 'scripts/polyfills';
import '@rally/arcade-ui-web-components';

// Probably should have this angular module only have angular stuff.
import angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-cookies';
import 'angular-google-analytics';
import 'angular-loading-bar';
import 'angular-moment';
import 'angular-sanitize';
import 'angular-touch';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-loader-static-files';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-ui-router';
import 'ng-redux';
import { react2angular } from 'react2angular';
import { StateChangeSuccess } from 'scripts/config/state-change-success';
import { StateChangeStart } from 'scripts/config/state-change-start';
import 'scripts/i18n';
import 'ui-router-extras';
import '../styles/arcade.styl';
import { ActivateService } from './api/activate/activate.service';
import './api/api.module';
import { DocumentsService } from './api/documents/documents.service';
import { UserService } from './api/user/user.service';
import { IRootScope } from './arcade.module.interfaces';
import { Amplitude } from './config/amplitude.config';
import { AdobeAnalytics, GoogleAnalytics } from './config/analytics.config';
import { DisableHttpCache, initializeApi, LoadingBar, Request, RxPromise } from './config/api.config';
import { IPerceptions } from './config/iperceptions.config';
import { Routes } from './config/routes.config';
import { EnglishStrings, Translate, TranslateEvents } from './config/translate.config';
import { ClaimLettersComponent } from './features/claims-and-accounts/claim-letters/claim-letters.component';
import { ClaimsAndAccountsComponent } from './features/claims-and-accounts/claims-and-accounts.component';
import { ClaimsAndAccountsRoutes } from './features/claims-and-accounts/claims-and-accounts.config';
import { AllClaimsComponent } from './features/claims-and-accounts/claims/all-claims.component';
import { ClaimDetailsBreakdownComponent } from './features/claims-and-accounts/claims/claim-details/claim-details-breakdown/claim-details-breakdown.component';
import { ClaimDetailsServicesComponent } from './features/claims-and-accounts/claims/claim-details/claim-details-services/claim-details-services.component';
import { ClaimDetailsSummaryComponent } from './features/claims-and-accounts/claims/claim-details/claim-details-summary/claim-details-summary.component';
import { ClaimDetailsComponent } from './features/claims-and-accounts/claims/claim-details/claim-details.component';
import { AccumulatorsComponent } from './features/claims-and-accounts/plan-balances/accumulators/accumulators.component';
import { AutoPaymentComponent } from './features/claims-and-accounts/plan-balances/auto-payment/auto-payment.component';
import { DirectDepositComponent } from './features/claims-and-accounts/plan-balances/direct-deposit/direct-deposit.component';
import { FinancialAccountsComponent } from './features/claims-and-accounts/plan-balances/financial-accounts/financial-accounts.component';
import { PlanBalancesBannerComponent } from './features/claims-and-accounts/plan-balances/plan-balances-banner.component';
import { PlanBalancesMessageComponent } from './features/claims-and-accounts/plan-balances/plan-balances-message/plan-balances-message.component';
import { PlanBalancesComponent } from './features/claims-and-accounts/plan-balances/plan-balances.component';
import { SpendingAndCostSummaryAccumulators } from './features/claims-and-accounts/spending-and-cost-summary/spending-and-cost-summary-accumulators';
import { SpendingAndCostSummaryComponent } from './features/claims-and-accounts/spending-and-cost-summary/spending-and-cost-summary.component';
import { StatementsComponent } from './features/claims-and-accounts/statements/statements.component';
import { SubmitClaimHeaderComponent } from './features/claims-and-accounts/submit/submit-claim-header/submit-claim-header.component';
import { SubmitClaimHipaaFormsComponent } from './features/claims-and-accounts/submit/submit-claim-hipaa-forms/submit-claim-hipaa-forms.component';
import { SubmitClaimOtherFormsComponent } from './features/claims-and-accounts/submit/submit-claim-other-forms/submit-claim-other-forms.component';
import { SubmitClaimComponent } from './features/claims-and-accounts/submit/submit-claim.component';
import { AdditionalLinksComponent } from './features/claims-and-accounts/summary/additional-links/additional-links.component';
import { ClaimsNeedAttentionComponent } from './features/claims-and-accounts/summary/claims-need-attention/claims-need-attention.component';
import { DentalAccountSummaryComponent } from './features/claims-and-accounts/summary/dental-account-summary/dental-account-summary.component';
import { SummaryComponent } from './features/claims-and-accounts/summary/summary.component';
import { AccountInfoComponent } from './features/dashboard/account-info/account-info.component';
import { AccountSummaryService } from './features/dashboard/account-summary/account-summary-explanation.service';
import { AccountSummaryComponent } from './features/dashboard/account-summary/account-summary.component';
import { AccumulatorsSummaryMaxComponent } from './features/dashboard/accumulators-summary/accumulators-summary-max.component';
import { AccumulatorsSummaryComponent } from './features/dashboard/accumulators-summary/accumulators-summary.component';
import { AdditionalServicesComponent } from './features/dashboard/additional-services/additional-services.component';
import { CostsComponent } from './features/dashboard/costs/costs.component';
import { CSCostsComponent } from './features/dashboard/cs-costs/cs-costs.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { ChromeContainerComponent } from './features/chrome-container/chrome-container.component';
import { DrugCostsComponent } from './features/dashboard/drug-costs/drug-costs.component';
import { FitbitBannerComponent } from './features/dashboard/fitbit/fitbit-banner.component';
import { FitbitStatusService } from './features/dashboard/fitbit/fitbit-status.service';
import { IncentivesComponent } from './features/dashboard/incentives/incentives.component';
import { LinkBarComponent } from './features/dashboard/link-bar/link-bar.component';
import { MRAccountSummary } from './features/dashboard/mr-account-summary/mr-account-summary';
import { MRCostsComponent } from './features/dashboard/mr-costs/mr-costs.component';
import { MrRxSpendingCostSummary } from './features/dashboard/mr-rx-spending-cost-summary/mr-rx-spending-cost-summary';
import { PcpReferralsComponent } from './features/pcp-referrals/pcp-referrals.component';
import { PcpComponent } from './features/dashboard/pcp/pcp.component';
import { PromosDirective } from './features/dashboard/promos/promos.directive';
import { CsQuickLinksComponent } from './features/dashboard/quick-links/cs-quick-links.component';
import { MrQuickLinksComponent } from './features/dashboard/quick-links/mr-quick-links.component';
import { QuickLinks } from './features/dashboard/quick-links/quick-links';
import { QuickLinksComponent } from './features/dashboard/quick-links/quick-links.component';
import { RecentClaimsComponent } from './features/dashboard/recent-claims/recent-claims.component';
import { SingleStepBannerComponent } from './features/dashboard/single-step-banners/single-step-banner.component';
import { SingleStepContainerComponent } from './features/dashboard/single-step-banners/single-step-container.component';
import { GuidedSearchComponent } from './features/find-care/guided-search/guided-search.component';
import { FooterContainerComponent } from './features/footer-container/footer-container.component';
import { HeaderContainerComponent } from './features/header-container/header-container.component';
import { ActivateBannerComponent } from './features/header/activate-banner.component';
import { ExpirationWarningComponent } from './features/header/expiration-warning.component';
import { NotificationComponent } from './features/header/notification.component';
import { OnboardingBannerComponent } from './features/header/onboarding-banner.component';
import { SuperUserBannerComponent } from './features/header/super-user-banner.component';
import { TermedMemberBanner } from 'scripts/features/header/termed-member-banner';
import { VideoBannerComponent } from './features/header/video-banner.component';
import { ContactUsComponent } from './features/help/contact-us/contact-us.component';
import { FaqComponent } from './features/help/faq/faq.component';
import { HelpComponent } from './features/help/help.component';
import { InternalErrorComponent } from './features/internal-error/internal-error.component';
import { InternalRedirectComponent } from './features/internal-redirect/internal-redirect.component';
import { LoginComponent } from './features/login/login.component';
import { AccountSelectorComponent } from './features/modals/account-selector/account-selector.component';
import { AccountSummaryExplanationComponent } from './features/modals/account-summary/account-summary-explanation.component';
import { ActivateVideoTranscriptComponent } from './features/modals/activate-video-transcript/activate-video-transcript.component';
import { ActivateVideoComponent } from './features/modals/activate-video/activate-video.component';
import {
  AllClaimsFaqComponent,
  AllClaimsFaqController,
} from './features/modals/all-claims-faq/all-claims-faq.component';
import { AllClaimsPrintComponent } from './features/modals/all-claims-print/all-claims-print.component';
import { AllClaimsRefineResultsComponent } from './features/modals/all-claims-refine-results/all-claims-refine-results.component';
import { AllClaimsSelectDatesComponent } from './features/modals/all-claims-select-dates/all-claims-select-dates.component';
import { ClaimDeniedExplanationController } from './features/modals/claim-denied-explanation/claim-denied-explanation.controller';
import { ClaimVideoComponent } from './features/modals/claim-video/claim-video.component';
import { ClaimsAndAccountsModalController } from './features/modals/claims-and-accounts/claims-and-accounts-modal.controller';
import { CompareCareComponent } from './features/modals/compare-care/compare-care.component';
import { CostInfoComponent } from './features/modals/cost-info/cost-info.component';
import { CostInfoController } from './features/modals/cost-info/cost-info.controller';
import { CostCopy } from './features/modals/cost-info/cost-info.interfaces';
import { CostInfoService } from './features/modals/cost-info/cost-info.service';
import { CustomFaqComponent } from './features/modals/custom-faq/custom-faq.component';
import { DrugTiersComponent } from './features/modals/drug-tiers/drug-tiers.component';
import {
  EditClaimNoteComponent,
  EditClaimNoteController,
} from './features/modals/edit-claim-note/edit-claim-note.controller';
import { EobErrorComponent } from './features/modals/eob-error/eob-error.component';
import { FinancialAccountsModalController } from './features/modals/financial-accounts/financial-accounts-modal.controller';
import { HraapController } from './features/modals/hraap/hraap.controller';
import { IdCard } from './features/modals/id-cards/id-card';
import { IdCardsComponent } from './features/modals/id-cards/id-cards.component';
import { InactiveComponent } from './features/modals/inactive/inactive.component';
import { MarkAsPaidExplanationController } from './features/modals/mark-as-paid-explanation/mark-as-paid-explanation.controller';
import { MedicalAccountExplanationComponent } from './features/modals/medical-account-explanation/medical-account-explanation.component';
import { OrthoMaxExplanationController } from './features/modals/ortho-max-explanation/ortho-max-explanation.controller';
import { PayNowComponent } from './features/modals/pay-now/pay-now.component';
import { PcdConfirmationComponent } from './features/modals/pcd-confirmation/pcd-confirmation.component';
import { PcpChangeUnavailableComponent } from './features/modals/pcp-change-unavailable/pcp-change-unavailable.component';
import { PcpChangeComponent } from './features/modals/pcp/pcp-change.component';
import { SeeYouLaterComponent } from './features/modals/see-you-later/see-you-later.component';
import { TipsToSaveComponent } from './features/modals/tips-to-save/tips-to-save.component';
import { CommunicationPreferenceService } from './features/onboarding/communication-preference.service';
import { CommunicationPreferenceComponent } from './features/onboarding/communication-preference/communication-preference.component';
import { OnboardingStatusService } from './features/onboarding/onboarding-status.service';
import { PharmacyComponent } from './features/pharmacy/pharmacy.component';
import { PremiumPaymentsComponent } from './features/premium-payments/premium-payments.component';
import { ProgramsComponent } from './features/programs/programs.component';
import { PromoPanelsComponent } from './features/programs/promo-panels/promo-panels.component';
import { PromoTilesComponent } from './features/programs/promo-tiles/promo-tiles.component';
import { ResourcesComponent } from './features/programs/resources/resources.component';
import { WellnessComponent } from './features/programs/wellness/wellness.component';
import { RecommendationsComponent } from './features/recommendations/recommendations.component';
import { SharedFooterComponent } from './features/shared-header-footer/shared-footer.component';
import { SharedHeaderComponent } from './features/shared-header-footer/shared-header.component';
import { SubNavComponent } from './features/sub-nav/sub-nav.component';
import { UnauthorizedErrorComponent } from './features/unauthorized-error/unauthorized-error.component';
import { NgStore } from './store/ng-store';
import { AccessibleOutlinesDirective } from './ui/accessible-outlines/accessible-outlines.directive';
import { AccordionDirective, AccordionItemDirective } from './ui/accordion/accordion.directives';
import { AccumulatorComponent } from './ui/accumulator/accumulator.component';
import { HraapAccumulatorComponent } from './ui/accumulator/hraap-accumulator.component';
import { AnchorDirective } from './ui/anchor/anchor.directive';
import { BindHtmlCompileDirective } from './ui/bind-html-compile/bind-html-compile.directive';
import { CarouselDirective, SlideDirective } from './ui/carousel/carousel.directives';
import { ClaimHelpMenuComponent } from './ui/claim-help-menu/claim-help-menu.component';
import { Claim } from './ui/claim/claim';
import { SubmitClaimOtherForms } from './features/claims-and-accounts/submit/submit-claim-other-forms/submit-claim-other-forms';
import { SubmitClaimHeader } from './features/claims-and-accounts/submit/submit-claim-header/submit-claim-header';
import { ClaimMarkPaidComponent } from './ui/claim/claim-mark-paid.component';
import { ClaimPayNowComponent } from './ui/claim/claim-pay-now.component';
import { ContentHopperDirective, HopSpotDirective } from './ui/content-hopper/content-hopper.directives';
import { DateSelectorComponent } from './ui/date-selector/date-selector.component';
import { DonutComponent } from './ui/donut/donut.component';
import { Dropdown } from './ui/dropdown/dropdown';
import { DropdownDirective } from './ui/dropdown/dropdown.directive';
import { FeatureFlagDirective } from './ui/feature-flag/feature-flag.directives';
import { FilterComponent } from './ui/filter/filter.component';
import { FilterService } from './ui/filter/filter.service';
import { InViewDirective } from './ui/in-view/in-view.directive';
import { InactiveCoverageComponent } from './ui/inactive-coverage/inactive-coverage.component';
import { InfiniteScrollComponent } from './ui/infinite-scroll/infinite-scroll.component';
import {
  MelodeonContentDirective,
  MelodeonDirective,
  MelodeonToggleAllDirective,
  MelodeonToggleDirective,
} from './ui/melodeon/melodeon.directive';
import { ModalDirective } from './ui/modal/modal.directive';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { PieChartComponent } from './ui/pie-chart/pie-chart.component';
import { PrintHeaderComponent } from './ui/print/print-header.component';
import { PrintButtonDirective, PrintDirective, PrintElementDirective } from './ui/print/print.directives';
import RallyPayWidget from './ui/rally-pay-widget/rally-pay-widget';
import { RolodexCardDirective, RolodexDirective } from './ui/rolodex/rolodex.directive';
import { SharedHeaderFooterEventsDirective } from './ui/shared-header-footer-events/shared-header-footer-events.directive';
import { ShowForLobDirective } from './ui/show-for-lob/show-for-lob.directives';
import { StickyDirective } from './ui/sticky/sticky.directive';
import { SundaySkyTrackDirective } from './ui/sunday-sky/sunday-sky-track.directives';
import { SundaySkyVideoComponent } from './ui/sunday-sky/sunday-sky-video.component';
import { TrackDirective, TrackFeatureDirective } from './ui/track/track.directives';
import { WaitForContainerDirective, WaitForDirective } from './ui/wait-for/wait-for.directive';
import './util/constants/environment.constants';
import { EnvironmentConstants } from './util/constants/environment.constants';
import { FilesConstant } from './util/constants/files.constant';
import { ContentOverrideFilter } from './util/content-override/content-override.filter';
import { FeatureFlagService } from './util/feature-flag/feature-flag.service';
import { GenesysService } from './util/genesys/genesys.service';
import { LocaleService } from './util/locale/locale.service';
import { PopulationService } from './util/population/population.service';
import { ResourceService } from './util/resource/resource.service';
import { StateService } from './util/state/state.service';
import { ProviderReferrals } from './features/pcp-referrals/provider-referrals';
import { AdvantageChromeContainerComponent } from './features/advantage/advantage-chrome-container.component';
import { EIAccountSummaryExplanationComponent } from './features/modals/account-summary/ei-account-summary-explanation.component';
import { MRAccountSummaryExplanationComponent } from './features/modals/account-summary/mr-account-summary-explanation.component';
import { HealthClaimBalanceExplanationComponent } from './features/modals/health-claim-balance-explanation/health-claim-balance-explanation.component';
import { EIMedicalAccountExplanationComponent } from './features/modals/medical-account-explanation/ei-medical-account-explanation.component';
import { MRMedicalAccountExplanationComponent } from './features/modals/medical-account-explanation/mr-medical-account-explanation.component';
import { ThemeDirective } from 'scripts/ui/theme/theme.directive';
import { ArachneHeartbeatService } from 'scripts/api/user/arachne-heartbeat.service';
import { AdvantageService } from 'scripts/api/advantage/advantage.service';
import { VersionedIncludeDirective } from 'scripts/ui/versioned-include/versioned-include.directive';
import { AllClaims } from './features/claims-and-accounts/claims/all-claims';
import { AllClaimsContainerComponent } from './features/claims-and-accounts/claims/all-claims-container.component';
import { ArcadeWebTheme, IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { PcpStrip } from './features/dashboard/pcp/pcp-strip';

class GeneralSetup {
  constructor($animate: ng.animate.IAnimateService, $rootScope: IRootScope, Environment: IEnvironmentConstants) {
    'ngInject';
    $animate.enabled(true);
    $rootScope.showPage = false;
    $rootScope.advantage = Environment.CONFIG.ARCADE_WEB_THEME === ArcadeWebTheme.Advantage;
  }
}

angular
  .module('arcade', [
    'angular-google-analytics',
    'angular-loading-bar',
    'angularMoment',
    'arcade.api',
    'arcade.environment',
    'ct.ui.router.extras',
    'ngAria',
    'ngCookies',
    'ngSanitize',
    'ngTouch',
    'pascalprecht.translate',
    'ui.router',
    'ngAnimate',
    'ngRedux',
  ])

  .config(GoogleAnalytics)
  .config(LoadingBar)
  .config(Request)
  .config(Routes)
  .config(initializeApi)
  .config(Translate)
  .config(ClaimsAndAccountsRoutes)
  .config(NgStore)

  .run(AdobeAnalytics)
  .run(DisableHttpCache)
  .run(GeneralSetup)
  .run(IPerceptions)
  .run(Amplitude)
  .run(StateChangeStart)
  .run(StateChangeSuccess)
  .run(TranslateEvents)
  .run(EnglishStrings)
  .run(RxPromise)

  .constant('Environment', EnvironmentConstants.constants)
  .constant('CostCopy', CostCopy)
  .constant('Files', FilesConstant.constants)

  .component('accountInfo', new AccountInfoComponent())
  .component('accountSelector', new AccountSelectorComponent())
  .component('accountSummary', new AccountSummaryComponent())
  .component('advantageChromeContainer', new AdvantageChromeContainerComponent())
  .component('accountSummaryExplanation', new AccountSummaryExplanationComponent())
  .component('eiAccountSummaryExplanation', new EIAccountSummaryExplanationComponent())
  .component('mrAccountSummaryExplanation', new MRAccountSummaryExplanationComponent())
  .component('healthClaimBalanceExplanation', new HealthClaimBalanceExplanationComponent())
  .component('accumulator', new AccumulatorComponent())
  .component('accumulatorsSummary', new AccumulatorsSummaryComponent())
  .component('accumulatorsSummaryMax', new AccumulatorsSummaryMaxComponent())
  .component('allClaimsFaq', new AllClaimsFaqComponent())
  .component('arcadeFooterContainer', new FooterContainerComponent())
  .component('arcadeHeaderContainer', new HeaderContainerComponent())
  .component('arcadeSharedFooter', new SharedFooterComponent())
  .component('arcadeSharedHeader', new SharedHeaderComponent())
  .component('autoPayment', new AutoPaymentComponent())
  .component('compareCare', new CompareCareComponent())
  .component('contactUs', new ContactUsComponent())
  .component('costInfo', new CostInfoComponent())
  .component('costs', new CostsComponent())
  .component('csCosts', new CSCostsComponent())
  .component('dashboard', new DashboardComponent())
  .component('chromeContainer', new ChromeContainerComponent())
  .component('editClaimNote', new EditClaimNoteComponent())
  .component('eobError', new EobErrorComponent())
  .component('faq', new FaqComponent())
  .component('filter', new FilterComponent())
  .component('guidedSearch', new GuidedSearchComponent())
  .component('help', new HelpComponent())
  .component('hraapAccumulator', new HraapAccumulatorComponent())
  .component('idCards', new IdCardsComponent())
  .component('inactive', new InactiveComponent())
  .component('inactiveCoverage', new InactiveCoverageComponent())
  .component('incentives', new IncentivesComponent())
  .component('internalError', new InternalErrorComponent())
  .component('unauthorizedError', new UnauthorizedErrorComponent())
  .component('internalRedirect', new InternalRedirectComponent())
  .component('login', new LoginComponent())
  .component('mrCosts', new MRCostsComponent())
  .component('recentClaims', new RecentClaimsComponent())
  .component('tipsToSave', new TipsToSaveComponent())
  .component('subNav', new SubNavComponent())
  .component('programs', new ProgramsComponent())
  .component('claimsAndAccounts', new ClaimsAndAccountsComponent())
  .component('planBalances', new PlanBalancesComponent())
  .component('quickLinks', new QuickLinksComponent())
  .component('mrQuickLinks', new MrQuickLinksComponent())
  .component('csQuickLinks', new CsQuickLinksComponent())
  .component('planBalancesMessage', new PlanBalancesMessageComponent())
  .component('financialAccounts', new FinancialAccountsComponent())
  .component('accumulators', new AccumulatorsComponent())
  .component('planBalancesBanner', new PlanBalancesBannerComponent())
  .component('wellness', new WellnessComponent())
  .component('resources', new ResourcesComponent())
  .component('pharmacy', new PharmacyComponent())
  .component('premiumPayments', new PremiumPaymentsComponent())
  .component('promoPanels', new PromoPanelsComponent())
  .component('promoTiles', new PromoTilesComponent())
  .component('donut', new DonutComponent())
  .component('pieChart', new PieChartComponent())
  .component('pcp', new PcpComponent())
  .component('pcpChangeInProgress', new PcpChangeComponent())
  .component('pcpReferrals', new PcpReferralsComponent())
  .component('providerReferrals', react2angular(ProviderReferrals))
  .component('printHeader', new PrintHeaderComponent())
  .component('customFaq', new CustomFaqComponent())
  .component('linkBar', new LinkBarComponent())
  .component('notification', new NotificationComponent())
  .component('expirationWarning', new ExpirationWarningComponent())
  .component('drugCosts', new DrugCostsComponent())
  .component('communicationPreference', new CommunicationPreferenceComponent())
  .component('claimsAccountsSummary', new SummaryComponent())
  .component('dentalAccountSummary', new DentalAccountSummaryComponent())
  .component('additionalLinks', new AdditionalLinksComponent())
  .component('claimsNeedAttention', new ClaimsNeedAttentionComponent())
  .component('onboardingBanner', new OnboardingBannerComponent())
  .component('fitbitBanner', new FitbitBannerComponent())
  .component('allClaimsContainer', new AllClaimsContainerComponent())
  .component('allClaims', new AllClaimsComponent())
  .component('reactAllClaims', react2angular(AllClaims))
  .component('recommendations', new RecommendationsComponent())
  .component('infiniteScroll', new InfiniteScrollComponent())
  .component('drugTiers', new DrugTiersComponent())
  .component('allClaimsRefineResults', new AllClaimsRefineResultsComponent())
  .component('allClaimsPrint', new AllClaimsPrintComponent())
  .component('superUserBanner', new SuperUserBannerComponent())
  .component('allClaimsSelectDates', new AllClaimsSelectDatesComponent())
  .component('dateSelector', new DateSelectorComponent())
  .component('seeYouLater', new SeeYouLaterComponent())
  .component('pagination', new PaginationComponent())
  .component('claimVideo', new ClaimVideoComponent())
  .component('overviewVideo', new ActivateVideoComponent())
  .component('overviewVideoTranscript', new ActivateVideoTranscriptComponent())
  .component('claimDetails', new ClaimDetailsComponent())
  .component('arcadeSundaySkyVideo', new SundaySkyVideoComponent())
  .component('claimDetailsSummary', new ClaimDetailsSummaryComponent())
  .component('claimPayNow', new ClaimPayNowComponent())
  .component('payNowModal', new PayNowComponent())
  .component('claimMarkPaid', new ClaimMarkPaidComponent())
  .component('claimDetailsBreakdown', new ClaimDetailsBreakdownComponent())
  .component('claimDetailsServices', new ClaimDetailsServicesComponent())
  .component('activateBanner', new ActivateBannerComponent())
  .component('videoBanner', new VideoBannerComponent())
  .component('termedMemberBanner', react2angular(TermedMemberBanner))
  .component('singleStepContainer', new SingleStepContainerComponent())
  .component('singleStepBanner', new SingleStepBannerComponent())
  .component('additionalServices', new AdditionalServicesComponent())
  .component('submitClaim', new SubmitClaimComponent())
  .component('submitClaimHeader', new SubmitClaimHeaderComponent())
  .component('submitClaimOtherForms', new SubmitClaimOtherFormsComponent())
  .component('submitClaimHipaaForms', new SubmitClaimHipaaFormsComponent())
  .component('directDeposit', new DirectDepositComponent())
  .component('pcdConfirmation', new PcdConfirmationComponent())
  .component('statements', new StatementsComponent())
  .component('claimLetters', new ClaimLettersComponent())
  .component('pcpChangeUnavailable', new PcpChangeUnavailableComponent())
  .component('claimHelpMenu', new ClaimHelpMenuComponent())
  .component('medicalAccountExplanation', new MedicalAccountExplanationComponent())
  .component('eiMedicalAccountExplanation', new EIMedicalAccountExplanationComponent())
  .component('mrMedicalAccountExplanation', new MRMedicalAccountExplanationComponent())
  .component('rallyPayWidget', react2angular(RallyPayWidget, ['onMessage', 'claimsInfoPacket', 'token']))
  .component(
    'reactDropdown',
    react2angular(Dropdown, [
      'className',
      'dictionary',
      'id',
      'label',
      'labelSelected',
      'nav',
      'onSelect',
      'options',
      'selected',
      'toggleClass',
    ]),
  )
  .component('spendingAndCostSummary', new SpendingAndCostSummaryComponent())
  .component('spendingAndCostSummaryAccumulators', react2angular(SpendingAndCostSummaryAccumulators))
  .component('mrRxSpendingCostSummary', react2angular(MrRxSpendingCostSummary))
  .component('reactClaim', react2angular(Claim, ['claim', 'clientConfig', 'onClaimUpdate']))
  .component('reactQuickLinks', react2angular(QuickLinks))
  .component('reactIdCard', react2angular(IdCard))
  .component('reactSubmitClaimOtherForms', react2angular(SubmitClaimOtherForms))
  .component('reactSubmitClaimHeader', react2angular(SubmitClaimHeader))
  .component('reactMrAccountSummary', react2angular(MRAccountSummary))
  .component('reactPcpStrip', react2angular(PcpStrip))

  .directive('promos', PromosDirective.Factory())
  .directive('accordion', AccordionDirective.Factory())
  .directive('accordionItem', AccordionItemDirective.Factory())
  .directive('melodeon', MelodeonDirective.Factory())
  .directive('melodeonToggle', MelodeonToggleDirective.Factory())
  .directive('melodeonToggleAll', MelodeonToggleAllDirective.Factory())
  .directive('melodeonContent', MelodeonContentDirective.Factory())
  .directive('accessibleOutlines', AccessibleOutlinesDirective.Factory())
  .directive('modal', ModalDirective.Factory())
  .directive('carousel', CarouselDirective.Factory())
  .directive('slide', SlideDirective.Factory())
  .directive('contentHopper', ContentHopperDirective.Factory())
  .directive('hopSpot', HopSpotDirective.Factory())
  .directive('dropdown', DropdownDirective.Factory())
  .directive('featureFlag', FeatureFlagDirective.Factory())
  .directive('print', PrintDirective.Factory())
  .directive('printButton', PrintButtonDirective.Factory())
  .directive('printElement', PrintElementDirective.Factory())
  .directive('sharedHeaderFooterEvents', SharedHeaderFooterEventsDirective.Factory())
  .directive('showForLob', ShowForLobDirective.Factory())
  .directive('sticky', StickyDirective.Factory())
  .directive('track', TrackDirective.Factory())
  .directive('trackSundaySky', SundaySkyTrackDirective.Factory())
  .directive('trackFeature', TrackFeatureDirective.Factory())
  .directive('a', AnchorDirective.Factory())
  .directive('waitFor', WaitForDirective.Factory())
  .directive('waitForContainer', WaitForContainerDirective.Factory())
  .directive('rolodex', RolodexDirective.Factory())
  .directive('rolodexCard', RolodexCardDirective.Factory())
  .directive('bindHtmlCompile', BindHtmlCompileDirective.Factory())
  .directive('inView', InViewDirective.Factory())
  .directive('theme', ThemeDirective.Factory())
  .directive('versionedInclude', VersionedIncludeDirective.Factory())

  .service('costInfoService', CostInfoService)
  .service('localeService', LocaleService)
  .service('userService', UserService)
  .service('arachneHeartbeatService', ArachneHeartbeatService)
  .service('populationService', PopulationService)
  .service('accountSummaryService', AccountSummaryService)
  .service('resourceService', ResourceService)
  .service('stateService', StateService)
  .service('featureFlagService', FeatureFlagService)
  .service('communicationPreferenceService', CommunicationPreferenceService)
  .service('filterService', FilterService)
  .service('onboardingStatusService', OnboardingStatusService)
  .service('fitbitStatusService', FitbitStatusService)
  .service('activateService', ActivateService)
  .service('documentsService', DocumentsService)
  .service('genesysService', GenesysService)
  .service('advantageService', AdvantageService)

  .filter('contentOverride', ContentOverrideFilter.Factory())

  .controller('allClaimsFaqController', AllClaimsFaqController)
  .controller('editClaimNoteController', EditClaimNoteController)
  .controller('costInfoController', CostInfoController)
  .controller('claimDeniedExplanationController', ClaimDeniedExplanationController)
  .controller('markAsPaidExplanationController', MarkAsPaidExplanationController)
  .controller('hraapController', HraapController)
  .controller('financialAccountsModalController', FinancialAccountsModalController)
  .controller('claimsAndAccountsModalController', ClaimsAndAccountsModalController)
  .controller('orthoMaxExplanationController', OrthoMaxExplanationController);
