import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection, CardSide } from './id-cards.interfaces';
import { IIdCard } from 'scripts/api/plans/plans.interfaces';
import classNames from 'classnames';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Button } from 'scripts/ui/button/button';
import { Anchor } from 'scripts/ui/anchor/anchor';
import { hasActiveUser } from 'scripts/util/plans/plans';
import { IProfileUser, LineOfBusiness, State } from 'scripts/api/profile/profile.interfaces';
import { idCardsByMail } from 'scripts/util/resource/resource.constants';
import { getResource } from 'scripts/util/resource/resource';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { FeatureFlagGate } from 'scripts/ui/feature-flag-gate/feature-flag-gate';
import { LinkTarget } from 'scripts/api/api.interfaces';

interface IIdCardActionsProps {
  currentUser: IProfileUser;
  users: IProfileUser[];
  currentCoverageIdCards: IIdCard[];
  coverage: ICoverageSection;
  flipped: boolean;
  isCS: boolean;
  population?: IPopulation;
  flipCard: () => void;
}

export const IdCardActions: FunctionComponent<IIdCardActionsProps> = props => {
  const { currentCoverageIdCards, coverage, flipped, flipCard, isCS, currentUser, users, population } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);
  const viewOptionButton = flipped ? t(`${Dictionary.ID_CARDS}:VIEW_FRONT`) : t(`${Dictionary.ID_CARDS}:VIEW_BACK`);
  const viewOptionTrack = flipped ? CardSide.Front : CardSide.Back;
  const viewPrintOption =
    currentCoverageIdCards.length > 1
      ? t(`${Dictionary.ID_CARDS}:PRINT_ID_CARDS`)
      : t(`${Dictionary.ID_CARDS}:PRINT_ID_CARD`);
  const anchorTarget = isCS ? LinkTarget.Blank : LinkTarget.Self;
  const buttonAriaLabel =
    currentCoverageIdCards.length > 1 ? t(`${Dictionary.ID_CARDS}:ID_CARDS`) : t(`${Dictionary.ID_CARDS}:ID_CARD`);
  const cardsLabel =
    currentCoverageIdCards.length > 1
      ? t(`${Dictionary.ID_CARDS}:MAIL_ID_CARDS`)
      : t(`${Dictionary.ID_CARDS}:MAIL_ID_CARD`);
  const TrackedButton = withClickTracking(Button, viewOptionTrack);
  const TrackedPrintButton = withClickTracking(Button, 'print');
  const TrackedAnchor = withClickTracking(Anchor, 'mail');
  const isCSNewJersey = currentUser.userInfo.state === State.NJ && isCS;
  const hasGeneratedIdCards = (currentCoverageIdCards: IIdCard[]): boolean => {
    return currentCoverageIdCards.every(c => c.generatedImage);
  };
  const getMailLink = (user: IProfileUser, coverage: ICoverageSection): string => {
    const mailLink = getResource(idCardsByMail, population);
    return user.lineOfBusiness === LineOfBusiness.EI
      ? mailLink + '?cardType=' + coverage.coverageType + '&depSqrNbr=' + user.dependentSeqNum
      : mailLink;
  };

  return (
    <>
      <div className="inline-buttons" data-testid="id-card-actions">
        {!hasGeneratedIdCards(currentCoverageIdCards) && (
          <TrackedButton
            data-testid="view-option-button"
            className={classNames('cta link view-back', { flip: flipped })}
            aria-label={
              viewOptionButton + ' ' + buttonAriaLabel + ' ' + t(`${Dictionary.COMMON}:${coverage.coverageType}`)
            }
            onClick={flipCard}
          >
            {viewOptionButton}
          </TrackedButton>
        )}
        {hasActiveUser(coverage, users) && (!hasGeneratedIdCards(currentCoverageIdCards) || !isCS) && (
          <FeatureFlagGate
            flagName={'ARCADE_FEATURES_ADVANTAGE'}
            whenTrue={null}
            whenFalse={
              <TrackedAnchor
                className="cta link mail-id"
                aria-label={cardsLabel + ' ' + t(`${Dictionary.COMMON}:${coverage.coverageType}`)}
                href={getMailLink(currentUser, coverage)}
                target={anchorTarget}
              >
                {cardsLabel}
              </TrackedAnchor>
            }
          />
        )}
      </div>
      {!isCSNewJersey && (!hasGeneratedIdCards(currentCoverageIdCards) || isCS) && (
        // TODO add print-button attribute
        <TrackedPrintButton data-testid="print-id-card-button" className="cta-btn">
          {viewPrintOption}
        </TrackedPrintButton>
      )}
    </>
  );
};
