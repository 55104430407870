import { ICampaigns, IClientConfig } from 'scripts/api/targeting/targeting.interfaces';

export enum TargetingServiceActionTypes {
  GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR',
  GET_CAMPAIGNS_LOADING = 'GET_CAMPAIGNS_LOADING',
  GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS',
  GET_CLIENT_CONFIG_ERROR = 'GET_CLIENT_CONFIG_ERROR',
  GET_CLIENT_CONFIG_LOADING = 'GET_CLIENT_CONFIG_LOADING',
  GET_CLIENT_CONFIG_SUCCESS = 'GET_CLIENT_CONFIG_SUCCESS',
}

export interface IGetCampaignsLoading {
  type: TargetingServiceActionTypes.GET_CAMPAIGNS_LOADING;
}

export interface IGetCampaignsSuccess {
  type: TargetingServiceActionTypes.GET_CAMPAIGNS_SUCCESS;
  payload: ICampaigns;
}

export interface IGetCampaignsError {
  type: TargetingServiceActionTypes.GET_CAMPAIGNS_ERROR;
}

export interface IGetClientConfigLoading {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_LOADING;
}

export interface IGetClientConfigSuccess {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_SUCCESS;
  payload: IClientConfig;
}

export interface IGetClientConfigError {
  type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_ERROR;
}

export function getCampaignsLoading(): IGetCampaignsLoading {
  return {
    type: TargetingServiceActionTypes.GET_CAMPAIGNS_LOADING,
  };
}

export function getCampaignsSuccess(payload: ICampaigns): IGetCampaignsSuccess {
  return {
    type: TargetingServiceActionTypes.GET_CAMPAIGNS_SUCCESS,
    payload,
  };
}

export function getCampaignsError(): IGetCampaignsError {
  return {
    type: TargetingServiceActionTypes.GET_CAMPAIGNS_ERROR,
  };
}

export function getClientConfigLoading(): IGetClientConfigLoading {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_LOADING,
  };
}

export function getClientConfigSuccess(payload: IClientConfig): IGetClientConfigSuccess {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_SUCCESS,
    payload,
  };
}

export function getClientConfigError(): IGetClientConfigError {
  return {
    type: TargetingServiceActionTypes.GET_CLIENT_CONFIG_ERROR,
  };
}

export type TargetingServiceAction =
  | IGetCampaignsLoading
  | IGetCampaignsSuccess
  | IGetCampaignsError
  | IGetClientConfigLoading
  | IGetClientConfigSuccess
  | IGetClientConfigError;
