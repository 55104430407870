import { PopulationActionTypes } from 'scripts/actions/population-actions';
import { ArcadeAction } from 'scripts/reducers/reducer.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
export { IPopulation as IPopulationState } from 'scripts/util/population/population.interfaces';

export const initialState: IPopulation = null;

export default (state: IPopulation = initialState, action: ArcadeAction): IPopulation => {
  switch (action.type) {
    case PopulationActionTypes.SET_POPULATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
