import { activateStart } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import singleStepBannerTemplate from 'views/dashboard/single-step-banner.html';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { ISingleStep, NonFtueStepName } from './single-step-container.component';
import { checkForBannerSuppressionCookie } from 'scripts/config/routes.config';
import { ActivatePlanKey } from 'scripts/api/activate/activate.interfaces';

const getPlanIdFromStepId = (stepId: string): string => {
  switch (stepId) {
    case NonFtueStepName.cob:
      return ActivatePlanKey.COB;
    case NonFtueStepName.emailBounce:
      return ActivatePlanKey.EMAIL_BOUNCE;
    default:
      return '';
  }
};

export class SingleStepBannerController {
  public activateResourceLink: IResource;
  public toActivateHref: string;
  public step: ISingleStep;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private resourceService: IResourceService,
    private $window: ng.IWindowService,
    private $cookies: ng.cookies.ICookiesService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activateStart;
  }

  public toActivate(): void {
    const planId = getPlanIdFromStepId(this.step.stepId);
    if (planId !== '') {
      checkForBannerSuppressionCookie(this.$cookies, planId);
    }
    if (this.step.url) {
      this.$window.location.href = `${this.step.url}&isModal=true`;
    } else {
      this.$window.location.href =
        this.resourceService.get(this.activateResourceLink) + `&stepId=${this.step.stepId}&isModal=true`;
    }
  }
}

export class SingleStepBannerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings = {
    step: '<',
  };

  constructor() {
    this.controller = SingleStepBannerController;
    this.templateUrl = singleStepBannerTemplate;
  }
}
