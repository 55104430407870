import React, { FunctionComponent } from 'react';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { Maybe } from 'scripts/arcade.module.interfaces';

interface IAllClaimsFiltersProps {
  claims: Maybe<IClaim[]>;
}

export const AllClaimsFilters: FunctionComponent<IAllClaimsFiltersProps> = () => {
  return <div className="filters-container hide-mobile" />;
};
