import React, { ComponentType, FunctionComponent } from 'react';
import { connect, ConnectedComponentClass, Provider } from 'react-redux';
import { Store } from 'redux';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { useDependencies } from 'scripts/hooks/use-dependencies/use-dependencies';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { selectHeartbeatData } from 'scripts/selectors/user-service-selectors';
import arcadeStore from 'scripts/store/store';
import { getProfile as getProfileThunk } from 'scripts/thunks/profile-service-thunks';
import { getHeartbeat as getHeartbeatThunk } from 'scripts/thunks/user-service-thunks';

interface IDependenciesProps {
  getHeartbeat: () => void;
  getProfile: () => void;
  heartbeat: IHeartbeat;
  profile: IProfileUser;
}

// This will prevent the WrapperComponent from being rendered until critical data (heartbeat and profile)
// is fetched and added to the Redux state. This prevents us from having to check that the profile is loaded
// in all our components or in our thunks. We can just assume profile data is already in the Redux state.
function withDependenciesLoadedRaw<GenericProps extends IDependenciesProps>(
  WrappedComponent: ComponentType,
): FunctionComponent<GenericProps> {
  return props => {
    const { heartbeat, getHeartbeat, profile, getProfile, ...rest } = props;
    const { loaded } = useDependencies({ heartbeat, getHeartbeat, profile, getProfile });
    return loaded && <WrappedComponent {...rest} />;
  };
}

function withDependenciesLoaded<GenericProps>(
  WrappedComponent: ComponentType<GenericProps>,
): ConnectedComponentClass<FunctionComponent<IDependenciesProps>, Pick<IDependenciesProps, never>> {
  return connect(
    (state: IReduxState) => ({
      heartbeat: selectHeartbeatData(state),
      profile: currentUser.selectProfile(state),
    }),
    {
      getHeartbeat: getHeartbeatThunk,
      getProfile: getProfileThunk,
    },
  )(withDependenciesLoadedRaw(WrappedComponent));
}

function withProvider<GenericProps>(
  WrappedComponent: ComponentType<GenericProps>,
  store: Store = arcadeStore,
): FunctionComponent<GenericProps> {
  return (props: GenericProps) => {
    const WrappedComponentWithDependenciesLoaded = withDependenciesLoaded(WrappedComponent);
    return (
      <Provider store={store}>
        <WrappedComponentWithDependenciesLoaded {...props} />
      </Provider>
    );
  };
}

export default withProvider;
