import CONFIG from 'scripts/util/constants/config';
import planBalancesTemplate from 'views/claims-and-accounts/plan-balances/plan-balances.html';
import { IDropdownOption } from '../../../ui/dropdown/dropdown.interfaces';

export interface IYearOptions {
  hasCurrentYear: boolean;
  hasPreviousYear: boolean;
}

export class PlanBalancesController implements ng.IComponentController {
  public showCurrentYear: boolean;
  public yearOptions: IDropdownOption[];
  public selectedOption: IDropdownOption;
  public ssoTrackingLink: string;
  private currentYearOption: IDropdownOption = { label: 'CURRENT_YEAR', value: 'true' };
  private previousYearOption: IDropdownOption = { label: 'PREVIOUS_YEAR', value: 'false' };

  constructor() {
    'ngInject';
    this.yearOptions = [];
    this.ssoTrackingLink = CONFIG.ARCADE_WEB_RALLY_AUTH_URL + '/sso/v1/vendor/MYUHCACCBAL/completed';
  }

  public setYear(): (newVal: IDropdownOption) => void {
    return (newVal: IDropdownOption) => {
      this.showCurrentYear = newVal && newVal.value === 'true';
    };
  }

  public setYearOptions({ hasCurrentYear, hasPreviousYear }: IYearOptions): void {
    this.showCurrentYear = !this.showCurrentYear ? hasCurrentYear : this.showCurrentYear;
    if (hasCurrentYear && !this.yearOptions.some(option => option === this.currentYearOption)) {
      this.yearOptions = [this.currentYearOption].concat(this.yearOptions);
    }
    if (hasPreviousYear && !this.yearOptions.some(option => option === this.previousYearOption)) {
      this.yearOptions = this.yearOptions.concat(this.previousYearOption);
    }
    this.selectedOption = this.showCurrentYear ? this.currentYearOption : this.previousYearOption;
  }

  public showYearDropDown(): boolean {
    return this.yearOptions.some(option => option === this.previousYearOption);
  }
}

export class PlanBalancesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PlanBalancesController;
    this.templateUrl = planBalancesTemplate;
  }
}
