import { AxiosRequestConfig } from 'axios';
import moment from 'moment';

export enum CoverageType {
  BehavioralHealth = 'BEHAVIORAL_HEALTH',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
  Rx = 'RX',
  Vision = 'VISION',
}

export enum CoverageTypeCode {
  HospitalIndemnity = 'HIP',
  HospitalIndemnityRider = 'HIPRIDER',
  MA = 'MA',
  MAPD = 'MAPD',
  MedicareSupplement = 'MEDSUPP',
  MedicareSupplementRider = 'MEDSUPPRIDER',
  PDP = 'PDP',
  PHIP = 'PHIP',
  SSP = 'SSP',
}

export enum RelationshipType {
  Dependent = 'DEPENDENT',
  DomesticPartner = 'DOMESTIC_PARTNER',
  Other = 'OTHER',
  Retiree = 'RETIREE',
  Spouse = 'SPOUSE',
  Subscriber = 'SUBSCRIBER',
}

export enum CoverageStatus {
  Active = 'ACTIVE',
  Future = 'FUTURE',
  Termed = 'TERMED',
}

export enum LinkTarget {
  Blank = '_blank',
  Self = '_self',
}

export interface ITimePeriod {
  startDate: moment.Moment;
  endDate?: moment.Moment;
}

export interface ICoverageTimePeriod extends ITimePeriod {
  status: CoverageStatus;
}

export interface IFullName {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
}

export interface ICurrencyAmount {
  iso4217: string;
  value: number;
}

export interface IDateFilter extends ng.IFilterService {
  (name: 'amDateFormat'): (input: moment.Moment, format: string) => string;
}

export interface ILink {
  href?: string | { pathname: string; search: string };
  text?: string;
  target?: LinkTarget;
}

export interface IAxiosRequestConfig extends AxiosRequestConfig {
  ignoreLoadingBar?: boolean;
  localizedCacheKey?: boolean;
  cacheName?: CacheName;
}

export interface IRequestShortcutConfig extends ng.IRequestShortcutConfig {
  ignoreLoadingBar?: boolean;
}

export interface IResponse<T> {
  data: T;
  config: any;
  status: number;
  statusText: string;
  headers?: Record<string, string>;
  arcadeDataUpdated?: moment.Moment;
}

export interface IEmptyResponse extends IResponse<void> {}

export interface IBadUrls {
  [url: string]: IBadUrl;
}

export interface IBadUrl {
  lastId: number;
  errorCount: number;
}

export enum CacheName {
  Activate = 'activate',
  Advantage = 'advantage',
  ClaimNotes = 'claimNotes',
  Claims = 'claims',
  ClaimsFinancial = 'claimsFinancial',
  ClaimsHealthcare = 'claimsHealthcare',
  ClaimsNeedAttention = 'claimsNeedAttention',
  ClaimsRallyPay = 'claimsRallyPay',
  Documents = 'documents',
  FpcPrimaryCareInformation = 'fpcPrimaryCareInformation',
  Ledger = 'ledger',
  Plans = 'plans',
  PrimaryCareInformation = 'primaryCareInformation',
  Profile = 'profile',
  ProfilePreferences = 'profilePreferences',
  RealTimeOfferCount = 'realTimeOfferCount',
  Targeting = 'targeting',
  Tracking = 'tracking',
  User = 'user',
}
