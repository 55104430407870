import { Observable } from 'rxjs/Observable';
import { IProfileService } from '../../api/profile/profile.service';
import { CampaignPlacementType } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { RelationshipType } from './../../api/api.interfaces';

export interface IOnboardingStatusService {
  get(): Observable<boolean>;
}

export class OnboardingStatusService implements IOnboardingStatusService {
  constructor(
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
  }

  public get(): Observable<boolean> {
    return this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId =>
        Observable.zip(
          this.profileService.get(rallyId).map(rsp => rsp.data),
          this.profileService.getCurrentProfile(rallyId),
        ),
      )
      .flatMap(
        ([profile]) => {
          return this.targetingService
            .getCampaigns(profile.rallyId, [CampaignPlacementType.ArcadeOnboardingPaperless])
            .map(rsp => rsp.data.placements);
        },
        ([profile, profileUser], placements) => ({ profile, profileUser, placements }),
      )
      .map(({ profile, profileUser, placements }) => {
        let showOnboardingBanner = false;
        if (profile.onboardingSteps) {
          const paperlessCampaigns = placements[CampaignPlacementType.ArcadeOnboardingPaperless];
          if (paperlessCampaigns) {
            const paperlessCampaign = paperlessCampaigns[0];
            const stepNeeded = profile.onboardingSteps.communicationPreference.stepNeeded;
            const isSubscriber = profileUser.relationshipType === RelationshipType.Subscriber;
            showOnboardingBanner = isSubscriber && stepNeeded && !!paperlessCampaign;
          }
        }
        return showOnboardingBanner;
      })
      .defaultIfEmpty(false);
  }
}
