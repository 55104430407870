import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import {
  aarp,
  appeals,
  documentsOverview,
  explanationOfBenefits,
  healthRiskSurvey,
  ksAppeal,
  ksGrievance,
  memberHandbook,
  orderMaterials,
  valueAddedServices,
} from 'scripts/util/resource/resource.constants';
import linkBarTemplate from 'views/dashboard/link-bar.html';
import { CoverageStatus, CoverageType, CoverageTypeCode, LinkTarget } from '../../../api/api.interfaces';
import { CsPrimaryCustomerId, LineOfBusiness, MembershipCategory } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { ILinkBarLink } from './link-bar.interfaces';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';

export class LinkBarController implements ng.IComponentController {
  public headerText: string;
  public linkRows: ILinkBarLink[][];
  public links: ILinkBarLink[];
  private profileSubscription: Subscription;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
    private featureFlagService: IFeatureFlagService,
  ) {
    'ngInject';

    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.LINK_BAR);

    this.links = [
      {
        id: 'EOB_SEARCH',
        title: 'MEDICAL_EXPLANATION_OF_BENEFITS',
        text: 'EOB_SEARCH',
        href: this.resourceService.get(explanationOfBenefits),
        icon: 'icon-circle-eob',
      },
      {
        id: 'FORMS_AND_RESOURCES',
        title: 'DOCUMENTS_AND_RESOURCES',
        text: 'VIEW_DOCUMENTS_AND_RESOURCES',
        href: this.resourceService.get(documentsOverview),
        icon: 'icon-circle-forms',
      },
      {
        id: 'MATERIALS',
        title: 'MATERIALS',
        text: 'ORDER_MATERIALS',
        href: this.resourceService.get(orderMaterials),
        icon: 'icon-circle-materials',
      },
      {
        id: 'VALUE_ADDED_SERVICES',
        title: 'ADDITIONAL_DISCOUNTS_AND_SERVICES',
        text: 'VIEW_DISCOUNTS_AND_SERVICES',
        href: this.resourceService.get(valueAddedServices),
        icon: 'icon-circle-value-2',
      },
      {
        id: 'EVIDENCE',
        title: 'EVIDENCE_OF_COVERAGE',
        text: 'VIEW_MATERIALS',
        href: this.resourceService.get(memberHandbook),
        icon: 'icon-circle-id-card',
      },
      {
        id: 'MEMBER_HANDBOOK',
        title: 'MEMBER_HANDBOOK',
        text: 'VIEW_MATERIALS',
        href: this.resourceService.get(memberHandbook),
        icon: 'icon-circle-materials',
      },
      {
        id: 'APPEALS',
        title: 'COMPLAINT_AND_GRIEVANCE',
        text: 'VIEW_FORM',
        href: this.resourceService.get(appeals),
        icon: 'icon-circle-forms',
        target: LinkTarget.Blank,
      },
      {
        id: 'FORMULARY',
        title: 'COMPREHENSIVE_FORMULARY',
        text: 'VIEW_DRUG_LIST',
        href: this.resourceService.get(documentsOverview),
        icon: 'icon-circle-pharmacy',
      },
      {
        id: 'AARP',
        title: 'VISIT_AARP',
        text: 'AARP',
        href: this.resourceService.get(aarp),
        icon: 'icon-circle-external-link',
        target: LinkTarget.Blank,
      },
      {
        id: 'ASSESSMENT',
        title: 'HEALTH_ASSESSMENT',
        text: 'TAKE_SURVEY',
        href: this.resourceService.get(healthRiskSurvey),
        icon: 'icon-circle-heart',
      },
    ];
  }

  public $onInit(): void {
    this.initLinks();
    this.profileSubscription = this.profileService.profileChanged.subscribe(() => this.initLinks());
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  public getIcon(link: ILinkBarLink): string {
    return this.Files.getIcon(link.icon);
  }

  private initLinks(): void {
    this.updateRows();
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .flatMap(rsp => {
        const { currentUser } = rsp.data;
        const { primaryCustomerId } = currentUser.userInfo;
        let isTermedCS = false;
        const isTermedMedical = this.profileService.isTermedForCoverageType(
          CoverageType.Medical,
          currentUser.planCoverages,
        );
        const isPreEffective = currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future);
        if (currentUser.lineOfBusiness === LineOfBusiness.MR) {
          this.headerText = 'ADDITIONAL_LINKS';
          this.links[1].show = true;
          this.links[8].show = currentUser.membershipCategory === MembershipCategory.AARP && !isTermedMedical;
          this.links[4].href = this.resourceService.get(documentsOverview);
          this.links[9].show = isPreEffective && this.featureFlagService.isHealthAssesmentLinkOn();
        } else if (currentUser.lineOfBusiness === LineOfBusiness.CS) {
          this.headerText = 'FORMS_AND_DOCUMENTS';
          const isDsnp = currentUser.memberFeatures.isDsnp;
          this.links[0].show = true;
          this.links[0].target = LinkTarget.Blank;
          this.links[4].show = isDsnp;
          this.links[5].show = !isDsnp;
          this.links[6].show = primaryCustomerId === CsPrimaryCustomerId.CAMCMP;

          if (primaryCustomerId === CsPrimaryCustomerId.KSKCMD || primaryCustomerId === CsPrimaryCustomerId.KSKCCH) {
            const kansasAppealsAndGrievancesTile = {
              id: 'APPEALS',
              title: 'APPEALS_AND_GRIEVANCES',
              text: 'FILE_APPEAL',
              href: this.resourceService.get(ksAppeal),
              icon: 'icon-circle-forms',
              target: LinkTarget.Blank,
              secondaryHref: this.resourceService.get(ksGrievance),
              secondaryText: 'REPORT_GRIEVANCE',
            };

            this.links[6] = kansasAppealsAndGrievancesTile;
            this.links[6].show = true;
          }

          isTermedCS = isTermedMedical;
        }
        const hasActiveCoverage = currentUser.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Active);
        const hasSspOnly = currentUser.planCoverages.every(c => c.coverageTypeCode === CoverageTypeCode.SSP);
        if (isPreEffective) {
          this.links[4].show = true;
          this.links[7].show = currentUser.planCoverages.some(c => {
            return c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP;
          });
        } else if (!isTermedCS && !hasSspOnly) {
          this.links[0].show = true;
        }
        if (hasActiveCoverage && currentUser.lineOfBusiness !== LineOfBusiness.CS) {
          this.links[2].show = true;
          this.updateRows();
          return this.profileService.getProducts(currentUser.rallyId).map(productsRsp => productsRsp.data.products);
        } else {
          this.updateRows();
          return Observable.of(undefined);
        }
      })
      .subscribe(products => {
        // ARC-3057 - check for any of 8 products corresponding to value added services
        if (products && ProfileService.isValueAddedServiceEligible(products)) {
          this.links[3].show = true;
          this.updateRows();
        }
      }, console.warn);
  }

  private updateRows(): void {
    // Supports a maximum of 8 links
    const availableLinks = this.links.filter(link => link.show).slice(0, 8);
    this.linkRows = [];
    if (availableLinks.length <= 4) {
      this.linkRows.push(availableLinks);
    } else {
      const split = Math.ceil(availableLinks.length / 2);
      this.linkRows.push(availableLinks.slice(0, split));
      this.linkRows.push(availableLinks.slice(split, availableLinks.length));

      if (this.linkRows[0].length > this.linkRows[1].length) {
        // push an empty filler link
        this.linkRows[1].push(undefined);
      }
    }
  }
}

export class LinkBarComponent {
  public controller = LinkBarController;
  public templateUrl = linkBarTemplate;
}
