import angular from 'angular';
import moment from 'moment';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import filterTemplate from 'views/ui/filter.html';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFilterDropdownOption } from '../dropdown/dropdown.interfaces';
import { FilterType, IAvailableFilterValue, IGenericFilter } from './filter.interfaces';
import { IFilterService } from './filter.service';

export class FilterController<T, U> implements ng.IComponentController {
  // Bindings
  public data: T[];
  public filter: IGenericFilter<T, U>;
  public handleFilters: (_: { filterName: string; selectedValues: U[] }) => void;

  public availableFilters: IAvailableFilterValue<U>[];
  public dropDownAvailableFilters: IFilterDropdownOption<U>[];
  public endDate: moment.Moment;
  public isCollapsed: boolean;
  public selectedDropDown: IFilterDropdownOption<U>;
  public showEndDate: boolean;
  public showStartDate: boolean;
  public startDate: moment.Moment;
  public keywordFilterValue: string;
  public keywordInputDebounce = 50;

  constructor(
    private $scope: ng.IScope,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private filterService: IFilterService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.FILTER);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS);

    this.data = this.data || [];
  }

  public $onInit(): void {
    this.isCollapsed = false;
    this.initFilters();
    this.$scope.$on('allClaims.resetFilters', () => this.resetFilter());
  }

  public $onChanges(changesObj: { [property: string]: angular.IChangesObject<T[]> }): void {
    if (changesObj.data.currentValue) {
      this.initFilters();
    }
  }

  public clearKeywordFilter(): void {
    this.keywordFilterValue = '';
    this.filterChange();
  }

  public filterChange(): void {
    let selectedValues = [];
    if (this.filter.type === FilterType.Checkbox) {
      selectedValues = this.availableFilters.filter(av => av.selected).map(av => av.value);
    }
    if (this.filter.type === FilterType.DateRange) {
      selectedValues = [this.startDate.toISOString(), this.endDate.toISOString()];
    }
    if (this.filter.type === FilterType.Dropdown) {
      selectedValues = this.availableFilters.filter(av => av.selected).map(av => av.value);
    }
    if (this.filter.type === FilterType.Keyword) {
      const trimmed = this.keywordFilterValue && this.keywordFilterValue.trim();
      if (trimmed) {
        selectedValues = trimmed.split(' ').filter(s => s);
      }
    }
    this.handleFilters({
      filterName: this.filter.name,
      selectedValues: selectedValues.length > 0 ? selectedValues : null,
    });
  }

  public selectDropdownFilter(): (option: IFilterDropdownOption<U>, $event: ng.IAngularEvent) => void {
    return option => {
      this.selectedDropDown = option;
      this.availableFilters.forEach(filter => {
        filter.selected = filter.value === (option as IFilterDropdownOption<U>).filterValue;
      });
      this.filterChange();
    };
  }

  public showFilter(): boolean {
    return (
      [FilterType.DateRange, FilterType.Keyword].indexOf(this.filter.type) > -1 || this.availableFilters.length > 0
    );
  }

  public showHeader(): boolean {
    return this.filter.type !== FilterType.Keyword;
  }

  public toggleAccordion(event: Event): void {
    const toggler = event.currentTarget as HTMLElement;
    const toggled = toggler.nextElementSibling as HTMLElement;

    this.showEndDate = false;
    this.showStartDate = false;

    if (this.isCollapsed) {
      this.expandSection(toggled);
    } else {
      this.collapseSection(toggled);
    }
  }

  public toggleEndDate(): void {
    this.showEndDate = !this.showEndDate;
    this.showStartDate = false;
  }

  public toggleStartDate(): void {
    this.showEndDate = false;
    this.showStartDate = !this.showStartDate;
  }

  private collapseSection(element: HTMLElement): void {
    const sectionHeight = element.scrollHeight;
    const elementTransition = element.style.transition;
    element.style.transition = '';
    element.style.overflow = 'hidden';

    requestAnimationFrame(() => {
      element.style.height = sectionHeight + 'px';
      element.style.transition = elementTransition;

      requestAnimationFrame(() => {
        element.style.height = 0 + 'px';
      });
    });

    this.isCollapsed = true;
  }

  private expandSection(element: HTMLElement): void {
    const sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + 'px';

    const transitionEndListener = (): void => {
      element.removeEventListener('transitionend', transitionEndListener);
      element.style.height = null;
      element.style.overflow = null;
    };
    element.addEventListener('transitionend', transitionEndListener);

    this.isCollapsed = false;
  }

  private initFilters(): void {
    const selectedFilters = this.filterService.getStateValue() || { values: {} };
    if (this.filter.type === FilterType.Checkbox) {
      this.availableFilters = this.filterService.getFilterValues(this.data, this.filter);
      if (selectedFilters.values && selectedFilters.values[this.filter.name]) {
        for (const selectedValue of selectedFilters.values[this.filter.name]) {
          for (const availableFilter of this.availableFilters) {
            if (angular.equals(selectedValue, availableFilter.value)) {
              availableFilter.selected = true;
            }
          }
        }
      }
    } else if (this.filter.type === FilterType.DateRange) {
      if (
        selectedFilters.values &&
        selectedFilters.values[this.filter.name] &&
        selectedFilters.values[this.filter.name].length === 2
      ) {
        this.startDate = moment(selectedFilters.values[this.filter.name][0]);
        this.endDate = moment(selectedFilters.values[this.filter.name][1]);
      } else if (this.filter.defaultValues && this.filter.defaultValues.length === 2) {
        this.startDate = moment(this.filter.defaultValues[0]);
        this.endDate = moment(this.filter.defaultValues[1]);
      }
    } else if (this.filter.type === FilterType.Dropdown) {
      this.availableFilters = this.filterService.getFilterValues(this.data, this.filter);
      if (this.filter.filterOnStateFunc) {
        this.availableFilters = this.availableFilters.filter(avail =>
          this.filter.filterOnStateFunc(avail, this.filterService),
        );
      }
      this.dropDownAvailableFilters = this.availableFilters.map(val => {
        return { label: val.display, value: val.display, isSelected: val.selected, filterValue: val.value };
      });
      if (selectedFilters.values && selectedFilters.values[this.filter.name]) {
        for (const selectedValue of selectedFilters.values[this.filter.name]) {
          for (const availableFilter of this.availableFilters) {
            if (angular.equals(selectedValue, availableFilter.value)) {
              availableFilter.selected = true;
              for (const dropDownOption of this.dropDownAvailableFilters) {
                if (dropDownOption.filterValue === availableFilter.value) {
                  this.selectedDropDown = dropDownOption;
                }
              }
            }
          }
        }
      }
    } else if (this.filter.type === FilterType.Keyword) {
      if (
        selectedFilters.values &&
        selectedFilters.values[this.filter.name] &&
        selectedFilters.values[this.filter.name].length > 0
      ) {
        this.keywordFilterValue = selectedFilters.values[this.filter.name].join(' ');
      }
    }
  }

  private resetFilter(): void {
    if (this.filter.type === FilterType.Checkbox) {
      this.availableFilters.forEach(availableFilter => (availableFilter.selected = false));
    }
    if (this.filter.type === FilterType.DateRange) {
      this.showEndDate = false;
      this.showStartDate = false;
      if (this.filter.defaultValues && this.filter.defaultValues.length === 2) {
        this.startDate = moment(this.filter.defaultValues[0]);
        this.endDate = moment(this.filter.defaultValues[1]);
      } else {
        this.startDate = undefined;
        this.endDate = undefined;
      }
    }
    if (this.filter.type === FilterType.Dropdown) {
      this.availableFilters.forEach(availableFilter => (availableFilter.selected = false));
      this.dropDownAvailableFilters.forEach(filterVal => (filterVal.isSelected = false));
      this.selectedDropDown = undefined;
    }
    if (this.filter.type === FilterType.Keyword) {
      this.keywordFilterValue = undefined;
    }
    this.filter.selectedValues = undefined;
  }
}

export class FilterComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    data: '<',
    filter: '<',
    handleFilters: '&',
  };

  constructor() {
    this.controller = FilterController;
    this.templateUrl = filterTemplate;
  }
}
