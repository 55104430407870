import { IClaimsInfoPacket, OutboundMessageEventData, IPaymentsHSA, MessageType } from '@rally/rallypay';
import { Observable } from 'rxjs/Observable';
import {
  ClaimType,
  IClaim,
  IGetMatchingClaimParams,
  IHealthcareClaimDetails,
  IRallyPayParsedResponse,
} from 'scripts/api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from 'scripts/api/claims/claims.service';
import { AccountType, ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService } from 'scripts/api/ledger/ledger.service';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { ClaimMarkPaid } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { instamedPayNow } from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { ITrackingService } from 'scripts/api/tracking/tracking.service';
import {
  ITrackingEventRequest,
  TrackingClickType,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import { AmpEventType } from 'scripts/api/tracking/amplitude.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { getFeatureList, getPlacement, formatTrackingString } from 'scripts/util/tracking/tracking-helper';
import { constructParams, parse } from 'scripts/util/uri/uri';
import payNowTemplate from 'views/modals/pay-now-modal.html';
import { getMoneyValue } from 'scripts/util/money/money';
import { IModalController } from 'scripts/ui/modal/modal.interfaces';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';

export interface IGetPayNowParams extends IGetMatchingClaimParams {
  claimPayKey: string;
  payNowFrom: string;
}

export class PayNowController implements ng.IComponentController {
  public $modal: IModalController;
  public claim: IClaim | IHealthcareClaimDetails;
  public claimsInfoPacket: IClaimsInfoPacket | {};
  public isValidConfirmationInput: boolean;
  public hasYMOError: boolean;
  public modalTitle: string;

  public confirmationInput: string;
  public rallyPayParsedResponse: IRallyPayParsedResponse;
  public showSuccessMessage = false;
  public rallyPayToken: string;
  public request: Observable<IClaimsInfoPacket | {}>;
  public sendToUrl: string;
  public showPaymentWarning = false;
  public showInsufficientFundsWarning = false;
  public toPayAmount: number | string;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $rootScope: ng.IRootScopeService,
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private $stateParams: IGetPayNowParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private claimsService: IClaimsService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private trackingService: ITrackingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.PAY_NOW_MODAL);

    const matchingClaimParams = {
      ...this.$stateParams,
      from: this.$stateParams.payNowFrom,
    };

    const claimAndProfile$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => this.claimsService.getMatchingClaim(profile, matchingClaimParams),
        (profile, claim) => ({
          profile,
          claim,
        }),
      )
      .flatMap(
        ({ profile: { rallyId }, claim }) =>
          this.profileService.getProfileInfoForRallyPay(rallyId, claim).map(rsp => rsp.data),
        (profileAndClaim, profileInfoForRallyPay) => ({
          ...profileAndClaim,
          profileInfoForRallyPay,
        }),
      )
      .do(({ claim }) => {
        this.claim = claim;
        this.modalTitle = this.getModalTitle(claim.claimType);
        this.toPayAmount = getMoneyValue(ClaimsService.getYouMayOweAmount(claim));
      });

    const accounts$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(({ rallyId }) => this.ledgerService.getAccounts(rallyId))
      .map(rsp => rsp.data);

    const token$ = this.userService
      .getRallyPayToken()
      .map(rsp => rsp.data.token)
      .do(token => (this.rallyPayToken = token));

    this.request = Observable.zip(claimAndProfile$, accounts$, token$)
      .flatMap(([{ claim, profile, profileInfoForRallyPay: { encryptedProfileInfo } }, accounts]) => {
        const {
          rallyId,
          currentUser: { membershipCategory },
        } = profile;

        const hsaAccount: ILedgerAccount = accounts && accounts.find(act => act.accountType === AccountType.HSA);
        // At this point, we know the user has an HSA, but in case the call fails for some reason
        const encryptedAccountInfo = hsaAccount && hsaAccount.rallypayLedgerData;

        return this.claimsService
          .getRallyPayClaimDetails(
            rallyId,
            claim,
            encryptedAccountInfo,
            encryptedProfileInfo,
            matchingClaimParams,
            membershipCategory,
          )
          .catch(() => {
            this.hasYMOError = true;
            return Observable.of({ data: {} });
          });
      })
      .map(rsp => rsp.data)
      .do(claimsInfoPacket => (this.claimsInfoPacket = claimsInfoPacket));
  }

  public $onInit(): void {
    this.request.subscribe(() => undefined, console.warn);
    const { filters, claimPayKey, payNowFrom } = this.$stateParams;
    this.sendToUrl = this.generateSendToUrl(filters, claimPayKey, payNowFrom);
    this.showPaymentWarning = this.featureFlagService.isPayNowModalWarningOn();
  }

  public closeModal(): void {
    this.$modal.close();
  }

  public onMessage = ({ payload, type }: OutboundMessageEventData): void => {
    switch (type) {
      case MessageType.Submit:
        this.onSubmit(payload);
        return;
      case MessageType.InsufficientFunds:
        this.onInsufficientFunds();
        return;
    }
  };

  public onConfirmationSubmit = (event: Event): void => {
    event.preventDefault();
    this.isValidConfirmationInput =
      this.confirmationInput === this.Environment.CONFIG.ARCADE_WEB_RALLY_PAY_WIDGET_CONFIRMATION;
    if (!this.isValidConfirmationInput) {
      this.$window.alert('Invalid confirmation input!');
    }
  };

  private onSubmit(data: IPaymentsHSA | void): void {
    this.showSuccessMessage = !!data;

    this.trackSubmit(this.showSuccessMessage, data ? data.amountBilled.value : null);

    if (data) {
      const { amountBilled, confirmationNumber, timeStamp } = data;
      this.rallyPayParsedResponse = {
        amount: getMoneyValue(parseFloat(amountBilled.value)),
        confirmationNumber,
        timeStamp,
      };

      // mark the claim as paid and send a request to the API to update it
      this.claim.claimManagementInfo.markPaid = true;
      this.userService
        .getHeartbeat()
        .let(this.profileService.toProfile())
        .map(rsp => rsp.data)
        .flatMap(profile => this.claimsService.togglePaid(profile.currentUser, this.claim))
        .subscribe(() => {
          // currently there is no way to communicate between modal and the components that triggered it,
          // so we're emitting an event for other components to act upon it
          // this should be replaced in the future, when claims are set in the Redux store
          this.$rootScope.$emit(ClaimMarkPaid, this.claim);
        }, console.warn);
    }

    this.$scope.$apply();
  }

  private trackSubmit(successful: boolean, paymentAmount?: string): void {
    const widgetElement =
      this.$element.find('.rally-pay-widget-container').length && this.$element.find('.rally-pay-widget-container')[0];
    const element = widgetElement || this.$element[0];
    if (!element) return;

    const event: ITrackingEventRequest = {
      featureList: getFeatureList(element)
        .concat(element.getAttribute('track-feature'))
        .filter(Boolean),
      actionName: formatTrackingString(AmpEventType.RallyPayPaymentCompleted),
      placement: getPlacement(element),
      uri: window.location.pathname,
      trigger: TrackingTriggerType.Click,
      clickType: TrackingClickType.PageEvent,
      serviceVersion: CONFIG.ARCADE_WEB_VERSION,
      additionalProperties: {
        successful,
        ...(paymentAmount ? { paymentAmount } : {}),
      },
    };

    this.trackingService.queueEvent(event);
  }

  private onInsufficientFunds(): void {
    this.showInsufficientFundsWarning = true;
    this.$scope.$apply();
  }

  private generateSendToUrl(stateFilters: string, claimPayKey: string, from: string): string {
    const initialUrl = this.resourceService.get(instamedPayNow);

    // Remove filters and store them in session storage because of the Instamed requirements that the RETURN_URL cannot be "too" long
    const params = {};
    if (stateFilters) {
      this.$window.sessionStorage.setItem('arcade.claim.filters', stateFilters);
    }
    const { pathname, search } = parse(from);
    const currentParams = new URLSearchParams(search);
    currentParams.forEach((value, key) => (params[key] = value));
    const paramsWithoutFilters = {
      ...params,
      filters: undefined,
      useSessionFilters: stateFilters ? 1 : undefined,
    };
    const filterlessCurrentUrl = `${pathname}${constructParams(paramsWithoutFilters)}`;
    const returnStateUrl = this.$state.href('authenticated.claimsAndAccounts.claimPaid', {
      goTo: filterlessCurrentUrl,
    });
    const returnUrl = parse(returnStateUrl).href;
    return `${initialUrl}${claimPayKey}&RETURN_URL=${encodeURIComponent(returnUrl)}`;
  }

  private getModalTitle(claimType: ClaimType): string {
    switch (claimType) {
      case ClaimType.Medical:
        return 'MEDICAL_CLAIM_NUMBER';
      case ClaimType.Dental:
        return 'DENTAL_CLAIM_NUMBER';
      case ClaimType.Rx:
        return 'RX_CLAIM_NUMBER';
      default:
        return 'CLAIM_CLAIM_NUMBER';
    }
  }
}

export class PayNowComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PayNowController;
    this.templateUrl = payNowTemplate;
  }
}
