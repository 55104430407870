import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { IAnyClaim, IClaim } from 'scripts/api/claims/claims.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { IProducts } from 'scripts/api/profile/profile.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectAccountsData } from 'scripts/selectors/ledger-service-selectors';
import { selectProductsData } from 'scripts/selectors/profile-service-selectors';
import { getAccounts } from 'scripts/thunks/ledger-service-thunks';
import { getProducts } from 'scripts/thunks/profile-service-thunks';
import { isHealthcareClaim } from 'scripts/util/claims/claims';
import { ClaimBalancesFinancial } from './claim-balances-financial';
import { ClaimBalancesHealthcare } from './claim-balances-healthcare';
import { ClaimButtons } from './claim-buttons';
import { ClaimIcon } from './claim-icon';
import { ClaimInfo } from './claim-info';
import { ClaimMain } from './claim-main';

export interface IClaimProps extends IClaimStateToProps, IClaimDispatchToProps {
  claim: IAnyClaim;
  clientConfig: IClientConfig;
  onClaimUpdate: (claim: IClaim) => void;
}

interface IClaimStateToProps {
  accounts: ILedgerAccount[];
  products: IProducts;
}

interface IClaimDispatchToProps {
  getAccounts: () => void;
  getProducts: () => void;
}

export const RawClaim: FunctionComponent<IClaimProps> = props => {
  const { accounts, claim, clientConfig, onClaimUpdate, products } = props;

  useEffect(() => {
    if (!accounts) {
      props.getAccounts();
    }
    if (!products) {
      props.getProducts();
    }
  }, []);

  return (
    <div className="claim" data-testid={`claim-${claim.claimKey}`}>
      <div className="claim-container">
        <ClaimInfo claim={claim} />
        <div className="claim-body">
          <ClaimIcon claim={claim} />
          <div>
            <ClaimMain claim={claim} clientConfig={clientConfig} />
            {isHealthcareClaim(claim) ? (
              <ClaimBalancesHealthcare claim={claim} />
            ) : (
              <ClaimBalancesFinancial claim={claim} />
            )}
            {isHealthcareClaim(claim) && (
              <ClaimButtons
                claim={claim}
                clientConfig={clientConfig}
                accounts={accounts}
                onClaimUpdate={onClaimUpdate}
                products={products}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IClaimStateToProps => ({
  accounts: selectAccountsData(state),
  products: selectProductsData(state),
});

export const Claim = withProvider(
  connect(
    mapStateToProps,
    { getAccounts, getProducts },
  )(RawClaim),
);
