import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const IdCardCoverageDateHeader: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.ID_CARDS);

  return (
    <h1 data-testid={'id-card-coverage-date-header'} className="coverage-status-date">
      {t('COVERAGE_STATUS_AS_OF', { date: moment().format('MM/DD/YYYY') })}
    </h1>
  );
};
