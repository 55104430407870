import { Observable } from 'rxjs/Observable';
import {
  accountPreferences,
  accountProfile,
  allClaims,
  connect,
  documentsOverview,
  drugCostEstimator,
  drugCostEstimatorOptum,
  explanationOfBenefits,
  paymentsOverview,
  pharmacyLocator,
  prescriptions,
  valueAddedServices,
} from 'scripts/util/resource/resource.constants';
import { CoverageStatus, CoverageTypeCode, LinkTarget } from '../../../api/api.interfaces';
import { FundingType, MembershipCategory, ProgramType } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IGridSetup } from '../../../util/grid/grid.interfaces';
import { IResourceService } from '../../../util/resource/resource.service';
import { IQuickLinksCategory } from './quick-links.controller';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';

export class MrQuickLinksController implements IGridSetup, ng.IComponentController {
  public links: IQuickLinksCategory[];
  public maxColumns = 5;
  public showExternalLinkMessage: boolean;

  private LINK_URL;

  constructor(
    public $state: ng.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.QUICK_LINKS);

    this.LINK_URL = {
      CLAIMS: this.resourceService.get(allClaims),
      DOCUMENT_DELIVERY_PREFERENCES: this.resourceService.get(accountPreferences),
      DRUG_COST_ESTIMATOR: this.resourceService.get(drugCostEstimator),
      EOB: this.resourceService.get(explanationOfBenefits),
      EXT_FIND_CARE: this.resourceService.get(connect),
      FORMS_AND_RESOURCES: this.resourceService.get(documentsOverview),
      PAYMENTS: this.resourceService.get(paymentsOverview),
      PHARMACY: this.resourceService.get(pharmacyLocator),
      PREFERENCES: this.resourceService.get(accountPreferences),
      PRESCRIPTIONS: this.resourceService.get(prescriptions),
      PROFILE_PREFERENCES: this.resourceService.get(accountProfile),
      VALUE_ADDED_SERVICES: this.resourceService.get(valueAddedServices),
    };

    this.links = [
      {
        id: 'CLAIMS',
        text: 'VIEW_YOUR_CLAIMS',
        href: this.LINK_URL.CLAIMS,
        icon: 'icon-claims',
      },
      {
        id: 'PAYMENT',
        text: undefined,
        href: this.LINK_URL.PAYMENTS,
        icon: 'icon-payments',
      },
      {
        id: 'PRESCRIPTIONS',
        text: 'MANAGE_PRESCRIPTIONS',
        href: this.LINK_URL.PRESCRIPTIONS,
        icon: 'icon-pharmacy',
      },
      {
        id: 'VALUE_ADDED',
        text: 'VIEW_DISCOUNTS_AND_SERVICES',
        href: this.LINK_URL.VALUE_ADDED_SERVICES,
        icon: 'icon-value-2',
      },
      {
        id: 'FIND_PROVIDER',
        text: 'FIND_PROVIDER',
        href: this.$state.href('authenticated.findCare'),
        icon: 'icon-find-doctor',
      },
      {
        id: 'PROFILE',
        text: 'PROFILE_PREFERENCES',
        href: this.LINK_URL.PROFILE_PREFERENCES,
        icon: 'icon-gear',
      },
      {
        id: 'PHARMACY',
        text: 'LOCATE_PHARMACY',
        href: this.LINK_URL.PHARMACY,
        icon: 'icon-pharmacy-locator',
      },
      {
        id: 'DRUG',
        text: 'LOOK_UP_DRUGS',
        href: this.LINK_URL.DRUG_COST_ESTIMATOR,
        icon: 'icon-pharmacy',
      },
      {
        id: 'DOCUMENT_DELIVERY_PREFERENCES',
        text: 'DOCUMENT_DELIVERY_PREFERENCES',
        href: this.LINK_URL.DOCUMENT_DELIVERY_PREFERENCES,
        icon: 'icon-gear',
      },
      {
        id: 'PREFERENCES',
        text: 'ONLINE_DELIVERY',
        href: this.LINK_URL.PREFERENCES,
        icon: 'icon-green',
      },
      {
        id: 'EOB',
        text: 'EOB',
        href: this.LINK_URL.EOB,
        icon: 'icon-eob',
      },
      {
        id: 'VIEW_DOCS',
        text: 'VIEW_DOCUMENTS',
        href: this.LINK_URL.FORMS_AND_RESOURCES,
        icon: 'icon-forms',
      },
    ];

    userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => {
        const nonTermedCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status !== CoverageStatus.Termed);
        const hasMaOrMapdPlan = nonTermedCoverages.some(
          c => c.coverageTypeCode === CoverageTypeCode.MA || c.coverageTypeCode === CoverageTypeCode.MAPD,
        );
        const hasMapdOrPdpPlan = nonTermedCoverages.some(
          c => c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP,
        );
        const activeCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Active);
        const futureCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Future);
        const hasActivePdpPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.PDP);
        const hasActiveMapdPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MAPD);
        const hasActiveMaPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MA);
        const activeMapdOrPdpPlan = activeCoverages.filter(
          c => c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP,
        )[0];
        const isActive = activeCoverages.length > 0;
        const isFuture =
          currentUser.planCoverages.length &&
          currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future);
        const isTermed = nonTermedCoverages.length === 0;
        const isFutureShip = isFuture && futureCoverages.some(c => c.planFeatures.programType === ProgramType.Ship);
        const hasSspOnly = currentUser.planCoverages.every(c => c.coverageTypeCode === CoverageTypeCode.SSP);
        this.getLink('CLAIMS').show = isActive || isTermed;
        this.getLink('EOB').show = !hasSspOnly && (isActive || isTermed);
        this.getLink('VIEW_DOCS').show = isFuture;
        this.getLink('PROFILE').show = isFutureShip;
        this.getLink('PAYMENT').show =
          currentUser.memberFeatures.premiumPaymentEligible &&
          (isActive || (isFuture && this.featureFlagService.isMRPreEffPremiumPaymentsOn()));
        this.getLink('PAYMENT').text = isFuture ? 'SET_UP_PAYMENT' : 'MAKE_A_PAYMENT';
        this.getLink('VALUE_ADDED').show = isFutureShip ? true : !isActive ? false : undefined;
        this.getLink('FIND_PROVIDER').show = hasMaOrMapdPlan && currentUser.memberFeatures.fpcEligible && !isFutureShip;
        this.getLink('PHARMACY').show = hasMapdOrPdpPlan;
        const showDrugCostEstimator = hasActiveMapdPlan || hasActivePdpPlan;
        this.getLink('DRUG').show = showDrugCostEstimator;
        if (showDrugCostEstimator && activeMapdOrPdpPlan.planFeatures.fundingArrangementType === FundingType.Group) {
          const drugLink = this.getLink('DRUG');
          drugLink.href = this.resourceService.get(drugCostEstimatorOptum);
          drugLink.target = LinkTarget.Blank;
          this.showExternalLinkMessage = true;
        }
        this.getLink('PREFERENCES').show =
          isActive &&
          (currentUser.membershipCategory === MembershipCategory.AARP ||
            currentUser.membershipCategory === MembershipCategory.UHC);
        this.getLink('DOCUMENT_DELIVERY_PREFERENCES').show = isFuture && !isFutureShip;

        if (hasActiveMaPlan) {
          const findProviderLink = this.getLink('FIND_PROVIDER');
          findProviderLink.href = this.$state.href('internalRedirect', { deepLink: this.LINK_URL.EXT_FIND_CARE });
          findProviderLink.action = e => this.internalRedirect(e, this.LINK_URL.EXT_FIND_CARE);
        }

        if (!this.featureFlagService.isMRPharmaciesOn() || !activeMapdOrPdpPlan) {
          this.getLink('PRESCRIPTIONS').show = false;
        }
      })
      .flatMap(currentUser =>
        Observable.if(
          () => this.getLink('VALUE_ADDED').show === undefined || this.getLink('PRESCRIPTIONS').show === undefined,
          this.profileService.getProducts(currentUser.rallyId).map(rsp => rsp.data.products),
          Observable.of(undefined),
        ),
      )
      .do(products => {
        if (products) {
          if (this.getLink('VALUE_ADDED').show === undefined) {
            this.getLink('VALUE_ADDED').show = ProfileService.isValueAddedServiceEligible(products);
          }
          if (this.getLink('PRESCRIPTIONS').show === undefined) {
            const hasRxProduct = !!products.rx;
            this.getLink('PRESCRIPTIONS').show = hasRxProduct;
            if (hasRxProduct) {
              this.getLink('DRUG').show = false;
              this.getLink('PHARMACY').show = false;
            }
          }
        }
      })
      .subscribe(() => undefined, console.warn);
  }

  public getNumColumns(): number {
    const columns = this.links.reduce((columnTotal, link) => {
      return columnTotal + (link.show ? 1 : 0);
    }, 0);
    return columns <= this.maxColumns ? columns : this.maxColumns;
  }

  public getIcon(link: IQuickLinksCategory): string {
    if (link.icon) {
      return this.Files.getIcon(link.icon);
    } else if (link.iconUrl) {
      return link.iconUrl;
    }
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private getLink(id: string): IQuickLinksCategory {
    for (const link of this.links) {
      if (link.id === id) {
        return link;
      }
    }
  }
}
