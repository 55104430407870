import mrAccountSummaryExplanationTemplate from 'views/modals/mr-account-summary-explanation.html';
import angular from 'angular';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { documentsOverview } from 'scripts/util/resource/resource.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export class MRAccountSummaryExplanationController implements ng.IComponentController {
  public documentsLink: string;
  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private resourceService: IResourceService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);

    this.documentsLink = this.resourceService.get(documentsOverview);
  }
}

export class MRAccountSummaryExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = MRAccountSummaryExplanationController;
    this.templateUrl = mrAccountSummaryExplanationTemplate;
  }
}
