import { arachneUris } from 'scripts/util/uri/uri';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import * as arachne from '@rally/heartbeat';

/**
 * Simple service definition to wrap initiation of the Arachne ThrottledHeartbeat heartbeat.
 */
export interface IArachneHeartbeatService {
  start(): void;
}

/**
 * Controls the Arachne Heartbeat lifecycle.
 */
export class ArachneHeartbeatService implements IArachneHeartbeatService {
  constructor(private Environment: IEnvironmentConstants) {
    'ngInject';
  }

  /**
   * Starts the Arachne Heartbeat background job.
   */
  public start(): void {
    arachne.Utils.addXMLRequestCallback();
    arachne.ThrottledHeartbeat.Run({
      authNUrl: arachneUris.baseUri(this.Environment.CONFIG),
      accountUrl: '',
    });
  }
}
