import { History, Location, LocationDescriptor } from 'history';
import { useEffect, useState } from 'react';
import getAngularService from 'scripts/util/get-angular-service/get-angular-service';
import { goTo } from 'scripts/util/link/link';

interface IUseRouterState {
  location: Pick<Location, 'pathname' | 'search'>; // only including properties that we have implemented here
  history: Pick<History, 'push' | 'replace'>; // only including functions that we have implemented here
}

export function useRouter(): IUseRouterState {
  const [location, setLocation] = useState<IUseRouterState['location']>({
    pathname: window.location.pathname,
    search: window.location.search,
  });
  const handleStateChange = (): void => {
    setLocation({ pathname: window.location.pathname, search: window.location.search });
  };
  const history: IUseRouterState['history'] = {
    push(to: LocationDescriptor): void {
      goTo(to, false);
    },
    replace(to: LocationDescriptor): void {
      goTo(to, true).then(() => handleStateChange());
    },
  };
  useEffect(() => {
    const $rootScope = getAngularService<ng.IRootScopeService>('$rootScope');
    const cleanUp = $rootScope.$on('$stateChangeSuccess', handleStateChange);
    return () => cleanUp();
  }, []);
  return {
    location,
    history,
  };
}
