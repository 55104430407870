import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { Link } from 'scripts/ui/link/link';
import { useTranslation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

const TrackedGoBackLink = withClickTracking(Link, 'back');

export const ProviderReferralsGoBackLink: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.COMMON);

  return (
    <TrackedGoBackLink className="icon-back-btn" data-testid="provider-referrals-go-back" to="/dashboard">
      <span className="sr-only">{t('GO_BACK')}</span>
    </TrackedGoBackLink>
  );
};
