import React, { FunctionComponent } from 'react';
import { Anchor } from 'scripts/ui/anchor/anchor';
import CONFIG from 'scripts/util/constants/config';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { Feature } from 'scripts/ui/feature/feature';
import { useTranslation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

export const MrRxSpendingCostSummary: FunctionComponent = () => {
  const TrackedResourceAnchor = withClickTracking(Anchor, 'view-details');
  const link = CONFIG.ARCADE_WEB_OPTUM_RX_SPENDING_AND_COST_SUMMARY;
  const { t } = useTranslation(Dictionary.MR_RX_SPENDING_COST_SUMMARY);
  return (
    <Feature featureId="mr-rx-spending-cost-summary">
      <div className="mr-rx-spending-cost-summary container">
        <header className="section-header">
          <h2 className="mr-rx-spending-cost-summary-title">{t('MR_RX_SPENDING_COST_SUMMARY_HEADER')}</h2>
        </header>
        <p>{t('MR_RX_SPENDING_COST_SUMMARY_BODY')}</p>
        <div className="mr-rx-spending-cost-summary-link">
          <TrackedResourceAnchor href={link} target="_blank">
            {t('MR_RX_SPENDING_COST_SUMMARY_LINK')}
          </TrackedResourceAnchor>
        </div>
      </div>
    </Feature>
  );
};
