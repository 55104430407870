import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import ClaimMarkPaidCheckbox from './claim-mark-paid-checkbox';

interface IClaimMarkPaidProps {
  claim: IClaim;
  onClaimUpdate: (claim: IClaim) => void;
}

export default function ClaimMarkPaid(props: IClaimMarkPaidProps): ReactElement {
  const { claim, onClaimUpdate } = props;
  const { t } = useTranslation(Dictionary.COMMON);
  const id = `mark-as-paid-checkbox-${claim.claimId}-${claim.claimKey}`;
  return (
    <div data-testid="claim-mark-paid" className="claim-mark-paid">
      <ClaimMarkPaidCheckbox claim={claim} id={id} onClaimUpdate={onClaimUpdate} />
      <label htmlFor={id}>{t('MARK_AS_PAID')}</label>
    </div>
  );
}
