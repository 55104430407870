import { angularMinimumEscape, parse } from 'scripts/util/uri/uri';
import { formatTrackingString, getFeatureList, getPlacement } from 'scripts/util/tracking/tracking-helper';
import {
  ITrackingEventRequest,
  TrackingClickType,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';
import getAngularService from 'scripts/util/get-angular-service/get-angular-service';
import { ITrackingService } from 'scripts/api/tracking/tracking.service';
import { LinkTarget } from 'scripts/api/api.interfaces';
import React from 'react';

function getClickType(element: HTMLElement): TrackingClickType {
  const clickType = element.getAttribute('data-click-type');
  if (clickType) {
    return clickType as TrackingClickType;
  }
  const href = element.getAttribute('href');
  return href ? TrackingClickType.StateChange : TrackingClickType.PageEvent;
}

function callOriginalOnClick(
  event: React.MouseEvent<HTMLElement>,
  originalOnClick?: (event: React.MouseEvent<HTMLElement>) => void,
): void {
  if (originalOnClick && typeof originalOnClick === 'function') {
    originalOnClick(event);
  }
}

export function handleOnClick(
  event: React.MouseEvent<HTMLElement>,
  originalOnClick?: (event: React.MouseEvent<HTMLElement>) => void,
): void {
  const currentTarget = event && event.currentTarget;
  const href = currentTarget && currentTarget.getAttribute('href');
  const linkTarget = currentTarget && currentTarget.getAttribute('target');
  const clickType = currentTarget && getClickType(currentTarget);
  const featureListString = currentTarget.getAttribute('data-feature-list');
  const featureList = getFeatureList(currentTarget).concat(featureListString ? featureListString.split(',') : []);
  const trackingService = getAngularService<ITrackingService>('trackingService');
  const placement = currentTarget ? getPlacement(currentTarget) : '';

  const clickEvent: ITrackingEventRequest = {
    actionName: formatTrackingString(currentTarget.getAttribute('data-track-id')),
    featureList,
    placement,
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    trigger: TrackingTriggerType.Click,
    uri: window.location.pathname,
  };

  const anchor = parse(href);
  const isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
  const isExternalLink =
    isInternalRedirect ||
    linkTarget === LinkTarget.Blank ||
    anchor.hostname !== window.location.hostname ||
    clickType === TrackingClickType.ExternalLink;

  if (href) {
    if (isExternalLink) {
      clickEvent.clickType = TrackingClickType.ExternalLink;
      if (isInternalRedirect) {
        const decodedUrl = angularMinimumEscape(href.split('deepLink=').pop());
        const redirectUrlAnchor = document.createElement('a');
        redirectUrlAnchor.href = decodedUrl;
        clickEvent.externalDomain = redirectUrlAnchor.hostname;
        clickEvent.externalUrl = decodedUrl.split('?')[0];
      } else {
        clickEvent.externalDomain = anchor.hostname;
        clickEvent.externalUrl = anchor.href.split('?')[0];
      }
    } else {
      clickEvent.clickType = clickType || TrackingClickType.StateChange;
    }
  } else {
    clickEvent.clickType = clickType || TrackingClickType.PageEvent;
  }

  trackingService.queueEvent(clickEvent);

  if (isExternalLink && linkTarget !== LinkTarget.Blank) {
    event.preventDefault();
    trackingService
      .postEvents()
      .finally(() => {
        callOriginalOnClick(event, originalOnClick);
        if (!isInternalRedirect) {
          window.location.href = href;
        }
      })
      .subscribe();
  } else {
    callOriginalOnClick(event, originalOnClick);
  }
}

interface ITrackedProps {
  onClick?: () => void;
  onKeyPress?: () => void;
  href?: string;
  target?: string;
  dataTrackId?: string;
  dataClickType?: string;
  trackId?: string;
  clickType?: TrackingClickType;
}

function enhanceComponentWithClickTracking<T extends ITrackedProps>(
  WrappedComponent: React.ComponentType,
): React.ComponentType<T> {
  const WithClickTrackingComponent: React.FunctionComponent<T> = ({
    clickType,
    dataTrackId,
    dataClickType,
    onClick,
    trackId,
    ...props
  }) => {
    const handleClick = (e?: React.MouseEvent<HTMLElement>): void => {
      handleOnClick(e, onClick);
    };
    return (
      <FeatureContext.Consumer>
        {value => (
          <WrappedComponent
            {...props}
            onClick={handleClick}
            data-track-id={dataTrackId || trackId}
            data-feature-list={value.featureList}
            data-click-type={dataClickType || clickType}
          />
        )}
      </FeatureContext.Consumer>
    );
  };

  return WithClickTrackingComponent;
}

export default function withClickTracking<T>(
  WrappedComponent: React.ComponentType<T>,
  trackId: string,
  clickType?: TrackingClickType,
): React.ComponentType<T> {
  const TrackedComponent = enhanceComponentWithClickTracking(WrappedComponent);
  return props => <TrackedComponent trackId={trackId} clickType={clickType} {...props} />;
}
