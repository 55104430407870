import {
  AnyClaimType,
  ClaimDocumentType,
  ClaimType,
  ClaimVideoEligibility,
  IAnyClaim,
  IAnyClaimDetails,
  IClaim,
  IFinancialClaim,
  IFinancialClaimDetails,
  IHealthcareClaimDetails,
} from 'scripts/api/claims/claims.interfaces';
import { AccountType } from 'scripts/api/ledger/ledger.interfaces';
import { IProfileUser, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import getAngularService from '../get-angular-service/get-angular-service';
import { documentUris } from '../uri/uri';
import CONFIG from '../constants/config';
import { IConfig } from '../constants/environment.interfaces';

/*
This function returns 'dependentSeqNbr' from Claim if it is a valid one,
  otherwise returns 'dependentSeqNbr' coming from params.

This is a workaround for inconsistent api which in case of /claims/detail returns invalid dependentSeqNbr.
  Then dependentSeqNbr is expected to be in url params.
*/
export function determineDependentSeqNbr(
  claim: IAnyClaim | IAnyClaimDetails,
  dependentSeqNbrFromParams: string = '',
): string | null {
  const seqNbrRegex = /^\d{2}$/;

  const { serviceRecipient: { dependentSeqNbr: dependentSeqNbrFromClaim = '' } = {} } = claim;
  if (seqNbrRegex.test(dependentSeqNbrFromClaim)) {
    return dependentSeqNbrFromClaim;
  }

  if (seqNbrRegex.test(dependentSeqNbrFromParams)) {
    return dependentSeqNbrFromParams;
  }

  return null;
}

/*
  This function determines if a claim should have visible payNow button based on:
  - claim type
  - minimal youMayOweAmount
  - being marked as paid
  - presence of dependentSeqNbr either on Claim or provided separately as a param
*/
export function showPayNow(claim: IClaim | IHealthcareClaimDetails, dependentSeqNbrFromParams?: string): boolean {
  const hasDependentSeqNbr = !!determineDependentSeqNbr(claim, dependentSeqNbrFromParams);
  return !!(
    claim.claimType === ClaimType.Medical &&
    claim.balance.youMayOweAmount &&
    claim.balance.youMayOweAmount.value > 3 &&
    !claim.claimManagementInfo.markPaid &&
    hasDependentSeqNbr
  );
}

function isFunction<T>(t: T | (() => T)): t is () => T {
  return typeof t === 'function';
}

export function getByType<T, U>(
  type: AnyClaimType,
  healthRelated: (() => T) | T,
  financialRelated: (() => U) | U,
): T | U {
  const healthRelatedCb = isFunction(healthRelated) ? healthRelated : () => healthRelated;
  const financialRelatedCb = isFunction(financialRelated) ? financialRelated : () => financialRelated;
  switch (type) {
    case ClaimType.Medical:
    case ClaimType.Dental:
    case ClaimType.Rx:
    case ClaimType.All:
      return healthRelatedCb();
    case AccountType.DCSA:
    case AccountType.FSADC:
    case AccountType.FSAHC:
    case AccountType.FSALP:
    case AccountType.HCSA:
    case AccountType.HRA:
    case AccountType.HRAAP:
    case AccountType.HRAPD:
    case AccountType.HRASD:
    case AccountType.MRA:
      return financialRelatedCb();
    default:
      console.error('an unsupported type of claim was provided');
  }
}

export function getByClaim<T, U>(
  claim: IAnyClaim | IAnyClaimDetails,
  healthRelated: (() => T) | T,
  financialRelated: (() => U) | U,
): T | U {
  const type: AnyClaimType = (claim as IFinancialClaim).accountType || (claim as IClaim).claimType;
  return getByType(type, healthRelated, financialRelated);
}

export function getClaimAccountAbbreviation(type: AnyClaimType): string {
  switch (type as AccountType) {
    case AccountType.FSAHC:
    case AccountType.FSALP:
      return 'FSA';
    case AccountType.HRA:
    case AccountType.HRAAP:
    case AccountType.HRAPD:
    case AccountType.HRASD:
      return 'HRA';
    default:
      return type;
  }
}

export function getClaimDetailsUrl(claim: IAnyClaim, from?: string): string {
  const $state = getAngularService<ng.ui.IStateService>('$state');
  const { claimKey, serviceRecipient: { dependentSeqNbr = undefined } = {} } = claim;
  const type: AnyClaimType = getByClaim(claim, (claim as IClaim).claimType, (claim as IFinancialClaim).accountType);
  if (claimKey && type) {
    const params = {
      claimKey,
      type,
      claimDetailsFrom: from || $state.href($state.current, $state.params),
      dependentSeqNbr,
    };
    return $state.href('authenticated.claimsAndAccounts.claimDetails', params);
  } else {
    return $state.href('authenticated.claimsAndAccounts.claims');
  }
}

export function getExplanationOfBenefitsLink(
  currentUser: IProfileUser,
  healthClaimOrAnyClaimDetails: IClaim | IAnyClaimDetails,
): string {
  return getByClaim(
    healthClaimOrAnyClaimDetails,
    () => {
      const { claimType, claimKey, claimReference, eobDocument } = healthClaimOrAnyClaimDetails as
        | IClaim
        | IHealthcareClaimDetails;
      const membershipCategory =
        currentUser.membershipCategory === MembershipCategory.OXFORD ? currentUser.membershipCategory : undefined;

      return documentUris.healthcareClaimsForm(currentUser.rallyId, ClaimDocumentType.EOB, eobDocument, {
        claimType,
        claimKey,
        claimReference,
        membershipCategory,
      });
    },
    () => {
      const { accountType, documents } = healthClaimOrAnyClaimDetails as IFinancialClaimDetails;

      return documentUris.financialClaimsForm(currentUser.rallyId, ClaimDocumentType.EOB, documents.EOB[0], {
        accountType,
      });
    },
  );
}

export function getYouMayOweAmount(claim: IClaim | IHealthcareClaimDetails): number | undefined {
  const {
    claimType,
    balance: { youMayOweAmount, patientResponsibility },
  } = claim;
  if (claimType === ClaimType.Medical || claimType === ClaimType.Rx) {
    return youMayOweAmount && youMayOweAmount.value;
  } else {
    return patientResponsibility && patientResponsibility.value;
  }
}

export function getYouPaidAmount(claim: IClaim): number {
  const {
    claimType,
    balance: { onlinePaidAmount, patientResponsibility },
    claimManagementInfo: { markPaid },
  } = claim;
  const youMayOwe = getYouMayOweAmount(claim) || 0;
  let youPaid = markPaid ? youMayOwe : 0;
  if (claimType === ClaimType.Medical) {
    youPaid += onlinePaidAmount ? onlinePaidAmount.value : 0;
  } else if (claimType === ClaimType.Rx) {
    youPaid += patientResponsibility ? patientResponsibility.value : 0;
  }
  return youPaid;
}

export function isFinancialClaim(
  claim: IAnyClaim | IAnyClaimDetails,
): claim is IFinancialClaim | IFinancialClaimDetails {
  return getByClaim(claim, false, true);
}

export function isHealthcareClaim(claim: IAnyClaim | IAnyClaimDetails): claim is IClaim | IHealthcareClaimDetails {
  return getByClaim(claim, true, false);
}

export function getAAClaimStatusString(claims: IAnyClaim[]): string {
  return claims
    .map(claim => claim.claimStatus)
    .reduce((claims, claim) => (claims.indexOf(claim) == -1 ? [...claims, claim] : claims), [])
    .join('|');
}

export function getClaimsSearchMonthsAgo({ ARCADE_WEB_CLAIMS_SEARCH_MONTHS_AGO }: IConfig = CONFIG): number {
  const monthsAgo = parseInt(ARCADE_WEB_CLAIMS_SEARCH_MONTHS_AGO, 10);
  return isNaN(monthsAgo) ? 18 : monthsAgo;
}

export function isVideoAvailable(claim: IAnyClaim | IAnyClaimDetails): boolean {
  return (
    isHealthcareClaim(claim) &&
    claim.claimVideoEligibility &&
    (claim.claimVideoEligibility === ClaimVideoEligibility.Eligible ||
      claim.claimVideoEligibility === ClaimVideoEligibility.FirstEligible)
  );
}
