import { Observable } from 'rxjs/Observable';
import { understandingHealthStatement } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import statementsTemplate from 'views/claims-and-accounts/statements/statements.html';
import { IHealthcareStatement } from '../../../api/documents/documents.interfaces';
import { IDocumentsService } from '../../../api/documents/documents.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IStatementsByYear {
  [year: number]: IHealthcareStatement[];
}

export class StatementsController implements ng.IComponentController {
  public currentRallyId: string;
  public statementsByYear: IStatementsByYear;
  public statementsRequest: Observable<IHealthcareStatement[]>;
  public understandingHealthStatement: IResource;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private documentsService: IDocumentsService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.STATEMENTS);

    this.understandingHealthStatement = understandingHealthStatement;

    this.statementsByYear = {};
    this.statementsRequest = this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .do(rallyId => (this.currentRallyId = rallyId))
      .flatMap(rallyId => this.documentsService.getHealthcareStatements(rallyId))
      .map(rsp => rsp.data.memberHealthStatements);
  }

  public $onInit(): void {
    this.statementsRequest.subscribe(statements => {
      statements
        .sort((a, b) => (a.startDate.isAfter(b.startDate) ? -1 : 1))
        .forEach(statement => {
          if (statement.startDate.isValid() && statement.stopDate.isValid()) {
            const year = statement.startDate.get('year');
            if (!this.statementsByYear[year]) {
              this.statementsByYear[year] = [];
            }
            this.statementsByYear[year].push(statement);
          }
        });
    }, console.warn);
  }

  public getYears(statementsByYear: IStatementsByYear): number[] {
    return Object.keys(statementsByYear)
      .map(year => parseInt(year, 10))
      .sort((a, b) => b - a);
  }

  public getPdfLink(documentId: string): string {
    return this.documentsService.getHealthcareStatementPdfLink(this.currentRallyId, documentId);
  }

  public print(): void {
    window.print();
  }
}

export class StatementsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = StatementsController;
    this.templateUrl = statementsTemplate;
  }
}
