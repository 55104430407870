import { applyMiddleware, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import featureFlags from 'scripts/util/feature-flag/feature-flag';
import rootReducers from '../reducers';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';

const middlewares = [thunk];

if (featureFlags.isReduxLoggingOn() && process.env.NODE_ENV !== 'test') {
  middlewares.push(
    createLogger({
      collapsed: true,
      duration: true,
    }),
  );
}

export const configureStore = (preloadedState = {}): Store<IReduxState> =>
  createStore(rootReducers, preloadedState, applyMiddleware(...middlewares));

const store = configureStore();

export default store;
