import { IConfig, IFeatureFlags } from './environment.interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    CONFIG: IConfig;
    FEATURE_FLAGS: IFeatureFlags;
  }
}

const DEFAULT_FEATURE_FLAGS: IFeatureFlags = {
  ARCADE_FEATURES_ACTIVATE_SWITCH_ENABLED: false,
  ARCADE_FEATURES_ACTIVATE_VIDEO: true,
  ARCADE_FEATURES_ADDITIONAL_SERVICES: true,
  ARCADE_FEATURES_ADVANTAGE: false,
  ARCADE_FEATURES_ANALYTICS_DEBUG: false,
  ARCADE_FEATURES_AUTO_PAYMENT: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_DCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSADC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSAHC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSALP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAAP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAPD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRASD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_MRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_DCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSADC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSAHC: true,
  ARCADE_FEATURES_CLAIM_DETAILS_FSALP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HCSA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRA: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRAAP: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRAPD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_HRASD: true,
  ARCADE_FEATURES_CLAIM_DETAILS_MRA: true,
  ARCADE_FEATURES_CLAIM_HELP_MENU_ALL_CLAIMS: true,
  ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN: true,
  ARCADE_FEATURES_DIRECT_DEPOSIT: true,
  ARCADE_FEATURES_EI_RECOMMENDATIONS: true,
  ARCADE_FEATURES_EXPATS_MVP_DONUT: false,
  ARCADE_FEATURES_EXPATRIATE_CLAIMS_LINK: false,
  ARCADE_FEATURES_FPC_PCP_FOR_GATED: true,
  ARCADE_FEATURES_FPC_PCP_ADDITIONAL_PARAMS_REQUIRED: false,
  ARCADE_FEATURES_HEALTH_ASSESMENT: false,
  ARCADE_FEATURES_GENESYS_CHAT_CLAIMS: true,
  ARCADE_FEATURES_LINK_TO_INTERNATIONAL_CLAIMS: true,
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY: false,
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_CLAIM_TOTALS: true,
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_REACT: true,
  ARCADE_FEATURES_MR_NICE_DONUT_SUPPRESSION: true,
  ARCADE_FEATURES_MR_OPTUM_CLAIMS: true,
  ARCADE_FEATURES_MR_RALLY_CLAIMS: true,
  ARCADE_FEATURES_MR_PHARMACIES: true,
  ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS: true,
  ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY: true,
  ARCADE_FEATURES_MR_SECURE_MESSAGES: true,
  ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY: true,
  ARCADE_FEATURES_NOTIFICATION: false,
  ARCADE_FEATURES_OFFLINE_WEB_SUPPRESS_PCP_STRIP: false,
  ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN: true,
  ARCADE_FEATURES_PAY_NOW_MODAL: true,
  ARCADE_FEATURES_PAY_NOW_MODAL_REDIRECT: true,
  ARCADE_FEATURES_PAY_NOW_MODAL_TARGETING_ROLLOUT: false,
  ARCADE_FEATURES_PAY_NOW_MODAL_WARNING: false,
  ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME: false,
  ARCADE_FEATURES_PCP_POLARIS: true,
  ARCADE_FEATURES_PROFILE_RALLYPAY_CLAIM_START_DATE: true,
  ARCADE_FEATURES_QUICK_LINKS: true,
  ARCADE_FEATURES_RALLY_RX: false,
  ARCADE_FEATURES_REACT_ID_CARD_MODAL: false,
  ARCADE_FEATURES_REACT_PCP_STRIP: false,
  ARCADE_FEATURES_REACT_QUICK_LINKS: true,
  ARCADE_FEATURES_REDUX_LOGGING: true,
  ARCADE_FEATURES_REFERRAL_REQUIRED: false,
  ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO: true,
  ARCADE_FEATURES_SHOW_DENTAL_CLAIMS_PDF_SUBMISSION_FORM: true,
  ARCADE_FEATURES_SHOW_IOS_TRANSCRIPT_VIDEO: false,
  ARCADE_FEATURES_STATEMENTS: true,
  ARCADE_FEATURES_SUBMIT_CLAIM: true,
  ARCADE_FEATURES_SUBMIT_CLAIM_HEADER_REACT: false,
  ARCADE_FEATURES_SUBMIT_CLAIM_OTHER_FORMS_REACT: false,
  ARCADE_FEATURES_TIER_TWO: true,
  ARCADE_FEATURES_TIERED_BENEFITS: false,
  ARCADE_FEATURES_RALLY_RX_IN_PRODUCT_ELIGIBILITY: false,
  ARCADE_FEATURES_VIDEO_BANNER: false,
  ARCADE_FEATURES_EI_VIRTUAL_VISIT_PCP_ROW_ITEM: true,
};

const envFeatureFlags = window.FEATURE_FLAGS;
const useDefaultFeatureFlags =
  !envFeatureFlags || (envFeatureFlags.constructor === Object && Object.keys(envFeatureFlags).length === 0);
// The values defined in DEFAULT_FEATURE_FLAGS are only used locally. All feature flags
// necessary for the deployed application need to be done in neptune deploy.
const FEATURE_FLAGS: IFeatureFlags = useDefaultFeatureFlags ? DEFAULT_FEATURE_FLAGS : envFeatureFlags;

export default FEATURE_FLAGS;
