import {
  allClaims,
  connect,
  drugCostEstimator,
  paymentsOverview,
  pharmacyLocator,
  valueAddedServices,
} from 'scripts/util/resource/resource.constants';
import linkBarTemplate from 'views/dashboard/link-bar.html';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';
import { ILinkBarLink } from '../link-bar/link-bar.interfaces';

export class AdditionalServicesController implements ng.IComponentController {
  public headerText = 'ADDITIONAL_SERVICES';
  public linkRows: ILinkBarLink[][];
  public links: ILinkBarLink[];

  constructor(
    private $state: angular.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private resourceService: IResourceService,
    private userService: IUserService,
    private profileService: IProfileService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ADDITIONAL_SERVICES);
    $translatePartialLoader.addPart(Dictionary.QUICK_LINKS);

    this.links = [
      {
        id: 'CLAIMS',
        title: 'VIEW_YOUR_CLAIMS',
        text: 'VIEW_YOUR_CLAIMS',
        href: this.resourceService.get(allClaims),
        icon: 'icon-circle-eob',
      },
      {
        id: 'PAYMENT',
        title: 'MAKE_A_PAYMENT',
        text: 'MAKE_A_PAYMENT',
        href: this.resourceService.get(paymentsOverview),
        icon: 'icon-circle-forms',
      },
      {
        id: 'VALUE_ADDED',
        title: 'VIEW_DISCOUNTS_AND_SERVICES',
        text: 'VIEW_DISCOUNTS_AND_SERVICES',
        href: this.resourceService.get(valueAddedServices),
        icon: 'icon-circle-eob',
      },
      {
        id: 'FIND_PROVIDER',
        title: 'FIND_PROVIDER',
        text: 'FIND_PROVIDER',
        href: this.$state.href('internalRedirect', { deepLink: this.resourceService.get(connect) }),
        action: e => this.internalRedirect(e, this.resourceService.get(connect)),
        icon: 'icon-circle-id-card',
      },
      {
        id: 'PHARMACY',
        title: 'LOCATE_PHARMACY',
        text: 'LOCATE_PHARMACY',
        href: this.resourceService.get(pharmacyLocator),
        icon: 'icon-circle-materials',
      },
      {
        id: 'DRUG',
        title: 'LOOK_UP_DRUGS',
        text: 'LOOK_UP_DRUGS',
        href: this.resourceService.get(drugCostEstimator),
        icon: 'icon-circle-pharmacy',
      },
    ];

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(() => {
        // for now, show all the placeholder links
        this.links.forEach(link => (link.show = true));
        this.updateRows();
      })
      .subscribe(() => undefined, console.warn);
  }

  public getIcon(link: ILinkBarLink): string {
    return this.Files.getIcon(link.icon);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private updateRows(): void {
    // Supports a maximum of 8 links
    const availableLinks = this.links.filter(link => link.show).slice(0, 8);
    this.linkRows = [];
    if (availableLinks.length <= 4) {
      this.linkRows.push(availableLinks);
    } else {
      const split = Math.ceil(availableLinks.length / 2);
      this.linkRows.push(availableLinks.slice(0, split));
      this.linkRows.push(availableLinks.slice(split, availableLinks.length));

      if (this.linkRows[0].length > this.linkRows[1].length) {
        // push an empty filler link
        this.linkRows[1].push(undefined);
      }
    }
  }
}

export class AdditionalServicesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = AdditionalServicesController;
    this.templateUrl = linkBarTemplate;
  }
}
