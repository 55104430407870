import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimStatus, IAnyClaim } from 'scripts/api/claims/claims.interfaces';
import { ICONS } from 'scripts/util/constants/files';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export interface IClaimInfoProps {
  claim: IAnyClaim;
}

export const ClaimInfo: FunctionComponent<IClaimInfoProps> = props => {
  const {
    claim: { lastServiceDate, processedDate, serviceDate, serviceRecipient },
  } = props;

  let icon: string;
  switch (props.claim.claimStatus) {
    case ClaimStatus.Denied:
      icon = ICONS['icon-circle-denied'];
      break;
    case ClaimStatus.PartiallyDenied:
      icon = ICONS['icon-circle-partially-denied'];
      break;
  }

  const showLastServiceDate = lastServiceDate && !serviceDate.isSame(lastServiceDate, 'day');
  const { t } = useTranslation(Dictionary.COMMON);
  const claimStatusClass = `-${props.claim.claimStatus.toLowerCase().replace('_', '-')}`;

  return (
    <div className="claim-info">
      <div className="claim-value">
        <span>{t('PATIENTS_CLAIM_DOS', { patient: serviceRecipient.firstName })}</span>
        <span> - {serviceDate.format('MM/DD/YYYY')}</span>
        {showLastServiceDate && <span data-testid="last-service-date"> - {lastServiceDate.format('MM/DD/YYYY')}</span>}
      </div>
      <div className={classNames('claim-status', claimStatusClass)}>
        {icon && <img className="claim-status-icon" src={icon} role="presentation" />}
        <span className="sr-only">{t('CLAIM_STATUS')}:</span>
        <span>{t(props.claim.claimStatus)}</span>
        {processedDate && <span data-testid="processed-date"> - {processedDate.format('MM/DD/YYYY')}</span>}
      </div>
    </div>
  );
};
