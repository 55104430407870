import React, { FunctionComponent, useEffect } from 'react';
import { Feature } from 'scripts/ui/feature/feature';
import { selectedUser, selectProductsData, selectProductsError } from 'scripts/selectors/profile-service-selectors';
import { getAccounts } from 'scripts/thunks/ledger-service-thunks';
import { getCampaigns } from 'scripts/thunks/targeting-service-thunks';
import { getProducts } from 'scripts/thunks/profile-service-thunks';
import { trackFeatures } from 'scripts/util/constants/track-features';
import QuickLink from './quick-link';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { connect } from 'react-redux';
import { IProfileUser, IProducts, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { selectAccountsData, selectAccountsError } from 'scripts/selectors/ledger-service-selectors';
import { CampaignPlacementType, ICampaigns } from 'scripts/api/targeting/targeting.interfaces';
import { selectCampaignsData, selectCampaignsError } from 'scripts/selectors/targeting-service-selectors';
import {
  hasActiveRxCoverage as determineActiveRxCoverage,
  hasLedgerAccess as determineLedgerAccess,
} from 'scripts/util/profile/profile';
import { isExpatriate } from 'scripts/util/user/user';
import { IQuickLinksCategory } from './quick-links.controller';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getExpatLinks, getEiQuickLinks } from './ei-quick-links';
import { getCsQuickLinks } from './cs-quick-links';
import { getMrQuickLinks } from './mr-quick-links';

export interface IQuickLinksProps extends IQuickLinksStateToProps, IQuickLinksDispatchToProps {}

interface IQuickLinksStateToProps {
  accounts?: ILedgerAccount[];
  accountsError?: boolean;
  campaigns?: ICampaigns;
  campaignsError?: boolean;
  currentSelectedProfile: IProfileUser;
  population?: IPopulation;
  products: IProducts;
  productsError: boolean;
}

interface IQuickLinksDispatchToProps {
  getAccounts: () => void;
  getCampaigns: (placements: string[]) => void;
  getProducts: () => void;
}

const initQuickLinks = (props: IQuickLinksProps): IQuickLinksCategory[] => {
  const { currentSelectedProfile, population, products } = props;
  const isExpat = isExpatriate(currentSelectedProfile);
  if (isExpat) {
    return getExpatLinks(population);
  } else if (currentSelectedProfile.lineOfBusiness === LineOfBusiness.EI) {
    return getEiQuickLinks(props);
  } else if (currentSelectedProfile.lineOfBusiness === LineOfBusiness.CS) {
    return getCsQuickLinks(population, products);
  } else if (currentSelectedProfile.lineOfBusiness === LineOfBusiness.MR) {
    return getMrQuickLinks(currentSelectedProfile, population, products);
  }

  return [];
};

export const RawQuickLinks: FunctionComponent<IQuickLinksProps> = props => {
  const { accounts, accountsError, campaigns, campaignsError, currentSelectedProfile, products, productsError } = props;

  const isCS = currentSelectedProfile.lineOfBusiness === LineOfBusiness.CS;
  const isEI = currentSelectedProfile.lineOfBusiness === LineOfBusiness.EI;
  const isMR = currentSelectedProfile.lineOfBusiness === LineOfBusiness.MR;
  const hasLedgerAccess = isEI && determineLedgerAccess(currentSelectedProfile);
  let hasActiveRxCoverage = false;
  if (isCS) {
    hasActiveRxCoverage = determineActiveRxCoverage(currentSelectedProfile);
  }

  let dependenciesLoaded = false;
  if (isEI) {
    dependenciesLoaded = !!(
      hasLedgerAccess &&
      (accounts || accountsError) &&
      (campaigns || campaignsError) &&
      (products || productsError)
    );
  } else if (isCS) {
    dependenciesLoaded = hasActiveRxCoverage ? !!(products || productsError) : true;
  } else if (isMR) {
    dependenciesLoaded = !!(products || productsError);
  }

  function loadDependencies(): void {
    if (isEI) {
      if (hasLedgerAccess && (!accounts || !accountsError)) {
        props.getAccounts();
      }

      if (!campaigns || !campaignsError) {
        props.getCampaigns([CampaignPlacementType.ArcadeDashboardQuickLinks]);
      }
    }

    // fetch products for EI/M&R users and CS users with active Rx coverage
    if (isCS && !hasActiveRxCoverage) {
      return;
    } else {
      if (!products || !productsError) {
        props.getProducts();
      }
    }
  }

  useEffect(() => {
    loadDependencies();
  }, [dependenciesLoaded]);

  let quickLinks: IQuickLinksCategory[] = [];
  if (dependenciesLoaded) {
    // using .slice here to show a max of 5 quick-links. certain M&R scenarios set show to true on more than 5.
    // previously did the same limitTo:5 in the AngularJS template
    quickLinks = initQuickLinks(props).slice(0, 5);
  }

  return (
    <Feature featureId={trackFeatures.quickLinks}>
      <div className="container quick-links" data-testid="quick-links">
        {quickLinks.map(quickLink => (
          <QuickLink key={`${quickLink.id}_${quickLink.text}`} link={quickLink} />
        ))}
      </div>
    </Feature>
  );
};

const mapStateToProps = (state: IReduxState): IQuickLinksStateToProps => ({
  accounts: selectAccountsData(state),
  accountsError: selectAccountsError(state),
  campaigns: selectCampaignsData(state),
  campaignsError: selectCampaignsError(state),
  currentSelectedProfile: selectedUser.selectProfile(state),
  population: state.population,
  products: selectProductsData(state),
  productsError: selectProductsError(state),
});

export const QuickLinks = withProvider(
  connect(
    mapStateToProps,
    { getAccounts, getCampaigns, getProducts },
  )(RawQuickLinks),
);
