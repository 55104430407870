import { Observable } from 'rxjs/Observable';
import { LineOfBusiness } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { CampaignPlacementType } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';

export interface IFitbitStatusService {
  get(): Observable<boolean>;
}

export class FitbitStatusService implements IFitbitStatusService {
  constructor(
    private profileService: IProfileService,
    private targetingService: ITargetingService,
    private userService: IUserService,
    private Environment: IEnvironmentConstants,
  ) {
    'ngInject';
  }
  public get(): Observable<boolean> {
    return this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.profileService.get(rallyId).map(rsp => rsp.data))
      .takeWhile(profile => profile.currentUser.lineOfBusiness === LineOfBusiness.MR)
      .flatMap(
        profile => {
          return this.targetingService
            .getCampaigns(profile.rallyId, [CampaignPlacementType.ActivateInLine])
            .map(rsp => rsp.data.placements);
        },
        (profile, placements) => ({ profile, placements }),
      )
      .map(({ profile, placements }) => {
        let showFitbitBanner = false;
        if (placements[CampaignPlacementType.ActivateInLine]) {
          // Ticket specifies a single client ID, but this is an array for future-proofing.
          const validClientIDs = FitbitStatusService.parseEnvVar(
            this.Environment.CONFIG.ARCADE_WEB_FITBIT_ENABLED_CLIENT_IDS,
          );
          const validPolicies = FitbitStatusService.parseEnvVar(
            this.Environment.CONFIG.ARCADE_WEB_FITBIT_ENABLED_POLICIES,
          );
          const isMR = profile.currentUser.lineOfBusiness === LineOfBusiness.MR;
          const isValidPolicy = validPolicies.indexOf(profile.currentUser.primaryPolicyNumber) !== -1;
          let isValidClientId = false;
          // clientInfo can show up as undefined for E&I responses from doppelganger, possibly in an env too.
          if (profile.clientInfo) {
            isValidClientId = validClientIDs.indexOf(profile.clientInfo.clientId) !== -1;
          }
          showFitbitBanner = isMR && isValidPolicy && isValidClientId;
        }
        return showFitbitBanner;
      })
      .defaultIfEmpty(false);
  }
  public static parseEnvVar(str: string): string[] {
    if (!str) {
      return [];
    }
    return str.split(',').filter(item => item);
  }
}
