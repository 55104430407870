import React, { FunctionComponent, ReactElement } from 'react';
import { IBenefitsSpending } from 'scripts/ui/accumulator/accumulator.interfaces';
import { BenefitNetwork, DeductibleOOP } from 'scripts/api/plans/plans.interfaces';
import { AccumulatorsListItem } from './accumulators-list-item';

interface IAccumulatorsListProps {
  accumData: IBenefitsSpending[];
  disableLabelModal?: boolean;
  showPlanName: boolean;
}

export const AccumulatorsList: FunctionComponent<IAccumulatorsListProps> = props => {
  const { accumData, disableLabelModal, showPlanName } = props;
  const benefitTypes = [DeductibleOOP.DEDUCTIBLE, DeductibleOOP.OOP];
  let inNetworkAccums: ReactElement[] = null;
  let outOfNetworkAccums: ReactElement[] = null;
  const accumWithPlanName: IBenefitsSpending = showPlanName && accumData.find(accum => accum.planName);

  if (accumData.some(accum => accum.network === BenefitNetwork.InNetwork)) {
    inNetworkAccums = benefitTypes.map(benefit => {
      const accumulatorData = accumData.find(
        accum => accum.benefit === benefit && accum.network === BenefitNetwork.InNetwork,
      );
      const key = `${benefit}-${BenefitNetwork.InNetwork}`;
      return (
        <AccumulatorsListItem
          accumulatorData={accumulatorData}
          benefit={benefit}
          disableLabelModal={disableLabelModal}
          network={BenefitNetwork.InNetwork}
          key={key}
        />
      );
    });
  }

  if (accumData.some(accum => accum.network === BenefitNetwork.OutOfNetwork)) {
    outOfNetworkAccums = benefitTypes.map(benefit => {
      const accumulatorData = accumData.find(
        accum => accum.benefit === benefit && accum.network === BenefitNetwork.OutOfNetwork,
      );
      const key = `${benefit}-${BenefitNetwork.OutOfNetwork}`;
      return (
        <AccumulatorsListItem
          accumulatorData={accumulatorData}
          benefit={benefit}
          disableLabelModal={disableLabelModal}
          network={BenefitNetwork.OutOfNetwork}
          key={key}
        />
      );
    });
  }

  return (
    <>
      {accumWithPlanName && (
        <h2 className="plan-name" data-testid="plan-name">
          {accumWithPlanName.planName}
        </h2>
      )}
      <ul className="accumulators-list">
        {inNetworkAccums}
        {outOfNetworkAccums}
      </ul>
    </>
  );
};
