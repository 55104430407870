import { ITrackingService } from 'scripts/api/tracking/tracking.service';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { IVideoData, IVideoTrackingEvent, TrackingTriggerType } from './../../api/tracking/tracking.interfaces';

const videoName = 'cdp onboarding video';

const playStartEventType = 'playStart';

const progressEventType = 'progress';

export const SUNDAY_SKY_VIDEO_PROGRESS_ACTION_NAME = 'videoprogress';

export const SUNDAY_SKY_VIDEO_START_ACTION_NAME = 'playstarttriggered';

export interface IProgressEventDetail {
  position: number;
}

export interface ISundaySkyTrackController {
  registerTracking(sundaySkyVideo: Element): void;
}

export class SundaySkyTrackController {
  constructor(
    private $location: ng.ILocationService,
    private Environment: IEnvironmentConstants,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
    this.$location = $location;
    this.Environment = Environment;
    this.trackingService = trackingService;
  }

  public registerTracking(sundaySkyVideo: Element): void {
    const handleStart = (): void => this.handleStart();
    const handleProgress = (e: CustomEvent<IProgressEventDetail>): void => this.handleProgress(e);
    sundaySkyVideo.addEventListener(playStartEventType, handleStart);
    sundaySkyVideo.addEventListener(progressEventType, handleProgress);
  }

  private handleProgress(e: CustomEvent<IProgressEventDetail>): void {
    const { position } = e.detail;
    if (position === 0 || position % 0.25 !== 0) {
      return;
    }
    const data = {
      videoName,
      videoProgress: position,
    };
    const videoTrackingEvent: IVideoTrackingEvent = this.getVideoTrackingEvent(
      SUNDAY_SKY_VIDEO_PROGRESS_ACTION_NAME,
      data,
      TrackingTriggerType.SectionLoad,
    );
    this.trackingService.queueVideoEvent(videoTrackingEvent);
  }

  private handleStart(): void {
    const data = {
      videoName,
      videoSource: 'portal',
      playStartTriggered: true,
    };
    const videoTrackingEvent: IVideoTrackingEvent = this.getVideoTrackingEvent(
      SUNDAY_SKY_VIDEO_START_ACTION_NAME,
      data,
      TrackingTriggerType.Click,
    );
    this.trackingService.queueVideoEvent(videoTrackingEvent);
  }

  private getVideoTrackingEvent(
    actionName: string,
    videoData: IVideoData,
    trigger: TrackingTriggerType,
  ): IVideoTrackingEvent {
    return {
      trigger,
      uri: this.$location.url(),
      serviceVersion: this.Environment.CONFIG.ARCADE_WEB_VERSION,
      placement: '',
      actionName,
      featureList: [],
      videoData,
    };
  }
}
