import React, { FunctionComponent } from 'react';
import { BenefitNetwork, BenefitPaymentType, DeductibleOOP } from 'scripts/api/plans/plans.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import IconInfo from 'images/icons/icon-info.svg';

interface IAccumulatorLabelModalLinkProps {
  benefit: DeductibleOOP;
  modalLabel: string;
  network: BenefitNetwork;
  type: BenefitPaymentType;
}

export const AccumulatorLabelModalLink: FunctionComponent<IAccumulatorLabelModalLinkProps> = props => {
  const { benefit, modalLabel, network, type } = props;

  const TrackedModalLink = withClickTracking(Link, `accumulator-label-${benefit}-modal`);

  return (
    <TrackedModalLink
      className={`${benefit}-modal-explanation`}
      data-testid={`${benefit}-modal-explanation`}
      to={`/modal/medical-account-explanation/${network}/${type}`}
    >
      <IconInfo className="icon-info" aria-hidden="true" />
      <p>{modalLabel}</p>
    </TrackedModalLink>
  );
};
