import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { IResource } from './resource.interfaces';
import { parse } from 'url';

export const activate: IResource = ({ id }) => `/activate?locale=${id}`;

export const activateStart: IResource = ({ id }) => `/activate/start?locale=${id}`;

export const advantage: IResource = ({ advantageUrl }) => `${advantageUrl}`;

export const connect: IResource = ({ connectUrl, id }) => `${connectUrl}?locale=${id}`;

export const healthRecord: IResource = ({ healthRecordUrl }) => `${healthRecordUrl}`;

export const rewards: IResource = ({ rewardsUrl }) => `${rewardsUrl}`;

export const rx: IResource = ({ rxUrl }) => `${rxUrl}`;

export const benefitsCoverage: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/benefits-coverage.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits-coverage.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/benefits-coverage.html`,
};

export const benefitsCoverageMedical: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/benefits-coverage/medical.html`;

export const benefitsCoveragePharmacy: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/benefits-coverage/pharmacy.html`;

export const accumulatorPlanDocuments: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ibaagMedicalDetailLayout.do?keyword=OUT_OF_POCKET,OUTOFPOCKET,OUT-OF-POCKET&csId=15013`;

export const manageOptumBankAccount: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_OptumBank_ACCTDETAIL`;

export const idCardsByMail: IResource = {
  default: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/account-profile/request-id-cards-by-mail.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/order-materials/overview.html`,
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/submitQuesMedicaidStepAem.do?questionType=idcardrequest`,
};

export const contactUs: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/contact-us/overview.html`;

export const claimForms: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms.html`;

export const statements: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/viewstatslayoutaem.do`;

export const documents: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/myDocumentslayoutAem.do`;

export const accountSettings: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/account-profile.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/account/profile.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/account-profile.html`,
};

export const automaticPaymentSettings: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/autoPaymentLayoutaem.do`;

export const directDepositSettings: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/directDepositLayoutAem.do`;

export const manageDirectDeposit: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do` + '?partner=sso_SSOPartner_GS_DD_CONTACT2';

export const manageYourAccount: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do` +
  '?partner=sso_SSOPartner_OptumHealthBank_CAP&psnName=link.nav.ca.rmsa&requestor=/leftNav';

export const submitClaim: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}` + '/secure/claims-account/claim-forms.html';

export const allClaims: IResource = {
  default: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claims-summary.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/claims/overview.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/claims-account/claims-summary.html`,
};

export const claimDetails: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}` + '/secure/claims-account/claim-detail.html';

export const claimAccountBalances: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/account-balance.html`;

export const instamedPayNow: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/claimsSsoOutBoundAEM.do?partner=sso_SSOPartner_INSMEMPAY&key=`;

export const instamedPaymentDetails: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/claimsSsoOutBoundAEM.do?partner=sso_SSOPartner_InstamedViewPayReceipt&InsPayTransID=`;

export const instamedPayments: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_InstamedMyClaimPay&psnName=link.nav.ca.myclaimpayments`;

export const instamedAccounts: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}` +
  '/member/ssoOutBound.do?partner=sso_SSOPartner_InstamedMyClaimPayAccounts&psnName=link.nav.ca.myclaimpaymentAccounts';

export const instamedHelp: IResource = 'https://www.instamed.com/Click2Pay/help.html';

export const appealMedialClaimsOffline: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/submit-appeal-grievance-by-mail.html`;

export const appealMedicalClaimsOnline: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/submit-medical-appeals.html`;

export const appealMedicalClaimDetailOnline: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/appealrequestcontentAem.do?` +
  'target=information&requestParam=Claims&APPEAL_SUMMARY_REQ=true&requestor=/appealModalPopup&key=';

export const appealDentalClaimsOffline: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/dental-grievance-form.html`;

export const claimReleaseInfo: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/release-of-information.html`;

export const claimReleaseInfoEmpire: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/externalURLLayout.do?psnName=link.paperappeal.authorizationEmpireURL&csId=12041&requestor=/appealClaimDetail`;

export const askAQuestion: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/help-ask-a-question.html`,
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/submitQuesMedicaidStepAem.do`,
};

export const chatWithAgent: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/clicktochataem.do`;

export const understandingHealthStatement: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/claims/Understanding_Your_Health_StatementUpdated.pdf`;

export const visionClaims: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do` + '?partner=sso_SSOPartner_MyUhcVision&csId=15066';

export const reviewDosAndDonts: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reviewdodontslayoutaem.do`;

export const reviewEligibleExpenses: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/eligibleFSAExpenseslayoutaem.do`;

export const oxfordAccessRequestForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/access_request_form.pdf`;

export const oxfordAccidentalInjuryForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/Accidental_Injury_Form_R5.pdf`;

export const oxfordAccountingRequestForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/accounting_request_form.pdf`;

export const oxfordAmendmentRequestForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/amendment_request_form.pdf`;

export const oxfordConfidentialCommunicationRequestForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/conf_comm_form.pdf`;

export const dcsaPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/jpmc/DCSA_ClaimForm.pdf`;

export const dentalPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/dental-claim-form.pdf`;

export const dentalOnlineForm: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms/dental-claim-form.html`;

export const empireInsideNYMedicalPdfForm: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/staticPdfLayout.do?` +
  'psnName=link.insidenewyorkclaimform.empire&requestor=/submitClaimDetail&pdfUrl=claims/EmpireInStateFormNew.pdf?' +
  'psnName=link.insidenewyorkclaimform.empireURL&requestor=/submitClaimDetail';

export const empireOutsideNYMedicalPdfForm: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/staticPdfLayout.do?` +
  'psnName=link.outsidenewyorkclaimform.empire&requestor=/submitClaimDetail&pdfUrl=claims/EmpireOutOfStateFormNew.pdf?' +
  'psnName=link.outsidenewyorkclaimform.empireURL&requestor=/submitClaimDetail';

export const empireSurpriseBillAssignmentPdfForm: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/content/myuhc/Member/Assets/Pdfs/Empire_OON_assignment_benefits_form.pdf`;

export const exchangeMonthlyPremiumPayment: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_Cobra_HealthInsurancePremium&csId=15067`;

export const expatGlobalGateway: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_GS_GlobalResources2`;

export const expatHealthResources: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/health-resources.html`;

export const expatMedicalPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/Expat_MBR-C-26437_ClaimForm_180614_fillable_Standard.pdf`;

export const expatSubmitClaims: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_GS_SUB_CLAIMS2`;

export const oxfordDisabilityQuestionnaire: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/Disability.pdf`;

export const fsahcPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/fsa/FSA_Healthcare_Claim_Form.pdf`;

export const fsaOnlineForm: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms/submit-claim-form.html#submit`;

export const hraOnlineForm: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms/submit-claim-form.html#submit`;

export const hraPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/hra/HRA_ClaimForm_cams.pdf`;

export const internationalClaims: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_GS_VIEW_CLAIMS2`;

export const jpmcOnlineForm: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms/submit-claim-form.html#submit`;

export const medicalOnlineForm: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account/claim-forms/medical-claim-form.html`;

export const medicalPDFForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/medClaimForm.pdf`;

export const oxfordMemberAuthorizationForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/7322_R4_Member_Authorization_Form.pdf`;

export const mentalHealthOnlineForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_UBH_SUBMITCLAIM`;

export const mraHcsaPdfForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/jpmc/MRA_HCSA_ClaimForm.pdf`;

export const oxfordOptumRxAuthorizationForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/10872_ORx_PHI_Authorization.pdf`;

export const oxfordOptumRxMailOrderForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/mail_order_form.pdf`;

export const oxfordPharmacyReimbursementClaimForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/prescription_reimb_claim_form.pdf`;

export const oxfordPriorCarrierDeductibleForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/pcd_4878.pdf`;

export const oxfordRestrictedRequestForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/HIPAA%20Member%20Forms/restriction_request_form.pdf`;

export const oxfordSweatEquityClaimFormSpanish: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/SweatEquity_Oxford_Member_Claim+Form_Spanish.pdf`;

export const oxfordSweatEquityClaimForm: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/SweatEquity_Oxford_Member_Claim%20Form.pdf`;

export const oxfordSweatEquityWaiverSpanish: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/` +
  'SweatEquity_Oxford_Member+Participation+Waiver_Spanish.pdf';

export const oxfordSweatEquityWaver: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/content/dam/myuhc/pdfs/claim-forms/group/oxford/Other%20Forms/SweatEquity_Oxford_Member%20Participation%20Waiver.pdf`;

export const claimDeniedQuestion: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/custCareAskQuestionStep2aem.do?questionCategory=Claim`;

export const optumPlanBalances: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_OptumHealthBank_CAP`;

export const ancillaryBenefits: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits.html#anc-ben`;

export const benefitRiders: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits.html#avail-riders`;

export const connectDoctor: IResource = ({ connectUrl, id }) => `${connectUrl}/find/6?locale=${id}`;

export const connectSpecialist: IResource = ({ connectUrl, id }) => `${connectUrl}/find/7?locale=${id}`;

export const connectUrgent: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?sort=distance&specialtyCategory=372&locale=${id}`;

export const connectEmergent: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?sort=tierOneHospital&specialtyCategory=13&locale=${id}`;

export const connectVirtualVisits: IResource = ({ connectUrl, id }) => `${connectUrl}/virtual-visits/356?locale=${id}`;

export const connectOutpatient: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=13&locale=${id}`;

export const connectInpatient: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=13&locale=${id}`;

export const connectDurable: IResource = ({ connectUrl, id }) =>
  `${connectUrl}/search/facilities//page-1?coverageType=medical&sort=distance&specialtyCategory=15&locale=${id}`;

export const connectProvider: IResource = ({ connectUrl }) => `${connectUrl}/provider/`;

export const connectQueryLocale: IResource = ({ id }) => `?locale=${id}`;

export const connectQueryLocalePartner: IResource = ({ id }) => `?partnerId=true&locale=${id}`;

export const connectReferrals: IResource = ({ connectUrl }) => `${connectUrl}/referral/`;

export const connectEditPcp: IResource = ({ connectUrl, id }) => `${connectUrl}/edit-pcp?locale=${id}`;

export const connectSaved: IResource = ({ connectUrl, id }) => `${connectUrl}/saved-providers/gallery?locale=${id}`;

export const connectPlans: IResource = {
  default: ({ connectUrl, id }) => `${connectUrl}/plans/uhc?locale=${id}`,
  [LineOfBusiness.MR]: ({ connectUrl }) => `${connectUrl}/county-plan-selection/uhc.mnr/zip`,
};

export const connectHealthChecklist: IResource = ({ connectUrl, id }) => `${connectUrl}/health-checklist?locale=${id}`;

export const drugCoverage: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/benefits.html#drug-benefits`;

export const pharmacyLocator: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/pharmacy-locator/overview.html`;

export const drugCostEstimator: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/drug-lookup/overview.html`;

export const drugCostEstimatorOptum: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/sso/outbound?outboundTo=optumrx&deepLink=rxpricingtool`;

export const valueAddedServices: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/benefits/discounts-services.html`;

export const explanationOfBenefits: IResource = {
  default: '',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/eob.html`,
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/viewCSPDocCenterSearchLayoutAem.do`,
};

export const paymentsOverview: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/payments/overview.html`;

export const accountPreferences: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/account/preferences.html`;

export const accountProfile: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/account/profile.html`;

export const documentsOverview: IResource = {
  default: '',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/documents/overview.html`,
  [LineOfBusiness.CS]: ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/bcMemberHandbook.do`,
};

export const prescriptions: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/rx.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/pharmacy/overview.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/rx.html`,
};

export const viewDocs: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/viewCSPDocCenterSearchLayoutAem.do`;

export const orderMaterials: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/order-plan-materials.html`;

export const aarp: IResource = 'https://www.aarp.org';

export const healthRiskSurvey: IResource = ({ myUhcBaseUrl }) => {
  const myUhcBaseUrlObject = parse(myUhcBaseUrl);
  const baseUrl = `${myUhcBaseUrlObject.protocol}//${myUhcBaseUrlObject.host}`;
  return `${baseUrl}/wellness/health/uhcarticle/hwal-complete-your-health-assessment`;
};

export const memberHandbook: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/benefits-coverage.html?goto=member_handbook`;

export const appeals: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/appealsandgrievancesCA';

export const ksAppeal: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/KS-Appeal';

export const ksGrievance: IResource = 'https://www.personalhealthmessagecenter.com/public/forms/KS-Grievance';

export const recommendedCare: IResource = ({ myUhcBaseUrl, language }) =>
  `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/recommended-care.html`;

export const dentistSearch: IResource = 'http://www.uhcmedicaredentistsearch.com';

export const visionSearch: IResource = 'https://www.myuhcvision.com/wf';

export const mentalHealthSearch: IResource = ({ language }) =>
  `https://provider.liveandworkwell.com/content/laww/3659/${language}/spa.html#/provider-home`;

export const optumPharmacy: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_PS&deepLink=RP`;

export const memberServicesPhone: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/easyconnectpromptlayoutaem.do`;

export const coordinationBenefits: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/cobSummaryLayoutaem.do`;

export const uhcCommunityPlan: IResource = 'https://www.uhccommunityplan.com/';

export const revenueAdvantage: IResource = 'https://usapayx.com/';

export const exchangePremiumPayments: IResource = ({ myUhcLegacyBaseUrl }) =>
  `${myUhcLegacyBaseUrl}/member/ssoOutBound.do?partner=sso_SSOPartner_Cobra_HealthInsurancePremium&csId=15067`;

export const preLoginCompanyInfo: IResource = {
  default: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/preLoginCompanyInfoLayout.do?psnName=link.nav.footer.companyinfo.prelogin&requestor=/footerPreLogin`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reference/about-us.html`,
};

export const preLoginExternalUri: IResource = {
  default: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/preLoginExternalURLLayout.do?psnName=link.footer.legalentity&requestor=/footerPreLogin`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reference/disclaimers.html`,
};

export const preLoginPrivacyLayout: IResource = {
  default: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/preLoginPrivacyLayout.do?psnName=link.nav.footer.privacy.prelogin&requestor=/footerPreLogin`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reference/privacy-policy.html`,
};

export const preLoginLegalLayout: IResource = {
  default: ({ myUhcLegacyBaseUrl }) =>
    `${myUhcLegacyBaseUrl}/member/preLoginLegalLayout.do?psnName=link.nav.footer.legal.prelogin&requestor=/footerPreLogin`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reference/terms-of-use.html`,
};

export const nonDiscriminationEnglish: IResource = {
  default: 'https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) =>
    `${myUhcBaseUrl}/content/dam/UCP/Individual/Non-Discrimination_Form_English.pdf`,
};

export const nonDiscriminationSpanish: IResource = {
  default: 'https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices#spa',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) =>
    `${myUhcBaseUrl}/content/dam/UCP/Individual/Non-Discrimination_Form_Spanish.pdf`,
};

export const nonDiscriminationChinese: IResource = {
  default: 'https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices#Chi',
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) =>
    `${myUhcBaseUrl}/content/dam/UCP/Individual/Non-Discrimination_Form_Chinese.pdf`,
};

export const accessibility: IResource = 'https://www.uhc.com/legal/accessibility';

export const providerDataInfo: IResource = 'https://www.uhc.com/legal/provider';

export const legalEntities: IResource = 'https://www.uhc.com/legal/legal-entities';

export const messages: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/message-center.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/contact-us/overview.html`,
};

export const facebook: IResource = 'https://www.facebook.com/UnitedHealthcareCommunityPlan';

export const legalNotices: IResource = {
  default: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/reference/disclaimers.html`,
  [LineOfBusiness.CS]: 'https://www.uhc.com/legal',
};

export const secureClaimsAccount: IResource = {
  default: ({ myUhcBaseUrl, language }) => `${myUhcBaseUrl}/content/myuhc/${language}/secure/claims-account.html`,
  [LineOfBusiness.MR]: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/claims.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/claims-account/claims-summary.html`,
};

export const introVideo: IResource = ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}/member/onBoardingVideo.do`;

export const health: IResource = {
  default: ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/health-and-wellness.html`,
  [LineOfBusiness.CS]: ({ myUhcBaseUrl, language }) =>
    `${myUhcBaseUrl}/content/myuhc/${language}/secure/communityplan/health-wellness.html`,
};

export const premiumPayments: IResource = ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/premium-payments.html`;

export const dentalCostCalculator: IResource = ({ myUhcBaseUrl }) =>
  `${myUhcBaseUrl}/member/dentalCostCalculatoraem.do`;
