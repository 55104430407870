import { IResponse } from '../api.interfaces';

export enum CampaignCtaType {
  CustomEvent = 'custom_event',
  Phone = 'phone',
  Url = 'url',
}

export enum CampaignPlacementType {
  ActivateInLine = 'activate_in_line', // This is currently only ever used for Fitbit campaigns.
  ArcadeCarousel = 'arcade_carousel',
  ArcadeDashboardQuickLinks = 'arcade_dashboard_placements_quick_links',
  ArcadeOnboardingPaperless = 'arcade_onboarding_paperless',
  ArcadeRecommendationRto = 'arcade_recommendation_rto',
  ArcadeResourcesGeneralPromo = 'arcade_resources_general_promo',
  ArcadeResourcesPrimaryWellness = 'arcade_resources_primary_wellness',
  ArcadeResourcesPrograms = 'arcade_resources_programs',
  ArcadeResourcesSecondaryWellness = 'arcade_resources_secondary_wellness',
  ArcadeResourcesSection1Link = 'arcade_resources_section_1_link',
  ArcadeResourcesSection2Link = 'arcade_resources_section_2_link',
  ArcadeResourcesText = 'arcade_resources_text',
}

export const AllProgramsPageCampaigns = 'arcade_resources*';

export enum AwardType {
  ClientFulfilled = 'CLIENT_FULFILLED',
  ClientFulfilledGiftCard = 'CLIENT_FULFILLED_GIF',
  ClientFulfilledHia = 'CLIENT_FULFILLED_HIA',
  ClientFulfilledHra = 'CLIENT_FULFILLED_HRA',
  ClientFulfilledHsa = 'CLIENT_FULFILLED_HSA',
  ClientFulfilledReward = 'CLIENT_FULFILLED_REW',
  DeductibleCredit = 'DEDUCTIBLE_CREDIT',
  EV1_HIA = 'CLIENT_EV1_HIA',
  EV1_HRA = 'CLIENT_EV1_HRA',
  EV1_HSA = 'CLIENT_EV1_HSA',
  Hia = 'HIA',
  Hra = 'HRA',
  Hsa = 'HSA',
  OPTUM_D_GIFTCARD = 'OPTUM_D_GIFTCARD',
  OPTUM_DP_GIFTCARD = 'OPTUM_DP_GIFTCARD',
  OPTUM_P_GIFTCARD = 'OPTUM_P_GIFTCARD',
  RallyCoins = 'RALLY_COINS',
  RallyGiftCard = 'RALLY_GIFTCARD',
  Track = 'TRACK',
  UHC_D_GIFTCARD = 'UHC_D_GIFTCARD',
  UHC_DP_GIFTCARD = 'UHC_DP_GIFTCARD',
  UHC_P_GIFTCARD = 'UHC_P_GIFTCARD',
  WagBalanceReward = 'WAG_BALANCE_REWARD',
  Wire = 'WIRE',
}

export enum AwardMediaType {
  CanadianDollars = 'CANADIAN_DOLLARS',
  Coins = 'COINS',
  Dollars = 'DOLLARS',
  Percent = 'PERCENT',
  Points = 'POINTS',
  Track = 'TRACK',
}

export enum HelpFaqType {
  JPMC = 'JPMC',
}

export interface IColorPair {
  foreground: string;
  background: string;
}

export interface ICampaignCta {
  ctaType: CampaignCtaType;
  ctaText: string;
  ctaValue: string;
}

export enum OutcomeType {
  GatekeeperActivity = 'gatekeeper_activity',
  NoIncentive = 'no_incentive',
  RewardCompleted = 'reward_completed',
  RewardOpen = 'reward_open',
  Undetermined = 'undetermined',
}

export interface ICampaignMetadata {
  activityID: string;
  outcome: OutcomeType;
  rewardValue: string;
}

export interface ICampaign {
  campaignId: string;
  imageUrl?: string;
  logo?: string;
  headline?: string;
  body?: string;
  colorPair: IColorPair;
  cta: ICampaignCta;
  priority: number;
  placementType: CampaignPlacementType;
  metadata?: ICampaignMetadata;
}

export interface ICampaigns {
  placements: ICampaignPlacements;
}

export interface ICampaignPlacements {
  [campaignPlacementType: string]: ICampaign[];
}

export interface ICampaignResponse extends IResponse<ICampaigns> {}

export interface IPlanInfo {
  planId: string;
  planName: string;
  planTitle: string;
  planStartDate: number;
  planEndDate: number;
  maxCap: number;
  numberOfIncentives: number;
  earnedAmount: number;
  allocationType: string;
  awardMedia: string;
  awardType: string;
  progressMessage: string;
}

export interface IAdditionalProgressInfo {
  primaryBonusAmount: number;
  primaryGrandTotal: number;
  progressMeterPrimary: string;
  progressMeterPrimaryBonus: string;
  progressMeterPrimaryTotal: string;
}

export interface IIncentives {
  planInfo: IPlanInfo;
  additionalProgressInfo?: IAdditionalProgressInfo;
}

export interface IIncentivesResponse extends IResponse<IIncentives> {}

export interface IClientConfigResponse extends IResponse<IClientConfig> {}

export interface IClientConfig {
  clientCustomContentId: string;
  clientMetaData: IClientMetaData;
  suppressions: IContentSuppressions;
  customLabels: ICustomLabels;
  customMessaging: ICustomMessaging;
  contentOverrides: IContentOverrides;
}
export interface IClientMetaData {
  clientConfigId: string;
  clientId: string;
  policyNumber?: string;
  pvrcCodes?: string;
}
export interface IContentSuppressions {
  accumulatorSuppression?: IAccumulatorSuppressionNetwork;
  dependentAccumulatorsSuppression: boolean;
  financialAccountSuppression: string[];
  idCardSuppression: boolean;
  onlineMedicalAppeals: boolean;
  overrideShowClaimForm?: IOverrideClaimFormSuppression;
  payNowSuppression: boolean;
  rallypayRolloutEnabled: boolean;
  showTier1OnDashboard: boolean;
  showMERPLabel: boolean;
  suppressAllFinancialAccountLinks: string[];
  suppressClaimDocuments: boolean;
  suppressClaimStatements: boolean;
  suppressFinancialAccountLinks: IFinancialAccountLinkSuppression;
  suppressOnlineFSAHRASubmission: boolean;
}
export interface ICustomLabels {
  customAccumulatorLabels: ICustomAccumulatorLabelNetwork;
  customFinancialAccountLabels: ICustomFinancialAccountLabelAccount;
  customFormsLabels: ICustomFormsLabel[];
  customNetworkLabels: ICustomNetworkLabel;
}
export interface ICustomMessaging {
  allClaimsCustomMessage?: IAllClaimsCustomMessage;
  findCareMessage?: string;
  pharmacyHubCustomMessage?: IPharmacyHubCustomMessage;
  customMessagePlanBalances?: string;
  memberServicesMessage?: string;
  memberServicesPhone?: string;
  nurseLineMessage?: INurseLineMessage;
  submitClaimCustomMessage?: string;
}
export interface IContentOverrides {
  customFaq: ICustomFaq;
  planBalanceFAQ: ICustomFaq;
  planBalanceHowItWorks: IPlanBalanceHowItWorks;
  customAccumulatorDefinition: ICustomAccumulatorDefinitionNetwork;
  customFinancialAccountLink: ICustomFinancialAccountLinks;
  customGlossaryDefinitions: ICustomGlossaryDefinitions;
  helpFAQTypes: HelpFaqType[];
  customAllClaimsFAQ: IFaq[];
  customPdfClaimForms: ICustomPdfClaimForms;
}

export interface ICustomGlossaryDefinitions {
  claimAmountBilled: IClaimAmountBilled;
  claimPlanPaid: IClaimPlanPaid;
  claimYouMayOwe: IClaimYouMayOwe;
  claimYouPaid: IClaimYouPaid;
}

export interface ICustomFinancialAccountLinks {
  [accountType: string]: {}[];
}

export interface ICustomAccumulatorDefinitionNetwork {
  inNetwork?: ICustomAccumulatorDefinition;
  outOfNetwork?: ICustomAccumulatorDefinition;
  tier1?: ICustomAccumulatorDefinition;
}
export interface ICustomAccumulatorDefinition {
  INDIVIDUAL_DEDUCTIBLE?: string;
  FAMILY_DEDUCTIBLE?: string;
  INDIVIDUAL_OOP?: string;
  FAMILY_OOP?: string;
  INDIVIDUAL_ANNUAL_MAX?: string;
  INDIVIDUAL_LIFETIME_MAX?: string;
  PLAN_LIFETIME_MAX?: string;
}
export interface ICustomAccumulatorLabelNetwork {
  inNetwork?: ICustomAccumulatorLabel;
  outOfNetwork?: ICustomAccumulatorLabel;
  tier1?: ICustomAccumulatorLabel;
}

export interface ICustomAccumulatorLabel {
  INDIVIDUAL_DEDUCTIBLE?: string;
  FAMILY_DEDUCTIBLE?: string;
  INDIVIDUAL_OOP?: string;
  FAMILY_OOP?: string;
  INDIVIDUAL_ANNUAL_MAX?: string;
  INDIVIDUAL_LIFETIME_MAX?: string;
  PLAN_LIFETIME_MAX?: string;
}

export interface IAccumulatorSuppressionNetwork {
  inNetwork?: string[];
  outOfNetwork?: string[];
  tier1?: string[];
}
export interface IFinancialAccountLinkSuppression {
  // the string value in the array is the actual string link to suppress EX "How It Works!"
  // might be a value in the string[]
  [accountType: string]: string[];
}
export interface ICustomFaq {
  [accountType: string]: IFaq[];
}

export interface IFaq {
  question: string;
  answer: string;
}

export interface ICustomPdfClaimForms {
  [customPdfClaimFormType: string]: string;
}

export interface ICustomFinancialAccountLabelAccount {
  [accountType: string]: ICustomFinancialAccountLabel;
}

export interface ICustomFinancialAccountLabel {
  fullName: string;
  abbreviation: string;
}
export interface IPharmacyHubCustomMessage {
  header?: string;
  body?: string;
}
export interface IAllClaimsCustomMessage {
  header?: string;
  body?: string;
}
export interface IPlanBalanceHowItWorks {
  [accountType: string]: string[];
}
export interface INurseLineMessage {
  header?: string;
  body?: string;
}
export interface IClaimPlanPaid {
  header?: string;
  body?: string;
}
export interface IClaimYouMayOwe {
  header?: string;
  body?: string;
}
export interface IClaimYouPaid {
  header?: string;
  body?: string;
}
export interface IClaimAmountBilled {
  header?: string;
  body?: string;
}

export interface ICustomNetworkLabel {
  inNetwork?: string;
  outOfNetwork?: string;
  tier1?: string;
}

export enum ClaimFormType {
  MEDICAL_ONLINE = 'MEDICAL_ONLINE',
  MEDICAL_PDF = 'MEDICAL_PDF',
}

export enum ClaimFormDisplay {
  show = 'show',
  hide = 'hide',
}

export interface IOverrideClaimFormSuppression {
  [ClaimFormType.MEDICAL_PDF]: ClaimFormDisplay;
  [ClaimFormType.MEDICAL_ONLINE]: ClaimFormDisplay;
}

export enum RecommendationStatus {
  MaybeLater = 'MaybeLater',
  NoInterest = 'NoInterest',
  TellMeMore = 'TellMeMore',
}

export interface IRecommendationCampaign extends ICampaign {
  offerCode: string;
  offerKey: string;
}

export interface IRecommendationsResponse extends IResponse<{ realTimeOffers: IRecommendationCampaign[] }> {}

export interface IRealTimeOfferCountResponse extends IResponse<{ leadPromotionCount: number }> {}

export interface IRecommendationPostData {
  offerKey: string;
  offerStatus: RecommendationStatus;
}

export const ExpatCustomPolicies = {
  Ford: ['0911754'],
  InternationalBankOfKorea: ['914234', '914235'],
};

export interface ICustomFormsLabel {
  label: string;
  url: string;
}

export interface IAlertsResponse extends IResponse<IAlerts> {}

export enum AlertCodes {
  EIOB = 'EIOB',
}

export interface IAlert {
  code: AlertCodes;
}

export interface IAlerts {
  alerts: IAlert[];
  isMemberFound: boolean;
}
