import eiAccountSummaryExplanationTemplate from 'views/modals/ei-account-summary-explanation.html';
import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import { IBenefitExplanation } from '../../../api/plans/plans.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import {
  AccountSummaryService,
  IAccountSummaryService,
} from '../../dashboard/account-summary/account-summary-explanation.service';
import { getIsEmpire } from '../../../util/population/population';
import { IPopulationService } from '../../../util/population/population.service';

export class EIAccountSummaryExplanationController implements ng.IComponentController {
  public customExplanation: IBenefitExplanation[];
  public deductibleLinkLabel: string;
  public isEmpire: boolean;
  public oopMaxLinkLabel: string;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private accountSummaryService: IAccountSummaryService,
    private populationService: IPopulationService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
  }

  public $onInit(): void {
    Observable.zip(
      this.accountSummaryService.getAccountSummaryExplanations(),
      this.accountSummaryService.getHeaderWithLabelsTranslated(),
    ).subscribe(([explanations, labelReplacements]) => {
      this.customExplanation = explanations;
      this.deductibleLinkLabel =
        labelReplacements[AccountSummaryService.DEDUCTIBLE_HEADER_KEY].customLabel || undefined;
      this.oopMaxLinkLabel = labelReplacements[AccountSummaryService.OOP_MAX_HEADER_KEY].customLabel || undefined;
    }, console.warn);
    this.isEmpire = getIsEmpire(this.populationService.getPopulation());
  }
}

export class EIAccountSummaryExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = EIAccountSummaryExplanationController;
    this.templateUrl = eiAccountSummaryExplanationTemplate;
  }
}
