import angular from 'angular';
import { Observable, Subscription } from 'rxjs/Rx';
import { getLocale } from 'scripts/util/locale/locale';
import { sendCampaignsLoadedEvent } from 'scripts/util/tracking/adobe-analytics';
import { getLink } from 'scripts/util/uri/uri';
import recommendationsTemplate from 'views/states/recommendations.html';
import {
  ICampaign,
  IRecommendationCampaign,
  IRecommendationPostData,
  RecommendationStatus,
} from '../../api/targeting/targeting.interfaces';
import { ITargetingService, TargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { ILocaleService } from '../../util/locale/locale.service';

export interface IRecommendation extends IRecommendationCampaign {
  dismissed?: boolean;
  showErrorMessage?: boolean;
  dismissRequest?: Subscription;
}

export interface IStoredRecommendations {
  localeId: string;
  recommendations: IRecommendation[];
  numActive: number;
}

export const RecommendationsStorageKey = 'arcade.recommendations';

export class RecommendationsController implements ng.IComponentController {
  public recommendations: IRecommendation[];
  public activeGrid: IRecommendation[][];
  public dismissedGrid: IRecommendation[][];
  public numDismissed = 0;
  public showBackButton: boolean;
  public withIncentives: boolean;
  public request: Observable<IRecommendation[]>;
  private rallyId: string;
  private localeSubscription: Subscription;

  constructor(
    private $filter: ng.IFilterService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private localeService: ILocaleService,
    public targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';

    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.RECOMMENDATIONS);

    this.showBackButton = this.$window.history.length > 1;
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .do(rsp => {
        this.rallyId = rsp.data.rallyId;
      })
      .subscribe();
    this.setRecommendations();
    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.setRecommendations();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getLink(link: string): string {
    return getLink(link);
  }

  public goBack(): void {
    this.$window.history.back();
  }

  public toggleDismissed(rec: IRecommendation): void {
    // keep at original state until the request is successful
    rec.dismissed = !rec.dismissed;
    this.checkboxClick(rec);
  }

  public ctaClick(rec: IRecommendation): void {
    const postData: IRecommendationPostData = {
      offerKey: rec.offerKey,
      offerStatus: RecommendationStatus.TellMeMore,
    };
    this.targetingService.postRecommendationStateChange(this.rallyId, postData).subscribe();
  }

  public checkboxClick(rec: IRecommendation): void {
    const newStatus = rec.dismissed ? RecommendationStatus.MaybeLater : RecommendationStatus.NoInterest;
    const postData: IRecommendationPostData = {
      offerKey: rec.offerKey,
      offerStatus: newStatus,
    };
    rec.dismissRequest = this.targetingService.postRecommendationStateChange(this.rallyId, postData).subscribe(
      () => {
        rec.dismissed = !rec.dismissed;
        rec.dismissed ? this.numDismissed++ : this.numDismissed--;
        rec.dismissRequest = undefined;
        this.saveState();
        this.setGrids();
      },
      err => {
        console.warn(err);
        rec.showErrorMessage = true;
        this.$timeout(() => {
          rec.dismissRequest = undefined;
          rec.showErrorMessage = false;
        }, 5000);
      },
    );
  }

  public isIncentiveValid(promo: ICampaign): boolean {
    return TargetingService.isIncentiveValid(promo);
  }

  private setRecommendations(): void {
    const storedRecs = JSON.parse(this.$window.sessionStorage.getItem(RecommendationsStorageKey));
    const recRequest$ = this.userService
      .getHeartbeat()
      .flatMap(rsp => this.targetingService.getRecommendations(rsp.data.rallyId))
      .filter(({ data }) => !!(data && data.realTimeOffers))
      .map(({ data: { realTimeOffers } }) => realTimeOffers);

    this.request = Observable.if(
      () => storedRecs && storedRecs.localeId === getLocale().id,
      Observable.of(storedRecs && storedRecs.recommendations),
      recRequest$,
    );
    this.request.subscribe(recommendations => {
      if (recommendations) {
        sendCampaignsLoadedEvent(undefined, undefined, recommendations);
      }
      this.recommendations = recommendations || [];
      // just in case the state was saved previously with any rec in the error state
      this.recommendations.forEach(rec => {
        rec.dismissRequest = undefined;
        rec.showErrorMessage = false;
      });
      this.numDismissed = this.recommendations.reduce((total, rec) => {
        return rec.dismissed ? total + 1 : total;
      }, 0);
      this.withIncentives = this.recommendations.some(
        r => !!(r.metadata && r.metadata.rewardValue) && this.isIncentiveValid(r),
      );
      this.saveState();
      this.setGrids();
    }, console.warn);
  }

  private setGrids(): void {
    const activePrograms = this.recommendations.filter(p => !p.dismissed);
    const dismissedPrograms = this.recommendations.filter(p => p.dismissed);
    this.activeGrid = this.createGrid(activePrograms);
    this.dismissedGrid = this.createGrid(dismissedPrograms);
  }

  private createGrid(recommendations: IRecommendation[]): any {
    const grid = [];
    for (let i = 0; i < recommendations.length / 3; i++) {
      grid.push(recommendations.slice(3 * i, 3 * (i + 1)));
    }
    return grid;
  }

  private saveState(): void {
    const storedRecommendations: IStoredRecommendations = {
      localeId: getLocale().id,
      recommendations: this.recommendations,
      numActive: this.recommendations.length - this.numDismissed,
    };
    this.$window.sessionStorage.setItem(RecommendationsStorageKey, JSON.stringify(storedRecommendations));
  }
}

export class RecommendationsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = RecommendationsController;
    this.templateUrl = recommendationsTemplate;
  }
}
