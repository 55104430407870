import React, { FunctionComponent } from 'react';
import { DefaultArcObject } from 'd3-shape';
import * as d3 from 'd3-shape';

export interface IArcProps {
  amount: number;
}

export interface IDonutProps {
  data: IArcProps[];
  outerDiameter?: number;
  innerDiameter?: number;
}
const DEFAULT_OUTER_DIAMETER = 250;
const DEFAULT_INNER_DIAMETER = 170;

export const Donut: FunctionComponent<IDonutProps> = props => {
  const { children, data, outerDiameter = DEFAULT_OUTER_DIAMETER, innerDiameter = DEFAULT_INNER_DIAMETER } = props;

  const viewBox = [0, 0, outerDiameter, outerDiameter].join(' ');
  const svgStyle = {
    height: outerDiameter,
    width: outerDiameter,
  };

  const innerRadius = innerDiameter / 2;
  const outerRadius = outerDiameter / 2;

  const arcCount = data.filter(arc => arc.amount).length;
  const pie =
    arcCount > 1
      ? d3.pie().sortValues(null)(data.map(arc => arc.amount))
      : // We want a full circle for a single slice or empty set
        d3.pie()([{ amount: 100 }].map(arc => arc.amount));
  return (
    <div className="donut">
      <div className="donut-content">{children}</div>
      <svg className="donut-svg" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} style={svgStyle}>
        {pie.map((d, i) => {
          const arcGen = d3.arc();
          const arcObj: DefaultArcObject = {
            startAngle: d.startAngle,
            endAngle: d.endAngle,
            innerRadius: innerRadius,
            outerRadius: outerRadius,
          };
          return (
            <path
              className={arcCount > 0 ? 'donut-segment' : 'donut-zero'}
              transform={`translate(${outerRadius},${outerRadius})`}
              id={'ds-' + i}
              key={i}
              d={arcGen(arcObj)}
            />
          );
        })}
      </svg>
    </div>
  );
};
