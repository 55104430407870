import React, { FunctionComponent } from 'react';
import IconCircleCheck from 'images/icons/icon-circle-check-4.svg';
import IconCircleDoctor from 'images/icons/icon-circle-doctor.svg';
import IconCircleInfo from 'images/icons/icon-circle-info.svg';
import IconInfo from 'images/icons/icon-info.svg';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { PcpTileStatusText, ISelectedUserPrimaryCareInfo } from './pcp-strip';
import { PcpStripBaseTile } from './pcp-strip-base-tile';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getResource } from 'scripts/util/resource/resource';
import { connectProvider, connectQueryLocalePartner, connectEditPcp } from 'scripts/util/resource/resource.constants';
import { withTrackDataUponRender } from 'scripts/hoc/with-track-data-upon-render/with-track-data-upon-render';

interface IPcpStripPrimaryCareProviderTileProps {
  isCS: boolean;
  isDsnp: boolean;
  isOxford: boolean;
  isTermedMedical: boolean;
  population: IPopulation;
  primaryCareInfo: ISelectedUserPrimaryCareInfo;
  statusText: PcpTileStatusText;
}

const ActivePcpLinkWithClickTracking = withClickTracking(Link, 'active-pcp');
const ChangeInProgressLinkWithClickTracking = withClickTracking(Link, 'change-in-progress');
const ChangePcpLinkWithClickTracking = withClickTracking(Link, 'change-pcp');

const PcpTileChangeInProgressOrChangeContent: FunctionComponent<IPcpStripPrimaryCareProviderTileProps> = props => {
  const { isCS, isDsnp, isOxford, isTermedMedical, population, primaryCareInfo, statusText } = props;
  const { t } = useTranslation(Dictionary.PCP);
  const activePcpDeepLink = `${getResource(connectProvider, population)}${primaryCareInfo.activeDocId}${getResource(
    connectQueryLocalePartner,
    population,
  )}`;
  const editPcpDeeplink = `${getResource(connectEditPcp, population)}`;
  const canChangePcpOnline = !(primaryCareInfo.lockedIn && (isOxford || isCS));
  const changePcpLinkTo = canChangePcpOnline
    ? { pathname: '/internal-redirect', search: `?deepLink=${editPcpDeeplink}` }
    : '/modal/pcp-change-unavailable';
  const showChangeInProgressOrChangeLink = !isDsnp && !isTermedMedical;

  return (
    <>
      <IconCircleDoctor className="type-icon" aria-hidden="true" />
      <div className="text">
        {isTermedMedical ? (
          <span className="provider-name" data-testid="pcp-tile-provider-name">
            {primaryCareInfo.activeDocName}
          </span>
        ) : (
          <ActivePcpLinkWithClickTracking
            className="provider-name"
            data-testid="pcp-tile-provider-name-link"
            to={{ pathname: '/internal-redirect', search: `?deepLink=${activePcpDeepLink}` }}
          >
            {primaryCareInfo.activeDocName}
          </ActivePcpLinkWithClickTracking>
        )}
        {primaryCareInfo.phoneNumber && (
          <div className="phone-num" data-testid="pcp-tile-phone-number">
            {primaryCareInfo.phoneNumber}
          </div>
        )}
        {showChangeInProgressOrChangeLink &&
          (statusText === PcpTileStatusText.ChangeInProgress ? (
            <ChangeInProgressLinkWithClickTracking
              className="change-pcp"
              data-testid="pcp-tile-change-in-progress-link"
              to="/modal/pcp-change-in-progress"
            >
              <IconInfo className="icon-info" aria-hidden="true" />
              {t(statusText)}
            </ChangeInProgressLinkWithClickTracking>
          ) : (
            <ChangePcpLinkWithClickTracking
              className="change-pcp"
              data-testid="pcp-tile-change-pcp-link"
              to={changePcpLinkTo}
            >
              {t('CHANGE_PCP')}
            </ChangePcpLinkWithClickTracking>
          ))}
      </div>
    </>
  );
};

const AssignmentInProgressLinkWithClickTracking = withClickTracking(Link, 'assignment-in-progress');
const PcpTileAssignmentInProgressContent: FunctionComponent<IPcpStripPrimaryCareProviderTileProps> = props => {
  const { statusText } = props;
  const { t } = useTranslation(Dictionary.PCP);
  return (
    <>
      <IconCircleInfo className="type-icon" aria-hidden="true" />
      <AssignmentInProgressLinkWithClickTracking
        className="text centered"
        data-testid="pcp-tile-assignment-in-progress-link"
        to="/modal/pcp-change-in-progress"
      >
        {t(statusText)}
      </AssignmentInProgressLinkWithClickTracking>
    </>
  );
};

const SelectPcpLinkWithClickTracking = withClickTracking(Link, 'select-pcp');
const PcpTileAssignContent: FunctionComponent<IPcpStripPrimaryCareProviderTileProps> = props => {
  const { isCS, isOxford, population, primaryCareInfo } = props;
  const { t } = useTranslation(Dictionary.PCP);
  const editPcpDeeplink = `${getResource(connectEditPcp, population)}`;
  const canChangePcpOnline = !(primaryCareInfo.lockedIn && (isOxford || isCS));
  const changePcpLinkTo = canChangePcpOnline
    ? { pathname: '/internal-redirect', search: `?deepLink=${editPcpDeeplink}` }
    : '/modal/pcp-change-unavailable';
  return (
    <>
      <IconCircleCheck className="type-icon" aria-hidden="true" />
      <SelectPcpLinkWithClickTracking
        className="text centered"
        data-testid="pcp-tile-select-pcp-link"
        to={changePcpLinkTo}
      >
        {t('SELECT_PCP')}
      </SelectPcpLinkWithClickTracking>
    </>
  );
};

const PcpStripBaseTileWithTrackDataUponRender = withTrackDataUponRender(PcpStripBaseTile);
export const PcpStripPrimaryCareProviderTile: FunctionComponent<IPcpStripPrimaryCareProviderTileProps> = props => {
  const { statusText } = props;

  return (
    <PcpStripBaseTileWithTrackDataUponRender
      className="care-container"
      providerTypeTranslationKey="PCP"
      trackLabel="PCP"
      trackData={{ pcpState: statusText }}
    >
      {(statusText === PcpTileStatusText.ChangeInProgress || statusText === PcpTileStatusText.Change) && (
        <PcpTileChangeInProgressOrChangeContent {...props} />
      )}
      {statusText === PcpTileStatusText.AssignmentInProgress && <PcpTileAssignmentInProgressContent {...props} />}
      {statusText === PcpTileStatusText.Assign && <PcpTileAssignContent {...props} />}
    </PcpStripBaseTileWithTrackDataUponRender>
  );
};
