import { LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { ArcadeAuthType, IConfig } from '../constants/environment.interfaces';
import { IPopulation } from '../population/population.interfaces';
import { getResource } from '../resource/resource';
import { IAlternativeUrls, IBaseUrls } from './uri.interfaces';

function readAlternativeUrls(config: IConfig): IAlternativeUrls[] {
  return config.ARCADE_WEB_ALTERNATIVE_BASE_URLS.split(';').map(
    (urlPair: string): IAlternativeUrls => {
      const urls = urlPair.split(',');
      return {
        ARCADE_WEB_BASE_URL: urls[0].trim(),
        ARCADE_WEB_BASE_API_URL: urls[1].trim(),
      };
    },
  );
}

export function constructParams(queryParams: object): string {
  let queryString = '';
  for (const param in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, param) && queryParams[param]) {
      queryString ? (queryString += '&') : (queryString += '?');
      if (queryParams[param] instanceof Array) {
        queryString += queryParams[param].map(val => `${param}=${encodeURIComponent(val)}`).join('&');
      } else {
        queryString += `${param}=${encodeURIComponent(queryParams[param])}`;
      }
    }
  }
  return queryString;
}

function constructParamsNoEncoding(queryParams: object): string {
  let queryString = '';
  for (const param in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, param) && queryParams[param]) {
      queryString ? (queryString += '&') : (queryString += '?');
      if (queryParams[param] instanceof Array) {
        queryString += queryParams[param].map(val => `${param}=${val}`).join('&');
      } else {
        queryString += `${param}=${queryParams[param]}`;
      }
    }
  }
  return queryString;
}

function sanitizeLink(link: string): string {
  return link
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '');
}

export function parse(href: string): URL {
  try {
    return new URL(href, window.location.href);
  } catch (err) {
    console.error('Could not parse URL:', href, err);
    // defaulting to the current location, the same as if an empty string was passed in
    return new URL(window.location.href);
  }
}

export function constructUri(
  uri: string,
  api: string,
  version: string,
  method: string,
  addUriParams?: object,
  addQueryParams?: object,
  queryParameterBuilder: (queryParams: object) => string = constructParams,
): string {
  for (const key in addUriParams) {
    if (Object.prototype.hasOwnProperty.call(addUriParams, key)) {
      addUriParams[key] = encodeURIComponent(addUriParams[key]);
    }
  }

  const params = {
    api,
    version,
    method,
    ...addUriParams,
  };

  if ((uri.match(/\[/g) || []).length === Object.keys(params).length && arguments.length >= 4) {
    for (const param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        uri = uri.replace('[' + param + ']', params[param]);
      }
    }
    return (uri += addQueryParams ? queryParameterBuilder(addQueryParams) : '');
  } else {
    throw new Error('Not enough parameters supplied to construct uri.');
  }
}

export function getBaseUrls(config: IConfig = CONFIG): IBaseUrls {
  const locationUri = parse(window.location.href).host;
  let webUrl;
  let apiUrl;
  if (locationUri === parse(config.ARCADE_WEB_BASE_URL).host) {
    webUrl = config.ARCADE_WEB_BASE_URL;
    apiUrl = config.ARCADE_WEB_BASE_API_URL;
  } else if (config.ARCADE_WEB_ALTERNATIVE_BASE_URLS) {
    for (const ALTERNATIVE_URL of readAlternativeUrls(config)) {
      if (locationUri === parse(ALTERNATIVE_URL.ARCADE_WEB_BASE_URL).host) {
        webUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_URL;
        apiUrl = ALTERNATIVE_URL.ARCADE_WEB_BASE_API_URL;
        break;
      }
    }
  }
  return { api: apiUrl, web: webUrl };
}

const baseUrls = getBaseUrls(CONFIG);
const baseApiUri = (baseUrls.api || '') + '/rest/[api]/v[version]/[method]';
const baseAuthUri = baseApiUri + '/u/[rallyId]';

export const activateUris = {
  stepsV7: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'stepplans',
      { rallyId },
      { allowCreation: 'false' },
    );
  },
  stepsPostPlanV7: (rallyId: string, planKey: string, planVersion: number) => {
    const stepsUri = baseAuthUri + '/plan/[planKey]/planVersion/[planVersion]';
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'stepplans',
      { rallyId, planKey, planVersion },
      { allowCreation: 'false' },
    );
  },
  stepsPostSwitch: (rallyId: string, planKey: string, planVersion: number) => {
    const stepsUri = baseAuthUri + '/plan/[planKey]/planVersion/[planVersion]';
    return constructUri(
      stepsUri,
      'edge-dashboard/activate-steps',
      '7',
      'dhp',
      { rallyId, planKey, planVersion },
      { allowCreation: 'false' },
    );
  },
  stepsSwitch: (rallyId: string) => {
    const stepsUri = baseAuthUri;
    return constructUri(stepsUri, 'edge-dashboard/activate-steps', '7', 'dhp', { rallyId });
  },
};

export const advantageUris = {
  productAuthList: (config: IConfig) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/v1/productauthlist`;
  },
  profile: (config: IConfig) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/profile/v2/user`;
  },
  uiConfig: (config: IConfig, locale: string) => {
    return `${config.ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL}/rest/advantage/v2/chimera/uiconfig?locale=${locale}`;
  },
};

export const claimUris = {
  alerts: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'alerts', { rallyId }, queryParams);
  },
  financial: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'financial', { rallyId }, queryParams);
  },
  financialDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/financial', { rallyId }, queryParams);
  },
  healthcare: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'healthcare', { rallyId }, queryParams);
  },
  healthcareV3: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '3', 'healthcare', { rallyId }, queryParams);
  },
  healthcareDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/healthcare', { rallyId }, queryParams);
  },
  manage: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '2', 'manage', { rallyId });
  },
  getNote: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'note', { rallyId }, queryParams);
  },
  postNote: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '2', 'note', { rallyId }, queryParams);
  },
  rallyPayClaimDetails: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'detail/rallypay', { rallyId }, queryParams);
  },
  summary: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'summary', { rallyId }, queryParams);
  },
  totals: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/claims', '1', 'totals', { rallyId }, queryParams);
  },
};

export const documentUris = {
  claimLetterPdf: (rallyId: string, documentId: string, queryParams: object) => {
    const claimLetterUri = baseAuthUri + '/id/[documentId]';
    return constructUri(
      claimLetterUri,
      'edge-dashboard/documents',
      '1',
      'claims/letter',
      { rallyId, documentId },
      queryParams,
    );
  },
  claimLettersList: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/documents', '1', 'claims/letter', { rallyId });
  },
  financialClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/type/[documentType]/key/[documentKey]';
    return constructUri(
      claimDocumentUri,
      'edge-dashboard/documents',
      '1',
      'claims/financial',
      { rallyId, documentType, documentKey },
      queryParams,
    );
  },
  healthcareClaimsForm: (rallyId: string, documentType: string, documentKey: string, queryParams: object) => {
    const claimDocumentUri = baseAuthUri + '/type/[documentType]/key/[documentKey]';
    return constructUri(
      claimDocumentUri,
      'edge-dashboard/documents',
      '1',
      'claims/healthcare',
      { rallyId, documentType, documentKey },
      queryParams,
    );
  },
  healthStatementList: (rallyId: string, queryParams?: object) => {
    const healthStatementUri = baseAuthUri + '/list';
    return constructUri(
      healthStatementUri,
      'edge-dashboard/documents',
      '1',
      'claims/healthStatement',
      { rallyId },
      queryParams,
    );
  },
  healthStatementPdf: (rallyId: string, documentId: string) => {
    const healthStatementUri = baseAuthUri + '/id/[documentId]';
    return constructUri(healthStatementUri, 'edge-dashboard/documents', '1', 'claims/healthStatement', {
      rallyId,
      documentId,
    });
  },
};

export const ledgerUris = {
  balance: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/ledger', '1', 'balance', { rallyId });
  },
};

export const planUris = {
  benefits: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'benefits', { rallyId }, queryParams);
  },
  familyBenefits: (rallyId: string, queryParams: object) => {
    const familyBenefitsUri = baseAuthUri + '/family';
    return constructUri(familyBenefitsUri, 'edge-dashboard/plans', '2', 'benefits', { rallyId }, queryParams);
  },
  accumulator: (rallyId: string, queryParams?: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/plans', '1', 'accumulator', { rallyId }, queryParams);
  },
  familyAccumulator: (rallyId: string, queryParams: object) => {
    const familyAccumulatorsUri = baseAuthUri + '/family';
    return constructUri(familyAccumulatorsUri, 'edge-dashboard/plans', '2', 'accumulator', { rallyId }, queryParams);
  },
  idCards: (rallyId: string, cardType: string, queryParams?: object) => {
    const idCardsUri = baseAuthUri + '/cardType/[cardType]';
    return constructUri(idCardsUri, 'edge-dashboard/plans', '2', 'idcards', { rallyId, cardType }, queryParams);
  },
};

export const profileUris = {
  autoPaymentPreferences: (rallyId: string) => {
    const autoPaymentUri = baseAuthUri + '/autosubmission';
    return constructUri(autoPaymentUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  claimsPreferences: (rallyId: string) => {
    const claimsUri = baseAuthUri + '/claims';
    return constructUri(claimsUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  decryptProvider: (rallyId: string, queryParams: object) => {
    const decryptProviderUri = baseAuthUri + '/decrypt';
    return constructUri(decryptProviderUri, 'edge-dashboard/profile', '1', 'redirect', { rallyId }, queryParams);
  },
  eftPreferences: (rallyId: string) => {
    const eftUri = baseAuthUri + '/eft';
    return constructUri(eftUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  healthcareCoverages: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/healthcarecoverages',
      'edge-dashboard/profile',
      '1',
      'profile',
      { rallyId },
      queryParams,
    );
  },
  instamedMobileToken: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/mobiletoken',
      'edge-dashboard/profile',
      '1',
      'instamed',
      { rallyId },
      queryParams,
    );
  },
  paperlessPreferences: (rallyId: string) => {
    const paperlessUri = baseAuthUri + '/paperless';
    return constructUri(paperlessUri, 'edge-dashboard/profile', '1', 'preferences', { rallyId });
  },
  fpcPrimaryCare: (
    rallyId: string,
    queryParams?: { lineOfBusiness: LineOfBusiness; membershipCategory: MembershipCategory },
  ) => {
    const primaryCareUri = baseAuthUri + '/primarycare/fpc';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '3', 'doctor', { rallyId }, queryParams);
  },
  primaryCare: (rallyId: string, queryParams?: object) => {
    const primaryCareUri = baseAuthUri + '/primarycare';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '2', 'doctor', { rallyId }, queryParams);
  },
  products: (rallyId: string) => {
    const productsUri = baseAuthUri + '/products';
    return constructUri(productsUri, 'edge-dashboard/profile', '1', 'profile', { rallyId });
  },
  profile: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'profile', { rallyId });
  },
  profileInfoForRallyPay: (rallyId: string) => {
    const profileInfoForRallyPayUri = baseAuthUri + '/token';
    return constructUri(profileInfoForRallyPayUri, 'edge-dashboard/profile', '1', 'rallypay', { rallyId });
  },
  profileInfoForRallyPayV2: (rallyId: string, serviceStartDate: string) => {
    const profileInfoForRallyPayUri = baseAuthUri + '/token';
    return constructUri(
      profileInfoForRallyPayUri,
      'edge-dashboard/profile',
      '2',
      'rallypay',
      { rallyId },
      { serviceStartDate },
    );
  },
  providerVideoKey: (rallyId: string, queryParams: object) => {
    const providerVideoKeyUri = baseAuthUri + '/providervideokey';
    return constructUri(providerVideoKeyUri, 'edge-dashboard/profile', '1', 'profile', { rallyId }, queryParams);
  },
  activateProviderVideoKey: (rallyId: string, queryParams: object) => {
    const providerVideoKeyUri = baseAuthUri + '/activatevideokey';
    return constructUri(providerVideoKeyUri, 'edge-dashboard/profile', '1', 'video', { rallyId }, queryParams);
  },
  referrals: (rallyId: string) => {
    const referralsUri = baseAuthUri + '/referrals';
    return constructUri(referralsUri, 'edge-dashboard/profile', '1', 'doctor', { rallyId });
  },
  setPrimaryCare: (rallyId: string) => {
    const primaryCareUri = baseAuthUri + '/primarycare';
    return constructUri(primaryCareUri, 'edge-dashboard/profile', '1', 'doctor', { rallyId });
  },
  userPreferences: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/profile', '2', 'preferences', { rallyId });
  },
};

export const targetingUris = {
  alerts: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'alerts', { rallyId });
  },
  campaigns: (rallyId: string, queryParams: object) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '2', 'campaigns', { rallyId }, queryParams);
  },
  clientConfig: (configId: string, queryParams: object) => {
    const clientConfigUri = baseApiUri + '/[configId]/configuration';
    return constructUri(clientConfigUri, 'edge-dashboard/targeting', '2', 'client', { configId }, queryParams);
  },
  csFaqCustomizations: (planName: string) => {
    const csFaqCustomizationsUri = baseApiUri + '/[planName]/custom';
    return constructUri(csFaqCustomizationsUri, 'edge-dashboard/targeting', '1', 'client', { planName });
  },
  incentives: (rallyId: string) => {
    const incentivesUri = baseAuthUri + '/summary';
    return constructUri(incentivesUri, 'edge-dashboard/targeting', '1', 'incentives', { rallyId });
  },
  realTimeOfferCount: (rallyId: string, queryParams?: object) => {
    return constructUri(
      baseAuthUri + '/count',
      'edge-dashboard/targeting',
      '2',
      'realtimeoffers',
      { rallyId },
      queryParams,
    );
  },
  recommendations: (rallyId: string) => {
    return constructUri(baseAuthUri, 'edge-dashboard/targeting', '1', 'realtimeoffers', { rallyId });
  },
};

export const trackingUris = {
  events: () => {
    return constructUri(baseApiUri, 'tracking', '1', 'events');
  },
};

function heartbeatOrInfoUri(method: string): string {
  const userUri = baseApiUri + `/${method}`;
  return constructUri(userUri, 'user', '1', 'session');
}

export const arachneUris = {
  baseUri: (config: IConfig) => {
    return config.ARCADE_WEB_ARACHNE_SESSION_BASE_URL || config.ARCADE_WEB_RALLY_AUTH_URL;
  },
};

export const userUris = {
  info: () => {
    return heartbeatOrInfoUri('info');
  },
  heartbeat: () => {
    return heartbeatOrInfoUri('heartbeat');
  },
  idp: (idp: string, queryParams?: object) => {
    const idpUri = baseApiUri + '/idp/[idp]';
    // This needs to use no encoding because the entire redirect url needs to be encoded at once (this is only a part of it).
    return constructUri(idpUri, 'user', '1', 'session', { idp }, queryParams, constructParamsNoEncoding);
  },
  idpLogoutPage: (population: IPopulation, config = CONFIG) => {
    if (config.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      const resource =
        population.lineOfBusiness === LineOfBusiness.MR
          ? ({ myUhcBaseUrl }) => `${myUhcBaseUrl}/member/logout.html`
          : ({ myUhcLegacyBaseUrl }) => `${myUhcLegacyBaseUrl}${config.ARCADE_WEB_MYUHC_LOGOUT_PATH}`;
      return getResource(resource, population);
    } else {
      const redirectUrl = parse(userUris.idp('rally', { product: 'arcade' })).href;
      const rallyLogoutPage = config.ARCADE_WEB_RALLY_AUTH_URL + config.ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH;
      const sessionCreationEndpoint = encodeURIComponent(redirectUrl);
      return `${rallyLogoutPage}/?redirect=${sessionCreationEndpoint}`;
    }
  },
  idpRefresh: (config = CONFIG) => {
    const idpRefreshUri = baseApiUri + '/idp/[idp]/refresh';
    let authType = 'rally';

    if (config.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      authType = 'myuhc';
    }

    return constructUri(idpRefreshUri, 'user', '1', 'session', { idp: authType });
  },
  logout: () => {
    const logoutUri = baseApiUri + '/logout';
    return constructUri(logoutUri, 'user', '1', 'session');
  },
  arachneLogout: (config = CONFIG) => {
    return config.ARCADE_WEB_RALLY_AUTH_URL + '/auth/v1/logout';
  },
  prelogin: () => {
    const uri = baseApiUri + '/prelogin';
    return constructUri(uri, 'user', '1', 'session');
  },
  token: (url: string) => {
    const uri = baseApiUri + '/token';
    return constructUri(uri, 'user', '1', 'federation', {}, { deeplink: url });
  },
  sundog: (rallyId: string) => {
    const sundogUri = baseAuthUri + '/sundogdental';
    return constructUri(sundogUri, 'user', '1', 'redirect', { rallyId });
  },
  rallyPayToken: () => {
    const userUri = baseApiUri + '/token';
    return constructUri(userUri, 'user', '1', 'rallypay');
  },
};

const getSanitizedUrls = (config: IConfig): { connect: string; engage: string; rewards: string } => {
  return {
    connect: sanitizeLink(config.ARCADE_WEB_RALLY_CONNECT_URL),
    engage: sanitizeLink(config.ARCADE_WEB_RALLY_ENGAGE_URL),
    rewards: sanitizeLink(config.ARCADE_WEB_RALLY_REWARDS_URL),
  };
};

export function getLink(link: string, config: IConfig = CONFIG): string {
  const sanitizedUrls = getSanitizedUrls(config);
  if (
    link.indexOf(sanitizedUrls.connect) === -1 &&
    link.indexOf(sanitizedUrls.engage) === -1 &&
    link.indexOf(sanitizedUrls.rewards) === -1
  ) {
    return link;
  }
  return '/internal-redirect?deepLink=' + encodeURIComponent(link);
}

export function isLinkToConnect(link: string, config: IConfig = CONFIG): boolean {
  const sanitizedUrls = getSanitizedUrls(config);
  return link && link.indexOf(sanitizedUrls.connect) !== -1;
}

export function isLinkToArcade(link: string): boolean {
  return link && link.indexOf(baseUrls.web) !== -1;
}

// UI Router does some custom url encoding where / becomes ~2F and ~ becomes ~~
// source here: https://github.com/angular-ui/ui-router/blob/legacy/release/angular-ui-router.js#L1332
// (actually that is a different version, but same idea. anyway...)
// this function exists to reverse it
//   https://physics.stackexchange.com/questions/285270/minimum-angular-speed-of-earth-for-a-body-to-escape-its-gravity
export function angularMinimumEscape(uriComponent: string): string {
  return decodeURIComponent(uriComponent).replace(/(~~|~2F)/g, m => ({ '~~': '~', '~2F': '/' }[m]));
}
