import { Observable } from 'rxjs/Observable';
import { planUris } from 'scripts/util/uri/uri';
import { CacheName, CoverageType, CoverageTypeCode } from '../api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { getCache, getCacheKey } from '../cache';
import { IPlanCoverage, IProfile, IProfileResponse, IProfileUser, LineOfBusiness } from '../profile/profile.interfaces';
import { IProfileService } from '../profile/profile.service';
import { IUserService } from '../user/user.service';
import { getCoverageParams, getPlanVariationParams, planHasOtherMembers } from '../../util/plans/plans';
import {
  BenefitNetwork,
  IBenefitAccumulators,
  IBenefitAmount,
  IBenefitsSpendingList,
  IIdCardMRBenefitParams,
  IIdCardsResponse,
  IMRBenefitParams,
  IPlanAccumulatorsResponse,
  IPlanBenefitsResponse,
  IPlanFamilyAccumulatorsResponse,
  IPlanFamilyBenefitsResponse,
  IUsefulBenefits,
  IUsefulBenefitsWithAccumulators,
  IBenefit,
} from './plans.interfaces';

export interface IPlansService {
  getAccumulators(
    rallyId: string,
    dependentOffset?: string,
    coverageTypeCode?: CoverageTypeCode,
  ): Observable<IPlanAccumulatorsResponse>;
  getBenefits(
    rallyId: string,
    dependentOffset?: string,
    coverageTypeCode?: CoverageTypeCode,
  ): Observable<IPlanBenefitsResponse>;
  getFamilyAccumulators(rallyId: string, dependents: string[]): Observable<IPlanFamilyAccumulatorsResponse>;
  getFamilyBenefits(rallyId: string, dependents: string[]): Observable<IPlanFamilyBenefitsResponse>;
  getIdCards(currentUser: IProfileUser, coverage: IPlanCoverage, deqSeqNum?: string): Observable<IIdCardsResponse>;
  getBenefitsWithAccumulators(rallyId: string, dependentOffset?: string): Observable<IUsefulBenefitsWithAccumulators>;
  showCarveoutClaims(profile: IProfile): Observable<boolean>;
}

export class PlansService implements IPlansService {
  constructor(
    private baseApiService: IBaseApiService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
  }

  public getAccumulators(
    rallyId: string,
    dependentOffset?: string,
    coverageTypeCode?: CoverageTypeCode,
  ): Observable<IPlanAccumulatorsResponse> {
    const profile$ = this.profileService.get(rallyId);
    const currentProfile$ = this.profileService.getCurrentProfile(rallyId);

    return Observable.zip(profile$, currentProfile$).flatMap(
      ([{ data: profile }, currentProfile]: [IProfileResponse, IProfileUser]) => {
        const coverageParams = coverageTypeCode
          ? getCoverageParams(currentProfile, coverageTypeCode)
          : ({} as IMRBenefitParams);
        const params = {
          ...coverageParams,
          ...getPlanVariationParams(profile),
          d: dependentOffset || profile.currentUser.dependentSeqNum,
          showFamily: planHasOtherMembers(profile, currentProfile),
        };
        const url = planUris.accumulator(rallyId, params);
        return this.getBenefitsFromCacheOrApi(url, true) as Observable<IPlanAccumulatorsResponse>;
      },
    );
  }

  /**
   * ARC-1120: for getBenefits and getAccumulators, note that
   * 1. showFamily is not passed as an argument but is instead generated based on profile and currentProfile responses
   * 2. dependentSeqNum defaults to that of the logged in user if it is not passed
   */
  public getBenefits(
    rallyId: string,
    dependentOffset?: string,
    coverageTypeCode?: CoverageTypeCode,
  ): Observable<IPlanBenefitsResponse> {
    const profile$ = this.profileService.get(rallyId);
    const currentProfile$ = this.profileService.getCurrentProfile(rallyId);

    return Observable.zip(profile$, currentProfile$).flatMap(
      ([{ data: profile }, currentProfile]: [IProfileResponse, IProfileUser]): any => {
        const coverageParams = coverageTypeCode
          ? getCoverageParams(currentProfile, coverageTypeCode)
          : ({} as IMRBenefitParams);
        const params = {
          ...coverageParams,
          ...getPlanVariationParams(profile),
          d: dependentOffset || profile.currentUser.dependentSeqNum,
          showFamily: planHasOtherMembers(profile, currentProfile),
        };
        const url = planUris.benefits(rallyId, params);
        return this.getBenefitsFromCacheOrApi(url);
      },
    );
  }

  public getFamilyAccumulators(rallyId: string, dependents: string[]): Observable<IPlanFamilyAccumulatorsResponse> {
    const profile$ = this.profileService.get(rallyId);
    const currentProfile$ = this.profileService.getCurrentProfile(rallyId);

    return Observable.zip(profile$, currentProfile$).flatMap(
      ([{ data: profile }, currentProfile]: [IProfileResponse, IProfileUser]): any => {
        const commonParams = {
          ...getPlanVariationParams(profile),
          showFamily: planHasOtherMembers(profile, currentProfile),
        };
        if (dependents.length === 1) {
          const url = planUris.accumulator(rallyId, { d: dependents[0], ...commonParams });
          return this.getBenefitsFromCacheOrApi(url, true).map(({ data, ...rest }) => ({
            ...rest,
            data: { [dependents[0]]: data },
          }));
        } else {
          const url = planUris.familyAccumulator(rallyId, {
            d: dependents,
            ...commonParams,
          });
          return this.getBenefitsFromCacheOrApi(url, true, dependents);
        }
      },
    );
  }

  public getFamilyBenefits(rallyId: string, dependents: string[]): Observable<IPlanFamilyBenefitsResponse> {
    const profile$ = this.profileService.get(rallyId);
    const currentProfile$ = this.profileService.getCurrentProfile(rallyId);

    return Observable.zip(profile$, currentProfile$).flatMap(
      ([{ data: profile }, currentProfile]: [IProfileResponse, IProfileUser]): any => {
        const commonParams = {
          ...getPlanVariationParams(profile),
          showFamily: planHasOtherMembers(profile, currentProfile),
        };
        if (dependents.length === 1) {
          const url = planUris.benefits(rallyId, { d: dependents[0], ...commonParams });
          return this.getBenefitsFromCacheOrApi(url).map(({ data, ...rest }) => ({
            ...rest,
            data: { [dependents[0]]: data },
          }));
        } else {
          const url = planUris.familyBenefits(rallyId, { d: dependents, ...commonParams });
          return this.getBenefitsFromCacheOrApi(url, false, dependents);
        }
      },
    );
  }

  public getIdCards(
    currentUser: IProfileUser,
    coverage: IPlanCoverage,
    depSeqNum?: string,
  ): Observable<IIdCardsResponse> {
    const queryParams = depSeqNum ? { d: depSeqNum, getImage: true } : {};
    let url: string;
    if (currentUser.lineOfBusiness === LineOfBusiness.MR) {
      const medicareQueryParams: IIdCardMRBenefitParams = {
        marketType: coverage.additionalCoverageInfo.marketType,
        fundingArrangementType: coverage.planFeatures.fundingArrangementType,
        programType: coverage.planFeatures.programType,
        coverageTypeCode: coverage.coverageTypeCode,
        getImage: true,
      };
      url = planUris.idCards(currentUser.rallyId, coverage.coverageType, medicareQueryParams);
    } else {
      url = planUris.idCards(currentUser.rallyId, coverage.coverageType, queryParams);
    }

    const plansCache = getCache(CacheName.Plans);
    const cacheKey = getCacheKey(url);
    const cachedData = plansCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => plansCache.put(cacheKey, rsp));
    return cachedData ? Observable.of(cachedData) : nonCachedSrc$;
  }

  public getBenefitsWithAccumulators(
    rallyId: string,
    dependentOffset?: string,
  ): Observable<IUsefulBenefitsWithAccumulators> {
    const benefits$ = this.getBenefits(rallyId, dependentOffset);
    const accumulators$ = this.getAccumulators(rallyId, dependentOffset).catch(() =>
      Observable.of({ data: { benefits: [] as IBenefitAccumulators[] } }),
    );
    return Observable.zip(benefits$, accumulators$, (_benefits, _accumulators) => {
      const supportedAccumulators = (b: IBenefit | IBenefitAccumulators): boolean =>
        b.coverageType === CoverageType.Medical || b.coverageType === CoverageType.Dental;
      const benefits = _benefits.data.benefits.filter(supportedAccumulators);
      const accumulators = _accumulators.data.benefits.filter(supportedAccumulators);
      const useful: IUsefulBenefitsWithAccumulators = {};
      const preDeductibleMaxAmount = {};

      benefits.forEach(({ coverageType, maxes, planFeatures }) => {
        const b = (useful[coverageType] = {
          coverageType,
          isFamilyPlan: false,
          isTieredPlan: planFeatures.isTieredPlan,
          isCspGspPlan: planFeatures.isCspGspPlan,
        });

        PlansService.setUsefulMaxes(b, maxes, 'inNetwork');
        PlansService.setUsefulMaxes(b, maxes, 'outOfNetwork');
        PlansService.setUsefulMaxes(b, maxes, 'tier1');

        if (maxes.predeductibleMax) {
          preDeductibleMaxAmount[coverageType] = maxes.predeductibleMax.amount;
        }
      });

      accumulators.forEach((acc: IBenefitAccumulators) => {
        const b = useful[acc.coverageType];

        if (typeof b === 'undefined') {
          console.warn(`an accumulator was found for a benefit coverage (${acc.coverageType}) that doesn't exist`);
          return;
        } else {
          PlansService.setUsefulAmounts(b, acc.accumulators, 'inNetwork');
          PlansService.setUsefulAmounts(b, acc.accumulators, 'outOfNetwork');
          PlansService.setUsefulAmounts(b, acc.accumulators, 'tier1');

          if (preDeductibleMaxAmount[acc.coverageType]) {
            PlansService.setUsefulPreDeductibleMaxAmounts(
              b,
              acc.predeductibleAccumulators,
              preDeductibleMaxAmount[acc.coverageType],
            );
          }
        }
      });

      return useful;
    });
  }

  public showCarveoutClaims(profile: IProfile): Observable<boolean> {
    return this.getBenefits(profile.rallyId, profile.currentUser.dependentSeqNum)
      .map(rsp => rsp.data.benefits.some(benefit => benefit.showRxCarveoutClaims))
      .catch(() => Observable.of(false));
  }

  private getBenefitsFromCacheOrApi(
    url: string,
    isAccumulator?: boolean,
    dependents: string[] = [],
  ): Observable<IPlanBenefitsResponse | IPlanAccumulatorsResponse> {
    const plansCache = getCache(CacheName.Plans);
    const cacheKey = getCacheKey(url);
    const cachedData = plansCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        if (isAccumulator) {
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'inNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'outOfNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'accumulators', 'tier1', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'predeductibleAccumulators', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'lifetimeMaxAccumulator', 'amount', 'value');
          for (const d of dependents) {
            this.baseApiService.stringToFloat(
              rsp,
              'data',
              d,
              'benefits',
              'accumulators',
              'inNetwork',
              'amount',
              'value',
            );
            this.baseApiService.stringToFloat(
              rsp,
              'data',
              d,
              'benefits',
              'accumulators',
              'outOfNetwork',
              'amount',
              'value',
            );
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'accumulators', 'tier1', 'amount', 'value');
            this.baseApiService.stringToFloat(
              rsp,
              'data',
              d,
              'benefits',
              'predeductibleAccumulators',
              'amount',
              'value',
            );
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'lifetimeMaxAccumulator', 'amount', 'value');
          }
        } else {
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'maxes', 'inNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'services', 'inNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'maxes', 'outOfNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'services', 'outOfNetwork', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'maxes', 'tier1', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'services', 'tier1', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'maxes', 'predeductibleMax', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'maxes', 'lifetimeMax', 'amount', 'value');
          this.baseApiService.stringToFloat(rsp, 'data', 'benefits', 'services', 'type1', 'amount', 'value');
          for (const d of dependents) {
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'inNetwork', 'amount', 'value');
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'services', 'inNetwork', 'amount', 'value');
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'outOfNetwork', 'amount', 'value');
            this.baseApiService.stringToFloat(
              rsp,
              'data',
              d,
              'benefits',
              'services',
              'outOfNetwork',
              'amount',
              'value',
            );
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'tier1', 'amount', 'value');
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'services', 'tier1', 'amount', 'value');
            this.baseApiService.stringToFloat(
              rsp,
              'data',
              d,
              'benefits',
              'maxes',
              'predeductibleMax',
              'amount',
              'value',
            );
            this.baseApiService.stringToFloat(rsp, 'data', d, 'benefits', 'maxes', 'lifetimeMax', 'amount', 'value');
          }
        }
        return rsp;
      })
      .do(value => plansCache.put(url, value));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  private static setUsefulMaxes(
    usefulBenefits: IUsefulBenefits,
    maxes: IBenefitsSpendingList,
    benefitType: string,
  ): void {
    if (typeof maxes[benefitType] !== 'undefined' && maxes[benefitType].length > 0) {
      usefulBenefits[benefitType] = usefulBenefits[benefitType] || {};
      maxes[benefitType].forEach(max => {
        usefulBenefits[benefitType][max.type] = usefulBenefits[benefitType][max.type] || {};
        usefulBenefits[benefitType][max.type].max = max.amount;
        usefulBenefits[benefitType][max.type].network = benefitType as BenefitNetwork;
        usefulBenefits[benefitType][max.type].type = max.type;
        if (max.type.toUpperCase().indexOf('FAMILY') === 0) {
          usefulBenefits.isFamilyPlan = true;
        }
      });
    }
  }

  private static setUsefulAmounts(
    usefulBenefits: IUsefulBenefits,
    amounts: IBenefitsSpendingList,
    benefitType: string,
  ): void {
    if (typeof usefulBenefits[benefitType] !== 'undefined' && typeof amounts[benefitType] !== 'undefined') {
      amounts[benefitType].forEach(amount => {
        // If an amount type does not exist yet (from setUsefulMaxes), we should not create one
        if (typeof usefulBenefits[benefitType][amount.type] !== 'undefined') {
          usefulBenefits[benefitType][amount.type].amount = amount.amount;
        }
      });
    }
  }

  private static setUsefulPreDeductibleMaxAmounts(
    usefulBenefits: IUsefulBenefits,
    amounts: IBenefitAmount[],
    maxAmount: any,
  ): void {
    for (const benefitNetwork of [BenefitNetwork.InNetwork, BenefitNetwork.OutOfNetwork]) {
      if (typeof usefulBenefits[benefitNetwork] !== 'undefined' && amounts) {
        amounts.forEach(amount => {
          usefulBenefits[benefitNetwork][amount.type] = {
            max: maxAmount,
            amount: amount.amount,
            network: benefitNetwork,
            type: amount.type,
          };
        });
      }
    }
  }
}
