import React, {
  AnchorHTMLAttributes,
  ComponentType,
  createRef,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { Feature } from 'scripts/ui/feature/feature';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';
import { LoadingDots } from 'scripts/ui/loading-dots/loading-dots';
import { useTranslation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { sendSectionLoad } from 'scripts/util/tracking/tracking';

export interface IWaitForProps {
  classes?: string[];
  error: boolean | (() => boolean);
  errorMessage?: string;
  loading: boolean | (() => boolean);
  onRetry: () => void;
  trackData?: any;
  trackLabel?: string;
}

export function withWaitFor<T>(Component: ComponentType<T>): ComponentType<T & IWaitForProps> {
  const Button: FunctionComponent<AnchorHTMLAttributes<HTMLButtonElement>> = props => <button {...props} />;
  const TryAgainButton = withClickTracking(Button, 'try-again');
  const ref = createRef<HTMLDivElement>();
  return props => {
    const { t } = useTranslation(Dictionary.ERRORS);
    const {
      classes,
      error,
      errorMessage = 'WAIT_FOR_FAILURE_MESSAGE',
      loading,
      onRetry,
      trackData = {},
      trackLabel,
      ...rest
    } = props;
    const isLoading = typeof loading === 'function' ? loading() : loading;
    const isError = typeof error === 'function' ? error() : error;
    const [startTime, setStartTime] = useState();
    const { featureList } = useContext(FeatureContext);

    useEffect(() => {
      if (!isLoading && startTime) {
        sendSectionLoad(trackLabel, trackData, featureList, ref, isError, startTime);
        setStartTime(undefined);
      }

      if (isLoading && !startTime) {
        setStartTime(new Date().getTime());
      }
    }, [isLoading]);
    const handleTryAgain = (): void => {
      setStartTime(undefined);
      onRetry();
    };

    if (isLoading) {
      return (
        <div className={classNames(classes)}>
          <LoadingDots />
        </div>
      );
    }
    if (isError) {
      return (
        <Feature featureId="request-failure">
          <div
            className={classNames('fade-in', 'wait-for-failure', classes)}
            data-testid="wait-for-failure-container"
            ref={ref}
          >
            <p data-testid="wait-for-failure-message">{t(errorMessage)}</p>
            <TryAgainButton className="wait-for-failure-retry-btn" onClick={handleTryAgain}>
              {t('TRY_AGAIN')}
            </TryAgainButton>
          </div>
        </Feature>
      );
    }
    return (
      <div className={classNames('fade-in', classes)} ref={ref}>
        <Component {...rest as T} />
      </div>
    );
  };
}
