import { getMoneyValue } from 'scripts/util/money/money';
import hraapAccumulatorTemplate from 'views/ui/hraap-accumulator.html';
import { Dictionary } from '../../util/constants/i18n.constants';

export class AccumulatorController implements ng.IComponentController {
  // Bindings
  public max: number;
  public amount: number;
  public person?: string;
  public isMaxReimbursement?: boolean;
  public isFamily?: boolean;
  public isAccessible?: boolean;

  // Other template-exposed variables
  public moneyAmount: string;
  public moneyMax: string;
  public moneyZero: string;
  public percentage: string;
  public mobileLabel: string;
  public desktopLabel: string;
  public amountLabel: string;
  public modalLink: string;
  public modalTrack: string;

  constructor(private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);

    this.moneyAmount = getMoneyValue(this.amount);
    this.moneyMax = getMoneyValue(this.max);
    this.moneyZero = getMoneyValue(0);
    this.percentage = this.max > 0 ? ((this.amount / this.max) * 100).toFixed(0) + '%' : '0%';
    this.desktopLabel = this.getDesktopLabel();
    this.mobileLabel = this.isMaxReimbursement ? 'AMOUNT_USED' : 'ACCESS_POINT';
    this.amountLabel = this.isMaxReimbursement ? 'USED_OUT_OF_TOTAL' : 'APPLIED_OUT_OF_TOTAL';
    this.modalLink = this.getModalName().replace('-', '');
    this.modalTrack = this.getModalName().toLowerCase();
  }

  private getDesktopLabel(): string {
    if (this.isMaxReimbursement) {
      return 'PERSONS_AMOUNT_USED';
    } else {
      return this.isFamily ? 'FAMILY_ACCESS_POINT' : 'PERSONS_ACCESS_POINT';
    }
  }

  private getModalName(): string {
    if (this.isMaxReimbursement) {
      return 'Amount-Used';
    } else {
      return this.isFamily ? 'Family' : 'Individual';
    }
  }
}

export class HraapAccumulatorComponent implements ng.IComponentOptions {
  public controller = AccumulatorController;
  public templateUrl = hraapAccumulatorTemplate;
  public bindings = {
    person: '@?aPerson',
    max: '<aMax',
    amount: '<aAmount',
    isFamily: '<aIsFamily',
    isMaxReimbursement: '<aIsMaxReimbursement',
    isAccessible: '<?aIsAccessible',
  };
}
