import { IPlanAccumulators, IPlanBenefits, IIdCard } from 'scripts/api/plans/plans.interfaces';
import moment from 'moment';

export enum PlansServiceActionTypes {
  GET_ACCUMULATORS_ERROR = 'GET_ACCUMULATORS_ERROR',
  GET_ACCUMULATORS_LOADING = 'GET_ACCUMULATORS_LOADING',
  GET_ACCUMULATORS_SUCCESS = 'GET_ACCUMULATORS_SUCCESS',
  GET_BENEFITS_ERROR = 'GET_BENEFITS_ERROR',
  GET_BENEFITS_LOADING = 'GET_BENEFITS_LOADING',
  GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS',
  GET_ID_CARDS_ERROR = 'GET_ID_CARDS_ERROR',
  GET_ID_CARDS_LOADING = 'GET_ID_CARDS_LOADING',
  GET_ID_CARDS_SUCCESS = 'GET_ID_CARDS_SUCCESS',
}

export interface IGetAccumulatorsLoading {
  type: PlansServiceActionTypes.GET_ACCUMULATORS_LOADING;
}

export interface IGetAccumulatorsSuccess {
  type: PlansServiceActionTypes.GET_ACCUMULATORS_SUCCESS;
  payload: { accumulators: IPlanAccumulators; arcadeDataUpdated?: moment.Moment };
}

export interface IGetAccumulatorsError {
  type: PlansServiceActionTypes.GET_ACCUMULATORS_ERROR;
}

export interface IGetBenefitsLoading {
  type: PlansServiceActionTypes.GET_BENEFITS_LOADING;
}

export interface IGetBenefitsSuccess {
  type: PlansServiceActionTypes.GET_BENEFITS_SUCCESS;
  payload: IPlanBenefits;
}

export interface IGetBenefitsError {
  type: PlansServiceActionTypes.GET_BENEFITS_ERROR;
}

export interface IGetIdCardsLoading {
  type: PlansServiceActionTypes.GET_ID_CARDS_LOADING;
}

export interface IGetIdCardsSuccess {
  type: PlansServiceActionTypes.GET_ID_CARDS_SUCCESS;
  payload: IIdCard[][];
}

export interface IGetIdCardsError {
  type: PlansServiceActionTypes.GET_ID_CARDS_ERROR;
}

export function getAccumulatorsLoading(): IGetAccumulatorsLoading {
  return {
    type: PlansServiceActionTypes.GET_ACCUMULATORS_LOADING,
  };
}

export function getAccumulatorsSuccess(payload: {
  accumulators: IPlanAccumulators;
  arcadeDataUpdated?: moment.Moment;
}): IGetAccumulatorsSuccess {
  return {
    type: PlansServiceActionTypes.GET_ACCUMULATORS_SUCCESS,
    payload,
  };
}

export function getAccumulatorsError(): IGetAccumulatorsError {
  return {
    type: PlansServiceActionTypes.GET_ACCUMULATORS_ERROR,
  };
}

export function getBenefitsLoading(): IGetBenefitsLoading {
  return {
    type: PlansServiceActionTypes.GET_BENEFITS_LOADING,
  };
}

export function getBenefitsSuccess(payload: IPlanBenefits): IGetBenefitsSuccess {
  return {
    type: PlansServiceActionTypes.GET_BENEFITS_SUCCESS,
    payload,
  };
}

export function getBenefitsError(): IGetBenefitsError {
  return {
    type: PlansServiceActionTypes.GET_BENEFITS_ERROR,
  };
}

export function getIdCardsLoading(): IGetIdCardsLoading {
  return {
    type: PlansServiceActionTypes.GET_ID_CARDS_LOADING,
  };
}

export function getIdCardsSuccess(payload: IIdCard[][]): IGetIdCardsSuccess {
  return {
    type: PlansServiceActionTypes.GET_ID_CARDS_SUCCESS,
    payload,
  };
}

export function getIdCardsError(): IGetIdCardsError {
  return {
    type: PlansServiceActionTypes.GET_ID_CARDS_ERROR,
  };
}

export type PlansServiceAction =
  | IGetBenefitsLoading
  | IGetBenefitsSuccess
  | IGetBenefitsError
  | IGetAccumulatorsLoading
  | IGetAccumulatorsSuccess
  | IGetAccumulatorsError
  | IGetIdCardsLoading
  | IGetIdCardsSuccess
  | IGetIdCardsError;
