import angular from 'angular';
import jQuery from 'jquery';
import { IAmpRootScope } from 'scripts/api/tracking/amplitude.interfaces';
import { ITrackingService } from 'scripts/api/tracking/tracking.service';
import { ResetFocus } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getLocale } from 'scripts/util/locale/locale';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { sendTabLoadedEvent, updateUserFeedbackPath } from 'scripts/util/tracking/adobe-analytics';
import { queuePageLoadEvent } from 'scripts/util/tracking/tracking';

function updateTitle(state: ng.ui.IState, $translate: angular.translate.ITranslateService): void {
  $translate.refresh().finally(() => {
    if (state && state.data && state.data.title) {
      $translate(state.data.title).then(
        title => {
          document.title = title + ' | UnitedHealthcare';
        },
        () => {
          document.title = 'UnitedHealthcare';
        },
      );
    } else {
      document.title = 'UnitedHealthcare';
    }
  });
}

function scrollToTop(): void {
  const jBody = jQuery(document.body);
  // unlike the other browsers, the html element is what scrolls in FF
  const jHtml = jQuery(document.documentElement);
  jBody.animate({ scrollTop: 0 });
  jHtml.animate({ scrollTop: 0 });
}

// TODO There's probably a library that does this so we wouldn't have to maintain code.
// see https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript/2117523#2117523
function generateUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function sendPageLoadEvent(state: ng.ui.IState, $location: ng.ILocationService): void {
  const featureList = $location
    .path()
    .split('/')
    .filter(p => p);
  featureList.unshift($location.host());
  const data: any = {};
  if (state && state.data && state.data.title) {
    data.pageTitle = state.data.title;
  }
  const search = $location.search();
  if (search && Object.keys(search).length > 0) {
    data.queryParams = search;
  }
  data.locale = getLocale().id;
  queuePageLoadEvent($location.url(), featureList, data);
}

function emitCustomStateChangeEvent(fromStateName: string, toStateName: string): void {
  const isPageLoad = fromStateName === '';
  const isToOrFromModal = fromStateName.indexOf('modal') !== -1 || toStateName.indexOf('modal') !== -1;
  const isFromClaimsToClaims =
    fromStateName.indexOf('claimsAndAccounts') !== -1 && toStateName.indexOf('claimsAndAccounts') !== -1;

  if (isPageLoad || isToOrFromModal || isFromClaimsToClaims) {
    return;
  } else {
    const event = new CustomEvent('arcadeStateChange');
    document.dispatchEvent(event);
  }
}

export class StateChangeSuccess {
  constructor(
    $location: ng.ILocationService,
    $rootScope: IAmpRootScope,
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    $translate: ng.translate.ITranslateService,
    $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    localeService: ILocaleService,
    trackingService: ITrackingService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PAGE_TITLES);

    localeService.localeChanged.subscribe(() => {
      updateTitle($state.current, $translate);
    });
    $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState) => {
      if (fromState.name === toState.name) {
        return;
      }

      updateTitle(toState, $translate);
      if (fromState.name.indexOf('modal') === -1 && toState.name.indexOf('modal') === -1) {
        scrollToTop();
        $rootScope.$emit(ResetFocus);
        if (fromState.name) {
          sendTabLoadedEvent();
        }
      }
      $timeout(() => {
        updateUserFeedbackPath();
        emitCustomStateChangeEvent(fromState.name, toState.name);
      });
      sendPageLoadEvent(toState, $location);

      const isTrackedState = toState && toState.data && toState.data.name;
      if (isTrackedState) {
        toState.data.uuid = generateUID();
      }
      trackingService.stateChange(fromState, toState);
      if (isTrackedState) {
        $rootScope.lastTrackedPageInfo = {
          data: toState.data || {},
          path: window.location.pathname,
        };
      }
    });
  }
}
