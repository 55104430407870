import moment, { Moment } from 'moment';
import { IDropdownOption, ISortDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { SortBy } from './all-claims.service';
import { ClaimStatus, IAnyClaim, IClaim, IFinancialClaim } from 'scripts/api/claims/claims.interfaces';
import {
  getByClaim,
  getClaimsSearchMonthsAgo,
  getYouMayOweAmount,
  getYouPaidAmount,
  showPayNow,
} from 'scripts/util/claims/claims';
import { IGenericFilter, ISelectedFilters, ISelectedSort, ISort } from 'scripts/ui/filter/filter.interfaces';
import { ICurrencyAmount, ITimePeriod } from 'scripts/api/api.interfaces';
import { LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { getMoneyValue } from 'scripts/util/money/money';
import { ICsvField } from 'scripts/util/download/download.interfaces';
import { downloadCsv, getCsvContent } from 'scripts/util/download/download';

export const getHealthcareSortByOptions = (smartSortEligible?: boolean): ISortDropdownOption[] => {
  const sortByOptions = [
    {
      label: 'PROCESSED_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ProcessedDate,
    },
    {
      label: 'PROCESSED_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ProcessedDate,
      reverse: true,
    },
    {
      label: 'SERVICE_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ServiceDate,
    },
    {
      label: 'SERVICE_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ServiceDate,
      reverse: true,
    },
    {
      label: 'PLAN_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.HealthPlanPays,
    },
    {
      label: 'PLAN_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.HealthPlanPays,
      reverse: true,
    },
    {
      label: 'YOU_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.PatientResponsibility,
    },
    {
      label: 'YOU_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.PatientResponsibility,
      reverse: true,
    },
    {
      label: 'AMOUNT_BILLED_LOWEST_TO_HIGHEST',
      value: SortBy.TotalBilledAmount,
    },
    {
      label: 'AMOUNT_BILLED_HIGHEST_TO_LOWEST',
      value: SortBy.TotalBilledAmount,
      reverse: true,
    },
    {
      label: 'YOU_MAY_OWE_LOWEST_TO_HIGHEST',
      value: SortBy.YouMayOweAmount,
    },
    {
      label: 'YOU_MAY_OWE_HIGHEST_TO_LOWEST',
      value: SortBy.YouMayOweAmount,
      reverse: true,
    },
    {
      label: 'PROVIDER_NAME_A_Z',
      value: SortBy.ProviderName,
    },
    {
      label: 'PROVIDER_NAME_Z_A',
      value: SortBy.ProviderName,
      reverse: true,
    },
  ];
  if (smartSortEligible) {
    sortByOptions.unshift({
      label: 'SMART_SORT_PAY_NOW_FIRST',
      value: SortBy.SmartSort,
      reverse: true,
    });
  }
  return sortByOptions;
};

export const getFinancialSortByOptions = (): ISortDropdownOption[] => {
  return [
    {
      label: 'AMOUNT_PAID_LOWEST_TO_HIGHEST',
      value: SortBy.AmountPaid,
    },
    {
      label: 'AMOUNT_PAID_HIGHEST_TO_LOWEST',
      value: SortBy.AmountPaid,
      reverse: true,
    },
    {
      label: 'AMOUNT_PENDING_LOWEST_TO_HIGHEST',
      value: SortBy.AmountPending,
    },
    {
      label: 'AMOUNT_PENDING_HIGHEST_TO_LOWEST',
      value: SortBy.AmountPending,
      reverse: true,
    },
    {
      label: 'AMOUNT_SUBMITTED_LOWEST_TO_HIGHEST',
      value: SortBy.AmountSubmitted,
    },
    {
      label: 'AMOUNT_SUBMITTED_HIGHEST_TO_LOWEST',
      value: SortBy.AmountSubmitted,
      reverse: true,
    },
    {
      label: 'PROCESSED_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ProcessedDate,
    },
    {
      label: 'PROCESSED_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ProcessedDate,
      reverse: true,
    },
    {
      label: 'SERVICE_DATE_OLDEST_TO_NEWEST',
      value: SortBy.ServiceDate,
    },
    {
      label: 'SERVICE_DATE_NEWEST_TO_OLDEST',
      value: SortBy.ServiceDate,
      reverse: true,
    },
    {
      label: 'PROVIDER_NAME_A_Z',
      value: SortBy.ProviderName,
    },
    {
      label: 'PROVIDER_NAME_Z_A',
      value: SortBy.ProviderName,
      reverse: true,
    },
  ];
};

export const getSmartSortEligible = (claims: IClaim[]): boolean => {
  return claims ? claims.some(claim => (getYouMayOweAmount(claim) || 0) > 3) : false;
};

export const isDateRangeValid = (startDate: Moment, endDate: Moment): boolean => {
  return startDate.isSameOrBefore(endDate, 'day') && startDate.isSameOrBefore(moment(), 'day');
};

export const getSelectedSortOption = (options: ISortDropdownOption[], selectedSort: ISelectedSort): IDropdownOption => {
  const { by, reverse } = selectedSort;
  for (const option of options) {
    if (option.value === by && option.reverse === reverse) {
      return option;
    }
  }
};

export const getHealthcareDefaultSortBy = (isSmartSortEligible?: boolean): ISelectedSort => {
  const healthcareFilterSort = isSmartSortEligible ? SortBy.SmartSort : SortBy.ProcessedDate;
  return { by: healthcareFilterSort, reverse: true };
};

export const getHealthcareDefaultFilters = (isSmartSortEligible?: boolean): ISelectedFilters<IClaim> => ({
  sort: getHealthcareDefaultSortBy(isSmartSortEligible),
  values: {},
});

export const getFinancialDefaultSortBy = (): ISelectedSort => {
  return { by: SortBy.ProcessedDate, reverse: true };
};

const getValOrZero = (money: ICurrencyAmount): number => {
  return money ? money.value : 0;
};

const getISOStringOrBlank = (date: Moment): string => {
  return date && moment.isMoment(date) ? date.toISOString() : '';
};

const getYouMayOweSortValue = (claim: IClaim, reverse: boolean): number => {
  if (claim.claimManagementInfo.markPaid) {
    return 0;
  }
  const youMayOweAmount = getYouMayOweAmount(claim);
  if (youMayOweAmount) {
    return youMayOweAmount;
  }
  return reverse ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
};

export const getSort = (selectedSort: ISelectedSort): ISort<IAnyClaim> => {
  let by: ISort<IAnyClaim>['properties'];
  switch (selectedSort.by) {
    case SortBy.SmartSort:
      by = [(claim: IClaim) => showPayNow(claim).toString(), claim => getISOStringOrBlank(claim.processedDate)];
      break;
    case SortBy.AmountPaid:
      by = [(claim: IFinancialClaim) => getValOrZero(claim.balance.amountPaid)];
      break;
    case SortBy.AmountPending:
      by = [(claim: IFinancialClaim) => getValOrZero(claim.balance.amountPending)];
      break;
    case SortBy.AmountSubmitted:
      by = [(claim: IFinancialClaim) => getValOrZero(claim.balance.amountSubmitted)];
      break;
    case SortBy.ProcessedDate:
      by = [claim => getISOStringOrBlank(claim.processedDate)];
      break;
    case SortBy.ServiceDate:
      by = [claim => getISOStringOrBlank(claim.serviceDate)];
      break;
    case SortBy.HealthPlanPays:
      by = [(claim: IClaim) => getValOrZero(claim.balance.healthPlanPays)];
      break;
    case SortBy.PatientResponsibility:
      by = [(claim: IClaim) => getYouPaidAmount(claim)];
      break;
    case SortBy.TotalBilledAmount:
      by = [(claim: IClaim) => getValOrZero(claim.balance.totalBilledAmount)];
      break;
    case SortBy.YouMayOweAmount:
      by = [(claim: IClaim) => getYouMayOweSortValue(claim, selectedSort.reverse)];
      break;
    case SortBy.ProviderName:
      by = [claim => claim.providerName];
      break;
    default:
      by = [];
  }
  return {
    properties: [
      ...by,
      (claim: IClaim) => claim.providerName,
      (claim: IAnyClaim) => getByClaim(claim, (claim as IClaim).claimId, (claim as IFinancialClaim).claimNumber),
    ],
    reverse: selectedSort.reverse,
  };
};

export const getPeriod = (
  filters: IGenericFilter<IClaim, any>[],
  selectedFilters: ISelectedFilters<any>,
): ITimePeriod => {
  const selectedPeriod = selectedFilters.values && selectedFilters.values['by-date-range'];
  if (selectedPeriod && selectedPeriod.length === 2) {
    return { startDate: moment(selectedPeriod[0]), endDate: moment(selectedPeriod[1]) };
  }
  const byDateRangeFilter = filters.find(filter => filter.name === 'by-date-range');
  if (byDateRangeFilter) {
    const defaultPeriod = byDateRangeFilter.defaultValues;
    if (defaultPeriod && defaultPeriod.length === 2) {
      return { startDate: moment(defaultPeriod[0]), endDate: moment(defaultPeriod[1]) };
    }
  }
  return { startDate: moment().subtract(getClaimsSearchMonthsAgo(), 'M'), endDate: moment() };
};

function getClaimStatusLabel(payment: ClaimStatus): string {
  const label = {
    [ClaimStatus.Denied]: 'PROCESSED',
    [ClaimStatus.PartiallyDenied]: 'PROCESSED',
  }[payment];

  if (label === undefined) {
    return payment;
  }

  return label;
}
function getClaimPaymentStatusLabel(payment: ClaimStatus): string {
  const label = {
    [ClaimStatus.Denied]: 'DENIED',
    [ClaimStatus.PartiallyDenied]: 'PARTIALLY_DENIED',
  }[payment];

  if (label === undefined) {
    return '';
  }

  return label;
}

function getBalance(balance: ICurrencyAmount): string {
  return balance ? getMoneyValue(balance.value) : '';
}
function getDate(date: string | moment.Moment): string {
  return date ? moment(date).format('YYYY-MM-DD') : '';
}

/*
 Passing in the translation function for now while we have both react and angular.
  Once angular is phased out can remove and use just react version.
*/
export function downloadHealthcareClaims(
  claims: IClaim[],
  translationFunc: (_: string) => string,
  lob?: LineOfBusiness,
): void {
  // We don't have column defs from Optum yet for m&r, so this is a placeholder.
  const mrHealthcareCsvFields: ICsvField<IClaim>[] = [];
  const healthcareCsvFields: ICsvField<IClaim>[] = [
    {
      header: 'CLAIM_NUMBER',
      getValue: claim => claim.claimId,
    },
    {
      header: 'PATIENT_NAME',
      getValue: claim =>
        claim.serviceRecipient ? `${claim.serviceRecipient.firstName} ${claim.serviceRecipient.lastName}` : '',
    },
    {
      header: 'DATE_VISITED',
      getValue: claim => getDate(claim.serviceDate),
    },
    {
      header: 'VISITED_PROVIDER',
      getValue: claim => (claim.providerName ? claim.providerName : ''),
    },
    {
      header: 'CLAIM_TYPE',
      getValue: claim => translationFunc(claim.claimType),
    },
    {
      header: 'CLAIM_STATUS',
      getValue: claim => translationFunc(getClaimStatusLabel(claim.claimStatus)),
    },
    {
      header: 'PAYMENT_STATUS',
      getValue: claim => translationFunc(getClaimPaymentStatusLabel(claim.claimStatus)),
    },
    {
      header: 'DATE_PROCESSED',
      getValue: claim => getDate(claim.processedDate),
    },
    {
      header: 'AMOUNT_BILLED',
      getValue: claim => getBalance(claim.balance.totalBilledAmount),
    },
    {
      header: 'DEDUCTIBLE',
      getValue: claim => getBalance(claim.balance.deductible),
    },
    {
      header: 'YOUR_PLAN',
      getValue: claim => getBalance(claim.balance.healthPlanPays),
    },
    {
      header: 'PLAN_DISCOUNT',
      getValue: claim => getBalance(claim.balance.healthPlanDiscount),
    },
    {
      header: 'YOUR_RESPONSIBILITY',
      getValue: claim => getBalance(claim.balance.patientResponsibility),
    },
    {
      header: 'PAID_AT_VISIT_PHARMACY',
      getValue: claim => getBalance(claim.balance.copay),
    },
    {
      header: 'YOU_OWE',
      getValue: claim => getBalance(claim.balance.youMayOweAmount),
    },
    {
      header: 'FLAGGED_TO_WATCH',
      getValue: claim => (claim.claimManagementInfo && claim.claimManagementInfo.isSaved ? 'true' : 'false'),
    },
    {
      header: 'MARKED_AS_PAID',
      getValue: claim => (claim.claimManagementInfo && claim.claimManagementInfo.markPaid ? 'true' : 'false'),
    },
  ].map(({ header, getValue }) => {
    return {
      header: translationFunc(header),
      getValue,
    };
  });
  const content = getCsvContent(lob == LineOfBusiness.MR ? mrHealthcareCsvFields : healthcareCsvFields, claims);
  downloadCsv(content, 'MedicalClaimSummary.csv');
}

export function downloadFinancialClaims(claims: IFinancialClaim[], translationFunc: (_: string) => string): void {
  const financialCsvFields: ICsvField<IFinancialClaim>[] = [
    {
      header: 'MEMBER_NAME',
      getValue: claim =>
        claim.serviceRecipient ? `${claim.serviceRecipient.firstName} ${claim.serviceRecipient.lastName}` : '',
    },
    {
      header: 'PROVIDER_NAME',
      getValue: claim => (claim.providerName ? claim.providerName : ''),
    },
    {
      header: 'CLAIM_TYPE',
      getValue: claim => claim.accountType,
    },
    {
      header: 'CLAIM_NUMBER',
      getValue: claim => claim.claimNumber,
    },
    {
      header: 'CLAIM_STATUS',
      getValue: claim => translationFunc(claim.claimStatus),
    },
    {
      header: 'DATE_OF_SERVICE',
      getValue: claim => getDate(claim.serviceDate),
    },
    {
      header: 'DATE_PROCESSED',
      getValue: claim => getDate(claim.processedDate),
    },
    {
      header: 'AMOUNT_SUBMITTED',
      getValue: claim => getBalance(claim.balance.amountSubmitted),
    },
    {
      header: 'AMOUNT_PENDING',
      getValue: claim => getBalance(claim.balance.amountPending),
    },
    {
      header: 'AMOUNT_PAID',
      getValue: claim => getBalance(claim.balance.amountPaid),
    },
    {
      header: 'PAID_USING_DEBIT_CARD',
      getValue: claim => (claim.balance && claim.balance.paidWithDebitCard ? 'true' : 'false'),
    },
  ].map(({ header, getValue }) => {
    return {
      header: translationFunc(header),
      getValue,
    };
  });
  const content = getCsvContent(financialCsvFields, claims);
  downloadCsv(content, 'FinancialClaimSummary.csv');
}
