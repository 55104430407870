import summaryTemplate from 'views/claims-and-accounts/summary/summary.html';
import { CoverageType } from '../../../api/api.interfaces';
import { PlansService } from '../../../api/plans/plans.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class SummaryController implements ng.IComponentController {
  public showClaimsNeedAttention: boolean;
  public showDentalSummary: boolean;
  public showMedicalSummary: boolean;
  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private plansService: PlansService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
  }

  public $onInit(): void {
    const currentUser$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser);

    currentUser$.subscribe(currentUser => {
      const medicalCoverage = this.profileService.getCoverage(CoverageType.Medical, currentUser.planCoverages);
      this.showClaimsNeedAttention = !medicalCoverage || !medicalCoverage.planFeatures.phiRestricted;
      this.showMedicalSummary = !!medicalCoverage;
    }, console.warn);

    currentUser$
      .flatMap(currentUser =>
        this.plansService.getBenefitsWithAccumulators(currentUser.rallyId, currentUser.dependentSeqNum),
      )
      .subscribe(usefulBenefits => {
        this.showDentalSummary = !!(usefulBenefits.DENTAL && usefulBenefits.DENTAL.inNetwork);
      }, console.warn);
  }

  public print(): void {
    (window as any).print();
  }
}

export class SummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SummaryController;
    this.templateUrl = summaryTemplate;
  }
}
