import drugTiersTemplate from 'views/modals/drug-tiers.html';
import { CoverageStatus, CoverageTypeCode } from '../../../api/api.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

class DrugTiersController implements ng.IComponentController {
  public hasPdpPlan: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.COSTS);

    userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser.planCoverages)
      .subscribe(coverages => {
        this.hasPdpPlan = coverages.some(
          c => c.coverageTypeCode === CoverageTypeCode.PDP && c.planPeriod.status === CoverageStatus.Active,
        );
      }, console.warn);
  }
}

export class DrugTiersComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = DrugTiersController;
    this.templateUrl = drugTiersTemplate;
  }
}
