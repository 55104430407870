import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimType, IClaim } from 'scripts/api/claims/claims.interfaces';
import { Maybe } from 'scripts/arcade.module.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IMrExplanationBannerProps {
  claims: Maybe<IClaim[]>;
}

const TrackedHospitalIndemnityClaimLink = withClickTracking(Link, 'mr-hospital-indemnity-claim-explanation');
const TrackedMedicareSupplementClaimLink = withClickTracking(Link, 'mr-medicare-supplement-claim-explanation');
const TrackedMedicalClaimLink = withClickTracking(Link, 'mr-medical-claim-explanation');
const TrackedPrescriptionDrugClaimLink = withClickTracking(Link, 'mr-prescription-drug-claim-explanation');

export const MrClaimTermsExplanationBanner: FunctionComponent<IMrExplanationBannerProps> = props => {
  const { claims } = props;
  const { t } = useTranslation(Dictionary.ALL_CLAIMS);

  // TODO in PAY-193: Medical claims can map to three different Explanation of Terms modals based upon
  // the new field claimCoverageTypeDesc. Waiting to get finalized API response values
  // back from optum so leaving Medical, MedicareSupplement, and HospitalIndemnity hardcoded for now.
  // Rx claims will only map to Prescription Drug regardless of claimCoverageTypeDesc.
  // Note: a user should never have both medical + medicareSupplement claims
  // Modals don't exist yet, TODO in PAY-201.
  const medical = false;
  const medicareSupplement = false;
  const hospitalIndemnity = false;
  const prescriptionDrug = claims && claims.some(claim => claim.claimType === ClaimType.Rx);

  return (
    <div className="container explanation-banner" data-testid="mr-claim-terms-explanation-banner">
      {hospitalIndemnity && (
        <div className="explanation-modal-link">
          <TrackedHospitalIndemnityClaimLink
            data-testid="hospital-indemnity-claim-link"
            to="/modal/hospital-indemnity-claim-explanation"
          >
            <span>{t('MR_HOSPTIAL_INDEMNITY_CLAIM_EXPLANATION_TITLE')}</span>
          </TrackedHospitalIndemnityClaimLink>
        </div>
      )}
      {medicareSupplement && (
        <div className="explanation-modal-link">
          <TrackedMedicareSupplementClaimLink
            data-testid="medicare-supplement-claim-link"
            to="/modal/medicare-supplement-claim-explanation"
          >
            <span>{t('MR_MEDICARE_SUPPLEMENT_CLAIM_EXPLANATION_TITLE')}</span>
          </TrackedMedicareSupplementClaimLink>
        </div>
      )}
      {medical && (
        <div className="explanation-modal-link">
          <TrackedMedicalClaimLink data-testid="medical-claim-link" to="/modal/medical-claim-explanation">
            <span>{t('MR_MEDICAL_CLAIM_EXPLANATION_TITLE')}</span>
          </TrackedMedicalClaimLink>
        </div>
      )}
      {prescriptionDrug && (
        <div className="explanation-modal-link">
          <TrackedPrescriptionDrugClaimLink
            data-testid="prescription-drug-claim-link"
            to="/modal/prescription-drug-claim-explanation"
          >
            <span>{t('MR_PRESCRIPTION_DRUG_CLAIM_EXPLANATION_TITLE')}</span>
          </TrackedPrescriptionDrugClaimLink>
        </div>
      )}
    </div>
  );
};
