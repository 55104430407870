import { Observable, Subscription } from 'rxjs';
import CONFIG from 'scripts/util/constants/config';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { getLocale } from 'scripts/util/locale/locale';
import { ILocale } from 'scripts/util/locale/locale.interfaces';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import { IBaseUrls, IPopulation } from 'scripts/util/population/population.interfaces';
import { IPopulationService } from 'scripts/util/population/population.service';
import { getBaseUrls } from 'scripts/util/uri/uri';
import { IProducts, IProfile, IProfileUser } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';

export interface ISharedHeaderFooterFeatureFlags {
  isHeaderFooterPhaseTwoOn: boolean;
  isRallyRxOn: boolean;
  isMRPharmaciesOn: boolean;
  isMRSecureMessagesOn: boolean;
  isExpatriateClaimsLinkOn: boolean;
  isRallyRxInProductEligibilityOn: boolean;
  isEIRecommendationsOn: boolean;
}

export interface ISharedHeaderFooterFeatureConfig {
  isLoggedInUserSelected?: boolean;
  rallyRxPolicies?: string;
  useA11ySkipToMain?: boolean;
}

export class SharedHeaderFooterController implements ng.IComponentController {
  public authenticated: boolean;
  public baseUrls: IBaseUrls;
  public currentLocale: ILocale['id'];
  public featureFlags: ISharedHeaderFooterFeatureFlags;
  public featureConfig: ISharedHeaderFooterFeatureConfig;
  public profileData: IProfile | {};
  public productData: IProducts | {};
  private isLoggedInUserSelected: boolean;
  private loggedInUserDependentSeqNum: IProfileUser['dependentSeqNum'];
  private localeSubscription: Subscription;
  private profileSubscription: Subscription;

  constructor(
    private localeService: ILocaleService,
    public populationService: IPopulationService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';

    this.authenticated = this.authenticated === undefined ? true : this.authenticated;
    this.currentLocale = getLocale().id;
  }

  public $onInit(): void {
    if (this.authenticated) {
      const heartbeat$ = this.userService.getHeartbeat();
      const profile$ = heartbeat$
        .let(this.profileService.toProfile())
        .map(rsp => rsp.data)
        .catch(() => Observable.of({}));
      const selectedUser$ = heartbeat$.let(this.profileService.toCurrentProfile());
      const currentUser$ = heartbeat$.let(this.profileService.toProfile()).map(rsp => rsp.data.currentUser);
      const products$ = currentUser$
        .flatMap(currentUser => this.profileService.getProducts(currentUser.rallyId))
        .map(rsp => rsp.data.products)
        .catch(error => {
          console.warn(error);
          return Observable.of({});
        });
      Observable.zip(profile$, products$, selectedUser$).subscribe(([profile, products, selectedUser]) => {
        this.loggedInUserDependentSeqNum =
          Object.keys(profile).length && (profile as IProfile).currentUser.dependentSeqNum;
        this.isLoggedInUserSelected = this.loggedInUserDependentSeqNum === selectedUser.dependentSeqNum;
        const population = this.populationService.getPopulation();
        const arcadeWebBaseUrlWithPopulation = this.getArcadeWebBaseUrlWithPopulation(population);
        this.baseUrls = {
          arcadeUrl: arcadeWebBaseUrlWithPopulation,
          logoutUrl: arcadeWebBaseUrlWithPopulation + '/logout',
          ...population.baseUrls,
        };

        this.featureConfig = {
          isLoggedInUserSelected: this.isLoggedInUserSelected,
          rallyRxPolicies: CONFIG.ARCADE_WEB_RALLY_RX_POLICIES,
          useA11ySkipToMain: true,
        };

        this.featureFlags = {
          isHeaderFooterPhaseTwoOn: FeatureFlagService.isSharedHeaderFooterPhaseTwoOn(),
          isRallyRxOn: FeatureFlagService.isRallyRxOn(),
          isMRPharmaciesOn: FeatureFlagService.isMRPharmaciesOn(),
          isMRSecureMessagesOn: FeatureFlagService.isMRSecureMessagesOn(),
          isExpatriateClaimsLinkOn: FeatureFlagService.isExpatriateClaimsLinkOn(),
          isRallyRxInProductEligibilityOn: FeatureFlagService.isRallyRxInProductEligibilityOn(),
          isEIRecommendationsOn: FeatureFlagService.isEiRecommendationsOn(),
        };

        this.profileData = profile;
        this.productData = products;
      }, console.warn);

      this.profileSubscription = this.profileService.profileChanged.subscribe(selectedUser => {
        this.isLoggedInUserSelected = this.loggedInUserDependentSeqNum === selectedUser.dependentSeqNum;
        this.featureConfig = {
          ...this.featureConfig,
          isLoggedInUserSelected: this.isLoggedInUserSelected,
        };
      });
    }

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => {
      this.currentLocale = getLocale().id;
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
    this.profileSubscription.unsubscribe();
  }

  private getArcadeWebBaseUrlWithPopulation(pop: IPopulation): string {
    const arcadeWebBaseUrl = getBaseUrls().web;
    const populationPath = pop.uri;

    return arcadeWebBaseUrl + populationPath;
  }
}
