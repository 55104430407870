import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import idx from 'idx';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import {
  dentalPdfForm,
  expatMedicalPdfForm,
  oxfordAccidentalInjuryForm,
  oxfordDisabilityQuestionnaire,
  oxfordOptumRxMailOrderForm,
  oxfordPharmacyReimbursementClaimForm,
  oxfordPriorCarrierDeductibleForm,
  oxfordSweatEquityClaimForm,
  oxfordSweatEquityClaimFormSpanish,
  oxfordSweatEquityWaiverSpanish,
  oxfordSweatEquityWaver,
} from 'scripts/util/resource/resource.constants';
import { CoverageStatus, CoverageType } from 'scripts/api/api.interfaces';
import { MembershipCategory, IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { selectClientConfigData } from 'scripts/selectors/targeting-service-selectors';
import { getProfile } from 'scripts/thunks/profile-service-thunks';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { Feature } from 'scripts/ui/feature/feature';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getCoverage } from 'scripts/util/profile/profile';
import { getResource } from 'scripts/util/resource/resource';
import { LinkToOtherForm, ILinkToOtherFormProps } from './link-to-other-form';

interface ISubmitClaimOtherFormsStateToProps {
  currentUser?: IProfileUser;
  population?: IPopulation;
  clientConfig?: IClientConfig;
}

export interface ISubmitClaimOtherFormsProps extends ISubmitClaimOtherFormsStateToProps {
  getClientConfig: () => void;
  getCurrentUser: () => void;
}

interface ILinkDefinition {
  getHref: (props: ISubmitClaimOtherFormsProps) => string;
  show: (props: ISubmitClaimOtherFormsProps) => boolean;
  text: string;
}

const hasMemberCategory = (category: MembershipCategory): ((props: ISubmitClaimOtherFormsProps) => boolean) => ({
  currentUser,
}: ISubmitClaimOtherFormsProps): boolean => currentUser.membershipCategory === category;
const hasOxfordCategory = hasMemberCategory(MembershipCategory.OXFORD);
const hasExpatCategory = hasMemberCategory(MembershipCategory.EXPATRIATE);

export const LINKS: ILinkDefinition[] = [
  {
    getHref: ({ clientConfig, population }) =>
      idx(clientConfig, _ => _.contentOverrides.customPdfClaimForms.DENTAL_PDF) ||
      getResource(dentalPdfForm, population),
    show: (props: ISubmitClaimOtherFormsProps) => {
      const dentalCoverage = getCoverage(CoverageType.Dental, props.currentUser.planCoverages);
      return (
        !hasExpatCategory(props) &&
        FeatureFlagService.isDentalClaimPDFOn() &&
        dentalCoverage &&
        [CoverageStatus.Active, CoverageStatus.Termed].indexOf(dentalCoverage.planPeriod.status) >= 0
      );
    },
    text: 'DENTAL_CLAIMS_PDF_SUBMISSION_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) =>
      getResource(oxfordPharmacyReimbursementClaimForm, population),
    show: hasOxfordCategory,
    text: 'PHARMACY_REIMBURSEMENT_CLAIM_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordOptumRxMailOrderForm, population),
    show: hasOxfordCategory,
    text: 'OPTUMRX_MAIL_ORDER_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordAccidentalInjuryForm, population),
    show: hasOxfordCategory,
    text: 'ACCIDENTAL_INJURY_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordDisabilityQuestionnaire, population),
    show: hasOxfordCategory,
    text: 'DISABILITY_QUESTIONNAIRE',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordPriorCarrierDeductibleForm, population),
    show: hasOxfordCategory,
    text: 'PRIOR_CARRIER_DEDUCTIBLE_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordSweatEquityClaimForm, population),
    show: hasOxfordCategory,
    text: 'SWEAT_EQUITY_CLAIM_FORM',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordSweatEquityWaver, population),
    show: hasOxfordCategory,
    text: 'SWEAT_EQUITY_WAIVER',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) =>
      getResource(oxfordSweatEquityClaimFormSpanish, population),
    show: hasOxfordCategory,
    text: 'SWEAT_EQUITY_CLAIM_FORM_SPANISH',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(oxfordSweatEquityWaiverSpanish, population),
    show: hasOxfordCategory,
    text: 'SWEAT_EQUITY_WAIVER_SPANISH',
  },
  {
    getHref: ({ population }: ISubmitClaimOtherFormsProps) => getResource(expatMedicalPdfForm, population),
    show: hasExpatCategory,
    text: 'MEDICAL_CLAIMS_PDF_SUBMISSION_FORM',
  },
];

const getCustomLinks = ({ clientConfig }: ISubmitClaimOtherFormsProps): ILinkToOtherFormProps[] =>
  (idx(clientConfig, _ => _.customLabels.customFormsLabels) || []).map(({ label, url }) => ({ href: url, label }));

const getLinks = (props: ISubmitClaimOtherFormsProps): ILinkToOtherFormProps[] =>
  LINKS.filter(link => link.show(props)).map(({ text, getHref }) => ({ label: text, href: getHref(props) }));

export const SubmitClaimOtherFormsComponent: FunctionComponent<ISubmitClaimOtherFormsProps> = props => {
  useEffect(() => {
    if (!props.clientConfig) {
      props.getClientConfig();
    }
  }, []);

  const links = getLinks(props);
  const customLinks = getCustomLinks(props);
  const linksToShow = [...links, ...customLinks].slice(0, 15);

  if (linksToShow.length === 0) {
    return null;
  }

  const linkColumns = [linksToShow.slice(0, 5), linksToShow.slice(5, 10), linksToShow.slice(10, 15)];

  const { t } = useTranslation(Dictionary.SUBMIT_CLAIM);

  return (
    <Feature featureId="submit-claim-other-forms">
      <section className="padded-bottom">
        <div className="container submit-claim-other-forms">
          <h2>{t('OTHER_FORMS')}</h2>
          <div className="submit-claim-other-forms-links-container">
            {linkColumns.map((linkRows, i) => (
              <ul className="submit-claim-other-forms-link-column" key={i}>
                {linkRows.map(linkProps => (
                  <li className="submit-claim-other-forms-link-container" key={`${linkProps.label}`}>
                    <LinkToOtherForm {...linkProps} />
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </section>
    </Feature>
  );
};

const mapStateToProps = (state: IReduxState): ISubmitClaimOtherFormsStateToProps => ({
  currentUser: currentUser.selectProfile(state),
  clientConfig: selectClientConfigData(state),
  population: state.population,
});

export const SubmitClaimOtherForms = withProvider(
  connect(
    mapStateToProps,
    { getClientConfig, getProfile },
  )(SubmitClaimOtherFormsComponent),
);
