export enum AppActionTypes {
  CHANGE_MODAL_OPEN = 'CHANGE_MODAL_OPEN',
}

export interface IChangeModalOpen {
  type: AppActionTypes.CHANGE_MODAL_OPEN;
  payload: boolean;
}

export function changeModalOpen(payload: boolean): IChangeModalOpen {
  return {
    type: AppActionTypes.CHANGE_MODAL_OPEN,
    payload,
  };
}

export type AppAction = IChangeModalOpen;
