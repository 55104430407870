import { ITrackingEventRequest } from 'scripts/api/tracking/tracking.interfaces';
import { trackFeatures } from '../constants/track-features';

export function formatCamelCasedTrackingString(str: string): string {
  return !str ? 'undefined' : str.replace(/([A-Z])/g, match => `-${match[0].toLowerCase()}`);
}

export function formatTrackingString(str: string): string {
  return !str
    ? 'undefined'
    : str
        .toLowerCase()
        .split(' ')
        .join('-')
        .split('_')
        .join('-');
}

export function getPlacement(element: HTMLElement): string {
  const body: HTMLElement = window.document.body;
  const html: HTMLElement = window.document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const offset = element.getBoundingClientRect().top + window.pageYOffset;
  return Math.round((100 * offset) / height) + '%';
}

function getParentsWithTrackFeature(elem: HTMLElement): HTMLElement[] {
  const originalElem = elem;
  const parentElementsWithTrackFeature = [];

  while (elem) {
    if (elem !== originalElem && elem.hasAttribute('track-feature')) {
      parentElementsWithTrackFeature.push(elem);
    }
    elem = elem.parentElement;
  }

  return parentElementsWithTrackFeature;
}

function getTrackFeatureValue(trackFeatureAttribute: string): string {
  const trackFeatureKey = trackFeatureAttribute.substring('$track.features.'.length);
  return trackFeatures[trackFeatureKey];
}

export function getFeatureList(element: HTMLElement): string[] {
  const featureList = [];
  const parentsWithTrackFeature = getParentsWithTrackFeature(element);

  parentsWithTrackFeature.forEach(elem => {
    const trackFeatureAttr = elem.getAttribute('track-feature');
    const trackFeatureValue = getTrackFeatureValue(trackFeatureAttr);
    featureList.push(formatTrackingString(trackFeatureValue));
  });

  return featureList.reverse();
}

export function stringifyData(event: ITrackingEventRequest): void {
  // Currently, data needs to be string for the service to accept the request.
  if (event.data !== undefined && typeof event.data !== 'string') {
    event.data = JSON.stringify(event.data);
  }
}
