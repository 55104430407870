import healthClaimBalanceExplanationTemplate from 'views/modals/health-claim-balance-explanation.html';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { ClaimBalanceType } from 'scripts/api/claims/claims.interfaces';
import { ICustomGlossaryDefinitions } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';

export interface IHealthClaimBalanceExplanationParams extends ng.ui.IStateParamsService {
  balances: Array<ClaimBalanceType>;
  title: string;
}

export const CLAIM_BALANCES = Object.keys(ClaimBalanceType).map(key => ClaimBalanceType[key]);

export class HealthClaimBalanceExplanationController implements ng.IComponentController {
  public customGlossary: ICustomGlossaryDefinitions | {};

  constructor(
    private $stateParams: IHealthClaimBalanceExplanationParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .subscribe(clientConfig => {
        this.customGlossary =
          (clientConfig && clientConfig.contentOverrides && clientConfig.contentOverrides.customGlossaryDefinitions) ||
          {};
      });
  }

  public getBalances(): Array<ClaimBalanceType> {
    const { balances } = this.$stateParams;
    return !balances || balances.length === 0
      ? CLAIM_BALANCES
      : balances.filter((b: string) => CLAIM_BALANCES.indexOf(b) >= 0);
  }
}

export class HealthClaimBalanceExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = HealthClaimBalanceExplanationController;
    this.templateUrl = healthClaimBalanceExplanationTemplate;
  }
}
