import angular from 'angular';
import 'rxjs/add/observable/defer';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/share';
import { NgStore } from 'scripts/store/ng-store';
import '../util/constants/environment.constants';
import { ClaimsService } from './claims/claims.service';
import { LedgerService } from './ledger/ledger.service';
import { PlansService } from './plans/plans.service';
import { ProfileService } from './profile/profile.service';
import { TargetingService } from './targeting/targeting.service';
import { AmplitudeService } from './tracking/amplitude.service';
import { TrackingService } from './tracking/tracking.service';
import { BaseApiService } from 'scripts/api/client/base-api.service';
import { AdvantageApiService } from 'scripts/api/client/advantage-api.service';

angular
  .module('arcade.api', [
    'angular-google-analytics',
    'angularMoment',
    'arcade.environment',
    'pascalprecht.translate',
    'ui.router',
    'ngRedux',
    'ngCookies',
  ])
  .config(NgStore)
  .service('baseApiService', BaseApiService)
  .service('advantageApiService', AdvantageApiService)
  .service('profileService', ProfileService)
  .service('ledgerService', LedgerService)
  .service('claimsService', ClaimsService)
  .service('plansService', PlansService)
  .service('targetingService', TargetingService)
  .service('amplitudeService', AmplitudeService)
  .service('trackingService', TrackingService);
