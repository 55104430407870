import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { activate } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { SUPPRESS_BANNER_KEY } from 'scripts/util/constants/cookies.constant';
import singleStepContainerTemplate from 'views/dashboard/single-step-container.html';
import {
  IActivateStepsResponseSwitch,
  ActivateStepStatus,
  IActivatePlanStep,
  IActivatePlanV7,
  ActivatePlanKey,
} from '../../../api/activate/activate.interfaces';
import { IActivateService } from '../../../api/activate/activate.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';

export enum StepName {
  basicCommunicationPreference = 'basic_communication_preference',
  basicMiniSurvey = 'basic_mini-survey',
  basicPhoneInfo = 'basic_phone_info',
  emailBounce = 'email_bounce',
  cob = 'coordination_of_benefits',
}

export enum NonFtueStepName {
  emailBounce = 'email_bounce',
  cob = 'coordination_of_benefits',
}

const checkNonFtuePlanSuppression = (stepName: string, $cookies: ng.cookies.ICookiesService): boolean => {
  let bannerObj = {};
  if ($cookies.get(SUPPRESS_BANNER_KEY)) {
    bannerObj = JSON.parse($cookies.get(SUPPRESS_BANNER_KEY));
  }
  switch (stepName) {
    case NonFtueStepName.emailBounce:
      return !bannerObj[ActivatePlanKey.EMAIL_BOUNCE];
    case NonFtueStepName.cob:
      return !bannerObj[ActivatePlanKey.COB];
    default:
      return false;
  }
};

const contentConfig = {
  [StepName.basicMiniSurvey]: {
    header: 'ACTIVATE_MINI_SURVEY_HEADER',
    body: 'ACTIVATE_MINI_SURVEY_BODY',
    cta: 'ACTIVATE_MINI_SURVEY_CTA',
  },
  [StepName.basicPhoneInfo]: {
    header: 'ACTIVATE_CONFIRM_CONTACT_HEADER',
    body: 'ACTIVATE_CONFIRM_CONTACT_BODY',
    cta: 'ACTIVATE_CONFIRM_CONTACT_CTA',
  },
  [StepName.basicCommunicationPreference]: {
    header: 'ACTIVATE_EMAIL_PAPERLESS_HEADER',
    body: 'ACTIVATE_EMAIL_PAPERLESS_BODY',
    cta: 'ACTIVATE_EMAIL_PAPERLESS_CTA',
  },
  [StepName.emailBounce]: {
    header: 'ACTIVATE_EMAIL_BOUNCE_HEADER',
    body: 'ACTIVATE_EMAIL_BOUNCE_BODY',
    cta: 'ACTIVATE_EMAIL_BOUNCE_CTA',
  },
  [StepName.cob]: {
    header: 'ACTIVATE_COB_HEADER',
    body: 'ACTIVATE_COB_BODY',
    cta: 'ACTIVATE_COB_CTA',
  },
};

const iconPaths = {
  [StepName.basicMiniSurvey]: '/images/illustrations/Survey-Banner.svg',
  [StepName.basicPhoneInfo]: '/images/illustrations/Phone-Banner.svg',
  [StepName.basicCommunicationPreference]: '/images/illustrations/Paperless-Banner.svg',
  [StepName.emailBounce]: 'images/illustrations/Email-Banner.svg',
  [StepName.cob]: 'images/illustrations/Cob-Banner.svg',
};

export interface ISingleStepContent {
  header: string;
  body: string;
  cta: string;
}

export interface ISingleStep {
  isSkipped: boolean;
  icon: string;
  content: ISingleStepContent | {};
  url?: string;
  stepId: string;
  bannerPriority?: number;
}

export class SingleStepContainerController {
  public activateResourceLink: IResource;
  public singleSteps: (ISingleStep | IActivatePlanStep)[];

  constructor(
    $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private activateService: IActivateService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private userService: IUserService,
    private $cookies: ng.cookies.ICookiesService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activate;
  }

  private stepsFilter(step: IActivatePlanStep, $cookies: ng.cookies.ICookiesService): boolean {
    return (
      (contentConfig[step.stepName] && step.stepState === ActivateStepStatus.skipped) ||
      (checkNonFtuePlanSuppression(step.stepName, $cookies) &&
        step.stepState === ActivateStepStatus.unstarted &&
        !!step.bannerPriority)
    );
  }

  public formatStepsData(step: IActivatePlanStep): ISingleStep {
    return {
      stepId: step.stepName,
      bannerPriority: step.bannerPriority,
      isSkipped: step.stepState === ActivateStepStatus.skipped,
      icon: iconPaths[step.stepName],
      content: contentConfig[step.stepName] || {},
      url: null,
    };
  }

  public createSingleSteps(plans: IActivatePlanV7[], $cookies: ng.cookies.ICookiesService): ISingleStep[] {
    return plans
      .map(plan => plan.steps.filter(step => this.stepsFilter(step, $cookies)).map(this.formatStepsData))
      .reduce((x, y) => x.concat(y), [])
      .sort((x, y) => x.bannerPriority - y.bannerPriority)
      .slice(0, this.Environment.CONFIG.ARCADE_WEB_MAX_NUM_OF_ACTIVATE_INLINE_BANNERS);
  }

  public createSwitchSteps(rsp: IActivateStepsResponseSwitch): ISingleStep[] {
    if (rsp.data.modalBanners && rsp.data.modalBanners.length > 0) {
      return rsp.data.modalBanners
        .map(banner => ({
          bannerPriority: banner.bannerPriority,
          stepId: banner.stepId,
          isSkipped: true,
          icon: iconPaths[banner.stepId],
          content: contentConfig[banner.stepId] || {},
          url: banner.url,
        }))
        .sort((x, y) => x.bannerPriority - y.bannerPriority)
        .slice(0, this.Environment.CONFIG.ARCADE_WEB_MAX_NUM_OF_ACTIVATE_INLINE_BANNERS);
    }
    return [];
  }

  public $onInit(): void {
    if (this.featureFlagService.isActivateSwitchOn()) {
      this.userService
        .getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getStepsSwitch(profile.rallyId))
        .subscribe(rsp => {
          this.singleSteps = this.createSwitchSteps(rsp);
        });
    } else {
      this.userService
        .getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getStepsV7(profile.rallyId))
        .subscribe(rsp => {
          this.singleSteps = this.createSingleSteps(rsp.data.plans, this.$cookies);
        });
    }
  }
}

export class SingleStepContainerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;
  constructor() {
    this.templateUrl = singleStepContainerTemplate;
    this.controller = SingleStepContainerController;
  }
}
