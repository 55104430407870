import { Observable } from 'rxjs/Observable';
import { parse } from 'scripts/util/uri/uri';
import claimVideoTemplate from 'views/modals/claim-video-modal.html';
import {
  ClaimType,
  IClaim,
  IGetMatchingClaimParams,
  IHealthcareClaimDetails,
} from '../../../api/claims/claims.interfaces';
import { IClaimsService } from '../../../api/claims/claims.service';
import { IProviderVideo } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { ISundaySkyClaimSessionParams } from '../../../ui/sunday-sky/sunday-sky-video.interfaces';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';

export interface IClaimVideoParams extends IGetMatchingClaimParams {
  claimVideoFrom: string;
}

export class ClaimVideoController implements ng.IComponentController {
  public claim: IClaim | IHealthcareClaimDetails;
  public modalTitle: string;
  public pageName: string;
  public providerVideo: IProviderVideo;
  public request: Observable<{ claim: IClaim | IHealthcareClaimDetails; providerVideo: IProviderVideo }>;
  public sessionParams: ISundaySkyClaimSessionParams;
  public sundaySkyToken: string;
  public sundaySkyEndpointUrl: string;

  constructor(
    private $stateParams: IClaimVideoParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private Environment: IEnvironmentConstants,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_VIDEO);

    this.sundaySkyToken = this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_TOKEN;
    this.sundaySkyEndpointUrl = this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_ENDPOINT_URL;

    const matchingClaimParams = {
      ...this.$stateParams,
      from: this.$stateParams.claimVideoFrom,
    };

    this.request = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => this.claimsService.getMatchingClaim(profile, matchingClaimParams),
        ({ currentUser }, claim) => ({ currentUser, claim }),
      )
      .do(({ claim }) => {
        this.claim = claim;
        this.modalTitle = this.getModalTitle(claim.claimType);
      })
      .flatMap(
        ({ currentUser, claim }) => this.profileService.getProviderVideoKey(currentUser, claim).map(rsp => rsp.data),
        ({ claim }, providerVideo) => ({ claim, providerVideo }),
      )
      .do(({ claim, providerVideo }) => {
        this.providerVideo = providerVideo;
        this.sessionParams = {
          bigfive: providerVideo.providerVideoKey,
          claimKey: encodeURIComponent(claim.claimKey),
          clmref: claim.claimReference,
        };
      });
  }

  public $onInit(): void {
    const { pathname } = parse(this.$stateParams.claimVideoFrom);
    this.pageName = pathname.split('/').pop();
    this.request.subscribe(() => undefined, console.warn);
  }

  private getModalTitle(claimType: ClaimType): string {
    switch (claimType) {
      case ClaimType.Medical:
        return 'VIDEO_EXPLANATION_FOR_MEDICAL_CLAIM';
      case ClaimType.Dental:
        return 'VIDEO_EXPLANATION_FOR_DENTAL_CLAIM';
      case ClaimType.Rx:
        return 'VIDEO_EXPLANATION_FOR_RX_CLAIM';
      default:
        return 'VIDEO_EXPLANATION_FOR_CLAIM';
    }
  }
}

export class ClaimVideoComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimVideoController;
    this.templateUrl = claimVideoTemplate;
  }
}
