import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { expatSubmitClaims } from 'scripts/util/resource/resource.constants';
import { ClaimFormType } from 'scripts/api/targeting/targeting.interfaces';
import IconMedical from 'images/icons/icon-medical.svg';
import SubmitClaimLink from './submit-claim-link';
import { withCustomTargetingPdf, withTargetingShowOverride } from './submit-claim-utils';
import { ISubmitClaimLinkProps, ISubmitClaimLinksProps } from './submit-claim-links.interface';

export const MedicalOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  clientConfig,
  population,
  t,
}) => {
  if (!withTargetingShowOverride(ClaimFormType.MEDICAL_ONLINE, true)(clientConfig)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={withCustomTargetingPdf('MEDICAL_ONLINE', expatSubmitClaims)(clientConfig, population)}
      Icon={IconMedical}
      text={t(`${Dictionary.SUBMIT_CLAIM}:ONLINE_SUBMISSION`)}
      title={t(`${Dictionary.COMMON}:CLAIMS`)}
    />
  );
};

const ExpatriateSubmitClaimLinks: FunctionComponent<ISubmitClaimLinksProps> = props => {
  const { t } = useTranslation([Dictionary.SUBMIT_CLAIM, Dictionary.COMMON]);
  const linkProps = { ...props, t };
  return <MedicalOnlineSubmitClaimLink {...linkProps} />;
};

export default ExpatriateSubmitClaimLinks;
