import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ClaimStatus, IAnyClaim } from 'scripts/api/claims/claims.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { getClaimAccountAbbreviation, getClaimDetailsUrl, isHealthcareClaim } from 'scripts/util/claims/claims';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export interface IClaimViewLinkProps {
  claim: IAnyClaim;
}

const TrackedLink = withClickTracking(Link, 'view-claim');

export const ClaimViewLink: FunctionComponent<IClaimViewLinkProps> = props => {
  const { claim } = props;
  const { claimStatus } = claim;
  const claimTypeKey = isHealthcareClaim(claim) ? claim.claimType : getClaimAccountAbbreviation(claim.accountType);
  const claimNumber = isHealthcareClaim(claim) ? claim.claimId : claim.claimNumber;
  const claimIsInProcess = claimStatus === ClaimStatus.InProcess;
  const { t } = useTranslation(Dictionary.CLAIM);
  const link = getClaimDetailsUrl(claim);
  const contents = t(`VIEW_${claimTypeKey}_CLAIM`, { claimNumber });

  const inProcess = <span>{contents}</span>;

  const notInProcess = (
    <>
      <TrackedLink className="claim-view-link hide-mobile" noNewWindowIcon={true} to={link} target="_blank">
        {contents}
      </TrackedLink>
      <TrackedLink className="claim-view-link hide-desktop" to={link}>
        {contents}
      </TrackedLink>
    </>
  );

  return claimIsInProcess ? inProcess : notInProcess;
};
