import { ArcadeWebTheme, IEnvironmentConstants } from '../../util/constants/environment.interfaces';

export class ThemeDirective implements ng.IDirective {
  public restrict = 'A';
  public priority = 100;
  public scope = false;
  public transclude: 'element' = 'element';

  constructor(private $animate: ng.animate.IAnimateService, private Environment: IEnvironmentConstants) {}

  /**
   * Implements the directive logic, which takes one or more theme names (string names that match an enum name in the
   * [[ArcadeWebTheme]] enumeration
   * @param scope the current ng Scope.
   * @param element the current element.
   * @param attrs the collection of attributes
   * @param ctrl
   * @param transclude a function that can be used to optionally render the inner content of an element the directive is
   * applied to.
   */
  public link = (scope, element, attrs, ctrl, transclude): void => {
    // Make a map from the list of comma-separated themes in the attribute.
    const themeValueStrings = attrs.theme
      ? attrs.theme.split(',').reduce((hash, theme) => {
          hash[theme.trim().toLowerCase()] = true;
          return hash;
        }, {})
      : {};
    // Match theme strings to actual enum names (not the enum values) from the ArcadeWebTheme enum.
    const themePropertynames = Object.getOwnPropertyNames(ArcadeWebTheme).filter(
      themePropertyName => themeValueStrings[themePropertyName.toLowerCase()],
    );
    const matchesCurrentTheme = themePropertynames.some(
      themePropertyName => ArcadeWebTheme[themePropertyName] === this.Environment.CONFIG.ARCADE_WEB_THEME,
    );
    // Display the nested elements if any of the themes in the "theme" attribute matches the current ARCADE_WEB_THEME
    if (matchesCurrentTheme) {
      transclude(clone => {
        this.$animate.enter(clone, element.parent(), element);
      });
    }
  };

  /**
   * Returns a directive factory which returns a new instance of the ThemeDirective.
   */
  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = (
      $animate: ng.animate.IAnimateService,
      Environment: IEnvironmentConstants,
    ) => {
      'ngInject';
      return new ThemeDirective($animate, Environment);
    };

    return directive;
  }
}
