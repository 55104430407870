import { Observable } from 'rxjs/Observable';
import { connectEditPcp, connectProvider, connectQueryLocalePartner } from 'scripts/util/resource/resource.constants';
import pcpChangeTemplate from 'views/modals/pcp-change.html';
import { CoverageStatus } from '../../../api/api.interfaces';
import {
  IPrimaryCarePhysician,
  IPrimaryCareUserInfo,
  IProfileUser,
  PcpMemberState,
} from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';

export class PcpChangeController implements ng.IComponentController {
  public activePcp?: IPrimaryCarePhysician;
  public futurePcp?: IPrimaryCarePhysician;
  public activePcpLink?: string;
  public futurePcpLink?: string;
  public futurePcpTitle: string;
  public changePcpLink: string;
  public modalTitle: string;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PCP);

    this.changePcpLink = this.resourceService.get(connectEditPcp);
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .flatMap(profile =>
        Observable.if(
          () => profile.memberFeatures.pcpEligible,
          this.getPrimaryCareUserInfo(profile),
          Observable.of(undefined),
        ),
      )
      .subscribe((pcpUserInfo: IPrimaryCareUserInfo) => {
        this.modalTitle = this.activePcp ? 'CHANGE_IN_PROGRESS' : 'ASSIGNMENT_IN_PROGRESS';
        this.futurePcpTitle =
          pcpUserInfo && pcpUserInfo.memberState === PcpMemberState.PcpChangeInProgress
            ? 'PENDING_PRIMARY_CARE_PROVIDER'
            : 'FUTURE_PRIMARY_CARE_PROVIDER';
        this.activePcpLink = this.activePcp ? this.getPcpLink(this.activePcp) : undefined;
        this.futurePcpLink = this.futurePcp ? this.getPcpLink(this.futurePcp) : undefined;
      }, console.warn);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private getPcpLink(pcp: IPrimaryCarePhysician): string {
    return (
      this.resourceService.get(connectProvider) +
      pcp.providerId +
      '%7C' +
      pcp.addressSequenceNumber +
      this.resourceService.get(connectQueryLocalePartner)
    );
  }

  private getPrimaryCareUserInfo(profile: IProfileUser): Observable<IPrimaryCareUserInfo> {
    return this.profileService
      .getPrimaryCare(profile.rallyId)
      .map(pcpInfo => pcpInfo.data.perMemberResults)
      .map(perMemberPcpInfo => perMemberPcpInfo[profile.dependentSeqNum])
      .do(pcpUserInfo => {
        const physicians = pcpUserInfo.primaryCarePhysicians;
        physicians.forEach(pcp => {
          this.activePcp = pcp.status === CoverageStatus.Active ? pcp : this.activePcp;
          this.futurePcp = pcp.status === CoverageStatus.Future ? pcp : this.futurePcp;
        });
      });
  }
}

export class PcpChangeComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PcpChangeController;
    this.templateUrl = pcpChangeTemplate;
  }
}
