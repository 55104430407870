import { AccountType, ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';

export function isHsaAccount(account: ILedgerAccount): boolean {
  return account && AccountType.HSA === account.accountType;
}

export function hasActiveHsaAccount(accounts: ILedgerAccount[]): boolean {
  return accounts && accounts.filter(account => account.isActive).some(account => isHsaAccount(account));
}

export function isDcsaAccount(account: ILedgerAccount): boolean {
  return account && AccountType.DCSA === account.accountType;
}

export function isFsaAccountType(accountType: AccountType): boolean {
  return [AccountType.FSADC, AccountType.FSAHC, AccountType.FSALP].indexOf(accountType) > -1;
}

export function isFsaAccount(account: ILedgerAccount): boolean {
  return account && isFsaAccountType(account.accountType);
}

export function isFsaDcAccount(account: ILedgerAccount): boolean {
  return account && AccountType.FSADC === account.accountType;
}

export function isFsaHcAccount(account: ILedgerAccount): boolean {
  return account && AccountType.FSAHC === account.accountType;
}

export function isHcsaAccount(account: ILedgerAccount): boolean {
  return account && AccountType.HCSA === account.accountType;
}

export function isHraAccountType(accountType: AccountType): boolean {
  return [AccountType.HRA, AccountType.HRAAP, AccountType.HRAPD, AccountType.HRASD].indexOf(accountType) > -1;
}

export function isHraAccount(account: ILedgerAccount): boolean {
  return account && isHraAccountType(account.accountType);
}

export function isJpmcAccountType(accountType: AccountType): boolean {
  return [AccountType.DCSA, AccountType.HCSA, AccountType.MRA].indexOf(accountType) > -1;
}

export function isJpmcAccount(account: ILedgerAccount): boolean {
  return account && isJpmcAccountType(account.accountType);
}

export function isJpmcFsaAccountType(accountType: AccountType): boolean {
  return [AccountType.HCSA, AccountType.DCSA].indexOf(accountType) > -1;
}

export function isJpmcHraAccountType(accountType: AccountType): boolean {
  return [AccountType.MRA].indexOf(accountType) > -1;
}

export function isMraAccount(account: ILedgerAccount): boolean {
  return account && AccountType.MRA === account.accountType;
}
