import axios from 'axios';
import { Observable } from 'rxjs';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { parse } from 'scripts/util/uri/uri';
import activateVideoTranscriptTemplate from 'views/modals/activate-video-transcript.html';
import { IStateParams } from '../../../arcade.module.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { createMapFromWhitelist } from '../../../util/population/population.constants';
import { IProfile } from './../../../api/profile/profile.interfaces';

interface ITranscriptSection {
  header: string;
  content: string;
}

export class ActivateVideoTranscriptController implements ng.IComponentController {
  public person: string;
  public request: Observable<[ITranscriptSection[], IProfile]>;
  public transcriptSections: ITranscriptSection[];

  constructor(
    private $stateParams: IStateParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACTIVATE_VIDEO_TRANSCRIPT);

    const httpClient = axios.create({
      headers: {},
      withCredentials: false,
      xsrfHeaderName: null,
      xsrfCookieName: null,
    });

    const transcriptDomainWhitelist = createMapFromWhitelist(
      Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_TRANSCRIPT_WHITELIST,
    );

    const matchesWhitelist = (): boolean => {
      const anchor = parse($stateParams.transcriptUrl);
      const hostname = anchor.hostname;
      return !!transcriptDomainWhitelist[hostname];
    };

    const transcriptRequest = Observable.if(
      () => matchesWhitelist(),
      httpClient.get<string>($stateParams.transcriptUrl),
      Observable.throw(new Error(`${$stateParams.transcriptUrl}'s domain is not whitelisted`)),
    )
      .map(response => response.data)
      .flatMap(this.parseTranscript);

    const profileRequest = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data);

    this.request = Observable.combineLatest(transcriptRequest, profileRequest);
  }

  public $onInit(): void {
    this.request.subscribe(([transcript, profile]) => {
      this.person = profile.currentUser.userInfo.firstName;
      this.transcriptSections = transcript;
    }, console.warn);
  }

  public goBack(): void {
    this.$window.history.back();
  }

  private parseTranscript(transcript: string): Observable<ITranscriptSection[]> {
    const descriptionBlocks = transcript.split('Description:');
    return Observable.from(descriptionBlocks)
      .filter(s => !!s.trim())
      .map(block => {
        const [description, narrator] = block.split('Narrator:');
        return [
          {
            header: 'Description:',
            content: description,
          } as ITranscriptSection,
          {
            header: 'Narrator:',
            content: narrator,
          } as ITranscriptSection,
        ];
      })
      .flatMap(sections => Observable.from(sections))
      .toArray();
  }
}

export class ActivateVideoTranscriptComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ActivateVideoTranscriptController;
    this.templateUrl = activateVideoTranscriptTemplate;
  }
}
