import { Observable } from 'rxjs/Rx';
import { ICommunicationPreferences, IProfileResponse } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';

export interface ICommunicationPreferenceService {
  communicationEmail: string;
  choosePaperlessOption(isPaperless: boolean): Observable<IProfileResponse>;
}

export class CommunicationPreferenceService implements ICommunicationPreferenceService {
  public communicationEmail: string;

  constructor(
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.onboardingSteps.communicationPreference)
      .do(communicationPreference => {
        this.communicationEmail = communicationPreference.communicationEmail;
      })
      .subscribe();
  }

  public choosePaperlessOption(isPaperless: boolean): Observable<IProfileResponse> {
    const preferences: ICommunicationPreferences = {
      hasAgreedToPaperlessCommunication: isPaperless,
      paperlessEmailAddress: isPaperless ? this.communicationEmail : undefined,
    };

    return this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .flatMap(currentUser =>
        this.profileService
          .setCommunicationPreferences(currentUser.rallyId, preferences)
          .flatMap(() => this.profileService.get(currentUser.rallyId, true)),
      )
      .do(() => {
        this.$timeout(() => {
          this.$state.go('authenticated.dashboard', {
            onboardingProfileUpdated: true,
          });
        });
      });
  }
}
