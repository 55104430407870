import { hasPayNowSuppression, hasRallypayRolloutEnabledSuppression } from 'scripts/util/targeting/targeting';
import React, { FunctionComponent } from 'react';
import { ClaimPayNowAnchorLink } from './claim-pay-now-anchor-link';
import { ClaimPayNowAnchorModal } from './claim-pay-now-anchor-modal';
import { connect } from 'react-redux';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { hasInstamed as determineHasInstamed } from 'scripts/util/profile/profile';
import { showPayNow as determineShowPayNow } from 'scripts/util/claims/claims';
import CONFIG from 'scripts/util/constants/config';
import { ArcadeWebTheme } from 'scripts/util/constants/environment.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { hasActiveHsaAccount } from 'scripts/util/ledger/ledger';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { useTranslation } from 'react-i18next';

interface IClaimPayNowProps {
  accounts: ILedgerAccount[];
  claim: IClaim;
  clientConfig: IClientConfig;
  hasInstamed: boolean;
}

export const RawClaimPayNow: FunctionComponent<IClaimPayNowProps> = props => {
  const { accounts, claim, clientConfig, hasInstamed } = props;
  const { claimId, claimPayKey } = claim;
  const { t } = useTranslation(Dictionary.COMMON);
  const {
    location: { search },
  } = useRouter();
  const hasHsa = hasActiveHsaAccount(accounts);
  const isPayNowSuppressed = hasPayNowSuppression(clientConfig);

  const showPayNow =
    determineShowPayNow(claim, new URLSearchParams(search).get('dependentSeqNbr')) &&
    !isPayNowSuppressed &&
    !!hasInstamed;
  const isPayNowModalOn = FeatureFlagService.isPayNowModalOn();

  const isRallypayTargetingRolloutEnabled = hasRallypayRolloutEnabledSuppression(clientConfig);
  const isTargetingRolloutFeatureFlagOn = FeatureFlagService.isPayNowModalTargetingRolloutOn();
  const showModalBasedOnTargeting = isTargetingRolloutFeatureFlagOn && isRallypayTargetingRolloutEnabled;

  const showPayNowModal = hasHsa && isPayNowModalOn && (!isTargetingRolloutFeatureFlagOn || showModalBasedOnTargeting);
  const showInstamedLink = CONFIG.ARCADE_WEB_THEME !== ArcadeWebTheme.Advantage;
  const payNowMsg = t('PAY_NOW');

  return (
    showPayNow &&
    (showPayNowModal ? (
      <ClaimPayNowAnchorModal claimId={claimId} claimPayKey={claimPayKey} payNowMsg={payNowMsg} />
    ) : (
      showInstamedLink && <ClaimPayNowAnchorLink claimPayKey={claimPayKey} payNowMsg={payNowMsg} />
    ))
  );
};

export default connect((state: IReduxState) => ({
  hasInstamed: determineHasInstamed(currentUser.selectProfile(state)),
}))(RawClaimPayNow);
