import eobErrorsTemplate from 'views/modals/eob-error.html';
import { ClaimType, IClaim } from '../../../api/claims/claims.interfaces';
import { IClaimsService } from '../../../api/claims/claims.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export interface IEobErrorParams extends ng.ui.IStateParamsService {
  claimKey: string;
  claimType: ClaimType;
  claimReference: string;
  eobErrorFrom: string;
  errorUID: string;
}

export class EobErrorController implements ng.IComponentController {
  public errorUID: string;

  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: IEobErrorParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderProvider,
    private claimsService: IClaimsService,
  ) {
    'ngInject';

    $translatePartialLoader.addPart(Dictionary.ERRORS);

    this.errorUID = this.$stateParams.errorUID;
  }

  public getClaimUrl(): string {
    const claim = {
      claimKey: this.$stateParams.claimKey,
      claimType: this.$stateParams.claimType,
      claimReference: this.$stateParams.claimReference,
    };
    const { eobErrorFrom } = this.$stateParams;
    const claimDetailsUrl = this.$state.href(this.$state.get('authenticated.claimsAndAccounts.claimDetails'));
    if (eobErrorFrom && eobErrorFrom.indexOf(claimDetailsUrl) === 0) {
      return eobErrorFrom;
    } else {
      return this.claimsService.getClaimDetailsUrl(claim as IClaim, this.$stateParams.eobErrorFrom);
    }
  }
}

export class EobErrorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = EobErrorController;
    this.templateUrl = eobErrorsTemplate;
  }
}
