import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import {
  SUNDAY_SKY_VIDEO_PROGRESS_ACTION_NAME,
  SUNDAY_SKY_VIDEO_START_ACTION_NAME,
} from 'scripts/ui/sunday-sky/sunday-sky-track.controller';
import { AMP_DEVICE_ID_COOKIE_KEY, AMP_SESSION_ID_COOKIE_KEY } from 'scripts/util/constants/cookies.constant';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { formatTrackingString } from 'scripts/util/tracking/tracking-helper';
import { IArcadeState } from '../../arcade.module.interfaces';
import { IEnvironmentConstants, ArcadeWebTheme } from '../../util/constants/environment.interfaces';
import { CoverageStatus, CoverageType, CoverageTypeCode } from '../api.interfaces';
import { Gender, IProfile, IProfileUser, LineOfBusiness } from '../profile/profile.interfaces';
import {
  AmpEventPropNames,
  AmpEventTrigger,
  AmpEventType,
  AmpUserPropNames,
  IAmpEventData,
  IAmplitudeInstance,
  IAmpRootScope,
  IAmpSundaySkyEventData,
  IAmpUserData,
  ILastTrackedPageInfo,
} from './amplitude.interfaces';
import { ITrackingEventRequest, IVideoTrackingEvent, TrackingClickType } from './tracking.interfaces';

const AmpProductName = 'DHP';
const AmpAppName = 'arcade-ui';
const AmpAdvantageAppName = 'advantage-arcade-ui';

export interface IAmplitudeService {
  ampInstance: IAmplitudeInstance;
  lastStateChangeEventProps: IAmpEventData;
  init(rallyId: string): void;
  registerClickEvent(clickEvent: ITrackingEventRequest): void;
  registerVideoEvent(clickEvent: IVideoTrackingEvent): void;
  setBaseUserProps(profile: IProfile): void;
  sendStateChangeEvent(fromState: IArcadeState, toState: IArcadeState): void;
}

export class AmplitudeService implements IAmplitudeService {
  public ampInstance: IAmplitudeInstance;
  public lastStateChangeEventProps: IAmpEventData;
  private baseEventProps: IAmpEventData;
  private directNavToModalIntermediateState: string;
  private possibleNewAmpSession = true;
  private isAdvantage: boolean;

  constructor(
    private $cookies: ng.cookies.ICookiesService,
    private $rootScope: IAmpRootScope,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
  ) {
    'ngInject';
  }

  public init(rallyId: string): void {
    if (this.$window.amplitude) {
      this.ampInstance = this.$window.amplitude.getInstance();
      this.ampInstance.setUserId(rallyId);
      this.ampInstance.setVersionName(`${this.Environment.CONFIG.ARCADE_WEB_VERSION} ${AmpAppName}`);
      this.isAdvantage = this.Environment.CONFIG.ARCADE_WEB_THEME === ArcadeWebTheme.Advantage;
      this.useOrSetDeviceIdCookie();
      this.setBaseEventProps();

      this.$window.onpopstate = () => {
        this.sendNonClickNavEvent(AmpEventTrigger.BrowserNav);
      };
    }
  }

  public registerClickEvent(clickEvent: ITrackingEventRequest): void {
    if (!this.ampInstance || !this.baseEventProps) {
      return;
    }
    const eventProps = this.getEventPropsWithTriggerInfo(clickEvent);
    if (clickEvent.clickType === TrackingClickType.StateChange) {
      this.lastStateChangeEventProps = eventProps;
    } else if (clickEvent.clickType === TrackingClickType.ExternalLink) {
      this.logEvent(AmpEventType.ExternalNav, eventProps);
    } else if (clickEvent.actionName === 'print' && this.$state.current.name === 'modal.idCards') {
      this.logEvent(AmpEventType.IdCardPrinted, eventProps);
    } else if (clickEvent.featureList.indexOf('content-hopper') !== -1) {
      const contentHopperEventType = this.getContentHopperEventType(clickEvent.actionName);
      this.logEvent(contentHopperEventType, eventProps);
    } else if (clickEvent.actionName === 'keyword-filter' && clickEvent.featureList.indexOf('all-claims')) {
      this.logEvent(AmpEventType.ClaimsSearchBoxClicked, eventProps);
    } else if (
      clickEvent.actionName === formatTrackingString(AmpEventType.RallyPayPaymentCompleted) &&
      clickEvent.featureList.indexOf(trackFeatures.payNow) >= 0
    ) {
      this.logEvent(AmpEventType.RallyPayPaymentCompleted, eventProps);
    }
  }

  public registerVideoEvent(videoEvent: IVideoTrackingEvent): void {
    const eventProps = this.getSundaySkyEventProps(videoEvent);
    if (videoEvent.actionName === SUNDAY_SKY_VIDEO_START_ACTION_NAME) {
      this.logEvent(AmpEventType.PageNav, eventProps);
    } else if (videoEvent.actionName === SUNDAY_SKY_VIDEO_PROGRESS_ACTION_NAME) {
      this.logEvent(AmpEventType.OnboardingMilestoneCompleted, eventProps);
    }
  }

  public setBaseUserProps(profile: IProfile): void {
    if (!this.ampInstance) {
      return;
    }
    const coverageLists = this.getCoverageLists(profile);
    const baseUserProps: IAmpUserData = {
      [AmpUserPropNames.product]: AmpProductName,
      [AmpUserPropNames.authenticated]: 'true',
      [AmpUserPropNames.advantageSession]: this.isAdvantage.toString(),
      [AmpUserPropNames.gender]: this.getGender(profile.currentUser.userInfo.gender),
      [AmpUserPropNames.policyNum]: profile.currentUser.primaryPolicyNumber,
      [AmpUserPropNames.clientId]: profile.currentUser.userInfo.primaryCustomerId,
      [AmpUserPropNames.lineOfBusiness]: profile.currentUser.lineOfBusiness,
      [AmpUserPropNames.membershipCategory]: profile.currentUser.membershipCategory,
      [AmpUserPropNames.coverageStatus]: this.getCoverageStatus(profile.currentUser),
      [AmpUserPropNames.relationshipType]: profile.currentUser.relationshipType,
      [AmpUserPropNames.age]: this.getAgeRange(profile.currentUser.userInfo.age),
      [AmpUserPropNames.coverageType]: coverageLists.types,
      [AmpUserPropNames.coverageTypeCode]: coverageLists.typeCodes,
      [AmpUserPropNames.pcpRequiredByPlan]: profile.currentUser.memberFeatures.pcpEligible,
    };
    if (profile.currentUser.pvrc) {
      baseUserProps[AmpUserPropNames.variationCode] = profile.currentUser.pvrc.planVariationCode;
    }

    this.ampInstance.setUserProperties(baseUserProps);
  }

  public sendStateChangeEvent(fromState: IArcadeState, toState: IArcadeState): void {
    if (!this.ampInstance) {
      return;
    }
    if (toState.name === 'logout') {
      if (fromState.data.uuid === this.lastStateChangeEventProps[AmpEventPropNames.triggerPageNavId]) {
        // for "active" (i.e. non-timeout) logouts, send the custom Logout event
        this.logEvent(AmpEventType.Logout, this.lastStateChangeEventProps);
      }
      this.logout();
    }

    this.$timeout(() => {
      const lastTrackedPageInfo: ILastTrackedPageInfo = this.$rootScope.lastTrackedPageInfo;
      if (
        fromState.name.split('.')[0] === 'modal' &&
        lastTrackedPageInfo &&
        this.$window.location.pathname === lastTrackedPageInfo.path.split('/modal')[0] &&
        (!this.lastStateChangeEventProps ||
          this.lastStateChangeEventProps[AmpEventPropNames.triggerElementName].indexOf('paperless') === -1)
      ) {
        return;
      }

      if (fromState.name === '') {
        if (this.$window.location.pathname.indexOf('/modal/') > -1 && toState.name.split('.')[0] !== 'modal') {
          // ARC-6280: for a direct nav to a modal, 2 state changes will be registered:
          // #1 with empty fromState and with toState as the modal's parent state, then
          // #2 with fromState as the parent state and toState as the modal state
          this.directNavToModalIntermediateState = toState.name;
        } else {
          this.sendNonClickNavEvent(AmpEventTrigger.DirectNav);
        }
      } else if (fromState.name === this.directNavToModalIntermediateState && toState.name.split('.')[0] === 'modal') {
        this.directNavToModalIntermediateState = null;
        this.sendNonClickNavEvent(AmpEventTrigger.DirectNav);
      } else if (toState.name === 'modal.inactive') {
        this.sendNonClickNavEvent(AmpEventTrigger.Auto);
        // ensure that 1. the state is one that we intend to track (existence of toState.data.name)
        // and 2. the uuid matches the one we were expecting based on the last recorded click event
      } else if (
        fromState.name !== toState.name &&
        toState.data &&
        toState.data.name &&
        this.lastStateChangeEventProps &&
        fromState.data.uuid === this.lastStateChangeEventProps[AmpEventPropNames.triggerPageNavId]
      ) {
        this.lastStateChangeEventProps[AmpEventPropNames.uri] = this.$window.location.pathname;
        this.lastStateChangeEventProps[AmpEventPropNames.pageName] = toState.data.name;
        this.lastStateChangeEventProps[AmpEventPropNames.pageTags] = toState.data.tags || [];
        this.lastStateChangeEventProps[AmpEventPropNames.pageNavId] = toState.data.uuid;
        this.setPropIfModal(this.lastStateChangeEventProps, this.$window.location.pathname);

        // copy the event props to avoid possible race conditions
        const eventProps = angular.copy(this.lastStateChangeEventProps);
        this.setPageArgsAndLogEvent(AmpEventType.PageNav, eventProps, toState);
      }
    });
  }

  private logout(): void {
    this.ampInstance.setUserProperties({ [AmpUserPropNames.authenticated]: 'false' });
    this.ampInstance.setUserId(null);
    this.$cookies.remove(AMP_DEVICE_ID_COOKIE_KEY);
    this.ampInstance.regenerateDeviceId();
  }

  private setBaseEventProps(): void {
    this.baseEventProps = {
      [AmpEventPropNames.domain]: this.$window.location.hostname,
      [AmpEventPropNames.product]: AmpProductName,
      [AmpEventPropNames.environment]: this.Environment.CONFIG.ARCADE_WEB_ENVIRONMENT_NAME,
      [AmpEventPropNames.appVersion]: this.Environment.CONFIG.ARCADE_WEB_VERSION,
      [AmpEventPropNames.appName]: this.isAdvantage ? AmpAdvantageAppName : AmpAppName,
    };
  }

  private setPreviousPageProps(eventProps: IAmpEventData, setTriggerPageProps?: boolean): void {
    const lastTrackedPageInfo: ILastTrackedPageInfo = this.$rootScope.lastTrackedPageInfo;
    if (lastTrackedPageInfo) {
      eventProps[AmpEventPropNames.previousPageNavId] = lastTrackedPageInfo.data.uuid;
      eventProps[AmpEventPropNames.previousUri] = lastTrackedPageInfo.path;
      eventProps[AmpEventPropNames.previousPageName] = lastTrackedPageInfo.data.name;
      eventProps[AmpEventPropNames.previousDomain] = this.$window.location.hostname;
      if (setTriggerPageProps) {
        eventProps[AmpEventPropNames.triggerPageNavId] = lastTrackedPageInfo.data.uuid;
        eventProps[AmpEventPropNames.triggerUri] = lastTrackedPageInfo.path;
        eventProps[AmpEventPropNames.triggerPageName] = lastTrackedPageInfo.data.name;
        eventProps[AmpEventPropNames.triggerPageTags] = lastTrackedPageInfo.data.tags || [];
      }
    }
  }

  private getEventPropsWithTriggerInfo(event: ITrackingEventRequest): IAmpEventData {
    const pageName = this.$state.current.data && this.$state.current.data.name;
    if (pageName) {
      const formattedActionName = this.removeSpecialCharactersFromActionName(event.actionName);
      const triggerEvent = AmpEventTrigger.Click;
      const triggerElement = `${event.featureList.join('.')}[${formattedActionName}]`;
      const pageTags = (this.$state.current.data && this.$state.current.data.tags) || [];
      const pageNavId = this.$state.current.data && this.$state.current.data.uuid;
      const eventProps = angular.copy(this.baseEventProps);

      eventProps[AmpEventPropNames.triggerEvent] = triggerEvent;
      eventProps[AmpEventPropNames.triggerSection] = event.featureList;
      eventProps[AmpEventPropNames.triggerElementName] = formattedActionName;
      eventProps[AmpEventPropNames.triggerElement] = triggerElement;
      eventProps[AmpEventPropNames.triggerSummary] = `${triggerEvent} ${triggerElement}`;
      eventProps[AmpEventPropNames.triggerSummaryExtended] = `${triggerEvent} ${pageName} ${triggerElement}`;
      eventProps[AmpEventPropNames.triggerUri] = this.$window.location.pathname;
      eventProps[AmpEventPropNames.triggerPageName] = pageName;
      eventProps[AmpEventPropNames.triggerPageTags] = pageTags;
      eventProps[AmpEventPropNames.triggerPageNavId] = pageNavId;

      if (event.clickType === TrackingClickType.StateChange) {
        this.setPreviousPageProps(eventProps);
        eventProps[AmpEventPropNames.newSession] = false;
      } else {
        eventProps[AmpEventPropNames.uri] = this.$window.location.pathname;
        eventProps[AmpEventPropNames.pageName] = pageName;
        eventProps[AmpEventPropNames.pageTags] = pageTags;
        eventProps[AmpEventPropNames.pageNavId] = pageNavId;
        this.setPropIfModal(eventProps, this.$window.location.pathname);
        if (event.clickType === TrackingClickType.ExternalLink) {
          eventProps[AmpEventPropNames.externalDomain] = event.externalDomain;
          eventProps[AmpEventPropNames.externalUrl] = this.getGroomedExternalUrl(
            event.externalUrl,
            formattedActionName,
          );
        }
      }

      if (typeof event.additionalProperties === 'object') {
        Object.keys(event.additionalProperties).forEach(propName => {
          const ampPropName = AmpEventPropNames[propName];
          if (ampPropName) {
            eventProps[ampPropName] = event.additionalProperties[propName];
          }
        });
      }

      return eventProps;
    }
  }

  private getSundaySkyEventProps(event: IVideoTrackingEvent): IAmpSundaySkyEventData {
    const pageName = this.$state.current.data && this.$state.current.data.name;
    if (pageName) {
      const formattedActionName = this.removeSpecialCharactersFromActionName(event.actionName);
      const triggerEvent = event.videoData.playStartTriggered ? AmpEventTrigger.Click : AmpEventTrigger.Auto;
      const triggerElement = `${event.featureList.join('.')}[${formattedActionName}]`;
      const pageTags = (this.$state.current.data && this.$state.current.data.tags) || [];
      const pageNavId = this.$state.current.data && this.$state.current.data.uuid;
      const eventProps = angular.copy(this.baseEventProps);

      eventProps[AmpEventPropNames.triggerEvent] = triggerEvent;
      eventProps[AmpEventPropNames.triggerSection] = event.featureList;
      eventProps[AmpEventPropNames.triggerElementName] = formattedActionName;
      eventProps[AmpEventPropNames.triggerElement] = triggerElement;
      eventProps[AmpEventPropNames.triggerSummary] = `${triggerEvent} ${triggerElement}`;
      eventProps[AmpEventPropNames.triggerSummaryExtended] = `${triggerEvent} ${pageName} ${triggerElement}`;
      eventProps[AmpEventPropNames.triggerUri] = this.$window.location.pathname;
      eventProps[AmpEventPropNames.triggerPageName] = pageName;
      eventProps[AmpEventPropNames.triggerPageTags] = pageTags;
      eventProps[AmpEventPropNames.triggerPageNavId] = pageNavId;
      eventProps[AmpEventPropNames.videoName] = event.videoData.videoName;
      if (event.videoData.playStartTriggered) {
        eventProps[AmpEventPropNames.playStartTriggered] = event.videoData.playStartTriggered;
      }
      if (event.videoData.videoProgress) {
        eventProps[AmpEventPropNames.videoProgress] = event.videoData.videoProgress;
      }

      return eventProps;
    }
  }

  private sendNonClickNavEvent(eventTrigger: AmpEventTrigger): void {
    const currentState: IArcadeState = this.$state.current;
    const pageName = currentState.data && currentState.data.name;
    if (pageName) {
      const pageTags = (currentState.data && currentState.data.tags) || [];
      const pageNavId = currentState.data && currentState.data.uuid;
      const eventProps = angular.copy(this.baseEventProps);

      eventProps[AmpEventPropNames.newSession] = this.isNewAmpSession();
      eventProps[AmpEventPropNames.triggerEvent] = eventTrigger;
      eventProps[AmpEventPropNames.triggerSummary] = eventTrigger;
      eventProps[AmpEventPropNames.triggerSummaryExtended] = eventTrigger;
      eventProps[AmpEventPropNames.uri] = this.$window.location.pathname;
      eventProps[AmpEventPropNames.pageName] = pageName;
      eventProps[AmpEventPropNames.pageTags] = pageTags;
      eventProps[AmpEventPropNames.pageNavId] = pageNavId;
      this.setPropIfModal(eventProps, this.$window.location.pathname);

      if (eventTrigger === AmpEventTrigger.Auto) {
        this.setPreviousPageProps(eventProps, true);
      }

      this.setPageArgsAndLogEvent(AmpEventType.PageNav, eventProps, currentState);
    }
  }

  private setPropIfModal(eventProps: IAmpEventData, path: string): void {
    if (path.indexOf('/modal/') > -1) {
      eventProps[AmpEventPropNames.isModal] = true;
    }
  }

  private setPageArgsAndLogEvent(eventType: AmpEventType, eventProps: IAmpEventData, toState: IArcadeState): void {
    const keys = toState.data.ampKeys;
    const waitForValues = toState.data.ampWaitForValues;

    const propsWithPageArgs$ =
      waitForValues &&
      waitForValues.map(values => {
        const pageArgs = [];
        for (let i = 0; i < values.length; i++) {
          if (values[i] !== null) {
            pageArgs.push(`${keys[i]}=${values[i]}`);
          }
        }
        eventProps[AmpEventPropNames.pageArgs] = pageArgs;
        return eventProps;
      });

    const updatedProps = Observable.if(() => !!(keys && waitForValues), propsWithPageArgs$, Observable.of(eventProps));
    updatedProps.subscribe(
      props => {
        this.logEvent(eventType, props);
      },
      () => {
        this.logEvent(eventType, eventProps);
      },
    );
  }

  private logEvent(type: AmpEventType, props: IAmpEventData): void {
    this.extendAmpSessionCookie();
    this.ampInstance.logEvent(type, props);
  }

  private isNewAmpSession(): boolean {
    // possibleNewAmpSession is initially true and set to false the first time this function gets called.
    // Also, the first time this function is called, the amplitude session ID cookie will be set if it does
    // not exist and if it does exist then the cookie's value will be used as the amplitude session ID.
    if (!this.possibleNewAmpSession) {
      return false;
    }
    this.possibleNewAmpSession = false;

    if (!this.ampInstance.getSessionId) {
      // if this warning occurs in non-local environments, then session tracking likely isn't working properly
      console.warn('getSessionId does not yet exist on the amplitude instance');
      return;
    }

    const sessionIdFromCookie = this.$cookies.get(AMP_SESSION_ID_COOKIE_KEY);
    if (sessionIdFromCookie) {
      if (sessionIdFromCookie !== this.ampInstance.getSessionId().toString()) {
        this.ampInstance.setSessionId(parseInt(sessionIdFromCookie, 10));
      }
      return false;
    } else {
      this.extendAmpSessionCookie();
      return true;
    }
  }

  private useOrSetDeviceIdCookie(): void {
    const deviceIdFromCookie = this.$cookies.get(AMP_DEVICE_ID_COOKIE_KEY);

    if (deviceIdFromCookie) {
      this.ampInstance.setDeviceId(deviceIdFromCookie);
    } else if (this.ampInstance && this.ampInstance.options) {
      this.$cookies.put(AMP_DEVICE_ID_COOKIE_KEY, this.ampInstance.options.deviceId);
    }
  }

  private extendAmpSessionCookie(): void {
    if (this.ampInstance && this.ampInstance.getSessionId) {
      // extend the cookie's life by 30 min (by default, more than 30 min between events will result in a new amplitude
      // session, according to their docs https://amplitude.zendesk.com/hc/en-us/articles/115002323627-Tracking-Sessions)
      this.$cookies.put(AMP_SESSION_ID_COOKIE_KEY, this.ampInstance.getSessionId().toString(), {
        expires: new Date(Date.now() + 1800000),
      });
    }
  }

  private getCoverageLists(profile: IProfile): { types: CoverageType[]; typeCodes: CoverageTypeCode[] } {
    const coverageLists = {
      types: [],
      typeCodes: [],
    };
    const activeCoverages = profile.currentUser.planCoverages.filter(
      c => c.planPeriod.status === CoverageStatus.Active,
    );
    activeCoverages.forEach(coverage => {
      coverageLists.types.push(coverage.coverageType);
      for (const subCoverageType of coverage.additionalCoverageTypes) {
        coverageLists.types.push(subCoverageType);
      }
      if (coverage.coverageTypeCode) {
        coverageLists.typeCodes.push(coverage.coverageTypeCode);
      }
    });
    return coverageLists;
  }

  // Get rid of any IDs that appear in the URL to avoid having tons of unique URLs sent to amplitude
  // Since there are only 2 track values we need to watch out for, this approach seems okay for now...
  private getGroomedExternalUrl(externalUrl: string, trackId: string): string {
    if (trackId === 'explanation-of-benefits') {
      return externalUrl.replace(/\/u\/.*\/eob\/.*/, '/u/***/eob/***');
    } else if (trackId === 'download-all-claims') {
      return externalUrl.replace(/\/u\/.*/, '/u/***');
    } else {
      return externalUrl;
    }
  }

  private getGender(gender: string): string {
    if (gender === Gender.male || gender === Gender.female) {
      return gender.toLowerCase();
    } else {
      return 'unspecified';
    }
  }

  private getAgeRange(age: number): string {
    if (age < 18) {
      return '<18';
    } else if (age >= 18 && age < 25) {
      return '18-24';
    } else if (age >= 25 && age < 35) {
      return '25-34';
    } else if (age >= 35 && age < 45) {
      return '35-44';
    } else if (age >= 45 && age < 55) {
      return '45-54';
    } else if (age >= 55 && age < 65) {
      return '55-64';
    } else if (age >= 65) {
      return '65+';
    }
  }

  private getCoverageStatus(user: IProfileUser): string {
    const coverageStatusToAmpCoverageStatus = {
      [CoverageStatus.Future]: 'Pre-Effective',
      [CoverageStatus.Active]: 'Active',
      [CoverageStatus.Termed]: 'Termed',
    };

    if (user.lineOfBusiness === LineOfBusiness.MR) {
      if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Active)) {
        return coverageStatusToAmpCoverageStatus[CoverageStatus.Active];
      } else if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Future)) {
        return coverageStatusToAmpCoverageStatus[CoverageStatus.Future];
      } else if (user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Termed)) {
        return coverageStatusToAmpCoverageStatus[CoverageStatus.Termed];
      }
    } else {
      for (const cov of user.planCoverages) {
        if (cov.coverageType === CoverageType.Medical) {
          return coverageStatusToAmpCoverageStatus[cov.planPeriod.status];
        }
      }
    }
  }

  private getContentHopperEventType(actionName: string): AmpEventType {
    // actionName gets formatted this way in the formatTrackingString fn in the tracking service, the originals are in content-hopper.json
    switch (actionName) {
      case 'ch-incentives':
        return AmpEventType.HopperIncentives;
      case 'ch-costs':
        return AmpEventType.HopperCosts;
      case 'ch-account-summary':
        return AmpEventType.HopperAccountSummary;
      case 'ch-recent-claims':
        return AmpEventType.HopperRecentClaims;
      case 'ch-link-bar':
        return AmpEventType.HopperLinkBar;
      case 'ch-claims-accounts-summary-account-summary':
        return AmpEventType.HopperClaimsAccountSummary;
      case 'ch-claims-accounts-summary-links':
        return AmpEventType.HopperClaimsAccountSummaryLinks;
      default:
        return AmpEventType.HopperDefault;
    }
  }

  private removeSpecialCharactersFromActionName(actionName: string): string {
    const specialCharacterRegex = /[^a-z\d-]/gm;
    return actionName.replace(specialCharacterRegex, '');
  }
}
