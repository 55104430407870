import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { CoverageType } from 'scripts/api/api.interfaces';
import { ICoverageSection } from './id-cards.interfaces';
import { getLabelFromCoverage, getLabelFromCoverageType, hasActiveOrFutureUser } from 'scripts/util/plans/plans';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IdCardIconCircleCheck, IdCardIconCircleMinus } from './id-card-status-icons';

interface IIdCardCoverageTypeHeaderProps {
  coverage: ICoverageSection;
  isCS: boolean;
  otherCoverageTypesForCs?: CoverageType[];
  users: IProfileUser[];
}

export const IdCardCoverageTypeHeader: FunctionComponent<IIdCardCoverageTypeHeaderProps> = props => {
  const { coverage, isCS, users, otherCoverageTypesForCs } = props;
  const { t } = useTranslation(Dictionary.COMMON);

  return (
    <h2 data-testid="id-card-coverage-type-header" key={'header-' + coverage.planKey}>
      <ul className={classNames('clearfix', { inactive: !hasActiveOrFutureUser(coverage, users) })}>
        <li>
          <div>{hasActiveOrFutureUser(coverage, users) ? <IdCardIconCircleCheck /> : <IdCardIconCircleMinus />}</div>
          <span> {t(getLabelFromCoverage(coverage))} </span>
        </li>
        {isCS
          ? otherCoverageTypesForCs &&
            otherCoverageTypesForCs.map(coverageType => (
              <li key={'otherCoverageTypesForCs-' + coverageType} data-testid="show-other-coverage-types-for-cs">
                <div>
                  {hasActiveOrFutureUser(coverage, users) ? <IdCardIconCircleCheck /> : <IdCardIconCircleMinus />}
                </div>
                <span> {t(getLabelFromCoverageType(coverageType))} </span>
              </li>
            ))
          : coverage.additionalCoverageTypes &&
            coverage.additionalCoverageTypes.map(coverageType => (
              <li key={'additionalCoverageTypes' + coverageType} data-testid="show-additional-coverage-types">
                <div>
                  {hasActiveOrFutureUser(coverage, users) ? <IdCardIconCircleCheck /> : <IdCardIconCircleMinus />}
                </div>
                <span> {t(getLabelFromCoverageType(coverageType))} </span>
              </li>
            ))}
      </ul>
    </h2>
  );
};
