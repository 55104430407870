import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { IRequestShortcutConfig } from 'scripts/api/api.interfaces';
import { Observable } from 'rxjs';

/**
 * AdvantageApiService wraps an [[IBaseApiService]] instance and ensures that the Arachne XSRF Token is added
 * and that withCredentials is used, which should fix the UE cookie problem.
 */
export class AdvantageApiService implements IBaseApiService {
  constructor(private $cookies: ng.cookies.ICookiesService, private baseApiService: IBaseApiService) {
    'ngInject';
  }

  private getXsrfToken(): string {
    const cookie = this.$cookies.get('FS');
    const parts = cookie && cookie.split('!');
    const xsrfToken = parts && parts.length && parts[0] ? parts[0] : 'INVALID-TOKEN!';
    return xsrfToken;
  }

  private ensureXsrfToken(config?: IRequestShortcutConfig): IRequestShortcutConfig {
    const newConfig = config ? config : {};
    newConfig.withCredentials = true;
    if (!newConfig.headers) {
      newConfig.headers = {};
    }
    if (!newConfig.headers['XSRF-TOKEN']) {
      newConfig.headers['XSRF-TOKEN'] = this.getXsrfToken();
    }
    return newConfig as IRequestShortcutConfig;
  }

  public get(url: string, config?: IRequestShortcutConfig): Observable<any> {
    return this.baseApiService.get(url, this.ensureXsrfToken(config));
  }

  public post(url: string, data?: {}, redirectOnError?: boolean, config?: IRequestShortcutConfig): Observable<any> {
    return this.baseApiService.post(url, data, redirectOnError, this.ensureXsrfToken(config));
  }
  public put(url: string, data: {}, config?: IRequestShortcutConfig): Observable<any> {
    return this.baseApiService.put(url, data, this.ensureXsrfToken(config));
  }

  public dateStringToMoment(parent: object, ...leaves: string[]): void {
    this.baseApiService.dateStringToMoment.apply(this.baseApiService, [parent, ...leaves]);
  }

  public timeStringToMoment(parent: object, ...leaves: string[]): void {
    this.baseApiService.timeStringToMoment.apply(this.baseApiService, [parent, ...leaves]);
  }

  public stringToFloat(parent: object, ...leaves: string[]): void {
    this.baseApiService.stringToFloat.apply(this.baseApiService, [parent, ...leaves]);
  }

  public getLocalizedCacheKey(url: string): string {
    return this.baseApiService.getLocalizedCacheKey(url);
  }
}
