import angular from 'angular';
import dropdownNavTemplate from 'views/ui/dropdown-nav.html';
import dropdownTemplate from 'views/ui/dropdown.html';
import { DropdownController } from './dropdown.controllers';
import { IDropdownController } from './dropdown.interfaces';
import { DropdownService } from './dropdown.service';

export class DropdownDirective implements ng.IDirective {
  public restrict = 'E';
  public scope = {};
  public controller = DropdownController;
  public controllerAs = '$ctrl';
  public bindToController = {
    options: '<',
    nav: '<',
    onSelect: '&',
    selected: '<',
    optionsClass: '@',
    optionClass: '@',
    toggleClass: '@',
    label: '@',
    labelSelected: '@',
    condition: '<',
  };

  public uniqueId = -1;

  constructor(private $document: ng.IDocumentService, private $timeout: ng.ITimeoutService) {}
  public templateUrl = (element: ng.IAugmentedJQuery, attrs: ng.IAttributes): string => {
    if (attrs.nav) {
      return dropdownNavTemplate;
    } else {
      return dropdownTemplate;
    }
  };

  public link = ($scope: ng.IScope, element: JQuery, attrs: ng.IAttributes, $ctrl: IDropdownController): void => {
    let uniqueId;
    let toggle;
    let options;
    let dropdownService: DropdownService;

    const setupEventListeners = (): void => {
      toggle = element[0].getElementsByClassName($ctrl.toggleClass)[0];
      options = element[0].getElementsByClassName($ctrl.optionClass);
      dropdownService = new DropdownService($scope, $ctrl, options, toggle);

      uniqueId = this.uniqueId++;
      $ctrl.optionsId = $ctrl.optionsClass.split(' ').join('') + '-' + uniqueId;
      $ctrl.toggleId = $ctrl.toggleClass.split(' ').join('') + '-' + uniqueId;

      element
        .on('keydown', e => {
          switch (e.keyCode) {
            case 40: // down arrow
              dropdownService.nextOption();
              e.preventDefault();
              break;
            case 38: // up arrow
              dropdownService.prevOption();
              e.preventDefault();
              break;
            case 13: // return
            case 32: // space
              if (!$ctrl.nav && e.target !== toggle) {
                angular.element(e.target).triggerHandler('click');
                toggle.focus();
                e.preventDefault();
              }
              break;
            case 27: // escape
              dropdownService.closeDropdown();
              toggle.focus();
              break;
          }
        })
        .on('mousedown', event => {
          event.preventDefault();
        });

      angular.element(toggle).on('click', () => {
        toggle.focus();
      });

      $scope.$watch('$ctrl.selected', val => {
        dropdownService.setSelectedIndex(val);
      });

      $scope.$watch(
        () => element.attr('label'),
        val => {
          if (val !== undefined) {
            $ctrl.label = val;
          }
        },
      );
    };

    this.$document.on('click', event => {
      if ($ctrl.show && element.find(event.target).length === 0) {
        dropdownService.closeDropdown();
      }
    });

    this.$timeout(setupEventListeners, 0);
  };
  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($document: ng.IDocumentService, $timeout: ng.ITimeoutService) => {
      'ngInject';
      return new DropdownDirective($document, $timeout);
    };
    return directive;
  }
}
