import {
  getAccumulatorsError,
  getAccumulatorsLoading,
  getAccumulatorsSuccess,
  getBenefitsError,
  getBenefitsLoading,
  getBenefitsSuccess,
  getIdCardsError,
  getIdCardsLoading,
  getIdCardsSuccess,
} from 'scripts/actions/plans-service-actions';
import {
  IPlanAccumulatorsResponse,
  IPlanBenefits,
  IPlanBenefitsResponse,
  IPlanAccumulators,
  IIdCardsResponse,
} from 'scripts/api/plans/plans.interfaces';
import { selectedUser as selectedUserSelector, selectProfileData } from 'scripts/selectors/profile-service-selectors';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { CoverageTypeCode } from 'scripts/api/api.interfaces';
import { IProfileUser, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { PlansApi } from 'scripts/api/plans/plans-api';
import { reflect } from 'scripts/util/promises/promise-reflect';
import moment from 'moment';

// PDP is not in the accumulators list because the endpoint on optum's side is broken.
// Eventually this can be condensed back to a single list
const approvedCoverageTypeCodesAccumulators = [CoverageTypeCode.MA, CoverageTypeCode.MAPD];

const getApprovedCoverageTypeCodes = (
  profile: IProfileUser,
  approvedCodeList: CoverageTypeCode[],
): CoverageTypeCode[] =>
  profile.planCoverages
    .map(({ coverageTypeCode }) => coverageTypeCode)
    .filter(coverageTypeCode => approvedCodeList.indexOf(coverageTypeCode) !== -1);

export function getIdCards(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getIdCardsLoading());
      const selectedProfile = selectedUserSelector.selectProfile(getState());
      const planCoverages = selectedProfile.planCoverages;
      const coverages = planCoverages.filter(planCoverage => planCoverage.planFeatures.hasIdCard);
      const idCardsPromises: Promise<IIdCardsResponse>[] = coverages.map(coverage => {
        return coverage.planFeatures.isIndividualIdCard
          ? PlansApi.getIdCards(selectedProfile, coverage, selectedProfile.dependentSeqNum)
          : PlansApi.getIdCards(selectedProfile, coverage);
      });
      const idCardsResults = await Promise.all(idCardsPromises.map(reflect));
      if (idCardsResults.every(idCardResult => !!idCardResult.error)) {
        dispatch(getIdCardsError());
      } else {
        const idCards = idCardsResults
          .map(idCardResult => {
            const { value } = idCardResult;
            return value && value.data;
          })
          .filter(val => !!val);
        for (let i = 0; i < coverages.length; i++) {
          if (coverages[i].coverageTypeCode) {
            for (let j = 0; j < idCards[i].length; j++) {
              idCards[i][j].coverageTypeCode = coverages[i].coverageTypeCode;
            }
          }
        }
        dispatch(getIdCardsSuccess(idCards));
      }
    } catch (error) {
      dispatch(getIdCardsError());
    }
  };
}

export function getAccumulators(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getAccumulatorsLoading());
      const profile = selectProfileData(getState());
      const selectedProfile = selectedUserSelector.selectProfile(getState());
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
      if (lineOfBusiness === LineOfBusiness.EI) {
        const { data, arcadeDataUpdated } = await PlansApi.getAccumulators(profile, selectedProfile);

        dispatch(getAccumulatorsSuccess({ accumulators: data, arcadeDataUpdated }));
      }
      if (lineOfBusiness === LineOfBusiness.MR) {
        const planAccumulatorsPromises: Promise<IPlanAccumulatorsResponse>[] = getApprovedCoverageTypeCodes(
          selectedProfile,
          approvedCoverageTypeCodesAccumulators,
        ).map(coverageTypeCode => PlansApi.getAccumulators(profile, selectedProfile, null, coverageTypeCode));

        const planAccumulatorsResults = await Promise.all(planAccumulatorsPromises.map(reflect));
        if (planAccumulatorsResults.every(planAccumulatorResult => !!planAccumulatorResult.error)) {
          dispatch(getAccumulatorsError());
        } else {
          const accumulators = planAccumulatorsResults
            .map(planAccumulatorResult => {
              const { value } = planAccumulatorResult;
              return value && value.data && value.data.benefits && value.data.benefits[0];
            })
            .filter(val => !!val);
          const accumulatorsUpdated = planAccumulatorsResults
            .map(planAccumulatorResult => {
              const { value } = planAccumulatorResult;
              return value && value.arcadeDataUpdated;
            })
            .filter(val => !!val);
          const arcadeDataUpdated = moment.max(accumulatorsUpdated);
          const planAccumulators: IPlanAccumulators = { benefits: accumulators };
          dispatch(getAccumulatorsSuccess({ accumulators: planAccumulators, arcadeDataUpdated }));
        }
      }
    } catch (error) {
      dispatch(getAccumulatorsError());
    }
  };
}

export function getBenefits(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getBenefitsLoading());
      const profile = selectProfileData(getState());
      const selectedProfile = selectedUserSelector.selectProfile(getState());
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
      if (lineOfBusiness === LineOfBusiness.EI) {
        const { data } = await PlansApi.getBenefits(profile, selectedProfile);
        dispatch(getBenefitsSuccess(data));
      }
      if (lineOfBusiness === LineOfBusiness.MR) {
        // PAY-658: getBenefits returns an IPlanBenefitsResponse, which contains a benefits array of IBenefit objects.
        // We need to call getBenefits for each coverage, pull out the IBenefit, and reconstruct IPlanBenefit to store in Redux store.
        const planBenefitsPromises: Promise<IPlanBenefitsResponse>[] = selectedProfile.planCoverages
          .map(coverage => coverage.coverageTypeCode)
          .map(coverageTypeCode => PlansApi.getBenefits(profile, selectedProfile, null, coverageTypeCode));

        const planBenefitsResults = await Promise.all(planBenefitsPromises.map(reflect));
        if (planBenefitsResults.every(planBenefitResult => !!planBenefitResult.error)) {
          dispatch(getBenefitsError());
        } else {
          const benefits = planBenefitsResults
            .map(planBenefitResult => {
              const { value } = planBenefitResult;
              return value && value.data && value.data.benefits && value.data.benefits[0];
            })
            .filter(val => !!val);
          const planBenefits: IPlanBenefits = { benefits };
          dispatch(getBenefitsSuccess(planBenefits));
        }
      }
    } catch (error) {
      dispatch(getBenefitsError());
    }
  };
}
