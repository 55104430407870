import React, { FunctionComponent } from 'react';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { Anchor, IAnchorProps } from 'scripts/ui/anchor/anchor';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import ExternalLinks from 'scripts/ui/external-link/external-link';

interface ISubmitClaimLinkProps extends IAnchorProps {
  href: string;
  text: string;
  title: string;
  Icon: FunctionComponent<React.SVGAttributes<SVGElement> & { title?: string }>;
}

const SubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({ title, text, href, Icon, ...rest }) => {
  const AnchorWithClickTracking = withClickTracking(Anchor, `${title} - ${text}`);

  return (
    <div className="link-container">
      <AnchorWithClickTracking
        className="possible-first-main-focus"
        href={href}
        target={LinkTarget.Blank}
        noNewWindowIcon
        {...rest}
      >
        <Icon className="link-icon" aria-hidden="true" />
        <span className="link-title">
          <span>{title}</span>
          <ExternalLinks />
        </span>
        <span className="link-text">{text}</span>
      </AnchorWithClickTracking>
    </div>
  );
};

export default SubmitClaimLink;
