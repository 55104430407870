import React, { FunctionComponent } from 'react';
import { connectQueryLocale, connectReferrals } from 'scripts/util/resource/resource.constants';
import { getFullName } from 'scripts/util/profile/profile';
import { getResource } from 'scripts/util/resource/resource';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IPhysician } from 'scripts/api/profile/profile.interfaces';
import { Link } from 'scripts/ui/link/link';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

interface IProviderReferralsPhysicianLinkProps {
  physician: IPhysician;
  population: IPopulation;
}

const getProviderReferralsPhysicianLink = (physician: IPhysician, population: IPopulation): string => {
  return getResource(connectReferrals, population) + physician.providerId + getResource(connectQueryLocale, population);
};

export const ProviderReferralsPhysicianLink: FunctionComponent<IProviderReferralsPhysicianLinkProps> = props => {
  const { physician, population } = props;
  const physicianRedirectUrl = `?deepLink=${getProviderReferralsPhysicianLink(physician, population)}`;

  return <Link to={{ pathname: '/internal-redirect', search: physicianRedirectUrl }}>{getFullName(physician)}</Link>;
};
export const TrackedReferredToPhysicianLink = withClickTracking(ProviderReferralsPhysicianLink, 'pcp-referred-to-name');
export const TrackedReferredByPhysicianLink = withClickTracking(ProviderReferralsPhysicianLink, 'pcp-referred-by-name');
