import { IArcadeStateData } from '../../arcade.module.interfaces';
import { CoverageType, CoverageTypeCode, RelationshipType } from '../api.interfaces';
import { LineOfBusiness, MembershipCategory } from '../profile/profile.interfaces';

export interface IAmplitude {
  getInstance(): IAmplitudeInstance;
}

export interface IAmplitudeInstance {
  options?: { deviceId: string };
  setDeviceId(id: string): void;
  setSessionId(id: number): void;
  getSessionId(): number;
  init(key: string, userId?: string, config?: object, callback?: () => void): void;
  setUserId(rallyId: string): void;
  setVersionName(name: string): void;
  setUserProperties(userProps: IAmpUserData): void;
  logEvent(type: AmpEventType, eventProps: IAmpEventData): void;
  regenerateDeviceId(): void;
}

export interface IAmpRootScope extends ng.IRootScopeService {
  lastTrackedPageInfo: ILastTrackedPageInfo;
}

export interface ILastTrackedPageInfo {
  path: string;
  data: IArcadeStateData;
}

export const AmpPageTags = {
  activate: 'activate',
  claims: 'claims',
  compareServices: 'compare-services',
  dashboard: 'dashboard',
  dental: 'dental',
  error: 'error',
  facilities: 'facilities',
  fsa: 'fsa',
  healthResources: 'health-resources',
  help: 'help',
  hra: 'hra',
  hsa: 'hsa',
  infoModal: 'info-modal',
  medical: 'medical',
  payments: 'payments',
  pcp: 'pcp',
  planBalances: 'plan-balances',
  providers: 'providers',
  rx: 'rx',
  splitHra: 'split-hra',
  urgentCare: 'urgent-care',
};

export const AmpUserPropNames = {
  product: 'Product',
  authenticated: 'Authenticated',
  advantageSession: 'Advantage Session',
  gender: 'Connect User Gender',
  policyNum: 'Connect Policy Number',
  variationCode: 'Connect Plan Variation Code',
  clientId: 'Connect Client ID',
  lineOfBusiness: 'Connect Line of Business',
  membershipCategory: 'DHP Membership Category',
  relationshipType: 'Connect Relationship Type',
  groupNum: 'Connect Employer Group Number',
  age: 'Connect User Age',
  coverageStatus: 'Connect Coverage Status',
  coverageType: 'Connect Coverage Type',
  coverageTypeCode: 'Connect Coverage Type Code',
  pcpRequiredByPlan: 'PCP Required By Plan',
};

export interface IAmpUserData {
  Product?: string;
  Authenticated?: boolean;
  'Advantage Session'?: boolean;
  'Connect User Gender'?: string;
  'Connect Policy Number'?: string;
  'Connect Plan Variation Code'?: string;
  'Connect Client ID'?: string;
  'Connect Line of Business'?: LineOfBusiness;
  'DHP Membership Category'?: MembershipCategory;
  'Connect Member Status'?: string;
  'Connect Relationship Type'?: RelationshipType;
  'Connect Employer Group Number'?: string;
  'Connect User Age'?: string;
  'Connect Coverage Type'?: CoverageType[];
  'Connect Coverage Type Code'?: CoverageTypeCode[];
  'PCP Required By Plan'?: boolean;
}

export const AmpEventPropNames = {
  domain: 'Domain',
  uri: 'URI',
  newSession: 'New Session',
  product: 'Product',
  environment: 'Environment',
  appVersion: 'Frontend App Version',
  appName: 'Frontend App Name',
  pageName: 'Page Name',
  pageNavId: 'Page Nav ID',
  pageTags: 'Page Tags',
  pageArgs: 'Page Args',
  isModal: 'Is Modal',
  triggerEvent: 'Trigger UI Event',
  triggerSection: 'Trigger UI Section',
  triggerElementName: 'Trigger UI Element Name',
  triggerElement: 'Trigger UI Element',
  triggerSummary: 'Trigger Summary',
  triggerSummaryExtended: 'Trigger Summary Extended',
  triggerPageNavId: 'Trigger Page Nav ID',
  triggerPageName: 'Trigger Page Name',
  triggerPageTags: 'Trigger Page Tags',
  triggerUri: 'Trigger URI',
  previousUri: 'Previous URI',
  previousDomain: 'Previous Domain',
  previousPageNavId: 'Previous Page Nav ID',
  previousPageName: 'Previous Page Name',
  externalDomain: 'External Domain',
  externalUrl: 'External URL',
  playStartTriggered: 'Play Start Triggered',
  videoProgress: 'Video Progress',
  videoName: 'Video Name',
  successful: 'Successful',
  paymentAmount: 'Payment Amount',
};

export interface IAmpEventData {
  Domain?: string;
  URI?: string;
  'New Session'?: boolean;
  Product?: string;
  Environment?: string;
  'Frontend App Version'?: string;
  'Frontend App Name'?: string;
  'Page Name'?: string;
  'Page Nav ID'?: string;
  'Page Tags'?: string[];
  'Is Modal'?: boolean;
  'Trigger UI Event'?: string;
  'Trigger UI Section'?: string;
  'Trigger UI Element Name'?: string;
  'Trigger UI Element'?: string;
  'Trigger Summary'?: string;
  'Trigger Summary Extended'?: string;
  'Trigger Page Nav ID'?: string;
  'Trigger Page Name'?: string;
  'Trigger Page Tags'?: string[];
  'Trigger URI'?: string;
  'Previous URI'?: string;
  'Previous Domain'?: string;
  'Previous Page Nav ID'?: string;
  'Previous Page Name'?: string;
  'External Domain'?: string;
  'External URL'?: string;
  Successful?: boolean;
  'Payment Amount'?: string;
}

export interface IAmpSundaySkyEventData extends IAmpEventData {
  'Video Name'?: string;
  'Play Start Triggered'?: boolean;
  'Video Progress'?: string;
}

export enum AmpEventType {
  ClaimsSearchBoxClicked = 'Claims Search Box Clicked',
  ExternalNav = 'External Nav',
  HopperAccountSummary = 'Hopper[view-accounts-claims]',
  HopperClaimsAccountSummary = 'Hopper[view-medical-account-summary]',
  HopperClaimsAccountSummaryLinks = 'Hopper[view-additional-links-documents]',
  HopperCosts = 'Hopper[view-costs-accounts-claims]',
  HopperDefault = 'Hopper[default]',
  HopperIncentives = 'Hopper[view-incentives-costs]',
  HopperLinkBar = 'Hopper[view-links]',
  HopperRecentClaims = 'Hopper[view-claims]',
  IdCardPrinted = 'DHP ID Card Printed',
  Logout = 'Logout',
  PageNav = 'Page Nav',
  OnboardingMilestoneCompleted = 'Activate Onboarding Video Milestone Completed',
  RallyPayPaymentCompleted = 'Rally Pay Payment Completed',
}

export enum AmpEventTrigger {
  Auto = 'AUTO',
  BrowserNav = 'BROWSER_NAV',
  Click = 'CLICK',
  DirectNav = 'DIRECT_NAV',
}
