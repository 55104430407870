import { dateStringToMoment, timeStringToMoment } from 'scripts/util/conversion/conversion';
import {
  IClaimsPreferencesResponse,
  IInstamedMobileTokenResponse,
  IProductsResponse,
  IProfileResponse,
  IReferralsResponse,
  IHealthcareCoveragesResponse,
  IPrimaryCareResponse,
  LineOfBusiness,
  MembershipCategory,
  IPrimaryCareFpcResponse,
} from './profile.interfaces';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { profileUris } from 'scripts/util/uri/uri';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';

function getProfile(rallyId: string): Promise<IProfileResponse> {
  const url = profileUris.profile(rallyId);
  return HttpClient.get<IProfileResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    // We need rallyId whenever we set the currently viewed profile, so we set it equal to the rallyId from the
    // profile response if it doesn't exist on the targeted profile.
    rsp.data.currentUser.rallyId = rsp.data.currentUser.rallyId ? rsp.data.currentUser.rallyId : rsp.data.rallyId;
    if (rsp.data.dependents) {
      rsp.data.dependents.forEach(dependent => {
        dependent.rallyId = dependent.rallyId ? dependent.rallyId : rsp.data.rallyId;
      });
    }
    const coverages = rsp.data.currentUser.planCoverages;
    if (coverages) {
      rsp.data.currentUser.acos = coverages
        .map(coverage => coverage.additionalCoverageInfo && coverage.additionalCoverageInfo.aco)
        .filter(aco => !!aco);
    }
    dateStringToMoment(rsp, 'data', 'currentUser', 'userInfo', 'dob');
    dateStringToMoment(rsp, 'data', 'dependents', 'userInfo', 'dob');
    dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'startDate');
    dateStringToMoment(rsp, 'data', 'currentUser', 'planCoverages', 'planPeriod', 'endDate');
    dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'startDate');
    dateStringToMoment(rsp, 'data', 'dependents', 'planCoverages', 'planPeriod', 'endDate');
    return rsp;
  });
}

function getClaimsPreferences(rallyId: string): Promise<IClaimsPreferencesResponse> {
  const url = profileUris.claimsPreferences(rallyId);
  return HttpClient.get<IClaimsPreferencesResponse>(url, { cacheName: CacheName.ProfilePreferences });
}

function getInstamedMobileToken(
  rallyId: string,
  claimPayKey: string,
  returnUrl: string,
): Promise<IInstamedMobileTokenResponse> {
  const url = profileUris.instamedMobileToken(rallyId, { instamedPacket: claimPayKey, callBackUrl: returnUrl });
  return HttpClient.get<IInstamedMobileTokenResponse>(url, { cacheName: CacheName.Profile });
}

function getFpcPrimaryCare(
  rallyId: string,
  lineOfBusiness: LineOfBusiness,
  membershipCategory: MembershipCategory,
): Promise<IPrimaryCareFpcResponse> {
  const params = FeatureFlagService.isFpcPcpAdditonalParametersRequired()
    ? { lineOfBusiness, membershipCategory }
    : undefined;
  const url = profileUris.fpcPrimaryCare(rallyId, params);
  return HttpClient.get<IPrimaryCareFpcResponse>(url, { cacheName: CacheName.FpcPrimaryCareInformation });
}

function getPrimaryCare(rallyId: string, isPcdEligible?: boolean): Promise<IPrimaryCareResponse> {
  const params = { isPcdEligible };
  const url = profileUris.primaryCare(rallyId, params);
  return HttpClient.get<IPrimaryCareResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    const { perMemberResults } = rsp.data;
    const perMemberResultsKeys = Object.keys(perMemberResults);
    for (const depSeqNum of perMemberResultsKeys) {
      dateStringToMoment(rsp, 'data', 'perMemberResults', depSeqNum, 'primaryCarePhysicians', 'effectiveDate');
    }
    return rsp;
  });
}

function getProducts(rallyId: string): Promise<IProductsResponse> {
  const url = profileUris.products(rallyId);
  return HttpClient.get<IProductsResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    const { products } = rsp.data;
    for (const p in products) {
      if (Object.prototype.hasOwnProperty.call(products, p)) {
        timeStringToMoment(products[p], 'contactInformation', 'hours', 'startTime');
        timeStringToMoment(products[p], 'contactInformation', 'hours', 'endTime');
      }
    }
    return rsp;
  });
}

function getReferrals(rallyId: string): Promise<IReferralsResponse> {
  const url = profileUris.referrals(rallyId);
  return HttpClient.get<IReferralsResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    const { perMemberReferrals } = rsp.data;
    const perMemberReferralsKeys = Object.keys(perMemberReferrals);
    for (const depSeqNum of perMemberReferralsKeys) {
      dateStringToMoment(rsp, 'data', 'perMemberReferrals', depSeqNum, 'referrals', 'effectiveDateRange', 'startDate');
      dateStringToMoment(rsp, 'data', 'perMemberReferrals', depSeqNum, 'referrals', 'effectiveDateRange', 'endDate');
    }

    return rsp;
  });
}

function getHealthcareCoverages(rallyId: string, showCarveoutClaims: boolean): Promise<IHealthcareCoveragesResponse> {
  const url = profileUris.healthcareCoverages(rallyId, { showCarveoutClaims });
  return HttpClient.get<IHealthcareCoveragesResponse>(url, { cacheName: CacheName.Profile });
}

export const ProfileApi = {
  getClaimsPreferences,
  getInstamedMobileToken,
  getFpcPrimaryCare,
  getPrimaryCare,
  getProducts,
  getProfile,
  getReferrals,
  getHealthcareCoverages,
};
