import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  IFullName,
  RelationshipType,
} from 'scripts/api/api.interfaces';
import {
  ICoverageInfo,
  IPlanCoverage,
  IProfileUser,
  LineOfBusiness,
  MembershipCategory,
  RxCarveOutProvider,
  RxCoverageType,
  ProgramType,
  IProducts,
  IReferralsPerMember,
} from 'scripts/api/profile/profile.interfaces';
import CONFIG from '../constants/config';
import { getResource } from '../resource/resource';
import { optumPharmacy } from '../resource/resource.constants';
import { IPopulation } from '../population/population.interfaces';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';

export function getCoverage(coverageType: CoverageType, coverages: IPlanCoverage[]): IPlanCoverage | undefined {
  for (const coverage of coverages) {
    if (coverage.coverageType === coverageType) {
      return coverage;
    }
  }
}

export function getCoverageInfo(
  coverages: IPlanCoverage[],
  statuses: CoverageStatus[] = [CoverageStatus.Active],
): ICoverageInfo {
  const coverageInfo: ICoverageInfo = {
    coverageTypes: {},
    coverageTypeCodes: {},
    numCoverages: 0,
  };
  if (coverages) {
    for (const c of coverages) {
      const hasCoverage = statuses.indexOf(c.planPeriod.status) > -1;
      if (hasCoverage) {
        coverageInfo.coverageTypes[c.coverageType] = true;
        if (c.coverageTypeCode) {
          coverageInfo.coverageTypeCodes[c.coverageTypeCode] = true;
        }
        for (const subCoverageType of c.additionalCoverageTypes) {
          coverageInfo.coverageTypes[subCoverageType] = true;
        }
      }
    }
  }
  coverageInfo.numCoverages = Object.keys(coverageInfo.coverageTypes).length;
  return coverageInfo;
}

export function getFullName(user: IFullName): string {
  let fullName = '';
  if (user.firstName) {
    fullName = user.firstName + ' ';
  }
  if (user.firstName && user.middleName) {
    fullName += user.middleName + ' ';
  }
  if (user.lastName) {
    fullName += user.lastName;
  }
  if (user.suffix) {
    fullName += ' ' + user.suffix;
  }
  return fullName;
}

export function getNumberOfSelectedUserReferrals(
  referralsPerMember: IReferralsPerMember,
  selectedUser: IProfileUser,
): number {
  const selectedUserReferrals = referralsPerMember && referralsPerMember[selectedUser.dependentSeqNum];
  return selectedUserReferrals ? selectedUserReferrals.totalNumberOfActiveReferrals : 0;
}

export function getRelationshipTypeText(currentUser: IProfileUser): string | IProfileUser['relationshipType'] {
  if (
    currentUser.membershipCategory &&
    currentUser.membershipCategory === MembershipCategory.EMPIRE &&
    currentUser.relationshipType === RelationshipType.Subscriber
  ) {
    return 'ENROLLEE';
  }
  return currentUser.relationshipType;
}

export function getRelationshipType(
  user: IProfileUser,
  coverage: ICoverageSection,
): string | IProfileUser['relationshipType'] {
  if (coverage.planFeatures.programType === ProgramType.Ship) {
    return 'INSURED_MEMBER';
  } else {
    return getRelationshipTypeText(user);
  }
}

export function getRxProviderMap(population: IPopulation): {} {
  return {
    [RxCarveOutProvider.Optum]: {
      logo: '/images/branding/optum-rx.svg',
      displayName: 'OptumRx',
      url: getResource(optumPharmacy, population),
    },
    [RxCarveOutProvider.Caremark]: {
      logo: '/images/branding/cvs-caremark.svg',
      displayName: 'CVS Caremark',
      url: CONFIG.ARCADE_WEB_CAREMARK_URL,
    },
    [RxCarveOutProvider.ExpressScripts]: {
      logo: '/images/branding/express-scripts.svg',
      displayName: 'Express Scripts',
      url: CONFIG.ARCADE_WEB_EXPRESS_SCRIPTS_URL,
    },
    [RxCarveOutProvider.Walgreens]: {
      logo: '/images/branding/walgreens.svg',
      displayName: 'Walgreens',
      url: CONFIG.ARCADE_WEB_WALGREENS_URL,
    },
  };
}

export function getRxProvider(coverage: IPlanCoverage): string {
  if (coverage.coverageType !== CoverageType.Rx || typeof coverage.rxCoverageInfo === 'undefined') {
    return;
  }

  return coverage.rxCoverageInfo.coverageType === RxCoverageType.CRX
    ? coverage.rxCoverageInfo.carveOutProvider
    : RxCarveOutProvider.Optum;
}

export function hasActiveRxCoverage(currentUser: IProfileUser): boolean {
  const activeCoverageInfo = getCoverageInfo(currentUser.planCoverages);
  return Object.prototype.hasOwnProperty.call(activeCoverageInfo.coverageTypes, CoverageType.Rx);
}

export function hasInstamed(currentUser: IProfileUser): boolean {
  return currentUser.relationshipType === RelationshipType.Subscriber;
}

export function hasLedgerAccess(currentUser: IProfileUser): boolean {
  return (
    currentUser.relationshipType === RelationshipType.Subscriber ||
    currentUser.relationshipType === RelationshipType.DomesticPartner
  );
}

export function isTermedForCoverageType(coverageType: CoverageType, coverages: IPlanCoverage[]): boolean | undefined {
  if (coverages.some(c => c.coverageType === coverageType)) {
    return this.getCoverage(coverageType, coverages).planPeriod.status === CoverageStatus.Termed;
  }
}

export function isValueAddedServiceEligible(products: IProducts): boolean {
  return !!(
    products.nurseHealthLine ||
    products.silverSneakers ||
    products.visionDiscount ||
    products.atYourBest ||
    products.dime ||
    products.dime2 ||
    products.providerDiscountNetwork ||
    products.myCarePath
  );
}

export function showMRRxSpendingCostSummary(profileUser: IProfileUser): boolean {
  const blackListedPolicyNumbers = CONFIG.ARCADE_WEB_MR_RX_SUPPRESSION_GROUP_NUMBERS.split(',');
  const isMR = profileUser.lineOfBusiness === LineOfBusiness.MR;
  const applicableCoverages = [CoverageTypeCode.MAPD, CoverageTypeCode.PDP];
  const hasCoverage = profileUser.planCoverages.some(
    cov =>
      applicableCoverages.indexOf(cov.coverageTypeCode) > -1 &&
      cov.planPeriod.status === CoverageStatus.Active &&
      !cov.planFeatures.lisLevel &&
      blackListedPolicyNumbers.indexOf(cov.policyNumber) === -1,
  );
  return isMR && hasCoverage;
}

export function showMRMedicalSpendingCostSummarySection(profileUser: IProfileUser): boolean {
  const isMR = profileUser.lineOfBusiness === LineOfBusiness.MR;
  const applicableCoverages = [CoverageTypeCode.MA, CoverageTypeCode.MAPD];
  const hasCoverage = profileUser.planCoverages.some(
    cov => applicableCoverages.indexOf(cov.coverageTypeCode) > -1 && cov.planPeriod.status === CoverageStatus.Active,
  );
  return isMR && hasCoverage;
}

export function showMRSpendingCostSummaryPage(profileUser: IProfileUser): boolean {
  const isMR = profileUser.lineOfBusiness === LineOfBusiness.MR;
  const applicableCoverages = [CoverageTypeCode.MA, CoverageTypeCode.MAPD, CoverageTypeCode.PDP];
  const hasCoverage = profileUser.planCoverages.some(
    cov => applicableCoverages.indexOf(cov.coverageTypeCode) > -1 && cov.planPeriod.status === CoverageStatus.Active,
  );
  return isMR && hasCoverage;
}

export function suppressMRDonut(user: IProfileUser, suppressionList: string): boolean {
  const medicalCoverage = user.planCoverages.find(cov => (cov.coverageType = CoverageType.Medical));
  const contractNumForDonutSuppression =
    medicalCoverage &&
    medicalCoverage.additionalCoverageInfo &&
    medicalCoverage.additionalCoverageInfo.productCode &&
    medicalCoverage.additionalCoverageInfo.productCode.slice(0, 5);
  return contractNumForDonutSuppression ? suppressionList.indexOf(contractNumForDonutSuppression) > -1 : false;
}
