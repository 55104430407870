import {
  getCampaignsLoading,
  getCampaignsSuccess,
  getCampaignsError,
  getClientConfigError,
  getClientConfigLoading,
  getClientConfigSuccess,
} from 'scripts/actions/targeting-service-actions';
import { TargetingApi } from 'scripts/api/targeting/targeting-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { currentUser as currentUserSelector, selectClientInfo } from 'scripts/selectors/profile-service-selectors';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';

export function getCampaigns(placements: string[]): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getCampaignsLoading());
      const rallyId = selectRallyId(getState());
      const campaigns = await TargetingApi.getCampaigns(rallyId, placements);
      dispatch(getCampaignsSuccess(campaigns));
    } catch (error) {
      dispatch(getCampaignsError());
    }
  };
}

export function getClientConfig(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getClientConfigLoading());
      const currentUser = currentUserSelector.selectProfile(getState());
      const clientInfo = selectClientInfo(getState());
      const clientConfig = await TargetingApi.getClientConfig(currentUser, clientInfo);
      dispatch(getClientConfigSuccess(clientConfig));
    } catch (error) {
      dispatch(getClientConfigError());
    }
  };
}
