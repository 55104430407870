import { IProfile } from 'scripts/api/profile/profile.interfaces';
import { ProfileService } from 'scripts/api/profile/profile.service';
import {
  CampaignPlacementType,
  ICampaignPlacements,
  IPlanInfo,
  IRecommendationCampaign,
} from 'scripts/api/targeting/targeting.interfaces';
import {
  IAACampaignsData,
  IAAContent,
  IAADataLayer,
  IAAMemberPlan,
  IAAPostPageData,
  IAASatellite,
  ITrackingEventRequest,
  IVideoTrackingEvent,
  TrackingTriggerType,
} from 'scripts/api/tracking/tracking.interfaces';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import {
  IPharmacyLinkCustomTrackingInfo,
  PharmacySiteSectionL2TrackingString,
} from 'scripts/features/pharmacy/pharmacy.component';
import { getLocale } from '../locale/locale';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    _satellite: IAASatellite;
    publishPostPageData: (trackingName: string, pageData: IAAPostPageData) => void;
  }
}

function getAASatellite(): IAASatellite {
  return (
    window._satellite || {
      track: () => {
        console.warn('Unable to find Adobe Analytics for tracking event');
      },
      pageBottom: () => {
        console.warn('Unable to find Adobe Analytics for pageBottom method');
      },
    }
  );
}

export function sendAAPageData<T>(name: string, content: IAAContent, additionalData?: T): void {
  const data = {
    content: {
      locale: getLocale().id,
      ...content,
    },
    ...additionalData,
  };

  const tryInterval = setInterval(() => {
    if (typeof window.publishPostPageData === 'function') {
      window.publishPostPageData(name, data);
      clearInterval(tryInterval);
    }
  }, 100);
}

export function getAAPageName(): string {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  let pageName = '';

  if (path.indexOf('/modal/') > -1) {
    pageName = 'modal:';
  }

  pageName = pageName + pathParts[pathParts.length - 1];
  return pageName;
}

export function getAADataLayer(): IAADataLayer {
  const pdl = 'pageDataLayer';
  window[pdl] = window[pdl] || {};
  window[pdl].content = window[pdl].content || {};
  return window[pdl];
}

/**
 * Adobe Analytics
 * This method is based off external requirements. Unfortunately, the way this is setup, it's extremely
 * prone to some pretty bad race conditions, which could render any tracked data completely useless. Every event
 * that is fired is done so asynchronously and at any given time (sometimes the same time). window._satellite
 * does not accept a data object as a parameter, but rather an object defined on global (window) scope. If two
 * events fire at the same time, it's entirely possible that one of them could override the data of the other,
 * thus corrupting what gets sent to Adobe Analytics. To fix this issue, window._satellite should accept the
 * event object as the param instead of the event type (which just so happens to exist in the event object).
 * @param event
 */
export function sendAAEvent(event: ITrackingEventRequest, aaDataLayer: IAADataLayer): void {
  try {
    const aa = getAASatellite();
    // Update the expected window.pageDataLayer object that AA looks for
    aaDataLayer.content.pageName = getAAPageName();
    aaDataLayer.content.siteSectionL1 = window.location.pathname.split('/')[1];
    aaDataLayer.content.locale = getLocale().id;
    aaDataLayer.content.mostRecentEvent = {
      uri: window.location.pathname,
      featureList: event.featureList,
      action: event.actionName,
      eventType: event.trigger,
      fullName: event.featureList.concat([event.actionName]).join('.'),
    };

    // Send out an event if the action is a click or a section fail
    switch (event.trigger) {
      case TrackingTriggerType.Click:
        aaDataLayer.content.siteErrorType = null;
        aa.track('click');
        break;
      case TrackingTriggerType.SectionFailed:
        aaDataLayer.content.siteErrorType = event.actionName;
        aa.track('errorTracking');
        break;
    }
  } catch (err) {
    console.error('Error sending AA event', err);
  }
}

/**
 * Adobe Analytics
 * This method is based off external requirements. Unfortunately, the way this is setup,
 * we need to bypass the normal flow of our event structure to log impressions to Adobe.
 * @param event
 */
export function sendOutOfFlowAAEvent(event: ITrackingEventRequest, aaDataLayer?: IAADataLayer): void {
  const aa = getAASatellite();
  aaDataLayer = aaDataLayer || getAADataLayer();
  // Update the expected window.pageDataLayer object that AA looks for
  aaDataLayer.content.pageName = getAAPageName();
  aaDataLayer.content.siteSectionL1 = window.location.pathname.split('/')[1];
  aaDataLayer.content.locale = getLocale().id;
  aaDataLayer.content.mostRecentEvent = {
    uri: window.location.pathname,
    featureList: event.featureList,
    action: event.actionName,
    eventType: event.trigger,
    fullName: event.featureList.concat([event.actionName]).join('.'),
  };

  // Send out an event if the action is a click. View doesn't seem to work.
  aaDataLayer.content.siteErrorType = null;
  aa.track('click');
}

export function sendAAInitEvent(): void {
  let adobeTryInterval; // eslint-disable-line prefer-const
  const adobeTryPageBottom = (): void => {
    if (window._satellite && window._satellite.pageBottom) {
      window._satellite.pageBottom();
      clearInterval(adobeTryInterval);
    }
  };
  adobeTryInterval = setInterval(adobeTryPageBottom, 10);
}

export function queueAAVideoEvent(data: IVideoTrackingEvent): void {
  const content = {
    pageName: getAAPageName(),
    siteSectionL1: window.location.pathname.split('/')[1],
  };

  const videoData = { videoContent: { ...data.videoData, videoPageName: getAAPageName() } };

  sendAAPageData(data.actionName, content, videoData);
}

export function sendCustomPharmacyAALoadedEvent(
  trackingName: string,
  pharmacySiteSectionL2: PharmacySiteSectionL2TrackingString,
): void {
  const content = {
    pageName: 'overview',
    siteSectionL1: window.location.pathname.split('/')[1], // value for this will be pharmacy
    siteSectionL2: pharmacySiteSectionL2,
  };

  sendAAPageData(trackingName, content);
}

export function sendPharmacyLinkClickAACustomEvent(customLinkTrackingInfo: IPharmacyLinkCustomTrackingInfo): void {
  const event = document.createEvent('customEvent') as CustomEvent;

  event.initCustomEvent('linkCall', true, true, {
    eventType: 'exitLinkClick',
    linkName: customLinkTrackingInfo.linkTrackingName,
    linkURL: customLinkTrackingInfo.trackingLink,
  });

  document.body.dispatchEvent(event);
}

export function sendTabLoadedEvent(): void {
  const path = window.location.pathname;
  const splitPath = path.split('/');
  const content = {
    tabName: splitPath[1],
    pageName: splitPath[splitPath.length - 1],
    path,
    locale: undefined,
  };
  sendAAPageData('topNavTabLoaded', content);
}

function getFormattedCampaignsData(
  campaigns?: ICampaignPlacements,
  incentivesPlanInfo?: IPlanInfo,
  recommendations?: IRecommendationCampaign[],
): IAACampaignsData {
  const ids = [];
  const types = [];
  const titles = [];
  const offerCodes = [];

  if (recommendations) {
    recommendations.forEach(rec => {
      ids.push(rec.campaignId);
      types.push(CampaignPlacementType.ArcadeRecommendationRto);
      titles.push(rec.headline.replace(/\|/g, ''));
      offerCodes.push(rec.offerCode);
    });
  }

  if (incentivesPlanInfo) {
    ids.push(incentivesPlanInfo.planId.replace(/\|/g, ''));
    types.push('incentives');
    titles.push(incentivesPlanInfo.planTitle.replace(/\|/g, ''));
  }

  if (campaigns) {
    const resourcesPagePlacementTypes = [
      CampaignPlacementType.ArcadeResourcesPrimaryWellness,
      CampaignPlacementType.ArcadeResourcesSecondaryWellness,
      CampaignPlacementType.ArcadeResourcesPrograms,
      CampaignPlacementType.ArcadeResourcesText,
      CampaignPlacementType.ArcadeResourcesSection1Link,
      CampaignPlacementType.ArcadeResourcesSection2Link,
      CampaignPlacementType.ArcadeResourcesGeneralPromo,
    ];

    for (const placementType of resourcesPagePlacementTypes) {
      if (campaigns[placementType]) {
        const campaignsOfType = campaigns[placementType];
        for (const campaign of campaignsOfType) {
          ids.push(campaign.campaignId);
          types.push(campaign.placementType);
          if (
            placementType === CampaignPlacementType.ArcadeResourcesSection1Link ||
            placementType === CampaignPlacementType.ArcadeResourcesSection2Link
          ) {
            titles.push(campaign.cta.ctaText.replace(/\|/g, ''));
          } else {
            titles.push(campaign.headline.replace(/\|/g, ''));
          }
        }
      }
    }
  }

  const data = {
    campaigns: {
      id: ids.join('|'),
      placement: types.join('|'),
      title: titles.join('|'),
    },
  } as IAACampaignsData;
  if (offerCodes.length > 0) {
    data.campaigns.offerCode = offerCodes.join('|');
  }

  return data;
}

export function sendCampaignsLoadedEvent(
  campaigns?: ICampaignPlacements,
  incentivesPlanInfo?: IPlanInfo,
  recommendations?: IRecommendationCampaign[],
): void {
  if (campaigns || incentivesPlanInfo || recommendations) {
    const campaignsData = getFormattedCampaignsData(campaigns, incentivesPlanInfo, recommendations);
    const path = window.location.pathname;
    const splitPath = path.split('/');
    const content = {
      tabName: splitPath[1],
      pageName: splitPath[splitPath.length - 1],
      path,
      locale: undefined,
    };
    sendAAPageData('campaignsLoaded', content, campaignsData);
  }
}

export function setInitialAADataLayerValues(aaDataLayer: IAADataLayer): void {
  aaDataLayer.rallyId = null;
  aaDataLayer.loginStatus = 'not-loggedin';
  aaDataLayer.zipcode = 'no-zipcode';
  aaDataLayer.clientId = 'no-id';
  aaDataLayer.clientName = 'no-name';
  aaDataLayer.memberPlansInfo = [];
}

export function setAAProfileValues(aaDataLayer: IAADataLayer, heartbeat: IHeartbeat, profile: IProfile): void {
  const notAvailable = 'n/a';

  aaDataLayer.groupId = profile.currentUser.primaryPolicyNumber;
  aaDataLayer.rallyId = heartbeat.rallyId;
  aaDataLayer.externalId = heartbeat.externalId;
  aaDataLayer.loginStatus = 'loggedin';
  aaDataLayer.dependentSeqNum = profile.currentUser.dependentSeqNum;
  aaDataLayer.memberStatus = ProfileService.getMemberStatus(profile.currentUser);
  aaDataLayer.zipcode = profile.currentUser.userInfo.zipCode ? profile.currentUser.userInfo.zipCode : notAvailable;
  aaDataLayer.clientId = profile.currentUser.userInfo.primaryCustomerId
    ? profile.currentUser.userInfo.primaryCustomerId
    : notAvailable;
  aaDataLayer.clientName = profile.clientInfo ? profile.clientInfo.displayName : notAvailable;

  aaDataLayer.memberPlansInfo = profile.currentUser.planCoverages.map(coverage => {
    return {
      aco:
        coverage.additionalCoverageInfo && coverage.additionalCoverageInfo.aco
          ? coverage.additionalCoverageInfo.aco
          : notAvailable,
      coverageTypeCode: coverage.coverageTypeCode ? coverage.coverageTypeCode : notAvailable,
      fundingArrangementCode: coverage.planFeatures.fundingArrangementType
        ? coverage.planFeatures.fundingArrangementType
        : notAvailable,
      govtProgramTypeCode: coverage.planFeatures.programType ? coverage.planFeatures.programType : notAvailable,
      memberId: coverage.memberId.id,
      policyNumber: coverage.policyNumber,
      status: coverage.planPeriod.status,
    } as IAAMemberPlan;
  });
}

export function updateUserFeedbackPath(): void {
  const pdl = getAADataLayer();
  if (pdl.userFeedback) {
    pdl.userFeedback.path = window.location.pathname;
  }
}

export function addUserFeedbackToPdl(): void {
  const pdl = getAADataLayer();
  pdl.userFeedback = {
    clientId: pdl.clientId,
    depSeqNumber: pdl.dependentSeqNum,
    lob: pdl.lineOfBusiness,
    memberId: pdl.externalId,
    memberType: pdl.membershipCategory,
    path: window.location.pathname,
    policyNumber: pdl.groupId,
    rallyId: pdl.rallyId,
    status: pdl.memberStatus,
  };
}
