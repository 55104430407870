import React, { FunctionComponent, HTMLAttributes } from 'react';
import { IIdCard } from 'scripts/api/plans/plans.interfaces';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { hasActiveOrFutureUser } from 'scripts/util/plans/plans';
import { getIdCards } from 'scripts/thunks/plans-service-thunks';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { IdCardActions } from './id-card-actions';
import { IdCardFlippable } from './id-card-flippable';
import classNames from 'classnames';

interface IIdCardContainerProps {
  idCards: IIdCard[][];
  coverage: ICoverageSection;
  currentUser: IProfileUser;
  users: IProfileUser[];
  isCS: boolean;
  csMedicalSupportPhoneNum?: string;
  population?: IPopulation;
  idCardsError: boolean;
  idCardsLoading: boolean;
  flipCard: () => void;
  flipped: boolean;
}

const Div: FunctionComponent<HTMLAttributes<HTMLElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

export const IdCardContainer: FunctionComponent<IIdCardContainerProps> = props => {
  const {
    currentUser,
    idCards,
    coverage,
    users,
    isCS,
    csMedicalSupportPhoneNum,
    population,
    idCardsError,
    idCardsLoading,
    flipCard,
    flipped,
  } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.ID_CARDS]);
  const isEmpire = currentUser.membershipCategory === MembershipCategory.EMPIRE;
  let currentCoverageIdCards: IIdCard[] = [];

  if (idCards) {
    const allIdCardsFlat: IIdCard[] = [].concat(...idCards);
    currentCoverageIdCards = allIdCardsFlat.filter(idCard => {
      if (coverage.coverageTypeCode && idCard.coverageTypeCode) {
        return idCard.cardType === coverage.coverageType && idCard.coverageTypeCode === coverage.coverageTypeCode;
      } else {
        return idCard.cardType === coverage.coverageType;
      }
    });
  }

  const handleRetry = (): void => {
    getIdCards();
  };

  return (
    <>
      {hasActiveOrFutureUser(coverage, users) && coverage.planFeatures.hasIdCard && !isEmpire && (
        <DivWithWaitFor
          className="id-card-container"
          track-label={'id-card-' + coverage.coverageType}
          error={idCardsError}
          errorMessage={'ERR_' + coverage.coverageType + '_ID'}
          onRetry={handleRetry}
          loading={idCardsLoading}
          data-testid="id-card-container"
        >
          <span aria-live="assertive" aria-label={t(`${Dictionary.ID_CARDS}:ID_CARD_LOADED`)} />
          {currentCoverageIdCards &&
            currentCoverageIdCards.length > 0 && [
              currentCoverageIdCards.map(card => {
                return (
                  //  TODO:  add print-element to the div below in ARC-8376: Migrate print directive
                  <div
                    key={'container-' + card.back.length + card.front.length + card.cardType}
                    className={classNames('flip-container', { flip: flipped }, { tall: card.generatedImage })}
                  >
                    <IdCardFlippable
                      currentUser={currentUser}
                      card={card}
                      flipped={flipped}
                      population={population}
                      csMedicalSupportPhoneNum={csMedicalSupportPhoneNum}
                    />
                  </div>
                );
              }),
              <div className="card-actions" key={'card-actions-' + coverage.planKey + coverage.coverageType}>
                <IdCardActions
                  currentCoverageIdCards={currentCoverageIdCards}
                  coverage={coverage}
                  flipCard={flipCard}
                  flipped={flipped}
                  isCS={isCS}
                  currentUser={currentUser}
                  users={users}
                  population={population}
                />
              </div>,
            ]}
        </DivWithWaitFor>
      )}
    </>
  );
};
