import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IProgressBarProps {
  amount: number;
  max: number;
}

export const ProgressBar: FunctionComponent<IProgressBarProps> = props => {
  const { amount, max } = props;
  const { t } = useTranslation([Dictionary.COMMON]);
  const percentage = max > 0 ? ((amount / max) * 100).toFixed(0) + '%' : '0%';
  const addDivider = percentage !== '0%' && percentage !== '100%';
  return (
    <div
      className={classNames('progress-bar', { 'border-right': addDivider })}
      role="progressbar"
      title={`${percentage} ${t('SPENT')}`}
      data-testid="progress-bar"
      aria-valuenow={amount}
      aria-valuemin={0}
      aria-valuemax={max}
      style={{ width: percentage }}
    >
      <span className="sr-only">
        {' '}
        {percentage} <span> {t('SPENT')} </span>{' '}
      </span>
    </div>
  );
};
