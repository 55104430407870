import moment from 'moment';
import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  ICoverageTimePeriod,
  IFullName,
  IResponse,
  ITimePeriod,
  RelationshipType,
} from '../api.interfaces';
import { AccountType } from '../ledger/ledger.interfaces';

export enum CsPrimaryCustomerId {
  CAMCMP = '0CAMCMP',
  KSKCCH = '0KSKCCH',
  KSKCMD = '0KSKCMD',
  MAUHCSCO = 'MAUHCSCO',
  NYCHP = 'NYCHP',
  NYEPP = 'NYEPP',
  OHMMEP = '0OHMMEP',
  OHPHCP = '0OHPHCP',
  PACHIP = '0PACHIP',
}

export enum Product {
  ClickToChat = 'CLICKTOCHAT',
  DVCN = 'DVCN',
  EasyConnect = 'EASYCONNECT',
  IHR = 'IHR',
  LAWW = 'LAWW',
  MemberSupport = 'MEMBERSUPPORT',
  NurseChat = 'NURSECHAT',
  NurseLine = 'NURSELINE',
  RallyConnect = 'RALLYCONNECT',
  RallyEngage = 'RALLYENGAGE',
  RX = 'RX',
  SecureMessage = 'SECUREMESSAGE',
  W500 = 'W500',
}

export enum RxCoverageType {
  CRX = 'CRX',
  ORX = 'ORX',
  RX = 'RX',
}

export enum RxCarveOutProvider {
  Caremark = 'CAREMARK',
  ExpressScripts = 'EXPRESS_SCRIPTS',
  Optum = 'OPTUM',
  Walgreens = 'WALGREENS',
}

export enum PcpMemberState {
  NoPcpSelected = 'NO_PCP_SELECTED',
  PcpChangeInProgress = 'PCP_CHANGE_IN_PROGRESS',
  PcpSelected = 'PCP_SELECTED',
}

export enum LineOfBusiness {
  CS = 'C&S',
  EI = 'E&I',
  MR = 'M&R',
}

export enum MembershipCategory {
  AARP = 'AARP',
  EMPIRE = 'EMPIRE',
  EXPATRIATE = 'EXPATRIATE',
  JPMC = 'JPMC',
  MEDICA = 'MEDICA',
  OXFORD = 'OXFORD',
  PCP = 'PCP',
  EXCHANGE = 'EXCHANGE',
  PUBLIC_EXCHANGE = 'PUBLIC_EXCHANGE',
  RETIREE = 'RETIREE',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  TEXAS_ERS = 'TEXAS_ERS',
  UHC = 'UHC',
  UHC_MEDICA = 'UHC_MEDICA',
  UHC_WEST = 'UHC_WEST',
}

export enum Aco {
  CDP = 'CDP',
  DP = 'DP',
}
export type Acos = Aco[];

export enum FundingType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
}

export enum ProgramType {
  Federal = 'FEDERAL',
  Ship = 'SHIP',
}

export enum Gender {
  female = 'FEMALE',
  male = 'MALE',
  unknown = 'UNKNOWN',
}

export enum ProviderType {
  Dental = 'D',
}

export enum FpcPcpType {
  Behavioral = 'BEHAVIORAL',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
}

export enum PcpType {
  Dental = 'DENTAL',
  Facility = 'FACILITY',
  Group = 'GROUP',
  Physician = 'PHYSICIAN',
}

export enum State {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum YesNo {
  Y = 'Y',
  N = 'N',
}

export enum EftAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export enum PhoneType {
  phone = 'phone',
  fax = 'fax',
}

export enum LisLevel {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export interface IProfileUserInfo extends IFullName {
  dob: moment.Moment;
  age: number;
  gender: Gender;
  zipCode?: string;
  primaryCustomerId?: string;
  state?: State;
}

export interface IMemberId {
  id: string;
}

export interface IPlanCoverage {
  planKey: string;
  coverageType: CoverageType;
  coverageTypeCode?: CoverageTypeCode;
  additionalCoverageInfo: IAdditionalCoverageInfo;
  additionalCoverageTypes: CoverageType[];
  policyNumber: string;
  memberId: IMemberId;
  planPeriod: ICoverageTimePeriod;
  planFeatures: IPlanFeatures;
  rxCoverageInfo?: IRxCoverageInfo;
}

export interface IDependent {
  sequenceNumber: string;
}

export interface IDependentAndCoverageTypes {
  dependent: IDependent;
  claimTypes: CoverageType[];
}

export interface IHealthcareCoveragesResponse extends IResponse<IDependentAndCoverageTypes[]> {}

export interface IAdditionalCoverageInfo {
  aco?: Aco;
  marketType?: string;
  obligorId?: string;
  productCode?: string;
  sharedArrangementCode?: string;
}

export interface IPlanFeatures {
  fundingArrangementType?: FundingType;
  hasIdCard: boolean;
  hasPcpReferrals: boolean;
  hsaEligible: boolean;
  isDVCN: boolean; // Virtual Visit Eligibility
  isEmergentWrap: boolean;
  isEssentialHealthBenefitsPlan: boolean;
  isIndividualIdCard: boolean;
  isSupplemental: boolean;
  pcpEligible: boolean;
  pcdEligible: boolean;
  phiRestricted: boolean;
  programType?: ProgramType;
  premiumPaymentEligible: boolean;
  lisLevel?: LisLevel;
  fpcEligible: boolean;
  planId?: string;
}

export interface IRxCoverageInfo {
  coverageType: RxCoverageType;
  carveOutProvider?: RxCarveOutProvider;
  isExternallyManaged: boolean;
}

export interface IFinancialAccount {
  accountType: AccountType;
  policyNumber: string;
  accountId: string;
}

export interface IArcadeClient {
  displayName: string;
  logo?: string;
  hasCustomContent: boolean;
  clientId: string;
  configId: string;
}

export interface IProfileUser {
  rallyId?: string;
  personId?: string;
  userInfo: IProfileUserInfo;
  lineOfBusiness: LineOfBusiness;
  membershipCategory?: MembershipCategory;
  dependentSeqNum: string;
  relationshipType: RelationshipType;
  planCoverages: IPlanCoverage[];
  financialAccounts: IFinancialAccount[];
  memberFeatures: IMemberFeatures;
  pvrc?: IPlanVariations;
  primaryPolicyNumber: string;
  acos?: Acos;
}

export interface ICommunicationPreferenceStep {
  stepNeeded: boolean;
  communicationEmail?: string;
}

export interface IOnboardingSteps {
  communicationPreference: ICommunicationPreferenceStep;
}

export interface IProfile {
  rallyId: string;
  currentUser: IProfileUser;
  dependents: IProfileUser[];
  clientInfo?: IArcadeClient;
  onboardingSteps?: IOnboardingSteps;
}

export interface IContactHours {
  startTime: moment.Moment;
  endTime: moment.Moment;
  timeZone: string;
  frequencyValue: string;
  isTwentyFourSeven: boolean;
}

export interface IContactInfo {
  phoneNum?: string;
  phoneExt?: string;
  url?: string;
  reciprocityId?: string;
  hours?: IContactHours[];
}

export interface IMemberFeatures {
  activateEligible: boolean;
  hsaEligible: boolean;
  viewRestricted: boolean;
  pcpEligible: boolean;
  pcdEligible?: boolean;
  hasPcpReferrals: boolean; // PCP referrals eligibility
  premiumPaymentEligible: boolean;
  fpcEligible: boolean;
  isDsnp?: boolean;
}

export interface IProduct {
  contactInformation?: IContactInfo;
  planInformation?: IPlanInformation;
  productId: Product;
  miscellaneousInfo?: IMiscellaneousInfo;
}

export interface IPlanInformation {
  networkId?: string;
}

export interface IMiscellaneousInfo {
  sourceSystem?: string;
  client?: string;
  consortium?: string;
  uniqueProduct?: string;
  dedicatedQueue?: string;
  offshoreRestricted?: string;
}

export interface IProducts {
  rallyConnect: IProduct;
  rallyEngage: IProduct;
  nurseChat: IProduct;
  nurseLine: IProduct;
  easyConnect: IProduct;
  clickToChat: IProduct;
  secureMessage: IProduct;
  medicalSupport: IProduct;
  technicalSupport: IProduct;
  rx: IProduct;
  individualHealthRecord: IProduct;
  liveAndWorkWell: IProduct;
  nurseHealthLine: IProduct;
  silverSneakers: IProduct;
  visionDiscount: IProduct;
  atYourBest: IProduct;
  dime: IProduct;
  dime2: IProduct;
  providerDiscountNetwork: IProduct;
  myCarePath: IProduct;
  dental: IProduct;
  core_mpe_rallyRx: IProduct;
}

export interface ICoverageInfo {
  coverageTypes: { [key: string]: boolean };
  coverageTypeCodes: { [key: string]: boolean };
  numCoverages: number;
}

export interface IPcpPcdEligibility {
  pcp: boolean;
  pcd: boolean;
}

export interface IProfileResponse extends IResponse<IProfile> {}

export interface IProductsResponse extends IResponse<{ products: IProducts }> {}

export interface IProfileInfoForRallyPayResponse extends IResponse<{ encryptedProfileInfo: string }> {}

export interface IPrimaryCareFpcResponse extends IResponse<IPrimaryCareFpc> {}

export interface IPrimaryCareResponse extends IResponse<{ perMemberResults: IPrimaryCarePerMember }> {}

export interface IPrimaryCareFpc {
  lockedIn?: boolean;
  dependentId: string;
  doctorDetails: IPrimaryCareFpcPhysician[];
}

export interface IPrimaryCarePerMember {
  [depSeqNum: string]: IPrimaryCareUserInfo;
}

export interface IPrimaryCareUserInfo {
  memberState: PcpMemberState;
  lockedIn?: boolean;
  primaryCarePhysicians: IPrimaryCarePhysician[];
}

export interface IPhysician extends IFullName {
  providerId?: string;
}

// Only added what we care about. See BE 'SundogDental' and 'Provider' classes for full sundog model.
// arcade-profile/web/app/com/rallyhealth/arcade/profile/models/SundogDental.scala
export interface ISundogInfo {
  rallyId: string;
  dependentSequenceNumber: string;
  provider: ISundogProvider;
}

export interface ISundogProvider {
  id: string;
  lastName: string;
  firstName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
}

export interface ISundogResponse extends IResponse<ISundogInfo> {}

export interface IAddress {
  city: string;
  county?: string;
  state: State;
  street1: string;
  street2?: string;
  zipCode: string;
}

export interface IPhone {
  number: string;
  phoneType: string;
}

export interface IPrimaryCareFpcPhysician extends IPhysician {
  address: IAddress;
  degree?: string;
  pcpId: string;
  pcpType: FpcPcpType;
  phones: IPhone[];
  status: CoverageStatus;
}

export interface IPrimaryCarePhysician extends IPhysician {
  addressSequenceNumber?: string;
  effectiveDate: moment.Moment;
  pcpType: PcpType;
  status: CoverageStatus;
}

export interface IReferralsResponse extends IResponse<{ perMemberReferrals: IReferralsPerMember }> {}

export interface IReferralsPerMember {
  [depSeqNum: string]: IReferralsUserInfo;
}

export interface IReferralsUserInfo {
  totalNumberOfActiveReferrals: number;
  referrals: IReferralInfo[];
  error?: string;
}

export interface IReferralInfo {
  approvedUnitCount?: string;
  effectiveDateRange?: ITimePeriod;
  patientName?: string;
  referralNumber: string;
  referralStatus: CoverageStatus;
  referrer?: IPhysician;
  remainingUnitCount?: string;
  servicer?: IPhysician;
}

export interface IPlanVariations {
  planVariationCode?: string;
  reportingCode?: string;
}

export interface ICommunicationPreferences {
  hasAgreedToPaperlessCommunication?: boolean;
  paperlessEmailAddress?: string;
}

export interface IProviderVideo {
  groupIdEncrypted: string;
  providerVideoKey: string;
  subscriberIdEncrypted: string;
}

export interface IProviderVideoKeyResponse extends IResponse<IProviderVideo> {}

export interface IPrimaryCareInfo {
  dependentSequenceNumber: string;
  providerType: ProviderType;
  providerId?: number;
}

export interface IMarkPaid {
  showDisclaimer: boolean;
}

export interface IClaimsPreferences {
  markAsPaid: IMarkPaid;
}

export interface IAutoSubmissionPreferences {
  autoSubmissionIndicator: YesNo;
  accountDetailTypeCode: AccountType;
  startDate: moment.Moment;
  stopDate: moment.Moment;
}

export interface IAutoSubmissionUpdatePreferences {
  autoSubmissionIndicator: YesNo;
  accountDetailTypeCode: AccountType;
  startDate: string;
  stopDate: string;
}

export interface IEftPreferences {
  prefStatusCode?: YesNo;
  accountNumber?: string;
  accountType?: EftAccountType;
  bankName?: string;
  eftHierarchyType?: string;
  employerPlanType?: string;
  planType?: string;
  routingNumber?: string;
}

export interface IUserPreferences {
  claimsPreferences?: IClaimsPreferences;
  communicationPreferences: ICommunicationPreferences;
  autoSubmissionPreferences?: IAutoSubmissionPreferences;
  eftPreferences?: IEftPreferences;
}

export interface IUserPreferencesResponse extends IResponse<IUserPreferences> {}

export interface IClaimsPreferencesResponse extends IResponse<IClaimsPreferences> {}

export interface IInstamedMobileToken {
  ssoPayload: string;
}

export interface IInstamedMobileTokenResponse extends IResponse<IInstamedMobileToken> {}
