import React, { FunctionComponent } from 'react';
import { getMoneyValue } from 'scripts/util/money/money';

interface IProgressZeroMaxProps {
  max: number;
}

export const ProgressZeroMax: FunctionComponent<IProgressZeroMaxProps> = props => {
  const { max } = props;
  const moneyMax = getMoneyValue(max);
  const moneyZero = getMoneyValue(0);
  return (
    <div className="progress-zero-max" aria-hidden="true" data-testid="progress-zero-max">
      <span className="progress-zero"> {moneyZero} </span>
      <span className="progress-max"> {moneyMax} </span>
    </div>
  );
};
