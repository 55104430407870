import { Aco, Acos, IProfileUser, LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import CONFIG from '../constants/config';
import {
  BRANDS,
  DEFAULT_LINE_OF_BUSINESS,
  DEFAULT_MEMBERSHIP_CATEGORY,
  POPULATION_PATHS,
} from './population.constants';
import { IBaseUrls, IBrand, IPopulation } from './population.interfaces';

export function getBaseUrls(lob?: LineOfBusiness, membershipCategory?: MembershipCategory): IBaseUrls {
  const baseUrls: IBaseUrls = {
    advantageUrl: CONFIG.ARCADE_WEB_RALLY_ADVANTAGE_URL,
    connectUrl: CONFIG.ARCADE_WEB_RALLY_CONNECT_URL,
    myUhcBaseUrl: CONFIG.ARCADE_WEB_MYUHC_BASE_URL,
    myUhcLegacyEnBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_BASE_URL,
    myUhcLegacyEsBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_ES_BASE_URL,
    myUhcLegacyIlBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_ILO_BASE_URL,
    myUhcLegacyZhBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_ZH_BASE_URL,
    myUhcLegacyViBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_VI_BASE_URL,
    myUhcLegacyKoBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_KR_BASE_URL,
    myUhcLegacyHtBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_HT_BASE_URL,
    myUhcLegacyTlBaseUrl: CONFIG.ARCADE_WEB_MYUHC_LEGACY_TAG_BASE_URL,
    healthRecordUrl: CONFIG.ARCADE_WEB_RALLY_IHR_URL,
    rewardsUrl: CONFIG.ARCADE_WEB_RALLY_REWARDS_URL,
    rxUrl: CONFIG.ARCADE_WEB_RALLY_RX_URL,
    engageUrl: CONFIG.ARCADE_WEB_RALLY_ENGAGE_URL,
  };
  if (lob === LineOfBusiness.MR) {
    switch (membershipCategory) {
      case MembershipCategory.AARP:
        baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_AARP_BASE_URL || baseUrls.myUhcBaseUrl;
        break;
      case MembershipCategory.MEDICA:
        baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_MEDICA_BASE_URL || baseUrls.myUhcBaseUrl;
        break;
      case MembershipCategory.PCP:
        baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_PCP_BASE_URL || baseUrls.myUhcBaseUrl;
        break;
      case MembershipCategory.RETIREE:
        baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_RETIREE_BASE_URL || baseUrls.myUhcBaseUrl;
        break;
      default:
        baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_MEDICARE_BASE_URL || baseUrls.myUhcBaseUrl;
    }
  } else if (lob === LineOfBusiness.CS) {
    baseUrls.myUhcBaseUrl = CONFIG.ARCADE_WEB_MYUHC_COMMUNITY_BASE_URL || baseUrls.myUhcBaseUrl;
  }
  return baseUrls;
}

export function getIsEmpire(population: IPopulation): boolean {
  if (!population) {
    return false;
  }
  return population.membershipCategory && population.membershipCategory === MembershipCategory.EMPIRE;
}

function getBrand(lob: LineOfBusiness, mc: MembershipCategory, acos?: Acos): IBrand {
  const aco = acos && acos.indexOf(Aco.CDP) >= 0 && Aco.CDP;
  return BRANDS[lob][aco] || BRANDS[lob][mc] || BRANDS[lob].DEFAULT;
}

function isValidType(type: any, instance: any): boolean {
  return Object.keys(type).filter(key => type[key] === instance).length > 0;
}

export function getPopulationByUri(uri: string = ''): IPopulation {
  const paths = POPULATION_PATHS;
  let lineOfBusiness = DEFAULT_LINE_OF_BUSINESS;
  let membershipCategory = DEFAULT_MEMBERSHIP_CATEGORY;
  for (const lob of Object.keys(paths)) {
    for (const category of Object.keys(paths[lob]).sort(mc => (mc === 'DEFAULT' ? 1 : -1))) {
      if (paths[lob][category].trim() === uri.toLowerCase().trim()) {
        lineOfBusiness = lob as LineOfBusiness;
        membershipCategory = category as MembershipCategory;
      }
    }
  }
  return {
    lineOfBusiness,
    membershipCategory,
    uri: POPULATION_PATHS[lineOfBusiness][membershipCategory].trim(),
    brand: getBrand(lineOfBusiness, membershipCategory),
    baseUrls: getBaseUrls(lineOfBusiness, membershipCategory),
  };
}

export function getPopulationByProfile(profileUser: IProfileUser): IPopulation {
  const { lineOfBusiness, membershipCategory, acos } = profileUser;
  const lob = isValidType(LineOfBusiness, lineOfBusiness) ? lineOfBusiness : DEFAULT_LINE_OF_BUSINESS;
  let category = isValidType(MembershipCategory, membershipCategory) ? membershipCategory : DEFAULT_MEMBERSHIP_CATEGORY;
  if (typeof POPULATION_PATHS[lob][category] === 'undefined') {
    category = DEFAULT_MEMBERSHIP_CATEGORY;
  }

  return {
    lineOfBusiness: lob,
    membershipCategory: category,
    uri: POPULATION_PATHS[lob][category].trim(),
    brand: getBrand(lob, category, acos),
    baseUrls: getBaseUrls(lob, category),
  };
}
