import { Observable } from 'rxjs/Observable';
import { SAME_TAB_PROMO_LINKS } from 'scripts/util/population/population.constants';
import { isLinkToArcade, isLinkToConnect, parse, targetingUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { getCache, getCacheKey } from '../cache';
import { IArcadeClient, LineOfBusiness } from '../profile/profile.interfaces';
import { IProfileService } from '../profile/profile.service';
import {
  IAlertsResponse,
  ICampaign,
  ICampaignResponse,
  IClientConfig,
  IClientConfigResponse,
  IIncentivesResponse,
  IRealTimeOfferCountResponse,
  IRecommendationPostData,
  IRecommendationsResponse,
  OutcomeType,
} from './targeting.interfaces';

export interface ITargetingService {
  getAlerts(rallyId: string): Observable<IAlertsResponse>;
  getCampaigns(rallyId: string, placements: string[]): Observable<ICampaignResponse>;
  getRecommendations(rallyId: string): Observable<IRecommendationsResponse>;
  getRealTimeOfferCount(rallyId: string, lob: LineOfBusiness): Observable<IRealTimeOfferCountResponse>;
  getIncentives(rallyId: string): Observable<IIncentivesResponse>;
  getClientConfig(rallyId: string): Observable<IClientConfig>;
  getCsFaqCustomizations(planName: string): Observable<any>;
  postRecommendationStateChange(rallyId: string, data: IRecommendationPostData): Observable<void>;
  shouldOpenPromoInSameTab(link: string): boolean;
}

export class TargetingService implements ITargetingService {
  constructor(private baseApiService: IBaseApiService, private profileService: IProfileService) {
    'ngInject';
    getCache(CacheName.RealTimeOfferCount).removeAll();
  }

  public getAlerts(rallyId: string): Observable<IAlertsResponse> {
    const url = targetingUris.alerts(rallyId);
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getIncentives(rallyId: string): Observable<IIncentivesResponse> {
    const url = targetingUris.incentives(rallyId);
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getCampaigns(rallyId: string, placements: string[]): Observable<ICampaignResponse> {
    const url = targetingUris.campaigns(rallyId, { placements });
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getRecommendations(rallyId: string): Observable<IRecommendationsResponse> {
    const url = targetingUris.recommendations(rallyId);
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getRealTimeOfferCount(rallyId: string, lob: LineOfBusiness): Observable<IRealTimeOfferCountResponse> {
    const url = targetingUris.realTimeOfferCount(rallyId, { lob });
    const cache = getCache(CacheName.RealTimeOfferCount);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getClientConfig(rallyId: string): Observable<IClientConfig> {
    const defaultConfig = {
      clientCustomContentId: '',
      clientConfigId: '',
      clientMetaData: {} as any,
      suppressions: {} as any,
      customLabels: {} as any,
      customMessaging: {},
      contentOverrides: {} as any,
    };

    return this.profileService.get(rallyId).flatMap(({ data }) => {
      if (data.clientInfo && data.clientInfo.hasCustomContent && data.clientInfo.configId) {
        const policyNumber = data.currentUser.primaryPolicyNumber;
        const pvrc = data.currentUser.pvrc
          ? data.currentUser.pvrc.planVariationCode + data.currentUser.pvrc.reportingCode
          : undefined;

        return this.getClientConfigFromCacheOrApi(data.clientInfo, policyNumber, pvrc)
          .map(rsp => rsp.data)
          .catch(() => Observable.of(defaultConfig));
      } else {
        return Observable.of(defaultConfig);
      }
    });
  }

  public getCsFaqCustomizations(planName: string): Observable<any> {
    const url = targetingUris.csFaqCustomizations(planName);
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public postRecommendationStateChange(rallyId: string, data: IRecommendationPostData): Observable<void> {
    const url = targetingUris.recommendations(rallyId);
    return this.baseApiService.post(url, data);
  }

  public shouldOpenPromoInSameTab(link: string): boolean {
    const anchor = parse(link);
    const hostname = anchor.hostname.indexOf('www.') === 0 ? anchor.hostname.substr(4) : anchor.hostname;
    return isLinkToArcade(link) || isLinkToConnect(link) || SAME_TAB_PROMO_LINKS[hostname];
  }

  private getClientConfigFromCacheOrApi(
    client: IArcadeClient,
    policyNumber: string,
    pvrc: string,
  ): Observable<IClientConfigResponse> {
    const url = targetingUris.clientConfig(client.configId, {
      clientId: client.clientId,
      policyNumber,
      pvrc,
    });
    const cache = getCache(CacheName.Targeting);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public static isIncentiveValid(promo: ICampaign): boolean {
    return (
      promo.metadata &&
      (promo.metadata.outcome === OutcomeType.GatekeeperActivity || promo.metadata.outcome === OutcomeType.RewardOpen)
    );
  }
}
