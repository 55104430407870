import angular from 'angular';
import 'angular-ui-router';
import { Observable } from 'rxjs';
import {
  IActivatePlansV7,
  IActivatePlanV7,
  ISwitchStep,
  ActivatePlanKey,
  isNonFtuePlan,
} from 'scripts/api/activate/activate.interfaces';
import { ACTIVATE_LINEAR_SESSION_KEY, SUPPRESS_BANNER_KEY } from 'scripts/util/constants/cookies.constant';
import { activate, connect } from 'scripts/util/resource/resource.constants';
import { sendOutOfFlowAAEvent } from 'scripts/util/tracking/adobe-analytics';
import { getBaseUrls, userUris } from 'scripts/util/uri/uri';
import { canShowRecommendations } from 'scripts/util/user/user';
import callToChangePcpTemplate from 'views/modals/call-to-change-pcp.html';
import costInfoTemplate from 'views/modals/cost-info.html';
import preferredMailTemplate from 'views/modals/preferred-mail.html';
import preferredRetailTemplate from 'views/modals/preferred-retail.html';
import standardRetailTemplate from 'views/modals/standard-retail.html';
import findCareTemplate from 'views/states/find-care.html';
import { IActivateService } from '../api/activate/activate.service';
import { IProfileResponse, LineOfBusiness, MembershipCategory } from '../api/profile/profile.interfaces';
import { AmpPageTags as Tags } from '../api/tracking/amplitude.interfaces';
import { ITrackingEventRequest, TrackingTriggerType } from '../api/tracking/tracking.interfaces';
import { ITrackingService } from '../api/tracking/tracking.service';
import { IHeartbeatResponse } from '../api/user/user.interfaces';
import { IUserService } from '../api/user/user.service';
import { BannerDismissedKeyBase } from '../util/constants/storage.constants';
import { defaultLocale } from '../util/locale/locale';
import { ILocaleService } from '../util/locale/locale.service';
import { IPopulation } from '../util/population/population.interfaces';
import { IPopulationService } from '../util/population/population.service';
import { IResourceService } from '../util/resource/resource.service';
import { IProfileService } from './../api/profile/profile.service';
import { AlertCodes } from './../api/targeting/targeting.interfaces';
import { ITargetingService } from './../api/targeting/targeting.service';
import { IFeatureFlagService } from './../util/feature-flag/feature-flag.interface';
import { ArcadeWebTheme, IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';

declare module 'angular' {
  namespace ui.extras {
    interface IStateService {
      getInactiveStates: () => IState[];
    }
  }
  namespace ui {
    interface IState {
      $$state?: () => IInternalState;
    }
    interface IInternalState {
      url: { exec: <T = any, U = any>(path: string, params?: U) => T };
      name: string;
    }
  }
}

const checkIfCookieExists = ($cookies: ng.cookies.ICookiesService): boolean => {
  const linearSessionFromCookie = $cookies.get(ACTIVATE_LINEAR_SESSION_KEY);
  if (!linearSessionFromCookie) {
    $cookies.put(ACTIVATE_LINEAR_SESSION_KEY, 'true', { expires: new Date(Date.now() + 6 * 3600 * 1000) });
    return false;
  }
  return true;
};

const checkForAutoPrompt = (data: IActivatePlansV7, $cookies: ng.cookies.ICookiesService): IActivatePlanV7 => {
  const autoPromptPlans = data.plans.filter(plan => plan.autoPrompt === true);
  if (!checkIfCookieExists($cookies) && autoPromptPlans.length > 0) {
    // There should only ever be one autoPromptPlan if there are any
    return autoPromptPlans[0];
  }
  return null;
};

export const checkForBannerSuppressionCookie = (
  $cookies: ng.cookies.ICookiesService,
  planId: ActivatePlanKey | string,
): void => {
  const suppressBanner = $cookies.get(SUPPRESS_BANNER_KEY);
  const suppressBannerObj = suppressBanner ? JSON.parse(suppressBanner) : {};
  $cookies.put(SUPPRESS_BANNER_KEY, JSON.stringify({ ...suppressBannerObj, [planId]: true }), {
    expires: new Date(Date.now() + 6 * 3600 * 1000),
  });
};

const createVideoBannerShownObservable = (
  profile: IProfileResponse,
  targetingService: ITargetingService,
  trackingService: ITrackingService,
  $timeout: ng.ITimeoutService,
  previouslyDismissed: string,
): Observable<boolean> => {
  if (profile.data.currentUser.memberFeatures.activateEligible) {
    return targetingService.getAlerts(profile.data.rallyId).map(alertsRsp => {
      const eiobAlertPresent = alertsRsp.data.alerts.some(alert => alert.code === AlertCodes.EIOB);
      this.storageBannnerKey = `${BannerDismissedKeyBase}_${profile.data.rallyId}`;
      if (eiobAlertPresent) {
        const bannerShown = previouslyDismissed !== 'true';
        if (bannerShown) {
          // Create and send AA Event
          const newEvent = {
            uri: 'dashboard',
            featureList: ['dashboard', 'video-banner'],
            trigger: TrackingTriggerType.Click,
            actionName: 'view',
            serviceVersion: 'xx',
            placement: '',
          } as ITrackingEventRequest;

          $timeout(() => {
            const dataLayer = trackingService.getCurrentAADataLayer();
            sendOutOfFlowAAEvent(newEvent, dataLayer);
          }, 1000);
        }
        return bannerShown;
      }
      return null;
    });
  }
  return Observable.of(null);
};

const createRecommendationsShownObservable = (
  $timeout: ng.ITimeoutService,
  featureFlagService: IFeatureFlagService,
  profile: IProfileResponse,
  profileService: IProfileService,
  targetingService: ITargetingService,
  trackingService: ITrackingService,
): Observable<boolean> => {
  const { currentUser: loggedInUser, rallyId } = profile.data;
  return profileService.getCurrentProfile(rallyId).flatMap(selectedUser => {
    const isLoggedInUserSelected = loggedInUser.dependentSeqNum === selectedUser.dependentSeqNum;
    const shouldRequestCount = isLoggedInUserSelected && canShowRecommendations(selectedUser, featureFlagService);
    if (shouldRequestCount) {
      return targetingService
        .getRealTimeOfferCount(rallyId, selectedUser.lineOfBusiness)
        .filter(({ data }) => !!data && data.leadPromotionCount > -1)
        .map(({ data: { leadPromotionCount } }) => leadPromotionCount)
        .map(count => count > 0 || selectedUser.lineOfBusiness !== LineOfBusiness.CS)
        .do(recsShown => {
          if (recsShown) {
            const newEvent = {
              uri: 'dashboard',
              featureList: ['dashboard', 'account-info', 'recommendations'],
              trigger: TrackingTriggerType.Click,
              actionName: 'view',
              serviceVersion: 'xx',
              placement: '',
            } as ITrackingEventRequest;

            $timeout(() => {
              const dataLayer = trackingService.getCurrentAADataLayer();
              sendOutOfFlowAAEvent(newEvent, dataLayer);
            }, 1000);
          }
        });
    }
    return Observable.of(false);
  });
};

const createRecommendationsObservable = (
  $filter: ng.IFilterService,
  rallyId: string,
  targetingService: ITargetingService,
): Observable<string[]> => {
  return targetingService
    .getRecommendations(rallyId)
    .filter(({ data }) => !!(data && data.realTimeOffers))
    .map(({ data: { realTimeOffers } }) =>
      $filter('orderBy')(realTimeOffers, 'priority', true).map(offer => offer.headline),
    );
};

function resolveUrl($state: ng.ui.IStateService, $timeout: ng.ITimeoutService): boolean | ng.IPromise<boolean> {
  'ngInject';
  // if web url was not found in the config then send the user to the internal error page
  const baseUrls = getBaseUrls();
  if (!baseUrls || baseUrls.web === undefined) {
    return $timeout(() => $state.go('unauthenticated.internalError')).then(() => {
      console.warn('unable to determine urls from config');
      throw new Error();
    });
  }
  return true;
}

function resolveHeartbeat(
  Analytics: angular.google.analytics.AnalyticsService,
  userService: IUserService,
  url: boolean,
): Promise<IHeartbeatResponse> {
  'ngInject';
  // do not attempt to resolve the heartbeat if the resolveUrl failed
  if (!url) {
    return Promise.reject();
  }
  return userService
    .getHeartbeat(false)
    .do(rsp => Analytics.set('&uid', rsp.data.rallyId))
    .toPromise()
    .then(rsp => rsp);
}

function resolveProfile(
  $state: ng.ui.IStateService,
  heartbeat: IHeartbeatResponse,
  profileService: IProfileService,
): Promise<IProfileResponse> {
  'ngInject';
  return profileService
    .get(heartbeat.data.rallyId)
    .catch(err => {
      if (err.status !== 401) {
        const uid = err.data && err.data.correlationId ? err.data.correlationId : err.statusText;
        const errorCount = err.data && err.data.errorCount ? err.data.errorCount : 0;

        // If the profile request has failed 10 times, do not let the user refresh anymore
        $state.go('unauthenticated.internalError', { refresh: errorCount < 10, errorUID: uid });
      }
      return Observable.throw(err);
    })
    .toPromise()
    .then(rsp => rsp);
}

function resolvePopulationByPath(
  $stateParams: ng.ui.IStateParamsService,
  $location: ng.ILocationService,
  populationService: IPopulationService,
): IPopulation {
  'ngInject';
  const population = populationService.getPopulation() || populationService.setPopulationByUri($stateParams.path);
  if ($stateParams.path && $stateParams.path !== population.uri) {
    $location.url(decodeURI($location.url()).replace($stateParams.path, population.uri));
  }
  return population;
}

function resolvePopulationByProfile(
  $stateParams: ng.ui.IStateParamsService,
  $location: ng.ILocationService,
  populationService: IPopulationService,
  profile: IProfileResponse,
  localeService: ILocaleService,
): IPopulation {
  'ngInject';
  const currentUser = profile.data.currentUser;
  const population = populationService.setPopulationByProfile(currentUser);
  if ($stateParams.path && $stateParams.path !== population.uri) {
    $location.url(decodeURI($location.url()).replace($stateParams.path, population.uri));
  } else if (!$stateParams.path && population.uri) {
    $location.url(population.uri + $location.url());
  }
  if (population.lineOfBusiness === LineOfBusiness.MR || population.membershipCategory === MembershipCategory.EMPIRE) {
    localeService.set(defaultLocale.id);
  }
  return population;
}

function resolveActivateStatus(
  $location: ng.ILocationService,
  $window: ng.IWindowService,
  profile: IProfileResponse,
  activateService: IActivateService,
  featureFlagService: IFeatureFlagService,
  resourceService: IResourceService,
  $cookies: ng.cookies.ICookiesService,
): Promise<string> {
  'ngInject';

  if (profile.data.currentUser.memberFeatures.activateEligible) {
    const activateResourceLink = activate;

    const tryRedirect = (newQueryParams = ''): void => {
      const queryParams = $location.search();

      const isRedirectSuppressed = queryParams && queryParams.noActivateRedirect;
      if (!isRedirectSuppressed) {
        // this prevents the page from appearing to load before the redirect
        document.body.hidden = true;
        $window.location.replace(resourceService.get(activateResourceLink) + newQueryParams);
      }
    };

    const trySwitchRedirect = (autoPrompt: ISwitchStep): void => {
      const queryParams = $location.search();

      const isRedirectSuppressed = queryParams && queryParams.noActivateRedirect;
      if (!isRedirectSuppressed) {
        // this prevents the page from appearing to load before the redirect
        document.body.hidden = true;
        $window.location.replace(autoPrompt.url);
      }
    };

    let steps: Promise<string>;
    if (featureFlagService.isActivateSwitchOn()) {
      steps = activateService
        .getStepsSwitch(profile.data.rallyId)
        .map(rsp => rsp.data)
        .flatMap(data => {
          if (data.autoPrompt) {
            const { flowId: autoPromptFlowKey, flowVerison: autoPromptFlowVersion } = data.autoPrompt;
            if (isNonFtuePlan(autoPromptFlowKey)) {
              checkForBannerSuppressionCookie($cookies, autoPromptFlowKey);
            }
            return activateService
              .postStepsSwitch(profile.data.rallyId, autoPromptFlowKey, autoPromptFlowVersion)
              .catch(() => Observable.of(undefined))
              .do(() => trySwitchRedirect(data.autoPrompt));
          }
          return Observable.of(undefined);
        })
        .catch(() => Observable.of(undefined))
        .toPromise()
        .then(rsp => rsp);
    } else {
      steps = activateService
        .getStepsV7(profile.data.rallyId)
        .map(rsp => rsp.data)
        .flatMap(data => {
          const plan = checkForAutoPrompt(data, $cookies);
          if (plan !== null) {
            const { planKey: autoPromptPlanKey, planVersion: autoPromptPlanVersion } = plan;
            if (autoPromptPlanKey && autoPromptPlanVersion) {
              if (isNonFtuePlan(autoPromptPlanKey)) {
                checkForBannerSuppressionCookie($cookies, autoPromptPlanKey);
              }
              return activateService
                .postPlanV7(profile.data.rallyId, autoPromptPlanKey, autoPromptPlanVersion)
                .catch(() => Observable.of(undefined))
                .do(() => tryRedirect(`&autoPromptPlanKey=${encodeURIComponent(autoPromptPlanKey)}`));
            }
          }
          return Observable.of(undefined);
        })
        .catch(() => Observable.of(undefined))
        .toPromise()
        .then(rsp => rsp);
    }

    return steps;
  }
}

export class Routes {
  constructor(
    $compileProvider: ng.ICompileProvider,
    $locationProvider: angular.ILocationProvider,
    $qProvider: ng.IQProvider,
    $stateProvider: angular.ui.IStateProvider,
    $urlRouterProvider: angular.ui.IUrlRouterProvider,
    Environment: IEnvironmentConstants,
  ) {
    'ngInject';
    const isAdvantage = Environment.CONFIG.ARCADE_WEB_THEME === ArcadeWebTheme.Advantage;
    $locationProvider.html5Mode({ enabled: true });
    $locationProvider.hashPrefix('');
    $qProvider.errorOnUnhandledRejections(false);
    $compileProvider.preAssignBindingsEnabled(true);
    // Any query parameter that is added to our base url when accessing our site directly will be ignored
    // This will not happen if the /dashboard route is accessed directly
    $urlRouterProvider.otherwise(($injector, $location) => $location.path().replace(/\/$/, '') + '/dashboard');

    // PLEASE READ:
    // Values for state.data.name and state.data.tags should come from product (they are used for amplitude analytics).
    // If you haven't gotten specific values from product, please don't add anything at all.
    $stateProvider
      .state({
        name: 'login',
        // To add a new query parameter to this route make sure to add it to api.module.ts as well
        url: '/login?redirect&lob&membershipCategory&locale&loginLocale',
        views: {
          page: {
            template: '<login></login>',
          },
        },
        onEnter: ($state: ng.ui.IStateService, $stateParams: ng.ui.IStateParamsService, userService: IUserService) => {
          'ngInject';
          document.title = 'Login';
          // Advantage theme means we're using Arachne.  If we're using Arachne, don't kill the existing session
          // since this state is triggered by the BaseApi Service during a 401 and killing the session will kill
          // the newly minted arcade session.
          if (!isAdvantage) {
            userService.logout(true).subscribe();
          }

          // ARC-6519: if an auth redirect happens, we can't simply go back on modal close, so pass along the referrer instead
          if ($stateParams.redirect && $stateParams.redirect.indexOf('goBackOnClose=1') > -1) {
            const newParam = document.referrer ? `goToOnClose=${document.referrer}` : 'goBackOnClose=0';
            $stateParams.redirect = $stateParams.redirect.replace('goBackOnClose=1', newParam);
          }
        },
        data: {
          title: 'T_LOGIN',
        },
      })
      .state({
        name: 'modal.overviewVideo',
        url: '/overview-video',
        template: '<overview-video></overview-video>',
        data: {
          name: 'WatchOverviewVideo',
          title: 'T_OVERVIEW_VIDEO',
        },
      })
      .state({
        name: 'modal.overviewVideoTranscript',
        url: '/overview-video/transcript?{transcriptUrl}',
        template: '<overview-video-transcript></overview-video-transcript>',
        data: {
          name: 'OverviewTranscript',
          title: 'T_OVERVIEW_VIDEO',
        },
      })
      .state({
        name: 'logout',
        url: '{path:any}/logout',
        resolve: {
          populationByPath: resolvePopulationByPath,
        },
        onEnter: ($window, populationService: IPopulationService, userService: IUserService) => {
          'ngInject';
          const population = populationService.getPopulation();
          const logoutPage = userUris.idpLogoutPage(population);
          userService
            .logout()
            .finally(() => {
              $window.location.href = logoutPage;
            })
            .subscribe();
        },
      })
      .state({
        name: 'internalRedirect',
        url: '{path:any}/internal-redirect?deepLink={urlEscapedDeeplink}',
        views: {
          page: {
            template: '<internal-redirect></internal-redirect>',
          },
        },
        resolve: {
          url: resolveUrl,
          populationByPath: resolvePopulationByPath,
          heartbeat: resolveHeartbeat,
        },
        data: {
          title: 'T_REDIRECT',
        },
      })
      .state({
        name: 'authenticated',
        abstract: true,
        url: '{path:any}',
        views: {
          page: {
            template: '<div ng-if="activeSession"><chrome-container><div ui-view></div></chrome-container></div>',
          },
        },
        resolve: {
          url: resolveUrl,
          populationByPath: resolvePopulationByPath,
          heartbeat: resolveHeartbeat,
          profile: resolveProfile,
          populationByProfile: resolvePopulationByProfile,
        },
        onEnter: (userService: IUserService) => {
          'ngInject';
          userService.enableHeartbeat();
        },
        sticky: true,
        deepStateRedirect: true,
      })
      .state({
        name: 'unauthenticated',
        abstract: true,
        url: '{path:any}',
        views: {
          page: {
            template: '<div ui-view></div>',
          },
        },
        resolve: {
          populationByPath: resolvePopulationByPath,
        },
        onEnter: (userService: IUserService) => {
          'ngInject';
          userService.disableHeartbeat();
        },
        sticky: true,
        deepStateRedirect: true,
      })
      .state({
        name: 'authenticated.dashboard',
        url: '/dashboard',
        template: '<dashboard></dashboard>',
        data: {
          name: 'Dashboard',
          tags: [Tags.dashboard],
          ampKeys: ['onboardingVideoBannerShown', 'viewRecommendationsShown'],
          title: 'T_HOME',
          useSelectedProfile: true,
        },
        resolve: {
          activateStatus: resolveActivateStatus,
        },
        // NOTE: not an ES6 arrow function because we need access to the parent object via this
        onEnter(
          $timeout: ng.ITimeoutService,
          featureFlagService: IFeatureFlagService,
          profile: IProfileResponse,
          profileService: IProfileService,
          targetingService: ITargetingService,
          trackingService: ITrackingService,
        ): void {
          'ngInject';
          const storageBannnerKey = `${BannerDismissedKeyBase}_${profile.data.rallyId}`;
          const previouslyDismissed = localStorage.getItem(storageBannnerKey);

          const videoBannerShown = createVideoBannerShownObservable(
            profile,
            targetingService,
            trackingService,
            $timeout,
            previouslyDismissed,
          );
          const recsShown = createRecommendationsShownObservable(
            $timeout,
            featureFlagService,
            profile,
            profileService,
            targetingService,
            trackingService,
          );
          this.data.ampWaitForValues = Observable.zip(videoBannerShown, recsShown);
        },
      })
      .state({
        name: 'authenticated.findCare',
        url: '/find-care',
        templateUrl: findCareTemplate,
        onEnter: (
          $state: ng.ui.IStateService,
          profile: IProfileResponse,
          profileService: IProfileService,
          resourceService: IResourceService,
        ) => {
          'ngInject';
          const isMR = profile.data.currentUser.lineOfBusiness === LineOfBusiness.MR;
          if (!profileService.hasFindCare(profile.data.currentUser)) {
            $state.go('authenticated.dashboard');
          } else if (!isMR) {
            $state.go('internalRedirect', { deepLink: resourceService.get(connect) });
          }
        },
        data: {
          name: 'FindCare&CostsIntermediary',
          tags: [Tags.providers],
          title: 'T_FIND_CARE',
        },
      })
      .state({
        name: 'authenticated.claimsAndAccounts',
        abstract: isAdvantage,
        url: isAdvantage ? '' : '/claims-and-accounts',
        template: '<claims-and-accounts></claims-and-accounts>',
      })
      .state({
        name: 'authenticated.healthResources',
        url: '/health-resources',
        template: '<programs></programs>',
        data: {
          name: 'HealthResources',
          tags: [Tags.healthResources],
          title: 'T_HEALTH_RESOURCES',
        },
      })
      .state({
        name: 'authenticated.premiumPayments',
        url: '/premium-payments',
        template: '<premium-payments></premium-payments>',
        data: {
          name: 'PremiumPayments',
          title: 'T_PREMIUM_PAYMENTS',
        },
      })
      .state({
        name: 'authenticated.pharmacy',
        url: '/pharmacy',
        template: '<pharmacy></pharmacy>',
        data: {
          name: 'Pharmacies&Prescriptions',
          tags: [Tags.rx],
          title: 'T_PHARMACY',
        },
      })
      .state({
        name: 'authenticated.recommendations',
        url: '/recommendations',
        template: '<recommendations></recommendations>',
        onEnter($filter: ng.IFilterService, profile: IProfileResponse, targetingService: ITargetingService): void {
          'ngInject';

          const recs = createRecommendationsObservable($filter, profile.data.rallyId, targetingService);
          this.data.ampWaitForValues = Observable.zip(recs);
        },
        data: {
          name: 'Recommendations',
          title: 'T_RECOMMENDATIONS',
          ampKeys: ['recommendations'],
        },
      })
      .state({
        name: 'authenticated.help',
        url: '/help?referrer',
        template: '<help wait-for-container></help>',
        data: {
          name: 'Help',
          tags: [Tags.help],
          title: 'T_HELP',
        },
      })
      .state({
        name: 'authenticated.pcpReferrals',
        url: '/pcp-referrals',
        template: '<pcp-referrals></pcp-referrals>',
        data: {
          name: 'PcpReferrals',
          tags: [Tags.pcp],
          title: 'T_PCP_REFERRALS',
        },
      })
      .state({
        views: {
          'page@': {
            template: '<div ng-if="activeSession"><div ui-view></div></div>',
          },
        },
        abstract: true,
        name: 'authenticated.onboarding',
        url: '/onboarding',
      })
      .state({
        name: 'unauthenticated.internalError',
        url: '/internal-error?{refresh:bool}&{errorUID:string}',
        template: '<internal-error></internal-error>',
        onEnter: (
          $state: ng.ui.IStateService,
          $stateParams: ng.ui.IStateParamsService,
          $timeout: ng.ITimeoutService,
          profileService: IProfileService,
          userService: IUserService,
        ) => {
          'ngInject';
          if ($stateParams.refresh) {
            userService.enableHeartbeat();
            userService
              .getHeartbeat(false)
              .let(profileService.toProfile())
              .subscribe(
                () =>
                  $timeout(() => {
                    $state.go('authenticated.dashboard');
                  }),
                console.warn,
              );
          }
        },
        data: {
          name: 'InternalError',
          tags: [Tags.error],
          title: 'T_INTERNAL_ERROR',
        },
      })
      .state({
        name: 'unauthenticated.unauthorizedError',
        url: '/unauthorized-error?errorUID&errorReason',
        template: '<unauthorized-error></unauthorized-error>',
        data: {
          name: 'UnauthorizedError',
          tags: [Tags.error],
          title: 'T_UNAUTHORIZED_ERROR',
        },
      })
      .state({
        name: 'unauthenticated.seeYouLater',
        url: '/see-you-later?destination',
        template: '<see-you-later></see-you-later>',
        data: {
          name: 'SeeYouLaterExternalNav',
          title: 'T_SEE_YOU_LATER',
        },
      })
      .state({
        name: 'modal',
        url: '{path:any}/modal?{goBackOnClose:bool}&{goToOnClose:string}',
        abstract: true,
        views: {
          modal: {
            template: '<div ui-view track-feature="$track.features.modal"></div>',
          },
        },
      })
      .state({
        name: 'modal.idCards',
        url: '/id-cards?{flipped:bool}',
        template: '<id-cards></id-cards>',
        data: {
          name: 'IdCards',
          title: 'T_ID_CARDS',
        },
      })
      .state({
        name: 'modal.accountSelector',
        url: '/select-account',
        template: '<account-selector wait-for-container></account-selector>',
        data: {
          name: 'SelectDependent',
          tags: [Tags.dashboard],
          title: 'T_ACCOUNT_SELECTOR',
        },
      })
      .state({
        name: 'modal.costInfo',
        url: '/cost-info/:id?typeCode&deepLink',
        templateUrl: costInfoTemplate,
        controller: 'costInfoController as $ctrl',
        resolve: {
          costCopy: ($stateParams, CostCopy) => {
            'ngInject';
            return CostCopy[$stateParams.id];
          },
          serviceCosts: ($stateParams, costInfoService) => {
            'ngInject';
            return costInfoService
              .getCostsForService($stateParams.id, $stateParams.typeCode)
              .toPromise()
              .then(rsp => rsp);
          },
        },
        onExit: ($location: ng.ILocationService) => {
          'ngInject';
          $location.search('deepLink', null);
        },
        data: {
          name: 'CostInformation',
          tags: [Tags.infoModal],
          title: 'T_COST_INFO',
        },
      })
      .state({
        name: 'modal.compareDoctorCare',
        url: '/compare-doctor-care',
        template: '<compare-care></compare-care>',
        data: {
          name: 'CompareDoctorSpecialistCare',
          tags: [Tags.compareServices, Tags.infoModal, Tags.providers],
          title: 'T_COMPARE_DOCTOR_VISITS',
        },
      })
      .state({
        name: 'modal.compareImmediateCare',
        url: '/compare-immediate-care',
        template: '<compare-care is-immediate-care="true"></compare-care>',
        data: {
          name: 'CompareUrgentEmergencyCare',
          tags: [Tags.compareServices, Tags.infoModal, Tags.urgentCare, Tags.facilities],
          title: 'T_COMPARE_IMMEDIATE_CARE',
        },
      })
      .state({
        name: 'modal.tipsToSave',
        url: '/tips-to-save',
        template: '<tips-to-save></tips-to-save>',
        data: {
          name: 'TipsToSave',
          tags: [Tags.infoModal],
          title: 'T_TIPS_TO_SAVE',
        },
      })
      .state({
        name: 'modal.accountSummaryExplanation',
        url: '/account-summary-explanation',
        template: '<account-summary-explanation></account-summary-explanation>',
        data: {
          name: 'MedicalDeductible&OopMaxExplanation',
          tags: [Tags.dashboard, Tags.medical, Tags.infoModal],
          title: 'T_ACCOUNT_SUMMARY_EXPLANATION',
        },
      })
      .state({
        name: 'modal.pcpChangeInProgress',
        url: '/pcp-change-in-progress',
        template: '<pcp-change-in-progress></pcp-change-in-progress>',
        data: {
          name: 'PcpChangeInProgress',
          tags: [Tags.pcp],
          title: 'T_PCP_CHANGE_IN_PROGRESS',
        },
      })
      .state({
        name: 'modal.callToChangePcp',
        url: '/call-to-change-pcp',
        templateUrl: callToChangePcpTemplate,
        data: {
          name: 'CallToChangePcp',
          tags: [Tags.pcp],
          title: 'T_CHANGE_PCP',
        },
      })
      .state({
        name: 'modal.drugTiers',
        url: '/drug-tiers',
        template: '<drug-tiers></drug-tiers>',
        data: {
          name: 'DrugTiers',
          tags: [Tags.rx],
          title: 'T_DRUG_TIERS',
        },
      })
      .state({
        name: 'modal.preferredRetailPharmacy',
        url: '/preferred-retail-pharmacy',
        templateUrl: preferredRetailTemplate,
        data: {
          name: 'PreferredRetailPharmacy',
          tags: [Tags.rx],
          title: 'T_PREFERRED_RETAIL',
        },
      })
      .state({
        name: 'modal.standardRetailPharmacy',
        url: '/standard-retail-pharmacy',
        templateUrl: standardRetailTemplate,
        data: {
          name: 'StandardRetailPharmacy',
          tags: [Tags.rx],
          title: 'T_STANDARD_RETAIL',
        },
      })
      .state({
        name: 'modal.preferredMailServicePharmacy',
        url: '/preferred-mail-service-pharmacy',
        templateUrl: preferredMailTemplate,
        data: {
          name: 'PreferredMailOrderPharmacy',
          tags: [Tags.rx],
          title: 'T_PREFERRED_MAIL',
        },
      })
      .state({
        name: 'modal.inactive',
        url: '/inactive',
        template: '<inactive></inactive>',
        data: {
          name: 'SessionTimeoutWarning',
          title: 'T_INACTIVE',
        },
      })
      .state({
        name: 'modal.communicationPreference',
        url: '/communication-preference',
        template: '<communication-preference></communication-preference>',
        data: {
          name: 'ActivatePaperlessPreferenceEdit',
          tags: [Tags.activate],
          title: 'T_COMMUNICATION_PREFERENCE',
        },
      })
      .state({
        name: 'authenticated.pcdConfirmation',
        url: '/pcd-confirmation?{payload:string}',
        views: {
          'page@': {
            template: '<div ui-view><pcd-confirmation></pcd-confirmation></div>',
          },
        },
        data: {
          name: 'ConfirmPrimaryCareDentist',
          title: 'T_PCD_CONFIRMATION',
        },
      })
      .state({
        name: 'modal.pcpChangeUnavailable',
        url: '/pcp-change-unavailable',
        template: '<pcp-change-unavailable></pcp-change-unavailable>',
        data: {
          name: 'PCPChangeLockedIn',
          title: 'T_PCP_CHANGE_UNAVAILABLE',
        },
      });
  }
}
