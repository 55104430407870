import getAngularService from '../get-angular-service/get-angular-service';
import { LocationDescriptor } from 'history';
import { parse } from '../uri/uri';

function getPath<T>(to: LocationDescriptor<T>): string {
  if (typeof to === 'string') {
    const url = parse(to);
    return url.pathname;
  }
  return to.pathname;
}

function getSearch<T>(to: LocationDescriptor<T>): string {
  if (typeof to === 'string') {
    const url = parse(to);
    return url.search;
  }
  return to.search;
}

function getQueryParams<T>(to: LocationDescriptor<T>): {} {
  const queryStr = getSearch(to);
  const params = new URLSearchParams(queryStr);
  const obj = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

function findState<T>(to: LocationDescriptor<T>): { foundState: ng.ui.IState; foundParams: T } {
  let foundState: ng.ui.IState;
  let foundParams: T;
  const $state = getAngularService<ng.ui.IStateService>('$state');
  const states = $state.get();
  const toPath = getPath(to);
  const toQueryParams = getQueryParams(to);
  for (const state of states) {
    if (state.$$state && !state.abstract) {
      const privateState = state.$$state();
      const match = privateState.url ? privateState.url.exec(toPath, toQueryParams) : null;
      if (match) {
        foundState = state;
        foundParams = match;
        break;
      }
    }
  }
  return { foundState, foundParams };
}

// Please do not call this function directly. Instead use the <Link> component, withRouter higher-order-component, or
// useRouter hook to go to other routes in the SPA.
export function goTo<T>(to: LocationDescriptor<T>, replace: boolean = false): Promise<void> {
  const { foundState, foundParams } = findState(to);
  if (foundState) {
    const $state = getAngularService<ng.ui.IStateService>('$state');
    const location = replace ? 'replace' : true;
    // converting the Angular promise that $state.go returns to a plan JS Promise
    return new Promise((resolve, reject) =>
      $state
        .go(foundState, foundParams, { location, notify: !replace })
        .then(() => resolve())
        .catch(() => reject()),
    );
  }
  return Promise.resolve();
}
