import { Observable } from 'rxjs/Observable';
import { AnyClaimType, ClaimType, IAnyClaimDetails } from 'scripts/api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from 'scripts/api/claims/claims.service';
import { AccountType } from 'scripts/api/ledger/ledger.interfaces';
import { MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { ClaimMarkPaid } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import claimDetailsTemplate from 'views/claims-and-accounts/claims/claim-details.html';
import { sendAAPageData } from 'scripts/util/tracking/adobe-analytics';
import { isVideoAvailable } from 'scripts/util/claims/claims';

export interface IClaimDetailsParams extends ng.ui.IStateParamsService {
  claimKey: string;
  type: AnyClaimType;
  claimDetailsFrom?: string;
}

export class ClaimDetailsController implements ng.IComponentController {
  public claimDetails: IAnyClaimDetails;
  public claimDetailsRequest: Observable<IAnyClaimDetails>;
  public claimStatusVideoPresence: boolean;
  public showBreakdown: boolean;
  private claimKey: string;
  private type: AnyClaimType;

  constructor(
    private $location: ng.ILocationService,
    private $rootScope: ng.IRootScopeService,
    private $stateParams: IClaimDetailsParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private claimsService: IClaimsService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_DETAILS);
  }

  public $onInit(): void {
    this.claimKey = this.$stateParams.claimKey || this.$location.search().claimKey;
    this.type = this.$stateParams.type || this.$location.search().type;

    this.showBreakdown = this.featureFlagService.isClaimDetailsBreakdownOn(this.type);

    this.claimDetailsRequest = this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .takeWhile(() => !!(this.type && this.claimKey))
      .flatMap(({ rallyId, membershipCategory }) =>
        this.getClaimDetails(rallyId, this.claimKey, this.type, membershipCategory),
      )
      .do(claimDetails => (this.claimDetails = claimDetails));

    this.claimDetailsRequest.subscribe(() => {
      this.claimStatusVideoPresence = isVideoAvailable(this.claimDetails);
      sendAAPageData('claimStatusType', {
        pageName: 'claim-details-summary',
        claimStatusType: this.claimDetails.claimStatus,
        videoAvailable: this.claimStatusVideoPresence ? 'ssky' : '',
      });
    }, console.warn);

    this.$rootScope.$on(ClaimMarkPaid, (_, claim: IAnyClaimDetails) => {
      this.handleClaimUpdate(claim);
    });
  }

  public handleClaimUpdate = (updatedClaim: IAnyClaimDetails): void => {
    this.claimDetails = updatedClaim;
  };

  public print(): void {
    window.print();
  }

  private getClaimDetails(
    rallyId: string,
    claimKey: string,
    type: AnyClaimType,
    membershipCategory?: MembershipCategory,
  ): Observable<IAnyClaimDetails> {
    const healthcareClaimDetails$ = this.claimsService
      .getHealthcareDetails(rallyId, claimKey, type as ClaimType, membershipCategory)
      .map(rsp => rsp.data);
    const financialClaimDetails$ = this.claimsService
      .getFinancialDetails(rallyId, claimKey, type as AccountType)
      .map(rsp => rsp.data);
    return ClaimsService.getByType(this.type, healthcareClaimDetails$, financialClaimDetails$);
  }
}

export class ClaimDetailsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimDetailsController;
    this.templateUrl = claimDetailsTemplate;
  }
}
