import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { askAQuestion } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { documentUris } from 'scripts/util/uri/uri';
import claimDetailsSummaryTemplate from 'views/claims-and-accounts/claims/claim-details-summary.html';
import {
  ClaimDocumentType,
  ClaimStatus,
  ClaimVideoEligibility,
  IAnyClaimDetails,
  IClaim,
  IFinancialClaimDetails,
  IHealthcareClaimDetails,
} from '../../../../../api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from '../../../../../api/claims/claims.service';
import { AccountType } from '../../../../../api/ledger/ledger.interfaces';
import { LedgerService } from '../../../../../api/ledger/ledger.service';
import { IProfileUser, MembershipCategory } from '../../../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../../api/user/user.service';
import { Dictionary } from '../../../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../../../../util/resource/resource.service';
import { IClaimDetailsParams } from '../claim-details.component';

export class ClaimDetailsSummaryController implements ng.IComponentController {
  // Bindings
  public claimDetails: IAnyClaimDetails;
  public currentUser: IProfileUser;
  public goBack: string;
  public hasClaimNote: boolean;
  public hasSecureMessage: boolean;
  public headerDraftNumbers: string[];
  public isBookmarked: boolean;
  public loading: boolean;
  public askAQuestion: IResource;
  public showDateProcessed: boolean;
  public showMerpLabel: boolean;

  private clientConfigReq: Observable<IClientConfig>;
  private currentUserReq: Observable<IProfileUser>;
  private healthCareClaimDetail: IHealthcareClaimDetails;

  constructor(
    public $location: ng.ILocationService,
    private $state: ng.ui.IStateService,
    private $stateParams: IClaimDetailsParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_DETAILS);
    $translatePartialLoader.addPart(Dictionary.EDIT_CLAIM_NOTE);

    this.askAQuestion = askAQuestion;

    this.currentUserReq = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => (this.currentUser = currentUser));

    this.clientConfigReq = this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => (this.showMerpLabel = clientConfig && clientConfig.suppressions.showMERPLabel));
  }

  public $onInit(): void {
    this.goBack =
      this.$stateParams.claimDetailsFrom ||
      this.$state.href(this.$state.get('authenticated.claimsAndAccounts.summary'));
    this.showDateProcessed =
      !!this.claimDetails.claimDates.dateProcessed && this.claimDetails.claimStatus !== ClaimStatus.InProcess;
    this.currentUserReq.subscribe(() => undefined, console.warn);
    this.clientConfigReq.subscribe(() => undefined, console.warn);
    if (this.isHealthcareClaimDetails(this.claimDetails)) {
      this.healthCareClaimDetail = this.claimDetails as IHealthcareClaimDetails;
      this.isBookmarked = this.healthCareClaimDetail.claimManagementInfo.isSaved;
    } else {
      this.headerDraftNumbers = this.getHeaderDraftNumbers(this.claimDetails as IFinancialClaimDetails);
    }

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(() => this.isHealthcareClaimDetails(this.claimDetails))
      .flatMap(({ rallyId }) =>
        this.claimsService.getClaimNote(rallyId, (this.claimDetails as IHealthcareClaimDetails).claimKey),
      )
      .map(rsp => rsp.data)
      .subscribe(
        claimNote => (this.hasClaimNote = !!(claimNote && claimNote.note && claimNote.note.trim())),
        console.warn,
      );

    this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.profileService.getProducts(data.rallyId))
      .map(({ data }) => data.products)
      .subscribe(products => {
        this.hasSecureMessage = !!products.secureMessage;
      });
  }

  public clickBookmark(): void {
    if (this.healthCareClaimDetail) {
      this.isBookmarked = !this.isBookmarked;
      this.loading = true;
      this.userService
        .getHeartbeat()
        .let(this.profileService.toProfile())
        .map(rsp => rsp.data.currentUser)
        .flatMap(currentUser =>
          this.claimsService.saveClaim(this.isBookmarked, currentUser, this.healthCareClaimDetail),
        )
        .subscribe(
          () => (this.loading = false),
          err => {
            console.warn(err);
            this.loading = false;
            this.isBookmarked = !this.isBookmarked;
          },
        );
    }
  }

  public getExplanationOfBenefitsLink(currentUser: IProfileUser, claimDetails: IAnyClaimDetails): string {
    return this.claimsService.getExplanationOfBenefitsLink(currentUser, claimDetails);
  }

  public getServiceLineString({ serviceLines }: IHealthcareClaimDetails): string {
    return (
      serviceLines &&
      serviceLines
        .map(line => {
          return line.providedServiceInfo && line.providedServiceInfo.description;
        })
        .filter(x => x)
        .join(', ')
    );
  }

  public getEobScreenReaderLink(currentUser: IProfileUser, anyClaimDetails: IAnyClaimDetails): undefined | string {
    if (anyClaimDetails.wcagAccessible && anyClaimDetails.wcagAccessible.eob) {
      const wcagAccessible = anyClaimDetails.wcagAccessible.eob;
      return ClaimsService.getByClaim(
        anyClaimDetails,
        () => {
          const { claimType, claimKey, claimReference, eobDocument } = anyClaimDetails as
            | IClaim
            | IHealthcareClaimDetails;
          const membershipCategory =
            currentUser.membershipCategory && currentUser.membershipCategory === MembershipCategory.OXFORD
              ? currentUser.membershipCategory
              : undefined;
          return documentUris.healthcareClaimsForm(currentUser.rallyId, ClaimDocumentType.EOB, eobDocument, {
            claimType,
            claimKey,
            claimReference,
            membershipCategory,
            wcagAccessible,
          });
        },
        () => {
          const { accountType, documents } = anyClaimDetails as IFinancialClaimDetails;
          return documentUris.financialClaimsForm(currentUser.rallyId, ClaimDocumentType.EOB, documents.EOB[0], {
            accountType,
            wcagAccessible,
          });
        },
      );
    }
  }

  public isHealthcareClaimDetails(claimDetails: IAnyClaimDetails): boolean {
    return ClaimsService.getByClaim(claimDetails, true, false);
  }
  public showAskAQuestion(claimDetails: IAnyClaimDetails): boolean {
    return this.isHealthcareClaimDetails(claimDetails) && this.hasSecureMessage;
  }

  public showExplanationOfBenefits(claimDetails: IAnyClaimDetails): boolean {
    return ClaimsService.getByClaim(
      claimDetails,
      () => {
        const { eobDocument } = claimDetails as IHealthcareClaimDetails;
        return !!eobDocument;
      },
      () => {
        const { documents } = claimDetails as IFinancialClaimDetails;
        return !!(documents.EOB && documents.EOB[0]);
      },
    );
  }

  public showEobScreenReader(claimDetails: IAnyClaimDetails): boolean {
    const { wcagAccessible } = claimDetails;
    const hasWcagEob = wcagAccessible && wcagAccessible.eob;
    return ClaimsService.getByClaim(
      claimDetails,
      () => {
        return hasWcagEob;
      },
      () => {
        const { documents } = claimDetails as IFinancialClaimDetails;
        return !!(hasWcagEob && documents.EOB && documents.EOB[0]);
      },
    );
  }

  public showSubmittedClaimButton(claimDetails: IAnyClaimDetails): boolean {
    return !!this.getSubmittedClaimFormLink(this.currentUser, claimDetails);
  }

  public getSubmittedClaimFormLink(currentUser: IProfileUser, claimDetails: IAnyClaimDetails): string | undefined {
    return ClaimsService.getByClaim(
      claimDetails,
      () => undefined,
      () => {
        const { accountType, documents } = claimDetails as IFinancialClaimDetails;
        const { rallyId } = currentUser;
        if (LedgerService.isFsaAccountType(accountType) || LedgerService.isJPMCFsaAccountType(accountType)) {
          if (documents.FSAFORM && documents.FSAFORM[0]) {
            return documentUris.financialClaimsForm(rallyId, ClaimDocumentType.FsaForm, documents.FSAFORM[0], {
              accountType,
            });
          }
        } else if (LedgerService.isHraAccountType(accountType) || LedgerService.isJPMCHraAccountType(accountType)) {
          if (documents.HRAFORM && documents.HRAFORM[0]) {
            return documentUris.financialClaimsForm(rallyId, ClaimDocumentType.HraForm, documents.HRAFORM[0], {
              accountType,
            });
          }
        }
      },
    );
  }

  public showLastServiceDate(claimDetails: IAnyClaimDetails): boolean {
    const {
      claimDates: { serviceStartDate, lastServiceDate },
    } = claimDetails;
    if (!lastServiceDate) {
      return false;
    }
    const serviceStartMoment = moment.isMoment(serviceStartDate) ? serviceStartDate : moment(serviceStartDate);
    const lastServiceMoment = moment.isMoment(lastServiceDate) ? lastServiceDate : moment(lastServiceDate);
    return !serviceStartMoment.isSame(lastServiceMoment, 'day');
  }

  public showVideoExplanation(claimDetails: IAnyClaimDetails): boolean {
    if (this.isHealthcareClaimDetails(claimDetails)) {
      const { claimVideoEligibility } = claimDetails as IHealthcareClaimDetails;
      return [ClaimVideoEligibility.Eligible, ClaimVideoEligibility.FirstEligible].indexOf(claimVideoEligibility) > -1;
    } else {
      return false;
    }
  }

  public getAccountTypeAbbreviation(accountType: AccountType): string {
    return ClaimsService.getClaimAccountAbbreviation(accountType);
  }

  public getHeaderDraftNumbers(claimDetails: IFinancialClaimDetails): string[] {
    // get all non-empty draftnumbers
    const allDraftNumbers = claimDetails.serviceLines.filter(line => !!line.draftNumber).map(line => line.draftNumber);
    // remove duplicates, grab first five
    return allDraftNumbers.filter((val, index) => allDraftNumbers.indexOf(val) === index).slice(0, 5);
  }
}

export class ClaimDetailsSummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    claimDetails: '<',
  };

  constructor() {
    this.controller = ClaimDetailsSummaryController;
    this.templateUrl = claimDetailsSummaryTemplate;
  }
}
