import { ICONS } from './files';

export interface IFilesConstant {
  getIcon(key: string): string;
}

export class FilesConstant implements IFilesConstant {
  public static get constants(): IFilesConstant {
    return new FilesConstant();
  }

  public getIcon(key: string): string {
    return ICONS[key];
  }
}
