export enum ArcadeAuthType {
  Arachne = 'Arachne',
  Optum = 'Optum',
  Rally = 'Rally',
}

export enum ArcadeWebTheme {
  Advantage = 'advantage',
  UHC = 'unitedhealth',
}

export enum ArcadeEnvironment {
  Bluesteel = 'Bluesteel',
  Development = 'Development',
  Integration = 'Integration',
  Loadtest = 'Loadtest',
  Local = 'Local',
  Production = 'Production',
}

export interface IConfig {
  ARCADE_WEB_MAX_NUM_OF_ACTIVATE_INLINE_BANNERS: number;
  ARCADE_WEB_VERSION: string;
  ARCADE_WEB_THEME: string;
  ARCADE_WEB_ENVIRONMENT_NAME: string;
  ARCADE_WEB_CACHE_TTL_MS: number;
  ARCADE_WEB_ACTIVITY_TIMEOUT_MS: number;
  ARCADE_WEB_HEARTBEAT_BUFFER_MS: number;
  ARCADE_WEB_TRACKING_INTERVAL_MS: number;
  ARCADE_WEB_TRACKING_MAX_RETRY_COUNT: number;
  ARCADE_WEB_BASE_URL: string;
  ARCADE_WEB_BASE_API_URL: string;
  ARCADE_WEB_ALTERNATIVE_BASE_URLS: string;
  ARCADE_WEB_AMPLITUDE_API_KEY: string;
  ARCADE_WEB_GENESYS_URL: string;
  ARCADE_WEB_GOOGLE_ANALYTICS_ID: string;
  ARCADE_WEB_IPERCEPTIONS_KEY: string;
  ARCADE_WEB_IPERCEPTIONS_SURVEY_ID: string;
  ARCADE_WEB_ADOBE_ANALYTICS_URL: string;
  ARCADE_WEB_CAREMARK_URL: string;
  ARCADE_WEB_CS_CUSTOM_FAQ_POLICIES: string;
  ARCADE_WEB_CS_HIDE_PRIOR_AUTH_FAQ_PRIMARY_CUSTOMER_IDS: string;
  ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_CUSTOMER_IDS: string;
  ARCADE_WEB_EXPRESS_SCRIPTS_URL: string;
  ARCADE_WEB_WALGREENS_URL: string;
  ARCADE_WEB_DEFAULT_AUTH: ArcadeAuthType;
  ARCADE_WEB_HIDE_COSTS_POLICIES: string;
  ARCADE_WEB_HIDE_PCP_SECTION_POLICIES: string;
  ARCADE_WEB_SAME_TAB_PROMO_LINKS: string;
  ARCADE_WEB_CS_WHITELIST_LINKS: string;
  ARCADE_WEB_MR_WHITELIST_LINKS: string;
  ARCADE_WEB_LINK_TIMER_MS: number;
  ARCADE_WEB_RALLY_ADVANTAGE_URL: string;
  ARCADE_WEB_RALLY_AUTH_URL: string;
  ARCADE_WEB_ARACHNE_SESSION_BASE_URL: string;
  ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL: string;
  ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH: string;
  ARCADE_WEB_RALLY_CONNECT_URL: string;
  ARCADE_WEB_RALLY_ENGAGE_URL: string;
  ARCADE_WEB_RALLY_IHR_URL: string;
  ARCADE_WEB_RALLY_PAY_BASE_API_URL: string;
  ARCADE_WEB_RALLY_PAY_ENVIRONMENT: string;
  ARCADE_WEB_RALLY_PAY_WIDGET_CONFIRMATION: string;
  ARCADE_WEB_RALLY_REWARDS_URL: string;
  ARCADE_WEB_RALLY_RX_ADVANTAGE_URL: string;
  ARCADE_WEB_RALLY_RX_POLICIES: string;
  ARCADE_WEB_RALLY_RX_URL: string;
  ARCADE_WEB_NOTIFICATION_MESSAGE: string;
  ARCADE_WEB_OPTUM_AUTH_URL: string;
  ARCADE_WEB_OPTUM_AUTH_PATH: string;
  ARCADE_WEB_OPTUM_AUTH_CLIENT_ID: string;
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_CLIENT_ID: string;
  ARCADE_WEB_OPTUM_AUTH_SCOPE: string;
  ARCADE_WEB_OPTUM_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_AARP_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_MEDICA_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_PCP_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_RETIREE_AUTH_PFID: string;
  ARCADE_WEB_OPTUM_RX_FIND_AND_PRICE_DRUGS: string;
  ARCADE_WEB_OPTUM_RX_FIND_PHARMACY: string;
  ARCADE_WEB_OPTUM_RX_ORDER_PRESCIPTIONS: string;
  ARCADE_WEB_OPTUM_RX_CHECK_ORDER_STATUS: string;
  ARCADE_WEB_OPTUM_RX_SPENDING_AND_COST_SUMMARY: string;
  ARCADE_WEB_MYUHC_BASE_URL: string;
  ARCADE_WEB_MYUHC_AARP_BASE_URL: string;
  ARCADE_WEB_MYUHC_MEDICA_BASE_URL: string;
  ARCADE_WEB_MYUHC_MEDICARE_BASE_URL: string;
  ARCADE_WEB_MYUHC_PCP_BASE_URL: string;
  ARCADE_WEB_MYUHC_RETIREE_BASE_URL: string;
  ARCADE_WEB_MYUHC_COMMUNITY_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ES_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ILO_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_ZH_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_VI_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_KR_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_HT_BASE_URL: string;
  ARCADE_WEB_MYUHC_LEGACY_TAG_BASE_URL: string;
  ARCADE_WEB_MYUHC_LOGOUT_PATH: string;
  ARCADE_WEB_MYUHC_EXTEND_SESSION_PATH: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_ENDPOINT_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ENDPOINT_URL: string;
  ARCADE_WEB_SUNDAY_SKY_LOADING_URL: string;
  ARCADE_WEB_SUNDAY_SKY_SCRIPT_URL: string;
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_TOKEN: string;
  ARCADE_WEB_SUNDAY_SKY_TOKEN: string;
  ARCADE_WEB_SUNDAY_SKY_TRANSCRIPT_WHITELIST: string;
  ARCADE_WEB_CLAIMS_SEARCH_MONTHS_AGO: string;
  ARCADE_WEB_FITBIT_ENABLED_POLICIES: string;
  ARCADE_WEB_FITBIT_ENABLED_CLIENT_IDS: string;
  ARCADE_WEB_SUNDOG_PCD_CHANGE_URL: string;
  ARCADE_WEB_MR_RX_SUPPRESSION_GROUP_NUMBERS: string;
  ARCADE_WEB_MR_DONUT_SUPPRESSION_LIST: string;
}

// All Feature Flags must be booleans
export interface IFeatureFlags {
  ARCADE_FEATURES_ACTIVATE_SWITCH_ENABLED: boolean;
  ARCADE_FEATURES_ACTIVATE_VIDEO: boolean;
  ARCADE_FEATURES_ADDITIONAL_SERVICES: boolean;
  ARCADE_FEATURES_ADVANTAGE: boolean;
  ARCADE_FEATURES_ANALYTICS_DEBUG: boolean;
  ARCADE_FEATURES_AUTO_PAYMENT: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_DCSA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSADC: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSAHC: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_FSALP: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HCSA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAAP: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRAPD: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_HRASD: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_MRA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_DCSA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_FSADC: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_FSAHC: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_FSALP: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_HCSA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_HRA: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_HRAAP: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_HRAPD: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_HRASD: boolean;
  ARCADE_FEATURES_CLAIM_DETAILS_MRA: boolean;
  ARCADE_FEATURES_CLAIM_HELP_MENU_ALL_CLAIMS: boolean;
  ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN: boolean;
  ARCADE_FEATURES_DIRECT_DEPOSIT: boolean;
  ARCADE_FEATURES_EI_RECOMMENDATIONS: boolean;
  ARCADE_FEATURES_EI_VIRTUAL_VISIT_PCP_ROW_ITEM: boolean;
  ARCADE_FEATURES_EXPATS_MVP_DONUT: boolean;
  ARCADE_FEATURES_EXPATRIATE_CLAIMS_LINK: boolean;
  ARCADE_FEATURES_FPC_PCP_FOR_GATED: boolean;
  ARCADE_FEATURES_FPC_PCP_ADDITIONAL_PARAMS_REQUIRED: boolean;
  ARCADE_FEATURES_HEALTH_ASSESMENT: boolean;
  ARCADE_FEATURES_GENESYS_CHAT_CLAIMS: boolean;
  ARCADE_FEATURES_LINK_TO_INTERNATIONAL_CLAIMS: boolean;
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY: boolean;
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_CLAIM_TOTALS: boolean;
  ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_REACT: boolean;
  ARCADE_FEATURES_MR_NICE_DONUT_SUPPRESSION: boolean;
  ARCADE_FEATURES_MR_OPTUM_CLAIMS: boolean;
  ARCADE_FEATURES_MR_RALLY_CLAIMS: boolean;
  ARCADE_FEATURES_MR_PHARMACIES: boolean;
  ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS: boolean;
  ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY: boolean;
  ARCADE_FEATURES_MR_SECURE_MESSAGES: boolean;
  ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY: boolean;
  ARCADE_FEATURES_NOTIFICATION: boolean;
  ARCADE_FEATURES_OFFLINE_WEB_SUPPRESS_PCP_STRIP: boolean;
  ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL_REDIRECT: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL_TARGETING_ROLLOUT: boolean;
  ARCADE_FEATURES_PAY_NOW_MODAL_WARNING: boolean;
  ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME: boolean;
  ARCADE_FEATURES_PCP_POLARIS: boolean;
  ARCADE_FEATURES_PROFILE_RALLYPAY_CLAIM_START_DATE: boolean;
  ARCADE_FEATURES_QUICK_LINKS: boolean;
  ARCADE_FEATURES_RALLY_RX: boolean;
  ARCADE_FEATURES_REACT_ID_CARD_MODAL: boolean;
  ARCADE_FEATURES_REACT_PCP_STRIP: boolean;
  ARCADE_FEATURES_REACT_QUICK_LINKS: boolean;
  ARCADE_FEATURES_REDUX_LOGGING: boolean;
  ARCADE_FEATURES_REFERRAL_REQUIRED: boolean;
  ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO: boolean;
  ARCADE_FEATURES_SHOW_DENTAL_CLAIMS_PDF_SUBMISSION_FORM: boolean;
  ARCADE_FEATURES_SHOW_IOS_TRANSCRIPT_VIDEO: boolean;
  ARCADE_FEATURES_STATEMENTS: boolean;
  ARCADE_FEATURES_SUBMIT_CLAIM: boolean;
  ARCADE_FEATURES_SUBMIT_CLAIM_HEADER_REACT: boolean;
  ARCADE_FEATURES_SUBMIT_CLAIM_OTHER_FORMS_REACT: boolean;
  ARCADE_FEATURES_TIER_TWO: boolean;
  ARCADE_FEATURES_TIERED_BENEFITS: boolean;
  ARCADE_FEATURES_RALLY_RX_IN_PRODUCT_ELIGIBILITY: boolean;
  ARCADE_FEATURES_VIDEO_BANNER: boolean;
}

export interface IEnvironmentConstants {
  CONFIG: IConfig;
  FEATURE_FLAGS: IFeatureFlags;
}
