import { formatTrackingString, getFeatureList, getPlacement } from 'scripts/util/tracking/tracking-helper';
import {
  ICampaignTrackingInfo,
  ITrackingEventRequest,
  TrackingClickType,
  TrackingTriggerType,
} from '../../api/tracking/tracking.interfaces';

import { angularMinimumEscape } from 'scripts/util/uri/uri';
import { ICampaign } from '../../api/targeting/targeting.interfaces';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { ITrackController } from './track.interfaces';
import { ITrackingService } from '../../api/tracking/tracking.service';
import { LinkTarget } from '../../api/api.interfaces';
import { trackFeatures } from 'scripts/util/constants/track-features';

export class TrackController implements ITrackController {
  public actionName: string;
  public clickType: TrackingClickType;
  public placement: string;
  public campaign: ICampaign;
  public isInView: boolean;

  constructor(
    private $attrs: ng.IAttributes,
    private $location: ng.ILocationService,
    private $scope: ng.IScope,
    private Environment: IEnvironmentConstants,
    private trackingService: ITrackingService,
  ) {
    'ngInject';
  }

  public queueEvent(element: ng.IAugmentedJQuery, isImpression: boolean = false): void {
    if (this.actionName) {
      this.trackingService.queueEvent(this.getTrackingEventRequest(element, isImpression), isImpression);
    }
  }

  public sendAndFlushEvents(element: ng.IAugmentedJQuery): void {
    const event = this.getTrackingEventRequest(element);
    this.trackingService.queueEvent(event);
    this.trackingService.postEvents().subscribe();
  }

  private getTrackingEventRequest(element: ng.IAugmentedJQuery, isImpression: boolean = false): ITrackingEventRequest {
    const featureList = getFeatureList(element[0]);
    const placement =
      this.placement || featureList.indexOf(trackFeatures.modal) > -1 ? 'modal' : getPlacement(element[0]);

    const event = {
      trigger: isImpression ? TrackingTriggerType.View : TrackingTriggerType.Click,
      uri: this.$location.url(),
      serviceVersion: this.Environment.CONFIG.ARCADE_WEB_VERSION,
      featureList,
      actionName: formatTrackingString(this.actionName),
      placement,
    } as ITrackingEventRequest;
    if (!isImpression) {
      this.setClickTypeAndExternalLinkProps(event);
    }

    if (this.campaign && this.campaign.campaignId) {
      const campaignInfo = this.getCampaignTrackingInfo(this.campaign);
      event.campaignTrackingInfo = campaignInfo;
      event.data = campaignInfo;
    }
    return event;
  }

  private getCampaignTrackingInfo(campaign: ICampaign): ICampaignTrackingInfo {
    return {
      campaignId: campaign.campaignId,
      placementType: campaign.placementType,
    };
  }

  private setClickTypeAndExternalLinkProps(event: ITrackingEventRequest): void {
    if (!this.$attrs.href) {
      event.clickType = this.clickType || TrackingClickType.PageEvent;
      return;
    }

    const anchor = document.createElement('a');
    anchor.href = this.$attrs.href;
    // IE11: without this, anchor.hostname will be "" when this.$attrs.href is relative
    anchor.href = anchor.href; // eslint-disable-line no-self-assign
    const isInternalRedirect = anchor.pathname.split('/').pop() === 'internal-redirect';
    const isExternalLink =
      isInternalRedirect ||
      this.$attrs.target === LinkTarget.Blank ||
      anchor.hostname !== window.location.hostname ||
      this.$attrs.trackClickType === TrackingClickType.ExternalLink;

    if (isExternalLink) {
      event.clickType = TrackingClickType.ExternalLink;
      if (isInternalRedirect) {
        const decodedUrl = angularMinimumEscape(this.$attrs.href.split('deepLink=').pop());
        const redirectUrlAnchor = document.createElement('a');
        redirectUrlAnchor.href = decodedUrl;
        event.externalDomain = redirectUrlAnchor.hostname;
        event.externalUrl = decodedUrl.split('?')[0];
      } else {
        event.externalDomain = anchor.hostname;
        event.externalUrl = anchor.href.split('?')[0];
      }
    } else {
      event.clickType = TrackingClickType.StateChange;
    }
  }
}
