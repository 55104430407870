import angular from 'angular';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ISelectedFilters, FilterStateKey } from 'scripts/ui/filter/filter.interfaces';
import {
  determineDependentSeqNbr,
  getByClaim,
  getByType,
  getClaimAccountAbbreviation,
  getClaimDetailsUrl,
  getExplanationOfBenefitsLink,
  getYouMayOweAmount,
  getYouPaidAmount,
  showPayNow,
  getClaimsSearchMonthsAgo,
} from 'scripts/util/claims/claims';
import { claimUris } from 'scripts/util/uri/uri';
import { IFeatureFlagService } from '../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../util/resource/resource.service';
import { CacheName, CoverageType, ICurrencyAmount, ITimePeriod, RelationshipType } from '../api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { getCache, getCacheKey } from '../cache';
import { AccountType } from '../ledger/ledger.interfaces';
import { IPlansService } from '../plans/plans.service';
import {
  IDependentAndCoverageTypes,
  IPlanCoverage,
  IProfile,
  IProfileUser,
  MembershipCategory,
} from '../profile/profile.interfaces';
import { IProfileService, ProfileService } from '../profile/profile.service';
import { IUserService } from '../user/user.service';
import {
  AnyClaimType,
  ClaimType,
  IAllClaimSummariesResponse,
  IAnyClaim,
  IAnyClaimDetails,
  IClaim,
  IClaimActionResponse,
  IClaimNoteResponse,
  IClaimsAllResponse,
  IClaimsManageResponse,
  IClaimsNeedAttentionResponse,
  IClaimsSummaryResponse,
  IClaimTotalsResponse,
  IFinancialClaimDetailsResponse,
  IFinancialClaimsResponse,
  IGetMatchingClaimParams,
  IHealthcareClaimDetails,
  IHealthcareClaimDetailsResponse,
  IManageClaimData,
  IRallyPayClaimDetailsResponse,
} from './claims.interfaces';
import { decodeQueryParamValue } from 'scripts/ui/filter/filter-utils';

export interface IClaimsService {
  getAll(
    profile: IProfile,
    type: AnyClaimType,
    period?: ITimePeriod,
  ): Observable<IClaimsAllResponse | IFinancialClaimsResponse>;
  getClaimDetailsUrl(claim: IAnyClaim, from?: string): string;
  getFinancial(profile: IProfile, accountType: AccountType, period?: ITimePeriod): Observable<IFinancialClaimsResponse>;
  getFinancialDetails(
    rallyId: string,
    claimKey: string,
    accountType: AccountType,
  ): Observable<IFinancialClaimDetailsResponse>;
  getHealthcare(profile: IProfile, period?: ITimePeriod): Observable<IAllClaimSummariesResponse>;
  getHealthcareV3(profile: IProfile, period?: ITimePeriod): Observable<IAllClaimSummariesResponse>;
  getHealthcareV1(profile: IProfile, period?: ITimePeriod): Observable<IClaimsAllResponse>;
  getHealthcareDetails(
    rallyId: string,
    claimKey: string,
    claimType: ClaimType,
    membershipCategory?: MembershipCategory,
  ): Observable<IHealthcareClaimDetailsResponse>;
  getMatchingClaim(profile: IProfile, params: IGetMatchingClaimParams): Observable<IClaim | IHealthcareClaimDetails>;
  getNeedAttention(profile: IProfile): Observable<IClaimsNeedAttentionResponse>;
  getNeedAttentionUrl(profile: IProfile): string;
  getRallyPayClaimDetails(
    rallyId: string,
    claim: IClaim | IHealthcareClaimDetails,
    encryptedAccountInfo: string,
    encryptedProfileInfo: string,
    params: IGetMatchingClaimParams,
    membershipCategory?: MembershipCategory,
  ): Observable<IRallyPayClaimDetailsResponse>;
  getSummary(profile: IProfileUser): Observable<IClaimsSummaryResponse>;
  getTotals(profile: IProfileUser, requestedClaimType?: ClaimType): Observable<IClaimTotalsResponse>;
  togglePaid(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Observable<IClaimsManageResponse>;
  saveClaim(
    isSaved: boolean,
    profile: IProfileUser,
    claim: IClaim | IHealthcareClaimDetails,
  ): Observable<IClaimsManageResponse>;
  setClaimNote(
    rallyId: string,
    claim: IClaim | IHealthcareClaimDetails,
    noteContents: string,
  ): Observable<IClaimActionResponse>;
  getClaimNote(rallyId: string, claimKey: string): Observable<IClaimNoteResponse>;
  getExplanationOfBenefitsLink(currentUser: IProfileUser, claimDetails: IAnyClaimDetails | IAnyClaim): string;
}

export class ClaimsService implements IClaimsService {
  private financialClaimsPeriod: ITimePeriod;
  private healthcareClaimsPeriod: ITimePeriod;

  constructor(
    private $state: ng.ui.IStateService,
    private baseApiService: IBaseApiService,
    private featureFlagService: IFeatureFlagService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    getCache(CacheName.ClaimsRallyPay).removeAll();
    getCache(CacheName.ClaimNotes).removeAll();
    getCache(CacheName.ClaimsNeedAttention).removeAll();
    getCache(CacheName.ClaimsHealthcare).removeAll();
    getCache(CacheName.ClaimsFinancial).removeAll();
  }

  public togglePaid(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Observable<IClaimsManageResponse> {
    const firstServiceDate =
      (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
    const lastServiceDate =
      (claim as IClaim).lastServiceDate || (claim as IHealthcareClaimDetails).claimDates.lastServiceDate;
    const config: IManageClaimData = {
      claimKey: claim.claimKey,
      manageClaim: {
        markPaid: claim.claimManagementInfo.markPaid,
        serviceDate: firstServiceDate.format('YYYY-MM-DD'),
        lastServiceDate: lastServiceDate.format('YYYY-MM-DD'),
        providerName: claim.providerName || '',
      },
    };
    const postUrl = claimUris.manage(profile.rallyId);
    const request = this.baseApiService.post(postUrl, config).do(() => {
      getCache(CacheName.Claims).removeAll();
      getCache(CacheName.ClaimsNeedAttention).removeAll();
      getCache(CacheName.ClaimsHealthcare).removeAll();
    });
    return request;
  }

  public saveClaim(
    isSaved: boolean,
    profile: IProfileUser,
    claim: IClaim | IHealthcareClaimDetails,
  ): Observable<IClaimsManageResponse> {
    const firstServiceDate =
      (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
    const lastServiceDate =
      (claim as IClaim).lastServiceDate || (claim as IHealthcareClaimDetails).claimDates.lastServiceDate;
    const config: IManageClaimData = {
      claimKey: claim.claimKey,
      manageClaim: {
        isSaved,
        serviceDate: firstServiceDate.format('YYYY-MM-DD'),
        lastServiceDate: lastServiceDate.format('YYYY-MM-DD'),
        providerName: claim.providerName || '',
      },
    };
    return this.updateManageClaimsInfo(profile, config);
  }

  public setClaimNote(
    rallyId: string,
    claim: IClaim | IHealthcareClaimDetails,
    noteContents: string,
  ): Observable<IClaimActionResponse> {
    const firstServiceDate =
      (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
    const lastServiceDate =
      (claim as IClaim).lastServiceDate || (claim as IHealthcareClaimDetails).claimDates.lastServiceDate;
    const config: IManageClaimData = {
      claimKey: claim.claimKey,
      manageClaim: {
        claimNote: noteContents,
        markPaid: claim.claimManagementInfo.markPaid,
        serviceDate: firstServiceDate.format('YYYY-MM-DD'),
        lastServiceDate: lastServiceDate.format('YYYY-MM-DD'),
        providerName: claim.providerName || '',
      },
    };
    const postUrl = claimUris.postNote(rallyId);
    const request = this.baseApiService.post(postUrl, config).do(() => getCache(CacheName.ClaimNotes).removeAll());
    return request;
  }

  public getClaimNote(rallyId: string, claimKey: string): Observable<IClaimNoteResponse> {
    const url = claimUris.getNote(rallyId, { claimKey });
    const claimsCache = getCache(CacheName.ClaimNotes);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getAll(
    profile: IProfile,
    type: AnyClaimType,
    period?: ITimePeriod,
  ): Observable<IClaimsAllResponse | IFinancialClaimsResponse> {
    return ClaimsService.getByType(
      type,
      () =>
        this.getHealthcare(profile, period).map(rsp => {
          const claims = rsp.data.claims;
          const modifiedResponse: IClaimsAllResponse = {
            ...rsp,
            data: claims,
          };
          return modifiedResponse;
        }),
      () => this.getFinancial(profile, type as AccountType, period),
    );
  }

  public getClaimDetailsUrl(claim: IAnyClaim, from?: string): string {
    return getClaimDetailsUrl(claim, from);
  }

  public getHealthcare(
    profile: IProfile,
    period: ITimePeriod = {
      startDate: moment()
        .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M')
        .startOf('date'),
      endDate: moment().startOf('date'),
    },
  ): Observable<IAllClaimSummariesResponse> {
    return this.getHealthcareV3(profile, period);
  }

  public getHealthcareV1(
    profile: IProfile,
    period: ITimePeriod = {
      startDate: moment()
        .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M')
        .startOf('date'),
      endDate: moment().startOf('date'),
    },
  ): Observable<IClaimsAllResponse> {
    return this.plansService.showCarveoutClaims(profile).flatMap(showCarveout => {
      ClaimsService.validatePeriod(period);
      const requestedPeriod = ClaimsService.getWiderTimePeriod(period, this.healthcareClaimsPeriod);
      const params = ClaimsService.getHealthcareParams(profile, showCarveout, requestedPeriod);
      const url = claimUris.healthcare(profile.rallyId, params);
      const claimsCache = getCache(CacheName.ClaimsHealthcare);
      const cacheKey = getCacheKey(url);
      const cachedData: IClaimsAllResponse = claimsCache.get(cacheKey);

      const nonCachedSrc$: Observable<IClaimsAllResponse> = this.baseApiService
        .get(url)
        .map(rsp => {
          const claims = rsp.data;
          for (const c of claims) {
            this.baseApiService.stringToFloat(c, 'balance', 'copay', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'deductible', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'healthPlanDiscount', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'healthPlanPays', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'onlinePaidAmount', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'patientResponsibility', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'planPaidAmount', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'totalBilledAmount', 'value');
            this.baseApiService.stringToFloat(c, 'balance', 'youMayOweAmount', 'value');
          }
          return rsp;
        })
        .do(rsp => claimsCache.put(url, rsp))
        .do(() => (this.healthcareClaimsPeriod = requestedPeriod));

      return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
        const modifiedResponse = angular.copy(rsp);
        for (const c of modifiedResponse.data) {
          this.baseApiService.dateStringToMoment(c, 'lastServiceDate');
          this.baseApiService.dateStringToMoment(c, 'processedDate');
          this.baseApiService.dateStringToMoment(c, 'serviceDate');
        }

        modifiedResponse.data = modifiedResponse.data.filter(claim => {
          const serviceDate = claim.serviceDate;
          return (
            serviceDate.isSameOrAfter(period.startDate, 'date') && serviceDate.isSameOrBefore(period.endDate, 'date')
          );
        });
        return modifiedResponse;
      });
    });
  }

  public getHealthcareV3(
    profile: IProfile,
    period: ITimePeriod = {
      startDate: moment()
        .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M')
        .startOf('date'),
      endDate: moment().startOf('date'),
    },
  ): Observable<IAllClaimSummariesResponse> {
    // Make plans call
    return this.plansService.showCarveoutClaims(profile).flatMap(showCarveout => {
      // Make profile call
      return this.profileService
        .getHealthcareCoverages(profile.rallyId, showCarveout)
        .map(rsp => rsp.data)
        .flatMap(healthcareCoverages => {
          ClaimsService.validatePeriod(period);
          const requestedPeriod = ClaimsService.getWiderTimePeriod(period, this.healthcareClaimsPeriod);
          const params = ClaimsService.getHealthcareV3Params(profile, healthcareCoverages, requestedPeriod);
          const url = claimUris.healthcareV3(profile.rallyId, params);
          const claimsCache = getCache(CacheName.ClaimsHealthcare);
          const cacheKey = getCacheKey(url);
          const cachedData: IAllClaimSummariesResponse = claimsCache.get(cacheKey);
          const nonCachedSrc$: Observable<IAllClaimSummariesResponse> = this.baseApiService
            .get(url)
            .map(rsp => {
              const claims = rsp.data.claims;
              for (const c of claims) {
                this.baseApiService.stringToFloat(c, 'balance', 'copay', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'deductible', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'healthPlanDiscount', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'healthPlanPays', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'onlinePaidAmount', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'patientResponsibility', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'planPaidAmount', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'totalBilledAmount', 'value');
                this.baseApiService.stringToFloat(c, 'balance', 'youMayOweAmount', 'value');
              }
              return rsp;
            })
            .do(rsp => claimsCache.put(url, rsp))
            .do(() => (this.healthcareClaimsPeriod = requestedPeriod));

          return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
            const modifiedResponse = angular.copy(rsp);
            for (const c of modifiedResponse.data.claims) {
              this.baseApiService.dateStringToMoment(c, 'lastServiceDate');
              this.baseApiService.dateStringToMoment(c, 'processedDate');
              this.baseApiService.dateStringToMoment(c, 'serviceDate');
            }

            modifiedResponse.data.claims = modifiedResponse.data.claims.filter(claim => {
              const serviceDate = claim.serviceDate;
              return (
                serviceDate.isSameOrAfter(period.startDate, 'date') &&
                serviceDate.isSameOrBefore(period.endDate, 'date')
              );
            });
            return modifiedResponse;
          });
        });
    });
  }

  public getHealthcareDetails(
    rallyId: string,
    claimKey: string,
    claimType: ClaimType,
    membershipCategory?: MembershipCategory,
  ): Observable<IHealthcareClaimDetailsResponse> {
    const url = claimUris.healthcareDetails(rallyId, { claimKey, claimType, membershipCategory });
    const claimsCache = getCache(CacheName.ClaimsHealthcare);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'claimCoinsurance', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'claimMemberNotCovered', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'claimTotalNotCovered', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'copay', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'deductible', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'fsaPaidToProvider', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'fsaPaidToYou', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'healthPlanDiscount', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'healthPlanPay', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'hraPaidToYou', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'hraPaidToProvider', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'patientResponsibility', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'totalBilledAmount', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'youMayOweAmount', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'otherInsurancePaid', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'medicarePaid', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'claimPaymentTypes', 'payment', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'serviceAdjudication', 'allowedAmount', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'serviceAdjudication', 'chargedAmount', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'serviceAdjudication', 'coinsurance', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'serviceAdjudication', 'copay', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'serviceAdjudication', 'deductible', 'value');
        this.baseApiService.stringToFloat(
          rsp,
          'data',
          'serviceLines',
          'serviceAdjudication',
          'memberNotCoveredAmount',
          'value',
        );
        this.baseApiService.stringToFloat(
          rsp,
          'data',
          'serviceLines',
          'serviceAdjudication',
          'patientResponsibility',
          'value',
        );
        this.baseApiService.stringToFloat(
          rsp,
          'data',
          'serviceLines',
          'serviceAdjudication',
          'planPaidAmount',
          'value',
        );
        this.baseApiService.stringToFloat(
          rsp,
          'data',
          'serviceLines',
          'serviceAdjudication',
          'providerWriteOff',
          'value',
        );
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const { claimDates } = rsp.data;
      const claimDatesKeys = Object.keys(claimDates);
      for (const date of claimDatesKeys) {
        this.baseApiService.dateStringToMoment(claimDates, date);
      }

      this.baseApiService.dateStringToMoment(rsp, 'data', 'serviceLines', 'lastServiceDate');
      this.baseApiService.dateStringToMoment(rsp, 'data', 'serviceLines', 'serviceDate');

      return rsp;
    });
  }

  public getFinancial(
    profile: IProfile,
    accountType: AccountType,
    period: ITimePeriod = {
      startDate: moment()
        .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M')
        .startOf('date'),
      endDate: moment().startOf('date'),
    },
  ): Observable<IFinancialClaimsResponse> {
    ClaimsService.validatePeriod(period);
    const requestedPeriod = ClaimsService.getWiderTimePeriod(period, this.financialClaimsPeriod);
    const params = ClaimsService.getFinancialParams(profile, accountType, requestedPeriod);
    const url = claimUris.financial(profile.rallyId, params);
    const claimsCache = getCache(CacheName.ClaimsFinancial);
    const cacheKey = getCacheKey(url);
    const cachedData: IFinancialClaimsResponse = claimsCache.get(cacheKey);

    const nonCachedSrc$: Observable<IFinancialClaimsResponse> = this.baseApiService
      .get(url)
      .map(rsp => {
        const claims = rsp.data;
        for (const c of claims) {
          this.baseApiService.stringToFloat(c, 'balance', 'amountSubmitted', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'amountPaid', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'amountPending', 'value');
        }
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp))
      .do(() => (this.financialClaimsPeriod = requestedPeriod));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const modifiedResponse = angular.copy(rsp);
      const modifiedResponseClaims = modifiedResponse.data;
      for (const c of modifiedResponseClaims) {
        this.baseApiService.dateStringToMoment(c, 'lastServiceDate');
        this.baseApiService.dateStringToMoment(c, 'processedDate');
        this.baseApiService.dateStringToMoment(c, 'serviceDate');
      }

      modifiedResponse.data = modifiedResponse.data.filter(claim => {
        const serviceDate = claim.serviceDate;
        return (
          serviceDate.isSameOrAfter(period.startDate, 'date') && serviceDate.isSameOrBefore(period.endDate, 'date')
        );
      });
      return modifiedResponse;
    });
  }

  public getFinancialDetails(
    rallyId: string,
    claimKey: string,
    accountType: AccountType,
  ): Observable<IFinancialClaimDetailsResponse> {
    const url = claimUris.financialDetails(rallyId, { claimKey, accountType });
    const claimsCache = getCache(CacheName.ClaimsFinancial);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'amountPending', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'amountPlanPaid', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'balance', 'amountSubmitted', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'amountPending', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'amountSubmitted', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'fsaPaid', 'value');
        this.baseApiService.stringToFloat(rsp, 'data', 'serviceLines', 'hraPaid', 'value');
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const { claimDates } = rsp.data;
      const claimDatesKeys = Object.keys(claimDates);
      for (const date of claimDatesKeys) {
        this.baseApiService.dateStringToMoment(claimDates, date);
      }

      this.baseApiService.dateStringToMoment(rsp, 'data', 'serviceLines', 'startDate');
      this.baseApiService.dateStringToMoment(rsp, 'data', 'serviceLines', 'stopDate');
      this.baseApiService.dateStringToMoment(rsp, 'data', 'serviceLines', 'claimPaidDate');

      return rsp;
    });
  }

  public getMatchingClaim(
    profile: IProfile,
    params: IGetMatchingClaimParams,
  ): Observable<IClaim | IHealthcareClaimDetails> {
    let claims$: Observable<IClaim | IHealthcareClaimDetails>;
    const { claimId, claimKey, from = '', type, filters } = params;
    if (from.indexOf('/dashboard') > -1) {
      claims$ = this.userService
        .getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profileRsp => this.getSummary(profileRsp))
        .flatMap(rsp => rsp.data);
    } else if (from.indexOf('/claims-and-accounts/summary') > -1) {
      claims$ = this.getNeedAttention(profile).flatMap(rsp => rsp.data);
    } else if (from.indexOf('/claims-and-accounts/claim-details') > -1 && claimKey && type) {
      claims$ = this.getHealthcareDetails(profile.rallyId, claimKey, type).map(rsp => rsp.data);
    } else {
      let period: ITimePeriod;
      const parsedFilters = decodeQueryParamValue<ISelectedFilters<moment.Moment>>(filters, FilterStateKey.Filters);
      const selectedPeriod = parsedFilters && parsedFilters.values && parsedFilters.values['by-date-range'];
      if (selectedPeriod && selectedPeriod.length === 2) {
        period = { startDate: moment(selectedPeriod[0]), endDate: moment(selectedPeriod[1]) };
      }
      claims$ = this.getHealthcare(profile, period).flatMap(rsp => rsp.data.claims);
    }
    return claims$.first(claim => claim.claimId === claimId);
  }

  public getNeedAttention(profile: IProfile): Observable<IClaimsNeedAttentionResponse> {
    const url = this.getNeedAttentionUrl(profile);
    const claimsCache = getCache(CacheName.ClaimsNeedAttention);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        const claims = rsp.data;
        for (const c of claims) {
          this.baseApiService.stringToFloat(c, 'balance', 'healthPlanDiscount', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'healthPlanPays', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'patientResponsibility', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'totalBilledAmount', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'youMayOweAmount', 'value');
        }
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const claims = rsp.data;
      for (const c of claims) {
        this.baseApiService.dateStringToMoment(c, 'lastServiceDate');
        this.baseApiService.dateStringToMoment(c, 'processedDate');
        this.baseApiService.dateStringToMoment(c, 'serviceDate');
      }

      return rsp;
    });
  }

  public getRallyPayClaimDetails(
    rallyId: string,
    claim: IClaim | IHealthcareClaimDetails,
    encryptedAccountInfo: string,
    encryptedProfileInfo: string,
    params: IGetMatchingClaimParams,
    membershipCategory?: MembershipCategory,
  ): Observable<IRallyPayClaimDetailsResponse> {
    const {
      claimKey,
      claimType,
      balance: { youMayOweAmount, patientResponsibility },
    } = claim;
    const dependentSeqNbr = determineDependentSeqNbr(claim, params.dependentSeqNbr);
    const amount = ClaimsService.getYouMayOweAmount(claim);
    const currency = (youMayOweAmount || patientResponsibility || ({} as ICurrencyAmount)).iso4217;
    const url = claimUris.rallyPayClaimDetails(rallyId, {
      claimKey,
      claimType,
      d: dependentSeqNbr,
      encryptedAccountInfo,
      encryptedProfileInfo,
      membershipCategory,
      'youMayOwe.amount': amount && amount.toFixed(2),
      'youMayOwe.currency': currency,
    });
    const rallyPayClaimsCache = getCache(CacheName.ClaimsRallyPay);
    const cacheKey = getCacheKey(url);
    const cachedData = rallyPayClaimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService.get(url).do(rsp => {
      rallyPayClaimsCache.put(url, rsp);
    });

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  public getSummary(profile: IProfileUser): Observable<IClaimsSummaryResponse> {
    const url = claimUris.summary(profile.rallyId, {
      d: profile.dependentSeqNum,
      membershipCategory: profile.membershipCategory,
    });
    const claimsCache = getCache(CacheName.Claims);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        const claims = rsp.data;
        for (const c of claims) {
          this.baseApiService.stringToFloat(c, 'balance', 'healthPlanDiscount', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'healthPlanPays', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'patientResponsibility', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'totalBilledAmount', 'value');
          this.baseApiService.stringToFloat(c, 'balance', 'youMayOweAmount', 'value');
        }
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const claims = rsp.data;
      for (const c of claims) {
        this.baseApiService.dateStringToMoment(c, 'lastServiceDate');
        this.baseApiService.dateStringToMoment(c, 'processedDate');
        this.baseApiService.dateStringToMoment(c, 'serviceDate');
      }

      return rsp;
    });
  }

  public getTotals(profile: IProfileUser, requestedClaimType?: ClaimType): Observable<IClaimTotalsResponse> {
    const claimType: ClaimType = requestedClaimType || ClaimType.All;
    const { dependentSeqNum, membershipCategory, rallyId } = profile;
    const url = claimUris.totals(rallyId, { claimType, d: dependentSeqNum, membershipCategory });
    const claimsCache = getCache(CacheName.Claims);
    const cacheKey = getCacheKey(url);
    const cachedData = claimsCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        const balances = rsp.data;
        for (const b of balances) {
          this.baseApiService.stringToFloat(b, 'balance', 'healthPlanDiscount', 'value');
          this.baseApiService.stringToFloat(b, 'balance', 'healthPlanPays', 'value');
          this.baseApiService.stringToFloat(b, 'balance', 'other', 'value');
          this.baseApiService.stringToFloat(b, 'balance', 'patientResponsibility', 'value');
          this.baseApiService.stringToFloat(b, 'balance', 'totalBilledAmount', 'value');
        }
        return rsp;
      })
      .do(rsp => claimsCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const claims = rsp.data;
      for (const c of claims) {
        this.baseApiService.dateStringToMoment(c, 'timestamp');
      }

      return rsp;
    });
  }

  public getExplanationOfBenefitsLink(
    currentUser: IProfileUser,
    healthClaimOrAnyClaimDetails: IClaim | IAnyClaimDetails,
  ): string {
    return getExplanationOfBenefitsLink(currentUser, healthClaimOrAnyClaimDetails);
  }

  public getNeedAttentionUrl(profile: IProfile): string {
    const { currentUser } = profile;
    const depSeqNums = [currentUser.dependentSeqNum];
    const params = { d: undefined, membershipCategory: currentUser.membershipCategory, claimType: ClaimType.Medical };
    const dependentsWithActiveMedical = profile.dependents.filter(d => {
      const { coverageTypes } = ProfileService.getCoverageInfo(d.planCoverages);
      return coverageTypes[CoverageType.Medical];
    });
    if (currentUser.relationshipType === RelationshipType.Subscriber) {
      depSeqNums.push(...dependentsWithActiveMedical.map(d => d.dependentSeqNum));
    }
    params.d = depSeqNums;
    return claimUris.alerts(profile.rallyId, params);
  }

  // Base function for make api call to update claimManage information
  private updateManageClaimsInfo(profile: IProfileUser, config: IManageClaimData): Observable<IClaimsManageResponse> {
    const postUrl = claimUris.manage(profile.rallyId);
    const request = this.baseApiService.post(postUrl, config).do(() => {
      getCache(CacheName.Claims).removeAll();
      getCache(CacheName.ClaimsNeedAttention).removeAll();
      getCache(CacheName.ClaimsHealthcare).removeAll();
    });
    return request;
  }
  public static getByClaim<T, U>(
    claim: IAnyClaim | IAnyClaimDetails,
    healthRelated: (() => T) | T,
    financialRelated: (() => U) | U,
  ): T | U {
    return getByClaim(claim, healthRelated, financialRelated);
  }

  public static getByType<T, U>(
    type: AnyClaimType,
    healthRelated: (() => T) | T,
    financialRelated: (() => U) | U,
  ): T | U {
    return getByType(type, healthRelated, financialRelated);
  }

  public static getHealthcareParams(profile: IProfile, showCarveout: boolean, period: ITimePeriod): object {
    const { currentUser, dependents } = profile;
    const { startDate, endDate } = period;
    const depSeqNums = [currentUser.dependentSeqNum];
    if (currentUser.relationshipType === RelationshipType.Subscriber) {
      depSeqNums.push(...dependents.map(d => d.dependentSeqNum));
    }
    return {
      d: depSeqNums,
      claimTypes: ClaimsService.getClaimTypesFromCoverage(currentUser.planCoverages, showCarveout),
      processedStartDate: startDate.format('YYYY-MM-DD'),
      processedEndDate: endDate.format('YYYY-MM-DD'),
      membershipCategory: currentUser.membershipCategory,
    };
  }

  public static getHealthcareV3Params(
    profile: IProfile,
    healthcareCoverages: IDependentAndCoverageTypes[],
    period: ITimePeriod,
  ): object {
    const { currentUser } = profile;
    const { startDate, endDate } = period;
    return {
      cov: healthcareCoverages.map(c => `${c.dependent.sequenceNumber};${c.claimTypes.join(',')}`),
      processedStartDate: startDate.format('YYYY-MM-DD'),
      processedEndDate: endDate.format('YYYY-MM-DD'),
      membershipCategory: currentUser.membershipCategory,
    };
  }

  public static getFinancialParams(profile: IProfile, accountType: AccountType, period: ITimePeriod): object {
    const { currentUser, dependents } = profile;
    const { startDate, endDate } = period;
    const depSeqNums = [currentUser.dependentSeqNum];
    if (currentUser.relationshipType === RelationshipType.Subscriber) {
      depSeqNums.push(...dependents.map(d => d.dependentSeqNum));
    }
    return {
      d: depSeqNums,
      accountType,
      processedStartDate: startDate.format('YYYY-MM-DD'),
      processedEndDate: endDate.format('YYYY-MM-DD'),
    };
  }

  public static getYouMayOweAmount(claim: IClaim | IHealthcareClaimDetails): number | undefined {
    return getYouMayOweAmount(claim);
  }

  public static getYouPaidAmount(claim: IClaim): number {
    return getYouPaidAmount(claim);
  }

  public static getClaimAccountAbbreviation(type: AnyClaimType): string {
    return getClaimAccountAbbreviation(type);
  }

  public static getClaimsSearchMonthsAgo(): number {
    return getClaimsSearchMonthsAgo();
  }

  public static getClaimTypesFromCoverage(coverages: IPlanCoverage[], showCarveout: boolean): ClaimType[] {
    const { coverageTypes } = ProfileService.getCoverageInfo(coverages);
    return Object.keys(ClaimType)
      .map(claimType => ClaimType[claimType])
      .filter(claimType => {
        switch (claimType) {
          case ClaimType.Dental:
            return coverages
              .filter(c => c.coverageType === CoverageType.Dental)
              .every(c => !c.planFeatures.pcdEligible);
          case ClaimType.Rx:
            return showCarveout || coverageTypes[claimType];
          default:
            return coverageTypes[claimType];
        }
      });
  }

  public static showPayNow(claim: IClaim | IHealthcareClaimDetails, dependentSeqNbrFromParams?: string): boolean {
    return showPayNow(claim, dependentSeqNbrFromParams);
  }

  private static getWiderTimePeriod(period1: ITimePeriod, period2?: ITimePeriod): ITimePeriod {
    const startDate =
      period2 && period2.startDate.isBefore(period1.startDate, 'date') ? period2.startDate : period1.startDate;
    const endDate = period2 && period2.endDate.isAfter(period1.endDate, 'date') ? period2.endDate : period1.endDate;
    return { startDate, endDate };
  }

  private static validatePeriod(period: ITimePeriod): void {
    // ensure that the end date is not in the future
    if (period.endDate && moment().isBefore(period.endDate, 'day')) {
      period.endDate = moment();
    }
  }
}
