import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CoverageStatus, CoverageType } from 'scripts/api/api.interfaces';
import { IPlanCoverage } from 'scripts/api/profile/profile.interfaces';
import { AccountSummaryModalExplanation } from 'scripts/features/dashboard/mr-account-summary/mr-account-summary-modal-explanation';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { FeatureFlagGate } from 'scripts/ui/feature-flag-gate/feature-flag-gate';
import { InactiveCoverage } from 'scripts/ui/inactive-coverage/inactive-coverage';
import { Link } from 'scripts/ui/link/link';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getCoverage } from 'scripts/util/profile/profile';
import { selectAccumulatorsArcadeDataUpdated } from 'scripts/selectors/plans-service-selectors';

interface IAccountSummaryHeaderProps extends IAccountSummaryHeaderStateToProps {}

interface IAccountSummaryHeaderStateToProps {
  arcadeDataUpdated: moment.Moment;
  medicalCoverage: IPlanCoverage;
}

export const RawMRAccountSummaryHeader: FunctionComponent<IAccountSummaryHeaderProps> = props => {
  const { arcadeDataUpdated = moment(), medicalCoverage } = props;

  const { t } = useTranslation([Dictionary.ACCOUNT_SUMMARY, Dictionary.COMMON]);

  const TrackedViewSummaryLink = withClickTracking(Link, 'view-all');

  const planPeriod = medicalCoverage && medicalCoverage.planPeriod;
  const status = planPeriod && planPeriod.status;

  return (
    <header className={'section-header mr-account-summary-header'}>
      <h2>{t(`${Dictionary.ACCOUNT_SUMMARY}:MR_ACCOUNT_SUMMARY_HEADER`)}</h2>
      <FeatureFlagGate
        flagName={'ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY'}
        whenTrue={
          <TrackedViewSummaryLink
            data-testid={'tracked-view-summary-link'}
            className={'view-summary-link'}
            to={'/aarp/claims-and-accounts/spending-and-cost-summary'}
          >
            {t(`${Dictionary.ACCOUNT_SUMMARY}:VIEW_SUMMARY_DETAILS`)}
          </TrackedViewSummaryLink>
        }
      />
      <aside className={'hide-mobile'}>
        <AccountSummaryModalExplanation />
      </aside>
      <InactiveCoverage />
      {status === CoverageStatus.Active && (
        <div className={'summary-period'}>
          <span data-testid={'as-of-date'}>
            {t(`${Dictionary.COMMON}:AS_OF_DATE`, { date: arcadeDataUpdated.format('MM/DD/YYYY') })}
          </span>
        </div>
      )}
    </header>
  );
};

const mapStateToProps = (state: IReduxState): IAccountSummaryHeaderStateToProps => ({
  arcadeDataUpdated: selectAccumulatorsArcadeDataUpdated(state),
  medicalCoverage: getCoverage(CoverageType.Medical, currentUser.selectProfile(state).planCoverages),
});

export const MRAccountSummaryHeader = connect(mapStateToProps)(RawMRAccountSummaryHeader);
