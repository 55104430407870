import { claimDeniedQuestion } from 'scripts/util/resource/resource.constants';
import { ResourceLink } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';

export class ClaimDeniedExplanationController implements ng.IComponentController {
  public link: ResourceLink;

  constructor(private resourceService: IResourceService) {
    'ngInject';
    this.link = this.resourceService.get(claimDeniedQuestion);
  }
}
