import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Anchor } from 'scripts/ui/anchor/anchor';
import { getRxProviderMap, getRxProvider } from 'scripts/util/profile/profile';
import { IPopulation } from 'scripts/util/population/population.interfaces';

interface IIdCardRxCarveOutCoverageContentProps {
  coverage: ICoverageSection;
  population?: IPopulation;
}

const TrackedAnchor = withClickTracking(Anchor, 'access-rx-benefits');

export const IdCardRxCarveOutCoverageContent: FunctionComponent<IIdCardRxCarveOutCoverageContentProps> = props => {
  const { coverage, population } = props;
  const { t } = useTranslation(Dictionary.ID_CARDS);
  const providerMap = getRxProviderMap(population);
  const rxProvider = getRxProvider(coverage);

  return (
    <div className="clearfix coverage-content">
      <div className="col-1-3 id-card-container">
        {providerMap && rxProvider && (
          <TrackedAnchor className="cta-btn" href={providerMap[rxProvider].url} target="_blank" noRel={true}>
            {t('ACCESS_RX_BENEFITS')}
          </TrackedAnchor>
        )}
      </div>
    </div>
  );
};
