import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { IEmptyResponse } from 'scripts/api/api.interfaces';
import { AccountType, ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService } from 'scripts/api/ledger/ledger.service';
import {
  IAutoSubmissionPreferences,
  IAutoSubmissionUpdatePreferences,
  IUserPreferences,
  YesNo,
} from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { accountSettings } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import autoPaymentTemplate from 'views/claims-and-accounts/auto-payment/auto-payment.html';

export class AutoPaymentController implements ng.IComponentController {
  public accounts: ILedgerAccount[];
  public autoPayPreferences: IAutoSubmissionPreferences;
  public endDate: moment.Moment;
  public hidePaymentPromptPage = false;
  public initialPreferenceRequest: Observable<[ILedgerAccount[], IUserPreferences]>;
  public isEnrolled: boolean;
  public isCheckboxChecked: boolean;
  public preferenceChangeRequest: Observable<IEmptyResponse>;
  public accountSettings: IResource;
  public startDate: moment.Moment;
  private supportedAccountTypes: AccountType[];

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.AUTO_PAYMENT);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);

    this.accountSettings = accountSettings;

    this.supportedAccountTypes = [
      AccountType.DCSA,
      AccountType.FSADC,
      AccountType.FSAHC,
      AccountType.FSALP,
      AccountType.HCSA,
      AccountType.HRA,
      AccountType.HRAAP,
      AccountType.HRASD,
      AccountType.HSA,
      AccountType.MRA,
    ];

    this.startDate = this.setStartDate();
    this.endDate = this.setEndDate();

    const accounts$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(({ data }) => data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(({ rallyId }) => this.ledgerService.getAccounts(rallyId))
      .map(({ data }) => data.filter(account => account.balance))
      .map(accounts => accounts.filter(({ accountType }) => this.supportedAccountTypes.indexOf(accountType) > -1))
      .map(accounts => {
        const accountTypes = {};
        return accounts.filter(({ accountType }) => !accountTypes[accountType] && (accountTypes[accountType] = true));
      });

    const userPreferences$ = this.userService
      .getHeartbeat()
      .map(rsp => rsp.data)
      .flatMap(({ rallyId }) => this.profileService.getUserPreferences(rallyId))
      .map(rsp => rsp.data);

    this.initialPreferenceRequest = Observable.zip(accounts$, userPreferences$);
  }

  public $onInit(): void {
    this.initialPreferenceRequest.subscribe(([accounts, userPreferences]) => {
      const { autoSubmissionPreferences } = userPreferences;
      const { autoSubmissionIndicator } = autoSubmissionPreferences;
      this.accounts = accounts;
      this.autoPayPreferences = autoSubmissionPreferences;
      this.isEnrolled = autoSubmissionIndicator && autoSubmissionIndicator === YesNo.Y;
      this.isCheckboxChecked = this.isEnrolled;
    }, console.warn);
  }

  public goBack(): void {
    this.$window.history.back();
  }

  public submitPreferenceChange(): void {
    if (this.isEnrolled !== this.isCheckboxChecked) {
      this.hidePaymentPromptPage = true;
      const { accountDetailTypeCode, startDate, stopDate } = this.autoPayPreferences;

      const updatedPreferences: IAutoSubmissionUpdatePreferences = {
        accountDetailTypeCode,
        autoSubmissionIndicator: this.isCheckboxChecked ? YesNo.Y : YesNo.N,
        startDate: startDate.format('YYYY-MM-DD'),
        stopDate: stopDate.format('YYYY-MM-DD'),
      };

      this.preferenceChangeRequest = this.userService
        .getHeartbeat()
        .map(rsp => rsp.data)
        .flatMap(({ rallyId }) => this.profileService.setAutoPaymentPreferences(rallyId, updatedPreferences))
        // This only gets executed if the request is successful:
        .do(() => (this.isEnrolled = !this.isEnrolled));
    }
  }

  // Can refactor this later if we want startDate based on date from back-end
  private setStartDate(): moment.Moment {
    return moment().startOf('year');
  }

  // Can refactor this later if we want endDate based on date from back-end
  private setEndDate(): moment.Moment {
    return moment().endOf('year');
  }
}

export class AutoPaymentComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AutoPaymentController;
    this.templateUrl = autoPaymentTemplate;
  }
}
