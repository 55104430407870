import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import inactiveCoverageTemplate from 'views/ui/inactive-coverage.html';
import { CoverageStatus, CoverageType, ICoverageTimePeriod } from '../../api/api.interfaces';
import { IPlanCoverage } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IStateService } from '../../util/state/state.service';

export class InactiveCoverageController implements ng.IComponentController {
  public planPeriod: ICoverageTimePeriod;
  public type: CoverageType;
  public displayType: boolean;
  public show: boolean;
  private coverage: IPlanCoverage;
  private onlyShowFuture: boolean;
  private useSelectedProfile: boolean;
  private profileSubscription: Subscription;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private profileService: IProfileService,
    private stateService: IStateService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.useSelectedProfile = this.stateService.getUseSelectedProfile();
    this.onlyShowFuture = Object.prototype.hasOwnProperty.call(this, 'onlyShowIfFuture');
    this.displayType = Object.prototype.hasOwnProperty.call(this, 'displayCoverageType');
  }

  public $onInit(): void {
    this.setPlanPeriod();

    if (this.useSelectedProfile) {
      this.profileSubscription = this.profileService.profileChanged.subscribe(() => {
        this.setPlanPeriod();
      });
    }
  }

  public $onDestroy(): void {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  public setPlanPeriod(): void {
    const heartbeat = this.userService.getHeartbeat();
    const selectedUser = heartbeat.let(this.profileService.toCurrentProfile());
    const loggedInUser = heartbeat.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    const coverageReq = Observable.if(() => this.useSelectedProfile, selectedUser, loggedInUser).map(user =>
      this.profileService.getCoverage(CoverageType.Medical, user.planCoverages),
    );

    Observable.if(() => !this.coverage, coverageReq, Observable.of(this.coverage)).subscribe(coverage => {
      if (coverage) {
        this.planPeriod = coverage && coverage.planPeriod;
        this.show = this.onlyShowFuture
          ? this.planPeriod.status === CoverageStatus.Future
          : this.planPeriod.status !== CoverageStatus.Active;
        this.type = coverage.coverageType;
      }
    }, console.warn);
  }
}

export class InactiveCoverageComponent implements ng.IComponentOptions {
  public controller = InactiveCoverageController;
  public templateUrl = inactiveCoverageTemplate;
  public bindings = {
    // onlyShowIfFuture, and displayCoverageType become booleans; T if present, F by default
    onlyShowIfFuture: '@?',
    displayCoverageType: '@?',
    coverage: '<?',
  };
}
