import moment from 'moment';
import { AnyClaimType, ClaimType } from 'scripts/api/claims/claims.interfaces';
import allClaimsSelectDatesTemplate from 'views/modals/all-claims-select-dates.html';
import { FilterStateKey, ISelectedFilters } from '../../../ui/filter/filter.interfaces';
import { IFilterService } from '../../../ui/filter/filter.service';
import { IModalController } from '../../../ui/modal/modal.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { AllClaimsService } from '../../claims-and-accounts/claims/all-claims.service';

export class AllClaimsSelectDatesController implements ng.IComponentController {
  // Bindings
  public $modal: IModalController;

  public endDate: moment.Moment;
  public startDate: moment.Moment;
  private selectedFilters: ISelectedFilters<any>;
  private type: AnyClaimType;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private filterService: IFilterService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS);

    this.type = this.filterService.getStateValue(FilterStateKey.Type) || ClaimType.Medical;
  }

  public $onInit(): void {
    this.selectedFilters = this.filterService.getStateValue() || AllClaimsService.getDefaultSelectedFilters(this.type);

    if (
      this.selectedFilters &&
      this.selectedFilters.values &&
      Object.prototype.hasOwnProperty.call(this.selectedFilters.values, 'by-date-range')
    ) {
      this.startDate = moment(this.selectedFilters.values['by-date-range'][0]);
      this.endDate = moment(this.selectedFilters.values['by-date-range'][1]);
    } else {
      this.startDate = moment()
        .startOf('date')
        .subtract(18, 'M');
      this.endDate = moment().startOf('date');
    }

    // Watch for the element to become not visible. This is necessary because this modal is only used on desktop. We hide the modal
    // with css. But if the user has the modal open and changes their viewport size to mobile, then the modal should close.
    this.$scope.$watch(() => this.$element.children().is(':visible'), isVisible => !isVisible && this.$modal.close());
  }

  public cancel(): void {
    this.$modal.close();
  }

  public update(): void {
    this.selectedFilters.values['by-date-range'] = [this.startDate.toISOString(), this.endDate.toISOString()];
    // this is to make sure that date range & year drop down dont have contradictory logic. Date range takes priority.
    if (Object.prototype.hasOwnProperty.call(this.selectedFilters.values, 'year')) {
      const newYears: string[] = [];
      for (const year of this.selectedFilters.values.year as string[]) {
        if (this.startDate.year() <= Number(year) && Number(year) <= this.endDate.year()) {
          newYears.push(year);
        }
      }
      this.selectedFilters.values.year = newYears.length > 0 ? newYears : undefined;
    }
    this.filterService.updateStateValue(this.selectedFilters).subscribe();
    this.$timeout(() => {
      this.$modal.close();
    });
  }

  public isValid(): boolean {
    return AllClaimsService.isDateRangeValid(this.startDate, this.endDate);
  }
}

export class AllClaimsSelectDatesComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsSelectDatesController;
    this.templateUrl = allClaimsSelectDatesTemplate;
  }
}
