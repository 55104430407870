import idx from 'idx';
import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IPopulationState } from 'scripts/reducers/population-reducer';

export const selectPopulation = (state: IReduxState): IPopulationState => state.population;

export const selectLineOfBusiness = createSelector(
  selectPopulation,
  population => idx(population, _ => _.lineOfBusiness),
);

export const selectMembershipCategory = createSelector(
  selectPopulation,
  population => idx(population, _ => _.membershipCategory),
);
