import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { IEmptyResponse, ITimePeriod } from 'scripts/api/api.interfaces';
import { AccountType, ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService } from 'scripts/api/ledger/ledger.service';
import { EftAccountType, IEftPreferences, IUserPreferences, YesNo } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { accountSettings } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import directDepositTemplate from 'views/claims-and-accounts/direct-deposit/direct-deposit.html';

export class DirectDepositController implements ng.IComponentController {
  public accountNumber: string;
  public accounts: ILedgerAccount[];
  public accountTypeOptions: IDropdownOption[];
  public currentYear: ITimePeriod;
  public hidePromptPage = false;
  public initialPreferenceRequest: Observable<[ILedgerAccount[], IUserPreferences]>;
  public isCurrentYearSelected = true;
  public isEnrolled: boolean;
  public preferenceChangeRequest: Observable<IEmptyResponse>;
  public previousYear: ITimePeriod;
  public accountSettings: IResource;
  public routingNumber: string;
  public selectedAccountType: IDropdownOption;
  public showPreviousYear: boolean;
  private currentYearEftPreferences: IEftPreferences;
  private previousYearEftPreferences: IEftPreferences;
  private supportedAccountTypes: AccountType[];

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private ledgerService: ILedgerService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.DIRECT_DEPOSIT);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.accountSettings = accountSettings;

    this.supportedAccountTypes = [
      AccountType.DCSA,
      AccountType.FSADC,
      AccountType.FSAHC,
      AccountType.FSALP,
      AccountType.HCSA,
      AccountType.HRA,
      AccountType.HRAAP,
      AccountType.HRASD,
      AccountType.HSA,
      AccountType.MRA,
    ];

    this.accountTypeOptions = [
      {
        label: 'CHECKING',
        value: EftAccountType.Checking,
      },
      {
        label: 'SAVINGS',
        value: EftAccountType.Savings,
      },
    ];

    this.selectedAccountType = this.accountTypeOptions[0];

    this.currentYear = {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('year'),
    };

    this.previousYear = {
      startDate: moment()
        .subtract(1, 'year')
        .startOf('year'),
      endDate: moment()
        .subtract(1, 'year')
        .endOf('year'),
    };

    const accounts$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .takeWhile(currentUser => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(({ rallyId }) => this.ledgerService.getAccounts(rallyId))
      .map(({ data }) => data.filter(account => account.balance))
      .map(accounts => accounts.filter(({ accountType }) => this.supportedAccountTypes.indexOf(accountType) > -1))
      .map(accounts => {
        const accountTypes = {};
        return accounts.filter(({ accountType }) => !accountTypes[accountType] && (accountTypes[accountType] = true));
      });

    const userPreferences$ = this.userService
      .getHeartbeat()
      .map(rsp => rsp.data)
      .flatMap(({ rallyId }) => this.profileService.getUserPreferences(rallyId))
      .map(rsp => rsp.data);

    this.initialPreferenceRequest = Observable.zip(accounts$, userPreferences$);
  }

  public $onInit(): void {
    this.initialPreferenceRequest.subscribe(([accounts, userPreferences]) => {
      this.accounts = accounts;
      this.showPreviousYear = accounts.some(account => !account.isActive);
      // The BE response currently doesn't have a way to differentiate current year from previous year
      // so setting the them both to the value of the response until we can get clarity on how the BE
      // will differentiate them.
      this.currentYearEftPreferences = userPreferences.eftPreferences;
      this.previousYearEftPreferences = userPreferences.eftPreferences;
      this.setInputFields(this.currentYearEftPreferences);
    }, console.warn);
  }

  public changeAccountType(): (option: IDropdownOption) => void {
    return (option: IDropdownOption) => (this.selectedAccountType = option);
  }

  public goBack(): void {
    this.$window.history.back();
  }

  public handleYearChange(): void {
    this.setInputFields(this.isCurrentYearSelected ? this.currentYearEftPreferences : this.previousYearEftPreferences);
  }

  public submitPreferenceChange(): void {
    this.hidePromptPage = true;
    const eftPreferences = this.isCurrentYearSelected
      ? this.currentYearEftPreferences
      : this.previousYearEftPreferences;
    const updatedPreferences: IEftPreferences = {
      prefStatusCode: this.isEnrolled ? YesNo.Y : YesNo.N,
      accountNumber: this.accountNumber === eftPreferences.accountNumber ? undefined : this.accountNumber,
      routingNumber: this.routingNumber === eftPreferences.routingNumber ? undefined : this.routingNumber,
      accountType: this.selectedAccountType.value as EftAccountType,
    };
    this.preferenceChangeRequest = this.userService
      .getHeartbeat()
      .map(rsp => rsp.data)
      .flatMap(({ rallyId }) => this.profileService.setEftPreferences(rallyId, updatedPreferences));
  }

  private setInputFields(eftPreferences: IEftPreferences | undefined): void {
    if (eftPreferences) {
      this.isEnrolled = eftPreferences.prefStatusCode && eftPreferences.prefStatusCode === YesNo.Y;
      this.accountNumber = eftPreferences.accountNumber || '';
      this.routingNumber = eftPreferences.routingNumber || '';
      const foundAccountTypeOption = this.accountTypeOptions.find(
        option => option.value === eftPreferences.accountType,
      );
      if (foundAccountTypeOption) {
        this.selectedAccountType = foundAccountTypeOption;
      }
    }
  }
}

export class DirectDepositComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;

  constructor() {
    this.templateUrl = directDepositTemplate;
    this.controller = DirectDepositController;
  }
}
