import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { reviewEligibleExpenses, reviewDosAndDonts } from 'scripts/util/resource/resource.constants';
import { isDcsaAccount, isFsaAccount, isHcsaAccount, isHraAccount, isMraAccount } from 'scripts/util/ledger/ledger';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { hasAccount } from './submit-claim-utils';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { Anchor } from 'scripts/ui/anchor/anchor';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

interface IBeforeYouSubmitProps {
  accounts: ILedgerAccount[];
  population: IPopulation;
}

const ReviewEligibleExpansesAnchor = withClickTracking(Anchor, 'review-eligible-expenses');
const ReviewDosAndDontsAnchor = withClickTracking(Anchor, 'review-dos-and-donts');

export const getAccountStrings = (accounts: ILedgerAccount[]): string[] | null => {
  const accountStrings = [
    hasAccount(isFsaAccount)(accounts) && 'FSA',
    hasAccount(isHraAccount)(accounts) && 'HRA',
    hasAccount(isMraAccount)(accounts) && 'MRA',
    hasAccount(isHcsaAccount)(accounts) && 'HCSA',
    hasAccount(isDcsaAccount)(accounts) && 'DCSA',
  ].filter(Boolean);

  if (accountStrings.length === 0) {
    return null;
  }

  const lastAccountString = accountStrings[accountStrings.length - 1];
  const allButLastAccountStrings = accountStrings.slice(0, accountStrings.length - 1).join(', ');

  return [allButLastAccountStrings, lastAccountString].filter(Boolean);
};

const BeforeYouSubmit: FunctionComponent<IBeforeYouSubmitProps> = ({ accounts, population }) => {
  const { t } = useTranslation(Dictionary.SUBMIT_CLAIM);
  const accountStrings = getAccountStrings(accounts);

  if (!accountStrings) {
    return null;
  }

  let reviewEligibleExpensesI18nKey: string;
  if (hasAccount(isHcsaAccount)(accounts) && hasAccount(isDcsaAccount)(accounts)) {
    reviewEligibleExpensesI18nKey = 'VIEW_ELIGIBLE_HCSA_OR_DCSA_EXPENSES';
  } else if (hasAccount(isDcsaAccount)(accounts)) {
    reviewEligibleExpensesI18nKey = 'VIEW_ELIGIBLE_DCSA_EXPENSES';
  } else if (hasAccount(isHcsaAccount)(accounts)) {
    reviewEligibleExpensesI18nKey = 'VIEW_ELIGIBLE_HCSA_EXPENSES';
  } else if (hasAccount(isFsaAccount)(accounts)) {
    reviewEligibleExpensesI18nKey = 'VIEW_ELIGIBLE_FSA_EXPENSES';
  }

  let reviewDosAndDontsI18nKey = 'REVIEW_DOS_AND_DONTS';
  if (!hasAccount(isFsaAccount)(accounts) && hasAccount(isHraAccount)(accounts)) {
    reviewDosAndDontsI18nKey = 'REVIEW_DOS_AND_DONTS_HRA';
  }

  return (
    <div className="submit-claim-header-before-you-submit" data-testid="submit-claim-header-before-you-submit">
      <h3 className="submit-claim-header-before-you-submit-header">
        {accountStrings.length === 1
          ? t('BEFORE_YOU_SUBMIT_HEADER_1_ACCOUNT', { accountType: accountStrings[0] })
          : t('BEFORE_YOU_SUBMIT_HEADER_2_OR_MORE_ACCOUNTS', {
              accountType1: accountStrings[0],
              accountType2: accountStrings[1],
            })}
      </h3>
      <div className="submit-claim-header-before-you-submit-content">
        <div>
          {accountStrings.length === 1
            ? t('BEFORE_YOU_SUBMIT_CONTENT_1_ACCOUNT', { accountType: accountStrings[0] })
            : t('BEFORE_YOU_SUBMIT_CONTENT_2_OR_MORE_ACCOUNTS', {
                accountType1: accountStrings[0],
                accountType2: accountStrings[1],
              })}
        </div>

        <div className="submit-claim-header-before-you-submit-links">
          {reviewEligibleExpensesI18nKey && (
            <ReviewEligibleExpansesAnchor
              target={LinkTarget.Blank}
              href={getResource(reviewEligibleExpenses, population)}
              className="submit-claim-header-before-you-submit-link"
            >
              {t(reviewEligibleExpensesI18nKey)}
            </ReviewEligibleExpansesAnchor>
          )}
          <ReviewDosAndDontsAnchor
            target={LinkTarget.Blank}
            href={getResource(reviewDosAndDonts, population)}
            className="submit-claim-header-before-you-submit-link"
          >
            {t(reviewDosAndDontsI18nKey)}
          </ReviewDosAndDontsAnchor>
        </div>
      </div>
    </div>
  );
};

export default BeforeYouSubmit;
