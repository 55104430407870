import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IQuickLinksCategory } from './quick-links.controller';
import { LinkTarget, CoverageStatus, CoverageTypeCode } from 'scripts/api/api.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import {
  allClaims,
  connect,
  prescriptions,
  paymentsOverview,
  valueAddedServices,
  accountProfile,
  pharmacyLocator,
  drugCostEstimator,
  accountPreferences,
  explanationOfBenefits,
  documentsOverview,
  drugCostEstimatorOptum,
} from 'scripts/util/resource/resource.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import {
  IProducts,
  IProfileUser,
  ProgramType,
  FundingType,
  MembershipCategory,
} from 'scripts/api/profile/profile.interfaces';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { isValueAddedServiceEligible } from 'scripts/util/profile/profile';

const getAllMrQuickLinks = (population: IPopulation): IQuickLinksCategory[] => {
  return [
    {
      id: 'CLAIMS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_YOUR_CLAIMS',
      href: getResource(allClaims, population),
      icon: 'icon-claims',
    },
    {
      id: 'PAYMENT',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'MAKE_A_PAYMENT',
      href: getResource(paymentsOverview, population),
      icon: 'icon-payments',
    },
    {
      id: 'PRESCRIPTIONS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'MANAGE_PRESCRIPTIONS',
      href: getResource(prescriptions, population),
      icon: 'icon-pharmacy',
    },
    {
      id: 'VALUE_ADDED',
      dictionary: Dictionary.COMMON,
      text: 'VIEW_DISCOUNTS_AND_SERVICES',
      href: getResource(valueAddedServices, population),
      icon: 'icon-value-2',
    },
    {
      id: 'FIND_PROVIDER',
      dictionary: Dictionary.COMMON,
      text: 'FIND_PROVIDER',
      href: '/find-care',
      icon: 'icon-find-doctor',
    },
    {
      id: 'PROFILE',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'PROFILE_PREFERENCES',
      href: getResource(accountProfile, population),
      icon: 'icon-gear',
    },
    {
      id: 'PHARMACY',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'LOCATE_PHARMACY',
      href: getResource(pharmacyLocator, population),
      icon: 'icon-pharmacy-locator',
    },
    {
      id: 'DRUG',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'LOOK_UP_DRUGS',
      href: getResource(drugCostEstimator, population),
      icon: 'icon-pharmacy',
    },
    {
      id: 'DOCUMENT_DELIVERY_PREFERENCES',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'DOCUMENT_DELIVERY_PREFERENCES',
      href: getResource(accountPreferences, population),
      icon: 'icon-gear',
    },
    {
      id: 'PREFERENCES',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'ONLINE_DELIVERY',
      href: getResource(accountPreferences, population),
      icon: 'icon-green',
    },
    {
      id: 'EOB',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'EOB',
      href: getResource(explanationOfBenefits, population),
      icon: 'icon-eob',
    },
    {
      id: 'VIEW_DOCS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_DOCUMENTS',
      href: getResource(documentsOverview, population),
      icon: 'icon-forms',
    },
  ];
};

export const getMrQuickLinks = (
  currentUser: IProfileUser,
  population: IPopulation,
  products: IProducts,
): IQuickLinksCategory[] => {
  const originalMrQuickLinks = getAllMrQuickLinks(population);
  const getMrQuickLink = (id: string): IQuickLinksCategory =>
    originalMrQuickLinks.find(mrQuickLink => mrQuickLink.id === id);

  const activeCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Active);
  const futureCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Future);
  const nonTermedCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status !== CoverageStatus.Termed);

  const hasMaOrMapdPlan = nonTermedCoverages.some(
    c => c.coverageTypeCode === CoverageTypeCode.MA || c.coverageTypeCode === CoverageTypeCode.MAPD,
  );
  const hasMapdOrPdpPlan = nonTermedCoverages.some(
    c => c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP,
  );
  const hasActivePdpPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.PDP);
  const hasActiveMapdPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MAPD);
  const hasActiveMaPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MA);
  const activeMapdOrPdpPlan = activeCoverages.filter(
    c => c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP,
  )[0];
  const hasSspOnly = currentUser.planCoverages.every(c => c.coverageTypeCode === CoverageTypeCode.SSP);
  const isActive = activeCoverages.length > 0;
  const isFuture =
    currentUser.planCoverages.length &&
    currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future);
  const isTermed = nonTermedCoverages.length === 0;
  const isFutureShip = isFuture && futureCoverages.some(c => c.planFeatures.programType === ProgramType.Ship);

  getMrQuickLink('CLAIMS').show = isActive || isTermed;
  getMrQuickLink('EOB').show = !hasSspOnly && (isActive || isTermed);
  getMrQuickLink('VIEW_DOCS').show = isFuture;
  getMrQuickLink('PROFILE').show = isFutureShip;
  getMrQuickLink('PAYMENT').show =
    currentUser.memberFeatures.premiumPaymentEligible &&
    (isActive || (isFuture && FeatureFlagService.isMRPreEffPremiumPaymentsOn()));
  getMrQuickLink('PAYMENT').text = isFuture ? 'SET_UP_PAYMENT' : 'MAKE_A_PAYMENT';
  getMrQuickLink('VALUE_ADDED').show = isFutureShip ? true : !isActive ? false : undefined;
  getMrQuickLink('FIND_PROVIDER').show = hasMaOrMapdPlan && currentUser.memberFeatures.fpcEligible && !isFutureShip;
  const pharmacyLink = getMrQuickLink('PHARMACY');
  pharmacyLink.show = hasMapdOrPdpPlan;

  const showDrugCostEstimator = hasActiveMapdPlan || hasActivePdpPlan;
  const drugLink = getMrQuickLink('DRUG');
  drugLink.show = showDrugCostEstimator;
  if (showDrugCostEstimator && activeMapdOrPdpPlan.planFeatures.fundingArrangementType === FundingType.Group) {
    drugLink.href = getResource(drugCostEstimatorOptum, population);
    drugLink.target = LinkTarget.Blank;
  }

  getMrQuickLink('PREFERENCES').show =
    isActive &&
    (currentUser.membershipCategory === MembershipCategory.AARP ||
      currentUser.membershipCategory === MembershipCategory.UHC);
  getMrQuickLink('DOCUMENT_DELIVERY_PREFERENCES').show = isFuture && !isFutureShip;

  if (hasActiveMaPlan) {
    getMrQuickLink('FIND_PROVIDER').href = {
      pathname: '/internal-redirect',
      search: `?deepLink=${getResource(connect, population)}`,
    };
  }

  const prescriptionsMrQuickLink = getMrQuickLink('PRESCRIPTIONS');
  if (!FeatureFlagService.isMRPharmaciesOn() || !activeMapdOrPdpPlan) {
    prescriptionsMrQuickLink.show = false;
  }

  if (products) {
    const valueAddedMrQuickLink = getMrQuickLink('VALUE_ADDED');
    if (valueAddedMrQuickLink.show === undefined) {
      valueAddedMrQuickLink.show = isValueAddedServiceEligible(products);
    }
    if (prescriptionsMrQuickLink.show === undefined) {
      prescriptionsMrQuickLink.show = !!products.rx;
      if (prescriptionsMrQuickLink.show) {
        drugLink.show = false;
        pharmacyLink.show = false;
      }
    }
  }

  return originalMrQuickLinks.filter(quickLink => quickLink.show);
};
