import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitNetwork } from 'scripts/api/plans/plans.interfaces';
import { AccountSummaryModalExplanation } from 'scripts/features/dashboard/mr-account-summary/mr-account-summary-modal-explanation';
import { MRAccountSummaryClaimTotals } from 'scripts/features/dashboard/mr-account-summary/mr-account-summary-claim-totals';
import { MRAccountSummaryHeader } from 'scripts/features/dashboard/mr-account-summary/mr-account-summary-header';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { AccumulatorsListConnected } from 'scripts/ui/accumulators-list/accumulators-list-connected';
import { Feature } from 'scripts/ui/feature/feature';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { FeatureFlagGate } from 'scripts/ui/feature-flag-gate/feature-flag-gate';

export const RawMRAccountSummary: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.ACCOUNT_SUMMARY);
  return (
    <Feature featureId={'mr-account-summary'}>
      <div className="container account-summary mr-account-summary" data-testid="mr-account-summary">
        <MRAccountSummaryHeader />
        <AccumulatorsListConnected
          networkFilter={BenefitNetwork.InNetwork}
          trackLabel={'spent-benefits'}
          disableLabelModal={true}
        >
          <aside className={'hide-desktop'}>
            <AccountSummaryModalExplanation />
          </aside>
        </AccumulatorsListConnected>
        <FeatureFlagGate
          flagName="ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_CLAIM_TOTALS"
          whenTrue={<MRAccountSummaryClaimTotals />}
        />
        <aside className="mr-account-summary-disclaimer" role="note">
          {t('MR_ACCOUNT_SUMMARY_DISCLAIMER')}
        </aside>
      </div>
    </Feature>
  );
};

export const MRAccountSummary = withProvider(RawMRAccountSummary);
