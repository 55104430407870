import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getMoneyValue } from 'scripts/util/money/money';

interface IProgressLabelProps {
  amount: number;
  max: number;
}

export const ProgressLabel: FunctionComponent<IProgressLabelProps> = props => {
  const { t } = useTranslation(Dictionary.ACCUMULATORS);
  const { amount, max } = props;
  const moneyAmount = getMoneyValue(amount);
  const moneyMax = getMoneyValue(max);
  return (
    <div className="progress-label network-color" data-testid="progress-label">
      {t('SPENT_OUT_OF_TOTAL', { spent: moneyAmount, outOf: moneyMax })}
    </div>
  );
};
