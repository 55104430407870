import { ISort, ISelectedFilters, FilterStateKey } from './filter.interfaces';
import { IRouterProps } from 'scripts/hoc/with-router/with-router';
import { constructParams } from 'scripts/util/uri/uri';

export const doSort = <T>(values: T[], sort: ISort<T> = { properties: [], reverse: false }): T[] => {
  const { properties, reverse } = sort;
  return values.sort((a, b) => {
    const first = reverse ? b : a;
    const second = reverse ? a : b;
    return properties.reduce((previous, property) => {
      if (previous !== 0) {
        return previous;
      }
      const firstValue = property(first);
      const secondValue = property(second);
      if (typeof firstValue === 'number' && typeof secondValue === 'number') {
        return firstValue - secondValue;
      } else if (typeof firstValue === 'string' && typeof secondValue === 'string') {
        return firstValue.localeCompare(secondValue);
      } else {
        return String(firstValue).localeCompare(String(secondValue));
      }
    }, 0);
  });
};

export const shouldEncodeQueryParam = (key: FilterStateKey): boolean => {
  return key === FilterStateKey.Filters;
};

export const decodeQueryParamValue = <U>(value: string, key: FilterStateKey): U => {
  if (!shouldEncodeQueryParam(key)) {
    return value as any;
  }
  let decodedValue: U;
  if (value) {
    try {
      value = atob(value);
    } catch (err) {
      console.warn('Could not decode state value');
    }
    try {
      decodedValue = JSON.parse(value);
    } catch (err) {
      console.warn('Could not parse state value');
      decodedValue = undefined;
    }
  }
  return decodedValue;
};

export const encodeQueryParamValue = <U>(value: U, key: FilterStateKey): string => {
  if (!shouldEncodeQueryParam(key)) {
    return String(value);
  }
  return btoa(JSON.stringify(value));
};

export const getFiltersFromLocation = <T, U = ISelectedFilters<T>>(
  location: IRouterProps['location'],
  key: FilterStateKey = FilterStateKey.Filters,
): U => {
  const params = new URLSearchParams(location.search);
  return decodeQueryParamValue<U>(params.get(key), key);
};

export const updateFiltersInLocation = <U>(
  location: IRouterProps['location'],
  history: IRouterProps['history'],
  obj: U,
  key: FilterStateKey = FilterStateKey.Filters,
): void => {
  const currentParams = new URLSearchParams(location.search);
  const params: Record<string, string> = {};
  currentParams.forEach((value, key) => (params[key] = value));
  params[key] = encodeQueryParamValue(obj, key);
  history.replace(`${location.pathname}${constructParams(params)}`);
};
