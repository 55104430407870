import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { CoverageType, CoverageStatus, LinkTarget } from 'scripts/api/api.interfaces';
import { IProfileUser, IProducts } from 'scripts/api/profile/profile.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { CampaignPlacementType, ICampaignPlacements } from 'scripts/api/targeting/targeting.interfaces';
import { IQuickLinksCategory } from './quick-links.controller';
import { getResource } from 'scripts/util/resource/resource';
import { hasLedgerAccess as determineLedgerAccess } from 'scripts/util/profile/profile';
import {
  advantage,
  connect,
  connectSaved,
  expatHealthResources,
  expatGlobalGateway,
  expatSubmitClaims,
  rx,
} from 'scripts/util/resource/resource.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import CONFIG from 'scripts/util/constants/config';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import {
  isDcsaAccount,
  isFsaAccount,
  isHcsaAccount,
  isHraAccount,
  isHsaAccount,
  isMraAccount,
} from 'scripts/util/ledger/ledger';
import { shouldOpenPromoInSameTab } from 'scripts/util/targeting/targeting';
import { IQuickLinksProps } from './quick-links';

const getOriginalEiQuickLinks = (population: IPopulation): IQuickLinksCategory[] => {
  return [
    {
      id: CoverageType.Medical,
      dictionary: Dictionary.COMMON,
      text: 'FIND_DOCTOR',
      href: { pathname: '/internal-redirect', search: `?deepLink=${getResource(connect, population)}` },
      icon: 'icon-find-doctor',
      show: true,
    },
    {
      id: 'CLAIMS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'MANAGE_CLAIMS',
      href: '/claims-and-accounts/claims',
      icon: 'icon-claims',
      show: true,
    },
    {
      id: CoverageType.Rx,
      dictionary: Dictionary.QUICK_LINKS,
      text: 'MANAGE_PRESCRIPTIONS',
      href: '/pharmacy',
      icon: 'icon-pharmacy',
      show: false,
    },
    {
      id: 'ACCESS_FINANCIAL_ACCOUNTS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'ACCESS_FINANCIAL_ACCOUNTS',
      href: '/claims-and-accounts/plan-balances?accountsExpanded=1',
      icon: 'icon-balances',
      show: false,
    },
    {
      id: 'ADVANTAGE',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VISIT_HEALTH_AND_WELLNESS',
      target: LinkTarget.Blank,
      href: { pathname: '/internal-redirect', search: `?deepLink=${getResource(advantage, population)}` },
      icon: 'icon-wellness',
      show: false,
    },
    {
      id: 'SAVED',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_SAVED',
      href: { pathname: '/internal-redirect', search: `?deepLink=${getResource(connectSaved, population)}` },
      icon: 'icon-saved',
      show: true,
    },
  ];
};

export const getExpatLinks = (population: IPopulation): IQuickLinksCategory[] => {
  return [
    {
      id: CoverageType.Medical,
      dictionary: Dictionary.QUICK_LINKS,
      text: 'FIND_A_US_PROVIDER',
      href: { pathname: '/internal-redirect', search: `?deepLink=${getResource(connect, population)}` },
      icon: 'icon-find-doctor',
      show: true,
    },
    {
      id: 'VIEW_CLAIMS',
      dictionary: Dictionary.COMMON,
      text: 'VIEW_CLAIMS',
      href: '/claims-and-accounts/claims',
      icon: 'icon-claims',
      show: true,
    },
    {
      id: 'SUBMIT_CLAIMS',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'SUBMIT_CLAIMS',
      target: LinkTarget.Blank,
      href: getResource(expatSubmitClaims, population),
      icon: 'icon-submit-claim',
      show: true,
    },
    {
      id: 'HEALTH_RESOURCES',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_HEALTH_RESOURCES',
      href: getResource(expatHealthResources, population),
      icon: 'icon-health-resources',
      show: true,
    },
    {
      id: 'GLOBAL_GATEWAY',
      dictionary: Dictionary.QUICK_LINKS,
      text: 'VIEW_GLOBAL_GATEWAY',
      target: LinkTarget.Blank,
      href: getResource(expatGlobalGateway, population),
      icon: 'icon-global',
      show: true,
    },
  ];
};

const getAccountsLinkText = (accounts: ILedgerAccount[]): string => {
  const activeAccounts = accounts.filter(account => account.isActive);
  if (activeAccounts.every(isHsaAccount)) {
    return 'MANAGE_YOUR_HSA';
  }
  if (activeAccounts.every(isFsaAccount)) {
    return 'MANAGE_YOUR_FSA';
  }
  if (activeAccounts.every(isHraAccount)) {
    return 'MANAGE_YOUR_HRA';
  }
  if (activeAccounts.some(isHsaAccount) && activeAccounts.some(isFsaAccount)) {
    return 'MANAGE_YOUR_HSA_FSA';
  }
  if (activeAccounts.some(isHraAccount) && activeAccounts.some(isFsaAccount)) {
    return 'MANAGE_YOUR_HRA_FSA';
  }
  // Based on the case that JPMC user can only have JPMC account types
  if (activeAccounts.every(isMraAccount)) {
    return 'MANAGE_YOUR_MRA';
  }
  if (activeAccounts.some(isMraAccount) && activeAccounts.some(isHcsaAccount) && activeAccounts.some(isDcsaAccount)) {
    return 'MANAGE_YOUR_MRA_HCSA_DCSA';
  }
  if (activeAccounts.some(isMraAccount) && activeAccounts.some(isHcsaAccount)) {
    return 'MANAGE_YOUR_MRA_HCSA';
  }
  if (activeAccounts.some(isMraAccount) && activeAccounts.some(isDcsaAccount)) {
    return 'MANAGE_YOUR_MRA_DCSA';
  }
  return 'ACCESS_FINANCIAL_ACCOUNTS';
};

const setRxLinkUrl = (
  rxLink: IQuickLinksCategory,
  population: IPopulation,
  primaryPolicyNumber: IProfileUser['primaryPolicyNumber'],
  products: IProducts,
): void => {
  const rallyRxPolicies = CONFIG.ARCADE_WEB_RALLY_RX_POLICIES.split(',');
  const isPolicyMatch = !!rallyRxPolicies && rallyRxPolicies.indexOf(primaryPolicyNumber) !== -1;
  const isRallyRxInProducts =
    FeatureFlagService.isRallyRxInProductEligibilityOn() && products && products.core_mpe_rallyRx;

  if (FeatureFlagService.isRallyRxOn() || isPolicyMatch || isRallyRxInProducts) {
    rxLink.href = getResource(rx, population);
  }
};

const addEiCustomCampaignsQuickLinks = (
  originalQuickLinks: IQuickLinksCategory[],
  campaignPlacements: ICampaignPlacements,
): void => {
  const customCampaignsQuickLinks =
    campaignPlacements &&
    campaignPlacements[CampaignPlacementType.ArcadeDashboardQuickLinks] &&
    campaignPlacements[CampaignPlacementType.ArcadeDashboardQuickLinks].sort((a, b) => b.priority - a.priority);

  let numLinksShown = originalQuickLinks.filter(quickLink => quickLink.show).length;
  if (customCampaignsQuickLinks) {
    const accountsLink = originalQuickLinks[3];
    const advantageLink = originalQuickLinks[4];
    const savedLink = originalQuickLinks[5];

    const maxLoops = Math.min(customCampaignsQuickLinks.length, 2);
    for (let i = 0; i < maxLoops; i++) {
      // first make space for the custom link by hiding other ones as necessary
      while (numLinksShown > 4) {
        if (savedLink.show) {
          savedLink.show = false;
        } else if (advantageLink.show) {
          advantageLink.show = false;
        } else if (accountsLink.show) {
          accountsLink.show = false;
        }
        numLinksShown--;
      }

      const customLink = customCampaignsQuickLinks[i];
      const shouldOpenInSameTab = shouldOpenPromoInSameTab(customLink.cta.ctaValue);

      originalQuickLinks[i + 6] = {
        id: 'CUSTOM_LINK',
        text: customLink.cta.ctaText,
        target: shouldOpenInSameTab ? LinkTarget.Self : LinkTarget.Blank,
        href: customLink.cta.ctaValue,
        iconUrl: customLink.imageUrl,
        campaignId: customLink.campaignId,
        placementType: customLink.placementType,
        show: true,
      };
      numLinksShown++;
    }
  }
};

export const getEiQuickLinks = (props: IQuickLinksProps): IQuickLinksCategory[] => {
  const { accounts, campaigns, currentSelectedProfile, population, products } = props;
  const hasLedgerAccess = determineLedgerAccess(currentSelectedProfile);
  const originalQuickLinks = getOriginalEiQuickLinks(population);
  const rxLink = originalQuickLinks[2];
  setRxLinkUrl(rxLink, population, currentSelectedProfile.primaryPolicyNumber, products);

  // Show Rx quick link if there exists additional or standalone Rx coverage && the rx product exists.
  const hasRxCoverage =
    currentSelectedProfile.planCoverages.filter(coverage => {
      return (
        ((coverage.coverageType === CoverageType.Rx && typeof coverage.rxCoverageInfo !== 'undefined') ||
          (coverage.additionalCoverageTypes && coverage.additionalCoverageTypes.indexOf(CoverageType.Rx) > -1)) &&
        coverage.planPeriod.status === CoverageStatus.Active
      );
    }).length &&
    products &&
    products.rx;

  if (hasRxCoverage) {
    rxLink.show = true;
  }

  if (hasLedgerAccess && accounts && accounts.some(account => account.isActive)) {
    const accountsLink = originalQuickLinks[3];
    accountsLink.show = true;
    accountsLink.text = getAccountsLinkText(accounts);
  }

  if (products && products.rallyEngage) {
    // ARC-7786: long story short, checking that the product is engage, but linking to advantage
    const advantageLink = originalQuickLinks[4];
    advantageLink.show = true;
  }

  // if all other links are shown, then saved misses the cut
  const linksShown = originalQuickLinks.filter(quickLink => quickLink.show).length;
  if (linksShown === 6) {
    const savedLink = originalQuickLinks[5];
    savedLink.show = false;
  }

  if (campaigns) {
    addEiCustomCampaignsQuickLinks(originalQuickLinks, campaigns.placements);
  }

  return originalQuickLinks.filter(quickLink => quickLink.show);
};
