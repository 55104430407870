import { CoverageStatus } from 'scripts/api/api.interfaces';
import { IProfileUser, LineOfBusiness, MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';

export const isCS = (user: IProfileUser): boolean => {
  return user.lineOfBusiness === LineOfBusiness.CS;
};

export const isDsnp = (user: IProfileUser): boolean => {
  return !!user.memberFeatures.isDsnp;
};

export const isEI = (user: IProfileUser): boolean => {
  return user.lineOfBusiness === LineOfBusiness.EI;
};

/**
 * Checks if a user is an Empire user.
 * @param user
 * @returns true if the user is an Empire user; otherwise false.
 */
export const isEmpire = (user: IProfileUser): boolean => {
  return user.membershipCategory === MembershipCategory.EMPIRE;
};

export const isExpatriate = (user: IProfileUser): boolean => {
  return user.membershipCategory === MembershipCategory.EXPATRIATE;
};

export const isMR = (user: IProfileUser): boolean => {
  return user.lineOfBusiness === LineOfBusiness.MR;
};

export const isOxford = (user: IProfileUser): boolean => {
  return user.membershipCategory === MembershipCategory.OXFORD;
};

/**
 * Checks if a UserProfile should see recommendations.
 * @param user - An IProfileUser
 * @param featureFlagService - An IFeatureFlagService instance
 * @returns true if the user may see recommendations, otherwise false.
 */
export const canShowRecommendations = (user: IProfileUser, featureFlagService: IFeatureFlagService): boolean => {
  return (
    !isEmpire(user) &&
    !isExpatriate(user) &&
    (user.lineOfBusiness !== LineOfBusiness.EI || featureFlagService.isEiRecommendationsOn()) &&
    user.planCoverages.some(c => c.planPeriod.status === CoverageStatus.Active)
  );
};

export const concatPvrc = (user: IProfileUser): string | undefined =>
  user.pvrc ? user.pvrc.planVariationCode + user.pvrc.reportingCode : undefined;
