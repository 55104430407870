import React, { FunctionComponent } from 'react';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { Maybe } from 'scripts/arcade.module.interfaces';
import IconCheck from 'images/icons/icon-circle-check-2.svg';
import { useTranslation, Trans } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import moment from 'moment';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { Dropdown } from 'scripts/ui/dropdown/dropdown';
import { IDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { ResourceAnchor } from 'scripts/ui/anchor/resource-anchor';
import { internationalClaims } from 'scripts/util/resource/resource.constants';

interface IAllClaimsHeaderProps {
  claims: Maybe<IClaim[]>;
  filteredClaims: Maybe<IClaim[]>;
  sortByOptions: IDropdownOption[];
  selectedSortByOption?: IDropdownOption;
  onSortSelect: (option: IDropdownOption) => void;
  startDate: moment.Moment;
  endDate: moment.Moment;
  showLinkToInternationalClaims?: boolean;
}

const TrackedSelectDatesLink = withClickTracking(Link, 'select-dates');
const TrackedCommonQuestionsLink = withClickTracking(Link, 'common-questions');
const TrackedInternationalClaimsAnchor = withClickTracking(ResourceAnchor, 'view-international-claims');
const TrackedSortAndFilterLink = withClickTracking(Link, 'sort-filter-results');

export const AllClaimsHeader: FunctionComponent<IAllClaimsHeaderProps> = props => {
  const {
    claims = [],
    endDate,
    filteredClaims = [],
    onSortSelect,
    selectedSortByOption,
    showLinkToInternationalClaims = false,
    sortByOptions,
    startDate,
  } = props;
  const { t } = useTranslation([Dictionary.COMMON, Dictionary.ALL_CLAIMS]);
  const filteredCount = filteredClaims.length;
  const allCount = claims.length;

  return (
    <>
      <div className="all-claims-header hide-mobile" data-testid="all-claims-header">
        <h1>
          <div className="top-heading">
            <IconCheck aria-hidden="true" className="icon-check" />
            <Trans
              i18nKey={`${Dictionary.ALL_CLAIMS}:DISPLAYING_CLAIMS_NUM_OF_NUM`}
              values={{ filteredCount, allCount }}
            >
              <span className="displaying-claims" />
            </Trans>
          </div>
          <div className="bottom-heading">
            <div className="small">
              <Trans
                i18nKey={`${Dictionary.ALL_CLAIMS}:FROM_DATE_TO_DATE_REACT`}
                values={{ startDate: startDate.format('MM/DD/YYYY'), endDate: endDate.format('MM/DD/YYYY') }}
              >
                <TrackedSelectDatesLink data-testid="select-dates-link" to="/modal/claims-select-dates" />
              </Trans>
            </div>
            <TrackedCommonQuestionsLink
              className="small common-questions"
              data-testid="common-questions-link"
              to="/modal/common-questions"
            >
              {t(`${Dictionary.ALL_CLAIMS}:COMMON_QUESTIONS`)}
            </TrackedCommonQuestionsLink>
          </div>
        </h1>
        <div className="sort-by-container">
          <label htmlFor="all-claims-sort-by-dd">{t(`${Dictionary.ALL_CLAIMS}:SORT_BY`)}:</label>
          <Dropdown
            className="black"
            data-testid="sort-by-dropdown"
            id="all-claims-sort-by-dd"
            options={sortByOptions}
            selected={selectedSortByOption ? selectedSortByOption : null}
            onSelect={onSortSelect}
            dictionary={Dictionary.ALL_CLAIMS}
          />
        </div>
      </div>
      <div className="all-claims-header hide-desktop">
        <h1>
          <IconCheck aria-hidden="true" className="icon-check" />
          <span>{t(`${Dictionary.ALL_CLAIMS}:OF_CLAIMS`, { filteredCount, allCount })}</span>
        </h1>
        <div className="all-claims-sort-filter-results">
          <TrackedSortAndFilterLink
            className="primary-btn"
            data-testid="sort-filter-link"
            to="/modal/claims-refine-results"
          >
            {t(`${Dictionary.ALL_CLAIMS}:SORT_AND_FILTER_RESULTS`)}
          </TrackedSortAndFilterLink>
          {showLinkToInternationalClaims && (
            <TrackedInternationalClaimsAnchor
              className="primary-btn"
              data-testid="international-claims-link"
              resourceValue={internationalClaims}
              target="_blank"
            >
              <span>{t(`${Dictionary.COMMON}:VIEW_INTERNATIONAL_CLAIMS`)}</span>
              <i className="icon-external-link" aria-label="{{'OPENS_IN_NEW_WINDOW' | translate}}" />
            </TrackedInternationalClaimsAnchor>
          )}
        </div>
        <TrackedCommonQuestionsLink
          className="small common-questions"
          data-testid="common-questions-link"
          to="/modal/common-questions"
        >
          {t(`${Dictionary.ALL_CLAIMS}:COMMON_QUESTIONS`)}
        </TrackedCommonQuestionsLink>
      </div>
    </>
  );
};
