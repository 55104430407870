import {
  getClaimsPreferencesError,
  getClaimsPreferencesLoading,
  getClaimsPreferencesSuccess,
  getProductsError,
  getProductsLoading,
  getProductsSuccess,
  getProfileError,
  getProfileLoading,
  getProfileSuccess,
  getReferralsError,
  getReferralsLoading,
  getReferralsSuccess,
  getHealthcareCoveragesLoading,
  getHealthcareCoveragesSuccess,
  getHealthcareCoveragesError,
  getPrimaryCareError,
  getPrimaryCareLoading,
  getPrimaryCareSuccess,
  getFpcPrimaryCareLoading,
  getFpcPrimaryCareSuccess,
  getFpcPrimaryCareError,
} from 'scripts/actions/profile-service-actions';
import { ProfileApi } from 'scripts/api/profile/profile-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';
import { selectedUser as selectedUserSelector } from 'scripts/selectors/profile-service-selectors';
import { selectMembershipCategory } from 'scripts/selectors/population-selectors';

export function getProfile(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getProfileLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getProfile(rallyId);
      let selectedProfile = data.currentUser;
      let selectedProfileDependentSeqNum;
      try {
        selectedProfileDependentSeqNum = sessionStorage.getItem('arcade.selectedProfileDependentSeqNum');
      } catch (e) {
        console.warn('Could not get selected profile dependent sequence number from session storage', e);
      }
      if (selectedProfileDependentSeqNum) {
        selectedProfile =
          data.dependents.find(d => d.dependentSeqNum === selectedProfileDependentSeqNum) || selectedProfile;
      }
      dispatch(getProfileSuccess({ profile: data, selectedProfile }));
    } catch (error) {
      dispatch(getProfileError());
    }
  };
}

export function getClaimsPreferences(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getClaimsPreferencesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getClaimsPreferences(rallyId);
      dispatch(getClaimsPreferencesSuccess(data));
    } catch (error) {
      dispatch(getClaimsPreferencesError());
    }
  };
}

export function getFpcPrimaryCare(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getFpcPrimaryCareLoading());
      const rallyId = selectRallyId(getState());
      const lineOfBusiness = selectedUserSelector.selectLineOfBusiness(getState());
      const membershipCategory = selectMembershipCategory(getState());
      const { data } = await ProfileApi.getFpcPrimaryCare(rallyId, lineOfBusiness, membershipCategory);
      dispatch(getFpcPrimaryCareSuccess(data));
    } catch (error) {
      dispatch(getFpcPrimaryCareError());
    }
  };
}

export function getPrimaryCare(isPcdEligible?: boolean): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getPrimaryCareLoading());
      const rallyId = selectRallyId(getState());
      const {
        data: { perMemberResults },
      } = await ProfileApi.getPrimaryCare(rallyId, isPcdEligible);
      dispatch(getPrimaryCareSuccess(perMemberResults));
    } catch (error) {
      dispatch(getPrimaryCareError());
    }
  };
}

export function getProducts(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getProductsLoading());
      const rallyId = selectRallyId(getState());
      const {
        data: { products },
      } = await ProfileApi.getProducts(rallyId);
      dispatch(getProductsSuccess(products));
    } catch (error) {
      dispatch(getProductsError());
    }
  };
}

export function getReferrals(): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getReferralsLoading());
      const rallyId = selectRallyId(getState());
      const {
        data: { perMemberReferrals },
      } = await ProfileApi.getReferrals(rallyId);
      dispatch(getReferralsSuccess(perMemberReferrals));
    } catch (error) {
      dispatch(getReferralsError());
    }
  };
}

export function getHealthcareCoverages(showCarveoutClaims: boolean): ArcadeThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch(getHealthcareCoveragesLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await ProfileApi.getHealthcareCoverages(rallyId, showCarveoutClaims);
      dispatch(getHealthcareCoveragesSuccess(data));
    } catch (error) {
      dispatch(getHealthcareCoveragesError());
    }
  };
}
