import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { ProviderReferralsGoBackLink } from './provider-referrals-go-back-link';
import { useTranslation } from 'react-i18next';

export const ProviderReferralsHeader: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.PCP);

  return (
    <section className="section-top" data-testid="provider-referrals-header">
      <span className="section-title">
        <ProviderReferralsGoBackLink />
        <h1>{t('PHYSICIAN_REFERRALS')}</h1>
      </span>
    </section>
  );
};
