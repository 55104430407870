import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IIdCard } from 'scripts/api/plans/plans.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { contactUs } from 'scripts/util/resource/resource.constants';

interface IIdCardFlippableProps {
  currentUser: IProfileUser;
  card: IIdCard;
  flipped: boolean;
  population?: IPopulation;
  csMedicalSupportPhoneNum?: string;
}

export const IdCardFlippable: FunctionComponent<IIdCardFlippableProps> = props => {
  const { currentUser, card, flipped, population, csMedicalSupportPhoneNum } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);
  const mrHelp = getResource(contactUs, population) as IResource;

  return (
    <>
      <div className="card-images flippable">
        <img
          className="id-card id-card-front"
          src={'data:image/jpg;base64,' + card.front.stream}
          alt={t(`${Dictionary.ID_CARDS}:FRONT_OF_ID_CARD`)}
          aria-hidden={flipped}
        />
        <img
          className="id-card id-card-back"
          src={'data:image/jpg;base64,' + card.back.stream}
          alt={t(`${Dictionary.ID_CARDS}:BACK_OF_ID_CARD`)}
          aria-hidden={!flipped}
        />
      </div>
      {card.generatedImage && (
        <div className="generated-id-disclaimer">
          {csMedicalSupportPhoneNum && (
            <span className="call-for-support">
              {t(`${Dictionary.ID_CARDS}:CALL_US_MEMBER_PHONE_TTY`, { phoneNum: csMedicalSupportPhoneNum })}
            </span>
          )}
          <span>
            {t(`${Dictionary.ID_CARDS}:GENERATED_ID_DISCLAIMER_` + currentUser.lineOfBusiness.replace('&', ''), {
              link: mrHelp,
            })}
          </span>
        </div>
      )}
    </>
  );
};
