import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { getLocale } from 'scripts/util/locale/locale';
import {
  connectEditPcp,
  connectProvider,
  connectQueryLocalePartner,
  connectSaved,
  connectVirtualVisits,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { userUris } from 'scripts/util/uri/uri';
import pcpTemplate from 'views/dashboard/pcp.html';
import { CoverageStatus, CoverageType, RelationshipType } from '../../../api/api.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import {
  FpcPcpType,
  IPcpPcdEligibility,
  IPrimaryCareFpc,
  IPrimaryCarePerMember,
  IPrimaryCareUserInfo,
  IProfileUser,
  IReferralsPerMember,
  LineOfBusiness,
  MembershipCategory,
  PcpMemberState,
  PcpType,
  PhoneType,
} from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { ILocale } from '../../../util/locale/locale.interfaces';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IPopulationService } from '../../../util/population/population.service';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IPrimaryInfo {
  activeDocId?: string;
  activeDocName?: string;
  phoneNumber?: string;
  request?:
    | Observable<IPrimaryCarePerMember>
    | Observable<IPrimaryCareFpc>
    | Observable<[IPrimaryCarePerMember, IPrimaryCareFpc]>;
  statusText?: string;
}

// Filler data to be replaced in ARC-5493
export interface ISavedProvider {
  providerType: string;
  name: string;
  uniqueId: string;
  phones: string;
  specialty: string;
}

export interface IChangePcdInfo {
  rallyId?: string;
  dependentSeqNum?: string;
  planName?: string;
  planId?: string;
  planNetworkId?: string;
  providerId?: string;
}

export class PcpController implements ng.IComponentController {
  private isCS: boolean;
  private localeSubscription: Subscription;
  private profileSubscription: Subscription;
  public allInfoRequests: Observable<any>;
  public canAccessPcpSectionInfo = true;
  public canChangePcpOnline = true;
  public changePcdInfo: IChangePcdInfo = {};
  public changePcdLink: string;
  public connectEditPcp: IResource;
  public connectProvider: IResource;
  public connectQueryLocalePartner: IResource;
  public connectSaved: IResource;
  public connectVirtualVisits: IResource;
  public eligibility: IPcpPcdEligibility;
  public infoAndReferralRequests: Observable<[[IPrimaryCarePerMember, IPrimaryCarePerMember], IReferralsPerMember]>;
  public isDsnp: boolean;
  public isOxford: boolean;
  public isTermedDental: boolean;
  public isTermedMedical: boolean;
  public locale: ILocale;
  public numReferrals: number;
  public pcdInfo: IPrimaryInfo = {};
  public pcpInfo: IPrimaryInfo = {};
  public pcpReferralsEligible: boolean;
  public referralsRequest: Observable<IReferralsPerMember>;
  public savedProviders: ISavedProvider[];
  public virtualVisitEligible: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private plansService: IPlansService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PCP);

    this.connectSaved = connectSaved;
    this.connectProvider = connectProvider;
    this.connectQueryLocalePartner = connectQueryLocalePartner;
    this.connectEditPcp = connectEditPcp;
    this.connectVirtualVisits = connectVirtualVisits;
    this.changePcdLink = this.Environment.CONFIG.ARCADE_WEB_SUNDOG_PCD_CHANGE_URL;
    /* Saved Providers is on the roadmap for Q3 2019
    This is just a placeholder for that eventual feature */
    this.savedProviders = [];
  }

  public $onInit(): void {
    const heartbeat$ = this.userService.getHeartbeat();
    const selectedUser$ = heartbeat$.let(this.profileService.toCurrentProfile());
    const loggedInUser$ = heartbeat$.let(this.profileService.toProfile()).map(profile => profile.data.currentUser);

    Observable.zip(selectedUser$, loggedInUser$).subscribe(([selectedUser, loggedInUser]) => {
      this.updateContent(selectedUser, loggedInUser);
    });

    this.profileSubscription = Observable.zip(this.profileService.profileChanged, loggedInUser$).subscribe(
      ([selectedUser, loggedInUser]) => {
        this.updateContent(selectedUser, loggedInUser);
      },
    );

    this.locale = getLocale();
    this.localeSubscription = this.localeService.localeChanged.subscribe(locale => {
      this.locale = locale;
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
    this.localeSubscription.unsubscribe();
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  public goChangePcd(): void {
    const form = document.createElement('form');
    form.action = this.changePcdLink;
    form.method = 'POST';
    const content = document.createElement('input');
    content.name = 'Content';
    content.type = 'hidden';
    content.value = this.createSundogRequestJson();
    form.appendChild(content);
    document.body.appendChild(form);
    form.submit();
  }

  private updateContent(selectedUserProfile: IProfileUser, loggedInUserProfile: IProfileUser): void {
    this.isOxford = selectedUserProfile.membershipCategory === MembershipCategory.OXFORD;
    this.isCS = selectedUserProfile.lineOfBusiness === LineOfBusiness.CS;
    this.isDsnp = selectedUserProfile.memberFeatures.isDsnp;
    this.isTermedMedical = this.profileService.isTermedForCoverageType(
      CoverageType.Medical,
      selectedUserProfile.planCoverages,
    );
    this.isTermedDental = this.profileService.isTermedForCoverageType(
      CoverageType.Dental,
      selectedUserProfile.planCoverages,
    );
    this.eligibility = this.profileService.getPcpPcdEligibility(selectedUserProfile.memberFeatures);
    this.eligibility.pcp = this.isTermedMedical ? true : this.eligibility.pcp;

    this.pcpInfo.activeDocName = undefined;
    this.pcpInfo.phoneNumber = undefined;
    this.pcpInfo.statusText = undefined;
    this.getUserPcpInfo(selectedUserProfile);
    this.pcpReferralsEligible =
      selectedUserProfile.memberFeatures.hasPcpReferrals && selectedUserProfile.lineOfBusiness !== LineOfBusiness.CS;
    if (
      this.pcpReferralsEligible &&
      ((loggedInUserProfile.relationshipType === RelationshipType.Subscriber &&
        !(selectedUserProfile.userInfo.age >= 13)) ||
        loggedInUserProfile.dependentSeqNum === selectedUserProfile.dependentSeqNum)
    ) {
      this.getUserReferralsInfo(selectedUserProfile);
    }

    if (this.eligibility.pcd) {
      this.pcdInfo.activeDocName = undefined;
      this.pcdInfo.statusText = undefined;
      this.getUserPcdInfo(selectedUserProfile);
      this.getChangePcdInfo(selectedUserProfile);
    }

    this.virtualVisitEligible =
      this.featureFlagService.isEIVirtualVisitPCPRowItemOn() &&
      !this.isTermedMedical &&
      selectedUserProfile.planCoverages.some(coverage => coverage.planFeatures.isDVCN);

    this.setInfoRequests();
    this.allInfoRequests.subscribe();
  }

  private setInfoRequests(): void {
    if (this.eligibility.pcd && this.canAccessPcpSectionInfo) {
      this.allInfoRequests = Observable.zip(this.pcdInfo.request, this.pcpInfo.request);
    } else if (this.eligibility.pcd || this.canAccessPcpSectionInfo) {
      this.allInfoRequests = this.pcdInfo.request || this.pcpInfo.request;
    } else {
      this.allInfoRequests = Observable.of(true);
    }
    this.infoAndReferralRequests = Observable.zip(this.allInfoRequests, this.referralsRequest);
  }

  private getUserPcpInfo(profile: IProfileUser): void {
    const primaryCare$ = this.userService
      .getHeartbeat()
      .flatMap(heartbeat => this.profileService.getPrimaryCare(heartbeat.data.rallyId))
      .map(pcpInfo => pcpInfo.data.perMemberResults);

    const fpcPrimaryCare$ = this.userService
      .getHeartbeat()
      .flatMap(heartbeat =>
        this.profileService.getFpcPrimaryCare(
          heartbeat.data.rallyId,
          profile.lineOfBusiness,
          profile.membershipCategory,
        ),
      )
      .map(fpcPcpRsp => fpcPcpRsp.data);

    if (this.eligibility.pcp && this.featureFlagService.isFpcPcpForGatedOn()) {
      this.pcpInfo.request = Observable.zip(primaryCare$, fpcPrimaryCare$);
      this.pcpInfo.request.subscribe(([perMemberPcpInfo, fpcPcpInfo]) => {
        this.setInfo(perMemberPcpInfo[profile.dependentSeqNum], this.pcpInfo, fpcPcpInfo);
      }, console.warn);
    } else if (this.eligibility.pcp) {
      this.pcpInfo.request = primaryCare$;
      this.pcpInfo.request.subscribe(perMemberPcpInfo => {
        this.setInfo(perMemberPcpInfo[profile.dependentSeqNum], this.pcpInfo);
      }, console.warn);
    } else {
      this.pcpInfo.request = fpcPrimaryCare$;
      this.pcpInfo.request.subscribe(fpcPcpInfo => {
        this.setInfo(undefined, this.pcpInfo, fpcPcpInfo);
      }, console.warn);
    }
  }

  private getUserPcdInfo(profile: IProfileUser): void {
    this.pcdInfo.request = this.userService
      .getHeartbeat()
      .flatMap(heartbeat => this.profileService.getPrimaryCare(heartbeat.data.rallyId, true))
      .map(pcpInfo => pcpInfo.data.perMemberResults);
    this.pcdInfo.request.subscribe(perMemberPcpInfo => {
      this.setPcdInfo(perMemberPcpInfo[profile.dependentSeqNum], this.pcdInfo);
    }, console.warn);
  }

  private getUserReferralsInfo(profile: IProfileUser): void {
    this.referralsRequest = this.userService
      .getHeartbeat()
      .flatMap(heartbeat => this.profileService.getReferrals(heartbeat.data.rallyId))
      .map(referralsInfo => referralsInfo.data.perMemberReferrals);
    this.referralsRequest.subscribe(perMemberReferrals => {
      const selectedUserReferrals = perMemberReferrals[profile.dependentSeqNum];
      this.numReferrals = selectedUserReferrals ? selectedUserReferrals.totalNumberOfActiveReferrals : 0;
    }, console.warn);
  }

  private setInfo(
    pcpUserInfo: IPrimaryCareUserInfo,
    primaryInfo: IPrimaryInfo,
    fpcPrimaryCareInfo?: IPrimaryCareFpc,
  ): void {
    let hasFuturePcp = false;
    if (
      ((this.eligibility.pcp && this.featureFlagService.isFpcPcpForGatedOn()) || !this.eligibility.pcp) &&
      fpcPrimaryCareInfo
    ) {
      fpcPrimaryCareInfo.doctorDetails.forEach(pcp => {
        const canShow = pcp.pcpType === FpcPcpType.Medical;
        if (pcp.status === CoverageStatus.Active && canShow) {
          primaryInfo.activeDocName = this.profileService.getFullName(pcp);
          primaryInfo.activeDocId = pcp.pcpId.split(' ').join('%7C'); // encode any spaces
          const firstPhoneNumber = pcp.phones.filter(phone => phone.phoneType === PhoneType.phone)[0];
          primaryInfo.phoneNumber = firstPhoneNumber && firstPhoneNumber.number;
        } else if (pcp.status === CoverageStatus.Future) {
          hasFuturePcp = true;
        }
      });
    } else if (pcpUserInfo && pcpUserInfo.primaryCarePhysicians) {
      pcpUserInfo.primaryCarePhysicians.forEach(pcp => {
        const canShow = this.featureFlagService.isPcpGroupOrFacilityNameOn()
          ? [PcpType.Physician, PcpType.Facility, PcpType.Group].indexOf(pcp.pcpType) > -1
          : pcp.pcpType === PcpType.Physician;
        if (pcp.status === CoverageStatus.Active && canShow) {
          primaryInfo.activeDocName = this.profileService.getFullName(pcp);
          if (this.isOxford || this.isCS) {
            primaryInfo.activeDocId = pcp.providerId.toString();
          } else {
            primaryInfo.activeDocId = pcp.providerId + '%7C' + pcp.addressSequenceNumber;
          }
        } else if (pcp.status === CoverageStatus.Future) {
          hasFuturePcp = true;
        }
      });
    }

    if (primaryInfo.activeDocName && hasFuturePcp) {
      primaryInfo.statusText = 'CHANGE_IN_PROGRESS';
    } else if (primaryInfo.activeDocName) {
      primaryInfo.statusText =
        pcpUserInfo && pcpUserInfo.memberState === PcpMemberState.PcpChangeInProgress ? 'CHANGE_IN_PROGRESS' : 'CHANGE';
    } else if (hasFuturePcp) {
      primaryInfo.statusText = 'ASSIGNMENT_IN_PROGRESS';
    } else {
      if (pcpUserInfo && pcpUserInfo.memberState === PcpMemberState.PcpChangeInProgress) {
        primaryInfo.statusText = 'ASSIGNMENT_IN_PROGRESS';
      } else {
        primaryInfo.statusText = 'ASSIGN';
        if (this.isDsnp) {
          // hide pcp section for dsnp members if they don't have an existing pcp or a pending assignment
          this.canAccessPcpSectionInfo = false;
        }
      }
    }

    const lockedIn = (fpcPrimaryCareInfo && fpcPrimaryCareInfo.lockedIn) || (pcpUserInfo && pcpUserInfo.lockedIn);
    this.canChangePcpOnline = !(lockedIn && (this.isOxford || this.isCS));
    if (
      this.isTermedMedical &&
      !(primaryInfo.statusText === 'CHANGE' || primaryInfo.statusText === 'CHANGE_IN_PROGRESS')
    ) {
      this.canAccessPcpSectionInfo = false; // hide pcp section for termed members if they don't have an existing saved pcp
    }
  }

  private setPcdInfo(pcdUserInfo: IPrimaryCareUserInfo, primaryInfo: IPrimaryInfo): void {
    if (pcdUserInfo && pcdUserInfo.primaryCarePhysicians) {
      pcdUserInfo.primaryCarePhysicians.forEach(pcd => {
        if (pcd.status === CoverageStatus.Active && pcd.pcpType === PcpType.Dental) {
          primaryInfo.activeDocName = this.profileService.getFullName(pcd);
          primaryInfo.activeDocId = pcd.providerId + '%7C' + pcd.addressSequenceNumber;
          this.changePcdInfo.providerId = pcd.providerId;
        }
      });
      if (primaryInfo.activeDocId) {
        primaryInfo.statusText = 'CHANGE';
      } else {
        primaryInfo.statusText = 'ASSIGN';
      }
    }
    if (this.isTermedDental && primaryInfo.statusText !== 'CHANGE') {
      this.eligibility.pcd = false; // hide pcd section for termed members if they don't have an existing saved pcd
    }
  }

  private getChangePcdInfo(profile: IProfileUser): void {
    this.changePcdInfo.rallyId = profile.rallyId;
    this.changePcdInfo.dependentSeqNum = profile.dependentSeqNum.toString();

    const dentalCoverage = this.profileService.getCoverage(CoverageType.Dental, profile.planCoverages);
    if (dentalCoverage) {
      this.changePcdInfo.planId = dentalCoverage.planFeatures.planId;

      this.plansService
        .getBenefits(profile.rallyId)
        .map(benefits => benefits.data.benefits)
        .subscribe(benefits => {
          benefits
            .filter(({ coverageType }) => coverageType === CoverageType.Dental)
            .forEach(benefit => {
              this.changePcdInfo.planName = benefit.planName;
            });
        }, console.warn);

      this.profileService
        .getProducts(profile.rallyId)
        .map(product => product.data.products)
        .map(products => products.dental)
        .map(dental => dental.planInformation)
        .subscribe(planInfo => {
          this.changePcdInfo.planNetworkId = planInfo.networkId;
        }, console.warn);
    }
  }

  private createSundogRequestJson(): string {
    const jsonObj = {
      rallyId: this.changePcdInfo.rallyId,
      correlationId: this.generateCorrelationId(),
      searchType: 'changePCD',
      returnUrl: userUris.sundog(this.changePcdInfo.rallyId),
      plans: [
        {
          id: this.changePcdInfo.planId || '',
          name: this.changePcdInfo.planName || '',
          networkIds: [this.changePcdInfo.planNetworkId || ''],
        },
      ],
      portalId: 'myuhc',
      dependentSequenceNumber: this.changePcdInfo.dependentSeqNum,
      registeredUser: true,
      language: this.locale.language,
      providerId: undefined,
    };
    if (this.changePcdInfo.providerId !== undefined) {
      jsonObj.providerId = this.changePcdInfo.providerId;
    }

    const jsonContent = JSON.stringify(jsonObj);
    return jsonContent;
  }

  private generateCorrelationId(): string {
    let correlationId = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 14; i++) {
      correlationId += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return correlationId;
  }
}

export class PcpComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PcpController;
    this.templateUrl = pcpTemplate;
  }
}
