import { ArcadeAuthType, IConfig, IFeatureFlags, ArcadeWebTheme, ArcadeEnvironment } from './environment.interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Window {
    CONFIG: IConfig;
    FEATURE_FLAGS: IFeatureFlags;
  }
}

const DEFAULT_CONFIG: IConfig = {
  ARCADE_WEB_MAX_NUM_OF_ACTIVATE_INLINE_BANNERS: 3,
  ARCADE_WEB_VERSION: process.env.VERSION,
  ARCADE_WEB_THEME: process.env.THEME || ArcadeWebTheme.UHC,
  ARCADE_WEB_ENVIRONMENT_NAME: ArcadeEnvironment.Local,
  ARCADE_WEB_CACHE_TTL_MS: 15 * 60 * 1000,
  ARCADE_WEB_ACTIVITY_TIMEOUT_MS: 5 * 60 * 1000,
  ARCADE_WEB_HEARTBEAT_BUFFER_MS: 0.5 * 60 * 1000,
  ARCADE_WEB_TRACKING_INTERVAL_MS: 0.5 * 60 * 1000,
  ARCADE_WEB_TRACKING_MAX_RETRY_COUNT: 4,
  ARCADE_WEB_BASE_URL: '',
  ARCADE_WEB_BASE_API_URL: '',
  ARCADE_WEB_ALTERNATIVE_BASE_URLS: '',
  ARCADE_WEB_AMPLITUDE_API_KEY: '702165734fc587cf157c1b227edc4044',
  ARCADE_WEB_GENESYS_URL: 'https://myoptum-stage.akamaized.net/etc/designs/genesys-cdn/stage/v1.1/js/ogn-genesys.js',
  ARCADE_WEB_GOOGLE_ANALYTICS_ID: 'UA-77207643-1',
  ARCADE_WEB_IPERCEPTIONS_KEY: 'ef6177e6-a195-4fc1-9b32-7636466e19b5',
  ARCADE_WEB_IPERCEPTIONS_SURVEY_ID: '127146',
  ARCADE_WEB_ADOBE_ANALYTICS_URL:
    '//assets.adobedtm.com/bff7f95d5e60a9bb1bafa9115ce25108c698418f/' +
    'satelliteLib-055c804974ec04f86156958042df3e3fdae95610-staging.js',
  ARCADE_WEB_CAREMARK_URL: 'https://federate.myuhc.com/myuhc/idp/startSSO.ping',
  ARCADE_WEB_CS_CUSTOM_FAQ_POLICIES: 'MOHNET,NJFAMCAR',
  ARCADE_WEB_CS_HIDE_PRIOR_AUTH_FAQ_PRIMARY_CUSTOMER_IDS: 'MAUHCSCO,0OHPHCP,0OHMMEP',
  ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_CUSTOMER_IDS: 'NYCHP,NYEPP,0PACHIP',
  ARCADE_WEB_EXPRESS_SCRIPTS_URL: 'https://www.expressscripts.com',
  ARCADE_WEB_WALGREENS_URL: 'https://www.walgreens.com/pharmacy',
  ARCADE_WEB_DEFAULT_AUTH: ArcadeAuthType.Rally,
  ARCADE_WEB_HIDE_COSTS_POLICIES: '0214257,0704534,0742714,0743465,0743843',
  ARCADE_WEB_HIDE_PCP_SECTION_POLICIES:
    '0100400,0144387,0182019,0182563,0183644,0184094,0196659,0200228,0301000,0517000,' +
    '0701444,0702551,0704201,0710639,0711809,0712790,0714273,0752420,0755446,0902854,0905531,0908909',
  ARCADE_WEB_SAME_TAB_PROMO_LINKS: 'ihr.werally.com,medicare.uhc.com,renewpositivity.com,uhcflulocator.com',
  ARCADE_WEB_CS_WHITELIST_LINKS:
    'healthproductsbenefit.com,member.uhc.com,myuhc.com,nurselinechat.com,' +
    'personalhealthmessagecenter.com,provider.liveandworkwell.com,rallyhealth.com,uhc.com,uhcbabyblocks.com,uhccommunityplan.com,' +
    'uhcomw.com,usapayx.com,test1.myuhc.com,systest1.myuhc.com,es-systest1.myuhc.com,ht-systest1.myuhc.com,ilo-systest1.myuhc.com,' +
    'kr-systest1.myuhc.com,tag-systest1.myuhc.com,vi-systest1.myuhc.com,zh-systest1.myuhc.com',
  ARCADE_WEB_MR_WHITELIST_LINKS:
    'aarpmedicareplans.com,beginsat50.com,docusign.net,healthyourway.com,learn.optumrx.com,member' +
    '.uhcinfo.com,myaarpmedicareplanorientation.com,optumrx.com,personalhealthmessagecenter.com,professionals.optumrx.com,read' +
    '.nxtbook.com,renew.aarpmedicareplans.com,renew.member.uhcmedicaresolutions.com,renewpositivity.com,uhc.com,uhcforcaregivers.com,' +
    'uhcforcaregivers.com,uhcmedicaresolutions.com,uhcvirtualvisits.com,unitedhealthcareonline.com,yourdentalplan.com',
  ARCADE_WEB_LINK_TIMER_MS: 3000,
  ARCADE_WEB_RALLY_ADVANTAGE_URL: 'https://home.dev.werally.in',
  ARCADE_WEB_RALLY_AUTH_URL: 'https://accounts.ecstatic-ride.rally-dev.com',
  ARCADE_WEB_ARACHNE_SESSION_BASE_URL: '',
  ARCADE_WEB_RALLY_ADVANTAGE_EDGE_URL: '',
  ARCADE_WEB_RALLY_AUTH_LOGOUT_PATH: '/lo',
  ARCADE_WEB_RALLY_CONNECT_URL: 'https://connect.dev.werally.in',
  ARCADE_WEB_RALLY_ENGAGE_URL: 'https://dev.werally.in',
  ARCADE_WEB_RALLY_IHR_URL: 'https://ihr.dev.werally.in',
  ARCADE_WEB_RALLY_PAY_BASE_API_URL: 'http://localhost:9300',
  ARCADE_WEB_RALLY_PAY_ENVIRONMENT: 'http://localhost:1977',
  ARCADE_WEB_RALLY_PAY_WIDGET_CONFIRMATION: 'showMeRallyPay!',
  ARCADE_WEB_RALLY_RX_ADVANTAGE_URL: 'https://rx.dev.werally.in',
  ARCADE_WEB_RALLY_RX_POLICIES: '',
  ARCADE_WEB_RALLY_RX_URL: 'https://rx.dev.werally.in',
  ARCADE_WEB_RALLY_REWARDS_URL: 'https://rewards.dev.werally.in',
  ARCADE_WEB_OPTUM_AUTH_URL: 'http://localhost:10083/optum',
  ARCADE_WEB_OPTUM_AUTH_PATH: '/ext/as/authorization.oauth2',
  ARCADE_WEB_OPTUM_AUTH_CLIENT_ID: 'systest-uhc-digital',
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_CLIENT_ID: 'stage-uhcmnr-digital',
  ARCADE_WEB_OPTUM_AUTH_SCOPE: 'openid profile benefits',
  ARCADE_WEB_OPTUM_AUTH_PFID: 'systest3myuhcdirect',
  ARCADE_WEB_OPTUM_AARP_AUTH_PFID: 'stagemnraarp',
  ARCADE_WEB_OPTUM_MEDICA_AUTH_PFID: 'stagemnrmedica',
  ARCADE_WEB_OPTUM_MEDICARE_AUTH_PFID: 'stagemnrmedicare',
  ARCADE_WEB_OPTUM_PCP_AUTH_PFID: 'stagemnrpcp',
  ARCADE_WEB_OPTUM_RETIREE_AUTH_PFID: 'stagemnrretiree',
  ARCADE_WEB_OPTUM_RX_FIND_AND_PRICE_DRUGS:
    'https://chp-stage.optumrx.com/sso/myuhc/rxp/login.html?DeepLink=rxpricingtool',
  ARCADE_WEB_OPTUM_RX_FIND_PHARMACY: 'https://chp-stage.optumrx.com/sso/myuhc/rxp/login.html?DeepLink=locatepharmacy',
  ARCADE_WEB_OPTUM_RX_ORDER_PRESCIPTIONS: 'https://chp-stage.optumrx.com/sso/myuhc/rxp/login.html?DeepLink=default',
  ARCADE_WEB_OPTUM_RX_CHECK_ORDER_STATUS: 'https://chp-stage.optumrx.com/sso/myuhc/rxp/login.html?DeepLink=orderstatus',
  ARCADE_WEB_OPTUM_RX_SPENDING_AND_COST_SUMMARY:
    'https://chp-stage.optumrx.com/sso/ucp/login.html?DeepLink=benefitsinformation',
  ARCADE_WEB_MYUHC_BASE_URL: 'http://localhost:1972',
  ARCADE_WEB_MYUHC_AARP_BASE_URL: 'http://localhost:1972/aarp',
  ARCADE_WEB_MYUHC_MEDICA_BASE_URL: 'http://localhost:1972/medica',
  ARCADE_WEB_MYUHC_MEDICARE_BASE_URL: 'http://localhost:1972/medicare',
  ARCADE_WEB_MYUHC_PCP_BASE_URL: 'http://localhost:1972/pcp',
  ARCADE_WEB_MYUHC_RETIREE_BASE_URL: 'http://localhost:1972/retiree',
  ARCADE_WEB_MYUHC_COMMUNITY_BASE_URL: 'http://localhost:1972/community',
  ARCADE_WEB_MYUHC_LEGACY_BASE_URL: 'https://systest1.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_ES_BASE_URL: 'https://es-systest1.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_ILO_BASE_URL: 'https://ilo-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_ZH_BASE_URL: 'https://zh-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_VI_BASE_URL: 'https://vi-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_KR_BASE_URL: 'https://kr-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_HT_BASE_URL: 'https://ht-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LEGACY_TAG_BASE_URL: 'https://tag-systest3.myuhc.com',
  ARCADE_WEB_MYUHC_LOGOUT_PATH: '/member/signOut.do',
  ARCADE_WEB_MYUHC_EXTEND_SESSION_PATH: '/apps/myuhc/session/extend',
  ARCADE_WEB_NOTIFICATION_MESSAGE:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
    'Donec eros nulla, congue id ex ac, sollicitudin fringilla felis. Maecenas semper, sem at ' +
    'pulvinar feugiat, augue purus interdum dui, vitae laoreet orci nunc nec erat. Duis faucibus ' +
    'ultricies nulla, eu euismod odio elementum vel. <a href="https://www.lipsum.com/">More Ipsum</a>.',
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_ENDPOINT_URL: 'https://sf-00000404.web.sundaysky-sandbox.com',
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_URL: 'https://player.sundaysky.com/customers/SF-00000404/loading.gif',
  ARCADE_WEB_SUNDAY_SKY_ACTIVATE_TOKEN: 'uhc',
  ARCADE_WEB_SUNDAY_SKY_ENDPOINT_URL: 'https://sf-00000265.web.sundaysky-sandbox.com',
  ARCADE_WEB_SUNDAY_SKY_LOADING_URL: 'https://player.sundaysky.com/customers/SF-00000265/loading.gif',
  ARCADE_WEB_SUNDAY_SKY_SCRIPT_URL: '//play.sundaysky.com/main/sundaysky-player.grey.en-us.min.js',
  ARCADE_WEB_SUNDAY_SKY_TOKEN: 'uhc',
  ARCADE_WEB_SUNDAY_SKY_TRANSCRIPT_WHITELIST: 'stream-edge.web.sundaysky.com',
  ARCADE_WEB_CLAIMS_SEARCH_MONTHS_AGO: '18',
  ARCADE_WEB_FITBIT_ENABLED_POLICIES: 'H7404-004,H2406-020',
  ARCADE_WEB_FITBIT_ENABLED_CLIENT_IDS: 'MH7404-004',
  ARCADE_WEB_SUNDOG_PCD_CHANGE_URL: 'https://test-uhc.cs65.force.com/providersearch',
  ARCADE_WEB_MR_RX_SUPPRESSION_GROUP_NUMBERS: '004237-7DU',
  ARCADE_WEB_MR_DONUT_SUPPRESSION_LIST: 'H0543,H0609,H3749,H3805,H4590',
};

const envConfig = window.CONFIG;
const useDefaultConfig = !envConfig || (envConfig.constructor === Object && Object.keys(envConfig).length === 0);
// The values defined in DEFAULT_CONFIG are only used locally. All configurations necessary for the deployed application
// need to be done in netpune deploy.
const CONFIG: IConfig = useDefaultConfig ? DEFAULT_CONFIG : envConfig;

export default CONFIG;
