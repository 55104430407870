import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';

export const NoProviderReferralsMessage: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.PCP);

  return (
    <div className="no-referrals-message" data-testid="no-provider-referrals-message">
      {t('NO_REFERRALS_MESSAGE')}
    </div>
  );
};
