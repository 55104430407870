import { dateStringToMoment, stringToFloat } from 'scripts/util/conversion/conversion';
import {
  IClaim,
  IClaimsManageResponse,
  IClaimsSummaryResponse,
  IHealthcareClaimDetails,
  IManageClaimData,
  ClaimType,
  IClaimTotalsResponse,
  IAllClaimSummariesResponse,
} from './claims.interfaces';
import { CacheName, ITimePeriod } from 'scripts/api/api.interfaces';
import { claimUris } from 'scripts/util/uri/uri';
import { getCache } from 'scripts/api/cache';
import HttpClient from 'scripts/api/http-client';
import { IProfileUser, IProfile, IDependentAndCoverageTypes } from 'scripts/api/profile/profile.interfaces';

function getAllHealthcare(
  profile: IProfile,
  healthcareCoverages: IDependentAndCoverageTypes[],
  period: ITimePeriod,
): Promise<IAllClaimSummariesResponse> {
  const { currentUser, rallyId } = profile;
  const { startDate, endDate } = period;
  const params = {
    cov: healthcareCoverages.map(c => `${c.dependent.sequenceNumber};${c.claimTypes.join(',')}`),
    processedStartDate: startDate.format('YYYY-MM-DD'),
    processedEndDate: endDate.format('YYYY-MM-DD'),
    membershipCategory: currentUser.membershipCategory,
  };
  const url = claimUris.healthcareV3(rallyId, params);
  return HttpClient.get<IAllClaimSummariesResponse>(url, { cacheName: CacheName.Claims }).then(rsp => {
    for (const claim of rsp.data.claims) {
      stringToFloat(claim, 'balance', 'copay', 'value');
      stringToFloat(claim, 'balance', 'deductible', 'value');
      stringToFloat(claim, 'balance', 'healthPlanDiscount', 'value');
      stringToFloat(claim, 'balance', 'healthPlanPays', 'value');
      stringToFloat(claim, 'balance', 'onlinePaidAmount', 'value');
      stringToFloat(claim, 'balance', 'patientResponsibility', 'value');
      stringToFloat(claim, 'balance', 'planPaidAmount', 'value');
      stringToFloat(claim, 'balance', 'totalBilledAmount', 'value');
      stringToFloat(claim, 'balance', 'youMayOweAmount', 'value');
      dateStringToMoment(claim, 'lastServiceDate');
      dateStringToMoment(claim, 'processedDate');
      dateStringToMoment(claim, 'serviceDate');
    }
    return rsp;
  });
}

function getSummary(profile: IProfileUser): Promise<IClaimsSummaryResponse> {
  const { dependentSeqNum, membershipCategory } = profile;
  const url = claimUris.summary(profile.rallyId, { d: dependentSeqNum, membershipCategory });
  return HttpClient.get<IClaimsSummaryResponse>(url, { cacheName: CacheName.Claims }).then(rsp => {
    for (const claim of rsp.data) {
      stringToFloat(claim, 'balance', 'copay', 'value');
      stringToFloat(claim, 'balance', 'deductible', 'value');
      stringToFloat(claim, 'balance', 'healthPlanDiscount', 'value');
      stringToFloat(claim, 'balance', 'healthPlanPays', 'value');
      stringToFloat(claim, 'balance', 'patientResponsibility', 'value');
      stringToFloat(claim, 'balance', 'totalBilledAmount', 'value');
      stringToFloat(claim, 'balance', 'youMayOweAmount', 'value');
      dateStringToMoment(claim, 'lastServiceDate');
      dateStringToMoment(claim, 'processedDate');
      dateStringToMoment(claim, 'serviceDate');
    }
    return rsp;
  });
}

function getTotals(profile: IProfileUser, requestedClaimType?: ClaimType): Promise<IClaimTotalsResponse> {
  const claimType: ClaimType = requestedClaimType || ClaimType.All;
  const { dependentSeqNum, membershipCategory, rallyId } = profile;
  const url = claimUris.totals(rallyId, { d: dependentSeqNum, membershipCategory, claimType });
  return HttpClient.get<IClaimTotalsResponse>(url, { cacheName: CacheName.Claims }).then(rsp => {
    for (const b of rsp.data) {
      stringToFloat(b, 'balance', 'healthPlanDiscount', 'value');
      stringToFloat(b, 'balance', 'healthPlanPays', 'value');
      stringToFloat(b, 'balance', 'other', 'value');
      stringToFloat(b, 'balance', 'patientResponsibility', 'value');
      stringToFloat(b, 'balance', 'totalBilledAmount', 'value');
    }
    return rsp;
  });
}

function saveClaim(profile: IProfileUser, claim: IClaim | IHealthcareClaimDetails): Promise<IClaimsManageResponse> {
  const firstServiceDate =
    (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
  const lastServiceDate =
    (claim as IClaim).lastServiceDate || (claim as IHealthcareClaimDetails).claimDates.lastServiceDate;
  const manageClaimData: IManageClaimData = {
    claimKey: claim.claimKey,
    manageClaim: {
      markPaid: claim.claimManagementInfo.markPaid,
      serviceDate: firstServiceDate.format('YYYY-MM-DD'),
      lastServiceDate: lastServiceDate.format('YYYY-MM-DD'),
      providerName: claim.providerName || '',
    },
  };
  const postUrl = claimUris.manage(profile.rallyId);
  return HttpClient.post<IClaimsManageResponse>(postUrl, manageClaimData).then(rsp => {
    getCache(CacheName.Claims).removeAll();
    getCache(CacheName.ClaimsNeedAttention).removeAll();
    getCache(CacheName.ClaimsHealthcare).removeAll();
    return rsp;
  });
}

export const ClaimsApi = {
  getAllHealthcare,
  getSummary,
  getTotals,
  saveClaim,
};
