import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { SAME_TAB_PROMO_LINKS } from 'scripts/util/population/population.constants';
import { isLinkToArcade, isLinkToConnect, parse } from 'scripts/util/uri/uri';

export function hasPayNowSuppression(clientConfig: IClientConfig): boolean {
  return clientConfig && !!clientConfig.suppressions.payNowSuppression;
}

export function hasRallypayRolloutEnabledSuppression(clientConfig: IClientConfig): boolean {
  return clientConfig && !!clientConfig.suppressions.rallypayRolloutEnabled;
}

export function hasShowMerpLabel(clientConfig: IClientConfig): boolean {
  return clientConfig && clientConfig.suppressions.showMERPLabel;
}

export function shouldOpenPromoInSameTab(link: string): boolean {
  const anchor = parse(link);
  const hostname = anchor.hostname.indexOf('www.') === 0 ? anchor.hostname.substr(4) : anchor.hostname;
  return isLinkToArcade(link) || isLinkToConnect(link) || SAME_TAB_PROMO_LINKS[hostname];
}
