import React, { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { getResource } from 'scripts/util/resource/resource';
import { connectSaved } from 'scripts/util/resource/resource.constants';
import { ISavedProvider } from './pcp.component';
import { IProfileUser, IReferralsPerMember, LineOfBusiness } from 'scripts/api/profile/profile.interfaces';
import { RelationshipType } from 'scripts/api/api.interfaces';
import { getNumberOfSelectedUserReferrals } from 'scripts/util/profile/profile';
import { withTrackDataUponRender } from 'scripts/hoc/with-track-data-upon-render/with-track-data-upon-render';

const Aside: FunctionComponent<HTMLAttributes<HTMLElement>> = props => <aside {...props} />;
const AsideWithTrackDataUponRender = withTrackDataUponRender(Aside);
const ViewAllLinkWithClickTracking = withClickTracking(Link, 'view-all');
const PcpReferralsLinkWithClickTracking = withClickTracking(Link, 'referrals');

export interface IPcpStripHeaderProps {
  currentUser: IProfileUser;
  isTermedMedical: boolean;
  population: IPopulation;
  referralsError: boolean;
  referralsPerMember: IReferralsPerMember;
  savedProviders: ISavedProvider[];
  selectedUser: IProfileUser;
}

export const PcpStripHeader: FunctionComponent<IPcpStripHeaderProps> = props => {
  const {
    currentUser,
    isTermedMedical,
    population,
    referralsError,
    referralsPerMember,
    savedProviders,
    selectedUser,
  } = props;
  const { t } = useTranslation(Dictionary.PCP);
  const savedNumTranslateValue = savedProviders.length || '';

  let numberOfSelectedUserReferrals;
  if (referralsPerMember || referralsError) {
    numberOfSelectedUserReferrals = getNumberOfSelectedUserReferrals(referralsPerMember, selectedUser);
  }

  const isPcpReferralsEligible =
    selectedUser.memberFeatures.hasPcpReferrals &&
    selectedUser.lineOfBusiness !== LineOfBusiness.CS &&
    ((currentUser.relationshipType === RelationshipType.Subscriber && !(selectedUser.userInfo.age >= 13)) ||
      currentUser.dependentSeqNum === selectedUser.dependentSeqNum);
  const providerReferralsTranslationText =
    numberOfSelectedUserReferrals === 1 ? 'PHYSICIAN_REFERRAL' : 'PHYSICIAN_REFERRALS';

  return (
    <header className="section-header">
      <h2>{t('MY_PROVIDERS_AND_FACILITIES')}</h2>
      {!isTermedMedical && (
        <ViewAllLinkWithClickTracking
          aria-labelledby="providers"
          className="view-all-link"
          data-testid="pcp-strip-header-view-all-link"
          to={{ pathname: '/internal-redirect', search: `?deepLink=${getResource(connectSaved, population)}` }}
        >
          {t('VIEW_ALL', { savedNum: savedNumTranslateValue })}
        </ViewAllLinkWithClickTracking>
      )}
      {isPcpReferralsEligible && (
        <AsideWithTrackDataUponRender
          className="provider-referrals"
          data-testid="pcp-strip-header-referrals-aside"
          trackLabel="referrals"
          trackData={{ numReferrals: numberOfSelectedUserReferrals }}
        >
          {numberOfSelectedUserReferrals > 0 && (
            <PcpReferralsLinkWithClickTracking data-testid="pcp-strip-header-referrals-link" to="/pcp-referrals">
              <span className="circle-num">{numberOfSelectedUserReferrals}</span>
              <span>{t(providerReferralsTranslationText)}</span>
            </PcpReferralsLinkWithClickTracking>
          )}
        </AsideWithTrackDataUponRender>
      )}
    </header>
  );
};
