import moment from 'moment';
import { ITimePeriod } from 'scripts/api/api.interfaces';
import { AnyClaimType, ClaimType, IAnyClaim, IClaim, IFinancialClaim } from 'scripts/api/claims/claims.interfaces';
import { ClaimsService } from 'scripts/api/claims/claims.service';
import { AccountType } from 'scripts/api/ledger/ledger.interfaces';
import { IDropdownOption, ISortDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import {
  FilterType,
  IAvailableFilterValue,
  IGenericFilter,
  ISelectedFilters,
  ISelectedSort,
  ISort,
} from 'scripts/ui/filter/filter.interfaces';
import { IFilterService } from 'scripts/ui/filter/filter.service';
import {
  isDateRangeValid,
  getSort,
  getHealthcareDefaultSortBy,
  getFinancialDefaultSortBy,
  getSelectedSortOption,
  getPeriod,
} from 'scripts/features/claims-and-accounts/claims/all-claims-utils';
import { getFinancialSortByOptions, getHealthcareSortByOptions, getSmartSortEligible } from './all-claims-utils';

export enum SortBy {
  AmountPaid = 'amountPaid',
  AmountPending = 'amountPending',
  AmountSubmitted = 'amountSubmitted',
  HealthPlanPays = 'healthPlanPays',
  PatientResponsibility = 'patientResponsibility',
  ProcessedDate = 'processedDate',
  ProviderName = 'providerName',
  ServiceDate = 'serviceDate',
  SmartSort = 'smartSort',
  TotalBilledAmount = 'totalBilledAmount',
  YouMayOweAmount = 'youMayOweAmount',
}

export class AllClaimsService {
  public static getDefaultSelectedFilters<U>(type: AnyClaimType, isSmartSortEligible?: boolean): ISelectedFilters<U> {
    return {
      sort: ClaimsService.getByType(type, getHealthcareDefaultSortBy(isSmartSortEligible), getFinancialDefaultSortBy()),
      values: {},
    };
  }

  public static getFilters(type: AnyClaimType): IGenericFilter<IClaim | IFinancialClaim, any>[] {
    return ClaimsService.getByType(type, () => this.getClaimFilters(), () => this.getFinancialClaimFilters());
  }

  public static getSelectedSortOption(options: ISortDropdownOption[], selectedSort: ISelectedSort): IDropdownOption {
    return getSelectedSortOption(options, selectedSort);
  }

  public static getSortByOptions(type: AnyClaimType, smartSortEligible?: boolean): ISortDropdownOption[] {
    const healthClaimTypes: AnyClaimType[] = [ClaimType.Medical, ClaimType.Dental, ClaimType.Rx];
    const financialClaimTypes: AnyClaimType[] = [
      AccountType.DCSA,
      AccountType.FSADC,
      AccountType.FSAHC,
      AccountType.FSALP,
      AccountType.HCSA,
      AccountType.HRA,
      AccountType.HRAAP,
      AccountType.HRAPD,
      AccountType.HRASD,
      AccountType.MRA,
    ];

    if (healthClaimTypes.indexOf(type) > -1) {
      return getHealthcareSortByOptions(smartSortEligible);
    } else if (financialClaimTypes.indexOf(type) > -1) {
      return getFinancialSortByOptions();
    }
  }

  public static getSort(selectedSort: ISelectedSort): ISort<IAnyClaim> {
    return getSort(selectedSort);
  }

  public static setIsMerp(claims: IAnyClaim[], type: AnyClaimType, showMerp: boolean): void {
    if (ClaimsService.getByType(type, true, false)) {
      const healthCareClaims = claims as IClaim[];
      healthCareClaims.forEach(claim => {
        if (claim.claimManagementInfo) {
          claim.claimManagementInfo.isMerp = claim.claimManagementInfo.isMerp && showMerp;
        }
      });
    }
  }

  public static getPeriod(filters: IGenericFilter<IClaim, any>[], selectedFilters: ISelectedFilters<any>): ITimePeriod {
    return getPeriod(filters, selectedFilters);
  }

  public static isDateRangeValid(startDate: moment.Moment, endDate: moment.Moment): boolean {
    return isDateRangeValid(startDate, endDate);
  }

  public static getSmartSortEligible(claims: IClaim[]): boolean {
    return getSmartSortEligible(claims);
  }

  private static getClaimFilters(): IGenericFilter<IClaim, any>[] {
    const filters: IGenericFilter<IClaim, any>[] = [];
    filters.push(
      ...[
        {
          name: 'keyword',
          title: 'KEYWORD',
          displayFunction: () => undefined,
          mapFunc: (filterData: IClaim) => [
            filterData.claimId,
            filterData.claimType === ClaimType.Rx ? 'PHARMACY' : filterData.claimType,
            filterData.providerName,
            filterData.serviceRecipient.firstName,
            filterData.serviceRecipient.lastName,
            filterData.serviceDate,
            filterData.lastServiceDate,
            filterData.processedDate,
          ],
          type: FilterType.Keyword,
        },
        {
          name: 'coverage-type',
          title: 'BY_COVERAGE_TYPE',
          displayFunction: (_, filterValue: ClaimType): string => {
            return filterValue === ClaimType.Rx ? 'PHARMACY' : filterValue;
          },
          mapFunc: (filterData: IClaim): ClaimType => {
            return filterData.claimType;
          },
          type: FilterType.Checkbox,
        },
        {
          name: 'member',
          title: 'BY_MEMBER',
          displayFunction: (filterData: IClaim): string => {
            return `${filterData.serviceRecipient.firstName} ${filterData.serviceRecipient.lastName}`;
          },
          mapFunc: (filterData: IClaim): string => {
            return filterData.serviceRecipient.dependentSeqNbr;
          },
          type: FilterType.Checkbox,
        },
        {
          name: 'year',
          title: 'BY_YEAR',
          labelSelected: 'SELECTED_YEAR',
          defaultValues: [moment().format('YYYY')],
          displayFunction: (filterData: IClaim): string => {
            return `${moment(filterData.serviceDate).format('YYYY')}`;
          },
          mapFunc: (filterData: IClaim): string => {
            return moment(filterData.serviceDate).format('YYYY');
          },
          filterOnStateFunc: (
            availableFilters: IAvailableFilterValue<string>,
            filterService: IFilterService,
          ): boolean => {
            const selectedFilters = filterService.getStateValue();

            if (
              selectedFilters &&
              selectedFilters.values &&
              Object.prototype.hasOwnProperty.call(selectedFilters.values, 'by-date-range')
            ) {
              const startDate = moment(selectedFilters.values['by-date-range'][0]).year();
              const endDate = moment(selectedFilters.values['by-date-range'][1]).year();
              const yearToBeChecked = moment(availableFilters.value, 'YYYY').year();
              return startDate <= yearToBeChecked && yearToBeChecked <= endDate;
            }
            return true;
          },
          sortFunc: (a: IAvailableFilterValue<string>, b: IAvailableFilterValue<string>): number =>
            a.value < b.value ? 1 : -1,

          type: FilterType.Dropdown,
        },
        {
          name: 'provider',
          title: 'BY_PROVIDER',
          labelSelected: 'SELECTED_PROVIDER',
          displayFunction: (filterData: IClaim): string => {
            return `${filterData.providerName}`;
          },
          mapFunc: (filterData: IClaim): string => {
            return filterData.providerHash;
          },
          type: FilterType.Dropdown,
        },
        {
          name: 'by-date-range',
          title: 'BY_DATE_RANGE',
          displayFunction: (_, filterValue: moment.Moment | string): string => {
            return (moment.isMoment(filterValue) ? filterValue : moment(filterValue)).format('MM/DD/YYYY');
          },
          mapFunc: (filterData: IClaim): moment.Moment | string => {
            return filterData.serviceDate;
          },
          isValid(): boolean {
            if (this.selectedValues && this.selectedValues.length === 2) {
              const [startDate, endDate]: moment.Moment[] = this.selectedValues.map(date =>
                moment.isMoment(date) ? date : moment(date),
              );
              return isDateRangeValid(startDate, endDate);
            }
            return true;
          },
          defaultValues: [
            moment()
              .startOf('date')
              .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M'),
            moment().startOf('date'),
          ],
          type: FilterType.DateRange,
        },
        {
          name: 'refine',
          title: 'REFINE',
          displayFunction: (_, filterValue: string): string => {
            const translationKeys = {
              claimNote: 'NOTED',
              isMerp: 'MERP_EXECUTIVE_CLAIM',
              isSaved: 'SAVED',
              markPaid: 'MARKED_AS_PAID',
              youOwe: 'YOU_MAY_OWE',
              notMarkedAsPaid: 'NOT_MARKED_AS_PAID',
            };
            return translationKeys[filterValue];
          },
          mapFunc: (filterData: IClaim): string[] => {
            const { claimManagementInfo } = filterData;
            claimManagementInfo.youOwe = this.getYouOweAmount(filterData) > 0 && !claimManagementInfo.markPaid;
            const result = Object.keys(claimManagementInfo).filter(key => claimManagementInfo[key]);
            if (!claimManagementInfo.markPaid && this.getYouOweAmount(filterData) !== 0) {
              result.push('notMarkedAsPaid');
            }
            return result;
          },
          type: FilterType.Checkbox,
        },
      ],
    );
    return filters;
  }

  private static getFinancialClaimFilters(): IGenericFilter<IFinancialClaim, any>[] {
    return [
      {
        name: 'by-date-range',
        title: 'BY_DATE_RANGE',
        displayFunction: (_, filterValue: moment.Moment | string): string => {
          return (moment.isMoment(filterValue) ? filterValue : moment(filterValue)).format('MM/DD/YYY');
        },
        mapFunc: (filterData: IFinancialClaim): moment.Moment | string => {
          return filterData.serviceDate;
        },
        defaultValues: [
          moment()
            .startOf('date')
            .subtract(ClaimsService.getClaimsSearchMonthsAgo(), 'M'),
          moment().startOf('date'),
        ],
        type: FilterType.DateRange,
      },
    ];
  }

  private static getYouOweAmount(claim: IClaim): number {
    return ClaimsService.getYouMayOweAmount(claim) || 0;
  }
}
