import React, { FunctionComponent, MouseEvent } from 'react';
import { LinkProps } from 'react-router-dom';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { goTo } from 'scripts/util/link/link';
import { Anchor } from '../anchor/anchor';

export interface ILinkProps extends LinkProps {
  noNewWindowIcon?: boolean;
}

export const Link: FunctionComponent<ILinkProps> = props => {
  const { onClick, replace, target, to, ...rest } = props;
  let href: string;
  if (typeof to === 'string') {
    href = to;
  } else if (to && to.pathname) {
    href = `${to.pathname}${to.search ? to.search : ''}`;
  }
  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    if (onClick) {
      onClick(event);
    }
    if (target !== LinkTarget.Blank) {
      event.preventDefault();
      goTo(to, replace);
    }
  };
  return <Anchor href={href} onClick={handleOnClick} target={target || LinkTarget.Self} {...rest} />;
};
