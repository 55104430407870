import { Subscription } from 'rxjs/Subscription';
import {
  oxfordAccessRequestForm,
  oxfordAccountingRequestForm,
  oxfordAmendmentRequestForm,
  oxfordConfidentialCommunicationRequestForm,
  oxfordMemberAuthorizationForm,
  oxfordOptumRxAuthorizationForm,
  oxfordRestrictedRequestForm,
} from 'scripts/util/resource/resource.constants';
import submitClaimHipaaFormsTemplate from 'views/claims-and-accounts/submit/submit-claim-hipaa-forms.html';
import { ILink, LinkTarget } from '../../../../api/api.interfaces';
import { MembershipCategory } from '../../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../../api/profile/profile.service';
import { IUserService } from '../../../../api/user/user.service';
import { Dictionary } from '../../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../../util/locale/locale.service';
import { IResourceService } from '../../../../util/resource/resource.service';

export interface ISubmitClaimHipaaFormsLink extends ILink {
  description?: string;
  getHref?: () => string;
  show?: boolean;
}

export class SubmitClaimHipaaFormsController implements ng.IComponentController {
  public links: ISubmitClaimHipaaFormsLink[];
  public showSection: boolean;

  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.SUBMIT_CLAIM);

    this.links = [
      {
        description: 'ACCESS_REQUEST_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordAccessRequestForm),
        target: LinkTarget.Blank,
        text: 'ACCESS_REQUEST_FORM',
      },
      {
        description: 'ACCOUNTING_REQUEST_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordAccountingRequestForm),
        target: LinkTarget.Blank,
        text: 'ACCOUNTING_REQUEST_FORM',
      },
      {
        description: 'AMENDMENT_REQUEST_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordAmendmentRequestForm),
        target: LinkTarget.Blank,
        text: 'AMENDMENT_REQUEST_FORM',
      },
      {
        description: 'CONFIDENTIAL_COMMUNICATION_REQUEST_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordConfidentialCommunicationRequestForm),
        target: LinkTarget.Blank,
        text: 'CONFIDENTIAL_COMMUNICATION_REQUEST_FORM',
      },
      {
        description: 'MEMBER_AUTHORIZATION_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordMemberAuthorizationForm),
        target: LinkTarget.Blank,
        text: 'MEMBER_AUTHORIZATION_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordOptumRxAuthorizationForm),
        target: LinkTarget.Blank,
        text: 'OPTUMRX_AUTHORIZATION_FORM',
      },
      {
        description: 'RESTRICTED_REQUEST_FORM_DESCRIPTION',
        getHref: () => this.resourceService.get(oxfordRestrictedRequestForm),
        target: LinkTarget.Blank,
        text: 'RESTRICTED_REQUEST_FORM',
      },
    ];
  }

  public $onInit(): void {
    this.setLinksHref();

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .subscribe(
        currentUser => (this.showSection = currentUser.membershipCategory === MembershipCategory.OXFORD),
        console.warn,
      );

    this.localeSubscription = this.localeService.localeChanged.subscribe(() => this.setLinksHref(), console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  private setLinksHref(): void {
    this.links.filter(link => link.getHref).forEach(link => (link.href = link.getHref()));
  }
}

export class SubmitClaimHipaaFormsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SubmitClaimHipaaFormsController;
    this.templateUrl = submitClaimHipaaFormsTemplate;
  }
}
