import React, { FunctionComponent } from 'react';
import { ProgressBar } from './progress-bar';
import { ProgressZeroMax } from './progress-zero-max';

interface IProgressProps {
  amount: number;
  max: number;
}

export const Progress: FunctionComponent<IProgressProps> = props => {
  const { amount, max } = props;
  return (
    <div className="progress" data-testid="progress">
      <ProgressBar amount={amount} max={max} />
      <ProgressZeroMax max={max} />
    </div>
  );
};
