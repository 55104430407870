import idx from 'idx';
import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ILocaleState } from 'scripts/reducers/locale-reducer';

const selectLocale = (state: IReduxState): ILocaleState => state.locale;

export const selectCountry = createSelector(
  selectLocale,
  locale => idx(locale, _ => _.country),
);

export const selectLanguage = createSelector(
  selectLocale,
  locale => idx(locale, _ => _.language),
);

export const selectId = createSelector(
  selectLocale,
  locale => idx(locale, _ => _.id),
);
