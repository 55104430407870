import { getBaseUrls, parse, userUris } from 'scripts/util/uri/uri';
import { LineOfBusiness, MembershipCategory } from '../../api/profile/profile.interfaces';
import { IUserService } from '../../api/user/user.service';
import { ArcadeAuthType, IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { SessionLocaleKey } from '../../util/constants/i18n.constants';
import { LocaleLanguage } from '../../util/locale/locale.interfaces';
import { IResourceService } from '../../util/resource/resource.service';
import { ILoginController } from './login.interfaces';

export class LoginController implements ILoginController {
  private pfAdapterId: string;
  private clientId: string;
  private oidcRedirect: string;
  private fullAuthUrl: string;

  constructor(
    private $location: ng.ILocationService,
    private $sce: ng.ISCEService,
    private $state: angular.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private Environment: IEnvironmentConstants,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    if (Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Optum) {
      this.useOptumAuthFlow();
    } else if (Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Rally) {
      this.useRallyIdpAuthFlow();
    } else if (Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH === ArcadeAuthType.Arachne) {
      this.useArachneIdpAuthFlow();
    }
  }

  public useOptumAuthFlow(): void {
    this.setPfAndClientIds();

    this.userService.prelogin(this.getSafeRedirectUrl(this.$stateParams.redirect)).subscribe(rsp => {
      if (!this.fullAuthUrl) {
        const authUrl = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_URL;
        const authPath = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PATH;
        this.fullAuthUrl = authUrl + authPath;
      }

      const form = document.createElement('form');
      const inputClient = document.createElement('input');
      const inputScope = document.createElement('input');
      const inputResponse = document.createElement('input');
      const inputState = document.createElement('input');
      const inputRedirect = document.createElement('input');
      const inputPfid = document.createElement('input');

      form.method = 'POST';
      form.action = this.$sce.trustAsResourceUrl(this.fullAuthUrl);

      inputClient.name = 'client_id';
      inputScope.name = 'scope';
      inputResponse.name = 'response_type';
      inputState.name = 'state';
      inputRedirect.name = 'redirect_uri';
      inputPfid.name = 'pfidpadapterid';

      inputClient.type = 'hidden';
      inputScope.type = 'hidden';
      inputResponse.type = 'hidden';
      inputState.type = 'hidden';
      inputRedirect.type = 'hidden';
      inputPfid.type = 'hidden';

      inputClient.value = this.clientId;
      inputScope.value = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_SCOPE;
      inputResponse.value = 'code';
      inputState.value = rsp.data.oidcStateCode;
      inputRedirect.value = this.oidcRedirect;
      inputPfid.value = this.pfAdapterId;

      form
        .appendChild(inputClient)
        .appendChild(inputScope)
        .appendChild(inputResponse)
        .appendChild(inputState)
        .appendChild(inputRedirect)
        .appendChild(inputPfid);

      document.body.appendChild(form);
      form.submit();
    }, console.warn);
  }

  public useRallyIdpAuthFlow(): void {
    const callbackUrl = this.getSafeRedirectUrl(this.$stateParams.redirect);
    const redirectUrl = parse(
      userUris.idp(this.Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH.toString().toLowerCase(), {
        product: 'arcade',
        deeplink: callbackUrl,
      }),
    ).href;

    // Encode url param to prevent first redirect stripping redirect param used by session endpoint
    const sessionCreationEndpoint = encodeURIComponent(redirectUrl);
    const url = `${this.Environment.CONFIG.ARCADE_WEB_RALLY_AUTH_URL}/?redirect=${sessionCreationEndpoint}`;

    this.$window.location.href = url;
  }

  public useArachneIdpAuthFlow(): void {
    const callbackUrl = this.getSafeRedirectUrl(this.$stateParams.redirect);
    const sessionCreationEndpoint = parse(
      userUris.idp(this.Environment.CONFIG.ARCADE_WEB_DEFAULT_AUTH.toString().toLowerCase(), {
        product: 'arcade',
        deeplink: callbackUrl,
      }),
    ).href;

    this.$window.location.href = sessionCreationEndpoint;
  }

  public getSafeRedirectUrl(href: string): string {
    const uri = parse(href);
    const url = uri.href;
    const dashboardUrn = this.$state.href('authenticated.dashboard');
    const dashboardUrl = parse(dashboardUrn).href;
    const locationHost = parse(this.$location.absUrl()).host;
    const apiHost = parse(getBaseUrls().api).host;

    return href &&
      uri.pathname !== '/login' &&
      uri.pathname !== '/internal-error' &&
      uri.pathname !== '/unauthorized-error' &&
      uri.pathname.indexOf('/modal/inactive') === -1 &&
      (uri.host === locationHost || uri.host === apiHost)
      ? url
      : dashboardUrl;
  }

  private setPfAndClientIds(): void {
    switch (this.$stateParams.lob) {
      // Handle M&R LoB which can have multiple sub clientIds and login portals based on membershipCategory
      case LineOfBusiness.MR:
        this.clientId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_MEDICARE_AUTH_CLIENT_ID;

        switch (this.$stateParams.membershipCategory) {
          case MembershipCategory.AARP:
            this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AARP_AUTH_PFID;
            this.oidcRedirect = parse(userUris.idp('aarp')).href;
            break;
          case MembershipCategory.MEDICA:
            this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_MEDICA_AUTH_PFID;
            this.oidcRedirect = parse(userUris.idp('medica')).href;
            break;
          case MembershipCategory.PCP:
            this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_PCP_AUTH_PFID;
            this.oidcRedirect = parse(userUris.idp('pcp')).href;
            break;
          case MembershipCategory.RETIREE:
            this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_RETIREE_AUTH_PFID;
            this.oidcRedirect = parse(userUris.idp('retiree')).href;
            break;
          default:
            this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_MEDICARE_AUTH_PFID;
            this.oidcRedirect = parse(userUris.idp('medicare')).href;
        }
        break;
      // Handle E&I LoB which always redirects to MyUHC for login
      // Default to MyUHC / E&I (for now) if we don't have more information about the member or how they got here
      case LineOfBusiness.EI:
      default:
        this.clientId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_CLIENT_ID;
        this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID;
        this.oidcRedirect = parse(userUris.idp('myuhc')).href;
        break;
    }

    let sessionLocale;
    // CS login flow will only have locale passed as a param, the only variables changing from the default are PFID and auth URL
    // Due to the session management on the optum side, we need to account for two different type of "locales". The one attached
    // to the session vs the one selected by the user. We use the one attached to the session for login, defaulting to the normal
    // one if it isn't found
    if (this.$stateParams.loginLocale) {
      // this.$stateParams.locale will come in as es-US, tag-US, etc. matching up to the - for the end result of es, tag, etc.
      sessionLocale = this.$stateParams.loginLocale.match(/^\w*/)[0].toLowerCase();
    } else if (this.$stateParams.locale) {
      sessionLocale = this.$stateParams.locale.match(/^\w*/)[0].toLowerCase();
    }
    // We need to store the session locale, because when returning to legacy pages, we have to use this original despite any language
    // changes that occurred within our Rally pages
    localStorage.setItem(SessionLocaleKey, sessionLocale);

    if (sessionLocale) {
      switch (sessionLocale) {
        case LocaleLanguage.Spanish:
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + LocaleLanguage.Spanish;
          break;
        case LocaleLanguage.Ilocano:
        case 'ilo':
          // the enum for Ilocano is 'il' (per ticket ARC-5151), the value listed in ARC-5642 is 'ilo'
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + 'ilo';
          break;
        case LocaleLanguage.ChineseTraditional:
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + LocaleLanguage.ChineseTraditional;
          break;
        case LocaleLanguage.Vietnamese:
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + LocaleLanguage.Vietnamese;
          break;
        case LocaleLanguage.Korean:
        case 'kr':
          // the enum for Korean is 'ko' (per ticket ARC-5151), the value listed in ARC-5642 is 'kr'
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + 'kr';
          break;
        case LocaleLanguage.HaitianCreole:
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + LocaleLanguage.HaitianCreole;
          break;
        case LocaleLanguage.Tagalog:
        case 'tag':
          // the enum for Tagalog is 'tl' (per ticket ARC-5151), the value listed in ARC-5642 is 'tag'
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID + 'tag';
          break;
        case LocaleLanguage.English:
        default:
          this.pfAdapterId = this.Environment.CONFIG.ARCADE_WEB_OPTUM_AUTH_PFID;
          break;
      }
    }
  }
}
