import React, { FunctionComponent, useEffect, useState } from 'react';
import { getRedirectLandingPage, shouldRedirect } from '@rally/rallypay';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { Link } from 'scripts/ui/link/link';
import CONFIG from 'scripts/util/constants/config';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { constructParams } from 'scripts/util/uri/uri';

const TrackedLink = withClickTracking(Link, 'pay-now');

interface IClaimPayNowAnchorModalProps {
  claimId: string;
  claimPayKey: string;
  payNowMsg: string;
}

export const ClaimPayNowAnchorModal: FunctionComponent<IClaimPayNowAnchorModalProps> = props => {
  const { claimId, claimPayKey, payNowMsg } = props;
  const {
    location: { search, pathname },
  } = useRouter();
  const [payNowFrom, setPayNowFrom] = useState('');
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete('payNowFrom');
    const searchString = urlSearchParams.toString();
    setPayNowFrom(searchString ? `${pathname}?${searchString}` : pathname);
  }, []);

  const params = { claimId, claimPayKey, payNowFrom };
  const to = { pathname: '/modal/pay-now', search: `${constructParams(params)}` };
  const isPayNowModalRedirectOn = FeatureFlagService.isPayNowModalRedirectOn();
  if (isPayNowModalRedirectOn && shouldRedirect) {
    const redirectUrl = `${window.location.origin}${window.location.pathname}${to.pathname}${to.search}`;
    const redirectLandingPage = getRedirectLandingPage(CONFIG.ARCADE_WEB_RALLY_PAY_BASE_API_URL, redirectUrl);

    return (
      <a className="secondary-btn claim-pay-now" data-testid="claim-pay-now-anchor-modal" href={redirectLandingPage}>
        {payNowMsg}
      </a>
    );
  }
  return (
    <TrackedLink className="secondary-btn claim-pay-now" data-testid="claim-pay-now-anchor-modal" to={to}>
      {payNowMsg}
    </TrackedLink>
  );
};
