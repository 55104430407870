import React, { FunctionComponent } from 'react';
import { Progress } from './progress';
import { ProgressLabel } from './progress-label';

interface IProgressContainerProps {
  amount: number;
  max: number;
}

export const ProgressContainer: FunctionComponent<IProgressContainerProps> = props => {
  const { amount, max } = props;
  return (
    <div className="progress-container" data-testid="progress-container">
      <ProgressLabel amount={amount} max={max} />
      <Progress amount={amount} max={max} />
    </div>
  );
};
