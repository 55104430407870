import { ArcadeAction } from 'scripts/reducers/reducer.interfaces';
import { AppActionTypes } from 'scripts/actions/app-actions';

export interface IAppState {
  modalOpen: boolean;
}

export const initialState: IAppState = {
  modalOpen: undefined,
};

export default (state: IAppState = initialState, action: ArcadeAction): IAppState => {
  switch (action.type) {
    case AppActionTypes.CHANGE_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      };
    default:
      return state;
  }
};
