import React, { FunctionComponent, useEffect, HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import {
  currentUser,
  selectDependents,
  selectProductsData,
  selectPrimaryCareData,
} from 'scripts/selectors/profile-service-selectors';
import { getBenefits, getIdCards } from 'scripts/thunks/plans-service-thunks';
import { getProducts, getPrimaryCare } from 'scripts/thunks/profile-service-thunks';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { IdCardCoverageDateHeader } from './id-card-coverage-date-header';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import {
  IProfileUser,
  LineOfBusiness,
  FundingType,
  IProducts,
  IPlanCoverage,
  IPrimaryCarePerMember,
  IPrimaryCarePhysician,
} from 'scripts/api/profile/profile.interfaces';
import { ICoverageSection, IPlanTypes } from './id-cards.interfaces';
import { ProfileService } from 'scripts/api/profile/profile.service';
import { IPlanBenefits, IIdCard } from 'scripts/api/plans/plans.interfaces';
import { CoverageType, CoverageStatus } from 'scripts/api/api.interfaces';
import {
  selectBenefitsData,
  selectIdCardsData,
  selectBenefitsError,
  selectBenefitsLoading,
  selectIdCardsError,
  selectIdCardsLoading,
} from 'scripts/selectors/plans-service-selectors';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import {
  getAllCoveragesFromUsers,
  getMedicalAndNonMedicalCoverages,
  getOtherCoverageTypesForCs,
  getGroupNumber,
  getUsersWithCoverage,
  hasTermedCoverage,
} from 'scripts/util/plans/plans';
import { IdCardRxCarveOutCoverage } from './id-card-rx-carve-out-coverage';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IdCardTypicalCoverage } from './id-card-typical-coverage';
import CONFIG from 'scripts/util/constants/config';
import { getFullName } from 'scripts/util/profile/profile';

interface IIdCardProps extends IIdCardStateToProps, IIdCardDispatchToProps {}

interface IIdCardStateToProps {
  currentProfile: IProfileUser;
  dependents: IProfileUser[];
  benefitsError: boolean;
  benefitsLoading: boolean;
  idCardsError: boolean;
  idCardsLoading: boolean;
  products: IProducts;
  planBenefits: IPlanBenefits;
  idCards: IIdCard[][];
  population?: IPopulation;
  primaryCarePerMember?: IPrimaryCarePerMember;
}

interface IIdCardDispatchToProps {
  getBenefits: () => void;
  getIdCards: () => void;
  getProducts: () => void;
  getPrimaryCare: () => void;
}

const Div: FunctionComponent<HTMLAttributes<HTMLElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

export const RawIdCard: FunctionComponent<IIdCardProps> = props => {
  const {
    currentProfile,
    dependents,
    products,
    benefitsError,
    benefitsLoading,
    planBenefits,
    idCards,
    idCardsError,
    idCardsLoading,
    population,
    primaryCarePerMember,
  } = props;
  const users = [currentProfile].concat(dependents);
  const coverages = [];
  /* get all coverages from each user */
  let planCoverages: IPlanCoverage[] = getAllCoveragesFromUsers(currentProfile, users);
  let otherCoverageTypesForCs: CoverageType[];
  const isCS = currentProfile.lineOfBusiness === LineOfBusiness.CS;
  let csMedicalSupportPhoneNum: string;
  const ssoTrackingLink = CONFIG.ARCADE_WEB_RALLY_AUTH_URL + '/sso/v1/vendor/MYUHCIDCARD/completed';
  const isGatedPlan = currentProfile.memberFeatures.pcpEligible;
  let activePcpPerMember: { [depSeqNum: string]: string };
  const error = benefitsError;
  const loading = benefitsLoading;

  useEffect(() => {
    if (!isCS && !planBenefits) {
      props.getBenefits();
    }
    if (!products) {
      props.getProducts();
    }
    if (!idCards) {
      props.getIdCards();
    }
    if (isGatedPlan && !primaryCarePerMember) {
      props.getPrimaryCare();
    }
  }, []);

  const handleRetry = (): void => {
    props.getBenefits();
  };

  const getActivePcp = (pcpList: IPrimaryCarePhysician[]): string => {
    for (const pcp of pcpList) {
      if (pcp.status === CoverageStatus.Active) {
        return getFullName(pcp);
      }
    }
  };

  if (isGatedPlan && primaryCarePerMember) {
    const activePcpMap = {};
    for (const depSeqNum in primaryCarePerMember) {
      if (primaryCarePerMember[depSeqNum]) {
        activePcpMap[depSeqNum] = getActivePcp(primaryCarePerMember[depSeqNum].primaryCarePhysicians);
      }
    }
    activePcpPerMember = activePcpMap;
  }

  if (isCS) {
    if (
      products &&
      products.medicalSupport &&
      products.medicalSupport.contactInformation &&
      products.medicalSupport.contactInformation.phoneNum
    ) {
      csMedicalSupportPhoneNum = products.medicalSupport.contactInformation.phoneNum;
    }
    const [medicalCoverages, nonMedicalCoverages] = getMedicalAndNonMedicalCoverages(planCoverages);
    if (medicalCoverages.length > 0) {
      otherCoverageTypesForCs = getOtherCoverageTypesForCs(medicalCoverages, nonMedicalCoverages, products);
    }
    planCoverages = medicalCoverages;
  }

  // Get id card for each coverage and set coverages array
  for (let j = 0; j < planCoverages.length; j++) {
    const coverage = planCoverages[j] as ICoverageSection;
    coverages[j] = coverage;
    coverages[j].planType = IPlanTypes[planCoverages[j].coverageType];
    coverages[j].template = ProfileService.isRxCarveOut(planCoverages[j])
      ? 'rx-carve-out-coverage'
      : 'typical-coverage';

    if (planBenefits) {
      // Use medical plan name for vision as well
      const planNameType =
        coverages[j].coverageType === CoverageType.Vision ? CoverageType.Medical : coverages[j].coverageType;
      planBenefits.benefits
        .filter(benefit => benefit.coverageType === planNameType)
        .forEach(benefit => (coverages[j].planName = benefit.planName));
    }

    if (coverages[j].planFeatures.hasIdCard && coverages[j].planFeatures.isIndividualIdCard) {
      // select first user with coverage
      for (const user of getUsersWithCoverage(coverages[j], users)) {
        if (!hasTermedCoverage(coverages[j], user)) {
          coverage.selectedDepSeqNum = user.dependentSeqNum;
          break;
        }
      }
    }

    coverages[j].flipped = false;
    coverages[j].showGroupNumber = !!getGroupNumber(coverages[j], currentProfile);
    if (currentProfile.lineOfBusiness === LineOfBusiness.MR) {
      coverages[j].showGroupNumber =
        coverages[j].showGroupNumber && coverages[j].planFeatures.fundingArrangementType !== FundingType.Individual;
    }
  }

  return (
    <>
      <IdCardCoverageDateHeader />
      <DivWithWaitFor
        error={error}
        loading={loading}
        onRetry={handleRetry}
        trackLabel="coverages"
        trackData={{ numCoverages: coverages.length }}
        data-testid={'react-id-card'}
      >
        {coverages &&
          coverages.map(coverage =>
            coverage.template === 'typical-coverage' ? (
              <section
                key={'typicalCoverage' + coverage.planKey + coverage.coverageTypeCode + coverage.planType}
                aria-labelledby={'header-' + coverage.planKey}
                aria-describedby={'details-' + coverage.planKey}
              >
                <IdCardTypicalCoverage
                  currentProfile={currentProfile}
                  coverage={coverage}
                  users={users}
                  population={population}
                  isCS={isCS}
                  otherCoverageTypesForCs={otherCoverageTypesForCs}
                  idCards={idCards}
                  csMedicalSupportPhoneNum={csMedicalSupportPhoneNum}
                  idCardsError={idCardsError}
                  idCardsLoading={idCardsLoading}
                  isGatedPlan={isGatedPlan}
                  activePcpPerMember={activePcpPerMember}
                />
              </section>
            ) : (
              coverage.template === 'rx-carve-out-coverage' && (
                <section
                  key={'rxcarveOutCoverage' + coverage.planKey + coverage.planType}
                  aria-labelledby={'header-' + coverage.planKey}
                  aria-describedby={'details-' + coverage.planKey}
                >
                  <IdCardRxCarveOutCoverage
                    coverage={coverage}
                    population={population}
                    users={users}
                    data-testid={'rx-carve-out-template'}
                  />
                </section>
              )
            ),
          )}
      </DivWithWaitFor>
      <img src={ssoTrackingLink} className="tracking-images" />
    </>
  );
};

const mapStateToProps = (state: IReduxState): IIdCardStateToProps => {
  return {
    currentProfile: currentUser.selectProfile(state),
    dependents: selectDependents(state),
    planBenefits: selectBenefitsData(state),
    benefitsError: selectBenefitsError(state),
    benefitsLoading: selectBenefitsLoading(state),
    idCardsError: selectIdCardsError(state),
    idCardsLoading: selectIdCardsLoading(state),
    products: selectProductsData(state),
    idCards: selectIdCardsData(state),
    population: state.population,
    primaryCarePerMember: selectPrimaryCareData(state),
  };
};

export const IdCard = withProvider(
  connect(
    mapStateToProps,
    {
      getBenefits,
      getProducts,
      getIdCards,
      getPrimaryCare,
    },
  )(RawIdCard),
);
