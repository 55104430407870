import { sendAAPageData } from 'scripts/util/tracking/adobe-analytics';
import spendingAndCostSummaryTemplate from 'views/claims-and-accounts/spending-and-cost-summary/spending-and-cost-summary.html';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { showMRMedicalSpendingCostSummarySection, showMRRxSpendingCostSummary } from '../../../util/profile/profile';

export class SpendingAndCostSummaryController {
  public showMRRxSpendingCostSummary: boolean;
  public showMRMedicalSpendingCostSummary: boolean;

  constructor(private profileService: IProfileService, private userService: IUserService) {
    'ngInject';
  }

  public $onInit(): void {
    sendAAPageData('trackPages', { pageName: 'spending and cost summary', siteSectionL1: 'claims' });

    const currentUser$ = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser);

    currentUser$.subscribe(currentUser => {
      this.showMRRxSpendingCostSummary = showMRRxSpendingCostSummary(currentUser);
      this.showMRMedicalSpendingCostSummary = showMRMedicalSpendingCostSummarySection(currentUser);
    });
  }
}

export class SpendingAndCostSummaryComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SpendingAndCostSummaryController;
    this.templateUrl = spendingAndCostSummaryTemplate;
  }
}
