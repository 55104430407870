import React, { FunctionComponent } from 'react';

interface INoAccumulatorProps {
  noAccumMessage: string;
}

export const NoAccumulator: FunctionComponent<INoAccumulatorProps> = props => {
  const { noAccumMessage } = props;
  return (
    <div className="no-accumulator-container" data-testid="no-accumulator-container">
      <div className="no-accumulator-message" data-testid="no-accumulator-message">
        <span> {noAccumMessage} </span>
      </div>
    </div>
  );
};
