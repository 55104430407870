import jQuery from 'jquery';

export class BindHtmlCompileDirective implements ng.IDirective {
  public restrict = 'A';

  constructor(private $compile: ng.ICompileService, private $sce: ng.ISCEService) {}
  public link = (scope, element, attrs): void => {
    scope.$watch(this.$sce.parseAsHtml(attrs.bindHtmlCompile), value => {
      element.html(value || '');

      // add the track directive to any anchors, use the anchor text as the track value
      if (Object.prototype.hasOwnProperty.call(attrs, 'addTrack')) {
        element.find('a').each((index, ele) => {
          const trackVal = jQuery(ele).text();
          if (trackVal && trackVal.length > 0) {
            ele.setAttribute('track', trackVal);
          }
        });
      }

      this.$compile(element.contents())(scope);
    });
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($compile, $sce) => {
      'ngInject';
      return new BindHtmlCompileDirective($compile, $sce);
    };
    return directive;
  }
}
