/* eslint-disable max-classes-per-file */
import melodeonContentTemplate from 'views/ui/melodeon-content.html';
import melodeonToggleAllTemplate from 'views/ui/melodeon-toggle-all.html';
import melodeonToggleTemplate from 'views/ui/melodeon-toggle.html';
import melodeonTemplate from 'views/ui/melodeon.html';
import { MelodeonController } from './melodeon.controller';
import { IMelodeonController } from './melodeon.interfaces';

export class MelodeonDirective implements ng.IDirective {
  public restrict = 'E';
  public replace = true;
  public transclude = true;
  public templateUrl = melodeonTemplate;
  public controller = MelodeonController;
  public controllerAs = '$melodeon';
  public scope = {};
  public bindToController = {
    animateTime: '<',
    expandOnInit: '=?',
    pairs: '=?',
  };

  private numInstances = 0;
  public link = (scope, element, attrs, $melodeon: IMelodeonController): void => {
    $melodeon.groupId = `melodeon-${this.numInstances}`;
    this.numInstances++;
  };

  public static Factory(): ng.IDirectiveFactory {
    return () => new MelodeonDirective();
  }
}

export class MelodeonToggleDirective implements ng.IDirective {
  public restrict = 'EA';
  public require = '^melodeon';
  public replace = true;
  public transclude = true;
  public scope = {
    $expanded: '=?',
  };
  public templateUrl = melodeonToggleTemplate;
  public link = (scope, element, attrs, $melodeon: IMelodeonController): void => {
    const index = (scope.$index = $melodeon.addToggle(scope, element));
    scope.$groupId = $melodeon.groupId;

    element
      .on('click', e => {
        let target = e.target;
        // determine if the user clicked on a link inside the melodeon toggle element
        while (target && target !== e.currentTarget && target.tagName !== 'A') {
          target = target.parentElement;
        }
        // if the user did not click on a link inside the toggle, then toggle this melodeon
        if (!target || target === e.currentTarget) {
          $melodeon.toggle(index);
        }
      })
      .on('keydown', e => {
        switch (e.keyCode) {
          case 13: // return
          case 32: // space
            e.target.click();
            e.preventDefault();
            break;
          case 40: // down arrow
            $melodeon.focusToggle(index + 1);
            e.preventDefault();
            break;
          case 38: // up arrow
            $melodeon.focusToggle(index - 1);
            e.preventDefault();
            break;
          case 35: // end
            $melodeon.focusToggle($melodeon.pairs.length - 1);
            e.preventDefault();
            break;
          case 36: // home
            $melodeon.focusToggle(0);
            e.preventDefault();
            break;
        }
      });

    scope.$on('$melodeonExpanded', (e, pair) => {
      if ($melodeon.pairs.indexOf(pair) === index) {
        scope.$expanded = pair.$expanded;
      }
    });

    scope.$on('$destroy', () => {
      $melodeon.removeToggle(index, element);
    });
  };

  public static Factory(): ng.IDirectiveFactory {
    return () => new MelodeonToggleDirective();
  }
}

export class MelodeonContentDirective implements ng.IDirective {
  public restrict = 'E';
  public require = '^melodeon';
  public replace = true;
  public transclude = true;
  public scope = {};
  public templateUrl = melodeonContentTemplate;

  constructor(private $timeout: ng.ITimeoutService) {}
  public link = (scope, element, attrs, $melodeon: IMelodeonController): void => {
    const index = (scope.$index = $melodeon.addContent(scope, element));
    scope.$groupId = $melodeon.groupId;

    element.css('padding-top', 0).css('padding-bottom', 0);

    scope.$on('$melodeonExpanded', (e, pair) => {
      if ($melodeon.pairs.indexOf(pair) === index) {
        const animateTime = $melodeon.animateTime;
        const $expanded = (scope.$expanded = pair.$expanded);
        this.$timeout(() => {
          if ($expanded) {
            element
              .addClass('accordion-expanded')
              .attr('aria-hidden', false)
              .css('display', 'block')
              .css('transition', 'height ' + animateTime + 'ms, padding ' + animateTime + 'ms');
            const expandedHeight = element[0].scrollHeight + 'px';
            element.css('height', expandedHeight);
          } else {
            element
              .removeClass('accordion-expanded')
              .attr('aria-hidden', true)
              .css('height', 0);
            this.$timeout(() => {
              element.css('display', 'none');
            }, animateTime);
          }
        });
      }
    });

    this.$timeout(() => {
      $melodeon.pairs[index].$expanded = (index === 0 && $melodeon.pairs.length === 1) || $melodeon.expandOnInit;
      $melodeon.$scope.$broadcast('$melodeonExpanded', $melodeon.pairs[index]);
    });

    scope.$on('$destroy', () => {
      $melodeon.removeContent(index, element);
    });
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive = ($timeout: ng.ITimeoutService): MelodeonContentDirective => {
      'ngInject';
      return new MelodeonContentDirective($timeout);
    };
    return directive;
  }
}

export class MelodeonToggleAllDirective implements ng.IDirective {
  public restrict = 'E';
  public require = '^melodeon';
  public replace = true;
  public scope = {
    expandText: '@',
    collapseText: '@',
  };
  public templateUrl = melodeonToggleAllTemplate;
  public link = (scope, element, attrs, $melodeon: MelodeonContentDirective): void => {
    scope.$melodeon = $melodeon;
  };

  public static Factory(): ng.IDirectiveFactory {
    return () => new MelodeonToggleAllDirective();
  }
}
