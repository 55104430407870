import { MembershipCategory } from '../../api/profile/profile.interfaces';
import { IContentOverrideConditions, IContentOverrideRules } from './content-override.interfaces';

export const contentOverrideRules: IContentOverrideRules = {
  EXPATRIATE(input: string, result: string): string {
    const rule = {
      IN_NETWORK: 'US_IN_NETWORK_SERVICES',
      IN_NETWORK_ACCOUNTS: 'US_IN_NETWORK_SERVICES_ACCOUNTS',
      OUT_OF_NETWORK: 'US_OUT_OF_NETWORK_SERVICES',
      OUT_OF_NETWORK_ACCOUNTS: 'US_OUT_OF_NETWORK_SERVICES_ACCOUNTS',
      TIER_ONE: 'INTERNATIONAL_SERVICES',
      TIER_ONE_ACCOUNTS: 'INTERNATIONAL_SERVICES_ACCOUNTS',
    };

    return result ? rule[input] : input;
  },

  CUSTOM_GLOSSARY(input: string, result: string): string {
    return result[input] || input;
  },
};

export const contentOverrideConditions: IContentOverrideConditions = {
  EXPATRIATE([membershipCategory]: MembershipCategory[]): boolean {
    return membershipCategory === MembershipCategory.EXPATRIATE;
  },

  CUSTOM_GLOSSARY([glossary]: { [glossary: string]: { header: string; body: string } }[]): object {
    const rule = {
      AMOUNT_BILLED: glossary.claimAmountBilled && glossary.claimAmountBilled.header,
      AMOUNT_BILLED_DESCRIPTION: glossary.claimAmountBilled && glossary.claimAmountBilled.body,
      PLAN_PAID: glossary.claimPlanPaid && glossary.claimPlanPaid.header,
      PLAN_PAID_DESCRIPTION: glossary.claimPlanPaid && glossary.claimPlanPaid.body,
      YOU_MAY_OWE: glossary.claimYouMayOwe && glossary.claimYouMayOwe.header,
      YOU_MAY_OWE_DESCRIPTION: glossary.claimYouMayOwe && glossary.claimYouMayOwe.body,
      YOU_PAID: glossary.claimYouPaid && glossary.claimYouPaid.header,
      YOU_PAID_DESCRIPTION: glossary.claimYouPaid && glossary.claimYouPaid.body,
    };

    return rule;
  },
};
