import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import {
  empireInsideNYMedicalPdfForm,
  empireOutsideNYMedicalPdfForm,
  empireSurpriseBillAssignmentPdfForm,
} from 'scripts/util/resource/resource.constants';
import { getResource } from 'scripts/util/resource/resource';
import IconMedical from 'images/icons/icon-medical.svg';
import IconClaims from 'images/icons/icon-claims.svg';
import SubmitClaimLink from './submit-claim-link';
import { ISubmitClaimLinkProps, ISubmitClaimLinksProps } from './submit-claim-links.interface';

export const EmpireInsideNYMedicalPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  population,
  t,
}) => (
  <SubmitClaimLink
    href={getResource(empireInsideNYMedicalPdfForm, population)}
    Icon={IconMedical}
    text={t('EMPIRE_MEDICAL_CLAIM_FORM')}
    title={t('EMPIRE_INSIDE_NY')}
  />
);

export const EmpireOutsideNYMedicalPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  population,
  t,
}) => (
  <SubmitClaimLink
    href={getResource(empireOutsideNYMedicalPdfForm, population)}
    Icon={IconMedical}
    text={t('EMPIRE_MEDICAL_CLAIM_FORM')}
    title={t('EMPIRE_OUTSIDE_NY')}
  />
);

export const EmpireSurpriseBillAssignmentPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  population,
  t,
}) => (
  <SubmitClaimLink
    href={getResource(empireSurpriseBillAssignmentPdfForm, population)}
    Icon={IconClaims}
    text={t('EMPIRE_ASSIGNMENT_FORM')}
    title={t('EMPIRE_SURPRISE_BILL')}
  />
);

const EmpireSubmitClaimLinks: FunctionComponent<ISubmitClaimLinksProps> = props => {
  const { t } = useTranslation(Dictionary.COMMON);
  const linkProps = { ...props, t };
  return (
    <>
      <EmpireInsideNYMedicalPdfSubmitClaimLink {...linkProps} />
      <EmpireOutsideNYMedicalPdfSubmitClaimLink {...linkProps} />
      <EmpireSurpriseBillAssignmentPdfSubmitClaimLink {...linkProps} />
    </>
  );
};

export default EmpireSubmitClaimLinks;
