import React, { FunctionComponent, HTMLAttributes, useEffect } from 'react';
import { IProfileUser, IReferralInfo, IReferralsPerMember } from 'scripts/api/profile/profile.interfaces';
import {
  selectDependents,
  selectReferralsData,
  selectReferralsError,
  selectReferralsLoading,
} from 'scripts/selectors/profile-service-selectors';
import { ActiveProviderReferralsHeader } from './active-provider-referrals-header';
import { ActiveProviderReferralsTable } from './active-provider-referrals-table';
import { connect } from 'react-redux';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { getBenefits } from 'scripts/thunks/plans-service-thunks';
import { getFullName } from 'scripts/util/profile/profile';
import { getReferrals } from 'scripts/thunks/profile-service-thunks';
import { IBenefit } from 'scripts/api/plans/plans.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { NoProviderReferralsMessage } from './no-provider-referrals-message';
import { ProviderReferralsErrorMessage } from './provider-referrals-error-message';
import { ProviderReferralsHeader } from './provider-referrals-header';
import { selectMedicalPlanName } from 'scripts/selectors/plans-service-selectors';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';

interface IProviderReferralsProps extends IProviderReferralsStateToProps, IProviderReferralsDispatchToProps {}

interface IProviderReferralsStateToProps {
  currentProfile: IProfileUser;
  dependents: IProfileUser[];
  medicalPlanName: IBenefit['planName'];
  referralsError: boolean;
  referralsLoading: boolean;
  referralsPerMember: IReferralsPerMember;
  population: IPopulation;
}

interface IProviderReferralsDispatchToProps {
  getBenefits: () => void;
  getReferrals: () => void;
}

const addReferrals = (
  profileUser: IProfileUser,
  referralsPerMember: IReferralsPerMember,
  referrals: IReferralInfo[],
): void => {
  if (referralsPerMember[profileUser.dependentSeqNum]) {
    referralsPerMember[profileUser.dependentSeqNum].referrals.forEach(ref => {
      ref.patientName = getFullName(profileUser.userInfo);
      referrals.push(ref);
    });
  }
};

const setReferrals = (
  currentProfile: IProfileUser,
  dependents: IProfileUser[],
  referralsPerMember: IReferralsPerMember,
): IReferralInfo[] => {
  const referrals = [];
  addReferrals(currentProfile, referralsPerMember, referrals);
  for (const dependent of dependents) {
    addReferrals(dependent, referralsPerMember, referrals);
  }

  return referrals;
};

const hasDataError = (referralsPerMember: IReferralsPerMember): boolean => {
  for (const member in referralsPerMember) {
    if (referralsPerMember[member].error) {
      return true;
    }
  }

  return false;
};

const Section: FunctionComponent<HTMLAttributes<HTMLElement>> = props => <section {...props} />;
const SectionWithWaitFor = withWaitFor(Section);

export const RawProviderReferrals: FunctionComponent<IProviderReferralsProps> = props => {
  const {
    currentProfile,
    dependents,
    medicalPlanName,
    referralsError,
    referralsLoading,
    referralsPerMember,
    population,
  } = props;
  const error = referralsError;
  const loading = referralsLoading;
  let referrals: IReferralInfo[] = [];

  useEffect(() => {
    if (!medicalPlanName) {
      props.getBenefits();
    }

    if (!referralsPerMember) {
      props.getReferrals();
    }
  }, []);

  const handleRetry = (): void => {
    props.getBenefits();
    props.getReferrals();
  };

  let referralsContent;

  if (referralsPerMember) {
    referrals = setReferrals(currentProfile, dependents, referralsPerMember);

    if (hasDataError(referralsPerMember)) {
      referralsContent = <ProviderReferralsErrorMessage />;
    } else if (referrals.length === 0) {
      referralsContent = <NoProviderReferralsMessage />;
    } else {
      referralsContent = (
        <>
          <ActiveProviderReferralsHeader activeReferralsCount={referrals.length} />
          <ActiveProviderReferralsTable referrals={referrals} population={population} />
        </>
      );
    }
  }

  return (
    <>
      <ProviderReferralsHeader />
      <SectionWithWaitFor className="provider-referrals" error={error} loading={loading} onRetry={handleRetry}>
        {referralsContent}
      </SectionWithWaitFor>
    </>
  );
};

const mapStateToProps = (state: IReduxState): IProviderReferralsStateToProps => ({
  currentProfile: currentUser.selectProfile(state),
  dependents: selectDependents(state),
  referralsError: selectReferralsError(state),
  referralsLoading: selectReferralsLoading(state),
  referralsPerMember: selectReferralsData(state),
  medicalPlanName: selectMedicalPlanName(state),
  population: state.population,
});

export const ProviderReferrals = withProvider(
  connect(
    mapStateToProps,
    { getBenefits, getReferrals },
  )(RawProviderReferrals),
);
