import { Observable } from 'rxjs/Observable';
import {
  BenefitNetwork,
  BenefitPaymentType,
  IBenefitExplanation,
  IBenefitLinkReplacement,
} from '../../../api/plans/plans.interfaces';
import {
  ICustomAccumulatorDefinitionNetwork,
  ICustomAccumulatorLabelNetwork,
} from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IPopulationService } from '../../../util/population/population.service';
import { getIsEmpire } from '../../../util/population/population';

export interface IAccountSummaryService {
  getAccountSummaryExplanations(): Observable<IBenefitExplanation[]>;
  getHeaderWithLabelsTranslated(): Observable<IBenefitLinkReplacement>;
}

export class AccountSummaryService implements IAccountSummaryService {
  public static DEDUCTIBLE_HEADER_KEY = 'DEDUCTIBLE';
  public static OOP_MAX_HEADER_KEY = 'OOP_MAX_HEADER';
  public isEmpire: boolean;
  private customExplanation: IBenefitExplanation[];
  private headerLinkLabels: IBenefitLinkReplacement;
  // currently we only show inNetwork on the dashboard main
  private defaultNetwork: BenefitNetwork = BenefitNetwork.InNetwork;

  constructor(
    private populationService: IPopulationService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';

    this.headerLinkLabels = {
      [AccountSummaryService.DEDUCTIBLE_HEADER_KEY]: {
        label: 'DEDUCTIBLE',
      },
      [AccountSummaryService.OOP_MAX_HEADER_KEY]: {
        label: 'OOP_MAX_HEADER',
      },
    };

    this.isEmpire = getIsEmpire(this.populationService.getPopulation());

    this.customExplanation = this.setBaseCustomExplanation();
  }

  private setBaseCustomExplanation(): IBenefitExplanation[] {
    const deductibleExplanation = {
      label: 'DEDUCTIBLE',
      definition: 'DEDUCTIBLE_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    };
    const oopExplanation = {
      label: 'OOP_MAX_HEADER',
      definition: 'OOP_MAX_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    };
    const coinsuranceExplanation = {
      label: 'COINSURANCE_HEADER',
      definition: 'COINSURANCE_EXPLANATION',
      isCustomLabel: false,
      isCustomDef: false,
    };
    return this.isEmpire ? [oopExplanation] : [deductibleExplanation, oopExplanation, coinsuranceExplanation];
  }

  public getAccountSummaryExplanations(): Observable<IBenefitExplanation[]> {
    return this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .map(clientConfig => {
        const customDefinitions = clientConfig.contentOverrides.customAccumulatorDefinition || {};
        const customLabels = clientConfig.customLabels.customAccumulatorLabels || {};
        this.applyCustomDefinitionsForNetwork(this.defaultNetwork, customDefinitions);
        this.applyLabelsForNetwork(this.defaultNetwork, customLabels);
        return this.customExplanation;
      });
  }

  public getHeaderWithLabelsTranslated(): Observable<IBenefitLinkReplacement> {
    return this.userService
      .getHeartbeat()
      .flatMap(() => this.getAccountSummaryExplanations())
      .map(() => {
        return this.headerLinkLabels;
      });
  }

  private getExplanationLabelFromAccumulatorLabel(label: string): string {
    switch (label) {
      case BenefitPaymentType.FamilyDeductible:
      case BenefitPaymentType.IndividualDeductible:
        return AccountSummaryService.DEDUCTIBLE_HEADER_KEY;
      case BenefitPaymentType.FamilyOop:
      case BenefitPaymentType.IndividualOop:
        return AccountSummaryService.OOP_MAX_HEADER_KEY;
    }
  }

  private applyCustomDefinitionsForNetwork(
    network: string,
    customDefinitions: ICustomAccumulatorDefinitionNetwork,
  ): void {
    if (customDefinitions && Object.prototype.hasOwnProperty.call(customDefinitions, network)) {
      for (const paymentType in customDefinitions[network]) {
        if (Object.prototype.hasOwnProperty.call(customDefinitions[network], paymentType)) {
          this.customExplanation.forEach((value, index) => {
            if (value.label === this.getExplanationLabelFromAccumulatorLabel(paymentType)) {
              this.customExplanation[index].isCustomDef = true;
              this.customExplanation[index].definition = customDefinitions[network][paymentType];
            }
          });
        }
      }
    }
  }

  private applyLabelsForNetwork(network: string, customLabels: ICustomAccumulatorLabelNetwork): void {
    if (customLabels && Object.prototype.hasOwnProperty.call(customLabels, network)) {
      for (const paymentType in customLabels[network]) {
        if (Object.prototype.hasOwnProperty.call(customLabels[network], paymentType)) {
          this.customExplanation.forEach((value, index) => {
            if (value.label === this.getExplanationLabelFromAccumulatorLabel(paymentType)) {
              if (Object.prototype.hasOwnProperty.call(this.headerLinkLabels, value.label)) {
                this.headerLinkLabels[value.label].customLabel = customLabels[network][paymentType];
              }
              this.customExplanation[index].isCustomLabel = true;
              this.customExplanation[index].label = customLabels[network][paymentType];
            }
          });
        }
      }
    }
  }
}
