import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IProducts } from 'scripts/api/profile/profile.interfaces';
import { getProducts } from 'scripts/thunks/profile-service-thunks';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectProductsData } from 'scripts/selectors/profile-service-selectors';

interface IProviderReferralsErrorProps
  extends IProviderReferralsErrorStateToProps,
    IProviderReferralsErrorDispatchToProps {}

interface IProviderReferralsErrorStateToProps {
  products: IProducts;
}

interface IProviderReferralsErrorDispatchToProps {
  getProducts: () => void;
}

export const RawProviderReferralsErrorMessage: FunctionComponent<IProviderReferralsErrorProps> = props => {
  const { t } = useTranslation(Dictionary.PCP);
  const { products } = props;

  useEffect(() => {
    if (!products) {
      props.getProducts();
    }
  }, []);

  const phoneNum =
    products &&
    products.medicalSupport &&
    products.medicalSupport.contactInformation &&
    products.medicalSupport.contactInformation.phoneNum;

  return (
    <div className="no-referrals-message" data-testid="provider-referrals-error-message">
      {t(phoneNum ? 'REFERRALS_ERROR_MESSAGE_PHONE_NUM' : 'REFERRALS_ERROR_MESSAGE', { number: phoneNum })}
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IProviderReferralsErrorStateToProps => ({
  products: selectProductsData(state),
});

export const ProviderReferralsErrorMessage = connect(
  mapStateToProps,
  { getProducts },
)(RawProviderReferralsErrorMessage);
