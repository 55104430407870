import React, { FunctionComponent } from 'react';
import { TrackedReferredByPhysicianLink, TrackedReferredToPhysicianLink } from './provider-referrals-physician-link';
import classNames from 'classnames';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { IReferralInfo } from 'scripts/api/profile/profile.interfaces';
import { useTranslation } from 'react-i18next';

interface IActiveProviderReferralRowProps {
  referral: IReferralInfo;
  population: IPopulation;
}

export const ActiveProviderReferralRow: FunctionComponent<IActiveProviderReferralRowProps> = props => {
  const {
    approvedUnitCount,
    effectiveDateRange,
    patientName,
    referralNumber,
    referrer,
    remainingUnitCount,
    servicer,
  } = props.referral;
  const { t } = useTranslation([Dictionary.COMMON, Dictionary.PCP]);
  // since all of the fields on an IPhysician are optional,
  // guarding against an empty object returning and and erroring out when generating the physician link
  const showReferrer = referrer && Object.keys(referrer).length !== 0;
  const showServicer = servicer && Object.keys(servicer).length !== 0;

  return (
    <tr data-testid="active-provider-referral-row">
      <td>
        <span>{t(`${Dictionary.COMMON}:PATIENT_NAME`)}</span>
        <span>{patientName}</span>
      </td>
      <td>
        <span>{t(`${Dictionary.PCP}:REFERRAL_NUMBER`)}</span>
        <span>{referralNumber}</span>
      </td>
      <td className={classNames({ 'hide-mobile': !showServicer })}>
        {showServicer && (
          <>
            <span>{t(`${Dictionary.PCP}:REFERRED_TO`)}</span>
            <span>
              <TrackedReferredToPhysicianLink physician={servicer} population={props.population} />
            </span>
          </>
        )}
      </td>
      <td className={classNames({ 'hide-mobile': !showReferrer })}>
        {showReferrer && (
          <>
            <span>{t(`${Dictionary.PCP}:REFERRED_BY`)}</span>
            <span>
              <TrackedReferredByPhysicianLink physician={referrer} population={props.population} />
            </span>
          </>
        )}
      </td>
      <td
        className={classNames({
          'hide-mobile': !effectiveDateRange || !effectiveDateRange.startDate || !effectiveDateRange.endDate,
        })}
      >
        {effectiveDateRange && effectiveDateRange.startDate && effectiveDateRange.endDate && (
          <>
            <span>{t(`${Dictionary.PCP}:DATES_VALID`)}</span>
            <span className="hide-mobile">{`${effectiveDateRange.startDate.format('MM/DD/YYYY')} -`}</span>
            <span className="hide-mobile">{effectiveDateRange.endDate.format('MM/DD/YYYY')}</span>
            <span className="hide-desktop">
              {`${effectiveDateRange.startDate.format('MM/DD/YYYY')} - ${effectiveDateRange.endDate.format(
                'MM/DD/YYYY',
              )}`}
            </span>
          </>
        )}
      </td>
      <td className={classNames({ 'hide-mobile': !remainingUnitCount || !approvedUnitCount })}>
        {remainingUnitCount && approvedUnitCount && (
          <>
            <span className="asterisk-before">{t(`${Dictionary.PCP}:VISITS_REMAINING`)}</span>
            <span>
              {t(`${Dictionary.PCP}:VISITS_REMAINING_OF_TOTAL`, {
                remaining: remainingUnitCount,
                total: approvedUnitCount,
              })}
            </span>
          </>
        )}
      </td>
    </tr>
  );
};
