import { dateStringToMoment, stringToFloat } from 'scripts/util/conversion/conversion';
import { ledgerUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IGetAccountsResponse } from './ledger.interfaces';

function getAccounts(rallyId: string): Promise<IGetAccountsResponse> {
  const url = ledgerUris.balance(rallyId);
  return HttpClient.get<IGetAccountsResponse>(url, { cacheName: CacheName.Ledger }).then(rsp => {
    for (const account of rsp.data) {
      stringToFloat(account, 'balance', 'availableAmount', 'value');
      stringToFloat(account, 'balance', 'currentAmount', 'value');
      stringToFloat(account, 'balance', 'paidAmount', 'value');
      stringToFloat(account, 'balance', 'investmentAmount', 'value');
      stringToFloat(account, 'balance', 'totalBalance', 'value');
      stringToFloat(account, 'balance', 'carryOverAmount', 'value');
      stringToFloat(account, 'balance', 'accountFunding', 'annualElections', 'value');
      stringToFloat(account, 'balance', 'accountFunding', 'employeeContribution', 'value');
      stringToFloat(account, 'balance', 'accountFunding', 'employerContribution', 'value');
      stringToFloat(account, 'balance', 'accountFunding', 'beginningAmount', 'value');
      stringToFloat(account, 'accessPointInformation', 'familyAccessPointInformation', 'familyAccessPoint');
      stringToFloat(
        account,
        'accessPointInformation',
        'familyAccessPointInformation',
        'familyAccessPointAmountApplied',
      );
      stringToFloat(account, 'accessPointInformation', 'individualAccessPointInformation', 'individualAccessPoint');
      stringToFloat(
        account,
        'accessPointInformation',
        'individualAccessPointInformation',
        'individualMaxReimbursementAmount',
      );
      stringToFloat(
        account,
        'accessPointInformation',
        'individualAccessPointInformation',
        'individualAccessPointAccumulators',
        'individualAccessPointApplied',
      );
      stringToFloat(
        account,
        'accessPointInformation',
        'individualAccessPointInformation',
        'individualAccessPointAccumulators',
        'individualMaxReimbursementAmountUsed',
      );
      dateStringToMoment(account, 'accountPeriod', 'startDate');
      dateStringToMoment(account, 'accountPeriod', 'endDate');
    }
    return rsp;
  });
}

export const LedgerApi = {
  getAccounts,
};
