import { Subscription } from 'rxjs/Subscription';
import { getLink } from 'scripts/util/uri/uri';
import {
  AllProgramsPageCampaigns,
  CampaignPlacementType,
  ICampaign,
} from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IWellnessController } from './wellness.interfaces';

export class WellnessController implements IWellnessController {
  public wellnessSections: ICampaign[];
  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private localeService: ILocaleService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.WELLNESS);

    this.setWellnessCampaigns();
    this.localeSubscription = localeService.localeChanged.subscribe(() => {
      this.setWellnessCampaigns();
    });
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getLink(link: string): string {
    return getLink(link);
  }

  private setWellnessCampaigns(): void {
    this.wellnessSections = [];

    this.userService
      .getHeartbeat()
      .flatMap(rsp => this.targetingService.getCampaigns(rsp.data.rallyId, [AllProgramsPageCampaigns]))
      .filter(({ data }) => !!(data && data.placements))
      .map(({ data: { placements } }) => placements)
      .subscribe(campaigns => {
        if (campaigns[CampaignPlacementType.ArcadeResourcesPrimaryWellness]) {
          this.wellnessSections.push(campaigns[CampaignPlacementType.ArcadeResourcesPrimaryWellness][0]);
        }
        if (campaigns[CampaignPlacementType.ArcadeResourcesSecondaryWellness]) {
          this.wellnessSections.push(campaigns[CampaignPlacementType.ArcadeResourcesSecondaryWellness][0]);
        }
      });
  }
}
