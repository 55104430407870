import React, { FunctionComponent } from 'react';
import { ICoverageSection } from './id-cards.interfaces';
import { IdCardRxCarveOutCoverageHeader } from './id-card-rx-carve-out-coverage-header';
import { IdCardRxCarveOutCoverageContent } from './id-card-rx-carve-out-coverage-content';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { Feature } from 'scripts/ui/feature/feature';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';

interface IIdCardRxCarveOutCoverageProps {
  coverage: ICoverageSection;
  population?: IPopulation;
  users: IProfileUser[];
}

export const IdCardRxCarveOutCoverage: FunctionComponent<IIdCardRxCarveOutCoverageProps> = props => {
  const { coverage, population, users } = props;

  return (
    <Feature featureId={coverage.coverageType}>
      <IdCardRxCarveOutCoverageHeader coverage={coverage} population={population} users={users} />
      {coverage.showCarveOutLink && (
        <IdCardRxCarveOutCoverageContent
          coverage={coverage}
          population={population}
          data-testid="id-card-rx-carveout-coverage-content"
        />
      )}
    </Feature>
  );
};
