import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { hasActiveCoverage } from 'scripts/util/plans/plans';
import classNames from 'classnames';
import { getFullName } from 'scripts/util/profile/profile';
import moment from 'moment';
import { IdCardCoveragePeriod } from './id-card-coverage-period';

interface IIdCardCarouselProps {
  coverage: ICoverageSection;
  user: IProfileUser;
  activePcpPerMember?: { [depSeqNum: string]: string };
}

export const IdCardCarousel: FunctionComponent<IIdCardCarouselProps> = props => {
  const { coverage, user, activePcpPerMember } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);

  return (
    <>
      <h3>{getFullName(user.userInfo)}</h3>
      <h4>{t(`${Dictionary.COMMON}:${user.relationshipType}`)}</h4>
      <table role="presentation" className={classNames({ inactive: !hasActiveCoverage(coverage, user) })}>
        <tbody>
          <tr>
            <IdCardCoveragePeriod coverage={coverage} user={user} />
          </tr>
          <tr>
            <td>
              <div className="coverage-label">{t(`${Dictionary.COMMON}:DOB`)}</div>
              <div className="coverage-value"> {moment(user.userInfo.dob).format('MM/DD/YYYY')}</div>
            </td>
            <td>
              {activePcpPerMember && activePcpPerMember[user.dependentSeqNum] && (
                <>
                  <div className="coverage-label">{t(`${Dictionary.ID_CARDS}:PCP_INITIALS`)}</div>
                  <div className="coverage-value">{activePcpPerMember[user.dependentSeqNum]}</div>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
