import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';

export class FeatureFlagDirective implements ng.IDirective {
  public restrict = 'A';
  public priority = 100;
  public scope = false;
  public transclude: 'element' = 'element';

  constructor(private $animate: ng.animate.IAnimateService, private Environment: IEnvironmentConstants) {}

  public link = (scope, element, attrs, ctrl, transclude): void => {
    const prefix = 'ARCADE_FEATURES_';
    const requestedFeature = attrs.featureFlag;
    let featureEnabled = false;

    switch (this.Environment.FEATURE_FLAGS[prefix + requestedFeature]) {
      case 'yes':
      case 'true':
      case true:
      case '1':
      case 1:
        featureEnabled = true;
        break;
    }

    if (
      (featureEnabled && attrs.showWhenFalse === undefined) ||
      (!featureEnabled && attrs.showWhenFalse !== undefined)
    ) {
      transclude(clone => this.$animate.enter(clone, element.parent(), element));
    }
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = (
      $animate: ng.animate.IAnimateService,
      Environment: IEnvironmentConstants,
    ) => {
      'ngInject';
      return new FeatureFlagDirective($animate, Environment);
    };
    return directive;
  }
}
