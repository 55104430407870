import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { getGroupNumber } from 'scripts/util/plans/plans';

interface IIdCardCoverageDetailsHeaderProps {
  coverage: ICoverageSection;
  currentUser: IProfileUser;
}

export const IdCardCoverageDetailsHeader: FunctionComponent<IIdCardCoverageDetailsHeaderProps> = props => {
  const { coverage, currentUser } = props;
  const { t } = useTranslation([Dictionary.ACCOUNT_INFO, Dictionary.ID_CARDS]);

  return (
    <ul className="coverage-details" key={'details-' + coverage.planKey} data-testid="id-card-coverage-details-header">
      {coverage.planName && (
        <li className={classNames({ 'long-plan-name': coverage.planName.length > 35 })} data-testid="has-plan-name">
          <dl>
            <dt>{t(`${Dictionary.ID_CARDS}:${coverage.planType}`)}</dt> <dd>{coverage.planName}</dd>
          </dl>
        </li>
      )}
      <li>
        <dl>
          <dt>{t(`${Dictionary.ACCOUNT_INFO}:MEMBER_ID`)}</dt> <dd>{coverage.memberId && coverage.memberId.id} </dd>
        </dl>
      </li>
      {coverage.showGroupNumber && (
        <li data-testid="has-show-group-number">
          <dl>
            <dt>{t(`${Dictionary.ACCOUNT_INFO}:GROUP_NUM`)}</dt> <dd>{getGroupNumber(coverage, currentUser)}</dd>
          </dl>
        </li>
      )}
    </ul>
  );
};
