import tipsToSaveTemplate from 'views/modals/tips-to-save.html';
import { CoverageType } from '../../../api/api.interfaces';
import { Tier1Feature } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

class TipsToSaveController implements ng.IComponentController {
  public showTier1Provider: boolean;
  public showTier1Hospital: boolean;
  public showUHPD: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.TIPS_TO_SAVE);

    userService
      .getHeartbeat()
      .let(profileService.toProfile())
      .flatMap(profile => this.plansService.getBenefits(profile.data.currentUser.rallyId))
      .flatMap(benefits => benefits.data.benefits)
      .first(({ coverageType }) => coverageType === CoverageType.Medical)
      .subscribe(
        ({ planFeatures: { knownFeatures }, hideUHPD }) => {
          this.showUHPD = !hideUHPD;
          this.showTier1Provider =
            knownFeatures.indexOf(Tier1Feature.PCP) > -1 || knownFeatures.indexOf(Tier1Feature.Specialist) > -1;
          this.showTier1Hospital = knownFeatures.indexOf(Tier1Feature.Hospitals) > -1;
        },
        error => {
          console.warn(error);
          this.showUHPD = true;
        },
      );
  }
}

export class TipsToSaveComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = TipsToSaveController;
    this.templateUrl = tipsToSaveTemplate;
  }
}
