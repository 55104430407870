import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { LinkTarget } from 'scripts/api/api.interfaces';
import { IQuickLinksCategory } from './quick-links.controller';
import { ICONS } from 'scripts/util/constants/files';
import { Link } from 'scripts/ui/link/link';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import ExternalLinks from 'scripts/ui/external-link/external-link';

interface IQuickLinkProps {
  link: IQuickLinksCategory;
}

const getIcon = (link: IQuickLinksCategory): string => {
  if (link.icon) {
    return ICONS[link.icon];
  } else if (link.iconUrl) {
    return link.iconUrl;
  }
};

const QuickLink: FunctionComponent<IQuickLinkProps> = props => {
  const { link } = props;
  const LinkWithClickTracking = withClickTracking(Link, link.text);
  const { t } = useTranslation([Dictionary.COMMON, Dictionary.QUICK_LINKS]);
  const isLinkTargetBlank = link.target === LinkTarget.Blank;
  const quickLinkText = link.id === 'CUSTOM_LINK' ? link.text : t(`${link.dictionary}:${link.text}`);

  return (
    <div className="link-container">
      {/* TODO: track-campaign dependency? */}
      <LinkWithClickTracking
        className="quick-link"
        to={link.href}
        target={link.target}
        noNewWindowIcon={isLinkTargetBlank}
      >
        <img className="icon-container" src={getIcon(link)} role="presentation" />
        <div className="text-container">
          <span>{quickLinkText}</span>
          {isLinkTargetBlank && <ExternalLinks />}
        </div>
      </LinkWithClickTracking>
    </div>
  );
};

export default QuickLink;
