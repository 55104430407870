import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import IconCircleCheck from 'images/icons/icon-circle-check.svg';
import IconCircleMinus from 'images/icons/icon-circle-minus.svg';

export const IdCardIconCircleCheck: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.ID_CARDS);
  return (
    <IconCircleCheck
      className="icon-circle-check"
      aria-label={t(`${Dictionary.ID_CARDS}:PLAN_ACTIVE_ALT_TEXT`)}
      data-testid="id-card-icon-circle-check"
    />
  );
};

export const IdCardIconCircleMinus: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.ID_CARDS);
  return (
    <IconCircleMinus
      className="icon-circle-minus"
      aria-label={t(`${Dictionary.ID_CARDS}:PLAN_INACTIVE_ALT_TEXT`)}
      data-testid="id-card-icon-circle-minus"
    />
  );
};
