import { ITrackingEventRequest, TrackingTriggerType } from 'scripts/api/tracking/tracking.interfaces';
import CONFIG from '../../util/constants/config';
import FEATURE_FLAGS from '../constants/feature-flag';
import { stringifyData, getFeatureList, formatTrackingString, getPlacement } from './tracking-helper';
import getAngularService from '../get-angular-service/get-angular-service';
import { ITrackingService } from 'scripts/api/tracking/tracking.service';

let queuedEvents = [];

export function getQueuedEvents(): ITrackingEventRequest[] {
  return queuedEvents;
}

export function resetQueuedEvents(): void {
  queuedEvents = [];
}

export function updateQueuedEvents(eventsOrEventToQueue: ITrackingEventRequest[] | ITrackingEventRequest): void {
  if (Array.isArray(eventsOrEventToQueue)) {
    queuedEvents = queuedEvents.concat(eventsOrEventToQueue);
  } else {
    queuedEvents.push(eventsOrEventToQueue);
  }
}

export function queuePageLoadEvent(uri: string, featureList: string[], data: any): void {
  const event = {
    trigger: TrackingTriggerType.PageLoad,
    actionName: 'page-load',
    uri,
    serviceVersion: CONFIG.ARCADE_WEB_VERSION,
    featureList,
    placement: 'page-load',
    data,
  };

  stringifyData(event);
  updateQueuedEvents(event);
}

export function logEvent(event: ITrackingEventRequest): void {
  // If logging the analytics events out to the console is turned on, display it.
  if (FEATURE_FLAGS.ARCADE_FEATURES_ANALYTICS_DEBUG) {
    console.warn(`Analytics event triggered:
        \turi: ${event.uri}
        \ttrigger: ${event.trigger}
        \tfeatureList: ${event.featureList}
        \taction: ${event.actionName}
        \tfullName: ${[...event.featureList, event.actionName].join('.')}
        \tplacement: ${event.placement}
        \tdata: ${event.data || 'NONE'}`);
  }
}

export function sendSectionLoad(
  trackLabel: string,
  trackData: any,
  featureList: string[],
  ref: React.RefObject<HTMLDivElement>,
  isError?: boolean,
  startTime?: number,
): void {
  if (trackLabel) {
    const fullFeatureList = getFeatureList(ref.current).concat(featureList);
    if (fullFeatureList.length > 0) {
      trackData.rspTime = startTime ? new Date().getTime() - startTime : 0;
      const event = {
        trigger: isError ? TrackingTriggerType.SectionFailed : TrackingTriggerType.SectionLoad,
        uri: window.location.toString(),
        serviceVersion: CONFIG.ARCADE_WEB_VERSION,
        featureList: fullFeatureList,
        actionName: formatTrackingString(trackLabel),
        placement: getPlacement(ref.current),
        data: trackData,
      };
      const trackingService = getAngularService<ITrackingService>('trackingService');
      trackingService.queueEvent(event);
    }
  }
}
