import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { IPopulationService } from 'scripts/util/population/population.service';
import {
  advantage,
  allClaims,
  connect,
  connectSaved,
  expatGlobalGateway,
  expatHealthResources,
  expatSubmitClaims,
  rx,
} from 'scripts/util/resource/resource.constants';
import { CoverageStatus, CoverageType, ILink, LinkTarget } from '../../../api/api.interfaces';
import { IGetAccountsResponse, ILedgerAccount } from '../../../api/ledger/ledger.interfaces';
import { ILedgerService, LedgerService } from '../../../api/ledger/ledger.service';
import { IProductsResponse, IProfileUser } from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { CampaignPlacementType } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { IEnvironmentConstants } from '../../../util/constants/environment.interfaces';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IGridSetup } from '../../../util/grid/grid.interfaces';
import { ILocaleService } from '../../../util/locale/locale.service';
import { IResourceService } from '../../../util/resource/resource.service';
import { ICampaignPlacements } from './../../../api/targeting/targeting.interfaces';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { isExpatriate } from '../../../util/user/user';

export interface IQuickLinksCategory extends ILink {
  action?: any;
  campaignId?: string;
  dictionary?: string;
  icon?: string;
  iconUrl?: string;
  id: string;
  placementType?: string;
  show?: boolean;
  subtext?: string;
}

export class QuickLinksController implements IGridSetup, ng.IComponentController {
  public links: IQuickLinksCategory[];
  public maxColumns = 5;
  public showExternalLinkMessage: boolean;

  private isExpat: boolean;
  private rallyRxInProducts: boolean;
  private LINK_URL;
  private localeSubscription: Subscription;
  private primaryPolicyNumber: IProfileUser['primaryPolicyNumber'];
  private profileSubscription: Subscription;

  constructor(
    private $filter: ng.IFilterService,
    private $state: angular.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private Files: IFilesConstant,
    private ledgerService: ILedgerService,
    private localeService: ILocaleService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
    private featureFlagService: IFeatureFlagService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.QUICK_LINKS);
    this.LINK_URL = {
      CLAIMS: this.resourceService.get(allClaims),
      ADVANTAGE: this.resourceService.get(advantage),
      EXT_FIND_CARE: this.resourceService.get(connect),
      RALLY_RX: this.resourceService.get(rx),
      SAVED: this.resourceService.get(connectSaved),
    };

    this.links = [
      {
        id: CoverageType.Medical,
        text: 'FIND_DOCTOR',
        href: this.$state.href('internalRedirect', { deepLink: this.LINK_URL.EXT_FIND_CARE }),
        action: e => this.internalRedirect(e, this.LINK_URL.EXT_FIND_CARE),
        icon: 'icon-find-doctor',
        show: true,
      },
      {
        id: 'CLAIMS',
        text: 'MANAGE_CLAIMS',
        href: $state.href('authenticated.claimsAndAccounts.claims'),
        icon: 'icon-claims',
        show: true,
      },
      {
        id: CoverageType.Rx,
        text: 'MANAGE_PRESCRIPTIONS',
        href: $state.href('authenticated.pharmacy'),
        icon: 'icon-pharmacy',
      },
      {
        id: 'ACCESS_FINANCIAL_ACCOUNTS',
        text: 'ACCESS_FINANCIAL_ACCOUNTS',
        href: $state.href('authenticated.claimsAndAccounts.planBalances', { accountsExpanded: true }),
        icon: 'icon-balances',
      },
      {
        id: 'ADVANTAGE',
        text: 'VISIT_HEALTH_AND_WELLNESS',
        target: LinkTarget.Blank,
        href: this.$state.href('internalRedirect', { deepLink: this.LINK_URL.ADVANTAGE }),
        icon: 'icon-wellness',
      },
      {
        id: 'SAVED',
        text: 'VIEW_SAVED',
        href: $state.href('internalRedirect', { deepLink: this.LINK_URL.SAVED }),
        action: e => this.internalRedirect(e, this.LINK_URL.SAVED),
        icon: 'icon-saved',
        show: true,
      },
    ];
  }

  public $onInit(): void {
    this.initQuickLinks();

    this.profileSubscription = this.profileService.profileChanged.subscribe(() => {
      this.initQuickLinks();
    });

    const campaigns$ = this.userService
      .getHeartbeat()
      .flatMap(rsp =>
        this.targetingService.getCampaigns(rsp.data.rallyId, [CampaignPlacementType.ArcadeDashboardQuickLinks]),
      )
      .filter(({ data }) => !!(data && data.placements))
      .map(({ data: { placements } }) => placements);

    this.localeSubscription = this.localeService.localeChanged
      .do(() => {
        this.resetLinksOnLocaleChange();
      })
      .flatMap(() => campaigns$)
      .subscribe(campaigns => {
        const numLinksShown = this.links.filter(link => link.show).length;
        this.modifyCustomLinks(campaigns, numLinksShown);
      }, console.warn);
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
    this.localeSubscription.unsubscribe();
  }

  public getNumColumns(): number {
    const columns = this.links.reduce((columnTotal, link) => {
      return columnTotal + (link.show ? 1 : 0);
    }, 0);
    return columns <= this.maxColumns ? columns : this.maxColumns;
  }

  public getIcon(link: IQuickLinksCategory): string {
    if (link.icon) {
      return this.Files.getIcon(link.icon);
    } else if (link.iconUrl) {
      return link.iconUrl;
    }
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private initQuickLinks(): void {
    const currentProfile = this.userService.getHeartbeat().let(this.profileService.toCurrentProfile());
    const products = this.userService
      .getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .catch(() => Observable.of(undefined));
    const campaigns = this.userService
      .getHeartbeat()
      .flatMap(rsp =>
        this.targetingService.getCampaigns(rsp.data.rallyId, [
          CampaignPlacementType.ArcadeDashboardQuickLinks,
          CampaignPlacementType.ArcadeCarousel,
        ]),
      )
      .filter(({ data }) => !!(data && data.placements))
      .map(({ data: { placements } }) => placements);
    const financialAccounts = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .flatMap(currentUser =>
        Observable.if(
          () => ProfileService.hasLedgerAccess(currentUser),
          this.ledgerService.getAccounts(currentUser.rallyId),
          Observable.of(undefined),
        ),
      )
      .catch(() => Observable.of(undefined));

    let numLinksShown = 3;

    Observable.zip(currentProfile, products, financialAccounts)
      .do(([currentProfileRsp, productsRsp, accountsRsp]: [IProfileUser, IProductsResponse, IGetAccountsResponse]) => {
        this.isExpat = isExpatriate(currentProfileRsp);
        if (this.isExpat) {
          this.setExpatLinks();
          return;
        }
        this.primaryPolicyNumber = currentProfileRsp.primaryPolicyNumber;
        const rxLink = this.links[2];
        this.rallyRxInProducts = productsRsp && !!productsRsp.data.products.core_mpe_rallyRx;
        this.setRxLinkUrl(rxLink, this.primaryPolicyNumber);
        const accountsLink = this.links[3];
        const advantageLink = this.links[4];
        const savedLink = this.links[5];

        // reset this.links to original state
        rxLink.show = false;
        accountsLink.show = false;
        advantageLink.show = false;
        savedLink.show = true;
        this.links = this.links.slice(0, 6);

        // Show Rx quick link if there exists additional or standalone Rx coverage && the rx product exists.
        const hasRxCoverage =
          currentProfileRsp.planCoverages.filter(coverage => {
            return (
              ((coverage.coverageType === CoverageType.Rx && typeof coverage.rxCoverageInfo !== 'undefined') ||
                coverage.additionalCoverageTypes.indexOf(CoverageType.Rx) > -1) &&
              coverage.planPeriod.status === CoverageStatus.Active
            );
          }).length > 0 &&
          productsRsp &&
          !!productsRsp.data.products.rx;

        if (hasRxCoverage) {
          rxLink.show = true;
          numLinksShown++;
        }

        if (accountsRsp && accountsRsp.data.some(account => account.isActive)) {
          accountsLink.show = true;
          accountsLink.text = this.getAccountsLinkText(accountsRsp.data);
          numLinksShown++;
        }

        if (productsRsp && productsRsp.data.products.rallyEngage) {
          // ARC-7786: long story short, checking that the product is engage, but linking to advantage
          advantageLink.show = true;
          this.showExternalLinkMessage = true;
          numLinksShown++;
        }

        // if all other links are shown, then saved misses the cut
        if (numLinksShown === 6) {
          savedLink.show = false;
          numLinksShown = 5;
        }
      })
      .flatMap(() => campaigns)
      .subscribe(campaignsRsp => {
        this.modifyCustomLinks(campaignsRsp, numLinksShown);
      });
  }

  private getAccountsLinkText(accounts: ILedgerAccount[]): string {
    const activeAccounts = accounts.filter(account => account.isActive);
    if (activeAccounts.every(LedgerService.isHsaAccount)) {
      return 'MANAGE_YOUR_HSA';
    }
    if (activeAccounts.every(LedgerService.isFsaAccount)) {
      return 'MANAGE_YOUR_FSA';
    }
    if (activeAccounts.every(LedgerService.isHraAccount)) {
      return 'MANAGE_YOUR_HRA';
    }
    if (activeAccounts.some(LedgerService.isHsaAccount) && activeAccounts.some(LedgerService.isFsaAccount)) {
      return 'MANAGE_YOUR_HSA_FSA';
    }
    if (activeAccounts.some(LedgerService.isHraAccount) && activeAccounts.some(LedgerService.isFsaAccount)) {
      return 'MANAGE_YOUR_HRA_FSA';
    }
    // Based on the case that JPMC user can only have JPMC account types
    if (activeAccounts.every(LedgerService.isMraAccount)) {
      return 'MANAGE_YOUR_MRA';
    }
    if (
      activeAccounts.some(LedgerService.isMraAccount) &&
      activeAccounts.some(LedgerService.isHcsaAccount) &&
      activeAccounts.some(LedgerService.isDcsaAccount)
    ) {
      return 'MANAGE_YOUR_MRA_HCSA_DCSA';
    }
    if (activeAccounts.some(LedgerService.isMraAccount) && activeAccounts.some(LedgerService.isHcsaAccount)) {
      return 'MANAGE_YOUR_MRA_HCSA';
    }
    if (activeAccounts.some(LedgerService.isMraAccount) && activeAccounts.some(LedgerService.isDcsaAccount)) {
      return 'MANAGE_YOUR_MRA_DCSA';
    }
    return 'ACCESS_FINANCIAL_ACCOUNTS';
  }

  private resetLinksOnLocaleChange(): void {
    this.LINK_URL.EXT_FIND_CARE = this.resourceService.get(connect);
    this.LINK_URL.RALLY_RX = this.resourceService.get(rx);
    this.LINK_URL.SAVED = this.resourceService.get(connectSaved);
    this.links[0].href = this.$state.href('internalRedirect', { deepLink: this.LINK_URL.EXT_FIND_CARE });
    this.links[0].action = e => this.internalRedirect(e, this.LINK_URL.EXT_FIND_CARE);
    this.setRxLinkUrl(this.links[2], this.primaryPolicyNumber);
    this.links[5].href = this.$state.href('internalRedirect', { deepLink: this.LINK_URL.SAVED });
    this.links[5].action = e => this.internalRedirect(e, this.LINK_URL.SAVED);
  }

  private setRxLinkUrl(rxLink: IQuickLinksCategory, primaryPolicyNumber: IProfileUser['primaryPolicyNumber']): void {
    const rallyRxPolicies = this.Environment.CONFIG.ARCADE_WEB_RALLY_RX_POLICIES.split(',');
    const isPolicyMatch = !!rallyRxPolicies && rallyRxPolicies.indexOf(primaryPolicyNumber) !== -1;
    const isRallyRxInProducts = this.featureFlagService.isRallyRxInProductEligibilityOn() && this.rallyRxInProducts;

    if (this.featureFlagService.isRallyRxOn() || isPolicyMatch || isRallyRxInProducts) {
      rxLink.href = this.LINK_URL.RALLY_RX;
      rxLink.target = LinkTarget.Self;
    }
  }

  private modifyCustomLinks(campaignsRsp: ICampaignPlacements, numLinksShown: number): void {
    if (this.isExpat) {
      return;
    }
    const accountsLink = this.links[3];
    const advantageLink = this.links[4];
    const savedLink = this.links[5];

    const customQuickLinks = this.$filter('orderBy')(
      campaignsRsp[CampaignPlacementType.ArcadeDashboardQuickLinks],
      'priority',
      true,
    );
    if (customQuickLinks) {
      const maxLoops = Math.min(customQuickLinks.length, 2);
      for (let i = 0; i < maxLoops; i++) {
        // first make space for the custom link by hiding other ones as necessary
        while (numLinksShown > 4) {
          if (savedLink.show) {
            savedLink.show = false;
          } else if (advantageLink.show) {
            advantageLink.show = false;
          } else if (accountsLink.show) {
            accountsLink.show = false;
          }
          numLinksShown--;
        }

        const customLink = customQuickLinks[i];
        const shouldOpenInSameTab = this.targetingService.shouldOpenPromoInSameTab(customLink.cta.ctaValue);
        if (!shouldOpenInSameTab) {
          this.showExternalLinkMessage = true;
        }

        this.links[i + 6] = {
          id: 'CUSTOM_LINK',
          text: customLink.cta.ctaText,
          target: shouldOpenInSameTab ? LinkTarget.Self : LinkTarget.Blank,
          href: customLink.cta.ctaValue,
          iconUrl: customLink.imageUrl,
          campaignId: customLink.campaignId,
          placementType: customLink.placementType,
          show: true,
        };
        numLinksShown++;
      }
    }
  }

  private setExpatLinks(): void {
    this.links = [
      {
        id: CoverageType.Medical,
        text: 'FIND_A_US_PROVIDER',
        href: this.$state.href('internalRedirect', { deepLink: this.LINK_URL.EXT_FIND_CARE }),
        action: e => this.internalRedirect(e, this.LINK_URL.EXT_FIND_CARE),
        icon: 'icon-find-doctor',
        show: true,
      },
      {
        id: 'VIEW_CLAIMS',
        text: 'VIEW_CLAIMS',
        href: this.$state.href('authenticated.claimsAndAccounts.claims'),
        icon: 'icon-claims',
        show: true,
      },
      {
        id: 'SUBMIT_CLAIMS',
        text: 'SUBMIT_CLAIMS',
        target: LinkTarget.Blank,
        href: this.resourceService.get(expatSubmitClaims),
        icon: 'icon-submit-claim',
        show: true,
      },
      {
        id: 'HEALTH_RESOURCES',
        text: 'VIEW_HEALTH_RESOURCES',
        href: this.resourceService.get(expatHealthResources),
        icon: 'icon-health-resources',
        show: true,
      },
      {
        id: 'GLOBAL_GATEWAY',
        text: 'VIEW_GLOBAL_GATEWAY',
        target: LinkTarget.Blank,
        href: this.resourceService.get(expatGlobalGateway),
        icon: 'icon-global',
        show: true,
      },
    ];

    this.showExternalLinkMessage = true;
  }
}
