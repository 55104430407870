import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { getMoneyValue } from 'scripts/util/money/money';
import { IAccountSummaryAmount } from '../account-summary/account-summary.interfaces';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';

interface IMrAccountSummaryDetailsProps {
  arcadeDataUpdated: moment.Moment;
  billedItems: IAccountSummaryAmount[];
  firstName: string;
  totalBilledAmount: number;
}

const TrackedMrMedicalExplanationOfTermsLink = withClickTracking(Link, 'TOTAL_BILLED');

export const MrAccountSummaryDetails: FunctionComponent<IMrAccountSummaryDetailsProps> = props => {
  const { arcadeDataUpdated = moment(), billedItems, firstName, totalBilledAmount } = props;
  const { t } = useTranslation(Dictionary.ACCOUNT_SUMMARY);

  return (
    <div
      className="summary-details col-1-2"
      aria-labelledby="claims-header"
      aria-describedby="claims-note"
      data-testid="account-summary-details"
    >
      <h3 id="claims-header" className="chart-header">
        <TrackedMrMedicalExplanationOfTermsLink to="/modal/medical-explanation-of-terms">
          {t('TOTAL_BILLED')}
        </TrackedMrMedicalExplanationOfTermsLink>
        <span className="right">{getMoneyValue(totalBilledAmount)}</span>
      </h3>
      <ul className="summary-details-list chart has-legend">
        {billedItems.map((item, index, arr) => {
          const TrackedLink = withClickTracking(Link, item.name);
          return (
            <li key={item.name}>
              <span
                className={classNames(
                  'legend-indicator',
                  `donut-color-${index + 1}`,
                  { 'donut-color-zero': item.amount === 0 },
                  { 'donut-color-last': arr.length - 1 === index },
                )}
                aria-hidden="true"
              />
              <span>
                <TrackedLink to="/modal/medical-explanation-of-terms">{t(item.name)}</TrackedLink>
              </span>
              <span className="right" data-testid={`${item.name}-amount`}>
                {getMoneyValue(item.amount)}
              </span>
            </li>
          );
        })}
      </ul>
      <aside id="claims-note" role="note" className="hide-mobile">
        <span>
          {t('INDIVIDUAL_TOTAL_BILLED_FROM', { person: firstName, date: arcadeDataUpdated.format('MM/DD/YYYY') })}
        </span>
      </aside>
    </div>
  );
};
