import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { getUsersWithCoverage, hasTermedCoverage, hasTermedUser } from 'scripts/util/plans/plans';
import { getFullName } from 'scripts/util/profile/profile';
import moment from 'moment';
import { IdCardCoveragePeriod } from './id-card-coverage-period';
import { CoverageType } from 'scripts/api/api.interfaces';

interface IIdCardTermedMembersTableProps {
  coverage: ICoverageSection;
  users: IProfileUser[];
  isGatedPlan?: boolean;
  activePcpPerMember?: { [depSeqNum: string]: string };
}

export const IdCardTermedMembersTable: FunctionComponent<IIdCardTermedMembersTableProps> = props => {
  const { coverage, users, isGatedPlan, activePcpPerMember } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);

  return (
    <>
      {hasTermedUser(coverage, users) && (
        <div data-testid="id-card-termed-members-table">
          <h2 className="coverage-table-header termed-members">{t(`${Dictionary.ID_CARDS}:TERMED_MEMBERS`)}</h2>
          <table role="presentation" data-testid="termed-members-table">
            <tbody>
              <tr className="sr-only">
                <th>{t(`${Dictionary.COMMON}:NAME`)}</th>
              </tr>
              {getUsersWithCoverage(coverage, users).map(
                user =>
                  hasTermedCoverage(coverage, user) && (
                    <tr
                      key={
                        'termed-member-details-' + user.lineOfBusiness + user.dependentSeqNum + coverage.coverageType
                      }
                      className="inactive"
                    >
                      <td>
                        <div className="member-name">{getFullName(user.userInfo)}</div>
                        <div className="member-type-dob">
                          <span>{t(user.relationshipType)} </span>-<span> {t(`${Dictionary.COMMON}:DOB`)}</span>
                          <span> {moment(user.userInfo.dob).format('MM/DD/YYYY')}</span>
                        </div>
                        {isGatedPlan &&
                          coverage.coverageType === CoverageType.Medical &&
                          activePcpPerMember &&
                          activePcpPerMember[user.dependentSeqNum] && (
                            <div className="member-pcp">
                              {t(`${Dictionary.ID_CARDS}:PCP_INITIALS`) +
                                '-' +
                                activePcpPerMember[user.dependentSeqNum]}
                            </div>
                          )}
                      </td>
                      <IdCardCoveragePeriod coverage={coverage} user={user} />
                    </tr>
                  ),
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
