import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { constructParams } from 'scripts/util/uri/uri';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import IconWatch from 'images/icons/icon-watch.svg';
import { Link } from 'scripts/ui/link/link';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { useTranslation } from 'react-i18next';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';

export interface IClaimVideoExplanationProps {
  claimVideoEligibility: boolean;
  claimId: string;
  classes?: string[];
}

const TrackedLink = withClickTracking(Link, 'claim-video');

export const ClaimVideoExplanation: FunctionComponent<IClaimVideoExplanationProps> = props => {
  const { claimId, claimVideoEligibility, classes } = props;
  const { location } = useRouter();
  const { t } = useTranslation(Dictionary.COMMON);
  const show = claimVideoEligibility;
  const claimVideoFrom = `${location.pathname}${location.search}`;
  const params = { claimId, claimVideoFrom };
  const to = { pathname: '/modal/claim-video', search: `${constructParams(params)}` };
  if (!show) {
    return null;
  }
  return (
    <TrackedLink className={classNames('claim-video', classes)} to={to}>
      <IconWatch className="icon-watch" aria-hidden="true" />
      <span>{t('WHY_DO_I_OWE_THIS_AMOUNT')}</span>
    </TrackedLink>
  );
};
