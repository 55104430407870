import jQuery from 'jquery';
import { Observable } from 'rxjs/Observable';
import { CoverageType, ITimePeriod, RelationshipType } from 'scripts/api/api.interfaces';
import {
  AnyClaimType,
  ClaimStatus,
  ClaimType,
  IAnyClaim,
  IClaim,
  IFinancialClaim,
} from 'scripts/api/claims/claims.interfaces';
import { ClaimsService } from 'scripts/api/claims/claims.service';
import { AccountType } from 'scripts/api/ledger/ledger.interfaces';
import { ILedgerService } from 'scripts/api/ledger/ledger.service';
import { IPlansService } from 'scripts/api/plans/plans.service';
import { MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { IAllClaimsCustomMessage, IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IDropdownOption, ISortDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { FilterStateKey, IGenericFilter, ISelectedFilters } from 'scripts/ui/filter/filter.interfaces';
import { IFilterService } from 'scripts/ui/filter/filter.service';
import { ClaimMarkPaid } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { IPopulationService } from 'scripts/util/population/population.service';
import { internationalClaims, visionClaims } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import allClaimsTemplate from 'views/claims-and-accounts/claims/all-claims.html';
import { AllClaimsService } from './all-claims.service';
import { sendAAPageData } from 'scripts/util/tracking/adobe-analytics';
import { getAAClaimStatusString, isVideoAvailable } from 'scripts/util/claims/claims';
import { IGenesysService } from 'scripts/util/genesys/genesys.service';
import { isExpatriate } from 'scripts/util/user/user';
import {
  downloadFinancialClaims,
  downloadHealthcareClaims,
} from 'scripts/features/claims-and-accounts/claims/all-claims-utils';

export class AllClaimsController implements ng.IComponentController {
  public allClaims: IAnyClaim[];
  public claims: IAnyClaim[];
  public claimStatusVideoPresence: boolean;
  public clientConfig: IClientConfig;
  public customMessage: IAllClaimsCustomMessage;
  public filters: IGenericFilter<IAnyClaim, any>[];
  public isOxford: boolean;
  public isSmartSortEligible: boolean;
  public request: Observable<IAnyClaim[]>;
  public selectedSortByOption: IDropdownOption;
  public showLinkToInternationalClaims: boolean;
  public showVisionLink: boolean;
  public sortByOptions: IDropdownOption[];
  public suppressChat: boolean;
  public type: AnyClaimType;
  public typeConfig: { [FinancialClaimType: string]: { value: AccountType; display: string } };
  public types: { value: AnyClaimType; display: string }[];
  public usePagination = true;
  public paginationPageSize = 10;
  public suppressPayNow: boolean;

  private internationalClaimsUrl: IResource;
  private visionClaims: IResource;
  private selectedFilters: ISelectedFilters<any>;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $translate: ng.translate.ITranslateService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private claimsService: ClaimsService,
    private featureFlagService: IFeatureFlagService,
    private filterService: IFilterService,
    private genesysService: IGenesysService,
    private ledgerService: ILedgerService,
    private plansService: IPlansService,
    private populationService: IPopulationService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ALL_CLAIMS);
    $translatePartialLoader.addPart(Dictionary.FAQ);

    this.typeConfig = {
      [AccountType.DCSA]: { value: AccountType.DCSA, display: 'DCSA' },
      [AccountType.FSADC]: { value: AccountType.FSADC, display: 'DEPENDENT_CARE_FSA' },
      [AccountType.FSAHC]: { value: AccountType.FSAHC, display: 'HEALTHCARE_FSA' },
      [AccountType.FSALP]: { value: AccountType.FSALP, display: 'LIMITED_PURPOSE_FSA' },
      [AccountType.HCSA]: { value: AccountType.HCSA, display: 'HCSA' },
      [AccountType.HRA]: { value: AccountType.HRA, display: 'HRA_SHORT' },
      [AccountType.HRAAP]: { value: AccountType.HRAAP, display: 'HRA_SHORT' },
      [AccountType.HRAPD]: { value: AccountType.HRAPD, display: 'HRA_SHORT' },
      [AccountType.HRASD]: { value: AccountType.HRASD, display: 'HRA_SHORT' },
      [AccountType.MRA]: { value: AccountType.MRA, display: 'MRA' },
    };

    this.type = this.filterService.getStateValue(FilterStateKey.Type) || ClaimType.Medical;
    this.initFilters(this.type);
    this.initSort(this.type);

    this.visionClaims = visionClaims;
    this.internationalClaimsUrl = this.resourceService.get(internationalClaims);

    this.isOxford = this.populationService.getPopulation().membershipCategory === MembershipCategory.OXFORD;
  }

  public handleClaimUpdate = (updatedClaim: IClaim): void => {
    this.claims = this.claims.map(claim => (claim.claimKey === updatedClaim.claimKey ? updatedClaim : claim));
  };

  public handleClaimType(type: AnyClaimType): void {
    this.filterService
      .updateStateValue(type, FilterStateKey.Type)
      .flatMap(() => this.filterService.updateStateValue(undefined))
      .subscribe(() => {
        this.allClaims = [];
        this.initFilters(type);
        this.initSort(type);
        this.initClaims();
      });
  }

  public handleClaimTypeKeypress(type: AnyClaimType, event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.type = type;
      this.handleClaimType(type);
    }
  }

  public handleFilters(filterName: string, selectedValues: any[]): void {
    for (const filter of this.filters) {
      if (filter.name === filterName) {
        filter.selectedValues = selectedValues;
        break;
      }
    }
    this.claims = this.filterService.sort(
      this.filterService.filter(this.allClaims, this.filters),
      AllClaimsService.getSort(this.selectedFilters.sort),
    );
    this.selectedFilters.values[filterName] = selectedValues;
    this.filterService.updateStateValue(this.selectedFilters).subscribe();
  }

  public $onInit(): void {
    this.genesysService.updatePageAndButtonClassNames();
    this.suppressChat = !this.featureFlagService.isGenesysChatOnForClaims();
    this.setClaimTypes();
    this.initClaims();
    this.setShowLinkOuts();

    this.$rootScope.$on(ClaimMarkPaid, (_, claim: IClaim) => {
      this.handleClaimUpdate(claim);
    });
  }

  public getPeriod(): ITimePeriod {
    return AllClaimsService.getPeriod(this.filters, this.selectedFilters);
  }

  public initClaims(): void {
    this.request = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => this.claimsService.getAll(profile, this.type, this.getPeriod()).map(rsp => rsp.data),
        (profile, claims) => ({ profile, claims }),
      )
      .flatMap(
        ({ profile }) => this.targetingService.getClientConfig(profile.rallyId),
        ({ claims }, clientConfig) => ({ claims, clientConfig }),
      )
      .do(({ claims, clientConfig }) => {
        AllClaimsService.setIsMerp(claims, this.type, clientConfig.suppressions.showMERPLabel);
        this.clientConfig = clientConfig;
        this.suppressPayNow = clientConfig && clientConfig.suppressions && clientConfig.suppressions.payNowSuppression;
        this.customMessage = clientConfig && clientConfig.customMessaging.allClaimsCustomMessage;
      })
      .map(({ claims }) => claims);

    this.request.subscribe(
      claims => {
        this.allClaims = claims;
        this.isSmartSortEligible =
          this.type === ClaimType.Medical && !this.suppressPayNow
            ? AllClaimsService.getSmartSortEligible(this.allClaims as IClaim[])
            : false;
        this.initFilters(this.type);
        this.initSort(this.type);
        this.filterService.setSelectedFilterValues(this.filters, this.selectedFilters, claims);
        this.claims = this.filterService.sort(
          this.filterService.filter(this.allClaims, this.filters),
          AllClaimsService.getSort(this.selectedFilters.sort),
        );

        const claimStatusString = getAAClaimStatusString(this.claims);

        if (claimStatusString) {
          this.claimStatusVideoPresence = this.claims.some(claim => isVideoAvailable(claim));
          sendAAPageData('claimStatusType', {
            pageName: 'all-claims',
            claimStatusType: claimStatusString,
            videoAvailable: this.claimStatusVideoPresence ? 'ssky' : '',
          });
        }
      },
      err => {
        this.allClaims = [];
        this.claims = [];
        console.warn(err);
      },
    );
  }

  public initFilters(type: AnyClaimType): void {
    this.filters = AllClaimsService.getFilters(type);
    this.selectedFilters =
      this.filterService.getStateValue() || AllClaimsService.getDefaultSelectedFilters(type, this.isSmartSortEligible);
  }

  public initSort(type: AnyClaimType): void {
    this.sortByOptions = AllClaimsService.getSortByOptions(type, this.isSmartSortEligible);
    this.selectedSortByOption = AllClaimsService.getSelectedSortOption(this.sortByOptions, this.selectedFilters.sort);
  }

  public changeSortBy(): (sortBy: ISortDropdownOption) => void {
    return (sortBy: ISortDropdownOption) => {
      this.$timeout(() => this.scrollToTop());
      this.selectedSortByOption = sortBy;
      this.selectedFilters.sort.by = sortBy.value;
      this.selectedFilters.sort.reverse = sortBy.reverse;
      this.filterService.updateStateValue(this.selectedFilters).subscribe(() => {
        this.claims = this.filterService.sort(this.claims, AllClaimsService.getSort(this.selectedFilters.sort));
      });
    };
  }

  public resetFilters(): void {
    this.filterService.updateStateValue(undefined).subscribe(() => {
      this.selectedFilters = AllClaimsService.getDefaultSelectedFilters(this.type, this.isSmartSortEligible);
      this.selectedSortByOption = AllClaimsService.getSelectedSortOption(this.sortByOptions, this.selectedFilters.sort);
      this.$scope.$broadcast('allClaims.resetFilters');
      this.initClaims();
    });
  }

  public toggleUsePagination(): void {
    this.usePagination = !this.usePagination;
    if (this.usePagination) {
      this.$timeout(() => this.scrollToTop());
    }
  }

  public downloadClaims(): void {
    ClaimsService.getByType(
      this.type,
      () => downloadHealthcareClaims(this.claims as IClaim[], this.$translate.instant),
      () => downloadFinancialClaims(this.claims as IFinancialClaim[], this.$translate.instant),
    );
  }

  private scrollToTop(): void {
    if (this.$window.pageYOffset > 0) {
      jQuery('html, body').animate({ scrollTop: 0 });
    }
  }

  private setClaimTypes(): void {
    this.types = [];

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => {
          return this.plansService.showCarveoutClaims(profile);
        },
        (profile, showCarveout) => ({ currentUser: profile.currentUser, showCarveout }),
      )
      .flatMap(
        ({ currentUser, showCarveout }) => {
          return this.profileService
            .getHealthcareCoverages(currentUser.rallyId, showCarveout)
            .map(rsp => rsp.data)
            .do(dependentAndClaimsTypes => {
              if (currentUser.relationshipType === RelationshipType.Subscriber) {
                const allClaimTypes = dependentAndClaimsTypes.reduce(
                  (accumulator, item) => [...item.claimTypes, ...accumulator],
                  [],
                );
                const subscriberClaimTypes = allClaimTypes.filter(
                  (value, index, self) => self.indexOf(value) === index,
                );
                this.types.push({ value: ClaimType.Medical, display: this.getClaimTypeLabel(subscriberClaimTypes) });
              } else {
                const user = dependentAndClaimsTypes.find(
                  d => d.dependent.sequenceNumber === currentUser.dependentSeqNum,
                );
                const claimTypes = user && user.claimTypes;
                if (claimTypes) {
                  this.types.push({ value: ClaimType.Medical, display: this.getClaimTypeLabel(claimTypes) });
                }
              }
            });
        },
        rsp => rsp,
      )
      .takeWhile(({ currentUser }) => ProfileService.hasLedgerAccess(currentUser))
      .flatMap(({ currentUser }) => this.ledgerService.getAccounts(currentUser.rallyId))
      .map(rsp => rsp.data.map(account => account.accountType))
      .flatMap(accountTypes => accountTypes, (accountTypes, accountType) => ({ accountTypes, accountType }))
      .distinct(({ accountType }) => accountType)
      .filter(
        ({ accountTypes, accountType }) =>
          accountType !== AccountType.FSALP || !accountTypes.some(at => at === AccountType.FSAHC),
      )
      .map(({ accountType }) => this.typeConfig[accountType])
      .filter(config => !!config)
      .distinct(config => config.display)
      .subscribe(config => this.types.push(config), console.warn);
  }

  private getClaimTypeLabel(claimTypes: CoverageType[]): string {
    const labels = [{ MEDICAL: 'MEDICAL' }, { DENTAL: 'DENTAL' }, { RX: 'PHARMACY' }];
    return labels
      .map(label => claimTypes.reduce((acc, claimType) => label[claimType] || acc, undefined))
      .filter(label => !!label)
      .join('_');
  }
  private getClaimPaymentStatusLabel(payment: ClaimStatus): string {
    const label = {
      [ClaimStatus.Denied]: 'DENIED',
      [ClaimStatus.PartiallyDenied]: 'PARTIALLY_DENIED',
    }[payment];

    if (label === undefined) {
      return '';
    }

    return this.$translate.instant(label);
  }

  private getClaimStatusLabel(payment: ClaimStatus): string {
    const label = {
      [ClaimStatus.Denied]: 'PROCESSED',
      [ClaimStatus.PartiallyDenied]: 'PROCESSED',
    }[payment];

    if (label === undefined) {
      return this.$translate.instant(payment);
    }

    return this.$translate.instant(label);
  }
  private setShowLinkOuts(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .subscribe(({ data: { currentUser } }) => {
        this.showLinkToInternationalClaims =
          isExpatriate(currentUser) && this.featureFlagService.isLinkToInternationalClaimsOn();
        const { coverageTypes } = ProfileService.getCoverageInfo(currentUser.planCoverages);
        this.showVisionLink = coverageTypes[CoverageType.Vision];
      }, console.warn);
  }
}

export class AllClaimsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = AllClaimsController;
    this.templateUrl = allClaimsTemplate;
  }
}
