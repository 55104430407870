import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Feature } from 'scripts/ui/feature/feature';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import {
  selectAllHealthcareData,
  selectAllHealthcareLoading,
  selectAllHealthcareError,
} from 'scripts/selectors/claims-service-selectors';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { getAllHealthcare } from 'scripts/thunks/claims-service-thunks';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { AllClaimsHeader } from './all-claims-header';
import { AllClaimsFilters } from './all-claims-filters';
import { AllClaimsBody } from './all-claims-body';
import {
  selectClientConfigData,
  selectClientConfigError,
  selectClientConfigLoading,
} from 'scripts/selectors/targeting-service-selectors';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { Maybe } from 'scripts/arcade.module.interfaces';
import {
  getHealthcareSortByOptions,
  getSmartSortEligible,
  getSelectedSortOption,
  getSort,
  getPeriod,
  getHealthcareDefaultFilters,
} from './all-claims-utils';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { isExpatriate } from 'scripts/util/user/user';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { ISortDropdownOption } from 'scripts/ui/dropdown/dropdown.interfaces';
import { doSort, getFiltersFromLocation, updateFiltersInLocation } from 'scripts/ui/filter/filter-utils';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { MrClaimTermsExplanationBanner } from './mr-claim-terms-explanation-banner';

interface IAllClaimsProps extends IAllClaimsStateToProps, IAllClaimsDispatchToProps {}

interface IAllClaimsStateToProps {
  claims: Maybe<IClaim[]>;
  clientConfig: Maybe<IClientConfig>;
  claimsError: boolean;
  claimsLoading: boolean;
  clientConfigError: boolean;
  clientConfigLoading: boolean;
  profileUser: IProfileUser;
}

interface IAllClaimsDispatchToProps {
  getAllHealthcare: () => void;
  getClientConfig: () => void;
}

export const RawAllClaims: FunctionComponent<IAllClaimsProps> = props => {
  const {
    claims,
    claimsError,
    claimsLoading,
    clientConfig,
    clientConfigError,
    clientConfigLoading,
    profileUser,
  } = props;
  const { location, history } = useRouter();
  const loading = claimsLoading || clientConfigLoading;
  const error = claimsError || clientConfigError;
  const suppressPayNow = clientConfig && clientConfig.suppressions && clientConfig.suppressions.payNowSuppression;
  const isSmartSortEligible = claims && !suppressPayNow && getSmartSortEligible(claims);
  const selectedFilters = getFiltersFromLocation<IClaim>(location) || getHealthcareDefaultFilters(isSmartSortEligible);
  const sortByOptions = getHealthcareSortByOptions(isSmartSortEligible);
  const selectedSortByOption = getSelectedSortOption(sortByOptions, selectedFilters.sort);
  // We do not have filtering yet so this array is empty
  const filters = [];
  const { startDate, endDate } = getPeriod(filters, selectedFilters);
  const showLinkToInternationalClaims = isExpatriate(profileUser) && FeatureFlagService.isLinkToInternationalClaimsOn();
  const sort = getSort(selectedFilters.sort);
  // We do not have filtering done yet, so just sorting all claims for now
  const filteredClaims = claims && doSort<IClaim>(claims, sort);

  useEffect(() => {
    if (!claims) {
      props.getAllHealthcare();
    }
    if (!clientConfig) {
      props.getClientConfig();
    }
  }, []);

  const handleRetry = (): void => {
    props.getAllHealthcare();
  };

  const handleClaimUpdate = (): void => {
    return undefined;
  };

  const handleSortSelect = (option: ISortDropdownOption): void => {
    updateFiltersInLocation(location, history, {
      ...selectedFilters,
      sort: { by: option.value, reverse: option.reverse },
    });
  };

  return (
    <Feature featureId="all-claims">
      <div className="all-claims" data-testid="all-claims">
        <AllClaimsHeader
          claims={claims}
          filteredClaims={filteredClaims}
          selectedSortByOption={selectedSortByOption}
          sortByOptions={sortByOptions}
          onSortSelect={handleSortSelect}
          startDate={startDate}
          endDate={endDate}
          showLinkToInternationalClaims={showLinkToInternationalClaims}
        />
        <div className="page-container">
          {claims && claims.length !== 0 && (
            <MrClaimTermsExplanationBanner claims={claims}> </MrClaimTermsExplanationBanner>
          )}
          <AllClaimsFilters claims={claims} />
          <AllClaimsBody
            claims={claims}
            loading={loading}
            error={error}
            onRetry={handleRetry}
            onClaimUpdate={handleClaimUpdate}
            clientConfig={clientConfig}
          />
        </div>
      </div>
    </Feature>
  );
};

const mapStateToProps = (state: IReduxState): IAllClaimsStateToProps => ({
  claims: selectAllHealthcareData(state),
  claimsError: selectAllHealthcareError(state),
  claimsLoading: selectAllHealthcareLoading(state),
  clientConfig: selectClientConfigData(state),
  clientConfigError: selectClientConfigError(state),
  clientConfigLoading: selectClientConfigLoading(state),
  profileUser: currentUser.selectProfile(state),
});

export const AllClaims = withProvider(
  connect(
    mapStateToProps,
    { getAllHealthcare, getClientConfig },
  )(RawAllClaims),
);
