import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IdCardForm } from './id-card-form';
import { IdCardGatedTable } from './id-card-gated-table';
import { IdCardTermedMembersTable } from './id-card-termed-members-table';
import { CoverageType } from 'scripts/api/api.interfaces';

interface IIdCardGatedCoverageProps {
  coverage: ICoverageSection;
  users: IProfileUser[];
  isGatedPlan: boolean;
  activePcpPerMember?: { [depSeqNum: string]: string };
  selectedUserUpdate: (selectedUser: IProfileUser['dependentSeqNum']) => void;
}

export const IdCardGatedCoverage: FunctionComponent<IIdCardGatedCoverageProps> = props => {
  const { coverage, users, isGatedPlan, activePcpPerMember, selectedUserUpdate } = props;
  const { t } = useTranslation(Dictionary.ID_CARDS);

  return (
    <div className="col-2-3 coverage-status" data-testid="id-card-gated-coverage">
      <h2 className="coverage-table-header">{t('VIEW_ID_FOR')}</h2>
      <IdCardForm coverage={coverage} users={users} selectedUserUpdate={selectedUserUpdate} />
      <IdCardGatedTable
        coverage={coverage}
        users={users}
        isGatedPlan={isGatedPlan}
        activePcpPerMember={activePcpPerMember}
      />
      <IdCardTermedMembersTable
        coverage={coverage}
        users={users}
        isGatedPlan={isGatedPlan}
        activePcpPerMember={activePcpPerMember}
      />
      {isGatedPlan && coverage.coverageType === CoverageType.Medical && <aside>* {t('PCP_STANDS_FOR')}</aside>}
    </div>
  );
};
