import {
  IClaimsPreferences,
  IProducts,
  IProfile,
  IProfileUser,
  IReferralsPerMember,
  IDependentAndCoverageTypes,
  IPrimaryCarePerMember,
  IPrimaryCareFpc,
} from 'scripts/api/profile/profile.interfaces';

export enum ProfileServiceActionTypes {
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',
  GET_PROFILE_LOADING = 'GET_PROFILE_LOADING',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  CHANGE_SELECTED_PROFILE = 'CHANGE_SELECTED_PROFILE',
  GET_CLAIMS_PREFERENCES_ERROR = 'GET_CLAIMS_PREFERENCES_ERROR',
  GET_CLAIMS_PREFERENCES_LOADING = 'GET_CLAIMS_PREFERENCES_LOADING',
  GET_CLAIMS_PREFERENCES_SUCCESS = 'GET_CLAIMS_PREFERENCES_SUCCESS',
  GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR',
  GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
  GET_REFERRALS_LOADING = 'GET_REFERRALS_LOADING',
  GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS',
  GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR',
  GET_HEALTHCARE_COVERAGES_ERROR = 'GET_HEALTHCARE_COVERAGES_ERROR',
  GET_HEALTHCARE_COVERAGES_LOADING = 'GET_HEALTHCARE_COVERAGES_LOADING',
  GET_HEALTHCARE_COVERAGES_SUCCESS = 'GET_HEALTHCARE_COVERAGES_SUCCESS',
  GET_FPC_PRIMARY_CARE_LOADING = 'GET_FPC_PRIMARY_CARE_LOADING',
  GET_FPC_PRIMARY_CARE_SUCCESS = 'GET_FPC_PRIMARY_CARE_SUCCESS',
  GET_FPC_PRIMARY_CARE_ERROR = 'GET_FPC_PRIMARY_CARE_ERROR',
  GET_PRIMARY_CARE_LOADING = 'GET_PRIMARY_CARE_LOADING',
  GET_PRIMARY_CARE_SUCCESS = 'GET_PRIMARY_CARE_SUCCESS',
  GET_PRIMARY_CARE_ERROR = 'GET_PRIMARY_CARE_ERROR',
}

export interface IGetProfileLoading {
  type: ProfileServiceActionTypes.GET_PROFILE_LOADING;
}

export interface IGetProfileSuccess {
  type: ProfileServiceActionTypes.GET_PROFILE_SUCCESS;
  payload: { profile: IProfile; selectedProfile: IProfileUser };
}

export interface IGetProfileError {
  type: ProfileServiceActionTypes.GET_PROFILE_ERROR;
}

export interface IChangeSelectedProfile {
  type: ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE;
  payload: IProfileUser;
}

export interface IGetClaimsPreferencesLoading {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_LOADING;
}

export interface IGetClaimsPreferencesSuccess {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_SUCCESS;
  payload: IClaimsPreferences;
}

export interface IGetClaimsPreferencesError {
  type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_ERROR;
}

export interface IGetProductsLoading {
  type: ProfileServiceActionTypes.GET_PRODUCTS_LOADING;
}

export interface IGetProductsSuccess {
  type: ProfileServiceActionTypes.GET_PRODUCTS_SUCCESS;
  payload: IProducts;
}

export interface IGetProductsError {
  type: ProfileServiceActionTypes.GET_PRODUCTS_ERROR;
}

export interface IGetReferralsLoading {
  type: ProfileServiceActionTypes.GET_REFERRALS_LOADING;
}

export interface IGetReferralsSuccess {
  type: ProfileServiceActionTypes.GET_REFERRALS_SUCCESS;
  payload: IReferralsPerMember;
}

export interface IGetReferralsError {
  type: ProfileServiceActionTypes.GET_REFERRALS_ERROR;
}

export interface IGetHealthcareCoveragesLoading {
  type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_LOADING;
}

export interface IGetHealthcareCoveragesSuccess {
  type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_SUCCESS;
  payload: IDependentAndCoverageTypes[];
}

export interface IGetHealthcareCoveragesError {
  type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_ERROR;
}

export interface IGetFpcPrimaryCareLoading {
  type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_LOADING;
}

export interface IGetFpcPrimaryCareSuccess {
  type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_SUCCESS;
  payload: IPrimaryCareFpc;
}

export interface IGetFpcPrimaryCareError {
  type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_ERROR;
}

export interface IGetPrimaryCareLoading {
  type: ProfileServiceActionTypes.GET_PRIMARY_CARE_LOADING;
}

export interface IGetPrimaryCareSuccess {
  type: ProfileServiceActionTypes.GET_PRIMARY_CARE_SUCCESS;
  payload: IPrimaryCarePerMember;
}

export interface IGetPrimaryCareError {
  type: ProfileServiceActionTypes.GET_PRIMARY_CARE_ERROR;
}

export function getProfileLoading(): IGetProfileLoading {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_LOADING,
  };
}

export function getProfileSuccess(payload: { profile: IProfile; selectedProfile: IProfileUser }): IGetProfileSuccess {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_SUCCESS,
    payload,
  };
}

export function getProfileError(): IGetProfileError {
  return {
    type: ProfileServiceActionTypes.GET_PROFILE_ERROR,
  };
}

export function changeSelectedProfile(payload: IProfileUser): IChangeSelectedProfile {
  return {
    type: ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE,
    payload,
  };
}

export function getClaimsPreferencesLoading(): IGetClaimsPreferencesLoading {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_LOADING,
  };
}

export function getClaimsPreferencesSuccess(payload: IClaimsPreferences): IGetClaimsPreferencesSuccess {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_SUCCESS,
    payload,
  };
}

export function getClaimsPreferencesError(): IGetClaimsPreferencesError {
  return {
    type: ProfileServiceActionTypes.GET_CLAIMS_PREFERENCES_ERROR,
  };
}

export function getProductsLoading(): IGetProductsLoading {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_LOADING,
  };
}

export function getProductsSuccess(payload: IProducts): IGetProductsSuccess {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_SUCCESS,
    payload,
  };
}

export function getProductsError(): IGetProductsError {
  return {
    type: ProfileServiceActionTypes.GET_PRODUCTS_ERROR,
  };
}

export function getReferralsLoading(): IGetReferralsLoading {
  return {
    type: ProfileServiceActionTypes.GET_REFERRALS_LOADING,
  };
}

export function getReferralsSuccess(payload: IReferralsPerMember): IGetReferralsSuccess {
  return {
    type: ProfileServiceActionTypes.GET_REFERRALS_SUCCESS,
    payload,
  };
}

export function getReferralsError(): IGetReferralsError {
  return {
    type: ProfileServiceActionTypes.GET_REFERRALS_ERROR,
  };
}

export function getHealthcareCoveragesLoading(): IGetHealthcareCoveragesLoading {
  return {
    type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_LOADING,
  };
}

export function getHealthcareCoveragesSuccess(payload: IDependentAndCoverageTypes[]): IGetHealthcareCoveragesSuccess {
  return {
    type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_SUCCESS,
    payload,
  };
}

export function getHealthcareCoveragesError(): IGetHealthcareCoveragesError {
  return {
    type: ProfileServiceActionTypes.GET_HEALTHCARE_COVERAGES_ERROR,
  };
}

export function getFpcPrimaryCareLoading(): IGetFpcPrimaryCareLoading {
  return {
    type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_LOADING,
  };
}

export function getFpcPrimaryCareSuccess(payload: IPrimaryCareFpc): IGetFpcPrimaryCareSuccess {
  return {
    type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_SUCCESS,
    payload,
  };
}

export function getFpcPrimaryCareError(): IGetFpcPrimaryCareError {
  return {
    type: ProfileServiceActionTypes.GET_FPC_PRIMARY_CARE_ERROR,
  };
}

export function getPrimaryCareLoading(): IGetPrimaryCareLoading {
  return {
    type: ProfileServiceActionTypes.GET_PRIMARY_CARE_LOADING,
  };
}

export function getPrimaryCareSuccess(payload: IPrimaryCarePerMember): IGetPrimaryCareSuccess {
  return {
    type: ProfileServiceActionTypes.GET_PRIMARY_CARE_SUCCESS,
    payload,
  };
}

export function getPrimaryCareError(): IGetPrimaryCareError {
  return {
    type: ProfileServiceActionTypes.GET_PRIMARY_CARE_ERROR,
  };
}

export type ProfileServiceAction =
  | IGetProfileLoading
  | IGetProfileSuccess
  | IGetProfileError
  | IChangeSelectedProfile
  | IGetClaimsPreferencesLoading
  | IGetClaimsPreferencesSuccess
  | IGetClaimsPreferencesError
  | IGetProductsLoading
  | IGetProductsSuccess
  | IGetProductsError
  | IGetReferralsLoading
  | IGetReferralsSuccess
  | IGetReferralsError
  | IGetHealthcareCoveragesLoading
  | IGetHealthcareCoveragesSuccess
  | IGetHealthcareCoveragesError
  | IGetFpcPrimaryCareLoading
  | IGetFpcPrimaryCareSuccess
  | IGetFpcPrimaryCareError
  | IGetPrimaryCareLoading
  | IGetPrimaryCareSuccess
  | IGetPrimaryCareError;
