import React, { FunctionComponent } from 'react';
import { ClaimType, IAnyClaim, IClaim, IFinancialClaim } from 'scripts/api/claims/claims.interfaces';
import { AccountType } from 'scripts/api/ledger/ledger.interfaces';
import { getByClaim } from 'scripts/util/claims/claims';
import { ICONS } from 'scripts/util/constants/files';

export interface IClaimIconProps {
  claim: IAnyClaim;
}

export const ClaimIcon: FunctionComponent<IClaimIconProps> = props => {
  const { claim } = props;
  const type = getByClaim(claim, (claim as IClaim).claimType, (claim as IFinancialClaim).accountType);
  let icon: string;

  switch (type) {
    case ClaimType.Medical:
      icon = ICONS['icon-circle-medical-2'];
      break;
    case ClaimType.Dental:
      icon = ICONS['icon-circle-dental-2'];
      break;
    case ClaimType.Rx:
      icon = ICONS['icon-circle-pharmacy-2'];
      break;
    case AccountType.DCSA:
    case AccountType.FSADC:
    case AccountType.FSAHC:
    case AccountType.FSALP:
    case AccountType.HCSA:
    case AccountType.HRA:
    case AccountType.HRAAP:
    case AccountType.HRAPD:
    case AccountType.HRASD:
    case AccountType.MRA:
      icon = ICONS['icon-provider-financial'];
      break;
  }

  return (
    <div className="claim-icon">
      <img src={icon} role="presentation" />
    </div>
  );
};
