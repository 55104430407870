import { LocaleKey } from '../../util/constants/i18n.constants';
import { ILocale, LocaleCountry, LocaleLanguage } from './locale.interfaces';

export const defaultLocale: ILocale = {
  id: `${LocaleLanguage.English}-${LocaleCountry.UnitedStates}`,
  language: LocaleLanguage.English,
  country: LocaleCountry.UnitedStates,
};

export function getSanitizedLocale(locale: string): string {
  if (locale.length !== 5) {
    return defaultLocale.id;
  }

  const separator = locale.substr(2, 1);

  if (separator !== '-' && separator !== '_') {
    return defaultLocale.id;
  }

  const localeArr = locale.split(separator);
  localeArr[0] = localeArr[0].toLowerCase();
  localeArr[1] = localeArr[1].toUpperCase();

  return localeArr.join('-');
}

export function getLanguage(locale: string): LocaleLanguage {
  return getSanitizedLocale(locale).split('-')[0] as LocaleLanguage;
}

export function getCountry(locale: string): LocaleCountry {
  return getSanitizedLocale(locale).split('-')[1] as LocaleCountry;
}

export function getLocale(locale?: string): ILocale {
  locale = locale || localStorage.getItem(LocaleKey) || defaultLocale.id;
  return {
    id: getSanitizedLocale(locale),
    language: getLanguage(locale),
    country: getCountry(locale),
  };
}
