import { CS_WHITE_MAP_LINKS, MR_WHITE_MAP_LINKS } from 'scripts/util/population/population.constants';
import { parse } from 'scripts/util/uri/uri';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { ITrackingService } from '../../api/tracking/tracking.service';
import { IPopulationService } from '../../util/population/population.service';
import { ITrackAttrs } from '../track/track.interfaces';

export class AnchorDirective implements ng.IDirective {
  public scope = false;

  constructor(
    private $state: ng.ui.IStateService,
    private populationService: IPopulationService,
    private trackingService: ITrackingService,
  ) {}
  public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ITrackAttrs): void => {
    if (attrs.uiSref === undefined && (attrs.ngHref !== undefined || attrs.href !== undefined)) {
      const href = attrs.ngHref || attrs.href;
      const anchor = parse(href);
      const hostname = anchor.hostname.indexOf('www.') === 0 ? anchor.hostname.substr(4) : anchor.hostname;
      const isSameAsCurrentHost = hostname === window.location.hostname;
      const isCurrentStateSeeYouLater = this.$state.current.name === 'unauthenticated.seeYouLater';
      const isHttp = anchor.protocol === 'https:' || anchor.protocol === 'http:';
      const pop = this.populationService.getPopulation();
      const lob = pop && pop.lineOfBusiness;
      const isSeeYouLaterRequired =
        !isSameAsCurrentHost &&
        !isCurrentStateSeeYouLater &&
        isHttp &&
        ((lob === LineOfBusiness.MR && !MR_WHITE_MAP_LINKS[hostname]) ||
          (lob === LineOfBusiness.CS && !CS_WHITE_MAP_LINKS[hostname]));
      const isTrackMissing = attrs.track === undefined;
      const isExternalLink = attrs.target === '_blank' && (attrs.noRel === undefined || attrs.noRel === 'false');

      if (isExternalLink) {
        element.attr('rel', 'noopener noreferrer');
      }

      if (isTrackMissing || isSeeYouLaterRequired) {
        element.on('click', e => {
          if (isTrackMissing) {
            this.trackingService.postEvents().subscribe();
          }
          if (isSeeYouLaterRequired) {
            e.preventDefault();
            const url = pop.uri + this.$state.href('unauthenticated.seeYouLater', { destination: href });
            window.open(decodeURIComponent(url));
          }
        });
      }
    }
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($state, populationService, trackingService) => {
      'ngInject';
      return new AnchorDirective($state, populationService, trackingService);
    };
    return directive;
  }
}
