import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import sundaySkyVideoTemplate from 'views/ui/sunday-sky-video.html';
import { ISundaySkyClaimSessionParams } from './sunday-sky-video.interfaces';

export class SundaySkyVideoController implements ng.IController {
  // Bindings
  public endpointUrl: string;
  public groupId: string;
  public onTranscript?: () => (e: CustomEvent<ITranscriptEventDetail>) => void;
  public pageName: string;
  public poster: string;
  public sessionParams: ISundaySkyClaimSessionParams;
  public showSurvey: boolean;
  public token: string;
  public userId: string;

  private iFrameContentObserver: MutationObserver;
  private iFrameDocument: Document;

  constructor(private $element: ng.IAugmentedJQuery, private Environment: IEnvironmentConstants) {
    'ngInject';
  }

  public $onInit(): void {
    const iFrame = this.$element.find('iframe')[0] as HTMLIFrameElement;
    this.iFrameDocument = iFrame.contentWindow.document;
    this.iFrameDocument.open();
    this.iFrameDocument.write('<!doctype html><html><head></head><body></body></html>');
    this.iFrameDocument.close();
    this.iFrameDocument.body.style.margin = '0';
    this.addMetaElement('source', 'portal');
    this.addScript();
    this.addMetaElement('groupId', this.groupId);
    this.addMetaElement('page', this.pageName);
    this.addMetaElement('token', this.token);
    this.addMetaElement('userid', this.userId);
    this.addVideoElement();
    if (this.showSurvey) {
      this.addSurveyElement();
    }
    this.iFrameContentObserver = new MutationObserver(() => {
      this.resizeIFrameToFitContent();
    });
    this.iFrameContentObserver.observe(this.iFrameDocument, { attributes: true, subtree: true });
    this.resizeIFrameToFitContent();
  }

  public $onDestroy(): void {
    const video = this.iFrameDocument.querySelector('video');
    if (video) {
      video.autoplay = false;
    }
    this.iFrameContentObserver.disconnect();
  }

  private addMetaElement(name: string, content: string): void {
    const metaElement = this.iFrameDocument.querySelector(`meta[name="ssky:${name}"]`);
    if (metaElement) {
      if (metaElement.getAttribute('content') !== content) {
        metaElement.setAttribute('content', content);
      }
    } else {
      const element = this.iFrameDocument.createElement('meta');
      element.name = `ssky:${name}`;
      element.content = content || '';
      this.iFrameDocument.head.appendChild(element);
    }
  }

  private addScript(): void {
    const sundaySkySrc = this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_SCRIPT_URL;
    const script = this.iFrameDocument.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = sundaySkySrc;
    this.iFrameDocument.head.appendChild(script);
  }

  private addVideoElement(): void {
    const videoElement = this.iFrameDocument.createElement('sundaysky-video');
    videoElement.setAttribute('id', 'sskyplayer');
    videoElement.setAttribute('endpoint-url', `${this.endpointUrl}/create_video_session`);
    videoElement.setAttribute('pre-roll', this.Environment.CONFIG.ARCADE_WEB_SUNDAY_SKY_LOADING_URL);
    videoElement.setAttribute('session-parameters', JSON.stringify(this.sessionParams));
    if (this.poster) {
      videoElement.setAttribute('poster', this.poster);
    }
    videoElement.style.width = 'calc(100% - 4px)';
    videoElement.style.border = '2px solid #CCCCCC';
    this.iFrameDocument.body.appendChild(videoElement);
    videoElement.addEventListener('transcript', (e: CustomEvent<ITranscriptEventDetail>) => {
      if (this.onTranscript) {
        this.onTranscript()(e);
      }
    });
  }

  private addSurveyElement(): void {
    const surveyElement = this.iFrameDocument.createElement('sundaysky-survey');
    surveyElement.setAttribute('id', 'sskysurvey');
    surveyElement.setAttribute('survey-id', 'uhc');
    surveyElement.setAttribute('player', 'sskyplayer');
    surveyElement.setAttribute('show-at', '0.9');
    this.iFrameDocument.body.appendChild(surveyElement);
  }

  private resizeIFrameToFitContent(): void {
    const iframe = this.$element.find('iframe')[0] as HTMLIFrameElement;
    if (iframe) {
      iframe.height = iframe.contentWindow.document.body.scrollHeight.toString();
    }
  }
}

export class SundaySkyVideoComponent implements ng.IComponentOptions {
  public bindings = {
    endpointUrl: '@',
    groupId: '@',
    onTranscript: '&?',
    pageName: '@',
    poster: '@?',
    sessionParams: '<',
    showSurvey: '<?',
    token: '@',
    userId: '@',
  };
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SundaySkyVideoController;
    this.templateUrl = sundaySkyVideoTemplate;
  }
}

export interface ITranscriptEventDetail {
  captions: string;
  descriptivetranscript: string;
  sessionId: string;
  transcript: string;
  videoFormat: string;
  videoUrl: string;
}
