import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import idx from 'idx';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { isExpatriate, isEmpire } from 'scripts/util/user/user';
import {
  selectClientConfigData,
  selectClientConfigError,
  selectClientConfigLoading,
} from 'scripts/selectors/targeting-service-selectors';
import { getClientConfig } from 'scripts/thunks/targeting-service-thunks';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import {
  selectAccountsData,
  selectAccountsError,
  selectAccountsLoading,
} from 'scripts/selectors/ledger-service-selectors';
import { getAccounts } from 'scripts/thunks/ledger-service-thunks';
import ExpatriateSubmitClaimLinks from './expatriate-submit-claim-links';
import EmpireSubmitClaimLinks from './empire-submit-claim-links';
import DefaultSubmitClaimLinks from './default-submit-claim-links';
import BeforeYouSubmit from './before-you-submit';
import { withWaitFor, IWaitForProps } from 'scripts/hoc/with-wait-for/with-wait-for';
import { Feature, IFeatureProps } from 'scripts/ui/feature/feature';
import HtmlElement from 'scripts/ui/html-element/html-element';

const FeatureWithWaitFor = withWaitFor((props: IFeatureProps) => <Feature {...props} />);

interface ISubmitClaimHeaderStateToProps {
  currentUser: IProfileUser;
  population: IPopulation;
  clientConfig?: IClientConfig;
  clientConfigError?: boolean;
  clientConfigLoading?: boolean;
  accounts?: ILedgerAccount[];
  accountsError?: boolean;
  accountsLoading?: boolean;
}

interface ISubmitClaimHeaderDispatchToProps {
  getClientConfig: () => void;
  getAccounts: () => void;
}

export interface ISubmitClaimHeaderProps
  extends ISubmitClaimHeaderStateToProps,
    ISubmitClaimHeaderDispatchToProps,
    IWaitForProps {}

export const RawSubmitClaimHeader: FunctionComponent<ISubmitClaimHeaderProps> = ({
  accounts,
  accountsError,
  accountsLoading,
  clientConfig,
  clientConfigError,
  clientConfigLoading,
  currentUser,
  population,
  getClientConfig,
  getAccounts,
}) => {
  const load = (): void => {
    if (!clientConfig && !clientConfigLoading) {
      getClientConfig();
    }
    if (!accounts && !accountsLoading) {
      getAccounts();
    }
  };
  useEffect(load, []);

  const { t } = useTranslation(Dictionary.SUBMIT_CLAIM);
  const loading =
    accountsLoading || clientConfigLoading || (!clientConfig && !clientConfigError) || (!accounts && !accountsError);
  const error = accountsError || clientConfigError;

  const customMessage = idx(clientConfig, _ => _.customMessaging.submitClaimCustomMessage);

  const submitClaimLinksProps = {
    currentUser,
    accounts,
    clientConfig,
    population,
  };

  return (
    <FeatureWithWaitFor featureId="submit-claim-header" error={error} loading={loading} onRetry={load}>
      <section className="padded">
        <div className="container submit-claim-header">
          <h1>{t('SUBMIT_CLAIM_HEADER')}</h1>
          <div className="links-container" data-testid="submit-claim-links">
            {isExpatriate(currentUser) && <ExpatriateSubmitClaimLinks {...submitClaimLinksProps} />}
            {isEmpire(currentUser) && <EmpireSubmitClaimLinks {...submitClaimLinksProps} />}
            {!isEmpire(currentUser) && !isExpatriate(currentUser) && (
              <DefaultSubmitClaimLinks {...submitClaimLinksProps} />
            )}
          </div>
          {customMessage && (
            <div className="custom-message" data-testid="submit-claim-custom-message">
              <HtmlElement className="submitted-claims" html={customMessage} />
            </div>
          )}
          <BeforeYouSubmit accounts={accounts} population={population} />
        </div>
      </section>
    </FeatureWithWaitFor>
  );
};

const mapStateToProps = (state: IReduxState): ISubmitClaimHeaderStateToProps => ({
  currentUser: currentUser.selectProfile(state),
  clientConfig: selectClientConfigData(state),
  clientConfigError: selectClientConfigError(state),
  clientConfigLoading: selectClientConfigLoading(state),
  population: state.population,
  accounts: selectAccountsData(state),
  accountsError: selectAccountsError(state),
  accountsLoading: selectAccountsLoading(state),
});

export const SubmitClaimHeader = withProvider(
  connect(
    mapStateToProps,
    { getAccounts, getClientConfig },
  )(RawSubmitClaimHeader),
);
