import chromeContainerTemplate from 'views/chrome/chrome-container.html';

export class ChromeContainerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public transclude = true;

  constructor() {
    this.templateUrl = chromeContainerTemplate;
  }
}
