export class AccessibleOutlinesDirective implements ng.IDirective {
  public restrict = 'A';
  public template = '';
  public link = ($scope: ng.IScope, element: JQuery): void => {
    element
      .addClass('hide-outlines')
      .on('mousedown', () => {
        element.addClass('hide-outlines');
      })
      .on('keydown', (e: JQueryEventObject) => {
        // When tab is pressed, allow for outlines
        if (e.keyCode === 9) {
          element.removeClass('hide-outlines');
        }
      });
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = () => new AccessibleOutlinesDirective();
    return directive;
  }
}
