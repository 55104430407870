import moment from 'moment';
import React, { FunctionComponent, HTMLAttributes, useEffect } from 'react';
import { connect } from 'react-redux';
import { IClaimTotals } from 'scripts/api/claims/claims.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import {
  selectTotalsArcadeDataUpdated,
  selectTotalsData,
  selectTotalsLoading,
  selectTotalsError,
} from 'scripts/selectors/claims-service-selectors';
import { selectedUser } from 'scripts/selectors/profile-service-selectors';
import { getTotals } from 'scripts/thunks/claims-service-thunks';
import CONFIG from 'scripts/util/constants/config';
import FeatureFlagService from 'scripts/util/feature-flag/feature-flag';
import { suppressMRDonut } from 'scripts/util/profile/profile';
import { MrAccountSummaryDetails } from './mr-account-summary-details';
import { MrAccountSummaryDonut } from './mr-account-summary-donut';
import { IAccountSummaryAmount } from '../account-summary/account-summary.interfaces';

interface IMrAccountSummaryClaimTotalsProps
  extends IMrAccountSummaryClaimTotalsStateToProps,
    IMrAccountSummaryClaimTotalsDispatchToProps {}

interface IMrAccountSummaryClaimTotalsStateToProps {
  arcadeDataUpdated: moment.Moment;
  currentProfile: IProfileUser;
  firstName: string;
  totals: IClaimTotals[];
  totalsError: boolean;
  totalsLoading: boolean;
}

interface IMrAccountSummaryClaimTotalsDispatchToProps {
  getTotals: () => Promise<void>;
}

const Div: FunctionComponent<HTMLAttributes<HTMLDivElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

const getBilledItems = (totals?: IClaimTotals[]): IAccountSummaryAmount[] => {
  const balance = totals && totals.length ? totals[0].balance : undefined;
  return [
    {
      name: 'MR_MEDICAL_DISCOUNTS_AND_ADJUSTMENTS_TITLE',
      amount: balance && balance.other ? balance.other.value || 0 : 0,
    },
    {
      name: 'MR_MEDICAL_HEALTH_PLAN_PAYS_TITLE',
      amount: balance && balance.healthPlanPays ? balance.healthPlanPays.value || 0 : 0,
    },
    {
      name: 'MR_MEDICAL_YOUR_SHARE_TITLE',
      amount: balance && balance.patientResponsibility ? balance.patientResponsibility.value || 0 : 0,
    },
  ];
};

export const RawMrAccountSummaryClaimTotals: FunctionComponent<IMrAccountSummaryClaimTotalsProps> = props => {
  const { arcadeDataUpdated, currentProfile, firstName, totals, totalsLoading, totalsError } = props;
  const billedItems = getBilledItems(totals);
  const totalBilledAmount = billedItems ? billedItems.reduce((prev, current) => prev + current.amount, 0) : 0;
  const isTotalNonZero = totalBilledAmount > 0;
  const suppressDonutSection =
    FeatureFlagService.isMRNICEDonutSuppressionOn() &&
    suppressMRDonut(currentProfile, CONFIG.ARCADE_WEB_MR_DONUT_SUPPRESSION_LIST);

  useEffect(() => {
    if (!totals) {
      props.getTotals();
    }
  }, []);

  const handleRetry = (): void => {
    props.getTotals();
  };

  return (
    <div className="container phi-unrestricted" data-testid="mr-account-summary-claim-totals">
      <DivWithWaitFor
        error={totalsError}
        errorMessage="ERR_TOTAL_BILLED"
        loading={totalsLoading}
        onRetry={handleRetry}
        trackData={{ nonZeroTotal: isTotalNonZero }}
        trackLabel="billed-claims"
      >
        {!suppressDonutSection && (
          <>
            <MrAccountSummaryDonut
              arcadeDataUpdated={arcadeDataUpdated}
              billedItems={billedItems}
              firstName={firstName}
              totalBilledAmount={totalBilledAmount}
            />
            <MrAccountSummaryDetails
              arcadeDataUpdated={arcadeDataUpdated}
              billedItems={billedItems}
              firstName={firstName}
              totalBilledAmount={totalBilledAmount}
            />
          </>
        )}
      </DivWithWaitFor>
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IMrAccountSummaryClaimTotalsStateToProps => {
  return {
    arcadeDataUpdated: selectTotalsArcadeDataUpdated(state),
    currentProfile: selectedUser.selectProfile(state),
    firstName: selectedUser.selectFirstName(state),
    totals: selectTotalsData(state),
    totalsError: selectTotalsError(state),
    totalsLoading: selectTotalsLoading(state),
  };
};

export const MRAccountSummaryClaimTotals = connect(
  mapStateToProps,
  { getTotals },
)(RawMrAccountSummaryClaimTotals);
