export interface IStateService {
  getUseSelectedProfile: () => boolean;
}

export class StateService implements IStateService {
  constructor(private $state: ng.ui.IStateService, private $stickyState: ng.ui.extras.IStateService) {
    'ngInject';
  }

  /**
   * Returns true when the current state or any of the inactive states contains the data item { useSelectedProfile: true }. The inactive
   * states contain the background state when a modal shown. This is necessary so the background page will correctly find that data item
   * in either the modal or the background state.
   */
  public getUseSelectedProfile(): boolean {
    return (
      (this.$state.current.data && this.$state.current.data.useSelectedProfile) ||
      this.$stickyState.getInactiveStates().some(s => s.data && s.data.useSelectedProfile)
    );
  }
}
