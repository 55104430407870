import { Subscription } from 'rxjs/Subscription';
import { CoverageStatus, CoverageType, ILink, LinkTarget } from 'scripts/api/api.interfaces';
import { MembershipCategory } from 'scripts/api/profile/profile.interfaces';
import { IProfileService, ProfileService } from 'scripts/api/profile/profile.service';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { ILocaleService } from 'scripts/util/locale/locale.service';

import {
  dentalPdfForm,
  expatMedicalPdfForm,
  oxfordAccidentalInjuryForm,
  oxfordDisabilityQuestionnaire,
  oxfordOptumRxMailOrderForm,
  oxfordPharmacyReimbursementClaimForm,
  oxfordPriorCarrierDeductibleForm,
  oxfordSweatEquityClaimForm,
  oxfordSweatEquityClaimFormSpanish,
  oxfordSweatEquityWaiverSpanish,
  oxfordSweatEquityWaver,
} from 'scripts/util/resource/resource.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';
import submitClaimOtherFormsTemplate from 'views/claims-and-accounts/submit/submit-claim-other-forms.html';

export interface ISubmitClaimOtherFormsLink extends ILink {
  getHref?: () => string;
  show?: boolean;
}

export class SubmitClaimOtherFormsController implements ng.IComponentController {
  public customLinks: ISubmitClaimOtherFormsLink[];
  public linkColumns: ISubmitClaimOtherFormsLink[][];
  public links: ISubmitClaimOtherFormsLink[];

  private localeSubscription: Subscription;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private localeService: ILocaleService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.SUBMIT_CLAIM);

    this.links = [
      {
        getHref: () => this.resourceService.get(dentalPdfForm),
        target: LinkTarget.Blank,
        text: 'DENTAL_CLAIMS_PDF_SUBMISSION_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordPharmacyReimbursementClaimForm),
        target: LinkTarget.Blank,
        text: 'PHARMACY_REIMBURSEMENT_CLAIM_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordOptumRxMailOrderForm),
        target: LinkTarget.Blank,
        text: 'OPTUMRX_MAIL_ORDER_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordAccidentalInjuryForm),
        target: LinkTarget.Blank,
        text: 'ACCIDENTAL_INJURY_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordDisabilityQuestionnaire),
        target: LinkTarget.Blank,
        text: 'DISABILITY_QUESTIONNAIRE',
      },
      {
        getHref: () => this.resourceService.get(oxfordPriorCarrierDeductibleForm),
        target: LinkTarget.Blank,
        text: 'PRIOR_CARRIER_DEDUCTIBLE_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordSweatEquityClaimForm),
        target: LinkTarget.Blank,
        text: 'SWEAT_EQUITY_CLAIM_FORM',
      },
      {
        getHref: () => this.resourceService.get(oxfordSweatEquityWaver),
        target: LinkTarget.Blank,
        text: 'SWEAT_EQUITY_WAIVER',
      },
      {
        getHref: () => this.resourceService.get(oxfordSweatEquityClaimFormSpanish),
        target: LinkTarget.Blank,
        text: 'SWEAT_EQUITY_CLAIM_FORM_SPANISH',
      },
      {
        getHref: () => this.resourceService.get(oxfordSweatEquityWaiverSpanish),
        target: LinkTarget.Blank,
        text: 'SWEAT_EQUITY_WAIVER_SPANISH',
      },
      {
        getHref: () => this.resourceService.get(expatMedicalPdfForm),
        target: LinkTarget.Blank,
        text: 'MEDICAL_CLAIMS_PDF_SUBMISSION_FORM',
      },
    ];

    this.customLinks = [];
  }

  public $onInit(): void {
    this.setLinksHref();

    const clientConfigReq = this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.targetingService.getClientConfig(rallyId))
      .do(({ customLabels }) => {
        const { customFormsLabels } = customLabels;
        if (customFormsLabels) {
          this.customLinks = customFormsLabels.map(({ label, url }) => ({
            href: url,
            show: true,
            target: LinkTarget.Blank,
            text: label,
          }));
          this.updateColumns();
        }
      })
      .do(({ contentOverrides }) => {
        const { customPdfClaimForms } = contentOverrides;
        if (customPdfClaimForms && customPdfClaimForms.DENTAL_PDF) {
          this.links
            .filter(link => link.text === 'DENTAL_CLAIMS_PDF_SUBMISSION_FORM')
            .forEach(link => (link.getHref = () => customPdfClaimForms.DENTAL_PDF));
          this.setLinksHref();
        }
      });

    clientConfigReq.subscribe(() => undefined, console.warn);

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .do(currentUser => {
        const activeOrTermedCoverageTypes = ProfileService.getCoverageTypes(currentUser.planCoverages, [
          CoverageStatus.Active,
          CoverageStatus.Termed,
        ]);
        // links to show only for Oxford members
        this.links
          .filter(
            link =>
              link.text !== 'DENTAL_CLAIMS_PDF_SUBMISSION_FORM' && link.text !== 'MEDICAL_CLAIMS_PDF_SUBMISSION_FORM',
          )
          .forEach(link => (link.show = currentUser.membershipCategory === MembershipCategory.OXFORD));
        // links to show only for Expatriate members
        this.links
          .filter(link => link.text === 'MEDICAL_CLAIMS_PDF_SUBMISSION_FORM')
          .forEach(link => (link.show = currentUser.membershipCategory === MembershipCategory.EXPATRIATE));
        // dental links to show based on conditions
        this.links
          .filter(link => link.text === 'DENTAL_CLAIMS_PDF_SUBMISSION_FORM')
          .forEach(
            link =>
              (link.show =
                currentUser.membershipCategory !== MembershipCategory.EXPATRIATE &&
                this.featureFlagService.isDentalClaimPDFOn() &&
                activeOrTermedCoverageTypes.some(coverageType => coverageType === CoverageType.Dental)),
          );
        this.updateColumns();
      })
      .subscribe(() => undefined, console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .do(() => this.setLinksHref())
      .flatMap(() => clientConfigReq)
      .subscribe(() => undefined, console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public showSection(): boolean {
    return [...this.links, ...this.customLinks].some(link => link.show);
  }

  private setLinksHref(): void {
    this.links.filter(link => link.getHref).forEach(link => (link.href = link.getHref()));
  }

  private updateColumns(): void {
    // Supports a maximum of 15 links
    const availableLinks = [...this.links, ...this.customLinks].filter(link => link.show).slice(0, 15);
    this.linkColumns = [availableLinks.slice(0, 5), availableLinks.slice(5, 10), availableLinks.slice(10, 15)];
  }
}

export class SubmitClaimOtherFormsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = SubmitClaimOtherFormsController;
    this.templateUrl = submitClaimOtherFormsTemplate;
  }
}
