import idx from 'idx';
import { CoverageStatus, CoverageType } from 'scripts/api/api.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IClientConfig, ClaimFormDisplay, ClaimFormType } from 'scripts/api/targeting/targeting.interfaces';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getCoverageInfo } from 'scripts/util/profile/profile';
import { getResource } from 'scripts/util/resource/resource';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { IResource } from 'scripts/util/resource/resource.interfaces';

/*
  Returns href value from targeting if present or default resource otherwise
*/
export const withCustomTargetingPdf = (targetingKey: string, defaultResource?: IResource) => (
  clientConfig: IClientConfig,
  population: IPopulation,
): string | null =>
  idx(clientConfig, _ => _.contentOverrides.customPdfClaimForms[targetingKey]) ||
  (defaultResource && getResource(defaultResource, population));

/*
  Returns boolean value from targeting if present or defaultShow otherwise
*/
export const withTargetingShowOverride = (targetingKey: ClaimFormType, defaultShow: boolean) => (
  clientConfig: IClientConfig,
): boolean => {
  const showFromTargeting = idx(clientConfig, _ => _.suppressions.overrideShowClaimForm[targetingKey]);
  return typeof showFromTargeting === 'string' ? showFromTargeting === ClaimFormDisplay.show : defaultShow;
};

export const hasTargetingSuppressOnlineFSAHRASubmission = (clientConfig: IClientConfig): boolean =>
  !!idx(clientConfig, _ => _.suppressions.suppressOnlineFSAHRASubmission);

export const hasActiveOrTermedCoverage = (coverageType: CoverageType) => (currentUser: IProfileUser): boolean =>
  !!getCoverageInfo(currentUser.planCoverages, [CoverageStatus.Active, CoverageStatus.Termed]).coverageTypes[
    coverageType
  ];

export const hasAccount = (checkAccount: (account: ILedgerAccount) => boolean) => (
  accounts: ILedgerAccount[],
): boolean => accounts.some(checkAccount);
