import { IRootScope } from 'scripts/arcade.module.interfaces';
import { ILocaleService } from 'scripts/util/locale/locale.service';
import angular from 'angular';

export class StateChangeStart {
  constructor(
    $location: ng.ILocationService,
    $rootScope: IRootScope,
    $state: ng.ui.IStateService,
    localeService: ILocaleService,
  ) {
    'ngInject';
    $rootScope.$state = $state;

    // This event is intended to handle modal routing and to support deep-linking for two states:
    // (1) the modal and (2) the underlying page.
    // Example url: "/some/page/state/modal/confirmLogout"
    // Given the example url, we will be navigating to two states. "/some/page/state" will be loaded into the `page`
    // ui-view. "/modal/confirmLogout" will be loaded into the `modal` ui-view - simultaneously. ui-router-extras
    // allows us to be able to do this via sticky states. The `page` parent state is a sticky state.
    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      let path;
      const states = $state.get();
      const toModal: boolean = toState.name.substr(0, 5) === 'modal';
      const fromModal: boolean = $location.path().indexOf('modal/') > -1;
      const queryParams = $location.search();

      // If a locale is found in the url, use it
      if (queryParams && queryParams.locale) {
        localeService.set(queryParams.locale);
        $location.search('locale', undefined).replace();
      }

      // When a user does a hard refresh of a particular location, or navigates to Arcade from an external source
      if (fromState.name === '' && toModal) {
        path = toParams.path;

        // When the path is an empty string, what we really want is the Dashboard (or home page), which is at '/'.
        path = path === '' ? '/dashboard' : path;

        // The event we catch is `$stateChangeStart`. This will prevent the forthcoming events from triggering.
        event.preventDefault();

        // This loop is a search for a `state` by url. The `modal` state gives us a `stateParam`, named `path` which
        // gives us the url we want to use to search for a `page` state that will lay beneath the modal.
        for (const state of states) {
          // In order to find the state name, we need access to the private `$$state()` object.
          if (angular.isDefined(state.$$state) && angular.isDefined(path) && !state.abstract) {
            const privateState = state.$$state();

            // `stateObj.url.exec(url)` returns an object if the state object's `url` matches the requested url.
            const match = privateState.url ? privateState.url.exec(path) : null;

            if (match) {
              // The path we had matched a state. Now, we want to load the state without changing the location bar or
              // finishing the state resolve. We are able to navigate to a sticky state without removing the modal.
              // The match may contain the path parameter from the authenticated abstract state, if it does pass it along
              // to the state so that it may get resolved.
              const toStateParams = match.path ? { path: match.path } : undefined;
              $state.go(privateState.name, toStateParams, { location: false });
            }
          }
        }
      } else if (!fromModal && fromState.url !== '/' && toModal) {
        const fromHrefArr = $state.href(fromState, fromParams).split('?');
        const fromPath = fromHrefArr[0];
        const fromQueryParams = fromHrefArr[1] || '';
        const toHrefArr = $state.href(toState, toParams).split('?');
        const toPath = toHrefArr[0];
        const toQueryParams = toHrefArr[1] || '';
        const queryParamStrings = [];

        if (fromQueryParams) {
          queryParamStrings.push(fromQueryParams);
        }

        if (toQueryParams) {
          queryParamStrings.push(toQueryParams);
        }

        let queryParamString = queryParamStrings.join('&');
        queryParamString = queryParamString ? '?' + queryParamString : '';
        path = fromPath.concat(toPath, queryParamString);
        event.preventDefault();

        $rootScope.$apply(() => {
          $location.url(path);
        });
      }
    });
  }
}
