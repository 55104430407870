import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

export function LoadingDots(): ReactElement {
  const { t } = useTranslation(Dictionary.COMMON);
  return (
    <div className="loading-dots" data-testid="loading-dots">
      <span className="loading-dot" />
      <span className="loading-dot" />
      <span className="loading-dot" />
      <span className="sr-only">{t('LOADING')}</span>
    </div>
  );
}
