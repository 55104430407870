import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IAnyClaim } from 'scripts/api/claims/claims.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { isHealthcareClaim, isVideoAvailable } from 'scripts/util/claims/claims';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { hasShowMerpLabel } from 'scripts/util/targeting/targeting';
import { ClaimVideoExplanation } from './claim-video-explanation';
import { ClaimViewLink } from './claim-view-link';

export interface IClaimMainProps {
  claim: IAnyClaim;
  clientConfig: IClientConfig;
}

export const ClaimMain: FunctionComponent<IClaimMainProps> = props => {
  const { claim, clientConfig } = props;
  const showMerpLabel = hasShowMerpLabel(clientConfig);
  const { providerName } = claim;
  const { t } = useTranslation(Dictionary.COMMON);

  const claimVideoEligibility = isVideoAvailable(claim);
  return (
    <>
      <h3 className="claim-name">
        <span className="sr-only">{t('PROVIDER')}:</span>
        <span data-testid="claim-provider-name">{providerName ? providerName : t('UNKNOWN_PROVIDER')}</span>
      </h3>
      <div className="coverage-type-and-id">
        <ClaimViewLink claim={claim} />
        {isHealthcareClaim(claim) && claim.claimManagementInfo.isMerp && showMerpLabel && (
          <div className="claim-merp-label" data-testid="claim-merp-label">
            {t('MERP_EXECUTIVE_CLAIM')}
          </div>
        )}
        {isHealthcareClaim(claim) && (
          <ClaimVideoExplanation
            claimId={claim.claimId}
            claimVideoEligibility={claimVideoEligibility}
            classes={['hide-mobile']}
          />
        )}
      </div>
      {isHealthcareClaim(claim) && (
        <ClaimVideoExplanation
          claimId={claim.claimId}
          claimVideoEligibility={claimVideoEligibility}
          classes={['hide-desktop']}
        />
      )}
    </>
  );
};
