import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { activate } from 'scripts/util/resource/resource.constants';
import activateBannerTemplate from 'views/chrome/activate-banner.html';
import {
  ActivateGlobalStatus,
  ActivatePlanKey,
  IActivatePlanV7,
  ActivateStepStatus,
  UserFlowData,
} from '../../api/activate/activate.interfaces';
import { IActivateService } from '../../api/activate/activate.service';
import { Aco } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IResource } from '../../util/resource/resource.interfaces';
import { IResourceService } from '../../util/resource/resource.service';
import { StepName } from '../dashboard/single-step-banners/single-step-container.component';

export class ActivateBannerController {
  public activateResourceLink: IResource;
  public showActivateBanner: void | boolean;
  public isCdp: boolean;
  public activateSwitchUrl: string;

  constructor(
    $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private activateService: IActivateService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private resourceService: IResourceService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.activateResourceLink = activate;
  }

  public checkForUnclearedPaperlessAlert(plan: IActivatePlanV7): boolean {
    if (!plan.steps) {
      return false;
    }

    if (
      plan.steps.some(
        step =>
          step.stepState === ActivateStepStatus.unstarted && step.stepName !== StepName.basicCommunicationPreference,
      )
    ) {
      return false;
    }

    const paperless = plan.steps.find(step => step.stepName === StepName.basicCommunicationPreference);

    return !!(
      paperless.stepState === ActivateStepStatus.unstarted &&
      paperless.userFlowTrackingData &&
      paperless.userFlowTrackingData.some(alert => alert === UserFlowData.Reminder)
    );
  }

  private setShowActivateBanner(steps: IActivatePlanV7[]): void {
    const activateFTUEPlanKeys: string[] = [
      ActivatePlanKey.EI,
      ActivatePlanKey.CDP,
      ActivatePlanKey.DP,
      ActivatePlanKey.GATED,
      ActivatePlanKey['NON-GATED-PCP'],
      ActivatePlanKey.MR,
    ];
    this.showActivateBanner = steps.some(activateSteps => {
      return (
        activateFTUEPlanKeys.some(key => key === activateSteps.planKey) &&
        activateSteps.status === ActivateGlobalStatus.started &&
        !this.checkForUnclearedPaperlessAlert(activateSteps)
      );
    });
  }

  public $onInit(): void {
    this.showActivateBanner = false;
    if (this.featureFlagService.isActivateSwitchOn()) {
      this.userService
        .getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .flatMap(profile => this.activateService.getStepsSwitch(profile.rallyId))
        .subscribe(rsp => {
          if (rsp.data.finishNowBanner) {
            this.activateSwitchUrl = rsp.data.finishNowBanner.url;
            this.showActivateBanner = true;
          }
        });
    } else {
      this.userService
        .getHeartbeat()
        .let(this.profileService.toCurrentProfile())
        .takeWhile(profile => profile.memberFeatures.activateEligible)
        .do(profile => {
          this.isCdp = profile.acos.some(aco => aco === Aco.CDP || aco === Aco.DP);
        })
        .flatMap(profile => this.activateService.getStepsV7(profile.rallyId))
        .subscribe(rsp => {
          this.setShowActivateBanner(rsp.data.plans);
        });
    }
  }

  public toActivateInternal(): void {
    if (this.activateSwitchUrl) {
      this.$window.location.href = this.activateSwitchUrl;
    } else {
      const activateLink = this.resourceService.get(this.activateResourceLink);
      this.userService.internalSimpleRedirect(activateLink);
    }
  }
}

export class ActivateBannerComponent implements ng.IComponentOptions {
  public templateUrl: string;
  public controller: any;
  constructor() {
    this.templateUrl = activateBannerTemplate;
    this.controller = ActivateBannerController;
  }
}
