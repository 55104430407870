import { ClaimsServiceActionTypes } from 'scripts/actions/claims-service-actions';
import { ProfileServiceActionTypes } from 'scripts/actions/profile-service-actions';
import { IClaim, IClaimTotals } from 'scripts/api/claims/claims.interfaces';
import { ArcadeAction, IStateData } from './reducer.interfaces';

export interface IClaimsState {
  allHealthcare: IStateData<IClaim[]>;
  summary: IStateData<IClaim[]>;
  totals: IStateData<IClaimTotals[]>;
}

export const initialState: IClaimsState = {
  allHealthcare: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  summary: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  totals: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: IClaimsState = initialState, action: ArcadeAction): IClaimsState => {
  switch (action.type) {
    case ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_SUCCESS:
      return {
        ...state,
        allHealthcare: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_LOADING:
      return {
        ...state,
        allHealthcare: {
          ...state.allHealthcare,
          loading: true,
        },
      };
    case ClaimsServiceActionTypes.GET_ALL_HEALTHCARE_ERROR:
      return {
        ...state,
        allHealthcare: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          arcadeDataUpdated: action.payload.arcadeDataUpdated,
          data: action.payload.summary,
          error: false,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_SUMMARY_LOADING:
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true,
        },
      };
    case ClaimsServiceActionTypes.GET_SUMMARY_ERROR:
      return {
        ...state,
        summary: {
          arcadeDataUpdated: undefined,
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_TOTALS_SUCCESS:
      return {
        ...state,
        totals: {
          arcadeDataUpdated: action.payload.arcadeDataUpdated,
          data: action.payload.totals,
          error: false,
          loading: false,
        },
      };
    case ClaimsServiceActionTypes.GET_TOTALS_LOADING:
      return {
        ...state,
        totals: {
          ...state.totals,
          loading: true,
        },
      };
    case ClaimsServiceActionTypes.GET_TOTALS_ERROR:
      return {
        ...state,
        totals: {
          arcadeDataUpdated: undefined,
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case ProfileServiceActionTypes.CHANGE_SELECTED_PROFILE:
      return {
        ...state,
        summary: {
          ...state.summary,
          data: undefined,
        },
        totals: {
          ...state.totals,
          data: undefined,
        },
      };
    // The following is a placeholder case. Once All Claims are stored in Redux state, this case should
    // be re-written to find the claim with the matching claimKey and update its markAsPaid boolean
    // (either by doing so directly or prompting another getAllClaims call)
    case ClaimsServiceActionTypes.SAVE_CLAIM_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
