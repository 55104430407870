import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from '../../../api/profile/profile.interfaces';
import moment from 'moment';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getMatchingPlanPeriod, getCoverageStatus } from 'scripts/util/plans/plans';

interface IIdCardCoveragePeriodProps {
  coverage: ICoverageSection;
  user: IProfileUser;
}

export const IdCardCoveragePeriod: FunctionComponent<IIdCardCoveragePeriodProps> = props => {
  const { coverage, user } = props;
  const { t } = useTranslation(Dictionary.ID_CARDS);

  return (
    <>
      <td>
        <div className="coverage-label">{t('COVERAGE_START')}</div>
        <div className="coverage-value">
          {moment(getMatchingPlanPeriod(coverage, user).startDate).format('MM/DD/YY')}
        </div>
      </td>
      <td>
        <div className="coverage-label">{t('COVERAGE_STATUS')}</div>
        <div className="coverage-value">
          {t(getCoverageStatus(coverage, user), {
            date: moment(getMatchingPlanPeriod(coverage, user).endDate).format('MM/DD/YY'),
          })}
        </div>
      </td>
    </>
  );
};
