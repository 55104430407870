import React, { FunctionComponent, KeyboardEvent } from 'react';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { Feature } from 'scripts/ui/feature/feature';
import { getUsersWithCoverage, hasTermedCoverage } from 'scripts/util/plans/plans';
import { IdCardTrackedInput } from './id-card-tracked-input';
import { getFullName } from 'scripts/util/profile/profile';

interface IIdCardFormProps {
  coverage: ICoverageSection;
  users: IProfileUser[];
  selectedUserUpdate: (selectedUser: IProfileUser['dependentSeqNum']) => void;
}

export const IdCardForm: FunctionComponent<IIdCardFormProps> = props => {
  const { coverage, users, selectedUserUpdate } = props;

  const selectUserWithKeyboard = (
    user: IProfileUser,
    coverage: ICoverageSection,
    event: KeyboardEvent<HTMLDivElement>,
  ): void => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      if (coverage.selectedDepSeqNum !== user.dependentSeqNum) {
        selectedUserUpdate(user.dependentSeqNum);
      }
    }
  };

  return (
    <form data-testid="id-card-form">
      <Feature featureId="member-id-selector">
        <fieldset>
          {getUsersWithCoverage(coverage, users).map(
            user =>
              !hasTermedCoverage(coverage, user) && (
                <div
                  key={'form-' + coverage.planType + user.dependentSeqNum}
                  className="radio-btn-container"
                  tabIndex={0}
                  role="button"
                  onKeyPress={e => selectUserWithKeyboard(user, coverage, e)}
                >
                  <IdCardTrackedInput user={user} coverage={coverage} selectedUserUpdate={selectedUserUpdate} />
                  <label htmlFor={coverage.coverageType + '-radio-' + user.dependentSeqNum}>
                    <span className="sr-only">{getFullName(user.userInfo)}</span>
                  </label>
                </div>
              ),
          )}
        </fieldset>
      </Feature>
    </form>
  );
};
