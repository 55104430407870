import { getMoneyValue } from 'scripts/util/money/money';
import { AccountType, ILedgerAccount } from '../../../api/ledger/ledger.interfaces';
import { ILedgerService } from '../../../api/ledger/ledger.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export class HraapController implements ng.IComponentController {
  public account: ILedgerAccount;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private ledgerService: ILedgerService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);

    userService
      .getHeartbeat()
      .flatMap(rsp => ledgerService.getAccounts(rsp.data.rallyId))
      .flatMap(rsp => rsp.data)
      .first(account => account.isActive && account.accountType === AccountType.HRAAP)
      .subscribe(account => (this.account = account), console.warn);
  }

  public getMoneyValue(amount: number): string {
    return getMoneyValue(amount || 0);
  }
}
