import { accumulatorPlanDocuments } from 'scripts/util/resource/resource.constants';
import {
  BENEFIT_TYPE,
  BenefitNetwork,
  BenefitPaymentType,
  BenefitTypeCopyMap,
} from '../../../api/plans/plans.interfaces';
import eiMedicalAccountExplanationTemplate from 'views/modals/ei-medical-account-explanation.html';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IEIMedicalAccountParams extends ng.ui.IStateParamsService {
  network: BenefitNetwork;
  paymentType: BenefitPaymentType;
}

export class EIMedicalAccountExplanationController implements ng.IComponentController {
  public customLabel: string;
  public customDefinition: string;
  public paymentTypeLabel: string;
  private paymentType: BenefitPaymentType;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $stateParams: IEIMedicalAccountParams,
    private resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .subscribe(clientConfig => {
        const network = this.$stateParams.network;
        this.paymentType = this.$stateParams.paymentType;
        this.paymentTypeLabel = this.getPaymentTypeLabel(this.paymentType);
        const customLabels = clientConfig.customLabels.customAccumulatorLabels || {};
        const customDefinitions = clientConfig.contentOverrides.customAccumulatorDefinition || {};
        this.customLabel =
          Object.prototype.hasOwnProperty.call(customLabels, network) &&
          Object.prototype.hasOwnProperty.call(customLabels[network], this.paymentType)
            ? customLabels[network][this.paymentType]
            : undefined;
        this.customDefinition =
          Object.prototype.hasOwnProperty.call(customDefinitions, network) &&
          Object.prototype.hasOwnProperty.call(customDefinitions[network], this.paymentType)
            ? customDefinitions[network][this.paymentType]
            : undefined;
      }, console.warn);
  }

  public getTrackFeature(feature: string): string {
    return `${feature}-${this.paymentTypeLabel}`;
  }

  public getLink(): string | undefined {
    switch (this.paymentType) {
      case BenefitPaymentType.FamilyCopayMax:
      case BenefitPaymentType.FamilyOopMax2:
      case BenefitPaymentType.IndividualCopayMax:
      case BenefitPaymentType.IndividualOopMax2:
        return this.resourceService.get(accumulatorPlanDocuments);
      default:
        return undefined;
    }
  }

  private getPaymentTypeLabel(paymentType: BenefitPaymentType): string {
    const benefitPaymentType = BENEFIT_TYPE[paymentType] ? BENEFIT_TYPE[paymentType].benefit : paymentType;
    return BenefitTypeCopyMap[benefitPaymentType] || '';
  }
}

export class EIMedicalAccountExplanationComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = EIMedicalAccountExplanationController;
    this.templateUrl = eiMedicalAccountExplanationTemplate;
  }
}
