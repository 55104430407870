import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import IconCircleAllSaved from 'images/icons/icon-circle-all-saved.svg';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import { connectSaved } from 'scripts/util/resource/resource.constants';
import { PcpStripBaseTile } from './pcp-strip-base-tile';

interface IPcpStripFindProvidersTileProps {
  population: IPopulation;
}

const ViewSavedLinkWithClickTracking = withClickTracking(Link, 'view-saved');

export const PcpStripFindProvidersTile: FunctionComponent<IPcpStripFindProvidersTileProps> = props => {
  const { population } = props;
  const { t } = useTranslation(Dictionary.PCP);
  return (
    <PcpStripBaseTile providerTypeTranslationKey={'SAVED'}>
      <IconCircleAllSaved className="type-icon" aria-hidden="true" />
      <ViewSavedLinkWithClickTracking
        aria-labelledby="providers"
        className="text centered"
        data-testid="find-providers-tile-view-saved-link"
        to={{ pathname: '/internal-redirect', search: `?deepLink=${getResource(connectSaved, population)}` }}
      >
        {t('FIND_AND_SAVE')}
      </ViewSavedLinkWithClickTracking>
    </PcpStripBaseTile>
  );
};
