import { Dictionary } from '../../util/constants/i18n.constants';
import { IActiveRangeValues, IRolodexCard, IRolodexController } from './rolodex.interfaces';

export class RolodexController implements IRolodexController {
  public remoteCtrl: IRolodexController;
  public cards: IRolodexCard[];
  public cardsPerPage: number;
  public activePage: number;
  public activeRange: string;
  public titleKey: string;

  constructor(private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.remoteCtrl = this;
    this.cards = [];
    this.activePage = 1;
    this.cardsPerPage = this.cardsPerPage || 3;
    this.titleKey = this.titleKey;
  }

  public add(card: IRolodexCard): IRolodexCard {
    const i = (card.$index = this.cards.length);
    card.scope.$active = this.isActive(card.$index);
    this.cards.push(card);
    this.setActiveRange();
    return this.cards[i];
  }

  public clear(): void {
    this.cards = [];
  }

  public isActive(index: number): boolean {
    const lastActiveIndex = this.getLastActiveIndex();
    const firstActiveIndex = this.getFirstActiveIndex();
    return index >= firstActiveIndex && index <= lastActiveIndex;
  }

  public onLastPage(): boolean {
    const numPages = Math.ceil(this.cards.length / this.cardsPerPage);
    return this.activePage >= numPages;
  }

  public nextPage(): void {
    const numPages = Math.ceil(this.cards.length / this.cardsPerPage);
    this.activePage = this.activePage < numPages ? this.activePage + 1 : this.activePage;
    this.setActiveRange();
  }

  public prevPage(): void {
    this.activePage = this.activePage > 1 ? this.activePage - 1 : this.activePage;
    this.setActiveRange();
  }

  public getNextActiveRangeMessage(): string {
    if (!this.onLastPage()) {
      return 'CAROUSEL_NEXT';
    }
  }

  public getNextActiveRangeValues(): IActiveRangeValues {
    const lastActiveNum = this.getLastActiveIndex() + 1;
    const fromNum = lastActiveNum + 1;
    const total = this.cards.length;
    const toNum = Math.min(lastActiveNum + this.cardsPerPage, total);
    return {
      fromNum,
      total,
      toNum,
    };
  }

  public getPrevActiveRangeMessage(): string {
    if (this.activePage > 1) {
      return 'CAROUSEL_PREV';
    }
  }

  public getPrevActiveRangeValues(): IActiveRangeValues {
    const fromNum = this.getFirstActiveIndex() - this.cardsPerPage + 1;
    const total = this.cards.length;
    const toNum = fromNum + this.cardsPerPage - 1;
    return {
      fromNum,
      total,
      toNum,
    };
  }

  private getLastActiveIndex(): number {
    const lastPossibleActiveIndex = this.cardsPerPage * this.activePage - 1;
    const lastCardIndex = this.cards.length - 1;
    return lastCardIndex < lastPossibleActiveIndex ? lastCardIndex : lastPossibleActiveIndex;
  }

  private getFirstActiveIndex(): number {
    return this.cardsPerPage * this.activePage - this.cardsPerPage;
  }

  private setActiveRange(): void {
    const fromNum = this.getFirstActiveIndex() + 1;
    const toNum = this.getLastActiveIndex() + 1;

    this.activeRange = `${fromNum}-${toNum}`;

    this.cards.forEach(card => {
      card.scope.$active = this.isActive(card.$index);
    });
  }
}
