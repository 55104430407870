import React, { FunctionComponent } from 'react';
import { contactUs } from 'scripts/util/resource/resource.constants';
import { IProfileUser, LineOfBusiness } from '../../api/profile/profile.interfaces';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { connect } from 'react-redux';
import { ProfileService } from 'scripts/api/profile/profile.service';
import { CoverageStatus } from 'scripts/api/api.interfaces';
import { ResourceAnchor } from 'scripts/ui/anchor/resource-anchor';
import IconAlert from 'images/icons/icon-alert.svg';

export interface ITermedMemberBannerProps {
  currentUser: IProfileUser;
}

export const RawTermedMemberBanner: FunctionComponent<ITermedMemberBannerProps> = props => {
  const { currentUser } = props;
  const { t } = useTranslation([Dictionary.EXPIRATION_WARNING, Dictionary.CONTACT_US]);
  if (
    currentUser &&
    currentUser.lineOfBusiness === LineOfBusiness.MR &&
    ProfileService.getMemberStatus(currentUser) === CoverageStatus.Termed
  ) {
    return (
      <div className="expiration-warning">
        <div className="container">
          <IconAlert className="icon-alert" aria-hidden="true" />
          <div className="warning-container">
            <span className="warning-message">
              {t(`${Dictionary.EXPIRATION_WARNING}:TERMED_MR_MEMBER_HEADER`)}
              <div className="warning-subheader">
                <ResourceAnchor className="warning-subheader-link" resourceValue={contactUs}>
                  {t(`${Dictionary.CONTACT_US}:CONTACT_US`)}
                </ResourceAnchor>
                &nbsp;{t(`${Dictionary.EXPIRATION_WARNING}:TERMED_MR_MEMBER_SUBHEADER`)}
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: IReduxState): ITermedMemberBannerProps => {
  return {
    currentUser: currentUser.selectProfile(state),
  };
};

export const TermedMemberBanner = withProvider(connect(mapStateToProps)(RawTermedMemberBanner));
