import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { getFullName, getRelationshipType } from 'scripts/util/profile/profile';
import { useTranslation } from 'react-i18next';
import { ICoverageSection } from './id-cards.interfaces';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import classNames from 'classnames';
import moment from 'moment';
import { hasCoverage, hasActiveCoverage } from 'scripts/util/plans/plans';
import { IdCardCoveragePeriod } from './id-card-coverage-period';

interface IIdCardCoverageTableProps {
  coverage: ICoverageSection;
  users: IProfileUser[];
}

export const IdCardCoverageTable: FunctionComponent<IIdCardCoverageTableProps> = props => {
  const { coverage, users } = props;
  const { t } = useTranslation([Dictionary.ID_CARDS, Dictionary.COMMON]);

  return (
    <div className="col-2-3 col-last coverage-status" data-testid="id-card-coverage-table">
      <h2 className="coverage-table-header">{t(`${Dictionary.ID_CARDS}:MEMBERS`)}</h2>
      <table role="presentation">
        <tbody>
          <tr className="sr-only">
            <th>{t(`${Dictionary.COMMON}:NAME`)}</th>
          </tr>
          {users.map(
            user =>
              hasCoverage(coverage, user) && (
                <tr
                  key={'details-' + user.userInfo.age + user.userInfo.dob + coverage.coverageType}
                  className={classNames({ inactive: !hasActiveCoverage(coverage, user) })}
                  data-testid="has-coverage"
                >
                  <td>
                    <div className="member-name">{getFullName(user.userInfo)}</div>
                    <div className="member-type-dob">
                      <span>{t(`${Dictionary.COMMON}:${getRelationshipType(user, coverage)}`)} </span>-{' '}
                      <span>{t(`${Dictionary.COMMON}:DOB`)}</span>
                      <span> {moment(user.userInfo.dob).format('MM/DD/YYYY')}</span>
                    </div>
                  </td>
                  <IdCardCoveragePeriod coverage={coverage} user={user} />
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  );
};
