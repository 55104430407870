import { getAccountsError, getAccountsLoading, getAccountsSuccess } from 'scripts/actions/ledger-service-actions';
import { LedgerApi } from 'scripts/api/ledger/ledger-api';
import { ArcadeThunkAction } from 'scripts/reducers/reducer.interfaces';
import { selectRallyId } from 'scripts/selectors/user-service-selectors';

export function getAccounts(): ArcadeThunkAction<void> {
  return async (dispatch, getState) => {
    try {
      dispatch(getAccountsLoading());
      const rallyId = selectRallyId(getState());
      const { data } = await LedgerApi.getAccounts(rallyId);
      dispatch(getAccountsSuccess(data));
    } catch (error) {
      dispatch(getAccountsError());
    }
  };
}
