import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IFinancialClaim } from 'scripts/api/claims/claims.interfaces';
import { getClaimAccountAbbreviation } from 'scripts/util/claims/claims';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { ClaimBalance } from './claim-balance';

export interface IClaimBalancesFinancialProps {
  claim: IFinancialClaim;
}

export const ClaimBalancesFinancial: FunctionComponent<IClaimBalancesFinancialProps> = props => {
  const {
    claim: {
      balance: { amountPaid, amountPending, amountSubmitted, paidWithDebitCard },
      accountType,
    },
  } = props;
  const { t } = useTranslation(Dictionary.ALL_CLAIMS);
  return (
    <div className="claim-balances" data-testid="claim-balances-financial">
      <ClaimBalance
        className="claim-submitted"
        label={t('SUBMITTED')}
        term="AMOUNT_SUBMITTED"
        value={amountSubmitted.value}
      />
      <ClaimBalance
        className="claim-paid"
        label={t('ACCOUNT_PAID', { claimType: getClaimAccountAbbreviation(accountType) })}
        term="AMOUNT_PAID"
        value={amountPaid.value}
      />
      <ClaimBalance className="claim-pending" label={t('PENDING')} term="AMOUNT_PENDING" value={amountPending.value} />
      {paidWithDebitCard && (
        <div className="claim-debit-card claim-balance" data-testid="claim-debit-card">
          <div className="claim-balance-value">
            <div className="_claim-balance-value">
              {t('PAID_USING_ACCOUNT_DEBIT_CARD', { claimType: getClaimAccountAbbreviation(accountType) })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
