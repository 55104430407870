import moment from 'moment';
import dateSelectorTemplate from 'views/ui/date-selector.html';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IDropdownOption } from '../dropdown/dropdown.interfaces';

export class DateSelectorController implements ng.IComponentController {
  // Bindings
  public selectedDate: moment.Moment;
  public onChange: () => void;
  public labelSelected: string;

  public dropdownDictionary: string;
  public selectedDayOption: IDropdownOption;
  public selectedMonthOption: IDropdownOption;
  public selectedYearOption: IDropdownOption;
  public dayOptions: IDropdownOption[];
  public monthOptions: IDropdownOption[];
  public yearOptions: IDropdownOption[];
  private minYear = 1900;
  private maxYear = moment().get('year');
  private monthLabels: string[] = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];

  constructor(
    private $scope: ng.IScope,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.DATE_SELECTOR);

    this.dropdownDictionary = Dictionary.DATE_SELECTOR;

    this.selectedDate = moment.isMoment(this.selectedDate) ? this.selectedDate : moment(this.selectedDate);
  }

  public $onInit(): void {
    this.$scope.$watch(() => this.selectedDate.toISOString(), (newVal, oldVal) => newVal !== oldVal && this.init());

    this.init();
  }

  public changeDay = (dayOption: IDropdownOption): void => {
    this.selectedDate.date(parseInt(dayOption.value, 10));
    this.selectedDayOption = dayOption;
    if (this.onChange) {
      this.onChange();
    }
    this.$scope.$apply();
  };

  public changeMonth = (monthOption: IDropdownOption): void => {
    this.selectedDate.month(parseInt(monthOption.value, 10));
    this.selectedMonthOption = monthOption;
    this.setDayOptions();
    this.setYearOptions();
    this.setSelectedDay();
    this.setSelectedYear();
    if (this.onChange) {
      this.onChange();
    }
    this.$scope.$apply();
  };

  public changeYear = (yearOption: IDropdownOption): void => {
    this.selectedDate.year(parseInt(yearOption.value, 10));
    this.selectedYearOption = yearOption;
    this.setDayOptions();
    this.setMonthOptions();
    this.setSelectedDay();
    this.setSelectedMonth();
    if (this.onChange) {
      this.onChange();
    }
    this.$scope.$apply();
  };

  private init(): void {
    this.setDayOptions();
    this.setMonthOptions();
    this.setYearOptions();

    this.setSelectedDay();
    this.setSelectedMonth();
    this.setSelectedYear();
  }

  private setSelectedDay(): void {
    this.selectedDayOption = this.findOption(this.dayOptions, this.selectedDate.date().toString());
  }

  private setSelectedMonth(): void {
    this.selectedMonthOption = this.findOption(this.monthOptions, this.selectedDate.month().toString());
  }

  private setSelectedYear(): void {
    this.selectedYearOption = this.findOption(this.yearOptions, this.selectedDate.year().toString());
  }

  private setDayOptions(): void {
    const daysInMonth = this.selectedDate.daysInMonth();
    this.dayOptions = [];
    for (let x = 1; x <= daysInMonth; x++) {
      this.dayOptions.push(this.getDayOption(x));
    }
  }

  private setMonthOptions(): void {
    this.monthOptions = [];
    for (let x = 0; x < this.monthLabels.length; x++) {
      this.monthOptions.push(this.getMonthOption(x));
    }
  }

  private setYearOptions(): void {
    this.yearOptions = [];
    for (let x = this.maxYear; x >= this.minYear; x--) {
      this.yearOptions.push(this.getYearOption(x));
    }
  }

  private getDayOption(day: number): IDropdownOption {
    return {
      label: (day < 10 ? '0' : '') + day.toString(),
      value: day.toString(),
    };
  }

  private getMonthOption(month: number): IDropdownOption {
    return {
      label: this.monthLabels[month],
      value: month.toString(),
    };
  }

  private getYearOption(year: number): IDropdownOption {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  }

  private findOption(options: IDropdownOption[], value: string): IDropdownOption | undefined {
    for (const option of options) {
      if (option.value === value) {
        return option;
      }
    }
  }

  private getLabelSelected(datePart: string): string | null {
    if (!this.labelSelected) {
      return null;
    }

    return `${this.labelSelected} - ${datePart}`;
  }
}

export class DateSelectorComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    maxDate: '<?',
    minDate: '<?',
    selectedDate: '<',
    labelSelected: '<',
    onChange: '&?',
  };

  constructor() {
    this.controller = DateSelectorController;
    this.templateUrl = dateSelectorTemplate;
  }
}
