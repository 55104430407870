import {
  BENEFIT_TYPE,
  BenefitNetwork,
  IBenefit,
  IBenefitAmount,
  ICoverageParams,
  INDIVIDUAL,
  IPlanAccumulators,
  IPlanBenefits,
  IPlanVariationParams,
} from 'scripts/api/plans/plans.interfaces';
import {
  CoverageStatus,
  CoverageType,
  CoverageTypeCode,
  RelationshipType,
  ICoverageTimePeriod,
} from 'scripts/api/api.interfaces';
import {
  IProfile,
  IProfileUser,
  IPlanCoverage,
  ProgramType,
  LineOfBusiness,
  IProducts,
} from 'scripts/api/profile/profile.interfaces';
import { IBenefitsSpending } from 'scripts/ui/accumulator/accumulator.interfaces';
import { concatPvrc } from 'scripts/util/user/user';
import { ICoverageSection } from 'scripts/features/modals/id-cards/id-cards.interfaces';

const CoverageStatusEnded = 'COVERAGE_STATUS_ENDED';
const CoverageStatusNotStarted = 'COVERAGE_STATUS_NOT_STARTED';
const CoverageStatusActive = 'COVERAGE_STATUS_ACTIVE';

export function getAllPossibleCoverages(planCoverages: IPlanCoverage[]): IPlanCoverage[] {
  const coverageTypes: { [type: string]: boolean } = {};
  for (let i = 0; i < planCoverages.length; i++) {
    const coverageType = planCoverages[i].coverageType;
    if (coverageTypes[coverageType]) {
      planCoverages.splice(i, 1);
      i--;
    } else {
      coverageTypes[coverageType] = true;
    }
  }
  return planCoverages;
}

export function getAllCoveragesFromUsers(currentProfile: IProfileUser, users: IProfileUser[]): IPlanCoverage[] {
  let planCoverages = [];
  for (const user of users) {
    planCoverages = planCoverages.concat(user.planCoverages);
  }
  if (currentProfile.lineOfBusiness === LineOfBusiness.EI) {
    // We want a list of all possible coverages among all users, so here we remove duplicates by coverageType
    planCoverages = getAllPossibleCoverages(planCoverages);
  }
  return planCoverages;
}

export function getMatchingPlanPeriod(coverage: IPlanCoverage, user: IProfileUser): ICoverageTimePeriod {
  if (user.planCoverages && user.planCoverages.length > 0) {
    for (const plan of user.planCoverages) {
      if (
        plan.coverageType === coverage.coverageType &&
        plan.coverageTypeCode === coverage.coverageTypeCode &&
        plan.planPeriod
      ) {
        return plan.planPeriod;
      }
    }
  }
}

export function getCoverageStatus(coverage: IPlanCoverage, user: IProfileUser): string {
  switch (getMatchingPlanPeriod(coverage, user).status) {
    case CoverageStatus.Active:
      return CoverageStatusActive;
    case CoverageStatus.Future:
      return CoverageStatusNotStarted;
    case CoverageStatus.Termed:
      return CoverageStatusEnded;
  }
}

export function hasActiveUser(coverage: ICoverageSection, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status === CoverageStatus.Active);
}

export function hasActiveOrFutureUser(coverage: ICoverageSection, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status !== CoverageStatus.Termed);
}

export function getGroupNumber(coverage: ICoverageSection, currentProfile: IProfileUser): string | undefined {
  const isCS = currentProfile.lineOfBusiness === LineOfBusiness.CS;
  const reportingCodePlanIds = ['0FLDSNP', '0OHDSNP'];
  const showReportingCodeAsGroupId =
    reportingCodePlanIds.indexOf(currentProfile.userInfo.primaryCustomerId) !== -1 && isCS;

  if (showReportingCodeAsGroupId) {
    return currentProfile.pvrc ? currentProfile.pvrc.reportingCode : undefined;
  } else {
    return coverage.policyNumber;
  }
}

export function getLabelFromCoverageType(coverageType: CoverageType): string {
  return coverageType === CoverageType.Rx ? 'PRESCRIPTION_DRUG' : coverageType;
}

export function getLabelFromCoverage(coverage: ICoverageSection): string {
  if (coverage.planFeatures.isSupplemental) {
    return coverage.planFeatures.programType === ProgramType.Ship ? 'HEALTH_INSURANCE_CARD' : 'SUPPLEMENTAL_MEDICAL';
  } else {
    return getLabelFromCoverageType(coverage.coverageType);
  }
}

export function getMedicalAndNonMedicalCoverages(planCoverages: IPlanCoverage[]): [IPlanCoverage[], IPlanCoverage[]] {
  const [medicalCoverage, nonMedicalCoverage] = planCoverages.reduce(
    (result, cov) => {
      result[cov.coverageType === CoverageType.Medical ? 0 : 1].push(cov);
      return result;
    },
    [[], []],
  );
  return [medicalCoverage, nonMedicalCoverage];
}

export function getOtherCoverageTypesForCs(
  medicalCoverage: IPlanCoverage[],
  nonMedicalCoverage: IPlanCoverage[],
  products?: IProducts,
): CoverageType[] {
  let otherCoverageTypesForCs: CoverageType[];
  // filter out coverage types that are already in the medical coverage's additionalCoverageTypes
  const uniqueNonMedicalCoverageTypes = nonMedicalCoverage
    .filter((cov: { coverageType: any }) => medicalCoverage[0].additionalCoverageTypes.indexOf(cov.coverageType) === -1)
    .map((cov: { coverageType: any }) => cov.coverageType);
  // combine other coverages with additional coverages
  otherCoverageTypesForCs = medicalCoverage[0].additionalCoverageTypes.concat(uniqueNonMedicalCoverageTypes);
  // filter out behavioral health coverage because, for C&S only, we get this from the plan summary response
  otherCoverageTypesForCs = otherCoverageTypesForCs.filter(cov => cov !== CoverageType.BehavioralHealth);
  // add behavioral health coverage if applicable
  if (products && products.liveAndWorkWell) {
    otherCoverageTypesForCs.push(CoverageType.BehavioralHealth);
  }
  otherCoverageTypesForCs.sort();
  return otherCoverageTypesForCs;
}

export function hasCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  return typeof getMatchingPlanPeriod(coverage, user) !== 'undefined';
}

export function getUsersWithCoverage(coverage: ICoverageSection, users: IProfileUser[]): IProfileUser[] {
  return users.filter(user => hasCoverage(coverage, user));
}

export function hasActiveCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  const coverageStatus = getMatchingPlanPeriod(coverage, user);
  return coverageStatus && coverageStatus.status === CoverageStatus.Active;
}

export function hasTermedCoverage(coverage: IPlanCoverage, user: IProfileUser): boolean {
  const coverageStatus = getMatchingPlanPeriod(coverage, user);
  return coverageStatus && coverageStatus.status === CoverageStatus.Termed;
}

export function hasTermedUser(coverage: IPlanCoverage, users: IProfileUser[]): boolean {
  return users
    .map(user => getMatchingPlanPeriod(coverage, user))
    .some(coverageStatus => coverageStatus && coverageStatus.status === CoverageStatus.Termed);
}

export function getCoverageParams(currentProfile: IProfileUser, coverageTypeCode: CoverageTypeCode): ICoverageParams {
  for (const coverage of currentProfile.planCoverages) {
    if (coverage.coverageTypeCode === coverageTypeCode && coverage.planPeriod.status === CoverageStatus.Active) {
      return {
        coverageTypeCode: coverage.coverageTypeCode,
        fundingArrangementType: coverage.planFeatures.fundingArrangementType,
        programType: coverage.planFeatures.programType,
        marketType: coverage.additionalCoverageInfo.marketType,
      };
    }
  }
  return {} as ICoverageParams;
}

export function isActiveOrFutureIndividualCardMultipleUsers(
  coverage: ICoverageSection,
  users: IProfileUser[],
): boolean {
  return coverage.planFeatures.isIndividualIdCard && users.length > 1 && hasActiveOrFutureUser(coverage, users);
}

export function planHasOtherMembers(profile: IProfile, currentProfile: IProfileUser): boolean {
  return currentProfile.relationshipType !== RelationshipType.Subscriber || profile.dependents.length > 0;
}

export const getPlanVariationParams = (profile: IProfile): IPlanVariationParams => ({
  pvrc: concatPvrc(profile.currentUser),
  clientId: profile.clientInfo ? profile.clientInfo.clientId : null,
});

// The following functions are all related to getBenefitsSpending

export function filterIndividualBenefitAmounts(benefitAmounts: IBenefitAmount[]): IBenefitAmount[] {
  return benefitAmounts.filter(({ type }) => (BENEFIT_TYPE[type].owner as string) === INDIVIDUAL);
}

export function formatBenefitAmounts(
  benefitAmounts: IBenefitAmount[],
  network: BenefitNetwork,
  planName: string,
): IBenefitsSpending[] {
  return benefitAmounts.map(({ amount, type }) => ({
    type,
    benefit: BENEFIT_TYPE[type].benefit,
    owner: BENEFIT_TYPE[type].owner,
    max: amount.value,
    amount: undefined,
    network,
    planName,
  }));
}

export function formatIndividualBenefits(
  benefitAmounts: IBenefitAmount[],
  network: BenefitNetwork,
  planName: string,
): IBenefitsSpending[] {
  const individualBenefits = filterIndividualBenefitAmounts(benefitAmounts);
  return formatBenefitAmounts(individualBenefits, network, planName);
}

export function formatInNetworkBenefits(benefit: IBenefit): IBenefitsSpending[] {
  if (benefit && benefit.maxes && benefit.maxes.inNetwork) {
    return formatIndividualBenefits(benefit.maxes.inNetwork, BenefitNetwork.InNetwork, benefit.planName);
  }
  return [];
}

export function formatOutOfNetworkBenefits(benefit: IBenefit): IBenefitsSpending[] {
  if (benefit && benefit.maxes && benefit.maxes.outOfNetwork) {
    return formatIndividualBenefits(benefit.maxes.outOfNetwork, BenefitNetwork.OutOfNetwork, benefit.planName);
  }
  return [];
}

export function formatBenefitsSpending(benefit: IBenefit): IBenefitsSpending[] {
  return formatInNetworkBenefits(benefit).concat(formatOutOfNetworkBenefits(benefit));
}

export function getBenefitsSpending(
  planAccumulators: IPlanAccumulators,
  planBenefits: IPlanBenefits,
): IBenefitsSpending[] {
  if (planAccumulators && planBenefits) {
    const approvedCTCs = [CoverageTypeCode.MA, CoverageTypeCode.MAPD, CoverageTypeCode.SSP];
    const medBenefit = planBenefits.benefits.find(benefit => {
      const matchingType = benefit.coverageType === CoverageType.Medical;
      const matchingTypeCode = approvedCTCs.indexOf(benefit.coverageTypeCode) !== -1;
      return !!(matchingType && matchingTypeCode && benefit.maxes);
    });
    const medBenefitAccum = planAccumulators.benefits.find(benefit => {
      return !!(benefit.coverageType === CoverageType.Medical && benefit.accumulators);
    });
    const formattedMedBenefits = formatBenefitsSpending(medBenefit);
    const medBenefitWithAccumulator = formattedMedBenefits.map(benefit => {
      if (
        medBenefitAccum &&
        medBenefitAccum.accumulators &&
        Object.prototype.hasOwnProperty.call(medBenefitAccum.accumulators, benefit.network)
      ) {
        const accumulators: IBenefitAmount[] = medBenefitAccum.accumulators[benefit.network];
        accumulators.forEach(acc => acc.type === benefit.type && (benefit.amount = acc.amount.value));
      }
      return benefit;
    });
    return medBenefitWithAccumulator;
  }
  return [];
}
