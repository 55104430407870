import React, { ComponentType, createRef, useContext, useEffect } from 'react';
import { FeatureContext } from 'scripts/contexts/feature-context/feature-context';
import { sendSectionLoad } from 'scripts/util/tracking/tracking';

export interface IWithTrackDataUponRenderProps {
  className?: string;
  trackData?: any;
  trackLabel?: string;
}

export function withTrackDataUponRender<T>(
  Component: ComponentType<T>,
): ComponentType<T & IWithTrackDataUponRenderProps> {
  const ref = createRef<HTMLDivElement>();
  return props => {
    const { className, trackData = {}, trackLabel, ...rest } = props;
    const { featureList } = useContext(FeatureContext);

    useEffect(() => {
      sendSectionLoad(trackLabel, trackData, featureList, ref);
    }, []);

    return (
      <div className={className} ref={ref}>
        <Component {...rest as T} />
      </div>
    );
  };
}
