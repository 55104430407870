import { ITrackingService } from './../../api/tracking/tracking.service';
import { ISundaySkyTrackController, SundaySkyTrackController } from './sunday-sky-track.controller';

const sundaySkyElementType = 'sundaysky-video';
export class SundaySkyTrackDirective implements ng.IDirective {
  public scope = false;
  public controller = SundaySkyTrackController;
  public controllerAs = '$trackSundaySky';

  constructor(private trackingService: ITrackingService) {}

  public link = (
    scope: ng.IScope,
    element: ng.IAugmentedJQuery,
    attrs: ng.IAttributes,
    $trackSundaySky: ISundaySkyTrackController,
  ): void => {
    let sundaySkyVideo: Element;
    element[0].querySelectorAll('iframe').forEach(item => {
      const result = item.contentWindow.document.body.querySelector(sundaySkyElementType);
      if (result) {
        sundaySkyVideo = result;
      }
    });

    if (sundaySkyVideo) {
      $trackSundaySky.registerTracking(sundaySkyVideo);
    }
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = (trackingService: ITrackingService) => {
      'ngInject';
      return new SundaySkyTrackDirective(trackingService);
    };
    return directive;
  }
}
