import { Observable } from 'rxjs/Observable';
import { ClaimStatus, IClaim } from 'scripts/api/claims/claims.interfaces';
import { ClaimsService, IClaimsService } from 'scripts/api/claims/claims.service';
import { IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { getAAClaimStatusString, isVideoAvailable } from 'scripts/util/claims/claims';
import { ClaimMarkPaid } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { IGenesysService } from 'scripts/util/genesys/genesys.service';
import { submitClaim, internationalClaims } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { sendAAPageData } from 'scripts/util/tracking/adobe-analytics';
import { isExpatriate } from 'scripts/util/user/user';
import claimsNeedAttentionTemplate from 'views/claims-and-accounts/summary/claims-need-attention.html';

export class ClaimsNeedAttentionController implements ng.IComponentController {
  public claims: IClaim[];
  public claimStatusVideoPresence: boolean;
  public clientConfig: IClientConfig;
  public currentUser: IProfileUser;
  public hasAttentionClaims: boolean;
  public internationalClaimsUrl: IResource;
  public request: Observable<IClaim[]>;
  public showLinkToInternationalClaims: boolean;
  public submitClaim: IResource;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private featureFlagService: IFeatureFlagService,
    private genesysService: IGenesysService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    public targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.CLAIMS_NEED_ATTENTION);
    $translatePartialLoader.addPart(Dictionary.COMMON);

    this.submitClaim = submitClaim;
    this.request = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => this.targetingService.getClientConfig(profile.rallyId),
        (profile, clientConfig) => ({ profile, clientConfig }),
      )
      .do(({ clientConfig }) => {
        this.clientConfig = clientConfig;
      })
      .flatMap(({ profile }) => this.claimsService.getNeedAttention(profile))
      .map(rsp => rsp.data);
  }

  public $onInit(): void {
    this.genesysService.updatePageAndButtonClassNames();
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .do(profile => {
        this.currentUser = profile.currentUser;
        this.showLinkToInternationalClaims =
          isExpatriate(profile.currentUser) && this.featureFlagService.isLinkToInternationalClaimsOn();
        this.internationalClaimsUrl = this.resourceService.get(internationalClaims);
      })
      .flatMap(() => this.request)
      .subscribe(claims => {
        this.claims = claims;

        const claimStatusString = getAAClaimStatusString(this.claims);

        if (claimStatusString) {
          this.claimStatusVideoPresence = this.claims.some(claim => isVideoAvailable(claim));
          sendAAPageData('claimStatusType', {
            pageName: 'smart-summary',
            claimStatusType: claimStatusString,
            videoAvailable: this.claimStatusVideoPresence ? 'ssky' : '',
          });
        }

        this.hasAttentionClaims = claims.some(claim => {
          const youMayOweAmount = ClaimsService.getYouMayOweAmount(claim);
          return claim.claimStatus === ClaimStatus.Denied || (youMayOweAmount && youMayOweAmount > 0);
        });
      }, console.warn);

    this.$rootScope.$on(ClaimMarkPaid, (_, claim: IClaim) => {
      this.handleClaimUpdate(claim);
    });
  }

  public handleClaimUpdate = (updatedClaim: IClaim): void => {
    this.claims = this.claims.map(claim => (claim.claimKey === updatedClaim.claimKey ? updatedClaim : claim));
  };
}

export class ClaimsNeedAttentionComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ClaimsNeedAttentionController;
    this.templateUrl = claimsNeedAttentionTemplate;
  }
}
