import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import {
  addUserFeedbackToPdl,
  getAADataLayer,
  getAAPageName,
  queueAAVideoEvent,
  sendAAEvent,
  sendAAInitEvent,
  setAAProfileValues,
  setInitialAADataLayerValues,
} from 'scripts/util/tracking/adobe-analytics';
import { getQueuedEvents, logEvent, resetQueuedEvents, updateQueuedEvents } from 'scripts/util/tracking/tracking';
import { stringifyData } from 'scripts/util/tracking/tracking-helper';
import { trackingUris } from 'scripts/util/uri/uri';
import { IArcadeState } from '../../arcade.module.interfaces';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { HeartbeatSuccess } from '../../util/constants/event.constants';
import { IPopulationService } from '../../util/population/population.service';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { LineOfBusiness, MembershipCategory } from '../profile/profile.interfaces';
import { IProfileService } from '../profile/profile.service';
import { IAmplitudeService } from './amplitude.service';
import { IAADataLayer, ITrackingEventRequest, IVideoTrackingEvent, TrackingTriggerType } from './tracking.interfaces';
import Axios, { AxiosResponse } from 'axios';

export interface ITrackingService {
  getCurrentAADataLayer(): IAADataLayer;
  postEvents(): Observable<any>;
  postAxiosTrackingEvents(): () => Promise<AxiosResponse<any>>;
  queueEvent(event: ITrackingEventRequest, isImpression?: boolean): void;
  queueVideoEvent(videoEvent: IVideoTrackingEvent): void;
  updateLobAndMembershipCategory(lob: LineOfBusiness, membershipCategory: MembershipCategory): void;
  stateChange(fromState: IArcadeState, toState: IArcadeState): void;
}

export class TrackingService implements ITrackingService {
  private postInterval: ng.IPromise<any>;
  private aaDataLayer: IAADataLayer;
  private failedPostEventCount = 0;

  constructor(
    private $interval: ng.IIntervalService,
    private $rootScope: ng.IRootScopeService,
    private $window: ng.IWindowService,
    private Analytics: angular.google.analytics.AnalyticsService,
    private amplitudeService: IAmplitudeService,
    private baseApiService: IBaseApiService,
    private Environment: IEnvironmentConstants,
    private populationService: IPopulationService,
    private profileService: IProfileService,
  ) {
    'ngInject';

    this.postInterval = $interval(() => {
      this.postEvents().subscribe();
    }, Environment.CONFIG.ARCADE_WEB_TRACKING_INTERVAL_MS);

    // Adobe Analytics Setup
    let aaInitialized = false;
    this.aaDataLayer = getAADataLayer();
    setInitialAADataLayerValues(this.aaDataLayer);

    $rootScope.$on(HeartbeatSuccess, (e, heartbeat) => {
      const pop = this.populationService.getPopulation();
      if (pop) {
        this.updateLobAndMembershipCategory(pop.lineOfBusiness, pop.membershipCategory);
      }
      this.amplitudeService.init(heartbeat.rallyId);

      profileService.get(heartbeat.rallyId).subscribe(({ data: profile }) => {
        this.amplitudeService.setBaseUserProps(profile);

        setAAProfileValues(this.aaDataLayer, heartbeat, profile);

        this.updateLobAndMembershipCategory(profile.currentUser.lineOfBusiness, profile.currentUser.membershipCategory);

        if (!aaInitialized) {
          this.aaDataLayer.content.pageName = getAAPageName();
          sendAAInitEvent();
          aaInitialized = true;
        }

        addUserFeedbackToPdl();
      }, console.warn);
    });
  }

  private eventCompile(): { url: string; queuedEventsToSend: ITrackingEventRequest[] } {
    const url = trackingUris.events();
    let queuedEventsToSend = [];
    if (this.failedPostEventCount <= this.Environment.CONFIG.ARCADE_WEB_TRACKING_MAX_RETRY_COUNT) {
      queuedEventsToSend = getQueuedEvents();
      resetQueuedEvents();
    }

    queuedEventsToSend.forEach(event => {
      logEvent(event);
    });

    return { url, queuedEventsToSend };
  }

  public postEvents(): Observable<any> {
    const { url, queuedEventsToSend } = this.eventCompile();

    const request$ = this.baseApiService.post(url, queuedEventsToSend, false, { ignoreLoadingBar: true }).catch(err => {
      updateQueuedEvents(queuedEventsToSend);
      this.failedPostEventCount++;
      return Observable.throw(err);
    });

    return Observable.if(() => queuedEventsToSend.length > 0, request$, Observable.of(0));
  }

  public postAxiosTrackingEvents(): () => Promise<AxiosResponse<any>> {
    const { url, queuedEventsToSend } = this.eventCompile();

    async function axiosRequest(): Promise<AxiosResponse<any>> {
      return await Axios.post(url, queuedEventsToSend, {
        timeout: 1000,
      });
    }

    return axiosRequest;
  }

  public queueEvent(event: ITrackingEventRequest, isImpression: boolean = false): void {
    // Don't send campaign "impressions" to Adobe Analytics
    if (!isImpression) {
      sendAAEvent(event, this.aaDataLayer);
    }
    // Only send GA events for "click" events for now because we are hitting our limit of events =(
    if (event.trigger === TrackingTriggerType.Click) {
      this.sendGAEvent(event);
      this.sendAmplitudeEvent(event);
    }
    stringifyData(event);
    updateQueuedEvents(event);
    // reset the failed post event count when a new event is added to the queue
    this.failedPostEventCount = 0;
  }

  public queueVideoEvent(videoEvent: IVideoTrackingEvent): void {
    this.sendVideoAmplitudeEvent(videoEvent);
    queueAAVideoEvent(videoEvent);
  }

  public updateLobAndMembershipCategory(lob: LineOfBusiness, membershipCategory?: MembershipCategory): void {
    // TODO: decouple AA/GA here and GA from angular GA package used project wide
    // Set the LoB and MembershipCategory in both AA and GA
    const gaLineOfBusinessDim = 'dimension1';

    this.aaDataLayer.lineOfBusiness = lob;
    this.Analytics.set(gaLineOfBusinessDim, lob);
    if (membershipCategory) {
      this.aaDataLayer.membershipCategory = membershipCategory;
    }
  }

  public stateChange(fromState: IArcadeState, toState: IArcadeState): void {
    try {
      this.amplitudeService.sendStateChangeEvent(fromState, toState);
    } catch (err) {
      console.error('Error sending Amplitude event', err);
    }
  }

  public getCurrentAADataLayer(): IAADataLayer {
    // temporary bridge to pass datalayer in routes.config.ts
    // Remove once tracking is fully refactored away from Angular service.
    return this.aaDataLayer;
  }

  private sendVideoAmplitudeEvent(event: IVideoTrackingEvent): void {
    try {
      this.amplitudeService.registerVideoEvent(event);
    } catch (err) {
      console.error('Error sending Video Amplitude event', err);
    }
  }

  private sendAmplitudeEvent(event: ITrackingEventRequest): void {
    try {
      this.amplitudeService.registerClickEvent(event);
    } catch (err) {
      console.error('Error sending Amplitude event', err);
    }
  }

  /**
   * Google Analytics
   * @param event
   */
  private sendGAEvent(event: ITrackingEventRequest): void {
    try {
      const category = event.featureList.join('.');
      const action = event.trigger.toString().toLowerCase();
      const label = event.actionName;
      this.Analytics.trackEvent(category, action, label);
    } catch (err) {
      console.error('Error sending GA event', err);
    }
  }
}
