import { LocaleActionTypes } from 'scripts/actions/locale-actions';
import { TargetingServiceActionTypes } from 'scripts/actions/targeting-service-actions';
import { IClientConfig, ICampaigns } from 'scripts/api/targeting/targeting.interfaces';
import { ArcadeAction, IStateData } from 'scripts/reducers/reducer.interfaces';

export interface ITargetingState {
  campaigns: IStateData<ICampaigns>;
  clientConfig: IStateData<IClientConfig>;
}

export const initialState: ITargetingState = {
  campaigns: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  clientConfig: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: ITargetingState = initialState, action: ArcadeAction): ITargetingState => {
  switch (action.type) {
    case LocaleActionTypes.CHANGE_LOCALE:
      return {
        ...state,
        campaigns: {
          data: undefined,
          error: undefined,
          loading: false,
        },
      };
    case TargetingServiceActionTypes.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case TargetingServiceActionTypes.GET_CAMPAIGNS_LOADING:
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: true,
        },
      };
    case TargetingServiceActionTypes.GET_CAMPAIGNS_ERROR:
      return {
        ...state,
        campaigns: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case TargetingServiceActionTypes.GET_CLIENT_CONFIG_SUCCESS:
      return {
        ...state,
        clientConfig: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case TargetingServiceActionTypes.GET_CLIENT_CONFIG_LOADING:
      return {
        ...state,
        clientConfig: {
          ...state.clientConfig,
          loading: true,
        },
      };
    case TargetingServiceActionTypes.GET_CLIENT_CONFIG_ERROR:
      return {
        ...state,
        clientConfig: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
