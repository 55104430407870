import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { IClaimsState } from 'scripts/reducers/claims-service-reducer';

const selectClaims = (state: IReduxState): IClaimsState => state.claims;

export const selectAllHealthcare = createSelector(
  selectClaims,
  claims => claims.allHealthcare,
);

export const selectAllHealthcareData = createSelector(
  selectAllHealthcare,
  allHealthcare => allHealthcare.data,
);

export const selectAllHealthcareLoading = createSelector(
  selectAllHealthcare,
  allHealthcare => allHealthcare.loading,
);

export const selectAllHealthcareError = createSelector(
  selectAllHealthcare,
  allHealthcare => allHealthcare.error,
);

export const selectSummary = createSelector(
  selectClaims,
  claims => claims.summary,
);

export const selectSummaryArcadeDataUpdated = createSelector(
  selectSummary,
  summary => summary.arcadeDataUpdated,
);

export const selectSummaryData = createSelector(
  selectSummary,
  summary => summary.data,
);

export const selectSummaryLoading = createSelector(
  selectSummary,
  summary => summary.loading,
);

export const selectSummaryError = createSelector(
  selectSummary,
  summary => summary.error,
);

export const selectTotals = createSelector(
  selectClaims,
  claims => claims.totals,
);

export const selectTotalsArcadeDataUpdated = createSelector(
  selectTotals,
  totals => totals.arcadeDataUpdated,
);

export const selectTotalsData = createSelector(
  selectTotals,
  totals => totals.data,
);

export const selectTotalsLoading = createSelector(
  selectTotals,
  totals => totals.loading,
);

export const selectTotalsError = createSelector(
  selectTotals,
  totals => totals.error,
);
