import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';
import { AccumulatorsListConnected } from 'scripts/ui/accumulators-list/accumulators-list-connected';

export const SpendingAndCostSummaryAccumulators: FunctionComponent = () => {
  const { t } = useTranslation(Dictionary.ACCUMULATORS);

  return (
    <div>
      <header className="section-header accumulators-header">
        <h2 className="accumulators-header-title">{t('MEDICAL_SPENDING_SUMMARY')}</h2>
      </header>
      <AccumulatorsListConnected showPlanName={true} />
    </div>
  );
};
