export const ICONS = {
  'icon-provider-financial': '/images/icons/icon-provider-financial.svg',
  'icon-medical': '/images/icons/icon-medical.svg',
  'icon-dental': '/images/icons/icon-dental.svg',
  'icon-vision': '/images/icons/icon-vision.svg',
  'icon-pharmacy': '/images/icons/icon-pharmacy.svg',
  'icon-mental-health': '/images/icons/icon-mental-health.svg',
  'icon-claims': '/images/icons/icon-claims.svg',
  'icon-saved': '/images/icons/icon-saved.svg',
  'icon-wellness': '/images/icons/icon-wellness.svg',
  'icon-circle-denied': '/images/icons/icon-circle-denied.svg',
  'icon-circle-dental': '/images/icons/icon-circle-dental.svg',
  'icon-circle-dental-2': '/images/icons/icon-circle-dental-2.svg',
  'icon-circle-external-link': '/images/icons/icon-circle-external-link.svg',
  'icon-circle-forms': '/images/icons/icon-circle-forms.svg',
  'icon-circle-heart': '/images/icons/icon-circle-heart.svg',
  'icon-circle-id-card': '/images/icons/icon-circle-id-card.svg',
  'icon-circle-materials': '/images/icons/icon-circle-materials.svg',
  'icon-circle-medical': '/images/icons/icon-circle-medical.svg',
  'icon-circle-medical-2': '/images/icons/icon-circle-medical-2.svg',
  'icon-circle-partially-denied': '/images/icons/icon-circle-partially-denied.svg',
  'icon-circle-pharmacy': '/images/icons/icon-circle-pharmacy.svg',
  'icon-circle-pharmacy-2': '/images/icons/icon-circle-pharmacy-2.svg',
  'icon-circle-physical': '/images/icons/icon-circle-physical.svg',
  'icon-circle-value': '/images/icons/icon-circle-value.svg',
  'icon-circle-vision': '/images/icons/icon-circle-vision.svg',
  'icon-find-doctor': '/images/icons/icon-find-doctor.svg',
  'icon-find-pharmacy': '/images/icons/icon-find-pharmacy.svg',
  'icon-find-rx': '/images/icons/icon-find-rx.svg',
  'icon-forms': '/images/icons/icon-forms.svg',
  'icon-balances': '/images/icons/icon-balances.svg',
  'icon-eob': '/images/icons/icon-eob.svg',
  'icon-global': '/images/icons/icon-global.svg',
  'icon-green': '/images/icons/icon-green.svg',
  'icon-gear': '/images/icons/icon-gear.svg',
  'icon-health-resources': '/images/icons/icon-health-resources.svg',
  'icon-order-status': '/images/icons/icon-order-status.svg',
  'icon-payments': '/images/icons/icon-payments.svg',
  'icon-pharmacy-locator': '/images/icons/icon-pharmacy-locator.svg',
  'icon-refill-prescriptions': '/images/icons/icon-refill-prescriptions.svg',
  'icon-rewards': '/images/icons/icon-rewards.svg',
  'icon-submit-claim': '/images/icons/icon-submit-claim.svg',
  'icon-value': '/images/icons/icon-value.svg',
  'icon-value-2': '/images/icons/icon-value-2.svg',
  'icon-circle-eob': '/images/icons/icon-circle-eob.svg',
  'icon-circle-value-2': '/images/icons/icon-circle-value-2.svg',
  'icon-benefits': '/images/icons/icon-benefits.svg',
  'icon-close-white': '/images/icons/icon-close-white.svg',
  'icon-plan-documents': '/images/icons/icon-plan-documents.svg',
};
