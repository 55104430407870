import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { IClaim, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IRouterProps, withRouter } from 'scripts/hoc/with-router/with-router';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectShowMarkAsPaidDisclaimer } from 'scripts/selectors/profile-service-selectors';
import { saveClaim } from 'scripts/thunks/claims-service-thunks';
import { getClaimsPreferences } from 'scripts/thunks/profile-service-thunks';
import { constructParams } from 'scripts/util/uri/uri';

interface IMarkPaidCheckboxProps {
  checked: boolean;
  id: string;
  loading: boolean;
  onChange: () => void;
}

function MarkPaidCheckbox(props: IMarkPaidCheckboxProps): ReactElement<IMarkPaidCheckboxProps> {
  const { checked, id, loading, onChange, ...rest } = props;
  return (
    <input
      checked={checked}
      data-testid="mark-as-paid-checkbox"
      disabled={loading}
      id={id}
      onChange={onChange}
      type="checkbox"
      {...rest}
    />
  );
}

const MarkPaidCheckboxWithTracking = withClickTracking(MarkPaidCheckbox, 'mark-as-paid');

export interface IMarkPaidCheckboxContainerProps {
  claim: IClaim;
  getClaimsPreferences: () => void;
  id: string;
  onClaimUpdate: (claim: IClaim) => void;
  saveClaim: (claim: IClaim | IHealthcareClaimDetails) => void;
  showMarkAsPaidDisclaimer: boolean;
}

interface IMarkPaidCheckboxContainerState {
  loading: boolean;
}

export class MarkPaidCheckboxContainer extends Component<
  IMarkPaidCheckboxContainerProps & IRouterProps,
  IMarkPaidCheckboxContainerState
> {
  constructor(props: IMarkPaidCheckboxContainerProps & IRouterProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render(): ReactElement<IMarkPaidCheckboxContainerProps> {
    const { claim, id } = this.props;
    const { loading } = this.state;
    return (
      <MarkPaidCheckboxWithTracking
        checked={claim.claimManagementInfo.markPaid}
        id={id}
        loading={loading}
        onChange={this.handleTogglePaid}
      />
    );
  }

  private handleTogglePaid = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.props.getClaimsPreferences();
    const { claim, showMarkAsPaidDisclaimer } = this.props;
    if (showMarkAsPaidDisclaimer) {
      const params = {
        claimId: claim.claimId,
        explanationFrom: `${this.props.location.pathname}${this.props.location.search}`,
      };
      this.props.history.push({ pathname: '/modal/mark-as-paid-explanation', search: constructParams(params) });
      // If getClaimsPreferences() fails, showMarkAsPaidDisclaimer will be undefined and we'll enter this block:
    } else {
      const { claimManagementInfo } = claim;
      const updatedClaim = {
        ...claim,
        claimManagementInfo: {
          ...claimManagementInfo,
          markPaid: !claimManagementInfo.markPaid,
        },
      };
      await this.props.saveClaim(updatedClaim);
      this.props.onClaimUpdate(updatedClaim);
    }
    this.setState({ loading: false });
  };
}

export default withRouter(
  connect(
    (state: IReduxState) => ({
      showMarkAsPaidDisclaimer: selectShowMarkAsPaidDisclaimer(state),
    }),
    {
      getClaimsPreferences,
      saveClaim,
    },
  )(MarkPaidCheckboxContainer),
);
