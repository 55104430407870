import idx from 'idx';
import { createSelector, Selector } from 'reselect';
import {
  IClaimsPreferences,
  IProducts,
  IProfile,
  IProfileUser,
  IReferralsPerMember,
  IDependentAndCoverageTypes,
  IPrimaryCarePerMember,
  IPrimaryCareFpc,
} from 'scripts/api/profile/profile.interfaces';
import { IReduxState, IStateData } from 'scripts/reducers/reducer.interfaces';

const selectProfile = (state: IReduxState): IStateData<IProfile> => state.profile.profile;

const selectSelectedProfile = (state: IReduxState): IProfileUser => state.profile.selectedProfile;

const selectClaimsPreferencesData = (state: IReduxState): IClaimsPreferences => state.profile.claimsPreferences.data;

const selectProducts = (state: IReduxState): IStateData<IProducts> => state.profile.products;

const selectReferrals = (state: IReduxState): IStateData<IReferralsPerMember> => state.profile.referrals;

const selectHealthcareCoverages = (state: IReduxState): IStateData<IDependentAndCoverageTypes[]> =>
  state.profile.healthcareCoverages;

const selectFpcPrimaryCare = (state: IReduxState): IStateData<IPrimaryCareFpc> => state.profile.fpcPrimaryCare;

const selectPrimaryCare = (state: IReduxState): IStateData<IPrimaryCarePerMember> => state.profile.primaryCare;

export const selectProfileData = createSelector(
  selectProfile,
  profile => profile.data,
);

const selectCurrentUser = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.currentUser),
);

export const selectClientInfo = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.clientInfo),
);

export const selectDependents = createSelector(
  selectProfileData,
  profile => idx(profile, _ => _.dependents) as IProfileUser[],
);

const commonProfileUserSelectors = [selectCurrentUser, selectSelectedProfile].map(
  (selectProfileUser: Selector<IReduxState, IProfileUser>) => {
    const selectDependentSeqNum = createSelector(
      selectProfileUser,
      profileUser => idx(profileUser, _ => _.dependentSeqNum),
    );

    const selectLineOfBusiness = createSelector(
      selectProfileUser,
      profileUser => idx(profileUser, _ => _.lineOfBusiness),
    );

    const selectUserInfo = createSelector(
      selectProfileUser,
      profileUser => idx(profileUser, _ => _.userInfo),
    );

    const selectFirstName = createSelector(
      selectUserInfo,
      userInfo => idx(userInfo, _ => _.firstName),
    );

    const selectLastName = createSelector(
      selectUserInfo,
      userInfo => idx(userInfo, _ => _.lastName),
    );

    return {
      selectProfile: selectProfileUser,
      selectDependentSeqNum,
      selectLineOfBusiness,
      selectUserInfo,
      selectFirstName,
      selectLastName,
    };
  },
);

// currentUser - is the user that is logged in
// selectedUser - is the user that is selected using the account selector on the dashboard, it may be the same as the currentUser
export const [currentUser, selectedUser] = commonProfileUserSelectors;

export const selectMarkAsPaid = createSelector(
  selectClaimsPreferencesData,
  claimsPreferences => idx(claimsPreferences, _ => _.markAsPaid),
);

export const selectShowMarkAsPaidDisclaimer = createSelector(
  selectMarkAsPaid,
  markAsPaid => idx(markAsPaid, _ => _.showDisclaimer),
);

export const selectProductsLoading = createSelector(
  selectProducts,
  products => products.loading,
);

export const selectProductsError = createSelector(
  selectProducts,
  products => products.error,
);

export const selectProductsData = createSelector(
  selectProducts,
  products => products.data,
);

export const selectReferralsLoading = createSelector(
  selectReferrals,
  referrals => referrals.loading,
);

export const selectReferralsError = createSelector(
  selectReferrals,
  referrals => referrals.error,
);

export const selectReferralsData = createSelector(
  selectReferrals,
  referrals => referrals.data,
);

export const selectHealthcareCoveragesLoading = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.loading,
);

export const selectHealthcareCoveragesError = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.error,
);

export const selectHealthcareCoveragesData = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.data,
);

export const selectFpcPrimaryCareLoading = createSelector(
  selectFpcPrimaryCare,
  fpcPrimaryCare => fpcPrimaryCare.loading,
);

export const selectFpcPrimaryCareError = createSelector(
  selectFpcPrimaryCare,
  fpcPrimaryCare => fpcPrimaryCare.error,
);

export const selectFpcPrimaryCareData = createSelector(
  selectFpcPrimaryCare,
  fpcPrimaryCare => fpcPrimaryCare.data,
);

export const selectPrimaryCareLoading = createSelector(
  selectPrimaryCare,
  primaryCare => primaryCare.loading,
);

export const selectPrimaryCareError = createSelector(
  selectPrimaryCare,
  primaryCare => primaryCare.error,
);

export const selectPrimaryCareData = createSelector(
  selectPrimaryCare,
  primaryCare => primaryCare.data,
);
