import { IResponse } from '../api.interfaces';

export enum ActivateStepStatus {
  completed = 'COMPLETED',
  skipped = 'SKIPPED',
  supressed = 'SUPPRESSED',
  unstarted = 'UNSTARTED',
}

export enum ActivateGlobalStatus {
  complete = 'COMPLETE',
  started = 'STARTED',
}

export enum ActivatePlanKey {
  'EI' = 'E&I',
  'CDP' = 'CDP',
  'DP' = 'DP',
  'GATED' = 'GATED_E&I',
  'NON-GATED-PCP' = 'NON-GATED_PCP_E&I',
  'EMAIL_BOUNCE' = 'BOUNCE',
  'MR' = 'M&R_FEDERAL',
  'COB' = 'COB',
}

export const isNonFtuePlan = (planId: string): boolean => {
  switch (planId) {
    case ActivatePlanKey.COB:
      return true;
    case ActivatePlanKey.EMAIL_BOUNCE:
      return true;
    default:
      return false;
  }
};

export interface IActivateStepsResponseSwitch extends IResponse<IActivateSwitch> {}
export interface IActivateStepsResponseV7 extends IResponse<IActivatePlansV7> {}
export interface IActivateStatusResponse extends IResponse<IActivateStatus> {}

export interface IActivatePlansV7 {
  plans: IActivatePlanV7[];
}

export interface IActivatePlanV7 {
  planKey: ActivatePlanKey;
  status: ActivateGlobalStatus;
  steps: IActivatePlanStep[];
  planVersion: number;
  autoPrompt: boolean;
}

export interface IActivateStatus {
  plans: IActivatePlansData[];
}

export interface IActivatePlansData {
  planKey: ActivatePlanKey;
  steps: IActivatePlanStep[];
}

export enum UserFlowData {
  Reminder = 'GP',
  Registration = 'NPS',
}

export interface IActivatePlanStep {
  sequence: number;
  stepId: string;
  stepName: string;
  stepState: ActivateStepStatus;
  bannerPriority?: number;
  userFlowTrackingData?: UserFlowData[];
}

export interface ISwitchStep {
  bannerPriority?: number;
  flowId: string;
  stepId: string;
  url: string;
  flowVerison: number;
}

export interface IActivateSwitch {
  autoPrompt?: ISwitchStep;
  finishNowBanner?: ISwitchStep;
  modalBanners?: ISwitchStep[];
}
