import idx from 'idx';
import { createSelector } from 'reselect';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ITargetingState } from 'scripts/reducers/targeting-service-reducer';

const selectTargeting = (state: IReduxState): ITargetingState => state.targeting;

const selectCampaigns = createSelector(
  selectTargeting,
  targeting => targeting.campaigns,
);

export const selectCampaignsData = createSelector(
  selectCampaigns,
  campaigns => campaigns.data,
);

export const selectCampaignsLoading = createSelector(
  selectCampaigns,
  campaigns => campaigns.loading,
);

export const selectCampaignsError = createSelector(
  selectCampaigns,
  campaigns => campaigns.error,
);

const selectClientConfig = createSelector(
  selectTargeting,
  targeting => targeting.clientConfig,
);

export const selectClientConfigData = createSelector(
  selectClientConfig,
  clientConfig => clientConfig.data,
);

export const selectClientConfigLoading = createSelector(
  selectClientConfig,
  clientConfig => clientConfig.loading,
);

export const selectClientConfigError = createSelector(
  selectClientConfig,
  clientConfig => clientConfig.error,
);

export const selectSuppressions = createSelector(
  selectClientConfigData,
  clientConfig => idx(clientConfig, _ => _.suppressions),
);
