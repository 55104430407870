import { IFilterService } from './filter.service';

export interface IAvailableFilterValue<T> {
  count: number;
  display: string;
  selected?: boolean;
  value: T;
}

export interface ISelectedFilters<U> {
  sort: ISelectedSort;
  values?: { [filterName: string]: U[] };
}

export interface IGenericFilter<T, U> {
  name: string;
  title: string;
  labelSelected?: string;
  displayFunction: (filterData: T, filterValue: U) => string;
  mapFunc: (filterData: T) => U;
  filterOnStateFunc?: (availableFilters: IAvailableFilterValue<U>, filterService: IFilterService) => boolean;
  sortFunc?: (a: IAvailableFilterValue<U>, b: IAvailableFilterValue<U>) => number;
  type: FilterType;
  selectedValues?: U[];
  defaultValues?: U[];
  isValid?: () => boolean;
}

export enum FilterType {
  Checkbox = 'checkbox',
  DateRange = 'dateRange',
  Dropdown = 'dropdown',
  Keyword = 'keyword',
}

export interface ISelectedSort {
  by: string;
  reverse: boolean;
}

export interface ISort<T> {
  properties: ((data: T) => string | number)[];
  reverse: boolean;
}

export enum FilterStateKey {
  Filters = 'filters',
  Type = 'type',
}

export type DateType = 'from' | 'to';
