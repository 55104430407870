import moment from 'moment';
import { Observable } from 'rxjs';
import { getMoneyValue } from 'scripts/util/money/money';
import {
  appealDentalClaimsOffline,
  appealMedialClaimsOffline,
  appealMedicalClaimDetailOnline,
  instamedPaymentDetails,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import claimDetailsBreakdownTemplate from 'views/claims-and-accounts/claims/claim-details-breakdown.html';
import { ICurrencyAmount } from '../../../../../api/api.interfaces';
import {
  ClaimPaymentType,
  ClaimStatus,
  ClaimTransactionType,
  ClaimType,
  IAnyClaimDetails,
  IFinancialClaimDetails,
  IHealthcareClaimDetails,
  TransactionFundingType,
} from '../../../../../api/claims/claims.interfaces';
import { ClaimsService } from '../../../../../api/claims/claims.service';
import { AccountType } from '../../../../../api/ledger/ledger.interfaces';
import { IProfileUser, MembershipCategory } from '../../../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../../../api/profile/profile.service';
import { IClientConfig } from '../../../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../../../api/targeting/targeting.service';
import { IUserService } from '../../../../../api/user/user.service';
import { IPieChartData, PieChartColor } from '../../../../../ui/pie-chart/pie-chart.interfaces';
import { Dictionary } from '../../../../../util/constants/i18n.constants';
import { IResourceService } from '../../../../../util/resource/resource.service';
import { IFeatureFlagService } from '../../../../../util/feature-flag/feature-flag.interface';
import { isHealthcareClaim } from 'scripts/util/claims/claims';

export interface IBreakdownLineItem {
  amount?: number;
  color?: PieChartColor;
  dateProcessed?: moment.Moment;
  includeInTotal?: boolean;
  label: string;
  note?: string;
  pie?: IPieChartData;
  show: boolean;
  totalItem?: boolean;
  transactionID?: string;
}

export interface IAbbreviationMapping {
  JPMC: IAccountMapping;
}

export interface IAccountMapping {
  FSA?: AccountType;
  HRA?: AccountType;
}

export class ClaimDetailsBreakdownController implements ng.IComponentController {
  // Bindings
  public claimDetails: IAnyClaimDetails;
  public isHealth: boolean;
  public lineItems: IBreakdownLineItem[];
  public onlineMedicalAppeals: boolean;
  public instamedPaymentDetails: IResource;
  public userProfile: IProfileUser;
  private clientConfigReq: Observable<IClientConfig>;

  constructor(
    private $scope: ng.IScope,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.CLAIM_DETAILS);

    this.instamedPaymentDetails = instamedPaymentDetails;

    this.clientConfigReq = this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => {
        this.onlineMedicalAppeals = clientConfig && clientConfig.suppressions.onlineMedicalAppeals;
      });
  }

  public $onInit(): void {
    this.clientConfigReq.subscribe(() => undefined, console.warn);

    this.isHealth = ClaimsService.getByClaim(this.claimDetails, true, false);
    if (this.isHealth) {
      this.$scope.$watch(
        () => (this.claimDetails as IHealthcareClaimDetails).claimManagementInfo.markPaid,
        () => (this.lineItems = this.getHealthcareClaimLineItems(this.claimDetails as IHealthcareClaimDetails)),
      );
    }

    this.lineItems = ClaimsService.getByClaim(
      this.claimDetails,
      () => this.getHealthcareClaimLineItems(this.claimDetails as IHealthcareClaimDetails),
      () => this.getFinancialClaimLineItems(this.claimDetails as IFinancialClaimDetails),
    );
    this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .subscribe(userProfile => (this.userProfile = userProfile), console.warn);
  }

  public getMoneyValue(amount: number, decimal?: boolean): string {
    return getMoneyValue(amount, decimal);
  }

  public showMarkPaid(claim: IHealthcareClaimDetails): boolean {
    const claimNotInProcess = claim.claimStatus !== ClaimStatus.InProcess;
    return claimNotInProcess && this.getBalanceAmount(claim.balance.youMayOweAmount) !== 0;
  }

  public getAppealsLink(claimDetails: IHealthcareClaimDetails, onlineMedicalAppeals: boolean): string {
    if (claimDetails.claimType === ClaimType.Medical) {
      if (onlineMedicalAppeals && claimDetails.claimPayKey) {
        return this.resourceService.get(appealMedicalClaimDetailOnline) + claimDetails.claimPayKey;
      } else {
        return this.resourceService.get(appealMedialClaimsOffline);
      }
    } else if (claimDetails.claimType === ClaimType.Dental) {
      return this.resourceService.get(appealDentalClaimsOffline);
    } else {
      return '';
    }
  }

  public showAppeals(claimDetails: IAnyClaimDetails): boolean {
    const appealableClaimTypes = [ClaimType.Dental, ClaimType.Medical];
    return ClaimsService.getByClaim(
      claimDetails,
      () => appealableClaimTypes.indexOf((this.claimDetails as IHealthcareClaimDetails).claimType) > -1,
      false,
    );
  }

  public getAccountAbbreviation(label: string, membershipCategory: MembershipCategory): AccountType {
    return ClaimsService.getByClaim(
      this.claimDetails,
      () => {
        const accountAbbreviations: IAbbreviationMapping = {
          JPMC: {
            FSA: AccountType.HCSA,
            HRA: AccountType.MRA,
          },
        };
        const abbreviation = label.split('_')[0];
        if (accountAbbreviations[membershipCategory] && accountAbbreviations[membershipCategory][abbreviation]) {
          return accountAbbreviations[membershipCategory][abbreviation];
        }
        return abbreviation;
      },
      () => ClaimsService.getClaimAccountAbbreviation((this.claimDetails as IFinancialClaimDetails).accountType),
    );
  }

  public getClaimStatus(claimDetails: IAnyClaimDetails): ClaimStatus {
    switch (claimDetails.claimStatus) {
      case ClaimStatus.Denied:
      case ClaimStatus.PartiallyDenied:
        return claimDetails.claimStatus;
      default:
        return ClaimStatus.Processed;
    }
  }

  public isRallyPayTransaction(): boolean {
    if (isHealthcareClaim(this.claimDetails)) {
      const claimDetails = this.claimDetails as IHealthcareClaimDetails;
      if (claimDetails.transactionInformation) {
        return claimDetails.transactionInformation.fundingType === TransactionFundingType.RallyPay;
      }
    }
    return false;
  }

  private getHealthcareClaimLineItems(claimDetails: IHealthcareClaimDetails): IBreakdownLineItem[] {
    const { balance, claimDates, claimType, claimPaymentTypes } = claimDetails;

    const payOnlineItems: IBreakdownLineItem[] = [];
    if (claimType === ClaimType.Medical && claimPaymentTypes) {
      claimPaymentTypes
        .filter(({ paymentType }) => paymentType === ClaimPaymentType.Online)
        .forEach(value => {
          payOnlineItems.push({
            amount: this.getBalanceAmount(value.payment),
            color: PieChartColor.green as PieChartColor,
            dateProcessed: value.postedDate,
            includeInTotal: true,
            label: this.getOnlinePaymentTitle(value.transactionType),
            show: true,
            transactionID: value.confirmationNumber,
          });
        });
    }

    const items: IBreakdownLineItem[] = [
      {
        label: 'TOTAL_AMOUNT_BILLED',
        amount: this.getBalanceAmount(balance.totalBilledAmount),
        dateProcessed: claimDates.dateProcessed,
        show: true,
        totalItem: true,
      },
      {
        amount: this.getBalanceAmount(balance.healthPlanDiscount),
        color: PieChartColor.blue as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'PLAN_DISCOUNT',
        show: claimType !== ClaimType.Dental,
      },
      {
        amount: this.getBalanceAmount(balance.healthPlanPay),
        color: PieChartColor.teal as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'PLAN_PAID',
        show: true,
      },
      {
        amount: this.getBalanceAmount(balance.medicarePaid),
        color: PieChartColor.green as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'MEDICARE_PAID',
        show: balance.medicarePaid && this.getBalanceAmount(balance.medicarePaid) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.otherInsurancePaid),
        color: PieChartColor.blue as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'OTHER_INSURANCE_PAID',
        show: balance.otherInsurancePaid && this.getBalanceAmount(balance.otherInsurancePaid) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.fsaPaidToProvider),
        color: PieChartColor.green as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'FSA_PAID_TO_PROVIDER',
        show: this.getBalanceAmount(balance.fsaPaidToProvider) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.hraPaidToProvider),
        color: PieChartColor.green as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'HRA_PAID_TO_PROVIDER',
        show: this.getBalanceAmount(balance.hraPaidToProvider) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.patientResponsibility),
        color: PieChartColor.green as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'YOU_PAID_FOR_RX',
        show: this.getBalanceAmount(balance.patientResponsibility) > 0 && claimType === ClaimType.Rx,
      },
      ...payOnlineItems,
      {
        amount: claimDetails.claimManagementInfo.markPaid
          ? 0
          : balance.youMayOweAmount
          ? this.getBalanceAmount(balance.youMayOweAmount)
          : undefined,
        color: PieChartColor.red as PieChartColor,
        includeInTotal: true,
        label: 'YOU_MAY_OWE',
        show: true,
      },
      {
        amount: this.getBalanceAmount(balance.copay),
        color: PieChartColor.green as PieChartColor,
        dateProcessed: claimDates.serviceStartDate,
        includeInTotal: true,
        label: 'COPAY',
        show: claimType === ClaimType.Medical && this.getBalanceAmount(balance.copay) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.fsaPaidToYou),
        color: PieChartColor.red as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'FSA_PAID_TO_YOU',
        note: 'FSA_HRA_PAID_TO_YOU_NOTE',
        show: this.getBalanceAmount(balance.fsaPaidToYou) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.hraPaidToYou),
        color: PieChartColor.red as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'HRA_PAID_TO_YOU',
        note: this.getBalanceAmount(balance.fsaPaidToYou) > 0 ? undefined : 'FSA_HRA_PAID_TO_YOU_NOTE',
        show: this.getBalanceAmount(balance.hraPaidToYou) > 0,
      },
      {
        amount: this.getBalanceAmount(balance.claimMemberNotCovered),
        color: PieChartColor.red as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        label: 'AMOUNT_NOT_COVERED',
        show: this.getBalanceAmount(balance.claimMemberNotCovered) > 0,
      },
    ];

    this.addPieChartData(items);

    return items;
  }

  private getFinancialClaimLineItems(claimDetails: IFinancialClaimDetails): IBreakdownLineItem[] {
    const { balance, claimDates } = claimDetails;

    const items = [
      {
        label: 'AMOUNT_SUBMITTED',
        amount: this.getBalanceAmount(balance.amountSubmitted),
        dateProcessed: claimDates.dateProcessed,
        show: true,
        totalItem: true,
      },
      {
        label: 'PLAN_ABBREVIATION_PAID',
        amount: this.getBalanceAmount(balance.amountPlanPaid),
        color: PieChartColor.teal as PieChartColor,
        dateProcessed: claimDates.dateProcessed,
        includeInTotal: true,
        show: true,
      },
      {
        label: 'AMOUNT_PENDING',
        amount: this.getBalanceAmount(balance.amountPending),
        color: PieChartColor.red as PieChartColor,
        includeInTotal: true,
        show: true,
      },
    ];

    this.addPieChartData(items);

    return items;
  }

  private getBalanceAmount(amount?: ICurrencyAmount): number {
    return amount && amount.value ? amount.value : 0;
  }

  private getPercentage(amount: number, total: number): number {
    const percentage = amount / total;
    return percentage > 1 ? 1 : percentage;
  }

  private getOnlinePaymentTitle(transactionType: ClaimTransactionType): string {
    switch (transactionType) {
      case ClaimTransactionType.ChargeBack:
        return 'ONLINE_PAYMENT_CHARGEBACK';
      case ClaimTransactionType.Refund:
        return 'ONLINE_PAYMENT_REFUND';
      case ClaimTransactionType.Return:
        return 'ONLINE_PAYMENT_RETURN';
      default:
        return 'YOU_PAID_ONLINE';
    }
  }

  private addPieChartData(items: IBreakdownLineItem[]): void {
    const shownItems = items.filter(item => item.show);
    const itemsIncludedInTotal = shownItems.filter(item => item.includeInTotal);
    const calculatedTotalAmount = itemsIncludedInTotal.map(item => item.amount).reduce((a, c) => a + c, 0);
    const totalItem = items.filter(item => item.totalItem);
    const totalItemAmount = totalItem && totalItem.length > 0 ? totalItem[0].amount : 0;
    const totalToUse = calculatedTotalAmount > totalItemAmount ? calculatedTotalAmount : totalItemAmount;

    let cumulativePercent = 0;

    shownItems.forEach(item => {
      if (item.totalItem) {
        item.pie = {
          slices: itemsIncludedInTotal.map(includedItem => ({
            percent: this.getPercentage(includedItem.amount, totalToUse),
            color: includedItem.color,
          })),
          border: calculatedTotalAmount === 0 ? (PieChartColor.black as PieChartColor) : undefined,
          spacing: true,
        };
      } else {
        const percent = this.getPercentage(item.amount, totalToUse);
        item.pie = {
          slices: [
            {
              percent,
              color: item.color,
            },
          ],
          border: item.color,
          spacing: false,
        };
        if (item.includeInTotal) {
          item.pie.rotation = cumulativePercent;
          cumulativePercent += percent;
        }
      }
    });
  }
}

export class ClaimDetailsBreakdownComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;
  public bindings = {
    claimDetails: '<',
  };

  constructor() {
    this.controller = ClaimDetailsBreakdownController;
    this.templateUrl = claimDetailsBreakdownTemplate;
  }
}
