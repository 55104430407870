import React, { FunctionComponent, ReactElement } from 'react';
// import { useTranslation } from 'react-i18next';
import { BenefitNetwork } from 'scripts/api/plans/plans.interfaces';
// import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IAccumulatorHeaderProps {
  benefitNetwork: BenefitNetwork;
  label: string | ReactElement;
}

// See PAY-889. We will use this header label again in the near future.  So for now, I am only commenting out instead of deleting.

// const NetworkStringIdMap = {
//   [BenefitNetwork.InNetwork]: 'IN_NETWORK',
//   [BenefitNetwork.OutOfNetwork]: 'OUT_OF_NETWORK',
//   [BenefitNetwork.Tier1]: 'TIER_ONE',
// };

export const AccumulatorHeader: FunctionComponent<IAccumulatorHeaderProps> = props => {
  const { /* benefitNetwork, */ label } = props;
  // const { t } = useTranslation(Dictionary.COMMON);
  return (
    <header className="accumulator-header" data-testid="accumulator-header">
      <div className="accumulator-label" data-testid="accumulator-label">
        {' '}
        {label}{' '}
      </div>
      {/*
      <div className="accumulator-network network-color" data-testid="accumulator-network"> {t(NetworkStringIdMap[benefitNetwork])} </div>
      */}
    </header>
  );
};
