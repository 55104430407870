import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import {
  dcsaPdfForm,
  dentalOnlineForm,
  fsahcPdfForm,
  fsaOnlineForm,
  hraOnlineForm,
  hraPdfForm,
  jpmcOnlineForm,
  medicalOnlineForm,
  mentalHealthOnlineForm,
  mraHcsaPdfForm,
  medicalPDFForm,
} from 'scripts/util/resource/resource.constants';
import {
  isDcsaAccount,
  isFsaAccount,
  isFsaDcAccount,
  isFsaHcAccount,
  isHcsaAccount,
  isHraAccount,
  isJpmcAccount,
  isMraAccount,
} from 'scripts/util/ledger/ledger';
import { CoverageType } from 'scripts/api/api.interfaces';
import { ClaimFormType } from 'scripts/api/targeting/targeting.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import IconMedical from 'images/icons/icon-medical.svg';
import IconDental from 'images/icons/icon-dental.svg';
import IconMentalHealth from 'images/icons/icon-mental-health.svg';
import IconClaims from 'images/icons/icon-claims.svg';
import IconPharmacy from 'images/icons/icon-pharmacy.svg';
import SubmitClaimLink from './submit-claim-link';
import {
  withCustomTargetingPdf,
  withTargetingShowOverride,
  hasActiveOrTermedCoverage,
  hasAccount,
  hasTargetingSuppressOnlineFSAHRASubmission,
} from './submit-claim-utils';
import { ISubmitClaimLinkProps, ISubmitClaimLinksProps } from './submit-claim-links.interface';

export const MedicalOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  clientConfig,
  population,
  t,
}) => {
  if (!withTargetingShowOverride(ClaimFormType.MEDICAL_ONLINE, false)(clientConfig)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={withCustomTargetingPdf('MEDICAL_ONLINE', medicalOnlineForm)(clientConfig, population)}
      Icon={IconMedical}
      text={t('ONLINE_SUBMISSION')}
      title={t('MEDICAL_CLAIMS')}
    />
  );
};

export const MedicalPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  clientConfig,
  population,
  t,
}) => {
  if (!withTargetingShowOverride(ClaimFormType.MEDICAL_PDF, true)(clientConfig)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={withCustomTargetingPdf('MEDICAL_PDF', medicalPDFForm)(clientConfig, population)}
      Icon={IconMedical}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('MEDICAL_CLAIMS')}
    />
  );
};

export const DentalOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  currentUser,
  population,
  t,
}) => {
  if (!hasActiveOrTermedCoverage(CoverageType.Dental)(currentUser)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(dentalOnlineForm, population)}
      Icon={IconDental}
      text={t('ONLINE_SUBMISSION')}
      title={t('DENTAL_CLAIMS')}
    />
  );
};

export const MentalHealthOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  currentUser,
  population,
  t,
}) => {
  if (!hasActiveOrTermedCoverage(CoverageType.BehavioralHealth)(currentUser)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(mentalHealthOnlineForm, population)}
      Icon={IconMentalHealth}
      text={t('ONLINE_SUBMISSION')}
      title={t('MENTAL_HEALTH_CLAIMS')}
    />
  );
};

export const MentalHealthPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  currentUser,
  clientConfig,
  population,
  t,
}) => {
  const href = withCustomTargetingPdf('MENTAL_HEALTH_PDF')(clientConfig, population);
  if (!href || !hasActiveOrTermedCoverage(CoverageType.BehavioralHealth)(currentUser)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={href}
      Icon={IconMentalHealth}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('MENTAL_HEALTH_CLAIMS')}
    />
  );
};

export const FsaOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({ accounts, population, t }) => {
  if (!hasAccount(isFsaAccount)(accounts)) {
    return null;
  }

  let title = 'FSA_CLAIMS';
  if (hasAccount(isFsaDcAccount)(accounts) && hasAccount(isFsaHcAccount)(accounts)) {
    title = 'FSA_HC_AND_DC_CLAIMS';
  } else if (hasAccount(isFsaDcAccount)(accounts)) {
    title = 'FSA_DC_CLAIMS';
  } else if (hasAccount(isFsaHcAccount)(accounts)) {
    title = 'FSA_HC_CLAIMS';
  }

  return (
    <SubmitClaimLink
      href={getResource(fsaOnlineForm, population)}
      Icon={IconClaims}
      text={t('ONLINE_SUBMISSION')}
      title={t(title)}
    />
  );
};

export const FsaHcPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  accounts,
  clientConfig,
  population,
  t,
}) => {
  if (!hasAccount(isFsaHcAccount)(accounts)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={withCustomTargetingPdf('FSA_HC_PDF', fsahcPdfForm)(clientConfig, population)}
      Icon={IconClaims}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('FSA_HC_CLAIMS')}
    />
  );
};

export const FsaDcPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  accounts,
  clientConfig,
  population,
  t,
}) => {
  const href = withCustomTargetingPdf('FSA_DC_PDF')(clientConfig, population);
  if (!href || !hasAccount(isFsaDcAccount)(accounts)) {
    return null;
  }

  return <SubmitClaimLink href={href} Icon={IconClaims} text={t('PDF_SUBMISSION_FORM')} title={t('FSA_DC_CLAIMS')} />;
};

export const HraOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  accounts,
  clientConfig,
  population,
  t,
}) => {
  if (!hasAccount(isHraAccount)(accounts) || hasTargetingSuppressOnlineFSAHRASubmission(clientConfig)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(hraOnlineForm, population)}
      Icon={IconClaims}
      text={t('ONLINE_SUBMISSION')}
      title={t('HRA_CLAIMS')}
    />
  );
};

export const HraPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  accounts,
  clientConfig,
  population,
  t,
}) => {
  if (!hasAccount(isHraAccount)(accounts)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={withCustomTargetingPdf('HRA_PDF', hraPdfForm)(clientConfig, population)}
      Icon={IconClaims}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('HRA_CLAIMS')}
    />
  );
};

export const JpmcOnlineSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({ accounts, population, t }) => {
  if (!hasAccount(isJpmcAccount)(accounts)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(jpmcOnlineForm, population)}
      Icon={IconClaims}
      text={t('ONLINE_SUBMISSION')}
      title={t('JPMC_CLAIMS')}
    />
  );
};

export const DcsaPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({ accounts, population, t }) => {
  if (!hasAccount(isDcsaAccount)(accounts)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(dcsaPdfForm, population)}
      Icon={IconClaims}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('DCSA_CLAIMS')}
    />
  );
};

export const MraHcsaPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({ accounts, population, t }) => {
  if (!hasAccount(isMraAccount)(accounts) && !hasAccount(isHcsaAccount)(accounts)) {
    return null;
  }

  return (
    <SubmitClaimLink
      href={getResource(mraHcsaPdfForm, population)}
      Icon={IconClaims}
      text={t('PDF_SUBMISSION_FORM')}
      title={t('MRA_HCSA_CLAIMS')}
    />
  );
};

export const RxPdfSubmitClaimLink: FunctionComponent<ISubmitClaimLinkProps> = ({
  currentUser,
  clientConfig,
  population,
  t,
}) => {
  const href = withCustomTargetingPdf('RX_PDF')(clientConfig, population);
  if (!href || !hasActiveOrTermedCoverage(CoverageType.Rx)(currentUser)) {
    return null;
  }

  return <SubmitClaimLink href={href} Icon={IconPharmacy} text={t('PDF_SUBMISSION_FORM')} title={t('RX_CLAIMS')} />;
};

const DefaultSubmitClaimLinks: FunctionComponent<ISubmitClaimLinksProps> = props => {
  const { t } = useTranslation(Dictionary.SUBMIT_CLAIM);
  const linkProps = { ...props, t };
  return (
    <>
      <MedicalOnlineSubmitClaimLink {...linkProps} />
      <MedicalPdfSubmitClaimLink {...linkProps} />
      <DentalOnlineSubmitClaimLink {...linkProps} />
      <MentalHealthOnlineSubmitClaimLink {...linkProps} />
      <MentalHealthPdfSubmitClaimLink {...linkProps} />
      <FsaOnlineSubmitClaimLink {...linkProps} />
      <FsaHcPdfSubmitClaimLink {...linkProps} />
      <FsaDcPdfSubmitClaimLink {...linkProps} />
      <HraOnlineSubmitClaimLink {...linkProps} />
      <HraPdfSubmitClaimLink {...linkProps} />
      <JpmcOnlineSubmitClaimLink {...linkProps} />
      <DcsaPdfSubmitClaimLink {...linkProps} />
      <MraHcsaPdfSubmitClaimLink {...linkProps} />
      <RxPdfSubmitClaimLink {...linkProps} />
    </>
  );
};

export default DefaultSubmitClaimLinks;
