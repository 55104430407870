import { Observable } from 'rxjs/Observable';
import { exchangePremiumPayments, revenueAdvantage } from 'scripts/util/resource/resource.constants';
import premiumPaymentsTemplate from 'views/states/premium-payments.html';
import { CsPrimaryCustomerId, IProfileUserInfo, MembershipCategory } from '../../api/profile/profile.interfaces';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { ProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { IEnvironmentConstants } from '../../util/constants/environment.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IPopulationService } from '../../util/population/population.service';
import { IResourceService } from '../../util/resource/resource.service';

export class PremiumPaymentsController implements ng.IComponentController {
  public features: string[];
  public request: Observable<any>;
  public revenueAdvantageUrl: string;
  public exchangePremiumPaymentsUrl: string;
  public isCS: boolean;
  public isEI: boolean;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private Environment: IEnvironmentConstants,
    private profileService: ProfileService,
    private populationService: IPopulationService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PREMIUM_PAYMENTS);
  }

  public $onInit(): void {
    const pop = this.populationService.getPopulation();
    this.isCS = pop && pop.lineOfBusiness === LineOfBusiness.CS;
    this.isEI = pop && pop.lineOfBusiness === LineOfBusiness.EI;

    if (this.isCS) {
      this.features = [
        'PREMIUM_PAYMENTS_MAKE_PAYMENT',
        'PREMIUM_PAYMENTS_SEE_PREMIUM',
        'PREMIUM_PAYMENTS_RECURRING_PAYMENTS',
        'PREMIUM_PAYMENTS_ADD_PAYMENT_METHOD',
      ];
    } else {
      this.features = [
        'PREMIUM_PAYMENTS_SIGN_UP',
        'PREMIUM_PAYMENTS_SEE_HISTORY',
        'PREMIUM_PAYMENTS_CHECK_COVERAGE',
        'PREMIUM_PAYMENTS_VIEW_ACCOUNT_INFO',
      ];
    }

    this.request = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data.currentUser)
      .map(currentUser => {
        const { ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_CUSTOMER_IDS } = this.Environment.CONFIG;
        const validRevenueAdvantagePrimaryCustomerIds = ARCADE_WEB_CS_PREMIUM_PAYMENT_PRIMARY_CUSTOMER_IDS.trim().split(
          ',',
        );
        if (
          this.isEI &&
          (currentUser.membershipCategory === MembershipCategory.EXCHANGE ||
            currentUser.membershipCategory === MembershipCategory.PUBLIC_EXCHANGE)
        ) {
          this.exchangePremiumPaymentsUrl = this.resourceService.get(exchangePremiumPayments);
        } else if (validRevenueAdvantagePrimaryCustomerIds.some(id => id === currentUser.userInfo.primaryCustomerId)) {
          return currentUser.userInfo.primaryCustomerId;
        } else {
          throw Observable.throw('This user is not eligible for premium payments');
        }
      });

    this.request.subscribe(
      primaryCustomerId => {
        this.revenueAdvantageUrl =
          this.resourceService.get(revenueAdvantage) + this.getRevenueAdvantageUrlSuffix(primaryCustomerId);
      },
      err => {
        console.warn(err);
      },
    );
  }

  private getRevenueAdvantageUrlSuffix(primaryCustomerId: IProfileUserInfo['primaryCustomerId']): string {
    if (primaryCustomerId === CsPrimaryCustomerId.NYCHP) {
      // inconsistent edge case where the primaryCustomerId is actually NYCHP, but the correct url suffix is 'NYCHIP', see ARC-6415
      return 'NYCHIP';
    } else if (primaryCustomerId === CsPrimaryCustomerId.PACHIP) {
      // previously informed that the primaryCustomerId was PACHIP, 0PACHIP is the actual correct id so the enum has been updated
      // returning the correct revenue advantage URL suffix for this scenario which is the previous PACHIP, see ARC-6471
      return 'PACHIP';
    }

    return primaryCustomerId;
  }
}

export class PremiumPaymentsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PremiumPaymentsController;
    this.templateUrl = premiumPaymentsTemplate;
  }
}
