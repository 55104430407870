import React, { FunctionComponent, HTMLAttributes } from 'react';
import { IClaim } from 'scripts/api/claims/claims.interfaces';
import { Claim } from 'scripts/ui/claim/claim';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { Maybe } from 'scripts/arcade.module.interfaces';

interface IAllClaimsBodyProps {
  claims: Maybe<IClaim[]>;
  clientConfig: Maybe<IClientConfig>;
  error: boolean;
  loading: boolean;
  onRetry: () => void;
  onClaimUpdate: () => void;
}

const Div: FunctionComponent<HTMLAttributes<HTMLDivElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

export const AllClaimsBody: FunctionComponent<IAllClaimsBodyProps> = props => {
  const { claims, clientConfig, error, loading, onClaimUpdate, onRetry } = props;
  const { t } = useTranslation([Dictionary.ALL_CLAIMS, Dictionary.ERRORS]);
  return (
    <div className="claims-container">
      <DivWithWaitFor
        loading={loading}
        error={error}
        onRetry={onRetry}
        errorMessage={t(`${Dictionary.ERRORS}:ERR_ALL_CLAIMS`)}
        trackLabel="all-claims"
        trackData={{ numClaims: claims ? claims.length : 0 }}
      >
        {claims && claims.length == 0 && (
          <div className="no-all-claims center" data-testid="no-all-claims">
            <p className="no-all-claims-message">{t(`${Dictionary.ALL_CLAIMS}:NO_ALL_CLAIMS`)}</p>
          </div>
        )}
        <div data-testid="all-claims-container">
          {claims &&
            clientConfig &&
            claims.map(claim => (
              <Claim claim={claim} clientConfig={clientConfig} onClaimUpdate={onClaimUpdate} key={claim.claimKey} />
            ))}
        </div>
      </DivWithWaitFor>
    </div>
  );
};
