import { Observable } from 'rxjs/Observable';
import claimMarkPaidTemplate from 'views/ui/claim-mark-paid.html';
import { IClaim, IHealthcareClaimDetails } from '../../api/claims/claims.interfaces';
import { IClaimsService } from '../../api/claims/claims.service';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';

export class ClaimMarkPaidController implements ng.IComponentController {
  // Bindings
  public claim: IClaim | IHealthcareClaimDetails;

  public loading: boolean;

  constructor(
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private profileService: IProfileService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
  }

  public togglePaid(claim: IClaim): void {
    this.loading = true;
    this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId => this.profileService.getClaimsPreferences(rallyId))
      .map(rsp => rsp.data.markAsPaid.showDisclaimer)
      .catch(err => {
        console.warn(err);
        return Observable.of(false);
      })
      .flatMap(showDisclaimer => {
        if (showDisclaimer) {
          claim.claimManagementInfo.markPaid = !claim.claimManagementInfo.markPaid;
          this.$timeout(() => {
            this.$state.go('modal.markAsPaidExplanation', {
              claimId: claim.claimId,
              explanationFrom: this.$state.href(this.$state.current),
            });
          });
          return Observable.of({});
        } else {
          return this.userService
            .getHeartbeat()
            .let(this.profileService.toProfile())
            .map(rsp => rsp.data.currentUser)
            .flatMap(currentUser => this.claimsService.togglePaid(currentUser, claim));
        }
      })
      .subscribe(
        () => (this.loading = false),
        err => {
          console.warn(err);
          this.loading = false;
          claim.claimManagementInfo.markPaid = !claim.claimManagementInfo.markPaid;
        },
      );
  }
}

export class ClaimMarkPaidComponent implements ng.IComponentOptions {
  public controller = ClaimMarkPaidController;
  public templateUrl = claimMarkPaidTemplate;
  public bindings = {
    claim: '<',
  };
}
