import React, { AnchorHTMLAttributes, forwardRef, FunctionComponent, Ref } from 'react';
import { LinkTarget } from 'scripts/api/api.interfaces';
import ExternalLinks from 'scripts/ui/external-link/external-link';

export interface IAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  noNewWindowIcon?: boolean;
  noRel?: boolean;
  forwardedRef?: Ref<HTMLAnchorElement>;
}

const RawAnchor: FunctionComponent<IAnchorProps> = props => {
  const { children, forwardedRef, noNewWindowIcon, noRel, target, ...rest } = props;
  const isBlank = target === LinkTarget.Blank;
  return (
    <a ref={forwardedRef} rel={!noRel && isBlank ? 'noopener noreferrer' : undefined} target={target} {...rest}>
      {children}
      {!noNewWindowIcon && isBlank && <ExternalLinks />}
    </a>
  );
};

export const Anchor = forwardRef((props: IAnchorProps, ref: Ref<HTMLAnchorElement>) => (
  <RawAnchor forwardedRef={ref} {...props} />
));
