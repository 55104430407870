import { BenefitNetwork, IPlanAccumulators, IPlanBenefits } from 'scripts/api/plans/plans.interfaces';
import { getAccumulators, getBenefits } from 'scripts/thunks/plans-service-thunks';
import React, { FunctionComponent, HTMLAttributes, useEffect } from 'react';
import {
  selectAccumulatorsData,
  selectAccumulatorsError,
  selectAccumulatorsLoading,
  selectBenefitsData,
  selectBenefitsError,
  selectBenefitsLoading,
} from 'scripts/selectors/plans-service-selectors';
import { connect } from 'react-redux';
import { getBenefitsSpending } from 'scripts/util/plans/plans';
import { IBenefitsSpending } from 'scripts/ui/accumulator/accumulator.interfaces';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { withWaitFor } from 'scripts/hoc/with-wait-for/with-wait-for';
import { AccumulatorsList } from 'scripts/ui/accumulators-list/accumulators-list';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';

interface IAccumulatorsListConnectedProps
  extends IAccumulatorsListConnectedStateToProps,
    IAccumulatorsListConnectedDispatchToProps {
  disableLabelModal?: boolean;
  networkFilter?: BenefitNetwork;
  showPlanName?: boolean;
  trackLabel?: string;
}

interface IAccumulatorsListConnectedStateToProps {
  accumulatorsError: boolean;
  accumulatorsLoading: boolean;
  benefitsError: boolean;
  benefitsLoading: boolean;
  planAccumulators?: IPlanAccumulators;
  planBenefits?: IPlanBenefits;
}

interface IAccumulatorsListConnectedDispatchToProps {
  getAccumulators: () => void;
  getBenefits: () => void;
}

const Div: FunctionComponent<HTMLAttributes<HTMLElement>> = props => <div {...props} />;
const DivWithWaitFor = withWaitFor(Div);

export const RawAccumulatorsListConnected: FunctionComponent<IAccumulatorsListConnectedProps> = props => {
  const {
    accumulatorsError,
    accumulatorsLoading,
    benefitsError,
    benefitsLoading,
    disableLabelModal,
    networkFilter,
    planAccumulators,
    planBenefits,
    showPlanName = false,
  } = props;
  const { t } = useTranslation(Dictionary.ERRORS);

  let accumData: IBenefitsSpending[] = [];
  const error = accumulatorsError || benefitsError;
  const loading = accumulatorsLoading || benefitsLoading;

  useEffect(() => {
    if (!planAccumulators) {
      props.getAccumulators();
    }
    if (!planBenefits) {
      props.getBenefits();
    }
    return () => {};
  }, []);

  if (planAccumulators && planBenefits) {
    accumData = getBenefitsSpending(planAccumulators, planBenefits);
    if (networkFilter) {
      accumData = accumData.filter(benefitSpending => benefitSpending.network === networkFilter);
    }
  }

  const handleRetry = (): void => {
    props.getAccumulators();
    props.getBenefits();
  };

  const { children, trackLabel = 'accumulators-list' } = props;

  return (
    <DivWithWaitFor
      className="container accumulators-list-container"
      error={error}
      errorMessage={t('ERR_ACCUMULATORS')}
      loading={loading}
      onRetry={handleRetry}
      trackLabel={trackLabel}
    >
      <AccumulatorsList accumData={accumData} disableLabelModal={disableLabelModal} showPlanName={showPlanName} />
      {children}
    </DivWithWaitFor>
  );
};

const mapStateToProps = (state: IReduxState): IAccumulatorsListConnectedStateToProps => ({
  accumulatorsError: selectAccumulatorsError(state),
  accumulatorsLoading: selectAccumulatorsLoading(state),
  benefitsError: selectBenefitsError(state),
  benefitsLoading: selectBenefitsLoading(state),
  planAccumulators: selectAccumulatorsData(state),
  planBenefits: selectBenefitsData(state),
});

export const AccumulatorsListConnected = withProvider(
  connect(
    mapStateToProps,
    {
      getAccumulators,
      getBenefits,
    },
  )(RawAccumulatorsListConnected),
);
