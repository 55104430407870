import moment from 'moment';
import { uhcCommunityPlan } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import expirationWarningTemplate from 'views/chrome/expiration-warning.html';
import { CoverageStatus, CoverageType } from '../../api/api.interfaces';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { IProfileService } from '../../api/profile/profile.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IFeatureFlagService } from './../../util/feature-flag/feature-flag.interface';
import { ResourceService } from './../../util/resource/resource.service';

export class ExpirationWarningController implements ng.IComponentController {
  public warningNeeded: boolean;
  public daysRemaining: number;
  public dismissed: boolean;
  public expiredMessage: string;
  public learnMoreLink: IResource;
  public subheaderMessage: string;
  private endDate: moment.Moment;
  private coverageStatus: CoverageStatus;
  private isMOHNET: boolean;
  private isNJFAMCAR: boolean;
  private warningKey = 'arcade.warning';

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $window: ng.IWindowService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    private resourceService: ResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.EXPIRATION_WARNING);

    const value = this.$window.sessionStorage.getItem(this.warningKey);
    this.dismissed = typeof value === 'string' ? JSON.parse(value) : false;

    this.warningNeeded = false;
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .takeWhile(currentUser => currentUser.lineOfBusiness === LineOfBusiness.CS)
      .map(({ planCoverages }) => this.profileService.getCoverage(CoverageType.Medical, planCoverages))
      .subscribe(medicalCoverage => {
        this.coverageStatus = medicalCoverage.planPeriod.status;
        this.endDate = medicalCoverage.planPeriod.endDate;

        if (this.coverageStatus != null && this.coverageStatus === CoverageStatus.Termed) {
          // plan has expired
          this.warningNeeded = true;
        } else if (this.endDate != null && moment(this.endDate).isSameOrBefore(moment().add(60, 'days'))) {
          // end date is within 60 days
          this.warningNeeded = true;
          this.daysRemaining = moment(this.endDate).diff(moment().startOf('date'), 'days');
        }
        this.learnMoreLink = uhcCommunityPlan;
        this.expiredMessage = 'EXPIRED_HEADER';
        this.subheaderMessage = 'EXPIRATION_SUBHEADER';

        this.isMOHNET = medicalCoverage.policyNumber === 'MOHNET';
        if (this.isMOHNET) {
          this.subheaderMessage = 'EXPIRATION_SUBHEADER_MOHNET';
        }

        this.isNJFAMCAR = medicalCoverage.policyNumber === 'NJFAMCAR';
        if (this.isNJFAMCAR) {
          this.subheaderMessage = 'EXPIRATION_SUBHEADER_NJFAMCAR';
        }
      });
  }

  public dismiss(): void {
    this.dismissed = true;
    this.$window.sessionStorage.setItem(this.warningKey, JSON.stringify(this.dismissed));
  }
}

export class ExpirationWarningComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = ExpirationWarningController;
    this.templateUrl = expirationWarningTemplate;
  }
}
