import moment from 'moment';
import { Observable } from 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';
import { connectHealthChecklist, recommendedCare } from 'scripts/util/resource/resource.constants';
import { CoverageStatus, CoverageType, RelationshipType } from '../../../api/api.interfaces';
import {
  IPlanCoverage,
  IProducts,
  IProfile,
  IProfileUser,
  LineOfBusiness,
  ProgramType,
  State,
} from '../../../api/profile/profile.interfaces';
import { IProfileService, ProfileService } from '../../../api/profile/profile.service';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../../util/resource/resource.service';
import { canShowRecommendations, isEmpire } from '../../../util/user/user';
import { RecommendationsStorageKey } from '../../recommendations/recommendations.component';

export class AccountInfoController implements ng.IComponentController {
  public selectedUser: IProfileUser;
  public coverages: IPlanCoverage[];
  public showMemberDropdown: boolean;
  public showRecs: boolean;
  public numRecs: number;
  public recsRequest: Observable<number>;
  public isMrPreEffective: boolean;
  public isCS: boolean;
  public isTermedCS: boolean;
  public coverageStartDate?: moment.Moment;
  public coverageEndDate?: moment.Moment;
  public recLink: string;
  public plansRequest: Observable<IProducts | boolean>;
  private loggedInUserDependentSeqNum: IProfileUser['dependentSeqNum'];
  private productRequest$: Observable<IProducts>;
  private isCSNewJersey: boolean;
  private isEmpire: boolean;
  private onlyShipPlans: boolean;
  private profileSubscription: Subscription;
  private hasMentalHealthCoverage: boolean;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private featureFlagService: IFeatureFlagService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_INFO);
    $translatePartialLoader.addPart(Dictionary.COMMON);
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .subscribe(rsp => {
        this.showMemberDropdown = AccountInfoController.canViewOtherMembers(rsp.data);
        const { currentUser } = rsp.data;
        this.loggedInUserDependentSeqNum = currentUser.dependentSeqNum;
        this.isCS = currentUser.lineOfBusiness === LineOfBusiness.CS;
        this.isCSNewJersey = this.isCS && currentUser.userInfo.state === State.NJ;
        this.isEmpire = isEmpire(currentUser);
        this.isMrPreEffective =
          currentUser.lineOfBusiness === LineOfBusiness.MR &&
          currentUser.planCoverages.length > 0 &&
          currentUser.planCoverages.every(c => c.planPeriod.status === CoverageStatus.Future);
        if (this.isMrPreEffective) {
          this.coverageStartDate = AccountInfoController.getEarliestStartDate(currentUser.planCoverages);
        }
        this.isTermedCS =
          this.isCS && this.profileService.isTermedForCoverageType(CoverageType.Medical, currentUser.planCoverages);
        this.recLink = this.getRecLink(currentUser);
        if (this.isCS) {
          this.coverageEndDate = AccountInfoController.getValidEndDate(currentUser.planCoverages);
          this.productRequest$ = this.profileService
            .getProducts(rsp.data.rallyId)
            .map(({ data }) => data.products)
            .do(products => {
              this.hasMentalHealthCoverage = !!products.liveAndWorkWell;
            });
        }
        this.plansRequest = Observable.if(() => this.isCS, this.productRequest$, Observable.of(true));
      }, console.warn);

    this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .map(rsp => {
        rsp.planCoverages.forEach(coverage => {
          if (ProfileService.isRxCarveOut(coverage) && typeof coverage.memberId !== 'undefined') {
            delete coverage.memberId;
          }
        });
        return rsp;
      })
      .subscribe(rsp => {
        this.selectedUser = rsp;
        this.coverages = rsp.planCoverages;
        this.onlyShipPlans = this.coverages.every(cov => cov.planFeatures.programType === ProgramType.Ship);
        this.setShowRecommendations();
      }, console.warn);

    this.profileSubscription = this.profileService.profileChanged.subscribe(profile => {
      this.selectedUser = profile;
      this.setShowRecommendations();
      this.coverages = profile.planCoverages;
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  public getMedicalCoverage(): IPlanCoverage[] {
    return this.coverages.filter(cov => cov.coverageType === CoverageType.Medical);
  }

  public getMemberIdLabel(plan: IPlanCoverage): string {
    let coverageTypeStr: string =
      this.selectedUser.lineOfBusiness === LineOfBusiness.MR && plan.coverageTypeCode
        ? `${plan.coverageType}_${plan.coverageTypeCode}`
        : plan.coverageType;

    if (plan.coverageType === CoverageType.Medical) {
      if (this.isCS) {
        // filter out coverage types that are already in the medical coverage's additionalCoverageTypes
        const nonMedicalCoverages = this.coverages
          .filter(
            cov =>
              cov.coverageType !== CoverageType.Medical &&
              plan.additionalCoverageTypes.indexOf(cov.coverageType) === -1,
          )
          .map(cov => cov.coverageType);
        // add in coverage from additionalCoverageTypes and filter out the behavioral health coverage from the profile response
        const allRelevantNonMedicalCoverages = plan.additionalCoverageTypes
          .concat(nonMedicalCoverages)
          .filter(coverageType => coverageType !== CoverageType.BehavioralHealth);
        // add behavioral health coverage from the plan summary response if applicable
        if (this.hasMentalHealthCoverage) {
          allRelevantNonMedicalCoverages.push(CoverageType.BehavioralHealth);
        }
        allRelevantNonMedicalCoverages.sort().forEach(coverage => {
          coverageTypeStr += `_${coverage.toUpperCase()}`;
        });
      } else {
        plan.additionalCoverageTypes
          .filter(c => c === CoverageType.Rx || c === CoverageType.Vision || c === CoverageType.BehavioralHealth)
          .sort()
          .forEach(coverage => {
            coverageTypeStr += `_${coverage.toUpperCase()}`;
          });
      }
    }

    return ProfileService.isRxCarveOut(plan) ? CoverageType.Rx : coverageTypeStr.toUpperCase() + '_MEMBER_ID';
  }

  public getViewIdLinkText(): string {
    if (this.isEmpire) {
      return 'VIEW_COVERAGE_DETAILS';
    } else if (this.isCSNewJersey) {
      return this.onlyShipPlans ? 'VIEW_MEMBER_INSURANCE_CARDS' : 'VIEW_MEMBER_CARD';
    } else if (this.onlyShipPlans) {
      return 'PRINT_INSURANCE_CARDS';
    } else {
      return this.isCS ? 'PRINT_CARD' : 'PRINT_CARDS';
    }
  }

  public openMemberSelectModal(): void {
    this.$timeout(() => {
      this.$state.go('modal.accountSelector');
    });
  }

  private setShowRecommendations(): void {
    // NOTE: any changes to this logic should also be reflected in routes.config.ts (impression tracking)
    const isLoggedInUserSelected = this.loggedInUserDependentSeqNum === this.selectedUser.dependentSeqNum;
    this.showRecs = isLoggedInUserSelected && canShowRecommendations(this.selectedUser, this.featureFlagService);
    if (this.showRecs) {
      this.setNumRecommendations(this.selectedUser.lineOfBusiness);
    }
  }

  private setNumRecommendations(lob: LineOfBusiness): void {
    const storedRecs = JSON.parse(this.$window.sessionStorage.getItem(RecommendationsStorageKey));

    const numActiveRequest$ = this.userService
      .getHeartbeat()
      .flatMap(rsp => this.targetingService.getRealTimeOfferCount(rsp.data.rallyId, lob))
      .filter(({ data }) => !!data && data.leadPromotionCount > -1)
      .map(({ data: { leadPromotionCount } }) => leadPromotionCount);

    // timeout to ensure that max-width css transition happens (num-recs-loaded class will be added on subsequent digest cycle)
    this.$timeout(() => {
      this.recsRequest = Observable.if(
        () => storedRecs,
        Observable.of(storedRecs && storedRecs.numActive),
        numActiveRequest$,
      );
      this.recsRequest.subscribe(
        numRecs => {
          if (numRecs > 0) {
            this.numRecs = numRecs;
          } else {
            this.showRecs = false;
          }
        },
        err => {
          console.warn(err);
          this.showRecs = false;
        },
      );
    });
  }

  private getRecLink(currentUser: IProfileUser): string {
    if (currentUser.lineOfBusiness === LineOfBusiness.MR) {
      return decodeURIComponent(this.$state.href('authenticated.recommendations'));
    } else if (currentUser.lineOfBusiness === LineOfBusiness.EI && this.featureFlagService.isEiRecommendationsOn()) {
      return this.$state.href('internalRedirect', { deepLink: this.resourceService.get(connectHealthChecklist) });
    } else {
      return this.resourceService.get(recommendedCare);
    }
  }

  private static canViewOtherMembers(profileData: IProfile): boolean {
    if (profileData.currentUser.relationshipType !== RelationshipType.Subscriber) {
      return false;
    }
    for (const dependent of profileData.dependents) {
      if (!dependent.memberFeatures.viewRestricted) {
        return true;
      }
    }
    return false;
  }

  private static getEarliestStartDate(planCoverages: IPlanCoverage[]): moment.Moment {
    let earliest = moment(new Date(9999, 0, 1));
    planCoverages.forEach(c => {
      if (moment(c.planPeriod.startDate).isBefore(earliest)) {
        earliest = moment(c.planPeriod.startDate);
      }
    });
    return earliest;
  }

  private static getValidEndDate(planCoverages: IPlanCoverage[]): moment.Moment | undefined {
    const medicalCoverage = planCoverages.filter(cov => cov.coverageType === CoverageType.Medical)[0];
    if (medicalCoverage && medicalCoverage.planPeriod) {
      const possibleEndDate = medicalCoverage.planPeriod.endDate;
      if (moment(possibleEndDate).isBefore(moment(new Date(9999, 1, 1)), 'day')) {
        return possibleEndDate;
      }
    }
  }
}
