import { Observable } from 'rxjs/Observable';
import { connectQueryLocale, connectReferrals } from 'scripts/util/resource/resource.constants';
import pcpReferralsTemplate from 'views/states/pcp-referrals.html';
import { CoverageType } from 'scripts/api/api.interfaces';
import { IBenefit } from 'scripts/api/plans/plans.interfaces';
import { IPlansService } from 'scripts/api/plans/plans.service';
import {
  IPhysician,
  IProfile,
  IProfileUser,
  IReferralInfo,
  IReferralsPerMember,
} from 'scripts/api/profile/profile.interfaces';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IUserService } from 'scripts/api/user/user.service';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IResourceService } from 'scripts/util/resource/resource.service';

export class PcpReferralsController implements ng.IComponentController {
  public showPatientName: boolean;
  public referrals: IReferralInfo[];
  public planName: string;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.PCP);

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(profileRsp => profileRsp.data)
      .do(profile => (this.showPatientName = profile.dependents && profile.dependents.length > 0))
      .flatMap(profile => Observable.zip(this.setReferrals(profile), this.setPlanName(profile)))
      .subscribe(() => undefined, console.warn);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  public getPhysicianLink(physician: IPhysician): string {
    return (
      this.resourceService.get(connectReferrals) + physician.providerId + this.resourceService.get(connectQueryLocale)
    );
  }

  private setReferrals(profile: IProfile): Observable<IReferralsPerMember> {
    return this.profileService
      .getReferrals(profile.rallyId)
      .map(referralsInfo => referralsInfo.data.perMemberReferrals)
      .do(perMemberReferrals => {
        this.referrals = [];
        this.addReferrals(profile.currentUser, perMemberReferrals);
        for (const d of profile.dependents) {
          this.addReferrals(d, perMemberReferrals);
        }
      });
  }

  private setPlanName(profile: IProfile): Observable<IBenefit> {
    return this.plansService
      .getBenefits(profile.rallyId)
      .flatMap(benefits => benefits.data.benefits)
      .first(({ coverageType }) => coverageType === CoverageType.Medical)
      .do(medicalPlan => (this.planName = medicalPlan.planName));
  }

  private addReferrals(profileUser: IProfileUser, perMemberReferrals: IReferralsPerMember): void {
    if (perMemberReferrals[profileUser.dependentSeqNum]) {
      perMemberReferrals[profileUser.dependentSeqNum].referrals.forEach(ref => {
        ref.patientName = this.profileService.getFullName(profileUser.userInfo);
        this.referrals.push(ref);
      });
    }
  }
}

export class PcpReferralsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PcpReferralsController;
    this.templateUrl = pcpReferralsTemplate;
  }
}
