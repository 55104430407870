import { Moment } from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { CoverageStatus, CoverageType } from 'scripts/api/api.interfaces';
import { IClaim, IClaimsSummaryResponse } from 'scripts/api/claims/claims.interfaces';
import { IClaimsService } from 'scripts/api/claims/claims.service';
import { IProfileService } from 'scripts/api/profile/profile.service';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { ITargetingService } from 'scripts/api/targeting/targeting.service';
import { IUserService } from 'scripts/api/user/user.service';
import { IAngularMoment } from 'scripts/arcade.module.interfaces';
import { getAAClaimStatusString, isVideoAvailable } from 'scripts/util/claims/claims';
import { ClaimMarkPaid } from 'scripts/util/constants/event.constants';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { internationalClaims } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { IResourceService } from 'scripts/util/resource/resource.service';
import { sendAAPageData } from 'scripts/util/tracking/adobe-analytics';
import { isExpatriate } from 'scripts/util/user/user';
import recentClaimsTemplate from 'views/dashboard/recent-claims.html';
import { ArcadeWebTheme, IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';

export class RecentClaimsController implements ng.IComponentController {
  public allClaimsUrl: string;
  public claims: IClaim[];
  public claimsRequest: Observable<IClaimsSummaryResponse>;
  public claimStatusVideoPresence: boolean;
  public clientConfig: IClientConfig;
  public isActiveOrTermed: boolean;
  public isAdvantage: boolean;
  public isAdvantageTheme: boolean;
  public lastUpdated: Moment;
  public showLinkToInternationalClaims: boolean;
  private internationalClaimsUrl: IResource;
  private profileSubscription: Subscription;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private claimsService: IClaimsService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private moment: IAngularMoment,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    this.$translatePartialLoader.addPart(Dictionary.RECENT_CLAIMS);
    this.$translatePartialLoader.addPart(Dictionary.COMMON);
    this.isAdvantageTheme = this.Environment.CONFIG.ARCADE_WEB_THEME === ArcadeWebTheme.Advantage;

    this.lastUpdated = this.moment();
    this.internationalClaimsUrl = this.resourceService.get(internationalClaims);

    //  PAY-1040: Calling alert claims instead of Summary for Advantage theme.
    this.claimsRequest = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(rsp => rsp.data)
      .flatMap(
        profile => this.targetingService.getClientConfig(profile.rallyId),
        (profile, clientConfig) => ({ profile, clientConfig }),
      )
      .do(({ clientConfig }) => {
        this.clientConfig = clientConfig;
      })
      .flatMap(({ profile }) =>
        this.isAdvantageTheme
          ? this.claimsService.getNeedAttention(profile)
          : this.claimsService.getSummary(profile.currentUser),
      );
  }

  public $onInit(): void {
    this.initCoverage();
    this.initClaims();
    this.initUIFlags();

    this.profileSubscription = this.profileService.profileChanged.subscribe(() => {
      this.initCoverage();
      this.initClaims();
    });

    this.$rootScope.$on(ClaimMarkPaid, (_, claim: IClaim) => {
      this.handleClaimUpdate(claim);
    });
  }

  public $onDestroy(): void {
    this.profileSubscription.unsubscribe();
  }

  public handleClaimUpdate = (updatedClaim: IClaim): void => {
    this.claims = this.claims.map(claim => (claim.claimKey === updatedClaim.claimKey ? updatedClaim : claim));
  };

  private initClaims(): void {
    this.claimsRequest.subscribe(
      rsp => {
        this.lastUpdated = rsp.arcadeDataUpdated || this.moment();
        this.claims = rsp.data.slice(0, 4);

        const claimStatusString = getAAClaimStatusString(this.claims);

        if (claimStatusString) {
          this.claimStatusVideoPresence = this.claims.some(claim => isVideoAvailable(claim));
          sendAAPageData('claimStatusType', {
            pageName: 'dashboard-summary-claims',
            claimStatusType: claimStatusString,
            videoAvailable: this.claimStatusVideoPresence ? 'ssky' : '',
          });
        }
      },
      e => {
        this.claims = [];
        console.warn(e);
      },
    );
  }

  private initCoverage(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toCurrentProfile())
      .map(user => this.profileService.getCoverage(CoverageType.Medical, user.planCoverages))
      .subscribe(coverage => {
        if (coverage) {
          this.isActiveOrTermed = coverage.planPeriod.status !== CoverageStatus.Future;
        }
      }, console.warn);
  }

  private initUIFlags(): void {
    this.isAdvantage = this.featureFlagService.isAdvantageOn();

    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .map(({ data }) => data)
      .subscribe(profile => {
        this.showLinkToInternationalClaims =
          isExpatriate(profile.currentUser) && this.featureFlagService.isLinkToInternationalClaimsOn();
      });
  }
}

export class RecentClaimsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = RecentClaimsController;
    this.templateUrl = recentClaimsTemplate;
  }
}
