import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClaimStatus, IClaim } from 'scripts/api/claims/claims.interfaces';
import { ILedgerAccount } from 'scripts/api/ledger/ledger.interfaces';
import { IProducts, IProfileUser } from 'scripts/api/profile/profile.interfaces';
import { IClientConfig } from 'scripts/api/targeting/targeting.interfaces';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { currentUser } from 'scripts/selectors/profile-service-selectors';
import { FeatureFlagGate } from 'scripts/ui/feature-flag-gate/feature-flag-gate';
import { getByClaim, getExplanationOfBenefitsLink, getYouMayOweAmount } from 'scripts/util/claims/claims';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { IPopulation } from 'scripts/util/population/population.interfaces';
import { getResource } from 'scripts/util/resource/resource';
import { askAQuestion } from 'scripts/util/resource/resource.constants';
import { constructParams } from 'scripts/util/uri/uri';
import { Anchor } from '../anchor/anchor';
import { Dropdown } from '../dropdown/dropdown';
import { IDropdownOption } from '../dropdown/dropdown.interfaces';
import ClaimMarkPaid from './claim-mark-paid';
import ClaimPayNow from './claim-pay-now';

export interface IClaimButtonsProps extends IClaimButtonsStateToProps {
  accounts: ILedgerAccount[];
  clientConfig: IClientConfig;
  claim: IClaim;
  products: IProducts;
  onClaimUpdate: (claim: IClaim) => void;
}

interface IClaimButtonsStateToProps {
  currentProfile: IProfileUser;
  population: IPopulation;
}

const TrackedAnchor = withClickTracking(Anchor, 'explanation-of-benefits');

export const RawClaimButtons: FunctionComponent<IClaimButtonsProps> = props => {
  const { accounts, claim, clientConfig, currentProfile, onClaimUpdate, population, products } = props;
  const { history } = useRouter();
  const hasSecureMessage = !!(products && products.secureMessage);
  const hasANote = claim.claimManagementInfo && claim.claimManagementInfo.claimNote;
  const hasEobDocument = !!claim.eobDocument;
  const eobLink = getExplanationOfBenefitsLink(currentProfile, claim);
  const params = { claimNoteClaimKey: claim.claimKey, claimNoteType: claim.claimType };
  const menuLinks = [
    {
      value: eobLink,
      label: 'VIEW_EXPLANATION_BENEFITS',
      condition: hasEobDocument,
      target: '_blank',
      noRel: true,
    },
    {
      value: getResource(askAQuestion, population),
      label: 'ASK_QUESTION',
      condition: hasSecureMessage,
      target: '_blank',
    },
    {
      value: '#',
      label: hasANote ? 'VIEW_EDIT_NOTE' : 'MAKE_A_NOTE',
      condition: getByClaim(claim, true, false),
      action: () => history.push({ pathname: '/modal/edit-claim-note', search: constructParams(params) }),
    },
  ];
  const showMarkPaid = !!(getYouMayOweAmount(claim) && claim.claimStatus !== ClaimStatus.InProcess);
  const handleSelect = (option: IDropdownOption): void => {
    return option.action && option.action();
  };
  const { t } = useTranslation(Dictionary.COMMON);
  return (
    <div className="claim-buttons" data-testid="claim-buttons">
      <FeatureFlagGate
        flagName={'ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN'}
        whenTrue={<Dropdown label="MORE_OPTIONS" nav={true} onSelect={handleSelect} options={menuLinks} />}
        whenFalse={
          <div className="claim-explanation-of-benefits" data-testid="claim-explanation-of-benefits">
            {hasEobDocument && (
              <TrackedAnchor className="inverse-btn" href={eobLink} no-rel={true} target="_blank">
                {t('VIEW_EXPLANATION_BENEFITS')}
              </TrackedAnchor>
            )}
          </div>
        }
      />
      {showMarkPaid && <ClaimMarkPaid claim={claim} onClaimUpdate={onClaimUpdate} />}
      <ClaimPayNow claim={claim} clientConfig={clientConfig} accounts={accounts} />
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IClaimButtonsStateToProps => ({
  currentProfile: currentUser.selectProfile(state),
  population: state.population,
});

export const ClaimButtons = connect(mapStateToProps)(RawClaimButtons);
