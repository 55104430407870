import { FunctionComponent, ReactElement } from 'react';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import FEATURE_FLAGS from 'scripts/util/constants/feature-flag';

export interface IFeatureFlagGateProps {
  flagName: keyof IFeatureFlags;
  whenTrue: ReactElement | null;
  whenFalse?: ReactElement;
}

export const FeatureFlagGate: FunctionComponent<IFeatureFlagGateProps> = ({ flagName, whenTrue, whenFalse }) => {
  const flag = FEATURE_FLAGS[flagName];
  if (flag) {
    return whenTrue;
  }
  if (whenFalse) {
    return whenFalse;
  }
  return null;
};
