import { SharedHeaderFooterEventsController } from './shared-header-footer-events.controller';

export class SharedHeaderFooterEventsDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = false;
  public controller = SharedHeaderFooterEventsController;
  public controllerAs = '$sharedHeaderFooterEvents';

  public link = (scope, element, attrs, $sharedHeaderFooterEvents): void => {
    $sharedHeaderFooterEvents.addSharedHeaderFooterListeners(element[0]);
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = () => {
      'ngInject';
      return new SharedHeaderFooterEventsDirective();
    };
    return directive;
  }
}
