import { PlansServiceActionTypes } from 'scripts/actions/plans-service-actions';
import { IPlanAccumulators, IPlanBenefits, IIdCard } from 'scripts/api/plans/plans.interfaces';
import { ArcadeAction, IStateData } from './reducer.interfaces';

export interface IPlansState {
  accumulators: IStateData<IPlanAccumulators>;
  benefits: IStateData<IPlanBenefits>;
  idCards: IStateData<IIdCard[][]>;
}

export const initialState: IPlansState = {
  accumulators: {
    arcadeDataUpdated: undefined,
    data: undefined,
    error: undefined,
    loading: false,
  },
  benefits: {
    data: undefined,
    error: undefined,
    loading: false,
  },
  idCards: {
    data: undefined,
    error: undefined,
    loading: false,
  },
};

export default (state: IPlansState = initialState, action: ArcadeAction): IPlansState => {
  switch (action.type) {
    case PlansServiceActionTypes.GET_ACCUMULATORS_SUCCESS:
      return {
        ...state,
        accumulators: {
          arcadeDataUpdated: action.payload.arcadeDataUpdated,
          data: action.payload.accumulators,
          error: false,
          loading: false,
        },
      };
    case PlansServiceActionTypes.GET_ACCUMULATORS_LOADING:
      return {
        ...state,
        accumulators: {
          ...state.accumulators,
          loading: true,
        },
      };
    case PlansServiceActionTypes.GET_ACCUMULATORS_ERROR:
      return {
        ...state,
        accumulators: {
          arcadeDataUpdated: undefined,
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case PlansServiceActionTypes.GET_BENEFITS_SUCCESS:
      return {
        ...state,
        benefits: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case PlansServiceActionTypes.GET_BENEFITS_LOADING:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          loading: true,
        },
      };
    case PlansServiceActionTypes.GET_BENEFITS_ERROR:
      return {
        ...state,
        benefits: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    case PlansServiceActionTypes.GET_ID_CARDS_SUCCESS:
      return {
        ...state,
        idCards: {
          data: action.payload,
          error: false,
          loading: false,
        },
      };
    case PlansServiceActionTypes.GET_ID_CARDS_LOADING:
      return {
        ...state,
        idCards: {
          ...state.idCards,
          loading: true,
        },
      };
    case PlansServiceActionTypes.GET_ID_CARDS_ERROR:
      return {
        ...state,
        idCards: {
          data: undefined,
          error: true,
          loading: false,
        },
      };
    default:
      return state;
  }
};
