import {
  IClaimsInfoPacket,
  PostMessage,
  HandleMessage,
  RallyPayReact,
  MessageType,
  OutboundMessageEventData,
  InboundMessageEventData,
} from '@rally/rallypay';
import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import withProvider from 'scripts/hoc/with-provider/with-provider';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectId } from 'scripts/selectors/locale-selectors';
import CONFIG from 'scripts/util/constants/config';

interface IRallyPayProps extends IRallyPayStateToProps {
  claimsInfoPacket: IClaimsInfoPacket;
  onMessage: HandleMessage<OutboundMessageEventData>;
  token: string;
}

interface IRallyPayStateToProps {
  locale: string;
}

export const RallyPayWidget: FunctionComponent<IRallyPayProps> = ({ claimsInfoPacket, locale, onMessage, token }) => {
  const [postMessage, setPostMessage] = useState<PostMessage<InboundMessageEventData>>();
  useEffect(() => {
    if (postMessage) {
      postMessage({ type: MessageType.InitialData, payload: { claimsInfoPacket, token } });
    }
  }, [postMessage]);

  return (
    <RallyPayReact
      environment={CONFIG.ARCADE_WEB_RALLY_PAY_ENVIRONMENT}
      locale={locale}
      onMessage={onMessage}
      setPostMessage={useCallback(fn => setPostMessage(() => fn), [])}
    />
  );
};

export const mapStateToProps = (state: IReduxState): IRallyPayStateToProps => ({
  locale: selectId(state),
});

export default withProvider(connect(mapStateToProps)(RallyPayWidget));
