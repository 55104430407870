import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { getMoneyValue } from 'scripts/util/money/money';
import { IAccountSummaryAmount } from '../account-summary/account-summary.interfaces';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { Donut } from 'scripts/ui/donut/donut';
import { InactiveCoverage } from 'scripts/ui/inactive-coverage/inactive-coverage';

interface IMrAccountSummaryDonutProps {
  arcadeDataUpdated: moment.Moment;
  billedItems: IAccountSummaryAmount[];
  firstName: string;
  totalBilledAmount: number;
}

export const MrAccountSummaryDonut: FunctionComponent<IMrAccountSummaryDonutProps> = props => {
  const { arcadeDataUpdated = moment(), billedItems, firstName, totalBilledAmount } = props;
  const billedSegments = billedItems.map(item => {
    return { amount: item.amount };
  });
  const { t } = useTranslation(Dictionary.ACCOUNT_SUMMARY);
  const [dollars, cents] = getMoneyValue(totalBilledAmount).split('.');

  return (
    <div className="donut-container col-1-2" aria-hidden="true" data-testid="account-summary-donut">
      <Donut data={billedSegments} outerDiameter={280} innerDiameter={200}>
        <div className="claim-info">
          <div className="total">
            <span className="total-underlined">
              <span>
                {dollars}
                {cents && '.'}
                {cents && <span className="cents">{cents}</span>}
              </span>
            </span>
          </div>
          <div className="summary-period hide-mobile">
            <span>{t('TOTAL_BILLED')}</span>
          </div>
          <div className="summary-period hide-desktop">
            <span>
              {t('INDIVIDUAL_TOTAL_BILLED_FROM', { person: firstName, date: arcadeDataUpdated.format('MM/DD/YYYY') })}
            </span>
          </div>
        </div>
      </Donut>
      <InactiveCoverage className="center" onlyShowFuture={true} />
    </div>
  );
};
