import { Subscription } from 'rxjs/Subscription';
import onboardingBannerTemplate from 'views/chrome/onboarding-banner.html';
import { LineOfBusiness } from '../../api/profile/profile.interfaces';
import { ICampaign } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { IUserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { IPopulationService } from '../../util/population/population.service';
import { CampaignPlacementType } from './../../api/targeting/targeting.interfaces';
import { ILocaleService } from './../../util/locale/locale.service';

export interface IOnboardingStateParams extends ng.ui.IStateParamsService {
  onboardingProfileUpdated: boolean;
}

export class OnboardingBannerController {
  // bindings
  public onboardingCampaign: ICampaign;
  public success: boolean;
  public viewed: boolean;
  public isEI: boolean;

  private localeSubscription: Subscription;
  private _dismissed = false;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private populationService: IPopulationService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.ONBOARDING);

    this.updateCampaign();
    this.localeSubscription = localeService.localeChanged.subscribe(() => this.updateCampaign());
    const pop = this.populationService.getPopulation();
    this.isEI = pop && pop.lineOfBusiness === LineOfBusiness.EI;
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public get profileUpdateSuccess(): boolean {
    return this.success;
  }

  public get dismissable(): boolean {
    return this.profileUpdateSuccess && !this._dismissed;
  }

  public get dismissed(): boolean {
    return this._dismissed;
  }

  public dismiss(): void {
    if (this.dismissable) {
      this._dismissed = true;
    }
  }

  private updateCampaign(): void {
    this.userService
      .getHeartbeat()
      .map(rsp => rsp.data.rallyId)
      .flatMap(rallyId =>
        this.targetingService.getCampaigns(rallyId, [CampaignPlacementType.ArcadeOnboardingPaperless]),
      )
      .map(rsp => rsp.data.placements[CampaignPlacementType.ArcadeOnboardingPaperless])
      .filter(placements => placements && placements.length > 0)
      .map(placements => placements[0])
      .subscribe(paperlessPlacement => {
        this.onboardingCampaign = paperlessPlacement;
      });
  }
}

export class OnboardingBannerComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;
  public bindings = {
    onboardingCampaign: '<',
    success: '<',
  };

  constructor() {
    this.controller = OnboardingBannerController;
    this.templateUrl = onboardingBannerTemplate;
  }
}
