import { Observable } from 'rxjs/Observable';
import {
  connect,
  dentistSearch,
  drugCostEstimator,
  drugCostEstimatorOptum,
  mentalHealthSearch,
  pharmacyLocator,
  visionSearch,
} from 'scripts/util/resource/resource.constants';
import guidedSearchTemplate from 'views/find-care/guided-search.html';
import { CoverageStatus, CoverageTypeCode, ILink, LinkTarget } from '../../../api/api.interfaces';
import { AncillaryBenefit } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { FundingType } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { IFilesConstant } from '../../../util/constants/files.constant';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IGridSetup } from '../../../util/grid/grid.interfaces';
import { IResourceService } from '../../../util/resource/resource.service';

export interface IGuidedSearchCategory extends ILink {
  id: string;
  subtext?: string;
  action?: any;
  icon?: string;
  show?: boolean;
}

export class GuidedSearchController implements IGridSetup {
  public links: IGuidedSearchCategory[];
  public maxColumns = 6;
  public showExternalLinkMessage: boolean;

  constructor(
    private $state: angular.ui.IStateService,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private Files: IFilesConstant,
    private plansService: IPlansService,
    private profileService: IProfileService,
    private resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.GUIDED_SEARCH);

    this.links = [
      {
        id: 'MEDICAL',
        text: 'PROVIDER_SEARCH',
        subtext: 'PROVIDER_SEARCH_LONG',
        href: $state.href('internalRedirect', { deepLink: this.resourceService.get(connect) }),
        action: e => this.internalRedirect(e, this.resourceService.get(connect)),
        icon: 'icon-medical',
      },
      {
        id: 'PHARMACY',
        text: 'PHARMACY_LOCATOR',
        subtext: 'PHARMACY_LOCATOR_LONG',
        href: this.resourceService.get(pharmacyLocator),
        icon: 'icon-pharmacy-locator',
      },
      {
        id: 'DRUG',
        text: 'DRUG_LOOKUP',
        subtext: 'DRUG_LOOKUP_LONG',
        icon: 'icon-pharmacy',
      },
      {
        id: 'DENTAL',
        text: 'DENTAL_DIRECTORY',
        subtext: 'DENTAL_DIRECTORY_LONG',
        href: this.resourceService.get(dentistSearch),
        target: LinkTarget.Blank,
        icon: 'icon-dental',
      },
      {
        id: 'VISION',
        text: 'VISION_DIRECTORY',
        subtext: 'VISION_DIRECTORY_LONG',
        href: this.resourceService.get(visionSearch),
        target: LinkTarget.Blank,
        icon: 'icon-vision',
      },
      {
        id: 'MENTAL_HEALTH',
        text: 'MENTAL_HEALTH_DIRECTORY',
        subtext: 'MENTAL_HEALTH_DIRECTORY_LONG',
        href: this.resourceService.get(mentalHealthSearch),
        target: LinkTarget.Blank,
        icon: 'icon-mental-health',
      },
    ];
  }

  public $onInit(): void {
    this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .flatMap(rsp => {
        const { currentUser } = rsp.data;
        const coverages = currentUser.planCoverages.filter(c => c.planPeriod.status !== CoverageStatus.Termed);
        const hasMaPlan = coverages.some(c => c.coverageTypeCode === CoverageTypeCode.MA);
        const hasMapdPlan = coverages.some(c => c.coverageTypeCode === CoverageTypeCode.MAPD);
        const hasPdpPlan = coverages.some(c => c.coverageTypeCode === CoverageTypeCode.PDP);
        const activeCoverages = currentUser.planCoverages.filter(c => c.planPeriod.status === CoverageStatus.Active);
        const hasActiveMaPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MA);
        const hasActiveMapdPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.MAPD);
        const hasActivePdpPlan = activeCoverages.some(c => c.coverageTypeCode === CoverageTypeCode.PDP);
        const activeMapdOrPdpPlan = activeCoverages.filter(
          c => c.coverageTypeCode === CoverageTypeCode.MAPD || c.coverageTypeCode === CoverageTypeCode.PDP,
        )[0];
        const showDrugCostEstimator = hasActiveMapdPlan || hasActivePdpPlan;
        const isGroupPlan =
          showDrugCostEstimator && activeMapdOrPdpPlan.planFeatures.fundingArrangementType === FundingType.Group;

        this.links[0].show = hasMapdPlan || hasMaPlan;
        this.links[1].show = hasMapdPlan || hasPdpPlan;
        this.links[2].show = showDrugCostEstimator;
        this.links[2].href = isGroupPlan
          ? this.resourceService.get(drugCostEstimatorOptum)
          : this.resourceService.get(drugCostEstimator);
        this.links[2].target = isGroupPlan ? LinkTarget.Blank : LinkTarget.Self;
        this.links[5].show = hasActiveMapdPlan || hasActiveMaPlan;
        this.setShowExternalLinkMessage();
        if (currentUser.planCoverages.some(c => c.planPeriod.status !== CoverageStatus.Future)) {
          return this.userService
            .getHeartbeat()
            .flatMap(({ data }) => this.plansService.getBenefits(data.rallyId))
            .flatMap(benefitsRsp => benefitsRsp.data.benefits);
        } else {
          return Observable.of(undefined);
        }
      })
      .subscribe(benefits => {
        if (benefits) {
          this.links[3].show = benefits.ancillaryBenefits.indexOf(AncillaryBenefit.Dental) > -1;
          this.links[4].show = benefits.ancillaryBenefits.indexOf(AncillaryBenefit.Vision) > -1;
          this.setShowExternalLinkMessage();
        }
      }, console.warn);
  }

  public getNumColumns(): number {
    const columns = this.links.reduce((columnTotal, link) => {
      return columnTotal + (link.show ? 1 : 0);
    }, 0);
    return columns <= this.maxColumns ? columns : this.maxColumns;
  }

  public getIcon(key: string): string {
    return this.Files.getIcon(key);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private setShowExternalLinkMessage(): void {
    this.showExternalLinkMessage = this.links.some(link => link.show && link.target === LinkTarget.Blank);
  }
}

export class GuidedSearchComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = GuidedSearchController;
    this.templateUrl = guidedSearchTemplate;
  }
}
