import classNames from 'classnames';
import React, { FunctionComponent, ReactElement } from 'react';
import { BenefitNetwork } from 'scripts/api/plans/plans.interfaces';
import { ProgressContainer } from '../progress/progress-container';
import { AccumulatorHeader } from './accumulator-header';

interface IAccumulatorProps {
  amount: number;
  benefitNetwork: BenefitNetwork;
  label: string | ReactElement;
  max: number;
}

const NetworkClassMap = {
  [BenefitNetwork.InNetwork]: 'accumulator-in-network',
  [BenefitNetwork.OutOfNetwork]: 'accumulator-out-of-network',
  [BenefitNetwork.Tier1]: 'accumulator-tier1',
};

export const Accumulator: FunctionComponent<IAccumulatorProps> = props => {
  const { amount, benefitNetwork, label, max } = props;
  return (
    <div className={classNames('accumulator', NetworkClassMap[benefitNetwork])} data-testid="accumulator">
      <AccumulatorHeader label={label} benefitNetwork={benefitNetwork} />
      <ProgressContainer amount={amount} max={max} />
    </div>
  );
};
