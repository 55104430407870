import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { IFilesConstant } from 'scripts/util/constants/files.constant';
import { IFeatureFlagService } from 'scripts/util/feature-flag/feature-flag.interface';
import { benefitsCoveragePharmacy } from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import { sendCustomPharmacyAALoadedEvent } from 'scripts/util/tracking/adobe-analytics';
import { sendPharmacyLinkClickAACustomEvent } from 'scripts/util/tracking/adobe-analytics';
import pharmacyTemplate from 'views/states/pharmacy.html';
import { CoverageType, ILink, LinkTarget } from '../../api/api.interfaces';
import {
  IPlanCoverage,
  IRxCoverageInfo,
  RxCarveOutProvider,
  RxCoverageType,
} from '../../api/profile/profile.interfaces';
import { ProfileService } from '../../api/profile/profile.service';
import { IClientConfig, IPharmacyHubCustomMessage } from '../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../api/targeting/targeting.service';
import { UserService } from '../../api/user/user.service';
import { Dictionary } from '../../util/constants/i18n.constants';
import { ILocaleService } from '../../util/locale/locale.service';
import { IResourceService } from '../../util/resource/resource.service';
import { isExpatriate } from '../../util/user/user';

export enum OptumRxLinkTrackingUrl {
  // OptumRx
  CHECK_ORDER_STATUS = 'https://www.optumrx.com/secure/order-status',
  FIND_AND_PRICE_DRUGS = 'https://www.optumrx.com/secure/member-tools/drug-pricing',
  FIND_PHARMACY = 'https://www.optumrx.com/secure/member-tools/pharmacy-locator',
  ORDER_PRESCRIPTIONS = 'https://www.optumrx.com/secure/my-medicine-cabinet',
  // Other Rx Carveout Provider Pharmacy Tracking Urls
  CAREMARK = 'https://www.caremark.com/wps/portal',
  EXPRESS_SCRIPTS = 'https://www.expressscripts.com/',
  WALGREENS = 'https://www.walgreens.com/pharmacy/',
}

export enum OptumRxPharmacyLinkTrackingName {
  // OptumRx
  CHECK_ORDER_STATUS = 'check-rx-order-status',
  FIND_AND_PRICE_DRUGS = 'price-drugs',
  FIND_PHARMACY = 'find-pharmacy',
  ORDER_PRESCRIPTIONS = 'order-prescriptions',
  // Other Rx Carveout Provider Pharmacy Tracking Names
  CAREMARK = 'manage-rx-cvs-caremark',
  EXPRESS_SCRIPTS = 'manage-rx-express-scripts',
  WALGREENS = 'manage-rx-walgreens',
}

export enum PharmacySiteSectionL2TrackingString {
  CAREMARK = 'cvs caremark',
  EXPRESS_SCRIPTS = 'express scripts',
  OPTUM = 'optum rx',
  WALGREENS = 'walgreens',
}

export interface ICarveOut {
  logo: string;
  displayName: string;
  featuresHeader: string;
  features: string[];
  url: string;
}

export interface ICarveOutMap {
  [provider: string]: ICarveOut;
}

export interface IPharmacyLinkCustomTrackingInfo {
  linkTrackingName: OptumRxPharmacyLinkTrackingName;
  trackingLink: OptumRxLinkTrackingUrl;
}

export interface IPharmacyLink extends ILink {
  id: string;
  subtext: string;
  icon: string;
  customLinkTrackingInfo: IPharmacyLinkCustomTrackingInfo;
}

export class PharmacyController implements ng.IComponentController {
  public customMessage: IPharmacyHubCustomMessage;
  public provider: string;
  public providerMap: ICarveOutMap;
  public request: Observable<IPlanCoverage>;
  public isExpatriate: boolean;
  public benefitsCoveragePharmacy: IResource;
  public optumRxLinks: IPharmacyLink[];
  public showOptumRxPharmacy: boolean;

  private optumFeatures = [
    'RX_VIEW_PRESCRIPTIONS',
    'RX_ORDER_STATUS',
    'RX_REFILL_MAIL',
    'RX_COMPARE',
    'RX_VIEW_BENEFITS',
  ];
  private caremarkFeatures = [
    'RX_ORDER_RETAIL_OR_MAIL',
    'RX_REQUEST',
    'RX_CHECK_COVERAGE',
    'RX_COMPARE_SAVE',
    'RX_PRINT_BENEFITS_CARD',
  ];
  private expressScriptsFeatures = [
    'RX_VIEW_PRESCRIPTIONS',
    'RX_REFILL',
    'RX_ORDER_MAIL',
    'RX_FIND',
    'RX_COMPARE_SAVE',
    'RX_DOWNLOAD',
  ];
  private walgreensFeatures = [
    'RX_VIEW_PRESCRIPTIONS',
    'RX_REFILL',
    'RX_ORDER_MAIL',
    'RX_FIND',
    'RX_COMPARE',
    'RX_PRINT_CARD_FORMS',
  ];
  private clientConfigReq: Observable<IClientConfig>;
  private localeSubscription: Subscription;
  private LINK_URL;

  constructor(
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
    private localeService: ILocaleService,
    private Environment: IEnvironmentConstants,
    private featureFlagService: IFeatureFlagService,
    private Files: IFilesConstant,
    private profileService: ProfileService,
    public resourceService: IResourceService,
    private targetingService: ITargetingService,
    private userService: UserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.PHARMACY);
    this.benefitsCoveragePharmacy = benefitsCoveragePharmacy;

    this.request = userService
      .getHeartbeat()
      .let(profileService.toProfile())
      .do(profile => (this.isExpatriate = isExpatriate(profile.data.currentUser)))
      .flatMap(profile => profile.data.currentUser.planCoverages)
      .first(
        ({ coverageType, rxCoverageInfo, additionalCoverageTypes }) =>
          PharmacyController.hasRxCoverage(coverageType, rxCoverageInfo) ||
          PharmacyController.hasRxRollupCoverage(coverageType, additionalCoverageTypes),
      );

    const providerMap = profileService.getRxProviderMap();
    providerMap[RxCarveOutProvider.Optum].features = this.optumFeatures;
    providerMap[RxCarveOutProvider.Optum].featuresHeader = 'RX_GO_TO_PROVIDER_TO';
    providerMap[RxCarveOutProvider.Caremark].features = this.caremarkFeatures;
    providerMap[RxCarveOutProvider.Caremark].featuresHeader = 'RX_LOG_INTO_PROVIDER';
    providerMap[RxCarveOutProvider.Caremark].customLinkTrackingInfo = this.getCustomLinkTrackingInfo(
      RxCarveOutProvider.Caremark,
    );
    providerMap[RxCarveOutProvider.ExpressScripts].features = this.expressScriptsFeatures;
    providerMap[RxCarveOutProvider.ExpressScripts].featuresHeader = 'RX_LOG_INTO_PROVIDER';
    providerMap[RxCarveOutProvider.ExpressScripts].customLinkTrackingInfo = this.getCustomLinkTrackingInfo(
      RxCarveOutProvider.ExpressScripts,
    );
    providerMap[RxCarveOutProvider.Walgreens].features = this.walgreensFeatures;
    providerMap[RxCarveOutProvider.Walgreens].featuresHeader = 'RX_LOG_INTO_PROVIDER';
    providerMap[RxCarveOutProvider.Walgreens].customLinkTrackingInfo = this.getCustomLinkTrackingInfo(
      RxCarveOutProvider.Walgreens,
    );

    this.providerMap = providerMap;

    this.clientConfigReq = this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .do(clientConfig => {
        this.customMessage = clientConfig && clientConfig.customMessaging.pharmacyHubCustomMessage;
      });
  }

  public $onInit(): void {
    this.clientConfigReq.subscribe(() => undefined, console.warn);

    this.localeSubscription = this.localeService.localeChanged
      .flatMap(() => this.clientConfigReq)
      .subscribe(() => undefined, console.warn);

    this.request.subscribe(coverage => {
      this.showOptumRxPharmacy = this.setShowOptumRxPharmacy(coverage);

      if (this.showOptumRxPharmacy) {
        this.initOptumRx();
      }

      this.provider =
        coverage.rxCoverageInfo && coverage.rxCoverageInfo.coverageType === RxCoverageType.CRX
          ? coverage.rxCoverageInfo.carveOutProvider
          : RxCarveOutProvider.Optum;

      sendCustomPharmacyAALoadedEvent('trackPages', PharmacySiteSectionL2TrackingString[this.provider]);
    }, console.warn);
  }

  public $onDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  public getIcon(key: string): string {
    return this.Files.getIcon(key);
  }

  public customPharmacyLinkClickEvent(customLinkTrackingInfo: IPharmacyLinkCustomTrackingInfo): void {
    sendPharmacyLinkClickAACustomEvent(customLinkTrackingInfo);
  }

  private initOptumRx(): void {
    this.LINK_URL = {
      FIND_AND_PRICE_DRUGS: this.Environment.CONFIG.ARCADE_WEB_OPTUM_RX_FIND_AND_PRICE_DRUGS,
      FIND_PHARMACY: this.Environment.CONFIG.ARCADE_WEB_OPTUM_RX_FIND_PHARMACY,
      ORDER_PRESCRIPTIONS: this.Environment.CONFIG.ARCADE_WEB_OPTUM_RX_ORDER_PRESCIPTIONS,
      CHECK_ORDER_STATUS: this.Environment.CONFIG.ARCADE_WEB_OPTUM_RX_CHECK_ORDER_STATUS,
    };

    this.optumRxLinks = [
      {
        id: 'FIND_AND_PRICE_DRUGS',
        text: 'FIND_AND_PRICE_DRUGS',
        subtext: 'FIND_AND_PRICE_DRUGS_DESCRIPTION',
        href: this.LINK_URL.FIND_AND_PRICE_DRUGS,
        icon: 'icon-find-rx',
        customLinkTrackingInfo: {
          linkTrackingName: OptumRxPharmacyLinkTrackingName.FIND_AND_PRICE_DRUGS,
          trackingLink: OptumRxLinkTrackingUrl.FIND_AND_PRICE_DRUGS,
        },
        target: LinkTarget.Blank,
      },
      {
        id: 'FIND_PHARMACY',
        text: 'FIND_PHARMACY',
        subtext: 'FIND_PHARMACY_DESCRIPTION',
        href: this.LINK_URL.FIND_PHARMACY,
        icon: 'icon-find-pharmacy',
        customLinkTrackingInfo: {
          linkTrackingName: OptumRxPharmacyLinkTrackingName.FIND_PHARMACY,
          trackingLink: OptumRxLinkTrackingUrl.FIND_PHARMACY,
        },
        target: LinkTarget.Blank,
      },
      {
        id: 'ORDER_PRESCRIPTIONS',
        text: 'ORDER_PRESCRIPTIONS',
        subtext: 'ORDER_PRESCRIPTIONS_DESCRIPTION',
        href: this.LINK_URL.ORDER_PRESCRIPTIONS,
        icon: 'icon-refill-prescriptions',
        customLinkTrackingInfo: {
          linkTrackingName: OptumRxPharmacyLinkTrackingName.ORDER_PRESCRIPTIONS,
          trackingLink: OptumRxLinkTrackingUrl.ORDER_PRESCRIPTIONS,
        },
        target: LinkTarget.Blank,
      },
      {
        id: 'CHECK_ORDER_STATUS',
        text: 'CHECK_ORDER_STATUS',
        subtext: 'CHECK_ORDER_STATUS_DESCRIPTION',
        href: this.LINK_URL.CHECK_ORDER_STATUS,
        icon: 'icon-order-status',
        customLinkTrackingInfo: {
          linkTrackingName: OptumRxPharmacyLinkTrackingName.CHECK_ORDER_STATUS,
          trackingLink: OptumRxLinkTrackingUrl.CHECK_ORDER_STATUS,
        },
        target: LinkTarget.Blank,
      },
    ];
  }

  private getCustomLinkTrackingInfo(rxCarveOutProvider: RxCarveOutProvider): IPharmacyLinkCustomTrackingInfo {
    return {
      linkTrackingName: OptumRxPharmacyLinkTrackingName[rxCarveOutProvider],
      trackingLink: OptumRxLinkTrackingUrl[rxCarveOutProvider],
    };
  }

  private setShowOptumRxPharmacy(coverage: IPlanCoverage): boolean {
    const { coverageType, additionalCoverageTypes, rxCoverageInfo } = coverage;
    return (
      this.featureFlagService.isOptumRxPharmacyRedesignOn() &&
      (PharmacyController.hasRxRollupCoverage(coverageType, additionalCoverageTypes) ||
        rxCoverageInfo.coverageType === RxCoverageType.RX ||
        rxCoverageInfo.coverageType === RxCoverageType.ORX)
    );
  }

  private static hasRxCoverage(coverageType: CoverageType, rxCoverageInfo: IRxCoverageInfo): boolean {
    return coverageType === CoverageType.Rx && typeof rxCoverageInfo !== 'undefined';
  }

  private static hasRxRollupCoverage(coverageType: CoverageType, additionalCoverageTypes: CoverageType[]): boolean {
    return (
      coverageType === CoverageType.Medical &&
      typeof additionalCoverageTypes !== 'undefined' &&
      additionalCoverageTypes.indexOf(CoverageType.Rx) > -1
    );
  }
}

export class PharmacyComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.controller = PharmacyController;
    this.templateUrl = pharmacyTemplate;
  }
}
