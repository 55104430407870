export function isIE11(): boolean {
  return '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;
}

export const isiOSSafari = (): boolean => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};
