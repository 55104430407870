import { Observable } from 'rxjs';
import {
  isDcsaAccount,
  isFsaAccount,
  isFsaAccountType,
  isFsaDcAccount,
  isFsaHcAccount,
  isHcsaAccount,
  isHraAccount,
  isHraAccountType,
  isHsaAccount,
  isJpmcAccount,
  isJpmcAccountType,
  isJpmcFsaAccountType,
  isJpmcHraAccountType,
  isMraAccount,
} from 'scripts/util/ledger/ledger';
import { ledgerUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { getCache, getCacheKey } from '../cache';
import { AccountType, IGetAccountsResponse, ILedgerAccount } from './ledger.interfaces';

export interface ILedgerService {
  getAccounts(rallyId: string): Observable<IGetAccountsResponse>;
}

export class LedgerService implements ILedgerService {
  constructor(private baseApiService: IBaseApiService) {
    'ngInject';
  }

  public getAccounts(rallyId: string): Observable<IGetAccountsResponse> {
    const url = ledgerUris.balance(rallyId);
    const ledgerCache = getCache(CacheName.Ledger);
    const cacheKey = getCacheKey(url);
    const cachedData = ledgerCache.get(cacheKey);

    const nonCachedSrc$ = this.baseApiService
      .get(url)
      .map(rsp => {
        const accounts = rsp.data;
        for (const a of accounts) {
          this.baseApiService.stringToFloat(a, 'balance', 'availableAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'currentAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'paidAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'investmentAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'totalBalance', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'carryOverAmount', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'annualElections', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'employeeContribution', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'employerContribution', 'value');
          this.baseApiService.stringToFloat(a, 'balance', 'accountFunding', 'beginningAmount', 'value');
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'familyAccessPointInformation',
            'familyAccessPoint',
          );
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'familyAccessPointInformation',
            'familyAccessPointAmountApplied',
          );
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'individualAccessPointInformation',
            'individualAccessPoint',
          );
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'individualAccessPointInformation',
            'individualMaxReimbursementAmount',
          );
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'individualAccessPointInformation',
            'individualAccessPointAccumulators',
            'individualAccessPointApplied',
          );
          this.baseApiService.stringToFloat(
            a,
            'accessPointInformation',
            'individualAccessPointInformation',
            'individualAccessPointAccumulators',
            'individualMaxReimbursementAmountUsed',
          );
        }
        return rsp;
      })
      .do(rsp => ledgerCache.put(url, rsp));

    return Observable.if(() => cachedData, Observable.of(cachedData), nonCachedSrc$).map(rsp => {
      const accounts = rsp.data;
      for (const a of accounts) {
        this.baseApiService.dateStringToMoment(a, 'accountPeriod', 'startDate');
        this.baseApiService.dateStringToMoment(a, 'accountPeriod', 'endDate');
      }

      return rsp;
    });
  }

  public static isDcsaAccount(account: ILedgerAccount): boolean {
    return isDcsaAccount(account);
  }

  public static isFsaAccount(account: ILedgerAccount): boolean {
    return isFsaAccount(account);
  }

  public static isJpmcAccount(account: ILedgerAccount): boolean {
    return isJpmcAccount(account);
  }

  public static isFsaHcAccount(account: ILedgerAccount): boolean {
    return isFsaHcAccount(account);
  }
  public static isFsaDcAccount(account: ILedgerAccount): boolean {
    return isFsaDcAccount(account);
  }

  public static isHraAccount(account: ILedgerAccount): boolean {
    return isHraAccount(account);
  }

  public static isHsaAccount(account: ILedgerAccount): boolean {
    return isHsaAccount(account);
  }

  public static isHcsaAccount(account: ILedgerAccount): boolean {
    return isHcsaAccount(account);
  }

  public static isMraAccount(account: ILedgerAccount): boolean {
    return isMraAccount(account);
  }

  public static isJpmcAccountType(accountType: AccountType): boolean {
    return isJpmcAccountType(accountType);
  }

  public static isFsaAccountType(accountType: AccountType): boolean {
    return isFsaAccountType(accountType);
  }

  public static isJPMCFsaAccountType(accountType: AccountType): boolean {
    return isJpmcFsaAccountType(accountType);
  }
  public static isJPMCHraAccountType(accountType: AccountType): boolean {
    return isJpmcHraAccountType(accountType);
  }
  public static isHraAccountType(accountType: AccountType): boolean {
    return isHraAccountType(accountType);
  }
}
