import angular from 'angular';
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ITimePeriod } from 'scripts/api/api.interfaces';
import {
  FilterStateKey,
  FilterType,
  IAvailableFilterValue,
  IGenericFilter,
  ISelectedFilters,
  ISort,
} from './filter.interfaces';
import { decodeQueryParamValue, encodeQueryParamValue } from './filter-utils';

export interface IFilterService {
  filter<T, U>(values: T[], filter: IGenericFilter<T, U>[]): T[];
  getFilterValues<T, U>(values: T[], filter: IGenericFilter<T, U>): IAvailableFilterValue<U>[];
  getStateValue<T, U = ISelectedFilters<T>>(key?: FilterStateKey): U;
  setSelectedFilterValues<T, U>(
    filters: IGenericFilter<T, U>[],
    selectedFilters: ISelectedFilters<U>,
    values: T[],
  ): void;
  sort<T>(values: T[], sort?: ISort<T>): T[];
  updateStateValue<U>(obj: U, key?: FilterStateKey): Observable<void>;
}
export class FilterService implements IFilterService {
  private orderByFilter: ng.IFilterOrderBy;

  constructor(
    private $filter: ng.IFilterService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
    'ngInject';
    this.orderByFilter = this.$filter('orderBy');
  }

  public filter<T, U>(values: T[], filters: IGenericFilter<T, U>[]): T[] {
    return values.filter(value => {
      const isIncluded = [];
      filters.forEach(filter => {
        if (!filter.selectedValues) {
          isIncluded.push(true);
        } else {
          const filterByValue = filter.mapFunc(value);
          if (filter.type === FilterType.Checkbox) {
            isIncluded.push(this.checkboxComparison(filterByValue, filter.selectedValues));
          }
          if (filter.type === FilterType.Dropdown) {
            isIncluded.push(this.dropdownComparison(filterByValue, filter.selectedValues));
          }
          if (filter.type === FilterType.DateRange) {
            isIncluded.push(this.dateRangeComparison(filterByValue, filter.selectedValues));
          }
          if (filter.type === FilterType.Keyword) {
            isIncluded.push(this.keywordComparison(filterByValue, filter.selectedValues));
          }
        }
      });
      return isIncluded.every(unanimous => unanimous);
    });
  }

  public getFilterValues<T, U>(values: T[], filter: IGenericFilter<T, U>): IAvailableFilterValue<U>[] {
    const availableFilterValues: IAvailableFilterValue<U>[] = [];
    for (const value of values) {
      const mappedValues: U[] = [].concat(...[filter.mapFunc(value)]);
      for (const mappedValue of mappedValues) {
        let found = false;
        for (const filterValue of availableFilterValues) {
          if (angular.equals(filterValue.value, mappedValue)) {
            filterValue.count++;
            found = true;
            break;
          }
        }
        if (!found) {
          availableFilterValues.push({
            value: mappedValue,
            display: filter.displayFunction(value, mappedValue),
            count: 1,
          });
        }
      }
    }
    if (filter.defaultValues) {
      for (const defaultValue of filter.defaultValues) {
        let found = false;
        for (const filterValue of availableFilterValues) {
          if (angular.equals(filterValue.value, defaultValue)) {
            found = true;
            break;
          }
        }
        if (!found) {
          availableFilterValues.push({ value: defaultValue, display: defaultValue.toString(), count: 1 });
        }
      }
    }
    const sortFunc = filter.sortFunc || ((a, b) => b.count - a.count);
    return availableFilterValues.sort(sortFunc);
  }

  public getStateValue<T, U = ISelectedFilters<T>>(key: FilterStateKey = FilterStateKey.Filters): U {
    return decodeQueryParamValue<U>(this.$stateParams[key], key);
  }

  public setSelectedFilterValues<T, U>(
    filters: IGenericFilter<T, U>[],
    selectedFilters: ISelectedFilters<U>,
    values: T[],
  ): void {
    filters.forEach(filter => {
      Object.keys(selectedFilters.values)
        .filter(key => filter.name === key)
        .map(key => selectedFilters.values[key])
        .filter(selectedValues => selectedValues && selectedValues.length > 0)
        .map(selectedValues => {
          const mappedValues = [].concat(...values.map(value => filter.mapFunc(value)));
          return selectedValues.filter(selectedValue => {
            if (filter.type === FilterType.Keyword) {
              return true;
            }
            return mappedValues.some(mappedVal => mappedVal === selectedValue);
          });
        })
        .filter(selectedValues => selectedValues && selectedValues.length > 0)
        .forEach(selectedValues => (filter.selectedValues = selectedValues));
    });
  }

  public sort<T>(values: T[], sort: ISort<T> = { properties: [], reverse: false }): T[] {
    const { properties, reverse } = sort;
    return this.orderByFilter(values, properties, reverse);
  }

  public updateStateValue<U>(obj: U, key: FilterStateKey = FilterStateKey.Filters): Observable<void> {
    const objString = encodeQueryParamValue(obj, key);
    return Observable.from(
      this.$state.go(this.$state.current, { [key]: objString }, { location: 'replace', notify: false }),
    );
  }

  private checkboxComparison<U>(filterByValue: U, selectedValues: U[]): boolean {
    return selectedValues.some(selectedValue => {
      return angular.isArray(filterByValue)
        ? filterByValue.indexOf(selectedValue) > -1
        : angular.equals(selectedValue, filterByValue);
    });
  }

  private dropdownComparison<U>(filterByValue: U, selectedValues: U[]): boolean {
    return selectedValues.some(selectedValue => {
      return angular.isArray(filterByValue)
        ? filterByValue.indexOf(selectedValue) > -1
        : angular.equals(selectedValue, filterByValue);
    });
  }

  private dateRangeComparison<U>(filterByValue: U, selectedValues: U[]): boolean {
    if (filterByValue && selectedValues && selectedValues.length === 2) {
      const toCompare = this.getMoment(filterByValue);
      const startDate = this.getMoment(selectedValues[0]);
      const endDate = this.getMoment(selectedValues[1]);
      return toCompare.isSameOrAfter(startDate, 'day') && toCompare.isSameOrBefore(endDate, 'day');
    }
    return false;
  }

  private keywordComparison<U>(filterByValue: U, selectedValues: U[]): boolean {
    if (
      filterByValue &&
      Array.isArray(filterByValue) &&
      filterByValue.length > 0 &&
      selectedValues &&
      selectedValues.length > 0
    ) {
      const queryStrings = this.getStringValues(selectedValues).map(queryString => queryString.trim().toLowerCase());
      const filterByValueStrings = this.getStringValues(filterByValue).map(fieldString =>
        fieldString.trim().toLowerCase(),
      );
      const filterByValueMoments = this.getMomentValues(filterByValue);
      return queryStrings.every(str => {
        const stringMatch = filterByValueStrings.some(fieldString => fieldString.includes(str));
        const timePeriod = this.getTimePeriodFromString(str);
        const dateMatch =
          timePeriod &&
          filterByValueMoments.some(fieldMoment => {
            return fieldMoment.isBetween(timePeriod.startDate, timePeriod.endDate, 'day', '[]');
          });
        return stringMatch || dateMatch;
      });
    }
    return false;
  }

  private getMoment(dateLike: moment.MomentInput): moment.Moment {
    return moment.isMoment(dateLike) ? dateLike : moment(dateLike);
  }

  private getMomentValues(values: any[]): moment.Moment[] {
    const isMoment = (value: any): value is moment.Moment => moment.isMoment(value);
    return values.filter(isMoment);
  }

  private getStringValues(values: any[]): string[] {
    return values.filter(value => typeof value === 'string');
  }

  private getTimePeriodFromString(str: string): ITimePeriod | undefined {
    const parts = str
      .replace(/\//g, '-')
      .split('-')
      .filter(part => !!part)
      .map(part => parseInt(part, 10));
    if (parts.some(part => isNaN(part) || part < 0)) {
      return;
    }
    if (parts.length === 1) {
      if (parts[0] <= 12) {
        const startDate = moment()
          .month(parts[0] - 1)
          .startOf('month');
        const endDate = moment()
          .month(parts[0] - 1)
          .endOf('month');
        if (startDate.isAfter(moment(), 'month')) {
          startDate.subtract(1, 'year');
          endDate.subtract(1, 'year');
        }
        return { startDate, endDate };
      } else if (parts[0] > 1900 && parts[0] <= moment().get('year')) {
        const startDate = moment()
          .year(parts[0])
          .startOf('year');
        const endDate = moment()
          .year(parts[0])
          .endOf('year');
        return { startDate, endDate };
      }
    } else if (parts.length === 2) {
      const startDate = moment().month(parts[0] - 1);
      const endDate = moment().month(parts[0] - 1);
      if (parts[1] <= 31) {
        startDate.date(parts[1]);
        endDate.date(parts[1]);
        if (startDate.isAfter(moment(), 'day')) {
          startDate.subtract(1, 'year');
          endDate.subtract(1, 'year');
        }
        return { startDate, endDate };
      } else if (parts[1] > 1900 && parts[1] <= moment().get('year')) {
        startDate.year(parts[1]).startOf('month');
        endDate.year(parts[1]).endOf('month');
        return { startDate, endDate };
      }
    } else if (parts.length === 3) {
      const dateMs = Date.parse(str);
      if (!isNaN(dateMs) && dateMs > 0) {
        const date = moment(dateMs);
        return { startDate: date, endDate: date };
      }
    }
  }
}
