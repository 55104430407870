import React, { FunctionComponent } from 'react';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import { useTranslation } from 'react-i18next';

interface IPcpStripBaseTileProps {
  providerTypeTranslationKey: string;
}

export const PcpStripBaseTile: FunctionComponent<IPcpStripBaseTileProps> = props => {
  const { children, providerTypeTranslationKey } = props;
  const { t } = useTranslation(Dictionary.PCP);
  return (
    <div className="care-container">
      <div className="provider-type">
        <span className="provider-type-text">{t(providerTypeTranslationKey)}</span>
      </div>
      <div className="provider-info">{children}</div>
    </div>
  );
};
