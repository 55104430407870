import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';

/**
 * This directive is a replacement for `ng-include`. It adds a query parameter to the resource to prevent the browser from caching the
 * resource for more than the current version of the application. When we include something with `ng-include`, it will be cached
 * by the browser. Therefore it may not be re-fetched when that resource changes. This directive prevents caching by including the
 * version as a query parameter. Therefore the browser will fetch the new resource for each new version of our app.
 */
export class VersionedIncludeDirective implements ng.IDirective {
  public restrict = 'A';
  public scope = {
    versionedInclude: '<',
  };

  constructor(private $compile: ng.ICompileService, private Environment: IEnvironmentConstants) {}

  public link = (scope, element): void => {
    const separator = scope.versionedInclude.includes('?') ? '&' : '?';
    const version = encodeURIComponent(this.Environment.CONFIG.ARCADE_WEB_VERSION);
    const withVersion = `'${scope.versionedInclude}${separator}v=${version}'`;
    if (element.attr('ng-include') !== withVersion) {
      element.attr('ng-include', withVersion);
      this.$compile(element)(scope);
    }
  };

  public static Factory(): ng.IDirectiveFactory {
    const directive: ng.IDirectiveFactory = ($compile: ng.ICompileService, Environment: IEnvironmentConstants) => {
      'ngInject';
      return new VersionedIncludeDirective($compile, Environment);
    };
    return directive;
  }
}
