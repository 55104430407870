import { IResponse } from '../api.interfaces';

export interface IHeartbeatResponse extends IResponse<IHeartbeat> {}

/**
 * This class correpsonds to the InfoResult class in the Arcade-User service.
 */
export interface IHeartbeat {
  rallyId: string;
  isSuperUser: boolean;
  externalId?: string;
  expiresAt: Date;
  sessionIdp: Idp;
  serverTime: Date;
}

export interface IToken {
  token: string;
}

export enum Idp {
  AARP = 'aarp',
  Arachne = 'arachne',
  Medica = 'medica',
  Medicare = 'medicare',
  MyUHC = 'myuhc',
  OfflineWeb = 'offlineweb',
  PCP = 'pcp',
  Rally = 'rally',
  Retiree = 'retiree',
}

export interface ITokenResponse extends IResponse<IToken> {}

export interface IPreLoginResponse extends IResponse<{ oidcStateCode: string }> {}

export const locationUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
