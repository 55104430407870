import { Observable } from 'rxjs';
import { AdvantageConfig } from '@rally/advantage-components/dist/types/models/advantageConfig.interface';
import { AdvantageProfile } from '@rally/advantage-components/dist/types/models/advantageProfile.interface';
import { IResponse, CacheName } from 'scripts/api/api.interfaces';
import { IBaseApiService } from 'scripts/api/client/base-api.service';
import { IEnvironmentConstants } from 'scripts/util/constants/environment.interfaces';
import { advantageUris } from 'scripts/util/uri/uri';
import { getCache, getCacheKey } from '../cache';

export interface IAdvantageProductAuthList {
  advantageAuthorizations: string[];
}

/**
 * Simple type aliases, Observable of an Http Response containing an [[IAdvantageProfile]]
 */
export interface IAdvantageProfileResponse extends IResponse<AdvantageProfile> {}
export type AdvantageProductAuthListResponse = Observable<IResponse<IAdvantageProductAuthList>>;
export type AdvantageProfileResponse = Observable<IAdvantageProfileResponse>;
export type UiConfigResponse = Observable<IResponse<AdvantageConfig>>;

/**
 * Provides API functionality for Advantage Specific Endpoints.
 */
export interface IAdvantageService {
  getAdvantageProductAuthList(): AdvantageProductAuthListResponse;
  getAdvantageProfile(): AdvantageProfileResponse;
  getAdvantageUiConfig(locale: string): UiConfigResponse;
}

export class AdvantageService implements IAdvantageService {
  constructor(private Environment: IEnvironmentConstants, private advantageApiService: IBaseApiService) {
    'ngInject';
  }

  /**
   * Returns an advantageAuthorizations array with the advantage products which the user is authorized to access
   */
  public getAdvantageProductAuthList(): AdvantageProductAuthListResponse {
    const url = advantageUris.productAuthList(this.Environment.CONFIG);
    const cache = getCache(CacheName.Advantage);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.advantageApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  /**
   * Gets the user's Advantage Profile (rally coins, messages, avatar, etc).
   */
  public getAdvantageProfile(): AdvantageProfileResponse {
    const url = advantageUris.profile(this.Environment.CONFIG);
    const cache = getCache(CacheName.Advantage);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.advantageApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }

  /**
   * Returns the UI configuration for the Advantage header/footer.
   * @param locale The current user's locale information.
   */
  public getAdvantageUiConfig(locale: string): UiConfigResponse {
    const url = advantageUris.uiConfig(this.Environment.CONFIG, locale);
    const cache = getCache(CacheName.Advantage);
    const cacheKey = getCacheKey(url, true);
    const cachedData = cache.get(cacheKey);
    const nonCachedSrc$ = this.advantageApiService.get(url).do(rsp => cache.put(cacheKey, rsp));

    return Observable.if(() => !!cachedData, Observable.of(cachedData), nonCachedSrc$);
  }
}
