import { getMoneyValue } from 'scripts/util/money/money';
import accumulatorTemplate from 'views/ui/accumulator.html';
import { ICurrencyAmount } from '../../api/api.interfaces';
import { BenefitNetwork, BenefitPaymentType } from '../../api/plans/plans.interfaces';
import { Dictionary } from '../../util/constants/i18n.constants';

const NetworkStringIdMap = {
  [BenefitNetwork.InNetwork]: 'IN_NETWORK',
  [BenefitNetwork.OutOfNetwork]: 'OUT_OF_NETWORK',
  [BenefitNetwork.Tier1]: 'TIER_ONE',
};

const NetworkClassMap = {
  [BenefitNetwork.InNetwork]: 'accumulator-in-network',
  [BenefitNetwork.OutOfNetwork]: 'accumulator-out-of-network',
  [BenefitNetwork.Tier1]: 'accumulator-tier1',
};

const Individual = 'INDIVIDUAL';
const Individuals = 'INDIVIDUALS';
const Family = 'FAMILY';
const MobileLabelMap = {
  [BenefitPaymentType.IndividualDeductible]: Individual,
  [BenefitPaymentType.IndividualOop]: Individual,
  [BenefitPaymentType.IndividualAnnualMax]: 'ANNUAL_MAX_DENTAL',
  [BenefitPaymentType.IndividualLifetimeMax]: 'LIFETIME_MAX_ORTHO',
  [BenefitPaymentType.FamilyDeductible]: Family,
  [BenefitPaymentType.FamilyOop]: Family,
  [BenefitPaymentType.PreDeductibleAllowanceCombined]: 'PREDEDUCTIBLE_ALLOWANCE_COMBINED',
  [BenefitPaymentType.PreDeductibleAllowanceEmergencyServices]: 'PREDEDUCTIBLE_ALLOWANCE_EMERGENCY_SERVICES',
  [BenefitPaymentType.PreDeductibleAllowanceRoutineServices]: 'PREDEDUCTIBLE_ALLOWANCE_ROUTINE_SERVICES',
};

const CustomLabelPrefixMap = {
  [BenefitPaymentType.IndividualDeductible]: Individuals,
  [BenefitPaymentType.IndividualOop]: Individuals,
  [BenefitPaymentType.IndividualAnnualMax]: Individuals,
  [BenefitPaymentType.IndividualLifetimeMax]: Individuals,
  [BenefitPaymentType.FamilyDeductible]: Family,
  [BenefitPaymentType.FamilyOop]: Family,
  [BenefitPaymentType.PreDeductibleAllowanceCombined]: Individuals,
  [BenefitPaymentType.PreDeductibleAllowanceEmergencyServices]: Individuals,
  [BenefitPaymentType.PreDeductibleAllowanceRoutineServices]: Individuals,
};

const ModalTrackMap = {
  [BenefitPaymentType.PreDeductibleAllowanceCombined]: 'pre-deductible-combined',
  [BenefitPaymentType.PreDeductibleAllowanceEmergencyServices]: 'pre-deductible-emergency',
  [BenefitPaymentType.PreDeductibleAllowanceRoutineServices]: 'pre-deductible-routine',
};

const maxBenefits = [
  BenefitPaymentType.PreDeductibleAllowanceCombined,
  BenefitPaymentType.PreDeductibleAllowanceEmergencyServices,
  BenefitPaymentType.PreDeductibleAllowanceRoutineServices,
  BenefitPaymentType.IndividualAnnualMax,
  BenefitPaymentType.IndividualLifetimeMax,
];

export interface IAccumulatorBindings extends ng.IOnChangesObject {
  network: ng.IChangesObject<BenefitNetwork>;
  type: ng.IChangesObject<BenefitPaymentType>;
  person?: ng.IChangesObject<string>;
  max: ng.IChangesObject<ICurrencyAmount>;
  amount: ng.IChangesObject<ICurrencyAmount>;
}

export class AccumulatorController implements ng.IComponentController {
  // Bindings
  public network: BenefitNetwork;
  public type: BenefitPaymentType;
  public person?: string;
  public max: ICurrencyAmount;
  public amount: ICurrencyAmount;
  public customLabel?: string;
  public customNetworkLabel?: string;

  // Other template-exposed variables
  public moneyAmount: string;
  public moneyMax: string;
  public moneyRemaining: string;
  public moneyZero: string;
  public percentage: string;
  public networkStringId: string;
  public networkClass: string;
  public mobileLabel: string;
  public customLabelPrefix: string;
  public isMaxBenefit: boolean;
  public link: string;
  public track: string;

  constructor(
    private $element: ng.IAugmentedJQuery,
    private $translatePartialLoader: ng.translate.ITranslatePartialLoaderService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.ACCUMULATORS);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);

    const amount = AccumulatorController.getValueFromCurrency(this.amount);
    const max = AccumulatorController.getValueFromCurrency(this.max);

    this.moneyAmount = getMoneyValue(amount);
    this.moneyMax = getMoneyValue(max);
    this.moneyZero = getMoneyValue(0);
    this.percentage = max > 0 ? ((amount / max) * 100).toFixed(0) + '%' : '0%';
    this.networkStringId = NetworkStringIdMap[this.network];
    this.networkClass = NetworkClassMap[this.network];
    this.mobileLabel = MobileLabelMap[this.type] || this.type;
    this.customLabelPrefix = CustomLabelPrefixMap[this.type];
    this.isMaxBenefit = maxBenefits.indexOf(this.type) > -1;
    if (this.isMaxBenefit) {
      this.moneyRemaining = getMoneyValue(max - amount);
      if (ModalTrackMap[this.type]) {
        this.link = 'modal.medicalAccountExplanation({ network: $ctrl.network, paymentType: $ctrl.type })';
        this.track = `medical-${ModalTrackMap[this.type]}-explanation`;
      }
    }
  }

  public $onChanges(changesObj: IAccumulatorBindings): void {
    if (typeof changesObj.max !== 'undefined' && typeof changesObj.max.currentValue === 'undefined') {
      this.$element.remove();
    }
  }

  private static getValueFromCurrency(currency: ICurrencyAmount): number {
    return (currency && currency.value) || 0;
  }
}

export class AccumulatorComponent implements ng.IComponentOptions {
  public controller = AccumulatorController;
  public templateUrl = accumulatorTemplate;
  public bindings = {
    network: '@aNetwork',
    type: '@aType',
    person: '@?aPerson',
    max: '<aMax',
    amount: '<aAmount',
    customLabel: '@?aCustomLabel',
    customNetworkLabel: '@?aCustomNetworkLabel',
  };
}
