export class Dictionary {
  public static ACCOUNT_INFO = 'account-info';
  public static ACCOUNT_SELECTOR = 'account-selector';
  public static ACCOUNT_SUMMARY = 'account-summary';
  public static ACCUMULATORS = 'accumulators';
  public static ACTIVATE_VIDEO = 'activate-video';
  public static ACTIVATE_VIDEO_TRANSCRIPT = 'activate-video-transcript';
  public static ADDITIONAL_LINKS = 'additional-links';
  public static ADDITIONAL_SERVICES = 'additional-services';
  public static ALL_CLAIMS = 'all-claims';
  public static ALL_CLAIMS_FAQ = 'all-claims-faq';
  public static AUTO_PAYMENT = 'auto-payment';
  public static CLAIM = 'claim';
  public static CLAIM_DETAILS = 'claim-details';
  public static CLAIM_HELP_MENU = 'claim-help-menu';
  public static CLAIM_LETTERS = 'claim-letters';
  public static CLAIM_VIDEO = 'claim-video';
  public static CLAIMS_AND_ACCOUNTS = 'claims-and-accounts';
  public static CLAIMS_NEED_ATTENTION = 'claims-need-attention';
  public static COMMON = 'common';
  public static COMPARE_CARE = 'compare-care';
  public static CONTACT_US = 'contact-us';
  public static CONTENT_HOPPER = 'content-hopper';
  public static COSTS = 'costs';
  public static DATE_SELECTOR = 'date-selector';
  public static DCSA_FAQ = 'dcsa-faq';
  public static DIRECT_DEPOSIT = 'direct-deposit';
  public static EDIT_CLAIM_NOTE = 'edit-claim-note';
  public static ERRORS = 'errors';
  public static FAQ = 'faq';
  public static FILTER = 'filter';
  public static FSA_FAQ = 'fsa-faq';
  public static GUIDED_SEARCH = 'guided-search';
  public static HEALTH_CHECKLIST = 'health-checklist';
  public static HRA_FAQ = 'hra-faq';
  public static HSA_FAQ = 'hsa-faq';
  public static HCSA_FAQ = 'hcsa-faq';
  public static HOW_IT_WORKS = 'how-it-works';
  public static ID_CARDS = 'id-cards';
  public static INACTIVE = 'inactive';
  public static INCENTIVES = 'incentives';
  public static LINK_BAR = 'link-bar';
  public static MRA_FAQ = 'mra-faq';
  public static MR_RX_SPENDING_COST_SUMMARY = 'mr-rx-spending-cost-summary';
  public static NOTIFICATION = 'notification';
  public static EXPIRATION_WARNING = 'expiration-warning';
  public static ONBOARDING = 'onboarding';
  public static PAGE_TITLES = 'page-titles';
  public static PAY_NOW_MODAL = 'pay-now-modal';
  public static PCP = 'pcp';
  public static PHARMACY = 'pharmacy';
  public static PLAN_BALANCES = 'plan-balances';
  public static PREMIUM_PAYMENTS = 'premium-payments';
  public static PROGRAMS = 'programs';
  public static QUICK_LINKS = 'quick-links';
  public static RECENT_CLAIMS = 'recent-claims';
  public static RECOMMENDATIONS = 'recommendations';
  public static RESOURCES = 'resources';
  public static SEE_YOU_LATER = 'see-you-later';
  public static STATEMENTS = 'statements';
  public static SUBMIT_CLAIM = 'submit-claim';
  public static SUPER_USER_BANNER = 'super-user-banner';
  public static TIPS_TO_SAVE = 'tips-to-save';
  public static WELLNESS = 'wellness';
}

export const LocaleKey = 'ARCADE_LOCALE';
export const SessionLocaleKey = 'ARCADE_SESSION_LOCALE';
