import { History, Location, LocationDescriptor } from 'history';
import React, { ComponentType } from 'react';
import { goTo } from 'scripts/util/link/link';

export interface IRouterProps {
  location: Pick<Location, 'pathname' | 'search'>; // only including properties that we have implemented here
  history: Pick<History, 'push' | 'replace'>; // only including functions that we have implemented here
}

const location: IRouterProps['location'] = {
  get pathname(): string {
    return window.location.pathname;
  },
  get search(): string {
    return window.location.search;
  },
};

const history: IRouterProps['history'] = {
  push(to: LocationDescriptor): void {
    goTo(to, false);
  },
  replace(to: LocationDescriptor): void {
    goTo(to, true);
  },
};

export function withRouter<T>(Component: ComponentType<T>): ComponentType<Omit<T, keyof IRouterProps>> {
  return props => {
    return <Component history={history} location={location} {...props as T} />;
  };
}
