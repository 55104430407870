import { targetingUris } from 'scripts/util/uri/uri';
import { CacheName } from '../api.interfaces';
import HttpClient from '../http-client';
import { IArcadeClient, IProfileUser } from '../profile/profile.interfaces';
import { ICampaignResponse, ICampaigns, IClientConfig, IClientConfigResponse } from './targeting.interfaces';
import { concatPvrc } from '../../util/user/user';

function getCampaigns(rallyId: string, placements: string[]): Promise<ICampaigns> {
  const url = targetingUris.campaigns(rallyId, { placements });
  return HttpClient.get<ICampaignResponse>(url, { cacheName: CacheName.Targeting }).then(rsp => rsp.data);
}

function getClientConfig(currentUser: IProfileUser, clientInfo?: IArcadeClient): Promise<IClientConfig> {
  const defaultConfig = {
    clientCustomContentId: '',
    clientConfigId: '',
    clientMetaData: {} as any,
    suppressions: {} as any,
    customLabels: {} as any,
    customMessaging: {},
    contentOverrides: {} as any,
  };

  if (clientInfo && clientInfo.hasCustomContent && clientInfo.configId) {
    const policyNumber = currentUser.primaryPolicyNumber;
    const pvrc = concatPvrc(currentUser);

    const url = targetingUris.clientConfig(clientInfo.configId, {
      clientId: clientInfo.clientId,
      policyNumber,
      pvrc,
    });

    return HttpClient.get<IClientConfigResponse>(url, { cacheName: CacheName.Targeting })
      .then(rsp => rsp.data)
      .catch(() => Promise.resolve(defaultConfig));
  } else {
    return Promise.resolve(defaultConfig);
  }
}

export const TargetingApi = {
  getCampaigns,
  getClientConfig,
};
