import angular from 'angular';
import 'angular-translate';
import { Observable } from 'rxjs/Observable';
import {
  benefitsCoverage,
  connectDoctor,
  connectInpatient,
  connectSpecialist,
  connectUrgent,
} from 'scripts/util/resource/resource.constants';
import { IResource } from 'scripts/util/resource/resource.interfaces';
import costsTemplate from 'views/dashboard/cs-costs.html';
import { IBenefitService } from '../../../api/plans/plans.interfaces';
import { IPlansService } from '../../../api/plans/plans.service';
import { IProduct } from '../../../api/profile/profile.interfaces';
import { IProfileService } from '../../../api/profile/profile.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';
import { IFeatureFlagService } from '../../../util/feature-flag/feature-flag.interface';
import { IResourceService } from '../../../util/resource/resource.service';
import { CostCopy, CostId } from '../../modals/cost-info/cost-info.interfaces';
import { CostInfoService } from '../../modals/cost-info/cost-info.service';
import { CostIdCodes, ICost } from '../costs/costs.interfaces';
import { IServiceCodeMap } from '../mr-costs/mr-costs.component';

export interface ICSCostItem extends ICost {
  isExternal?: boolean;
}

export interface ICSCosts {
  title?: string;
  costItems: ICSCostItem[];
}

export class CSCostsController {
  public costs: ICSCosts;
  public costsRequest: Observable<any>;
  public benefitsCoverage: IResource;
  public contactNurseInfo: { phone: string; url: string };
  public contactNurseText = 'NURSE_LINE';

  private productsRequest: Observable<IProduct>;

  constructor(
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private costInfoService: CostInfoService,
    private featureFlagService: IFeatureFlagService,
    private plansService: IPlansService,
    private profileService: IProfileService,
    public resourceService: IResourceService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COSTS);
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.TIPS_TO_SAVE);

    this.benefitsCoverage = benefitsCoverage;

    this.productsRequest = this.userService
      .getHeartbeat()
      .flatMap(rsp => this.profileService.getProducts(rsp.data.rallyId))
      .map(rsp => rsp.data.products)
      .map(products => products.nurseChat || products.nurseLine)
      .do(nurse => {
        const contactInfo = nurse && nurse.contactInformation;
        if (contactInfo) {
          this.contactNurseInfo = { phone: contactInfo.phoneNum, url: contactInfo.url };
          if (contactInfo.url) {
            this.contactNurseText = contactInfo.phoneNum ? 'NURSE_CHAT_WITH_PHONE' : 'NURSE_CHAT';
          }
        }
      });

    this.costs = {
      costItems: [
        {
          id: CostId.csDoctor,
          name: CostCopy.costCSDoctor.title,
          guidedSearch: {
            id: 'medical',
            text: CostCopy.costCSDoctor.link,
            href: this.resourceService.get(connectDoctor),
          },
        },
        {
          id: CostId.csSpecialist,
          name: CostCopy.costCSSpecialist.title,
          guidedSearch: {
            id: 'specialist',
            text: CostCopy.costCSSpecialist.link,
            href: this.resourceService.get(connectSpecialist),
          },
        },
        {
          id: CostId.csUrgent,
          name: CostCopy.costCSUrgent.title,
          guidedSearch: {
            id: 'urgent',
            text: CostCopy.costCSUrgent.link,
            href: this.resourceService.get(connectUrgent),
          },
        },
        {
          id: CostId.csHospital,
          name: CostCopy.costCSHospital.title,
          guidedSearch: {
            id: 'hospital',
            text: CostCopy.costCSHospital.link,
            href: this.resourceService.get(connectInpatient),
          },
        },
      ],
    };

    this.costsRequest = this.userService
      .getHeartbeat()
      .let(this.profileService.toProfile())
      .flatMap(profile =>
        this.plansService.getBenefits(profile.data.currentUser.rallyId, profile.data.currentUser.dependentSeqNum),
      )
      .do(benefitsRsp => {
        const medicalPlan = benefitsRsp.data.benefits[0];
        const serviceCodeMap = this.getServiceCodeMap(medicalPlan.services);
        this.costs.costItems = this.costs.costItems.filter(item => serviceCodeMap[CostIdCodes[item.id]]);
        this.costs.title = medicalPlan.planName || 'Medical Plan';
      })
      .flatMap(() =>
        Observable.if(
          () => this.costs.costItems.length > 0,
          Observable.from(this.costs.costItems)
            .flatMap(
              cost =>
                this.costInfoService
                  .getCostsForService(cost.id)
                  .map(({ inNetwork }) => {
                    if (cost.id === CostId.csSpecialist && this.featureFlagService.isReferralRequiredOn()) {
                      const inNetworkWithReferralRequired = inNetwork.filter(c => !!c.referralRequired);

                      if (inNetworkWithReferralRequired.length > 0) {
                        cost.showWithReferralDesc = true;

                        return costInfoService.getNonZeroCostMap(inNetworkWithReferralRequired);
                      }
                    }

                    return this.costInfoService.getNonZeroCostMap(inNetwork);
                  })
                  .catch(() => Observable.of({})),
              (cost, costMap) => ({ cost, costMap }),
            )
            .do(({ cost, costMap }) => {
              cost.costInfoCopy = this.costInfoService.chooseCostInfoCopy(costMap, true);
              cost.amounts = costMap;
            }),
          Observable.of(false),
        ),
      );
  }

  public $onInit(): void {
    this.costsRequest.subscribe(() => undefined, console.warn);
    this.productsRequest.subscribe(() => undefined, console.warn);
  }

  public internalRedirect($event: ng.IAngularEvent, url: string): void {
    $event.preventDefault();
    this.userService.internalSSORedirect(url);
  }

  private getServiceCodeMap(services: IBenefitService[]): IServiceCodeMap {
    const serviceCodeMap = {};
    for (const service of services) {
      serviceCodeMap[service.serviceCode.code] = service.inNetwork && service.inNetwork.length > 0;
    }
    return serviceCodeMap;
  }
}

export class CSCostsComponent implements ng.IComponentOptions {
  public controller: any;
  public templateUrl: any;

  constructor() {
    this.controller = CSCostsController;
    this.templateUrl = costsTemplate;
  }
}
