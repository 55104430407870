import { AccountType, BalanceType } from '../../../api/ledger/ledger.interfaces';
import { IClientConfig, ICustomFinancialAccountLabel } from '../../../api/targeting/targeting.interfaces';
import { ITargetingService } from '../../../api/targeting/targeting.service';
import { IUserService } from '../../../api/user/user.service';
import { Dictionary } from '../../../util/constants/i18n.constants';

export interface IFinancialAccountGlossaryParams extends ng.ui.IStateParamsService {
  accountType: AccountType;
  term: BalanceType;
}

export class FinancialAccountsModalController implements ng.IComponentController {
  public accountName: string;
  public accountNameAbbreviation: string;
  public accountType: AccountType;
  public term: BalanceType;
  private clientConfig: IClientConfig;
  private specialCaseMap: {
    [accountType: string]: {
      [balanceType: string]: AccountType;
    };
  };

  constructor(
    private $state: angular.ui.IStateService,
    private $stateParams: IFinancialAccountGlossaryParams,
    private $translatePartialLoader: angular.translate.ITranslatePartialLoaderService,
    private targetingService: ITargetingService,
    private userService: IUserService,
  ) {
    'ngInject';
    $translatePartialLoader.addPart(Dictionary.COMMON);
    $translatePartialLoader.addPart(Dictionary.HOW_IT_WORKS);
    $translatePartialLoader.addPart(Dictionary.ACCOUNT_SUMMARY);
    $translatePartialLoader.addPart(Dictionary.DCSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.FAQ);
    $translatePartialLoader.addPart(Dictionary.FSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HCSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HRA_FAQ);
    $translatePartialLoader.addPart(Dictionary.HSA_FAQ);
    $translatePartialLoader.addPart(Dictionary.MRA_FAQ);

    this.specialCaseMap = {
      [AccountType.FSADC]: {
        BALANCE: AccountType.FSADC,
      },
    };
  }

  public $onInit(): void {
    this.accountType = this.$stateParams.accountType;
    this.term = this.$stateParams.term;

    this.userService
      .getHeartbeat()
      .flatMap(({ data }) => this.targetingService.getClientConfig(data.rallyId))
      .subscribe(clientConfig => {
        this.clientConfig = clientConfig;
        if (this.accountType) {
          const customAccountLabel = this.getCustomAccountLabel(this.accountType);
          if (customAccountLabel) {
            this.accountName = customAccountLabel.fullName;
            this.accountNameAbbreviation = customAccountLabel.abbreviation;
          } else {
            this.accountNameAbbreviation = this.getGeneralAccountType();
          }
        }
      }, console.warn);
  }

  public getAccountType(): string {
    if (this.specialCaseMap[this.accountType] && this.specialCaseMap[this.accountType][this.term]) {
      return this.specialCaseMap[this.accountType][this.term];
    } else {
      return this.getGeneralAccountType();
    }
  }

  private getCustomAccountLabel(accountType: AccountType): ICustomFinancialAccountLabel | undefined {
    if (this.clientConfig && this.clientConfig.customLabels.customFinancialAccountLabels) {
      if (
        Object.prototype.hasOwnProperty.call(this.clientConfig.customLabels.customFinancialAccountLabels, accountType)
      ) {
        return this.clientConfig.customLabels.customFinancialAccountLabels[accountType];
      }
    }
  }

  private getGeneralAccountType(): string {
    switch (this.accountType) {
      case AccountType.HSA:
        return 'HSA';
      case AccountType.HRA:
      case AccountType.HRASD:
      case AccountType.HRAPD:
      case AccountType.HRAAP:
        return 'HRA';
      case AccountType.FSAHC:
      case AccountType.FSADC:
      case AccountType.FSALP:
        return 'FSA';
      case AccountType.DCSA:
        return 'DCSA';
      case AccountType.HCSA:
        return 'HCSA';
      case AccountType.MRA:
        return 'MRA';
    }
  }
}
