import { AnyClaimType } from '../../api/claims/claims.interfaces';
import { ClaimsService } from '../../api/claims/claims.service';
import { IEnvironmentConstants } from '../constants/environment.interfaces';
import { IFeatureFlagService } from './feature-flag.interface';

// Why do I exist?
// Constants (which we use for Environment/feature flags) are nearly impossible to test
// Services are mockable and spyOn-able
export class FeatureFlagService implements IFeatureFlagService {
  constructor(private Environment: IEnvironmentConstants) {
    'ngInject';
  }

  public isActivateSwitchOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_SWITCH_ENABLED;
  }

  public isActivateVideoOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ACTIVATE_VIDEO;
  }

  public isAdvantageOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_ADVANTAGE;
  }

  public isAutoPaymentOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_AUTO_PAYMENT;
  }

  public isClaimDetailsBreakdownOn(claimType: AnyClaimType): boolean {
    return ClaimsService.getByType(claimType, true, false)
      ? true
      : !!this.Environment.FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_BREAKDOWN_${claimType}`];
  }

  public isClaimDetailsOn(claimType: AnyClaimType): boolean {
    // ARC-5293 only HealthCare type is enabled currently
    return ClaimsService.getByType(claimType, true, false)
      ? true
      : !!this.Environment.FEATURE_FLAGS[`ARCADE_FEATURES_CLAIM_DETAILS_${claimType}`];
  }

  public isClaimMoreOptionsDropdownOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_CLAIM_MORE_OPTIONS_DROPDOWN;
  }

  public isDentalClaimPDFOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SHOW_DENTAL_CLAIMS_PDF_SUBMISSION_FORM;
  }

  public isDirectDepositOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_DIRECT_DEPOSIT;
  }

  public isEiRecommendationsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EI_RECOMMENDATIONS;
  }

  public isEIVirtualVisitPCPRowItemOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EI_VIRTUAL_VISIT_PCP_ROW_ITEM;
  }

  public isExpatriateClaimsLinkOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EXPATRIATE_CLAIMS_LINK;
  }

  public isExpatsMvpDonutOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_EXPATS_MVP_DONUT;
  }

  public isFpcPcpAdditonalParametersRequired(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_FPC_PCP_ADDITIONAL_PARAMS_REQUIRED;
  }

  public isFpcPcpForGatedOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_FPC_PCP_FOR_GATED;
  }

  public isHealthAssesmentLinkOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_HEALTH_ASSESMENT;
  }

  public isGenesysChatOnForClaims(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_GENESYS_CHAT_CLAIMS;
  }

  public isLinkToInternationalClaimsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_LINK_TO_INTERNATIONAL_CLAIMS;
  }

  public isMRAccountSummaryReactOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_ACCOUNT_SUMMARY_REACT;
  }

  public isMRNICEDonutSuppressionOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_NICE_DONUT_SUPPRESSION;
  }

  public isMRPharmaciesOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_PHARMACIES;
  }

  public isMRPreEffPremiumPaymentsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_PRE_EFF_PREMIUM_PAYMENTS;
  }

  public isMRRXSpendingAndCostSummaryOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_RX_SPENDING_AND_COST_SUMMARY;
  }

  public isMROptumClaimsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_OPTUM_CLAIMS;
  }

  public isMRRallyClaimsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_RALLY_CLAIMS;
  }

  public isMRSecureMessagesOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_SECURE_MESSAGES;
  }

  public isMRSpendingAndCostSummaryOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_MR_SPENDING_AND_COST_SUMMARY;
  }

  public isNotificationOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_NOTIFICATION;
  }

  public isOfflineWebPcpSuppressionOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_OFFLINE_WEB_SUPPRESS_PCP_STRIP;
  }

  public isPayNowModalOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL;
  }

  public isPayNowModalRedirectOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL_REDIRECT;
  }

  public isPayNowModalTargetingRolloutOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL_TARGETING_ROLLOUT;
  }

  public isPayNowModalWarningOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PAY_NOW_MODAL_WARNING;
  }

  public isPcpForPolarisOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_POLARIS;
  }

  public isPcpGroupOrFacilityNameOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PCP_GROUP_OR_FACILITY_NAME;
  }

  public isProfileRallyPayClaimStartDateOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_PROFILE_RALLYPAY_CLAIM_START_DATE;
  }

  public isOptumRxPharmacyRedesignOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_OPTUM_RX_PHARMACY_REDESIGN;
  }

  public isQuickLinksOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_QUICK_LINKS;
  }

  public isRallyRxInProductEligibilityOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_RALLY_RX_IN_PRODUCT_ELIGIBILITY;
  }

  public isRallyRxOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_RALLY_RX;
  }

  public isReactIdCardModalOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REACT_ID_CARD_MODAL;
  }

  public isReactPcpStripOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REACT_PCP_STRIP;
  }

  public isReactQuickLinksOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REACT_QUICK_LINKS;
  }

  public isReduxLoggingOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REDUX_LOGGING;
  }

  public isReferralRequiredOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_REFERRAL_REQUIRED;
  }

  public isSharedHeaderFooterPhaseTwoOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SHARED_HEADER_AND_FOOTER_PHASE_TWO;
  }

  public isStatementsOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_STATEMENTS;
  }

  public isSubmitClaimOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SUBMIT_CLAIM;
  }

  public isSubmitClaimHeaderReactOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SUBMIT_CLAIM_HEADER_REACT;
  }

  public isSubmitClaimOtherFormsReactOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_SUBMIT_CLAIM_OTHER_FORMS_REACT;
  }

  public isTierTwoEnabled(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_TIER_TWO;
  }

  public isVideoBannerOn(): boolean {
    return this.Environment.FEATURE_FLAGS.ARCADE_FEATURES_VIDEO_BANNER;
  }
}
