import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'scripts/util/constants/i18n.constants';
import withClickTracking from 'scripts/hoc/with-click-tracking/with-click-tracking';
import { Link } from 'scripts/ui/link/link';

const TrackedAccountSummaryModalExplanationLink = withClickTracking(Link, 'deductible-oop-explanation');

export const AccountSummaryModalExplanation: FunctionComponent = () => {
  const { t } = useTranslation([Dictionary.ACCOUNT_SUMMARY, Dictionary.COMMON]);

  return (
    <TrackedAccountSummaryModalExplanationLink
      className={'account-summary-modal-explanation'}
      to={'/modal/account-summary-explanation'}
    >
      {t(`${Dictionary.ACCOUNT_SUMMARY}:DEDUCTIBLE_AND_OOP_HEADER`, {
        deductibleLabel: t(`${Dictionary.COMMON}:DEDUCTIBLE`),
        oopMaxLabel: t(`${Dictionary.ACCOUNT_SUMMARY}:OOP_MAX_SINGULAR`),
      })}
    </TrackedAccountSummaryModalExplanationLink>
  );
};
